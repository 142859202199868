<template>
    <div class="admin-change-password-component container">
        <div class="change-password-form-wrapper">
            <h4 class="change-password-box-title">{{ $ml.get('adminMenu.changePassword') }}</h4>
            <div class="change-password-form-inner row">
                <div class="col-12 mb-3">
                    Please confirm the new password
                </div>
                <form v-on:submit.prevent class="col-12">
                    <div class="row my-3">
                        <div class="col-12">
                            <input class="col-12 w-100 pl-3" v-model="password" type="password" name="password" placeholder="Set password">
                            <span class="input-stripe-border"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <input class="w-100 pl-3" v-model="confirmPassword" type="password" name="confirm_password" placeholder="Confirm password">
                            <span class="input-stripe-border"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12 text-center">
                            <button class="change-password-button" :class="(!password || (password != confirmPassword))?'action-disabled':''" type="submit" v-on:click="changePassword">{{ $ml.get('adminMenu.changePassword') }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import auth from './../../services/auth.js';
import message from './../../services/messages.js';
import eventBus from './../../services/event_bus.js';

export default {
    name: 'AdminChangePassword',
    data: function(){
        return {
            password: '',
            confirmPassword: '',
            token:null
        };
    },
    mounted: function () {
        if(auth.user){
            this.$router.push('/app');
        }

        if (typeof this.$route.query.token == 'undefined') {
            this.$router.push('/app');
        }

        this.token = this.$route.query.token;
        eventBus.$on('change_password_success', this.changePasswordSuccess);
    },
    beforeDestroy () {
        eventBus.$off('change_password_success', this.changePasswordSuccess);
    },
    computed: {
    },
    methods: {
        changePasswordSuccess: function() {
            message.successMessage(message.GLOBAL_MESSAGES.PASSWORD_CHANGE_SUCCESS);
            this.$router.push('/app');
        },
        changePassword: function() {
            if (this.password != this.confirmPassword) {
                return;
            }
            auth.setPassword({password: this.password, confirmPassword: this.confirmPassword, token: this.token});
        },
    },
}
</script>

<style scoped>
label {
    /*min-width: 140px;*/
    margin-right: 20px;
    text-align: right;
}
input {
    height: 38px;
    margin-bottom: 0px;
    padding-top: 9px;
    padding-bottom: 9px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    border-radius: 2px;
    background-color: #fff;
    font-size: 13px;
    text-align: left;
}
input[type='password'] {
    outline: 0;
}
.input-stripe-border {
    margin-bottom: 25px;
    width: 100%;
    display: block;
    border-bottom: solid 1px #07ae9c;
}
input[type='password']:focus + .input-stripe-border {
    border-bottom: solid 2px #07ae9c;
    margin-bottom: 24px;
}
.change-password-form-wrapper {
    max-width: 600px;
    margin: auto;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.08);
    background-color: #f3f3f3;
    margin-top: 15px;
}
.change-password-form-inner {
    padding: 50px 50px 40px;
}
.change-password-box-title {
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #dfdfdf;
    background-color: #fff;
    font-family: museo-sans,sans-serif;
    color: #3b444d;
    font-size: 1.1rem;
    line-height: 1.3rem;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0;
    text-transform: capitalize;
    margin: 0;
}
.change-password-button {
    padding-top: 15px;
    padding-bottom: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: #08ae9c;
    border-radius: 2px;
    background-color: #08ae9c;
    -webkit-transition: color 200ms ease, background-color 200ms ease;
    transition: color 200ms ease, background-color 200ms ease;
    font-size: 14px;
    width: 100%;
    color: #ffffff;
}
.change-password-button:hover {
    background-color: transparent;
    color: #333;
}

.overlay-title {
    margin: 20px 0;
    color: #fff;
}
</style>
