<template>
    <fieldset class="rating">
        <span v-bind:class="{'sel': (rate >= 1), 'half': (rate >= 0.5 && rate < 1) }"></span>
        <span v-bind:class="{'sel': (rate >= 2), 'half': (rate >= 1.5 && rate < 2) }"></span>
        <span v-bind:class="{'sel': (rate >= 3), 'half': (rate >= 2.5 && rate < 3) }"></span>
        <span v-bind:class="{'sel': (rate >= 4), 'half': (rate >= 3.5 && rate < 4) }"></span>
        <span v-bind:class="{'sel': (rate == 5), 'half': (rate >= 4.5 && rate < 5) }"></span>
    </fieldset>
</template>

<script>
    export default {
        name: 'Rating',
        props: ['rate'],
        data() {
            return {
                loading: true,
            }
        },
        mounted() {
            
        },
        methods: {
        }
    }
</script>
