<template>
    <div class="search-component main-wrap-inner">
      <div class="side-bar-column">
        <div class="side-bar-filter-wrap">
          <h3 class="sidebar-head">{{ $ml.get("search.filters.byPrice") }}</h3>
          <div class="price-slider-wrap">
            <vue-slider
              v-if="filters.price"
              :min="filters.priceMin"
              :max="filters.priceMax"
              :min-range="10"
              v-model="filters.price"
              :enable-cross="false"
              :tooltip-formatter="formatterT"
              :tooltip="'always'"
              v-on:change="onPriceFilter"
            />
          </div>
        </div>
  
        <div v-if="generalOptions.app_id == 1" class="side-bar-filter-wrap">
          <h3 class="sidebar-head">
            {{ $ml.get("search.filters.refineResults") }}
          </h3>
          <div class="filters-wrap">
            <template v-for="(item, index) in professions">
              <div
                class="filter-block"
                v-bind:key="index"
                v-if="!professionsLimit || (professionsLimit && index <= 6)"
              >
                <input
                  type="checkbox"
                  :id="`profession_` + item.id"
                  :value="item.id"
                  v-model="filters.professions"
                  v-on:change="
                    updateFilterLabels();
                    onSearch();
                  "
                />
                <label :for="`profession_` + item.id">{{
                  item.profession
                }}</label>
              </div>
            </template>
            <div class="clear-filter-text">
              <a v-if="professionsLimit" v-on:click="professionsLimit = false">{{
                $ml.get("search.filters.more")
              }}</a>
              <a v-if="!professionsLimit" v-on:click="professionsLimit = true">{{
                $ml.get("search.filters.less")
              }}</a>
              <a
                v-if="filters.professions.length > 0"
                v-on:click="clearProfessions()"
                >{{ $ml.get("search.filters.clear") }}</a
              >
            </div>
          </div>
        </div>
        <div v-if="generalOptions.app_id == 1" class="app-links">
          <a
            rel="noreferrer"
            href="https://itunes.apple.com/us/app/ring-my-stylist/id1250537446"
            target="_blank"
          >
            <img
              class="app-store"
              src="../assets/images/app-store-icon.svg"
              alt="Download Ring My Stylist App from App Store"
            />
          </a>
          <a
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.ringmystylist.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
          >
            <img
              src="../assets/images/google-play-icon.svg"
              alt="Download Ring My Stylist App from Google Play"
            />
          </a>
        </div>
        <div v-if="generalOptions.app_id == 2" class="app-links">
          <a
            rel="noreferrer"
            href="https://itunes.apple.com/us/app/ring-my-barber-appointments/id1451800946"
            target="_blank"
          >
            <img
              class="app-store"
              src="../assets/images/app-store-icon.svg"
              alt="Download Ring My Barber App from App Store"
            />
          </a>
          <a
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.ringmystylist.app.rmb"
            target="_blank"
          >
            <img
              src="../assets/images/google-play-icon.svg"
              alt="Download Ring My Barber App from Google Play"
            />
          </a>
        </div>
        <div v-if="generalOptions.app_id == 5" class="app-links">
          <a
            rel="noreferrer"
            href="https://apps.apple.com/us/app/ring-my-loctician-booking-app/id1558673025"
            target="_blank"
          >
            <img
              class="app-store"
              src="../assets/images/app-store-icon.svg"
              alt="Download Ring My Loctician App from App Store"
            />
          </a>
          <a
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.ringmystylist.app.rml"
            target="_blank"
          >
            <img
              src="../assets/images/google-play-icon.svg"
              alt="Download Ring My Loctician App from Google Play"
            />
          </a>
        </div>
        <div class="side-bar-filter-wrap">
          <h3 v-if="false" class="sidebar-head">
            {{ $ml.get("search.filters.byCustomerReview") }}
          </h3>
          <div v-if="false" class="rating-filter-wrap">
            <div class="rating-filter" v-on:click="onRatingFilter(5)">
              <Rating :rate="5" />
            </div>
            <div class="rating-filter" v-on:click="onRatingFilter(4)">
              <Rating :rate="4" /><span
                >& {{ $ml.get("search.filters.up") }}</span
              >
            </div>
            <div class="rating-filter" v-on:click="onRatingFilter(3)">
              <Rating :rate="3" /><span
                >& {{ $ml.get("search.filters.up") }}</span
              >
            </div>
            <div class="rating-filter" v-on:click="onRatingFilter(2)">
              <Rating :rate="2" /><span
                >& {{ $ml.get("search.filters.up") }}</span
              >
            </div>
            <div class="rating-filter" v-on:click="onRatingFilter(1)">
              <Rating :rate="1" /><span
                >& {{ $ml.get("search.filters.up") }}</span
              >
            </div>
          </div>
          <div v-if="latestReviews.length" class="latest-profile-reviews">
            <div
              v-for="(review, index) in latestReviews"
              :key="index"
              class="profile-review-item"
            >
              <h4 class="review-item-title">
                <a
                  :href="
                    generalOptions.profile_url + review.profile_url + '#reviews'
                  "
                  target="_blank"
                  >{{ review.title.substr(0, 40)
                  }}{{ review.title.length > 40 ? "..." : "" }}</a
                >
              </h4>
              <p v-if="review.title.length > 0" class="review-item-details">
                {{ review.details.substr(0, 80)
                }}{{ review.details.length > 80 ? "..." : "" }}
              </p>
              <p v-if="review.title.length == 0" class="review-item-details">
                <a
                  :href="
                    generalOptions.profile_url + review.profile_url + '#reviews'
                  "
                  target="_blank"
                  >{{ review.details.substr(0, 80)
                  }}{{ review.details.length > 80 ? "..." : "" }}</a
                >
              </p>
              <div class="review-item-by text-right">
                {{ review.first_name }} {{ review.last_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="side-bar-filter-wrap">
          <h3 class="sidebar-head">{{ $ml.get("search.contactForm.title") }}</h3>
          <div class="sidebar-form-block w-form">
            <input
              v-model="contactEmailAddress"
              type="email"
              placeholder="Your Email"
              class="w-input email-input"
            />
            <textarea
              v-model="contactEmailText"
              :placeholder="$ml.get('search.contactForm.palceholder')"
              maxlength="5000"
              name="field"
              class="sidebar-text-area w-input"
            ></textarea>
            <input
              v-on:click="onSendContactEmail"
              type="button"
              value="Send Message"
              class="button w-button"
            />
            <div
              v-if="contactEmailMessage"
              class="sidebar-form-message alert alert-success"
            >
              {{ contactEmailMessage }}
            </div>
          </div>
        </div>
      </div>
      <div class="results-column">
        <div class="search-grid-wrap">
          <form autocomplete="off" v-on:submit="preventSubmit">
            <div class="w-layout-grid search-grid">
              <div class="search-grid-block">
                <div class="search-grid-title">
                  {{ $ml.get("search.filters.search") }}
                </div>
                <div class="search-form-block">
                  <input
                    v-model="search"
                    v-on:keyup.enter="onSearch"
                    v-on:click="onToggleSearchDropdown"
                    type="text"
                    class="filter-search-bar main-search"
                    name="search-filter-input"
                    :placeholder="
                      $ml.get(
                        generalOptions.app_id == 2
                          ? 'search.filters.searchPlaceholderRMB'
                          : 'search.filters.searchPlaceholder'
                      )
                    "
                  />
                </div>
              </div>
              <div class="search-grid-block">
                <div class="search-grid-title">
                  {{ $ml.get("search.filters.location") }}
                </div>
                <div class="search-form-block">
                  <div
                    v-on:click="onToggleLocationDropdown"
                    class="filter-input-button"
                  >
                    <span class="general-icon location"></span>
                    <div v-if="!search_location">
                      {{ $ml.get("search.filters.locationPlaceholder") }}
                    </div>
                    <div v-if="search_location">{{ search_location }}</div>
                  </div>
                </div>
              </div>
              <div class="search-grid-block">
                <div class="search-grid-title">
                  {{ $ml.get("search.filters.date") }}
                </div>
                <div
                  v-on:click="onToggleDateDropdown"
                  class="filter-input-button"
                >
                  <span class="general-icon calendar"></span>
                  <div v-if="!search_date">
                    {{ $ml.get("search.filters.datePlaceholder") }}
                  </div>
                  <div v-if="search_date">
                    {{ search_date }} {{ search_time }}
                  </div>
                </div>
              </div>
              <div class="search-submit-button" v-on:click="onSearch"></div>
            </div>
          </form>
          <div class="search-dropdown" :class="{ open: searchDropdownOpen }">
            <div class="search-dropdown-inner">
              <span
                class="filter-dropdown-close-button"
                v-on:click="searchDropdownOpen = false"
              ></span>
              <div class="search-item-section">
                <div class="search-section-title">
                  {{ $ml.get("search.filters.searchServiceLabel") }}
                </div>
                <span
                  v-for="(name, i) in featuredServicesSearch"
                  :key="i"
                  v-on:click="onSelectSearchItem(name)"
                  class="search-drop-item"
                  >{{ name }}</span
                >
              </div>
              <div class="search-item-section">
                <div class="search-section-title">
                  {{ $ml.get("search.filters.searchStylistLabel") }}
                </div>
                <span
                  v-for="(name, i) in featuredStylistsSearch"
                  :key="i"
                  v-on:click="onSelectSearchItem(name)"
                  class="search-drop-item"
                  >{{ name }}</span
                >
              </div>
            </div>
          </div>
          <div class="location-dropdown" :class="{ open: locationDropdownOpen }">
            <div class="location-dropdown-inner">
              <span
                class="filter-dropdown-close-button"
                v-on:click="locationDropdownOpen = false"
              ></span>
              <div class="search-drop-title">
                {{ $ml.get("search.filters.locationDropdownTitle") }}
              </div>
              <div class="location-form-block w-form">
                <form
                  class="location-form"
                  autocomplete="off"
                  v-on:submit="preventSubmit"
                >
                  <input
                    :ref="'search_city_zip_input'"
                    v-model="search_city_zip"
                    v-on:keyup.enter="onFindLocation"
                    type="text"
                    class="location-text-field w-input"
                    name="location-text-field"
                    tabindex="10"
                    :placeholder="
                      $ml.get('search.filters.locationDropdownPlaceholder')
                    "
                  />
                  <input
                    v-on:click="onFindLocation"
                    type="button"
                    :value="$ml.get('search.filters.locationDropdownButton')"
                    class="location-submit w-button"
                    autofocus
                  />
                </form>
              </div>
              <div
                v-on:click="onGetCurrentLocation"
                class="current-location-button"
              >
                <span class="general-icon target current-location-icon"></span>
                <div>
                  {{ $ml.get("search.filters.locationDropdownUseCurrent") }}
                </div>
              </div>
            </div>
          </div>
          <div class="date-dropdown" :class="{ open: dateDropdownOpen }">
            <div class="date-dropdown-inner">
              <span
                class="filter-dropdown-close-button"
                v-on:click="dateDropdownOpen = false"
              ></span>
              <div class="date-picker-wrap">
                <div class="search-drop-title">
                  {{ $ml.get("search.filters.dateChooseDate") }}
                </div>
                <div class="date-picker-block-2">
                  <datepicker
                    :value="search_date_calendar"
                    v-on:selected="onSelectDate"
                    :disabledDates="disabledDates"
                    :inline="true"
                  ></datepicker>
                </div>
              </div>
              <div class="timepicker-wrap">
                <div class="search-drop-title">
                  {{ $ml.get("search.filters.dateChooseTime") }}
                </div>
                <div class="timepicker-block">
                  <div class="w-layout-grid timepicker-grid">
                    <div
                      v-for="(hour, i) in filterHoursAvailable"
                      :key="i"
                      :class="{ chosen: search_time == hour }"
                      v-on:click="onSelectHour(hour)"
                      class="timepicker-time"
                    >
                      {{ hour }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="selected-filter-wrap">
          <div class="selected-filter-wrap-inner">
            <div
              v-for="(filter, i) in filter_labels"
              :key="i"
              class="selected-filter"
            >
              <div>{{ filter.name }}</div>
              <span
                v-on:click="onRemoveFilter(filter)"
                class="filter-close-icon"
              ></span>
            </div>
            <div
              v-if="
                filters.price &&
                (filters.price[0] > filters.priceMin ||
                  filters.price[1] < filters.priceMax)
              "
              class="selected-filter"
            >
              <div>
                {{ $ml.get("search.filters.price") }}: ${{ filters.price[0] }} -
                ${{ filters.price[1] }}
              </div>
              <span
                v-on:click="onRemoveSearchFilter('price')"
                class="filter-close-icon"
              ></span>
            </div>
            <div v-if="search_rate" class="selected-filter">
              <div>
                {{ $ml.get("search.filters.customerReview") }}: {{ search_rate }}
              </div>
              <span
                v-on:click="onRemoveSearchFilter('rate')"
                class="filter-close-icon"
              ></span>
            </div>
            <div v-if="search" class="selected-filter">
              <div>{{ $ml.get("search.filters.search") }}: {{ search }}</div>
              <span
                v-on:click="onRemoveSearchFilter('search')"
                class="filter-close-icon"
              ></span>
            </div>
            <div v-if="search_location" class="selected-filter">
              <div>
                {{ $ml.get("search.filters.location") }}: {{ search_location }}
              </div>
              <span
                v-on:click="onRemoveSearchFilter('location')"
                class="filter-close-icon"
              ></span>
            </div>
            <div v-if="search_date" class="selected-filter">
              <div>
                {{ $ml.get("search.filters.datePlaceholder") }}:
                {{ search_date }} {{ search_time }}
              </div>
              <span
                v-on:click="onRemoveSearchFilter('date')"
                class="filter-close-icon"
              ></span>
            </div>
          </div>
          <div
            data-delay="0"
            class="sort-dropdown w-dropdown"
            role="menu"
            style=""
          >
            <span
              v-on:click="show_sort_menu = !show_sort_menu"
              v-on:focusout="show_sort_menu = false"
              class="sort-dropdown-toggle w-dropdown-toggle"
              tabindex="0"
              aria-controls="w-dropdown-toggle-1"
              aria-haspopup="menu"
            >
              <div class="sort-dropdown-icon w-icon-dropdown-toggle"></div>
              <div v-if="!order_by">Sort by</div>
              <div v-if="order_by">{{ order_by_options[order_by] }}</div>
              <div class="sort-dropdown-icon w-icon-dropdown-toggle">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
            </span>
            <nav
              class="sort-menu-drop-list w-dropdown-list"
              :class="{ 'w--open': show_sort_menu }"
            >
              <span
                v-on:mousedown="onSortSelected('featured')"
                class="sort-menu-drop-link"
                >Featured</span
              >
              <span
                v-on:mousedown="onSortSelected('name_asc')"
                class="sort-menu-drop-link"
                >By Alphabet: A-Z</span
              >
              <span
                v-on:mousedown="onSortSelected('name_desc')"
                class="sort-menu-drop-link"
                >By Alphabet: Z-A</span
              >
              <span
                v-on:mousedown="onSortSelected('rate')"
                class="sort-menu-drop-link"
                >Highest Rated</span
              >
            </nav>
          </div>
        </div>
  
        <div class="profile-listing-section featured-listings">
          <div v-if="displayFeaturedSection()" class="profile-listing-group">
            <h2 class="stylists-group-name featured-group-name">
              {{ $ml.get("search.listing.featured") }}
            </h2>
            <div class="result-grid row">
              <div
                v-for="(stylist, index) in featuredStylists"
                :key="`featured_${index}`"
                class="col-xs-12 col-sm-6 col-md-4 custom-col-padding"
              >
                <ProfileListingItem
                  :stylist="stylist"
                  :index="index"
                  :featured="1"
                  v-on:modalProfileSelected="modalProfileSelected"
                  v-on:shareProfileSelected="shareProfileSelected"
                />
              </div>
            </div>
            <DownloadApp />
          </div>
        </div>
  
        <div class="profile-listing-section">
          <div
            v-for="(group, k) in groupedStylists"
            :key="k"
            class="profile-listing-group"
          >
            <h2 class="stylists-group-name">{{ group.profession }}</h2>
  
            <div class="result-grid row">
              <div
                v-for="(stylist, index) in group.stylists"
                :key="index"
                class="col-xs-12 col-sm-6 col-md-4 custom-col-padding"
              >
                <ProfileListingItem
                  :stylist="stylist"
                  :index="index"
                  :featured="filters_applied"
                  v-on:modalProfileSelected="modalProfileSelected"
                  v-on:shareProfileSelected="shareProfileSelected"
                />
              </div>
            </div>
  
            <DownloadApp v-if="k == 0 && !displayFeaturedSection()" />
            <RandomReview
              v-if="
                (k == 0 && displayFeaturedSection()) ||
                (k > 0 && k < groupedStylists.length - 1)
              "
              v-on:modalProfileSelected="modalProfileSelected"
            />
          </div>
        </div>
        <div v-if="initialLoad && groupedStylists.length == 0" class="no-results">
          {{ $ml.get("search.listing.noResultsFor") }}
          <span v-if="search">{{ search }}</span>
          <span v-if="search && search_location">, </span>
          <span v-if="search_location">{{ search_location }}</span>
          <div class="mt-2">
            {{ $ml.get("search.listing.tryADifferent") }}
            <span v-if="search">{{
              $ml.get("search.filters.search").toLowerCase()
            }}</span>
            <span v-if="search && search_location"> or </span>
            <span v-if="search_location">{{
              $ml.get("search.filters.location").toLowerCase()
            }}</span>
          </div>
        </div>
        <div v-if="searching" class="searching-results">
          {{ $ml.get("search.listing.searching") }}
        </div>
        <div
          class="load-more-wrap"
          v-if="stylists.length && totalProfiles > stylists.length"
        >
          <button class="load-more-button" v-on:click="onLoadMore">
            {{ $ml.get("search.listing.loadMore") }}
          </button>
        </div>
      </div>
      <div
        class="modal fade"
        id="share-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="share-modal"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                {{ $ml.get("search.listing.share") }}
                {{ currentShareProfile.first_name }}'s
                {{ $ml.get("search.listing.profile") }}:
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body share-buttons">
              <span class="share-icon facebook-share">
                <a :href="getShareLink('facebook')" target="_blank"
                  ><i class="fa fa-facebook-official" aria-hidden="true"></i
                ></a>
              </span>
              <span class="share-icon twitter-share">
                <a :href="getShareLink('twitter')" target="_blank"
                  ><i class="fa fa-twitter-square" aria-hidden="true"></i
                ></a>
              </span>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
  
      <BookingModalNew
        v-if="modalStylistAlias"
        :stylistAlias="modalStylistAlias"
        :serviceId="modalStylistServiceId"
        v-on:modalClosed="modalClosed"
      />
    </div>
  </template>
  
  <script>
  import _ from "lodash";
  import $ from "jquery";
  import auth from "./../services/auth.js";
  import api from "./../services/api_endpoints.js";
  import Rating from "./rating";
  import ProfileListingItem from "./profile_listing_item";
  import RandomReview from "./random_review";
  import DownloadApp from "./download_app";
  import VueSlider from "vue-slider-component";
  import "vue-slider-component/theme/antd.css";
  import Datepicker from "vuejs-datepicker";
  import moment from "moment";
  import BookingModalNew from "./book_modal_new";
  
  export default {
    name: "Search",
    data: function () {
      return {
        user: null,
        professions: [],
        groupedStylists: [],
        featuredStylists: [],
        modalProfile: null,
        filters: {
          professions: [],
          price: [],
          priceMin: 0,
          priceMax: 100,
        },
        filters_applied: false,
        search: "",
        search_service: "",
        search_location: "",
        search_date: "",
        search_time: "",
        search_city_zip: "",
        search_date_calendar: "",
        search_rate: "",
        order_by: "",
        order_by_options: {
          featured: "Featured",
          name_asc: "By Alphabet: A-Z",
          name_desc: "By Alphabet: Z-A",
          rate: "Highest Rated",
        },
        filter_labels: [],
        show_sort_menu: false,
        professionsLimit: false,
        formatterT: (v) => `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        vfTransitions: ["fade"],
        featuredNum: 6,
        perPage: 12,
        page: 1,
        totalProfiles: 0,
        initialLoad: false,
        searching: false,
        searchDropdownOpen: false,
        locationDropdownOpen: false,
        dateDropdownOpen: false,
        featuredStylistsSearch: [],
        featuredServicesSearch: [],
        filterHoursAvailable: [
          "8:00 am",
          "9:00 am",
          "10:00 am",
          "11:00 am",
          "12:00 pm",
          "1:00 pm",
          "2:00 pm",
          "3:00 pm",
          "4:00 pm",
          "5:00 pm",
          "6:00 pm",
          "7:00 pm",
          "8:00 pm",
          "9:00 pm",
        ],
        disabledDates: {
          to: new Date(Date.now() - 8640000),
        },
        currentShareProfile: {},
        contactEmailAddress: "",
        contactEmailText: "",
        contactEmailMessage: "",
        latestReviews: [],
        modalStylistAlias: "",
        modalStylistServiceId: 0,
        stylists: [],
      };
    },
    mounted: function () {
      this.$nextTick(function () {
        this.user = auth.user;
  
        let current_city = localStorage.getItem("current_city");
  
        if (current_city) {
          this.search_location = current_city;
        }
  
        //Display 12 featured profiles on the Loctician instance
        if (this.generalOptions.app_id == 5) {
          this.featuredNum = 12;
          this.perPage = 9;
        }
  
        this.getActiveProfessions();
        this.getFeaturedItems();
        this.getPriceRange();
        this.getFeaturedStylists(this.featuredNum, 0);
        this.getStylists(this.perPage, 0);
        this.getLatestReviews();
      });
    },
    computed: {},
    methods: {
      getFilterParams() {
        let params = [];
        this.filters_applied = false;
  
        if (this.search) {
          params.push("search_name_phone=" + encodeURIComponent(this.search));
          this.filters_applied = true;
        }
  
        if (this.search_service) {
          params.push(
            "search_service=" + encodeURIComponent(this.search_service)
          );
          this.filters_applied = true;
        }
  
        if (this.search_location) {
          params.push("location=" + encodeURIComponent(this.search_location));
          this.filters_applied = true;
        }
  
        if (this.search_date) {
          params.push("search_date=" + encodeURIComponent(this.search_date));
          this.filters_applied = true;
        }
  
        if (this.search_time) {
          params.push("search_time=" + encodeURIComponent(this.search_time));
          this.filters_applied = true;
        }
  
        if (this.order_by) {
          params.push("order_by=" + this.order_by);
        } else {
          params.push("order_by=featured");
        }
  
        if (this.search_rate) {
          params.push("search_rate=" + this.search_rate);
          this.filters_applied = true;
        }
  
        if (this.filters.price[0]) {
          params.push("search_price_min=" + this.filters.price[0]);
          params.push("search_price_max=" + this.filters.price[1]);
  
          if (
            this.filters.price[0] > this.filters.priceMin &&
            this.filters.price[1] < this.filters.priceMax
          ) {
            this.filters_applied = true;
          }
        }
  
        if (this.filters.professions.length) {
          let profession_filter = [];
          let self = this;
  
          this.filters.professions.forEach(function (id) {
            profession_filter.push("search_profession[]=" + id);
  
            if (
              typeof self.generalOptions.related_professions[id] != "undefined"
            ) {
              profession_filter.push(
                "search_profession[]=" +
                  self.generalOptions.related_professions[id]
              );
            }
          });
  
          params.push(profession_filter.join("&"));
          this.filters_applied = true;
        }
  
        return params;
      },
      getStylists: function (top, offset) {
        let params = this.getFilterParams();
        this.initialLoad = false;
  
        if (top) {
          params.push("top=" + parseInt(top));
          params.push("offset=" + parseInt(offset));
        }
  
        if (this.generalOptions.profession_id) {
          params.push(
            "featured_profession_id=" + this.generalOptions.profession_id
          );
        }
  
        params.push("save_view_log=1");
  
        let hasSearch = this.search != "" || this.search_location != "";
  
        this.$http
          .get(api.actions.get_profiles_list + "?" + params.join("&"))
          .then(
            function (response) {
              this.initialLoad = true;
              this.searching = false;
  
              if (response.data.error == true) {
                return false;
              }
  
              if (
                !hasSearch &&
                (this.search != "" || this.search_location != "")
              ) {
                return;
              }
  
              let stylists = response.data.profiles;
  
              //prepare images for lightbox
              stylists.forEach(function (el, k) {
                stylists[k].lightbox_images = el.portfolio_images.map(function (
                  img
                ) {
                  return {
                    thumb: img,
                    src: img,
                  };
                });
              });
  
              if (offset) {
                this.stylists = _.union(this.stylists, stylists);
              } else {
                this.stylists = stylists;
              }
  
              this.totalProfiles = response.data.total;
              this.groupStylists(this.stylists);
            }.bind(this),
            function () {
              return false;
            }
          );
      },
      getActiveProfessions: function () {
        this.$http.get(api.actions.get_active_professions).then(
          function (response) {
            if (response.data.error == true) {
              return false;
            }
  
            this.professions = _.filter(response.data.professions, function (el) {
              return el.users > 3 && el.id != 1;
            });
          }.bind(this)
        );
      },
      getFeaturedStylists: function (top, offset) {
        let params = this.getFilterParams();
        params.push("featured=1");
  
        if (this.generalOptions.profession_id) {
          params.push(
            "featured_profession_id=" + this.generalOptions.profession_id
          );
  
          if (
            typeof this.generalOptions.related_professions[
              this.generalOptions.profession_id
            ] != "undefined"
          ) {
            params.push(
              "related_profession_id=" +
                this.generalOptions.related_professions[
                  this.generalOptions.profession_id
                ]
            );
          }
        }
  
        params.push("app_id=" + this.generalOptions.app_id);
  
        if (top) {
          params.push("top=" + parseInt(top));
          params.push("offset=" + parseInt(offset));
        }
  
        params.push("save_view_log=1");
  
        this.featuredStylists = [];
  
        if (
          (this.order_by != "" && this.order_by != "featured") ||
          this.search ||
          this.search_location
        ) {
          return;
        }
  
        let hasSearch = this.search != "" || this.search_location != "";
  
        this.$http
          .get(api.actions.get_profiles_list + "?" + params.join("&"))
          .then(
            function (response) {
              if (response.data.error == true) {
                return false;
              }
  
              if (
                !hasSearch &&
                (this.search != "" || this.search_location != "")
              ) {
                return;
              }
  
              let stylists = response.data.profiles;
              let limitDiff = stylists.length % 3;
  
              if (limitDiff > 0) {
                stylists = _.slice(stylists, 0, stylists.length - limitDiff);
              }
  
              //prepare images for lightbox
              stylists.forEach(function (el, k) {
                stylists[k].lightbox_images = el.portfolio_images.map(function (
                  img
                ) {
                  return {
                    thumb: img,
                    src: img,
                  };
                });
              });
  
              this.featuredStylists = stylists;
            }.bind(this)
          );
      },
      getFeaturedItems: function () {
        this.$http.get(api.actions.get_featured_items).then(
          function (response) {
            if (response.data.error == true) {
              return false;
            }
  
            this.featuredStylistsSearch = response.data.featured_stylists.map(
              function (el) {
                return el.first_name + " " + el.last_name;
              }
            );
  
            this.featuredServicesSearch = response.data.featured_services.map(
              function (el) {
                return el.name;
              }
            );
          }.bind(this)
        );
      },
      getLatestReviews() {
        this.$http
          .get(
            api.actions.get_latest_profile_reviews +
              "?limit=12" +
              "&app_id=" +
              this.generalOptions.app_id
          )
          .then(
            function (response) {
              if (response.data.error == true) {
                return false;
              }
  
              this.latestReviews = response.data.reviews;
            }.bind(this)
          );
      },
      getPriceRange() {
        this.$http.get(api.actions.get_service_price_range).then(
          function (response) {
            if (response.data.error == true) {
              return false;
            }
  
            let priceMin = response.data.values.service_min_price;
            let priceMax = response.data.values.service_max_price;
  
            this.filters.priceMin = priceMin;
            this.filters.priceMax = priceMax;
  
            _.delay(() => {
              this.filters.price = [priceMin, priceMax];
            }, 100);
          }.bind(this),
          function () {
            return false;
          }
        );
      },
      getProfessionsLabel(professions) {
        return professions
          .map(function (el) {
            return el.profession;
          })
          .join(", ");
      },
      clearProfessions: function () {
        this.filters.professions = [];
        this.onSearch();
        this.updateFilterLabels();
      },
      getProfileUrl(stylist) {
        return this.generalOptions.profile_url + stylist.profile_url;
      },
      onLoadMore() {
        this.getStylists(this.perPage, this.perPage * this.page);
        this.page++;
      },
      groupStylists(stylists) {
        let groups = {};
        this.groupedStylists = [];
  
        stylists.forEach(function (el) {
          let profession = el.profession_name ? el.profession_name : "";
  
          if (typeof groups[profession] == "undefined") {
            groups[profession] = [];
          }
  
          groups[profession].push(el);
        });
  
        let other = [];
  
        for (let i in groups) {
          if (groups[i].length < 3 || !i) {
            other = _.union(other, groups[i]);
            continue;
          }
  
          let groupLimitDiff = groups[i].length % 3;
  
          if (groupLimitDiff > 0 && !this.filters_applied) {
            let limitStart = groups[i].length - groupLimitDiff;
            let currentProfiles = _.slice(groups[i], 0, limitStart);
            let otherProfiles = _.slice(
              groups[i],
              limitStart,
              limitStart + groupLimitDiff
            );
  
            groups[i] = currentProfiles;
            other = _.union(other, otherProfiles);
          }
  
          this.groupedStylists.push({
            profession: i,
            stylists: groups[i],
          });
        }
  
        if (other.length) {
          this.groupedStylists.push({
            profession: "",
            stylists: other,
          });
        }
      },
      onSearch() {
        this.stylists = [];
        this.groupedStylists = [];
        this.page = 1;
        this.searching = true;
        this.searchDropdownOpen = false;
        this.locationDropdownOpen = false;
        this.dateDropdownOpen = false;
  
        this.getFeaturedStylists(this.featuredNum, 0);
        this.getStylists(this.perPage, 0);
      },
      onSelectDate(date) {
        let formatted_date = moment(date).format("YYYY-MM-DD");
  
        if (formatted_date == this.search_date) {
          this.search_date = "";
          this.search_date_calendar = "";
          this.search_time = "";
          return;
        }
  
        this.search_date_calendar = date;
        this.search_date = moment(date).format("YYYY-MM-DD");
      },
      onSelectHour(hour) {
        this.search_time = hour;
      },
      onToggleSearchDropdown() {
        this.locationDropdownOpen = false;
        this.dateDropdownOpen = false;
        this.searchDropdownOpen = !this.searchDropdownOpen;
      },
      onSelectSearchItem(item) {
        this.search = item;
        this.onSearch();
      },
      onToggleLocationDropdown() {
        this.searchDropdownOpen = false;
        this.dateDropdownOpen = false;
        this.locationDropdownOpen = !this.locationDropdownOpen;
        this.$refs.search_city_zip_input.focus();
      },
      onToggleDateDropdown() {
        this.searchDropdownOpen = false;
        this.locationDropdownOpen = false;
        this.dateDropdownOpen = !this.dateDropdownOpen;
      },
      onGetCurrentLocation() {
        if (!navigator.geolocation) {
          return;
        }
  
        navigator.geolocation.getCurrentPosition((position) => {
          var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&key=' + process.env.VUE_APP_MAPS_API_KEY;
  
          this.$http.get(url, { noApiKey: true }).then(
            function (response) {
              if (!response.data) {
                return;
              }
  
              var city = "";
  
              response.body.results.forEach(function (el) {
                if (!el.address_components || city) {
                  return;
                }
  
                el.address_components.forEach(function (item) {
                  if (item.types.indexOf("locality") !== -1) {
                    city = item.long_name;
                    return;
                  }
                });
              });
  
              this.search_location = city;
              localStorage.setItem("current_city", city);
              this.onSearch();
            }.bind(this)
          );
        });
      },
      onFindLocation() {
        this.search_location = this.search_city_zip;
        this.search_city_zip = "";
        this.onSearch();
      },
      onSortSelected(sort) {
        this.order_by = sort;
        this.onSearch();
      },
      updateFilterLabels() {
        this.filter_labels = this.filters.professions.map((el) => {
          let prof = _.filter(this.professions, { id: el });
          return { id: el, name: prof[0].profession, type: "profession" };
        });
      },
      onRemoveFilter(filter) {
        this.filters.professions = _.remove(this.filters.professions, (el) => {
          return el != filter.id;
        });
        this.updateFilterLabels();
        this.onSearch();
      },
      onRatingFilter(rating) {
        this.search_rate = rating;
        this.onSearch();
      },
      onPriceFilter: _.debounce(function () {
        this.onSearch();
      }, 500),
      onRemoveSearchFilter(type) {
        switch (type) {
          case "rate":
            this.search_rate = "";
            break;
  
          case "price":
            this.filters.price = [this.filters.priceMin, this.filters.priceMax];
            break;
  
          case "search":
            this.search = "";
            break;
  
          case "location":
            localStorage.setItem("current_city", "");
            this.search_location = "";
            break;
  
          case "date":
            this.search_date = "";
            this.search_time = "";
            break;
        }
  
        this.onSearch();
      },
      getShareLink(network) {
        let link = "";
        let share = this.currentShareProfile;
  
        switch (network) {
          case "twitter":
            link =
              "https://twitter.com/intent/tweet?text=Stylist+Profile:+" +
              share.first_name +
              " " +
              share.last_name +
              " " +
              this.getProfileUrl(share);
            break;
  
          case "facebook":
            link =
              "https://www.facebook.com/share.php?quote=Stylist+Profile:+" +
              share.first_name +
              " " +
              share.last_name +
              "&u=" +
              this.getProfileUrl(share);
            break;
        }
  
        return link;
      },
      onSendContactEmail() {
        this.contactEmailMessage = "";
  
        this.$http
          .post(api.actions.send_contact_email, {
            email: this.contactEmailAddress,
            text: this.contactEmailText,
            app_id: this.generalOptions.app_id,
          })
          .then(
            function (response) {
              if (response.data.error == true) {
                return false;
              }
  
              this.contactEmailAddress = "";
              this.contactEmailText = "";
              this.contactEmailMessage = response.data.message;
            }.bind(this)
          );
      },
      preventSubmit(e) {
        e.preventDefault();
      },
      displayFeaturedSection() {
        return (
          this.featuredStylists.length && this.featuredStylists.length % 3 == 0
        );
      },
      modalProfileSelected(profile) {
        this.modalOpen(profile.profile_url);
      },
      shareProfileSelected(profile) {
        this.currentShareProfile = profile;
        $('#share-modal').modal('show');
      },
      modalOpen(profile_alias, service_id) {
        document.querySelector("body").classList = ["modal-open"];
        this.modalStylistAlias = profile_alias;
        this.modalStylistServiceId = service_id;
      },
      modalClosed() {
        document.querySelector("body").classList = [];
        this.modalStylistAlias = null;
        this.modalStylistServiceId = 0;
      },
    },
    components: {
      VueSlider,
      Rating,
      Datepicker,
      DownloadApp,
      RandomReview,
      ProfileListingItem,
      BookingModalNew,
    },
  };
  </script>
  