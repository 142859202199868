<template>
    <div v-if="!isShortLogin" class="footer">
        <div class="footer-inner-wrap">
            <div class="footer-inner">
                <div class="footer-inner-left">
                    <div class="business-icons">
                        <a target="_blank" href="https://www.ringmystylist.com/" class="rms-biz-link w-inline-block"
                            ><img src="../assets/images/rms-logo.png" alt=""
                        /></a>
                        <a target="_blank" href="https://www.ringmybarber.com/" class="rms-biz-link w-inline-block"
                            ><img src="../assets/images/rmb-logo.png" alt=""
                        /></a>
                        <a
                            target="_blank"
                            href="https://www.ringmyloctician.com/"
                            class="rms-biz-link rml-link w-inline-block"
                            ><img src="../assets/images/rml-logo.png" alt=""
                        /></a>
                        <a target="_blank" href="https://www.ringmygroomer.com/" class="rms-biz-link w-inline-block"
                            ><img src="../assets/images/IconRMG.png" alt=""
                        /></a>
                    </div>
                    <div class="app-links">
                        <a rel="noreferrer" :href="generalOptions.appstore_url" target="_blank">
                            <img
                                class="app-store"
                                src="../assets/images/app-store-icon.svg"
                                :alt="`Download ${generalOptions.app_name} App from App Store`"
                            />
                        </a>
                        <a rel="noreferrer" :href="generalOptions.googleplay_url" target="_blank">
                            <img
                                src="../assets/images/google-play-icon.svg"
                                :alt="`Download ${generalOptions.app_name} App from Google Play`"
                            />
                        </a>
                    </div>
                </div>
                <div class="footer-inner-right">
                    <p v-if="generalOptions.app_id == 1">
                        Appointment Booking Solution for Beauty Professionals, Salons, and Clients<br />
                        Ring My Stylist is available on mobiles, tablets, and computer browsers.
                    </p>
                    <p v-if="generalOptions.app_id == 2">
                        Appointment Booking Solution for Barbers, Barbershops, and Clients<br />
                        Ring My Barber is available on mobiles, tablets, and computer browsers.
                    </p>
                    <p v-if="generalOptions.app_id == 5">
                        Appointment Booking Solution for Locticians and Clients<br />
                        Ring My Locticians is available on mobiles, tablets, and computer browsers.
                    </p>

                    <ul class="bottom-nav-menu">
                        <li><a :href="generalOptions.terms_url" target="_blank">Terms</a></li>
                        <li><a :href="generalOptions.privacy_url" target="_blank">Privacy</a></li>
                        <li><a :href="generalOptions.refund_url" target="_blank">Refund</a></li>
                        <li><a :href="generalOptions.contact_url" target="_blank">Contact</a></li>
                        <li><a href="tel:+16198883886">+1 619-888-3886</a></li>
                    </ul>

                    <ul class="bottom-nav-menu-social">
                        <li>#{{ generalOptions.instagram }}</li>
                        <li><a href="https://twitter.com/ringmystylist" target="_blank">Twitter</a></li>
                        <li>
                            <a :href="`https://www.instagram.com/${generalOptions.instagram}`" target="_blank"
                                >Instagram</a
                            >
                        </li>
                        <li>
                            <a :href="`https://www.facebook.com/${generalOptions.instagram}`" target="_blank"
                                >Facebook</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="showCookieConsent" class="notification-bar">
            <div class="container">
                <div class="description-left">
                    <p>
                        We use cookies to make your experience of our websites better. By using and further navigating
                        this website you accept this.
                    </p>
                </div>
                <a v-on:click="acceptCookies" href="#" class="button-close">Accept and Close</a>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "../services/auth.js";
import eventBus from "../services/event_bus.js";
export default {
    name: "Footer",
    data: function () {
        return {
            year: null,
            isShortLogin: false,
            is_admin: false,
            showCookieConsent: false,
        };
    },
    mounted: function () {
        this.isShortLogin = this.$route.fullPath == "/login";
        this.$nextTick(function () {
            let current_datetime = new Date();
            this.year = current_datetime.getFullYear();
        });
        this.checkAdmin();
        eventBus.$on("user_profile_loaded", this.checkAdmin);
        eventBus.$on("user_logged_out", this.checkAdmin);
        eventBus.$on("admin_route_loaded", this.checkAdmin);

        var cookieConsent = this.getCookie("cookies_consent");
        this.showCookieConsent = cookieConsent ? false : true;
    },
    methods: {
        checkAdmin: function () {
            if (this.$route.path.indexOf("/app") !== -1 && auth.user) {
                this.is_admin = true;
            } else {
                this.is_admin = false;
            }
        },
        setCookie(name, value, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = "expires=" + d.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(";").shift();
        },
        acceptCookies(e) {
            e.preventDefault();
            this.setCookie("cookies_consent", 1, 365);
            this.showCookieConsent = false;
        },
    },
};
</script>
