<template>
    <div class="admin-login-component container">
        <div class="login-form-wrapper">
            <h4 class="login-box-title">Login</h4>
            <div class="login-form-inner row">
                <form v-if="!showPhoneLogin" class="col-12">
                    <div class="row my-3">
                        <div class="col-12">
                            <input
                                class="col-12 w-100 pl-3"
                                v-model="email"
                                v-on:keyup.enter="onLogin"
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                            />
                            <span class="input-stripe-border"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <input
                                class="w-100 pl-3"
                                v-model="password"
                                v-on:keyup.enter="onLogin"
                                type="password"
                                name="password"
                                placeholder="Password"
                                required
                            />
                            <span class="input-stripe-border"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <label
                            class="col-12 text-center d-flex align-items-center justify-content-center remember-me-wrap"
                        >
                            <input v-model="remember" type="checkbox" checked="checked" name="remember" value="1" />
                            <span class="ml-2">Keep Me Logged In</span>
                        </label>
                    </div>
                    <div class="row my-3">
                        <div class="col-12 text-center">
                            <button
                                class="login-button"
                                type="button"
                                v-on:click="
                                    onLogin();
                                    scrollToTop();
                                "
                            >
                                Login
                            </button>
                        </div>
                    </div>
                    <div class="row my-3">
                        <label
                            class="col-12 text-center d-flex align-items-center justify-content-center"
                        >
                            <strong>or</strong>
                        </label>
                    </div>
                    <div class="row my-3">
                        <div class="col-12 text-center">
                            <button
                                class="login-button"
                                type="button"
                                v-on:click="showPhoneLogin = true"
                            >
                                Login with Phone Number
                            </button>
                        </div>
                    </div>
                </form>
                <form v-if="showPhoneLogin" class="col-12">
                    <phone-login @back="showPhoneLogin=false"></phone-login>
                </form>
                <div class="col-12">
                    <div class="row my-3">
                        <div class="col-12 forgot-password-text text-center">
                            <br><br>
                            <router-link :to="{ path: '/app/forgot-password' }">{{
                                $ml.get("adminMenu.forgotPassword")
                            }}</router-link>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12 forgot-password-text text-center">
                            <span>Don't have an account yet? </span><br>
                            Click to 
                            <router-link :to="{ path: '/app/register' }">{{
                                $ml.get("adminMenu.register")
                            }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import phoneLogin from './phone_login.vue';

export default {
    name: "Account",
    data: function () {
        return {
            email: "",
            password: "",
            remember: 0,
            showPhoneLogin: false
        };
    },
    mounted: function () {
        if (auth.user) {
            this.$router.push("/app");
        }

        if (typeof this.$route.query.email != "undefined") {
            this.email = this.$route.query.email;
        }

        eventBus.$on("user_profile_loaded", this.onLoggedIn);
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded", this.onLoggedIn);
    },
    components: {
        phoneLogin
    },
    computed: {
        selectedStylist: {
            get: function () {
                return store.state.selectedStylist;
            },
            set: function (value) {
                store.commit("setSelectedStylist", value);
            },
        },
    },
    methods: {
        onLogin: function () {
            auth.login({ email: this.email, password: this.password, remember: this.remember }, true);
        },
        onLoggedIn: function () {
            this.$router.push("/app");
        },
        scrollToTop() {
            const duration = 800; // Scroll duration in milliseconds
            const start = window.scrollY;
            const startTime = "now" in window.performance ? performance.now() : new Date().getTime();

            const scrollToTop = (timestamp) => {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
                window.scrollTo(0, start + (0 - start) * easeInOutCubic(progress));

                if (elapsed < duration) {
                    requestAnimationFrame(scrollToTop);
                }
            };

            requestAnimationFrame(scrollToTop);
        },
    },
};
</script>
