var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed-menu",class:{
        'rms-app': _vm.generalOptions.app_id == 1,
        'rmb-app': _vm.generalOptions.app_id == 2,
        'rml-app': _vm.generalOptions.app_id == 5,
    },attrs:{"id":"app"}},[(!_vm.is_admin)?_c('Header'):_vm._e(),(_vm.is_admin)?_c('AdminHeader'):_vm._e(),(_vm.is_admin)?_c('Sidebar'):_vm._e(),_c('div',{staticClass:"container main-container full-width",class:{
            'has-side-menu': _vm.is_admin,
            'sidebar-collapsed': _vm.sidebarCollapse,
        }},[_c('div',{staticClass:"router-container"},[_c('router-view',{key:_vm.$route.fullPath})],1)]),_c('Footer',{class:{
            'admin-footer': _vm.is_admin,
            'sidebar-collapsed': _vm.sidebarCollapse,
        }})],1)}
var staticRenderFns = []

export { render, staticRenderFns }