<template>
  <div class="profile-listing-item">
    <div class="result-block">
      <div class="result-block-inner">
        <div class="profile-block">
          <div class="profile-avatar">
            <img
              v-if="stylist.avatar"
              :src="stylist.avatar"
              :alt="stylist.first_name + ' ' + stylist.last_name"
            />
            <img
              v-if="!stylist.avatar && generalOptions.app_id == 1"
              src="../assets/images/rms-logo.png"
              :alt="stylist.first_name + ' ' + stylist.last_name"
            />
            <img
              v-if="!stylist.avatar && generalOptions.app_id == 2"
              src="../assets/images/rmb-logo.png"
              :alt="stylist.first_name + ' ' + stylist.last_name"
            />
          </div>
          <div class="name-block">
            <span class="rating-wrap w-inline-block">
              <div class="rating-star-block">
                <Rating :rate="stylist.rate" />
              </div>
              <div class="review-count">({{ stylist.reviews_number }})</div>
            </span>
            <div class="name">
              <a :href="getProfileUrl(stylist)" target="_blank"
                >{{ stylist.first_name }} {{ stylist.last_name }}</a
              >
            </div>
            <div class="user-meta">
              <div v-if="stylist.profession.length > 0" class="title">
                {{ getProfessionsLabel(stylist.profession) }}
              </div>
              <div class="address">
                <div v-if="stylist.profile.address" class="address-line-1">
                  {{ stylist.address }}
                </div>
                <div v-if="stylist.profile.city" class="address-line-2">
                  {{ stylist.profile.city }}, {{ stylist.profile.state }}
                  {{ stylist.profile.zip }}
                </div>
              </div>
            </div>
          </div>
          <div class="share-menu-drop w-dropdown" role="menu" style="">
            <span
              v-on:click="stylist.show_menu = !stylist.show_menu"
              v-on:focusout="stylist.show_menu = false"
              class="share-menu-toggle w-dropdown-toggle"
              tabindex="0"
              style="outline: 0"
            >
              <span class="general-icon square-menu share-menu-icon"></span>
            </span>
            <nav
              class="share-menu-drop-list w-dropdown-list"
              :class="{ open: stylist.show_menu }"
            >
              <span
                v-on:mousedown="onMenuItemclick(stylist, 'like')"
                :class="{ liked: profileLikes.indexOf(stylist.id) !== -1 }"
                class="share-menu-drop-link w-inline-block"
              >
                <span class="general-icon heart share-link-icon"></span>
                <div>{{ $ml.get("search.listing.like") }}</div>
              </span>
              <span
                v-on:mousedown="onMenuItemclick(stylist, 'rate')"
                class="share-menu-drop-link w-inline-block"
              >
                <span class="general-icon star share-link-icon"></span>
                <div>{{ $ml.get("search.listing.rateIt") }}</div>
              </span>
              <span
                v-on:mousedown="onMenuItemclick(stylist, 'share')"
                class="share-menu-drop-link w-inline-block"
              >
                <span class="general-icon share share-link-icon"></span>
                <div>{{ $ml.get("search.listing.share") }}</div>
              </span>
            </nav>
          </div>
        </div>
        <div class="shop-url-wrap">
          <a v-if="stylist.salon_url" :href="stylist.salon_url">{{
            stylist.salon_name
          }}</a>
        </div>
        <div class="image-gallery-wrap">
          <div v-if="stylist.portfolio_images.length" class="image-gallery">
            <vue-flux
              :images="stylist.portfolio_images"
              :transitions="vfTransitions"
              :ref="'image_slider_featured_' + index"
            >
              <template v-slot:preloader>
                <flux-preloader />
              </template>
            </vue-flux>
            <span
              class="carousel-arrow arrow-prev"
              v-on:click="onCarouselPrev('_featured_' + index)"
            >
              <i class="fa fa-angle-left" aria-hidden="true"></i>
            </span>
            <span
              class="carousel-arrow arrow-next"
              v-on:click="onCarouselNext('_featured_' + index)"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </span>
            <!-- <div v-on:click="openImageLightbox('image_lightbox_featured_'+index)" class="lightbox-link-text">{{ $ml.get('search.listing.portfolio') }}</div> -->
          </div>
        </div>
        <div
          v-if="featured && stylist.random_review && stylist.random_review.rate"
          class="inline-profile-review"
        >
          <div class="inline-review-rate">
            <Rating :rate="stylist.random_review.rate" />
            <span class="inline-review-title">{{
              stylist.random_review.title
            }}</span>
          </div>
          <div class="inline-review-details">
            {{ stylist.random_review.details }}
          </div>
          <div class="inline-review-from">
            {{ stylist.random_review.first_name }}
            {{ stylist.random_review.last_name.substr(0, 1) }}. |
            {{ getFormattedDate(stylist.random_review.created_at) }}
          </div>
        </div>
        <LightBox
          v-if="stylist.lightbox_images.length"
          :images="stylist.lightbox_images"
          :showLightBox="false"
          rel="slider"
          :ref="'image_lightbox_featured_' + index"
        ></LightBox>
      </div>
      <div class="w-layout-grid thumb-action-grid">
        <a
          :href="getProfileUrl(stylist)"
          target="_blank"
          class="ghost-button extra-small w-button"
          >{{ $ml.get("search.listing.viewProfile") }}</a
        >
        <span
          v-if="stylist.accept_website_booking == 1"
          v-on:click="profileSelected(stylist)"
          class="button w-button"
          >{{ $ml.get("search.listing.bookNow") }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import auth from "./../services/auth.js";
import api from "./../services/api_endpoints.js";
import helper from "./../services/general.js";
import Rating from "./rating";
import message from "./../services/messages.js";
import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import moment from "moment";
import {
  VueFlux,
  FluxPreloader,
} from "vue-flux";

export default {
  name: "ProfileListingItem",
  emits: ["shareProfileSelected"],
  props: ["stylist", "index", "featured"],
  data: function () {
    return {
      user: null,
      vfTransitions: ["fade"],
      profileLikes: [],
    };
  },
  mounted: function () {
    this.user = auth.user;
    this.profileLikes = this.profileLikes = helper.methods.getProfileLikes();
  },
  methods: {
    getProfessionsLabel(professions) {
      return professions
        .map(function (el) {
          return el.profession;
        })
        .join(", ");
    },
    onMenuItemclick(stylist, item) {
      if (item == "rate") {
        window.open(this.getProfileUrl(stylist) + "#reviews", "_blank").focus();
      }

      if (item == "like") {
        if (this.profileLikes.indexOf(stylist.id) != -1) {
          return;
        }

        this.$http
          .post(api.actions.update_profile_likes, { userid: stylist.id })
          .then(
            function (response) {
              if (response.data.error == true) {
                return false;
              }

              let likes = helper.methods.getCookie("profile_likes");
              likes += likes.length ? "," + stylist.id : stylist.id;
              helper.methods.setCookie("profile_likes", likes, 365);

              this.profileLikes = helper.methods.getProfileLikes();
              message.successMessage(message.GLOBAL_MESSAGES.PROFILE_LIKE);
              stylist.liked_profile = true;
            }.bind(this)
          );
      }

      if (item == "share") {
        this.$emit("shareProfileSelected", stylist);
      }
    },
    openImageLightbox(slider_class) {
      this.$refs[slider_class].showImage(0);
    },
    getProfileUrl(stylist) {
      return this.generalOptions.profile_url + stylist.profile_url;
    },
    onCarouselNext(index) {
      let slider = this.$refs["image_slider" + index];

      if (
        slider.Images.imgs.length > 1 &&
        index == slider.Images.imgs.length - 1
      ) {
        return;
      }

      slider.show(slider.Images.$current.index + 1);
    },
    onCarouselPrev(index) {
      let slider = this.$refs["image_slider" + index];

      if (slider.Images.$current.index == 0) {
        return;
      }

      slider.show(slider.Images.$current.index - 1);
    },
    profileSelected(profile) {
      this.$emit("modalProfileSelected", profile);
    },
    getFormattedDate(date) {
      return moment(date).format("MMMM DD, YYYY");
    },
  },
  components: {
    Rating,
    VueFlux,
    FluxPreloader,
    LightBox,
  },
};
</script>
