<template>
    <div class="members-component text-left">
        <h3 class="section-title">Members</h3>
        <div class="members">
            <div class="member" v-for="(member, index) in members" v-bind:key="index">
                <div class="member-header">
                    <div class="member-avatar" v-if="member.profile.avatar">
                        <img v-bind:src="member.profile.avatar">
                    </div>
                    <h5>{{ member.user.first_name }} {{ member.user.last_name }}</h5>
                </div>
                <div class="member-details">
                    <div class="working-hours">
                        <h6 class="subsection-title">Working Hours</h6>
                        <ul class="hours">
                            <li v-for="(day, i) in days" v-bind:key="i">
                                <span class="day-label">{{ day }}</span>
                                <span v-if="member.work_settings[day]!=1">OFF</span>
                                <span v-if="member.work_settings[day]==1">{{ member.work_settings[day+'_from'] }} - {{ member.work_settings[day+'_to'] }}</span>
                            </li>
                        </ul>
                    </div>

                    <div class="user-services">
                        <h6 class="subsection-title">Services</h6>
                        <div class="service-categories">
                            <div v-for="(category, i) in member.service_categories" v-bind:key="i">
                                <div v-if="member.services[category.id]">
                                <span class="category-name">{{ category.name }}</span>
                                    <ul class="services">
                                        <li v-for="(service, i) in member.services[category.id]" v-bind:key="i">
                                            <span class="day-label">{{ service.name }}</span>
                                            <span>${{ service.price }} and up, {{ service.duration }} minutes</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from './../services/api_endpoints.js';
import auth from './../services/auth.js';

export default {
    name: 'Members',
    data: function(){
        return {
            members: [],
            user: null,
            days: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
        };
    },
    mounted: function () {
        this.$nextTick(function () {
            this.user = auth.user;
            this.getGroupsMembers();
        });
    },
    beforeDestroy () {
    },
    methods: {
        getGroupsMembers: function() {
            this.$http.get(api.actions.get_stylist_group_members + process.env.VUE_APP_GROUP_ID).then(function (response) {
                if(typeof response.data.members!='undefined') {
                    this.members = response.data.members;
                }
            }.bind(this), function () {
                return false;
            });
        },
    },
    components: {
    },
}
</script>

<style scoped>
.section-title {
    margin-bottom: 25px;
}
.subsection-title {
    font-weight: bold;
}
.member {
    padding: 20px 0;
    border-bottom: solid 1px #e8e8e8;
}
.member-avatar {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}
.member-avatar img {
    min-width: 100%;
    max-height: 100%;
}
.member-header {
    display: flex;
    align-items: center;
}
.member-details {
    padding: 30px 20px;
}
ul.hours {
    list-style: none;
    padding: 0;
    margin: 0;
}
.day-label {
    min-width: 200px;
    display: inline-block;
}
.member-details {
    display: flex;
}
.working-hours {
    margin-right: 50px;
}

</style>
