<template>
    <div class="optimize-scheduling">
        <div class="scheduling-container">
            <div class="heading-container">
                <h3>
                    {{ $ml.get("optimizeScheduling.title") }}
                </h3>
                <!--Team select-->
                <div
                    v-if="loading && adminDetails.group_owner == 1 && adminDetails.group_active == 1"
                    class="select-input-wrap"
                >
                    <label
                        class="stylist-label"
                        for="stylist-select"
                        :class="generalOptions.app_id === 5 ? 'loctician' : ''"
                        >Select
                        {{
                            generalOptions.app_id === 1
                                ? "Stylist"
                                : generalOptions.app_id === 2
                                ? "Barber"
                                : generalOptions.app_id === 5
                                ? "Loctician"
                                : ""
                        }}</label
                    >

                    <select
                        id="stylist-select"
                        v-model="for_stylist_id"
                        v-on:change="onStylistChange"
                        class="selectInput selectInputSmall"
                    >
                        <option
                            v-for="(member, index) in groupMembers"
                            v-bind:key="index"
                            v-bind:value="member.user.id"
                            v-bind:disabled="member.active != 1"
                        >
                            {{ member.user.first_name }} {{ member.user.last_name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="scheduling-wrapper section-wrap">
                <h5>{{ $ml.get("optimizeScheduling.subTitle") }}</h5>
                <p>
                    Enable this option to streamline your scheduling by allowing only adjacent booking slots before or
                    after existing appointments.
                </p>
                <p>This ensures your day is efficiently filled without leaving unusable gaps.</p>
                <p>
                    Ideal for services that don't align perfectly with your standard booking slot duration, it prevents
                    the scenario where slots remain unbooked due to misaligned service times.
                </p>
                <p>Activate to keep your schedule compact and maximize availability.</p>
                <div class="activate-scheduling">
                    <label for="active-scheduling">Activate Scheduling Optimization?</label>
                    <select
                        v-model="optimize_scheduling"
                        @change="saveOptimizeScheduling"
                        id="active-scheduling"
                        class="selectInput selectInputExtraSmall"
                    >
                        <option value="0">{{ $ml.get("general.no") }}</option>
                        <option value="1">{{ $ml.get("general.yes") }}</option>
                    </select>
                </div>
                <!--Loader-->
                <div v-if="firstLoad" class="loader-wrap">
                    <div class="loader">
                        <i class="fa fa-3x fa-spinner fa-spin"></i>
                    </div>
                </div>
                <div class="scenario">
                    <!--Scenario with Scheduling Optimization Inactive-->
                    <div class="section-wrap">
                        <h5>Scenario with Scheduling Optimization Inactive:</h5>
                        <p>
                            Imagine you start at 9:00, your slots are 30 minutes long, and you have one service that is
                            1 hour long.
                        </p>
                        <p>The first client may book from 9:30 to 10:30 (1 hour).</p>
                        <p>
                            The second client may book from 11:00 to 12:00. In this scenario, you will lose 30 minutes
                            from 9:00 to 9:30 and 30 minutes from 10:30 to 11:00.
                        </p>
                    </div>
                    <!--Scenario with Scheduling Optimization Active-->
                    <div class="section-wrap">
                        <h5>Scenario with Scheduling Optimization Active:</h5>
                        <p>
                            Imagine you start at 9:00, your slots are 30 minutes long, and you have one service that is
                            1 hour long.
                        </p>
                        <p>The first client will only see the available slot from 9:00 to 10:00 and will book it.</p>
                        <p>
                            The second client will only see the next adjacent slot available, from 10:00 to 11:00, and
                            will book.
                        </p>
                        <p>And so on. There's no way for you to have gaps between appointments.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import messages from "./../../services/messages.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";

export default {
    name: "AdminNotifications",
    data: function () {
        return {
            work_settings: {},
            optimize_scheduling: 0,
            for_stylist_id: 0,
            firstLoad: false,
            loading: false,
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.for_stylist_id = auth.user.id;
        this.getWorkSettings();

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
    },
    methods: {
        saveOptimizeScheduling: function () {
            if (this.for_stylist_id != auth.user.id) {
                this.work_settings.group_user_id = this.for_stylist_id;
            }

            this.work_settings.optimize_scheduling = parseInt(this.optimize_scheduling);

            this.$http.post(api.actions.save_work_settings, this.work_settings).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);

                        this.getWorkSettings();
                    }
                }.bind(this)
            );
        },

        getWorkSettings: function () {
            this.firstLoad = true;
            let stylist_id = this.for_stylist_id != auth.user.id ? "?user_id=" + this.for_stylist_id : "";

            let getAllSettings = "getAllSettings=1";
            if (stylist_id != "") {
                getAllSettings = "&" + getAllSettings;
            } else {
                getAllSettings = "?" + getAllSettings;
            }

            this.$http.get(api.actions.get_work_settings + stylist_id + getAllSettings).then(
                function (response) {
                    this.firstLoad = false;

                    if (typeof response.data.work_settings != "undefined") {
                        this.work_settings = response.data.work_settings;

                        this.optimize_scheduling = this.work_settings.optimize_scheduling;
                    }
                }.bind(this)
            );
        },
        onStylistChange() {
            this.getWorkSettings();
        },
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            }
        },
        getGroupsMembers: function (group_id) {
            this.serviceCategories = [];
            this.services = {};

            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                        this.loading = true;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
    },
    components: {},
};
</script>
