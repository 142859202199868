<template>
    <!--Fees and policies-->
    <div class="admin-policy" v-if="user && user.role === 'stylist'">
        <div class="policy-outter-wrapper">
            <div class="fees-and-policies">
                <h3 class="fees-title">Fees & Policies</h3>
            </div>
            <div class="section-wrap policy-outter-wrapper">
                <!--Deposits Section-->
                <div v-if="user" class="policy-main-container" id="deposits">
                    <!--Deposits content -->
                    <div class="policy-settings-wrap section-wrap">
                        <!--Deposits Header-->
                        <div class="header-container">
                            <h4>{{ $ml.get("policy.deposits") }}</h4>
                            <div class="header-paragraph">
                                <p>
                                    {{ $ml.get("policy.depositRule") }}
                                </p>
                                <p>{{ $ml.get("policy.clients") }}</p>
                            </div>
                        </div>
                        <div class="section-wrap highlighted-box">
                            <div class="policy-checkbox">
                                <h5>{{ $ml.get("policy.deposits") }}</h5>
                                <div>
                                    <label class="switch">
                                        <input type="checkbox" v-model="policy.deposit" value="1" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div v-if="policy.deposit" class="policy-inputs-wrap section-wrap">
                                <div class="policy-input">
                                    <input
                                        v-model="policy.deposit_type"
                                        type="radio"
                                        name="deposit_type"
                                        id="deposit_type_percentage"
                                        value="percentage"
                                        class="input-width"
                                    />
                                    <select
                                        v-model="deposit_percentage"
                                        name="deposit_fee"
                                        class="deposit-fee-input selectInput selectInputSmall"
                                    >
                                        <option v-for="i in percentageDropdown" :key="`item-${i}`" :value="i">
                                            {{ i }}%
                                        </option>
                                    </select>
                                    <span>{{ $ml.get("policy.nonRefundableDeposit") }}</span>
                                </div>
                                <div class="policy-input">
                                    <input
                                        v-model="policy.deposit_type"
                                        type="radio"
                                        name="deposit_type"
                                        id="deposit_type_fixed"
                                        value="fixed"
                                        class="input-width"
                                    />
                                    <input
                                        type="number"
                                        v-model="deposit_fixed"
                                        name="deposit_fee"
                                        class="deposit-fee-input text-field text-field-small"
                                    />
                                    <span>{{ $ml.get("policy.nonRefundableDeposit") }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="policy.deposit" class="section-wrap highlighted-box">
                            <h5>{{ $ml.get("policy.depositPolicy") }}</h5>
                            <div class="textarea-wrap">
                                <textarea
                                    v-model="policy.deposit_policy"
                                    class="policy-textarea text-field"
                                    maxlength="4000"
                                    placeholder="Write deposit policy"
                                ></textarea>
                                <div class="length-limit-label">{{ policy.deposit_policy.length }}/4000</div>
                            </div>
                        </div>
                        <div class="save-policy">
                            <button v-on:click="saveStylistPolicy()" class="primary-btn extra-small mobile">
                                {{ $ml.get("policy.save") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--Cancellation No Show Section-->
                <div v-if="user" class="policy-main-container" id="cancellation">
                    <div class="policy-settings-wrap section-wrap">
                        <div class="header-container">
                            <h4>{{ $ml.get("adminMenu.cancellationNoshow") }}</h4>
                            <div class="header-paragraph">
                                <p>{{ $ml.get("policy.cancelationRules") }}</p>
                            </div>
                        </div>
                        <div class="section-wrap highlighted-box">
                            <div class="policy-checkbox">
                                <h5>{{ $ml.get("adminMenu.cancellationNoshow") }}</h5>
                                <div>
                                    <label class="switch">
                                        <input type="checkbox" v-model="policy.cancel" value="1" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="policy-inner-wrap">
                                <div v-if="policy.cancel" class="policy-inputs-wrap section-wrap">
                                    <div class="policy-no-show-input">
                                        <span>{{ $ml.get("policy.clientCanCancel") }}</span>
                                        <select
                                            v-model="policy.cancel_advance_hours"
                                            name="cancel_advance_hours"
                                            class="deposit-fee-input selectInput selectInputSmall"
                                        >
                                            <option value="12">12</option>
                                            <option value="24">24</option>
                                            <option value="48">48</option>
                                        </select>
                                        <span>{{ $ml.get("policy.hoursInAdvance") }}</span>
                                    </div>
                                </div>
                                <div v-if="policy.cancel" class="policy-inputs-wrap section-wrap">
                                    <h5>{{ $ml.get("policy.fees") }}</h5>
                                    <div class="policy-no-show-input">
                                        <select
                                            v-model="policy.cancel_fee"
                                            name="cancel_fee"
                                            class="deposit-fee-input selectInput selectInputSmall"
                                        >
                                            <option v-for="i in percentageDropdown" :key="`item-${i}`" :value="i">
                                                {{ i }}%
                                            </option>
                                        </select>
                                        <span
                                            >{{ $ml.get("policy.cancelAfter") }} {{ policy.cancel_advance_hours }}
                                            {{ $ml.get("policy.hours") }}</span
                                        >
                                    </div>
                                    <div class="policy-no-show-input">
                                        <select
                                            v-model="policy.noshow_fee"
                                            name="noshow_fee"
                                            class="deposit-fee-input selectInput selectInputSmall"
                                        >
                                            <option v-for="i in percentageDropdown" :key="`item-${i}`" :value="i">
                                                {{ i }}%
                                            </option>
                                        </select>
                                        <span>{{ $ml.get("policy.noShow") }}</span>
                                    </div>
                                </div>
                                <div v-if="policy.cancel" class="section-wrap">
                                    <h5>{{ $ml.get("policy.cancellationPolicy") }}</h5>
                                    <div class="textarea-wrap">
                                        <textarea
                                            v-model="policy.cancel_policy"
                                            class="policy-textarea text-field"
                                            placeholder="Write cancellation policy"
                                            maxlength="4000"
                                        ></textarea>
                                        <div class="length-limit-label">{{ policy.cancel_policy.length }}/4000</div>
                                    </div>
                                </div>
                                <div v-if="policy.cancel" class="section-wrap policy-textarea-wrap" id="being-late">
                                    <div class="policy-checkbox">
                                        <h5>{{ $ml.get("policy.beingLate") }}</h5>
                                    </div>
                                    <div class="policy-being-late-wrap">
                                        <label class="switch">
                                            <input type="checkbox" v-model="policy.being_late" value="1" />
                                            <span class="slider round"></span>
                                        </label>
                                        <div class="policy-late-inputs">
                                            <div class="policy-late">
                                                <span class="item-label">{{ $ml.get("policy.late") }}</span>
                                                <input
                                                    v-model="policy.late_minutes"
                                                    type="number"
                                                    name="late_minutes"
                                                    class="fee-input text-field text-field-small"
                                                />
                                                {{ $ml.get("policy.min$") }}
                                                <input
                                                    v-model="policy.late_fee"
                                                    type="number"
                                                    name="late_fee"
                                                    class="fee-input text-field text-field-small"
                                                />
                                                {{ $ml.get("policy.lateFee") }}
                                            </div>
                                            <div class="policy-late">
                                                <span class="item-label">{{ $ml.get("policy.after") }}</span>

                                                <input
                                                    v-model="policy.late_minutes_cancel"
                                                    type="number"
                                                    name="late_minutes_cancel"
                                                    class="fee-input text-field text-field-small"
                                                />
                                                {{ $ml.get("policy.minCancelledNoExeptions") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="save-policy">
                            <button v-on:click="saveStylistPolicy()" class="primary-btn extra-small mobile">
                                {{ $ml.get("policy.save") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--Reschedule Section-->
                <div v-if="user" class="policy-main-container" id="reschedule">
                    <div class="policy-settings-wrap section-wrap">
                        <div class="header-container">
                            <h4>{{ $ml.get("adminMenu.reschedule") }}</h4>
                            <div class="header-paragraph">
                                <p>{{ $ml.get("policy.rescheduleRules") }}</p>
                            </div>
                        </div>
                        <div class="section-wrap highlighted-box">
                            <div class="policy-checkbox">
                                <h5>{{ $ml.get("adminMenu.reschedule") }}</h5>
                                <div>
                                    <label class="switch">
                                        <input type="checkbox" v-model="policy.reschedule" value="1" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="policy-inner-wrap">
                                <div v-if="policy.reschedule" class="policy-inputs-wrap highlighted-box section-wrap">
                                    <div class="policy-no-show-input">
                                        <span>{{ $ml.get("policy.clientCanReschedule") }}</span>
                                        <select
                                            v-model="policy.reschedule_advance_hours"
                                            name="reschedule_advance_hours"
                                            class="deposit-fee-input selectInput selectInputSmall"
                                        >
                                            <option value="12">12</option>
                                            <option value="24">24</option>
                                            <option value="48">48</option>
                                        </select>
                                        <span>{{ $ml.get("policy.hoursInAdvance") }}</span>
                                    </div>
                                </div>
                                <div v-if="policy.reschedule" class="policy-inputs-wrap highlighted-box section-wrap">
                                    <h5>{{ $ml.get("policy.fees") }}</h5>
                                    <div class="policy-no-show-input">
                                        <select
                                            v-model="policy.reschedule_fee"
                                            name="reschedule_fee"
                                            class="deposit-fee-input selectInput selectInputSmall"
                                        >
                                            <option v-for="i in percentageDropdown" :key="`item-${i}`" :value="i">
                                                {{ i }}%
                                            </option>
                                        </select>
                                        <span
                                            >{{ $ml.get("policy.rescheduleAfter") }}
                                            {{ policy.reschedule_advance_hours }}
                                            {{ $ml.get("policy.hours") }}</span
                                        >
                                    </div>
                                </div>
                                <div v-if="policy.reschedule" class="section-wrap highlighted-box">
                                    <h5>{{ $ml.get("policy.reschedulePolicy") }}</h5>
                                    <div class="textarea-wrap">
                                        <textarea
                                            v-model="policy.reschedule_policy"
                                            class="policy-textarea text-field"
                                            placeholder="Write reschedule policy"
                                            maxlength="4000"
                                        ></textarea>
                                        <div class="length-limit-label">
                                            {{ policy.reschedule_policy ? policy.reschedule_policy.length : 0 }}/4000
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="save-policy">
                            <button v-on:click="saveStylistPolicy()" class="primary-btn extra-small mobile">
                                {{ $ml.get("policy.save") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--Age Limit Section-->
                <div v-if="user" class="policy-main-container">
                    <div class="policy-settings-wrap section-wrap">
                        <div class="header-container">
                            <h4>{{ $ml.get("adminMenu.ageLimit") }}</h4>
                        </div>
                        <div class="section-wrap highlighted-box">
                            <div class="policy-age-input">
                                <span class="item-label">{{ $ml.get("policy.onlyAges") }}</span>
                                <input
                                    v-model="policy.age_limit"
                                    type="number"
                                    name="age_limit"
                                    class="fee-input text-field"
                                />
                                {{ $ml.get("policy.andUpOnlyAreAllowed") }}
                            </div>
                        </div>
                        <div class="save-policy">
                            <button v-on:click="saveStylistPolicy()" class="primary-btn extra-small mobile">
                                {{ $ml.get("policy.save") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--Policy Summary Section-->
                <div v-if="user" class="policy-main-container">
                    <div class="policy-settings-wrap section-wrap">
                        <div class="header-container">
                            <h3>{{ $ml.get("adminMenu.policySummary") }}</h3>
                            <div class="header-paragraph">
                                <p>{{ $ml.get("policy.thisIsASummary") }}</p>
                            </div>
                        </div>
                        <!--Deposits Summary-->
                        <div
                            v-if="!policy.deposit && !policy.cancel && !policy.reschedule"
                            class="section-wrap highlighted-box"
                        >
                            <h5>{{ $ml.get("policy.isEnable") }}</h5>
                        </div>
                        <div class="section-wrap highlighted-box" v-if="policy.deposit">
                            <div class="policy-checkbox">
                                <h5>{{ $ml.get("adminMenu.deposits") }}</h5>
                                <div>
                                    <span
                                        @click="scrollToMethod('#deposits')"
                                        class="edit-link primary-btn extra-small mobile"
                                        >{{ $ml.get("policy.edit") }}</span
                                    >
                                </div>
                            </div>
                            <div v-if="policy.deposit" class="policy-summary-wrap">
                                <p>
                                    {{ policy.deposit_fee }}<span v-if="policy.deposit_type == 'percentage'">%</span>
                                    {{ $ml.get("policy.nonRefundableDeposit") }}
                                </p>
                                <div v-if="policy.deposit_policy" class="policy-wrap">
                                    <p>{{ $ml.get("policy.policy") }}</p>
                                    <p class="section-wrap policy-summ">
                                        <text-toggle :text="policy.deposit_policy" stringLimit="250"></text-toggle>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!--Cancellation Summary-->
                        <div class="section-wrap highlighted-box" v-if="policy.cancel">
                            <div class="policy-checkbox">
                                <h5>{{ $ml.get("adminMenu.cancellation") }}</h5>
                                <div>
                                    <span
                                        @click="scrollToMethod('#cancellation')"
                                        class="edit-link primary-btn extra-small mobile"
                                        >{{ $ml.get("policy.edit") }}</span
                                    >
                                </div>
                            </div>
                            <div v-if="policy.cancel" class="policy-summary-wrap">
                                <p>
                                    {{ $ml.get("policy.canCancel") }} {{ policy.cancel_advance_hours }}
                                    {{ $ml.get("policy.hoursInAdvance") }}
                                </p>
                                <p>
                                    {{ policy.cancel_fee }}% {{ $ml.get("policy.feesAfter") }}
                                    {{ policy.cancel_advance_hours }}
                                    {{ $ml.get("policy.hours") }}
                                </p>
                                <p>{{ policy.noshow_fee }}% {{ $ml.get("policy.feesForNoShow") }}</p>
                                <div v-if="policy.cancel_policy" class="policy-wrap">
                                    <p>{{ $ml.get("policy.policy") }}</p>
                                    <p class="section-wrap">
                                        <text-toggle :text="policy.cancel_policy" stringLimit="250"></text-toggle>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!--Reschedule-->
                        <div class="section-wrap highlighted-box" v-if="policy.reschedule">
                            <div class="policy-checkbox">
                                <h5>{{ $ml.get("policy.reschedule") }}</h5>
                                <div>
                                    <span
                                        @click="scrollToMethod('#reschedule')"
                                        class="edit-link primary-btn extra-small mobile"
                                        >{{ $ml.get("policy.edit") }}</span
                                    >
                                </div>
                            </div>
                            <div class="policy-summary-wrap">
                                <p>
                                    {{ $ml.get("policy.clientCanReschedule") }} {{ policy.reschedule_advance_hours }}
                                    {{ $ml.get("policy.hoursInAdvance") }}
                                </p>
                                <p>After that is a {{ policy.reschedule_fee }}% charge</p>
                                <div v-if="policy.reschedule_policy" class="policy-wrap">
                                    <p>{{ $ml.get("policy.policy") }}</p>
                                    <p class="section-wrap">
                                        <text-toggle :text="policy.reschedule_policy" stringLimit="250"></text-toggle>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!--Being Late-->
                        <div class="section-wrap highlighted-box" v-if="policy.cancel && policy.being_late">
                            <div class="policy-checkbox">
                                <h5>{{ $ml.get("policy.beingLate") }}</h5>
                                <div>
                                    <span
                                        @click="scrollToMethod('#being-late')"
                                        class="edit-link primary-btn extra-small mobile"
                                        >{{ $ml.get("policy.edit") }}</span
                                    >
                                </div>
                            </div>
                            <div v-if="policy.cancel" class="policy-summary-wrap">
                                <p v-if="policy.being_late">
                                    {{ $ml.get("policy.late") }} {{ policy.late_minutes }} min ${{ policy.late_fee }}
                                    {{ $ml.get("policy.lateFee") }}, {{ $ml.get("policy.after") }}
                                    {{ policy.late_minutes_cancel }}
                                    {{ $ml.get("policy.minCancelledNoExeptions") }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";
import TextToggle from "./text_toggle.vue";

export default {
    name: "Policy",
    props: ["sections"],
    data: function () {
        return {
            user: null,
            message: "",
            policy: {
                deposit: 0,
                deposit_type: "",
                deposit_fee: 0,
                deposit_policy: "",
                cancel: 0,
                cancel_advance_hours: 0,
                cancel_fee: 0,
                noshow_fee: 0,
                cancel_policy: "",
                being_late: 0,
                late_minutes: 0,
                late_minutes_cancel: 0,
                late_fee: 0,
                type: "",
                age_limit: 0,
                reschedule: 0,
                reschedule_advance_hours: 0,
                reschedule_fee: 0,
                reschedule_policy: "",
            },
            percentageDropdown: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
            deposit_percentage: 0,
            deposit_fixed: 0,
            loaded: false,
            loading: false,
        };
    },
    mounted: function () {
        this.user = auth.user;
        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        stylistPolicy: {
            get: function () {
                return store.state.stylistPolicy;
            },
            set: function (value) {
                store.commit("setStylistPolicy", value);
            },
        },
    },
    methods: {
        scrollToMethod(id) {
            const targetElement = document.querySelector(id);

            if (targetElement) {
                const scrollPosition = targetElement.offsetTop - window.innerHeight / 2;
                window.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth",
                });
            }
        },

        onAdminDetailsLoad: function () {
            this.getStylistPolicy();
        },
        getStylistPolicy: function () {
            this.loaded = false;
            this.$http.get(api.actions.get_stylist_policy).then(
                function (response) {
                    this.loaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.policy != "undefined"
                    ) {
                        for (let i in this.policy) {
                            if (typeof response.body.policy[i] != "undefined") {
                                this.policy[i] = response.body.policy[i];
                            }

                            if (this.policy.deposit_type == "fixed") {
                                this.deposit_fixed = this.policy.deposit_fee;
                            } else {
                                this.deposit_percentage = this.policy.deposit_fee;
                            }

                            this.stylistPolicy = response.body.policy;
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        saveStylistPolicy: function () {
            if (this.policy.deposit_type == "fixed") {
                this.policy.deposit_fee = this.deposit_fixed;
            } else {
                this.policy.deposit_fee = this.deposit_percentage;
            }

            for (let i in this.policy) {
                if (this.policy[i] === false) {
                    this.policy[i] = 0;
                }
            }

            this.$http.post(api.actions.save_stylist_policy, this.policy).then(
                function (response) {
                    if (response.data.error) {
                        return;
                    }
                    messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                    this.getStylistPolicy();
                    eventBus.$emit("reload_user_details");
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
    },
    components: { TextToggle },
};
</script>
