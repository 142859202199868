<template>
    <div class="admin-marketing">
        <!--Special Offers Section-->
        <div v-if="isSectionVisible('special-offers')" class="marketing-main-container">
            <div class="marketing-wrapper">
                <div class="marketing-heading">
                    <h3>{{ $ml.get("marketing.specialOffers") }}</h3>
                </div>
                <!--Special offers content container-->
                <div class="marketing-content section-wrap">
                    <!--Show offer input toggle container-->
                    <div class="marketing-input-toggle">
                        <label for="show-offer" class="show-offer">
                            {{ $ml.get("marketing.showOffer") }}
                        </label>
                        <div>
                            <label class="switch">
                                <input
                                    id="show-offer"
                                    type="checkbox"
                                    v-model="showOffer"
                                    v-on:change="showOfferToggle"
                                />
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <!--Write offer content container-->
                    <div class="marketing-content-offer highlighted-box section-wrap" v-if="showOffer">
                        <div v-if="showOffer" class="write-offer-wrap">
                            <!--Button links row-->
                            <div class="btns-wrap">
                                <span v-if="!writeOffer" class="main-text-link" v-on:click="toggleWriteOffer">
                                    <span v-if="data.offer.length == 0">{{ $ml.get("marketing.offerWrite") }}</span>
                                    <span v-if="data.offer.length > 0">{{ $ml.get("marketing.editOffer") }}</span>
                                </span>
                                <span v-if="writeOffer" class="main-text-link" v-on:click="toggleWriteOffer">
                                    {{ $ml.get("marketing.cancel") }}
                                </span>
                            </div>
                            <!--Textarea row-->
                            <div class="marketing-textarea-wrap">
                                <div class="textarea-text" v-if="!writeOffer">
                                    {{ data.offer }}
                                </div>
                                <div v-if="writeOffer" class="textarea-wrap">
                                    <div>
                                        <textarea
                                            placeholder="Write your special offer"
                                            class="text-field"
                                            rows="6"
                                            maxlength="140"
                                            v-on:keyup="countCharOffer()"
                                            v-model="specialOffer.text"
                                        ></textarea>
                                    </div>
                                    <div class="counter-offer">{{ counter.offer }}/140</div>
                                </div>
                            </div>
                            <!--Restrict row-->
                            <div v-if="showOffer && writeOffer" class="restrict-offer-wrapper">
                                <div class="restrict-offer-wrap">
                                    <div class="restrict-label">
                                        <label for="restrict-user">{{ $ml.get("marketing.restrict") }}:</label>
                                    </div>
                                    <div class="">
                                        <input
                                            id="restrict-user"
                                            type="text"
                                            class="text-field"
                                            placeholder="zipcode"
                                            aria-label="Title"
                                            maxlength="10"
                                            v-model="specialOffer.zip"
                                        />
                                    </div>
                                </div>
                                <div class="restrict-toggle-wrap">
                                    <label class="switch">
                                        <input type="checkbox" v-model="specialOffer.restricted" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <!--Main save button-->
                            <div class="marketing-main-btn-wrap" v-if="specialOffer.active != showOffer || writeOffer">
                                <div>
                                    <button
                                        v-bind:class="{ 'action-disabled': saveOfferInProgress }"
                                        type="button"
                                        class="primary-btn extra-small"
                                        v-on:click="saveSpecialOffer"
                                    >
                                        {{ $ml.get("marketing.saveOffer") }}
                                    </button>
                                </div>
                            </div>
                            <div v-if="showOffer && data.offer != ''" class="marketing-gift-container">
                                <div class="">
                                    <div
                                        class="offer-display"
                                        :style="{ backgroundColor: generalOptions.defaultColor }"
                                    >
                                        <div class="gift-container">
                                            <i class="fa fa-gift"></i>
                                        </div>
                                        <div class="offer-text-message">
                                            {{ data.offer }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Push Notifications Main Conatiner for all the Sections-->
        <div class="all-the-push-sections">
            <!--Push Notifications | First Section-->
            <div v-if="!preview && isSectionVisible('push-notifications')" class="marketing-main-container">
                <div class="marketing-wrapper">
                    <div class="marketing-heading">
                        <h3 v-if="!smsSection">
                            {{ $ml.get("marketing.pushNotifications") }}
                        </h3>
                        <h3 v-if="smsSection">{{ $ml.get("marketing.smsNotifications") }}</h3>
                    </div>
                    <div class="marketing-content section-wrap">
                        <!--Select the type | section-->
                        <div class="push-notifications-wrapper highlighted-box section-wrap">
                            <div class="marketing-inner-heading">
                                <h5 v-if="!smsSection">{{ $ml.get("marketing.selectType") }}</h5>
                                <h5 v-if="smsSection">
                                    {{ $ml.get("marketing.smsTextNotifications") }}
                                </h5>
                            </div>
                            <div class="marketing-content-push-notifications">
                                <div class="push-radio-inputs-wrap">
                                    <div v-if="!smsSection" class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            id="push_notification"
                                            value="push"
                                            v-model="messageType"
                                            v-on:change="
                                                checkTrimCars();
                                                checkCardForm();
                                                getLastCustomNotification();
                                            "
                                        />
                                        <label class="push-form-check-label" for="push_notification">
                                            {{ $ml.get("marketing.pushNotification") }}
                                        </label>
                                    </div>
                                    <div
                                        v-if="
                                            adminDetails.profile &&
                                            smsSection /*&& sms_allowed_countries.indexOf(adminDetails.profile.country_id.toString()) > -1*/
                                        "
                                        class="push-form-check"
                                    >
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            id="sms_notification"
                                            value="sms"
                                            v-model="messageType"
                                            v-on:change="
                                                checkTrimCars();
                                                checkCardForm();
                                                setMessagesNumberBuy();
                                                getLastCustomNotification();
                                            "
                                        />
                                        <label class="push-form-check-label" for="sms_notification">
                                            {{ $ml.get("marketing.sms") }} ({{ availableSMS }}
                                            {{ $ml.get("marketing.available") }})
                                        </label>
                                    </div>
                                    <div
                                        class="transaction-summary"
                                        v-if="
                                            adminDetails.profile &&
                                            smsSection /*&& sms_allowed_countries.indexOf(adminDetails.profile.country_id.toString()) > -1*/
                                        "
                                    >
                                        <span v-on:click="getCampaignLog('sms')" class="history-link main-text-link">{{
                                            $ml.get("marketing.transactionsSummary")
                                        }}</span>
                                    </div>
                                    <div v-if="!smsSection" class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            id="email_notification"
                                            value="email"
                                            v-model="messageType"
                                            v-on:change="
                                                checkTrimCars();
                                                checkCardForm();
                                                setMessagesNumberBuy();
                                                getLastCustomNotification();
                                            "
                                        />
                                        <label class="push-form-check-label" for="email_notification">
                                            {{ $ml.get("marketing.email") }} ({{ availableEmails }}
                                            {{ $ml.get("marketing.available") }})
                                        </label>
                                    </div>
                                    <div v-if="!smsSection" class="transaction-summary">
                                        <span
                                            v-on:click="getCampaignLog('email')"
                                            class="history-link main-text-link"
                                            >{{ $ml.get("marketing.transactionsSummary") }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Free text messages for subscription anniversay | section-->
                        <div v-if="isCountrySMSAllowed() == true">
                            <div class="push-notifications-wrapper highlighted-box section-wrap" v-if="smsSection">
                                {{ $ml.get("marketing.weAreLoadingYourAccountWith") }} {{ sms_monthly_free }}
                                {{ $ml.get("marketing.freeTextMessage") }} ${{ sms_cost }}/
                                {{ $ml.get("marketing.messageText") }} (${{
                                    (sms_cost * sms_monthly_free).toFixed(2)
                                }}).
                            </div>
                        </div>

                        <!--Write your message | section-->
                        <div class="push-notifications-wrapper highlighted-box section-wrap">
                            <!--Heading | Write your message-->
                            <div class="marketing-inner-heading">
                                <h5>{{ $ml.get("marketing.write") }}</h5>
                            </div>
                            <!--Main content container-->
                            <div class="marketing-content-push-notifications">
                                <div class="write-message-wrapper">
                                    <!--Message Type-->
                                    <div class="message-type-wrap">
                                        <div v-if="messageType == 'push'" class="message-type">
                                            {{ $ml.get("marketing.write_content") }}
                                        </div>
                                        <div v-if="messageType == 'sms'" class="message-type">
                                            {{ $ml.get("marketing.write_content_sms") }}
                                        </div>
                                        <div v-if="messageType == 'email'" class="message-type">
                                            {{ $ml.get("marketing.write_content_email") }}
                                        </div>
                                    </div>
                                    <!--Title-->
                                    <div v-if="messageType != 'sms'" class="input-group-wrap">
                                        <div class="inner-input-group-wrap">
                                            <input
                                                type="text"
                                                class="text-field"
                                                placeholder="Title"
                                                aria-label="Title"
                                                maxlength="40"
                                                v-on:keyup="countChar('title')"
                                                v-model="data.title"
                                            />
                                        </div>
                                        <div class="input-counter">{{ counter.title }}/40</div>
                                    </div>
                                    <!--Message textarea-->
                                    <div class="input-group-wrap">
                                        <div
                                            class="inner-input-group-wrap"
                                            :class="{
                                                'mb-3': messageType == 'email',
                                                'mb-1': (messageType == 'sms') | (messageType == 'push'),
                                            }"
                                        >
                                            <input
                                                v-if="messageType == 'push'"
                                                type="text"
                                                class="text-field"
                                                placeholder="Message"
                                                aria-label="Message"
                                                maxlength="160"
                                                v-on:keyup="countChar('message')"
                                                v-model="data.message"
                                            />
                                            <textarea
                                                v-if="messageType != 'push'"
                                                class="text-field expand-textarea"
                                                placeholder="Message"
                                                aria-label="Message"
                                                :maxlength="messageType == 'sms' ? 160 : 2000"
                                                v-on:keyup="countChar('message')"
                                                v-model="data.message"
                                            ></textarea>
                                        </div>
                                        <div v-if="messageType != 'email'" class="input-counter">
                                            <span>{{ counter.message }}/160</span>
                                        </div>
                                        <div
                                            class="email-error-text section-wrap"
                                            v-if="messageType == 'email' && email_text_error != ''"
                                        >
                                            {{ email_text_error }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Send to | section-->
                        <div class="push-notifications-wrapper highlighted-box section-wrap">
                            <div class="marketing-inner-heading">
                                <h5>{{ $ml.get("marketing.sendTo") }}:</h5>
                            </div>
                            <div class="marketing-content-push-notifications">
                                <div class="push-radio-inputs-wrap">
                                    <div class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            name="sendToOpt"
                                            id="sendToOptToday"
                                            value="today"
                                            v-model="data.onlyOpen"
                                            v-on:change="checkCardForm"
                                        />
                                        <label class="push-form-check-label" for="sendToOptToday">
                                            {{ $ml.get("marketing.sendToOptToday") }}({{
                                                details.total_active_clients_today
                                            }})
                                        </label>
                                    </div>
                                    <div class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            name="sendToOpt"
                                            id="sendToOptTomorrow"
                                            value="tomorrow"
                                            v-model="data.onlyOpen"
                                            v-on:change="checkCardForm"
                                        />
                                        <label class="push-form-check-label" for="sendToOptTomorrow">
                                            {{ $ml.get("marketing.sendToOptTomorrow") }}({{
                                                details.total_active_clients_tomorrow
                                            }})
                                        </label>
                                    </div>
                                    <div class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            name="sendToOpt"
                                            id="sendToOptWeek"
                                            value="this_week"
                                            v-model="data.onlyOpen"
                                            v-on:change="checkCardForm"
                                        />
                                        <label class="push-form-check-label" for="sendToOptWeek">
                                            {{ $ml.get("marketing.sendToOptWeek") }}({{
                                                details.total_active_clients_this_week
                                            }})
                                        </label>
                                    </div>
                                    <div class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            name="sendToOpt"
                                            id="sendToOptNextWeek"
                                            value="next_week"
                                            v-model="data.onlyOpen"
                                            v-on:change="checkCardForm"
                                        />
                                        <label class="push-form-check-label" for="sendToOptNextWeek">
                                            {{ $ml.get("marketing.sendToOptNextWeek") }}({{
                                                details.total_active_clients_next_week
                                            }})
                                        </label>
                                    </div>
                                    <div class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            name="sendToOpt"
                                            id="sendToOptOpen"
                                            value="open"
                                            v-model="data.onlyOpen"
                                            v-on:change="checkCardForm"
                                        />
                                        <label class="push-form-check-label" for="sendToOptOpen">
                                            {{ $ml.get("marketing.sendToOptOpen") }}({{ details.total_active_clients }})
                                        </label>
                                    </div>
                                    <div class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            name="sendToOpt"
                                            id="sendToOptAll"
                                            value="all"
                                            v-model="data.onlyOpen"
                                            v-on:change="checkCardForm"
                                        />
                                        <label class="push-form-check-label" for="sendToOptAll">
                                            {{ $ml.get("marketing.sendToOptAll") }} ({{ details.total_clients }})
                                        </label>
                                    </div>
                                    <div v-if="details.total_salon_clients" class="push-form-check">
                                        <input
                                            class="radio-input"
                                            type="radio"
                                            name="sendToSlonAll"
                                            id="sendToSlonAll"
                                            value="all_salon"
                                            v-model="data.onlyOpen"
                                            v-on:change="checkCardForm"
                                        />
                                        <label class="push-form-check-label" for="sendToSlonAll">
                                            {{ $ml.get("marketing.sendToSalonAll") }} ({{
                                                details.total_salon_clients
                                            }})
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div v-if="messageType == 'sms'" class="marketing-content-push-notifications">
                                <div v-if="!hasCard" class="add-payment-method-wrap">
                                    <span @click="scrollToPaymentMethod" class="main-text-link">
                                        {{ $ml.get("marketing.addAtLeastOnePaymentMethod") }}</span
                                    >
                                </div>
                                <div v-if="!hasAvailableMessages()" class="push-radio-inputs-wrap">
                                    <!-- <div v-if="!hasCard">
                                        <div class="card-form" ref="braintree_card"></div>
                                        <div class="card-logos">
                                            <img class="verified" src="../../assets/images/verified-secured.jpg" />
                                        </div>
                                        <div class="card-errors">{{ cards_errors }}</div>
                                    </div> -->
                                    <div class="payment-form" v-if="isCountrySMSAllowed() == true">
                                        <div>
                                            <span>{{ $ml.get("marketing.buy") }}</span>
                                            <input
                                                placeholder="number of sms"
                                                type="number"
                                                v-model="buyMessagesNumber"
                                                class="messages-number-input text-field"
                                                v-on:change="checkMessagesNumberBuy"
                                            />
                                        </div>
                                        <div>
                                            <span
                                                >{{ messageType == "sms" ? "SMS" : "Emails" }} for ${{
                                                    getTotalMessageCost()
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="terms-and-conditions-wrap">
                                    <h6 v-on:click="showTermsModal" class="main-text-link">
                                        <span class="terms-and-conditions-link">{{ $ml.get("marketing.terms") }}</span>
                                    </h6>
                                    <div class="push-form-checkbox">
                                        <label class="switch" for="agreeTerms"
                                            ><input
                                                type="checkbox"
                                                name="agreeTerms"
                                                id="agreeTerms"
                                                value="1"
                                                v-model="agreeTerms"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                        <label class="push-form-check-label" for="agreeTerms">
                                            {{ $ml.get("marketing.agreeTerms") }}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="preview-btn-wrap">
                                <div>
                                    <button
                                        v-bind:class="{
                                            'action-disabled':
                                                sendNotificationInProgress ||
                                                loading ||
                                                (messageType == 'sms' && !agreeTerms) ||
                                                data.message == '' ||
                                                (messageType != 'sms' && data.title == '') ||
                                                clientsNumber == 0,
                                        }"
                                        type="button"
                                        class="primary-btn extra-small"
                                        v-on:click="onCampaignPreview"
                                    >
                                        {{
                                            messageType != "push" && !hasAvailableMessages()
                                                ? $ml.get("marketing.buy_preview")
                                                : $ml.get("marketing.preview")
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--Last you sent: | section-->
                        <div v-if="details.message != null && details.message != ''">
                            <div
                                class="push-notifications-wrapper highlighted-box section-wrap"
                                v-if="messageType == 'push' || messageType == 'sms' || messageType == 'email'"
                            >
                                <!--Push-->
                                <div v-if="messageType == 'push'" class="last-message-sent-wrap">
                                    <div class="last-message-heading">
                                        <h5>{{ $ml.get("marketing.messageLast") }}:</h5>
                                    </div>
                                    <div class="last-message-content">
                                        <div class="title-message-wrap">
                                            <div class="details-title">{{ details.title }}</div>
                                            <div class="details-message">{{ details.message }}</div>
                                        </div>
                                        <div class="details-date">
                                            <div>
                                                {{ details.date }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Email-->
                                <div v-if="messageType == 'email'" class="last-message-sent-wrap">
                                    <div class="last-message-heading">
                                        <h5>{{ $ml.get("marketing.messageLast") }}:</h5>
                                    </div>
                                    <div class="last-message-content">
                                        <div class="title-message-wrap">
                                            <div class="details-title">
                                                {{ last_email_title_send }}
                                            </div>
                                            <div class="details-message">
                                                {{ last_email_message_send }}
                                            </div>
                                        </div>
                                        <div class="details-date">
                                            <div>
                                                {{ details.date }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--SMS-->
                                <div v-if="messageType == 'sms'" class="last-message-sent-wrap">
                                    <div class="last-message-heading">
                                        <h5>{{ $ml.get("marketing.messageLast") }}:</h5>
                                    </div>
                                    <div class="last-message-content">
                                        <div>
                                            <div>{{ details.message }}</div>
                                        </div>
                                        <div>
                                            <div>
                                                {{ details.date }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Push Notifications | Preview Section-->
            <div v-if="preview && isSectionVisible('push-notifications')" class="marketing-main-container">
                <div class="marketing-wrapper">
                    <div class="marketing-heading">
                        <h3>{{ $ml.get("marketing.textMessagePreview") }}</h3>
                    </div>
                    <div class="marketing-content section-wrap">
                        <div class="push-notifications-wrapper highlighted-box section-wrap">
                            <div class="marketing-inner-heading">
                                <h5>{{ $ml.get("marketing.textMessagePreview") }}</h5>
                                <p>( {{ $ml.get("marketing.seeBelowWhatYouAreAboutToSend") }} )</p>
                            </div>

                            <div class="marketing-content-push-notifications">
                                <div class="preview-content-wrap section-wrap">
                                    <div class="preview-text-wrap section-wrap" v-if="messageType != 'sms'">
                                        <div class="preview-heading">Title:</div>
                                        <span class="preview-note">{{ data.title }}</span>
                                    </div>
                                    <div class="preview-text-wrap section-wrap">
                                        <div class="preview-heading">Message:</div>
                                        <span class="preview-note message-sent">{{ data.message }} </span>
                                    </div>
                                    <div v-if="messageType != 'push'" class="preview-text-wrap section-wrap">
                                        <div class="preview-heading">Notes</div>

                                        <div class="preview-notes-wrap">
                                            <span class="preview-note">
                                                * {{ $ml.get("marketing.theMessageWillBeSentTo") }} {{ clientsNumber }}
                                                {{ $ml.get("marketing.ofYourClients") }}.
                                            </span>
                                            <span class="preview-note">
                                                * {{ $ml.get("marketing.itWillConsume") }} {{ clientsNumber }}
                                                {{ messageType == "sms" ? "SMS" : "Email" }}
                                                {{ $ml.get("marketing.messagesFromThe") }}
                                                {{ messageType == "sms" ? availableSMS : availableEmails }}
                                                {{ messageType == "sms" ? "SMS" : "Email" }}
                                                {{ $ml.get("marketing.youHaveAvailable") }}.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="preview-btns-wrap">
                                    <div>
                                        <button type="button" class="secondary-btn small">
                                            <a
                                                v-on:click="
                                                    getCampaignSettings();
                                                    preview = false;
                                                "
                                                >{{ $ml.get("marketing.goBack") }}</a
                                            >
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            v-bind:class="{ 'action-disabled': sendNotificationInProgress || loading }"
                                            type="button"
                                            class="primary-btn extra-small"
                                            v-on:click="onCampaignSend"
                                        >
                                            {{ $ml.get("marketing.send") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Push Notifications | Payment Methods Section-->
            <div
                v-if="isSectionVisible('push-notifications') && smsSection && !preview"
                class="marketing-main-container"
            >
                <div class="marketing-wrapper">
                    <div class="marketing-content section-wrap payment-methods-wrap">
                        <div class="push-notifications-wrapper highlighted-box section-wrap">
                            <div class="marketing-inner-heading" id="add-payment-method">
                                <h5>{{ $ml.get("adminMenu.paymentMethods") }}</h5>
                            </div>
                            <div class="marketing-content-push-notifications">
                                <div class="content-wrap">
                                    <div v-if="braintree_cards.length > 0" class="payment-cards-container section-wrap">
                                        <div class="border-wrap">
                                            <div v-if="braintree_cards.length > 0" class="payment-methods-head">
                                                <div>{{ $ml.get("marketing.number") }}</div>
                                                <div>{{ $ml.get("marketing.expiration") }}</div>
                                                <div>{{ $ml.get("marketing.type") }}</div>
                                            </div>
                                            <div
                                                v-for="(method, i) in braintree_cards"
                                                :key="i"
                                                class="braintree-cards-wrapper"
                                            >
                                                <div class="braintree-card-wrap">
                                                    <div class="braintree-cards-cards">
                                                        {{ $ml.get("marketing.number") }}
                                                    </div>
                                                    <div>***{{ method.last4 }}</div>
                                                </div>
                                                <div class="braintree-card-wrap">
                                                    <div class="braintree-cards-cards">
                                                        {{ $ml.get("marketing.expiration") }}
                                                    </div>
                                                    <div>{{ method.expirationMonth }}/{{ method.expirationYear }}</div>
                                                </div>
                                                <div class="braintree-card-wrap">
                                                    <div class="braintree-cards-cards">
                                                        {{ $ml.get("marketing.type") }}
                                                    </div>
                                                    <div>{{ method.cardType }}</div>
                                                </div>
                                                <div class="braintree-card-wrap">
                                                    <div>
                                                        <span
                                                            class="primary-btn extra-small default-method"
                                                            v-if="
                                                                default_payment_method == method.payment_method_token ||
                                                                (!default_payment_method && !method.default)
                                                            "
                                                            >{{ $ml.get("marketing.default") }}</span
                                                        >
                                                        <span
                                                            class="primary-btn extra-small"
                                                            v-if="
                                                                (default_payment_method !=
                                                                    method.payment_method_token ||
                                                                    (!default_payment_method && !method.default)) &&
                                                                !loading
                                                            "
                                                            v-on:click="setDefaultCard(method.payment_method_token)"
                                                            >{{ $ml.get("marketing.defaultForSMSPurchase") }}</span
                                                        >
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <span
                                                            class="delete-card primary-btn extra-small"
                                                            v-if="
                                                                default_payment_method != method.payment_method_token &&
                                                                !method.default &&
                                                                !loading
                                                            "
                                                            v-on:click="
                                                                deleteBraintreeCard(method.payment_method_token)
                                                            "
                                                            >{{ $ml.get("marketing.delete") }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="displayNewBraintreeCardForm" class="stripe-card-wrap section-wrap">
                                        <div class="card-form" ref="braintree_card"></div>
                                        <div class="card-logos">
                                            <img class="verified" src="../../assets/images/verified-secured.jpg" />
                                        </div>
                                        <div class="card-errors">{{ cards_errors }}</div>

                                        <div class="terms-and-conditions-wrap">
                                            <h6 v-on:click="showTermsModal" class="main-text-link">
                                                <span class="terms-and-conditions-link">
                                                    {{ $ml.get("marketing.terms") }}</span
                                                >
                                            </h6>
                                            <div class="push-form-checkbox">
                                                <label for="agreeCardTerms" class="switch"
                                                    ><input
                                                        id="agreeCardTerms"
                                                        type="checkbox"
                                                        v-model="agreeCardTerms" />

                                                    <span class="slider round"></span
                                                ></label>
                                                <label for="agreeCardTerms" class="push-form-check-label">{{
                                                    $ml.get("marketing.agreeTerms")
                                                }}</label>
                                            </div>
                                        </div>

                                        <div class="action-buttons-wrap">
                                            <div>
                                                <button
                                                    v-on:click="saveCard"
                                                    type="button"
                                                    class="primary-btn extra-small"
                                                    :disabled="loading || !agreeCardTerms"
                                                >
                                                    {{ $ml.get("marketing.addCard") }}
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    v-on:click="
                                                        displayNewBraintreeCardForm = false;
                                                        agreeCardTerms = false;
                                                    "
                                                    type="button"
                                                    class="secondary-btn small"
                                                    :disabled="loading"
                                                >
                                                    {{ $ml.get("marketing.cancel") }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="cards_errors" class="card-errors-wrap">
                                        Error adding the card: {{ cards_errors }}
                                    </div>
                                    <div v-if="!displayNewBraintreeCardForm" class="action-buttons-wrap">
                                        <div>
                                            <button
                                                type="button"
                                                class="primary-btn extra-small"
                                                v-on:click="addNewBraintreeCard"
                                            >
                                                {{ $ml.get("marketing.addNewCard") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Push Notifications | Manual Payment Section-->
            <div
                v-if="isSectionVisible('push-notifications') && smsSection && !preview && isCountrySMSAllowed() == true"
                class="marketing-main-container"
            >
                <div class="marketing-wrapper">
                    <div class="marketing-content section-wrap">
                        <div class="push-notifications-wrapper highlighted-box section-wrap">
                            <div class="marketing-inner-heading">
                                <h5>{{ $ml.get("marketing.manualPayment") }}</h5>
                            </div>

                            <div class="marketing-content-push-notifications">
                                <div class="content-wrap">
                                    <div class="section-wrap">
                                        <div class="sms-amount-wrap">
                                            <label>{{ $ml.get("marketing.smsAmount") }}</label>
                                            <div class="sms-input-wrap">
                                                <div>
                                                    <input
                                                        type="number"
                                                        class="text-field"
                                                        v-model="buyMessagesNumber"
                                                    />
                                                </div>
                                                <div>
                                                    SMS x ${{ sms_cost }} = ${{
                                                        (sms_cost * buyMessagesNumber).toFixed(2)
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="terms-and-conditions-wrap">
                                            <h6 v-on:click="showTermsModal" class="main-text-link">
                                                <span class="terms-and-conditions-link">{{
                                                    $ml.get("marketing.terms")
                                                }}</span>
                                            </h6>
                                            <div class="push-form-checkbox">
                                                <label class="switch"
                                                    ><input
                                                        type="checkbox"
                                                        name="agreeTerms-2"
                                                        id="agreeTerms-2"
                                                        value="1"
                                                        v-model="agreeTerms" />
                                                    <span class="slider round"></span
                                                ></label>

                                                <label class="push-form-check-label" for="agreeTerms-2">
                                                    {{ $ml.get("marketing.agreeTerms") }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action-buttons-wrap">
                                        <div v-if="braintree_cards.length == 0" class="mt-2 pull-right text-danger">
                                            {{ $ml.get("marketing.noCreditCardOnFile") }}.
                                        </div>
                                        <div>
                                            <button
                                                v-on:click="manualChargePayment"
                                                :disabled="
                                                    loading ||
                                                    !agreeTerms ||
                                                    braintree_cards.length == 0 ||
                                                    buyMessagesNumber < 100
                                                "
                                                type="button"
                                                class="primary-btn extra-small"
                                            >
                                                {{ $ml.get("marketing.pay") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Push Notifications | Auto Reload SMS Section-->
            <div
                v-if="isSectionVisible('push-notifications') && smsSection && !preview && isCountrySMSAllowed() == true"
                class="marketing-main-container"
            >
                <div class="marketing-wrapper">
                    <div class="marketing-content section-wrap">
                        <div class="push-notifications-wrapper highlighted-box section-wrap">
                            <div class="marketing-inner-heading">
                                <h5>{{ $ml.get("marketing.autoreloadSMS") }}</h5>
                            </div>

                            <div class="marketing-content-push-notifications">
                                <div class="content-wrap">
                                    <div class="inner-content-wrap section-wrap">
                                        <div class="auto-reload-wrap">
                                            <label for="reload">{{ $ml.get("marketing.autoReload") }}</label>
                                            <label class="switch">
                                                <input id="reload" type="checkbox" v-model="autoreload_sms" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div class="sms-amount-wrap">
                                            <label>{{ $ml.get("marketing.smsAmount") }}</label>
                                            <div class="sms-input-wrap">
                                                <div>
                                                    <input
                                                        type="number"
                                                        class="text-field"
                                                        v-model="autoreload_sms_number"
                                                    />
                                                </div>
                                                <div>
                                                    {{ $ml.get("marketing.sms") }} x ${{ sms_cost }} = ${{
                                                        (sms_cost * autoreload_sms_number).toFixed(2)
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sms-amount-wrap">
                                            <label>{{ $ml.get("marketing.whenSmsBalanceIsBelow") }}</label>
                                            <div class="sms-input-wrap">
                                                <div>
                                                    <input
                                                        type="number"
                                                        class="text-field"
                                                        v-model="autoreload_sms_limit"
                                                    />
                                                </div>
                                                <div>{{ $ml.get("marketing.sms") }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action-buttons-wrap">
                                        <div v-if="braintree_cards.length == 0" class="pull-right text-danger">
                                            {{ $ml.get("marketing.noCreditCardOnFile") }}.
                                        </div>
                                        <div>
                                            <button
                                                v-on:click="saveAutoreloadSmsSettings"
                                                :disabled="
                                                    loading ||
                                                    braintree_cards.length == 0 ||
                                                    autoreload_sms_number < 100
                                                "
                                                type="button"
                                                class="primary-btn extra-small"
                                            >
                                                {{ $ml.get("marketing.save") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Social Links Section-->
        <div v-if="adminDetails && isSectionVisible('social-links')" class="marketing-main-container">
            <div class="marketing-wrapper">
                <div class="marketing-heading">
                    <h3>{{ $ml.get("marketing.socialLinks") }}</h3>
                    <div class="font-italic">
                        {{ $ml.get("marketing.socialLinksContent") }}
                    </div>
                </div>
                <div class="marketing-content section-wrap">
                    <!--Website-->
                    <div class="push-notifications-wrapper highlighted-box section-wrap">
                        <div class="socials-container">
                            <div class="gentle-hover-shake">
                                <label for="website"><i class="social-icon website-icon"></i></label>
                            </div>

                            <div class="socials-content">
                                <div class="socials-heading">
                                    <label for="website" class="social-label">
                                        {{ $ml.get("marketing.website") }}
                                    </label>
                                    <div class="button-test">
                                        <div>
                                            <a
                                                target="_blank"
                                                class="primary-btn extra-small"
                                                :href="websiteBaseUrl + websiteUrl"
                                                >{{ $ml.get("marketing.testLink") }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="social-input-wrap">
                                    <label class="url-field-label">{{ websiteBaseUrl }}</label>
                                    <input
                                        id="website"
                                        type="text"
                                        class="text-field"
                                        placeholder="Website URL"
                                        v-model="websiteUrl"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Facebook-->
                    <div class="push-notifications-wrapper highlighted-box section-wrap">
                        <div class="socials-container">
                            <div class="gentle-hover-shake">
                                <label for="facebook"><i class="social-icon facebook fa fa-facebook-square"></i></label>
                            </div>
                            <div class="socials-content">
                                <div class="socials-heading">
                                    <label for="facebook" class="social-label">
                                        {{ $ml.get("marketing.facebook") }}
                                    </label>
                                    <div class="button-test">
                                        <div>
                                            <a
                                                target="_blank"
                                                class="primary-btn extra-small"
                                                :href="facebookBaseUrl + facebookUrl"
                                                >{{ $ml.get("marketing.testLink") }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="social-input-wrap">
                                    <label class="url-field-label">{{ facebookBaseUrl }}</label>
                                    <input
                                        id="facebook"
                                        type="text"
                                        class="text-field"
                                        placeholder="Facebook URL"
                                        v-model="facebookUrl"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Instagram-->
                    <div class="push-notifications-wrapper highlighted-box section-wrap">
                        <div class="socials-container">
                            <div class="gentle-hover-shake">
                                <label for="instagram"><i class="social-icon instagram fa fa fa-instagram"></i></label>
                            </div>
                            <div class="socials-content">
                                <div class="socials-heading">
                                    <label for="instagram" class="social-label">
                                        {{ $ml.get("marketing.instagram") }}
                                    </label>
                                    <div class="button-test">
                                        <div>
                                            <a
                                                target="_blank"
                                                class="primary-btn extra-small"
                                                :href="instagramBaseUrl + instagramUrl"
                                                >{{ $ml.get("marketing.testLink") }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="social-input-wrap">
                                    <label class="url-field-label">{{ instagramBaseUrl }}</label>
                                    <input
                                        id="instagram"
                                        type="text"
                                        class="text-field"
                                        placeholder="Instagram URL"
                                        v-model="instagramUrl"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Yelp-->
                    <div class="push-notifications-wrapper highlighted-box section-wrap">
                        <div class="socials-container">
                            <div class="gentle-hover-shake">
                                <label for="yelp"><i class="social-icon yelp fa fa fa-yelp"></i></label>
                            </div>
                            <div class="socials-content">
                                <div class="socials-heading">
                                    <label for="yelp" class="social-label">
                                        {{ $ml.get("marketing.yelp") }}
                                    </label>
                                    <div class="button-test">
                                        <div>
                                            <a
                                                target="_blank"
                                                class="primary-btn extra-small"
                                                :href="yelpBaseUrl + yelpUrl"
                                                >{{ $ml.get("marketing.testLink") }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="social-input-wrap">
                                    <label class="url-field-label">{{ yelpBaseUrl }}</label>
                                    <input
                                        id="yelp"
                                        type="text"
                                        class="text-field"
                                        placeholder="Yelp URL"
                                        v-model="yelpUrl"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Twitter-->
                    <div class="push-notifications-wrapper highlighted-box section-wrap">
                        <div class="socials-container">
                            <div class="gentle-hover-shake">
                                <label for="twitter"><i class="social-icon twitter fa fa fa-twitter-square"></i></label>
                            </div>
                            <div class="socials-content">
                                <div class="socials-heading">
                                    <label for="twitter" class="social-label">
                                        {{ $ml.get("marketing.twitter") }}
                                    </label>
                                    <div class="button-test">
                                        <div>
                                            <a
                                                target="_blank"
                                                class="primary-btn extra-small"
                                                :href="twitterBaseUrl + twitterUrl"
                                                >{{ $ml.get("marketing.testLink") }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="social-input-wrap">
                                    <label class="url-field-label">{{ twitterBaseUrl }}</label>
                                    <input
                                        id="twitter"
                                        type="text"
                                        class="text-field"
                                        placeholder="Twitter URL"
                                        v-model="twitterUrl"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Tiktok-->
                    <div class="push-notifications-wrapper highlighted-box section-wrap">
                        <div class="socials-container">
                            <div class="gentle-hover-shake">
                                <label for="tiktok"
                                    ><img class="tiktok-img" src="../../assets/images/tiktok.png" width="100"
                                /></label>
                            </div>
                            <div class="socials-content">
                                <div class="socials-heading">
                                    <label for="tiktok" class="social-label">
                                        {{ $ml.get("marketing.tik_tok") }}
                                    </label>
                                    <div class="button-test">
                                        <div>
                                            <a
                                                target="_blank"
                                                class="primary-btn extra-small"
                                                :href="tiktokBaseUrl + tiktokUrl"
                                                >{{ $ml.get("marketing.testLink") }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="social-input-wrap">
                                    <label class="url-field-label">{{ tiktokBaseUrl }}</label>
                                    <input
                                        id="tiktok"
                                        type="text"
                                        class="text-field"
                                        placeholder="Tik Tok URL"
                                        v-model="tiktokUrl"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action-buttons-wrap">
                        <div>
                            <button
                                v-bind:class="{ 'action-disabled': saveOfferInProgress }"
                                type="button"
                                class="primary-btn extra-small"
                                v-on:click="saveProfile"
                            >
                                {{ $ml.get("marketing.save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Modal Terms and Conditions-->
        <div
            class="modal fade"
            id="terms-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="date-modal"
            aria-hidden="true"
            data-backdrop="false"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5>{{ $ml.get("marketing.terms") }}</h5>
                        </div>
                        <div>
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="terms-list-container section-wrap">
                            <ul class="terms-list">
                                <li>{{ $ml.get("marketing.smsMessagesAreLimited") }}.</li>
                                <li>{{ $ml.get("marketing.youCanOnlyUseTextMessages") }}.</li>
                                <li>{{ $ml.get("marketing.unlessWeStateInWritingOtherwise") }}.</li>
                                <li>{{ $ml.get("marketing.theRemainingSmsMessagesAreNotRefundable") }}.</li>
                                <li>{{ $ml.get("marketing.theRemainingEmailsAreNotRefundable") }}.</li>
                                <li v-if="smsSection && isCountrySMSAllowed() == true">
                                    {{ $ml.get("marketing.costPerSms") }} ${{ sms_cost }}.
                                </li>
                                <li v-if="!smsSection">{{ $ml.get("marketing.costPerEmail") }}.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
        <!--Modal 2-->
        <div
            class="modal fade"
            id="history-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="date-modal"
            aria-hidden="true"
            data-backdrop="false"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5>{{ $ml.get("marketing.transactionsSummary") }}</h5>
                        </div>
                        <div>
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="history-list-container">
                            <div class="history-content-wrap">
                                <div class="history-content-heading">
                                    <span v-if="logDisplayType == 'email'"
                                        >{{ campaignLogTotal }} {{ $ml.get("marketing.emailMessagesSent") }}.
                                    </span>
                                    <span v-if="logDisplayType == 'email'"
                                        >{{ availableEmails + " Email" }}
                                        {{ $ml.get("marketing.messagesRemain") }}.</span
                                    >
                                </div>

                                <div v-if="logDisplayType == 'sms'" class="history-log-wrap section-wrap">
                                    <div
                                        v-for="(item, i) in campaignLogSMS"
                                        :key="i"
                                        class="history-log-item section-wrap"
                                    >
                                        <h5>{{ item.month }}</h5>
                                        <div>
                                            <strong>{{ item.total_sent }}</strong>
                                        </div>
                                        <div
                                            v-for="(log, k) in item.log"
                                            :key="`items_${k}`"
                                            class="history-log-item section-wrap"
                                        >
                                            {{ log }}
                                        </div>
                                        <div v-if="item.sent_to_clients.log.length > 0">
                                            <div>
                                                <strong>{{ item.sent_to_clients.total }}</strong>
                                            </div>
                                            <div
                                                v-for="(lg, k) in item.sent_to_clients.log"
                                                :key="`sent_to_clients${k}`"
                                                class="history-log-item section-wrap"
                                            >
                                                {{ lg }}
                                            </div>
                                        </div>
                                        <div v-if="item.sent_to_stylist.log.length > 0">
                                            <div>
                                                <strong>{{ item.sent_to_stylist.total }}</strong>
                                            </div>
                                            <div
                                                v-for="(lg, k) in item.sent_to_stylist.log"
                                                :key="`sent_to_stylists${k}`"
                                                class="history-log-item section-wrap"
                                            >
                                                {{ lg }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="logDisplayType == 'email' && campaignLog.length > 0"
                                    class="history-log-wrap section-wrap"
                                >
                                    <div
                                        v-for="(item, i) in campaignLog"
                                        :key="i"
                                        class="history-log-item section-wrap"
                                    >
                                        {{ item.number }} {{ logDisplayType == "sms" ? "SMS" : "Emails" }}
                                        {{ item.event_type == "send" ? "sent" : "purchased" }} on
                                        {{ getFormattedDate(item.created_at, "MMM DD YYYY") }}
                                    </div>
                                </div>
                                <div
                                    v-if="logDisplayType == 'email' && campaignLog.length == 0"
                                    class="history-log-wrap section-wrap"
                                >
                                    <span class="no-transactions">{{
                                        $ml.get("marketing.noTransactionsSummary")
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
import moment from "moment";
import eventBus from "./../../services/event_bus.js";
import Vue from "vue";
import $ from "jquery";

export default {
    name: "AdminSchedule",
    props: ["sections", "smsSection"],
    data: function () {
        return {
            counter: {
                title: 0,
                message: 0,
                offer: 0,
            },
            data: {
                title: "",
                message: "",
                onlyOpen: "today",
                offer: "",
            },
            details: {
                title: 0,
                message: 0,
                date: "",
                total_active_clients_next_week: 0,
                total_active_clients_this_week: 0,
                total_active_clients_today: 0,
                total_active_clients_tomorrow: 0,
                total_active_clients: 0,
                total_clients: 0,
                total_salon_clients: 0,
            },
            specialOffer: {
                active: 0,
                restricted: 1,
                text: "",
                zip: "",
            },
            writeOffer: false,
            showOffer: false,
            facebookBaseUrl: "http://facebook.com/",
            instagramBaseUrl: "http://instagram.com/",
            yelpBaseUrl: "http://yelp.com/",
            twitterBaseUrl: "http://twitter.com/",
            tiktokBaseUrl: "http://tiktok.com/@",
            websiteBaseUrl: "http://",
            facebookUrl: "",
            instagramUrl: "",
            yelpUrl: "",
            twitterUrl: "",
            websiteUrl: "",
            tiktokUrl: "",

            sendNotificationInProgress: false,
            saveOfferInProgress: false,
            saveProfileInProgress: false,
            availableSMS: 0,
            availableEmails: 0,
            buyMessagesNumber: 100,
            messageType: "push",
            agreeTerms: false,
            hasCard: true,
            card_errors: "",
            loading: false,
            cards_errors: "",
            sms_cost: 0,
            sms_free_initial: 100,
            sms_free_initial_total_cost: 0,
            sms_monthly_free: 0,
            email_cost: process.env.VUE_APP_EMAIL_COST,
            sms_allowed_countries: [],
            preview: false,
            clientsNumber: 0,
            campaignLog: [],
            campaignLogTotal: 0,
            campaignLogSMS: [],
            logDisplayType: "",
            minSMSBuyNumber: 100,
            minEmailBuyNumber: 1000,
            displayNewBraintreeCardForm: false,
            braintree_client_token: "",
            braintree_dropin_instance: null,
            braintree_cards: [],
            default_payment_method: "",
            agreeCardTerms: false,
            autoreload_sms: 0,
            autoreload_sms_number: 0,
            autoreload_sms_limit: 0,
            email_text_error: "",
            last_email_title_send: "",
            last_email_message_send: "",
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.for_stylist_id = auth.user.id;

        this.sms_allowed_countries = process.env.VUE_APP_SMS_ALLOWED_COUNTRIES.split(",");

        if (this.smsSection) {
            this.messageType = "sms";
        }

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });
    },
    watch: {
        smsSection: function () {
            if (this.smsSection) {
                this.messageType = "sms";
            } else {
                this.messageType = "push";
            }
            this.checkTrimCars();
            this.checkCardForm();
            this.setMessagesNumberBuy();
            this.getLastCustomNotification();
        },
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
    },
    methods: {
        showOfferToggle() {
            if (!this.showOffer) {
                this.saveSpecialOffer();
            }
        },
        scrollToPaymentMethod() {
            const targetElement = document.getElementById("add-payment-method");

            if (targetElement) {
                const scrollPosition = targetElement.offsetTop - window.innerHeight / 2;

                window.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth",
                });
            }
        },
        isSectionVisible(section) {
            if (!this.sections) {
                return true;
            }

            if (this.sections.indexOf(section) != -1) {
                return true;
            }

            return false;
        },
        countChar: function (type) {
            this.counter[type] = this.data[type].length;
        },
        countCharOffer: function () {
            this.counter.offer = this.specialOffer.text.length;
        },
        toggleWriteOffer: function () {
            this.writeOffer = !this.writeOffer;
            this.countChar("offer");
        },
        isCountrySMSAllowed() {
            if (!this.adminDetails.profile) {
                return false;
            }
            let profileCountryId = this.adminDetails.profile.country_id;
            let countriesAllowed = this.sms_allowed_countries;

            return countriesAllowed.includes(profileCountryId.toString());
        },

        onAdminDetailsLoad: function () {
            this.getLastCustomNotification();
            this.getSpecialOffer();
            this.populateSocialUrls();
            this.getCampaignSettings();
            this.checkUserCard();
            if (this.sms_allowed_countries.indexOf(this.adminDetails.profile.country_id.toString()) > -1) {
                this.sms_cost = process.env["VUE_APP_SMS_COST_" + this.adminDetails.profile.country_id];
                this.sms_monthly_free = process.env["VUE_APP_MONTHLY_SMS_NUM_" + this.adminDetails.profile.country_id];
                this.sms_free_initial = 100;
            } else {
                this.sms_cost = process.env["VUE_APP_SMS_COST_0"];
                this.sms_monthly_free = process.env["VUE_APP_MONTHLY_SMS_NUM"];
                this.sms_free_initial = 50;
            }
            this.sms_free_initial_total_cost = (this.sms_cost * this.sms_free_initial).toFixed(2);
        },
        populateSocialUrls: function () {
            this.facebookUrl = this.adminDetails.profile.facebook_url;
            this.instagramUrl = this.adminDetails.profile.instagram_url;
            this.yelpUrl = this.adminDetails.profile.yelp_url;
            this.twitterUrl = this.adminDetails.profile.twitter_url;
            this.tiktokUrl = this.adminDetails.profile.tiktok_url;

            var fields = ["facebook_url", "instagram_url", "yelp_url", "twitter_url", "tiktok_url", "website"];
            var modelFields = ["facebookUrl", "instagramUrl", "yelpUrl", "twitterUrl", "tiktokUrl", "websiteUrl"];
            var modelFieldsBase = [
                "facebookBaseUrl",
                "instagramBaseUrl",
                "yelpBaseUrl",
                "twitterBaseUrl",
                "tiktokBaseUrl",
                "websiteBaseUrl",
            ];

            fields.forEach((field, k) => {
                if (this.adminDetails.profile[field] && this.adminDetails.profile[field].indexOf("http") == -1) {
                    this[modelFields[k]] = "http://" + this.adminDetails.profile[field];
                }

                if (this.adminDetails.profile[field] && this.adminDetails.profile[field].indexOf("https") != -1) {
                    this[modelFields[k]] = this.adminDetails.profile[field].replace("https", "http");
                }

                this[modelFields[k]] = this[modelFields[k]].replace("www.", "");
                this[modelFields[k]] = this[modelFields[k]].replace(this[modelFieldsBase[k]], "");
            });
        },
        getLastCustomNotification: function () {
            this.email_text_error = "";
            let additionalQuery = "";

            if (this.adminDetails.group_active == 1 && this.adminDetails.group_owner == 1) {
                additionalQuery = "&include_group_clients_count=" + this.adminDetails.group_id;
            }

            this.$http
                .get(api.actions.get_last_custom_notification + "?type=" + this.messageType + additionalQuery)
                .then(
                    function (response) {
                        if (typeof response.data.details != "undefined" && response.data.details) {
                            if (typeof response.data.details.date !== "undefined") {
                                this.details.date = moment(response.data.details.date).format("MMMM DD / hh:ss A");
                            }
                            this.details.title = response.data.details.title;
                            this.details.message = response.data.details.message;
                            this.details.total_active_clients_next_week = response.data.total_active_clients_next_week;
                            this.details.total_active_clients_this_week = response.data.total_active_clients_this_week;
                            this.details.total_active_clients_today = response.data.total_active_clients_today;
                            this.details.total_active_clients_tomorrow = response.data.total_active_clients_tomorrow;
                            this.details.total_active_clients = response.data.total_active_clients;
                            this.details.total_clients = response.data.total_clients;
                            this.details.total_salon_clients = response.data.total_salon_clients;
                            this.clientsNumber = this.getSelectedGroupNumber();
                            this.resetForm();
                            this.setMessagesNumberBuy();
                            this.splitText();
                        }
                    }.bind(this),
                    function () {
                        return false;
                    }
                );
        },
        resetForm: function () {
            this.data.title = "";
            this.data.message = "";
            this.countChar("title");
            this.countChar("message");
        },
        sendCustomNotification: function () {
            let data = {
                title: this.data.title,
                message: this.data.message,
            };
            if (data.title.length == 0 || data.message.length == 0) {
                Vue.toasted.show("Title of message cannot be empty", {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                return;
            }
            if (
                this.data.onlyOpen != "all" &&
                this.data.onlyOpen != "all_salon" &&
                this.details.total_active_clients == 0
            ) {
                Vue.toasted.show("There are no clients to send notification to.", {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                return;
            }
            if (this.data.onlyOpen == "all" && this.details.total_salon_clients == 0) {
                Vue.toasted.show("There are no clients to send notification to.", {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                return;
            }

            if (this.data.onlyOpen == "all_salon" && this.details.total_salon_clients == 0) {
                Vue.toasted.show("There are no clients to send notification to.", {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                return;
            }

            //make only_open
            switch (this.data.onlyOpen) {
                case "today":
                case "tomorrow":
                case "this_week":
                case "next_week":
                    data.only_open = 1;
                    data.only_open_interval = this.data.onlyOpen;
                    break;
                case "open":
                    data.only_open = 1;
                    break;
                case "all":
                case "all_salon":
                    data.only_open = 0;
                    break;
            }

            if (this.data.onlyOpen == "all_salon") {
                data.group_send = this.adminDetails.group_id;
            }

            this.sendNotificationInProgress = true;
            this.$http.post(api.actions.send_custom_notification, data).then(
                function (response) {
                    this.sendNotificationInProgress = false;
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.CUSTOM_NOTIFICATION_SENT);
                        this.getLastCustomNotification();
                        this.preview = false;
                        this.data.title = "";
                        this.data.message = "";
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getSpecialOffer: function () {
            if (!this.user) {
                return;
            }
            this.$http.post(api.actions.get_special_offer, { id: this.user.id }).then(
                function (response) {
                    if (typeof response.data.special_offer != "undefined" && response.data.special_offer) {
                        this.specialOffer.active = response.data.special_offer.active;
                        this.specialOffer.restricted = response.data.special_offer.restricted;
                        this.specialOffer.text = response.data.special_offer.text;
                        this.specialOffer.zip = response.data.special_offer.zip;

                        this.showOffer = this.specialOffer.active;
                        this.data.offer = this.specialOffer.text;

                        this.writeOffer = false;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveSpecialOffer: function () {
            let data = {
                restricted: this.specialOffer.restricted,
                zip: this.specialOffer.zip,
                text: this.specialOffer.text,
                active: this.showOffer,
            };
            if (this.showOffer && this.specialOffer.text.length == 0) {
                Vue.toasted.show("Text is required when offer is active", {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                return;
            }
            this.saveOfferInProgress = true;
            this.$http.post(api.actions.save_special_offer, data).then(
                function (response) {
                    this.saveOfferInProgress = false;
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.SAVE_OFFER_SUCCESS);
                        this.getSpecialOffer();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveProfile: function () {
            let data = {
                links: true,
                facebook_url: this.facebookBaseUrl + this.facebookUrl,
                instagram_url: this.instagramBaseUrl + this.instagramUrl,
                yelp_url: this.yelpBaseUrl + this.yelpUrl,
                tiktok_url: this.tiktokBaseUrl + this.tiktokUrl,
                twitter_url: this.twitterBaseUrl + this.twitterUrl,
                website: this.websiteBaseUrl + this.websiteUrl,
            };

            this.saveProfileInProgress = true;
            this.$http.post(api.actions.save_profile_info, data).then(
                function (response) {
                    this.saveProfileInProgress = false;
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.SOCIAL_LINKS_SAVED);
                        this.getStylistDetails();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStylistDetails: function () {
            this.$http.get(api.actions.get_stylist_details + this.user.id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        // let allInfoDetails = response.data;
                        let adminDetails = response.data.details;
                        store.commit("setAdminDetails", adminDetails);
                        eventBus.$emit("admin_details_loaded");
                    }
                }.bind(this)
            );
        },
        getCampaignSettings: function () {
            this.$http.get(api.actions.get_marketing_campaign_settings).then(
                function (response) {
                    if (typeof response.data.settings !== "undefined") {
                        this.availableSMS = response.data.settings.available_sms;
                        this.default_payment_method = response.data.settings.payment_method;
                        this.autoreload_sms = response.data.settings.autoreload_sms;
                        this.autoreload_sms_number = response.data.settings.autoreload_sms_number;
                        this.autoreload_sms_limit = response.data.settings.autoreload_sms_limit;

                        this.availableEmails = response.data.settings.available_emails;
                    }
                }.bind(this)
            );
        },
        checkTrimCars: function () {
            if (this.messageType != "email" && this.data.message.length > 160) {
                this.data.message = this.data.message.substr(0, 160);
                this.counter.message = 160;
            }
        },
        showTermsModal: function () {
            $("#terms-modal").modal("show");
        },
        hasAvailableMessages: function () {
            var clients = this.getSelectedGroupNumber();
            var messages = 0;

            switch (this.messageType) {
                case "sms":
                    messages = this.availableSMS;
                    break;
                case "email":
                    messages = this.availableEmails;
                    break;
            }

            this.clientsNumber = clients;
            var available = messages > clients;

            return available;
        },
        getSelectedGroupNumber() {
            var clients = 0;

            switch (this.data.onlyOpen) {
                case "tomorrow":
                    clients = this.details.total_active_clients_tomorrow;
                    break;
                case "today":
                    clients = this.details.total_active_clients_today;
                    break;
                case "this_week":
                    clients = this.details.total_active_clients_this_week;
                    break;
                case "next_week":
                    clients = this.details.total_active_clients_next_week;
                    break;
                case "open":
                    clients = this.details.total_active_clients;
                    break;
                case "all":
                    clients = this.details.total_clients;
                    break;
                case "all_salon":
                    clients = this.details.total_salon_clients;
                    break;
            }

            return clients;
        },
        checkUserCard() {
            this.$http.get(api.actions.get_braintree_customer_cards).then((response) => {
                if (response.data.error == true || !response.data.response.length) {
                    this.hasCard = false;
                    return;
                }

                this.hasCard = true;
                this.braintree_cards = response.data.response;
            });
        },
        setDefaultCard(card_id) {
            this.loading = true;

            this.$http
                .post(api.actions.save_marketing_campaign_payment_method, { payment_method: card_id })
                .then(() => {
                    this.loading = false;
                    this.default_payment_method = card_id;
                    this.checkUserCard();
                    this.getCampaignSettings();
                });
        },
        deleteBraintreeCard(card_id) {
            this.$swal({
                title: "Are you sure you want to delete the payment method?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    this.loading = true;

                    this.$http
                        .post(api.actions.delete_braintree_customer_cards, { payment_method_token: card_id })
                        .then(() => {
                            this.loading = false;
                            this.checkUserCard();
                        });
                }
            });
        },
        addNewBraintreeCard() {
            this.displayNewBraintreeCardForm = true;

            if (!this.$refs.braintree_card) {
                setTimeout(() => {
                    this.addNewBraintreeCard();
                }, 100);
                return;
            }

            this.$http.get(api.actions.get_braintree_client_token).then((response) => {
                this.braintree_client_token = response.data.client_token;

                window.braintree.dropin.create(
                    {
                        authorization: this.braintree_client_token,
                        container: this.$refs.braintree_card,
                    },
                    (error, dropinInstance) => {
                        if (!dropinInstance) {
                            return;
                        }

                        this.braintree_dropin_instance = dropinInstance;
                    }
                );
            });
        },
        saveCard() {
            this.loading = true;

            this.braintree_dropin_instance.requestPaymentMethod((error, payload) => {
                if (error) {
                    return false;
                }

                this.displayNewBraintreeCardForm = false;
                this.loading = true;
                this.cards_errors = "";

                this.$http
                    .post(api.actions.save_braintree_customer_card, { payment_method_nonce: payload.nonce })
                    .then((result) => {
                        this.loading = false;

                        if (result.data.error) {
                            this.cards_errors = result.data.message;
                            return;
                        } else {
                            this.loading = false;
                            this.hasCard = true;
                            this.checkUserCard();
                        }
                    });
            });
        },
        saveCardAndPay() {
            this.loading = true;

            this.braintree_dropin_instance.requestPaymentMethod((error, payload) => {
                if (error) {
                    return false;
                }

                this.displayNewBraintreeCardForm = false;
                this.loading = true;

                this.$http
                    .post(api.actions.save_braintree_customer_card, { payment_method_nonce: payload.nonce })
                    .then((result) => {
                        this.loading = false;

                        if (result.error) {
                            this.cards_errors = result.error.message;
                            return;
                        } else {
                            this.loading = false;
                            this.hasCard = true;
                            this.chargePayment();
                        }
                    });
            });
        },
        chargePayment() {
            this.loading = true;
            this.$http.get(api.actions.get_braintree_customer_cards).then((response) => {
                if (response.data.error == true || !response.data.response.length) {
                    this.loading = false;
                    this.hasCard = false;
                    return;
                }

                var payment_method = response.data.response[0];

                response.data.response.forEach((el) => {
                    if (el.default) {
                        payment_method = el;
                    }
                });

                var fields = {
                    number: this.buyMessagesNumber,
                    type: this.messageType,
                    payment_method_token: payment_method.payment_method_token,
                };

                this.$http.post(api.actions.charge_marketing_campaign_payment, fields).then(() => {
                    this.loading = false;
                    this.preview = true;

                    setTimeout(() => {
                        this.getCampaignSettings();
                    }, 2000);
                });
            });
        },
        manualChargePayment() {
            this.loading = true;
            this.$http.get(api.actions.get_braintree_customer_cards).then((response) => {
                if (response.data.error == true || !response.data.response.length) {
                    this.loading = false;
                    this.hasCard = false;
                    return;
                }

                var payment_method = response.data.response[0];

                response.data.response.forEach((el) => {
                    if (el.default) {
                        payment_method = el;
                    }
                });

                var fields = {
                    number: this.buyMessagesNumber,
                    type: this.messageType,
                    payment_method_token: this.default_payment_method
                        ? this.default_payment_method
                        : payment_method.payment_method_token,
                };

                this.$http.post(api.actions.charge_marketing_campaign_payment, fields).then((response) => {
                    this.loading = false;

                    if (response.data.error == true) {
                        messages.errorMessage(messages.ERROR_MESSAGES.PAYMENT_ERROR);
                        return;
                    }

                    messages.successMessage(messages.GLOBAL_MESSAGES.PAYMENT_SUCCESS);

                    setTimeout(() => {
                        this.getCampaignSettings();
                    }, 2000);
                });
            });
        },
        checkCardForm() {
            this.setMessagesNumberBuy();

            if (!this.hasAvailableMessages() && !this.hasCard) {
                this.addNewBraintreeCard();
            }
        },
        getTotalMessageCost() {
            var price = this.messageType == "sms" ? this.sms_cost : this.email_cost;

            return (price * this.buyMessagesNumber).toFixed(2);
        },
        checkMessagesNumberBuy() {
            if (this.messageType == "sms" && this.buyMessagesNumber < this.minSMSBuyNumber) {
                this.buyMessagesNumber = this.minSMSBuyNumber;
            } else if (this.messageType == "email" && this.buyMessagesNumber < this.minEmailBuyNumber) {
                this.buyMessagesNumber = this.minEmailBuyNumber;
            }
        },
        setMessagesNumberBuy() {
            var clients = this.getSelectedGroupNumber();

            if (this.messageType == "sms") {
                if (clients > this.availableSMS && this.minSMSBuyNumber < clients - this.availableSMS) {
                    this.minSMSBuyNumber = clients - this.availableSMS;
                } else {
                    this.minSMSBuyNumber = 100;
                }

                this.buyMessagesNumber = this.minSMSBuyNumber;
            } else if (this.messageType == "email") {
                if (clients > this.availableEmails && this.minEmailBuyNumber < clients - this.availableEmails) {
                    this.minEmailBuyNumber = clients - this.availableEmails;
                } else {
                    this.minEmailBuyNumber = 1000;
                }

                this.buyMessagesNumber = this.minEmailBuyNumber;
            }
        },
        onCampaignPreview() {
            if (this.adminDetails.web_trial == true) {
                //messages.errorMessage(messages.ERROR_MESSAGES.TRIAL_USER_MARKETING);
                //return;
            }

            this.clientsNumber = this.getSelectedGroupNumber();

            if (this.messageType != "push" && !this.hasAvailableMessages()) {
                if (this.hasCard) {
                    this.chargePayment();
                } else {
                    this.saveCardAndPay();
                }
                return;
            }

            this.preview = true;
        },
        onCampaignSend() {
            if (this.messageType == "push") {
                this.sendCustomNotification();
                return;
            }

            this.loading = true;
            var data = {
                type: this.messageType,
                subject: this.data.title,
                message: this.data.message,
            };

            switch (this.data.onlyOpen) {
                case "today":
                case "tomorrow":
                case "this_week":
                case "next_week":
                    data.only_open = 1;
                    data.only_open_interval = this.data.onlyOpen;
                    break;
                case "open":
                    data.only_open = 1;
                    break;
                case "all":
                    data.only_open = 0;
                    break;
            }

            this.$http.post(api.actions.send_marketing_campaign_message, data).then((response) => {
                if (response.data.error == true) {
                    this.email_text_error = response.data.message;
                    this.loading = false;
                    this.preview = false;

                    return;
                }

                this.getCampaignSettings();
                this.getLastCustomNotification();
                this.loading = false;
                this.preview = false;
                this.data.title = "";
                this.data.message = "";
                this.email_text_error = "";
                messages.successMessage(messages.GLOBAL_MESSAGES.MESSAGE_SENT);
            });
        },
        getCampaignLog(type) {
            this.logDisplayType = type;

            let method = type == "sms" ? "get_marketing_campaign_log_sms" : "get_marketing_campaign_log";

            this.$http.get(api.actions[method]).then((response) => {
                if (response.data.error == true) {
                    this.campaignLog = [];
                    this.campaignLogTotal = 0;
                    this.campaignLogSMS = [];
                    this.logDisplayType = "";
                    return;
                }

                if (type == "sms") {
                    this.campaignLogSMS = response.data.logs;
                } else {
                    this.campaignLog = response.data.log;
                }

                this.campaignLogTotal = response.data.total_sent;
                $("#history-modal").modal("show");
            });
        },
        getFormattedDate(date, format) {
            return moment(date).format(format);
        },
        saveAutoreloadSmsSettings() {
            let data = {
                autoreload_sms: this.autoreload_sms,
                autoreload_sms_number: this.autoreload_sms_number,
                autoreload_sms_limit: this.autoreload_sms_limit,
            };
            this.$http.post(api.actions.save_autoreload_sms_settings, data).then((response) => {
                if (response.data.error == true) {
                    return;
                }

                messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                this.getCampaignSettings();
            });
        },
        splitText() {
            if (!this.details.message) {
                return;
            }

            const parts = this.details.message.split(":");
            if (parts.length === 2) {
                this.last_email_title_send = parts[0].trim();
                this.last_email_message_send = parts[1].trim();
            }
        },
    },
    components: {},
};
</script>
