<template>
    <div class="contact-us">
        <div class="contact-us-heading">
            <h3>{{ $ml.get("adminMenu.contactUs") }}</h3>
        </div>
        <div class="main-container section-wrap">
            <div class="contact-us-socials-textarea section-wrap">
                <div class="contact-us-container">
                    <div class="contact-us-socials">
                        <div>
                            <span> {{ $ml.get("contactUs.getEmails") }}: </span>
                            <a class="main-text-link" :href="`mailto:${generalOptions.email}`">{{
                                generalOptions.email
                            }}</a>
                        </div>
                        <div>
                            <span>Phone: +1 619-888-3886 </span>
                        </div>
                        <div>
                            <span>{{ $ml.get("marketing.instagram") }}: </span
                            ><a
                                class="main-text-link"
                                :href="`https://www.instagram.com/${generalOptions.instagram}`"
                                target="_blank"
                                >@{{ generalOptions.instagram }}</a
                            >
                        </div>
                        <div>
                            <span>
                                {{ $ml.get("contactUs.within24") }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="contact-us-textarea">
                    <form>
                        <div class="form-textarea">
                            <textarea
                                v-model="message"
                                class="text-field"
                                id="message"
                                rows="3"
                                placeholder="Message Content"
                            ></textarea>
                        </div>
                        <button v-on:click="sendMessage" type="button" class="primary-btn extra-small mobile">Send</button>
                    </form>
                </div>
            </div>
            <div v-if="!loading"><client-notifications></client-notifications></div>
        </div>
    </div>
</template>

<script>
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";

import ClientNotifications from "./client_notifications.vue";

export default {
    name: "ContactUs",
    data: function () {
        return {
            user: null,
            message: "",
            supportMessages: [],
            loading: false,
        };
    },
    mounted: function () {
        this.user = auth.user;

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        localNotificationCount: {
            get: function () {
                return store.state.localNotificationCount;
            },
            set: function (value) {
                store.commit("setLocalNotificationCount", value);
            },
        },
    },
    methods: {
        sendMessage: function () {
            this.loading = true;
            let data = {
                message: this.message,
            };
            this.$http.post(api.actions.send_message_to_support, data).then(
                function (response) {
                    this.loading = false;
                    if (response.data.error) {
                        return;
                    }
                    messages.successMessage(messages.GLOBAL_MESSAGES.MESSAGE_SENT_TO_SUPPORT);
                    this.message = "";
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
    },
    components: {
        ClientNotifications,
    },
};
</script>
<style lang="scss">
.support-message-history {
    width: 100%;
}
</style>
