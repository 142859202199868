<template>
    <div class="admin-complete-checkout">
        <!--Complete Checkout section-->
        <div v-if="user">
            <!--Main contianer-->
            <div class="transactions-fees-container">
                <div class="transactions-heading">
                    <h3>{{ $ml.get("completeCheckout.title") }}</h3>
                    <div class="paragraph-heading-container">
                        <p class="paragraph-heading">{{ $ml.get("completeCheckout.headingParagraph") }}</p>
                    </div>
                </div>
                <div class="transactions-fees-wrap">
                    <div class="transactions-container">
                        <div class="transactions section-wrap">
                            <div class="search-team-wrap">
                                <div class="appts-search-wrap">
                                    <input
                                        v-model="searchQuery"
                                        v-on:keyup="filteredItems"
                                        type="text"
                                        name="search"
                                        class="search-field text-field text-field-extra-small text-field-small"
                                        placeholder="Search by client name or services"
                                    />
                                </div>
                                <div class="team-select" v-if="adminDetails.group_owner">
                                    <div>
                                        <label for="team-sel">{{ adminDetails.group_name }}</label>
                                    </div>
                                    <div>
                                        <select
                                            id="team-sel"
                                            class="selectInput selectInputSmall selectInputExtraSmall"
                                            v-model="selectedStylistId"
                                            @change="filteredItems"
                                            :value="selectedStylistId"
                                        >
                                            <option :value="0">Show all appts.</option>
                                            <option
                                                :value="member.user_id"
                                                :key="i"
                                                v-for="(member, i) in adminDetails.group_members"
                                            >
                                                {{ member.first_name }} {{ member.last_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="nr-of-appts-wrap">
                                <div class="number-of-appts">{{ appointmentsFilters.length }} appointments</div>
                            </div>
                            <div class="section-wrap">
                                <div
                                    v-if="
                                        ((appointmentsLoaded && appointments.length === 0) ||
                                            (appointmentsFilters.length === 0 && selectedStylistId)) &&
                                        searchQuery == ''
                                    "
                                    class="no-transactions"
                                >
                                    {{ $ml.get("completeCheckout.checkedOut") }}
                                </div>
                                <div
                                    class="no-transactions"
                                    v-if="searchQuery !== '' && appointmentsFilters.length === 0"
                                >
                                    <span>{{ $ml.get("completeCheckout.noApptFound") }}</span>
                                </div>
                                <div v-if="adminDetails" class="transactions-list">
                                    <div
                                        v-for="(appt, idx) in appointmentsFilters"
                                        :key="`transaction${idx}`"
                                        class="transaction-item highlighted-box"
                                    >
                                        <div class="left-col">
                                            <div class="trans-name">{{ appt.with_name }}</div>
                                            <div class="trans-date">{{ formatEventDate(appt.date) }}</div>
                                            <div class="trans-services">{{ appt.services }}</div>
                                        </div>
                                        <div class="right-col">
                                            <div class="trans-amount" v-if="adminDetails.profile">
                                                Price {{ appt.price }} {{ adminDetails.profile.currency }}
                                            </div>
                                            <div class="trans-amount">Duration {{ appt.duration }} min</div>
                                            <div
                                                v-on:click="openAppointmentDetails(appt.id)"
                                                class="trans-appt-id transaction-button"
                                            >
                                                {{ $ml.get("completeCheckout.title") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="transaction-load-more" v-if="hideLoadMore">
                                    <div
                                        class="primary-btn extra-small mobile"
                                        v-if="!allItemsLoaded"
                                        @click="appointmentsLoadMore"
                                    >
                                        {{ $ml.get("payments.loadMore") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Complete Checkout Modal-->
        <div
            class="modal fade"
            id="completeCheckoutModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="completeCheckoutModal"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="completeCheckoutModal">{{ $ml.get("completeCheckout.title") }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <CompleteCheckoutModal
                            v-if="appointment_details && appointment_details.details"
                            :suqare_location_id="suqare_location_id"
                            :appointment_details="appointment_details"
                            @on-modal-close="onModalClose"
                        ></CompleteCheckoutModal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import moment from "moment";
import _ from "lodash";
import $ from "jquery";

import CompleteCheckoutModal from "./complete_checkout_modal.vue";

export default {
    name: "CompleteCheckout",

    data: function () {
        return {
            user: null,
            message: "",
            statements: [],
            statementsLoaded: false,
            date: moment().format("YYYY-MM-DD hh:mm:ss"),
            weeks_before: 8,
            weeks_after: 1,

            appointments: [],
            appointmentsLimit: 5,
            appointmentsOffset: 0,
            appointmentsLoaded: false,
            appointmentsDisplayedItems: [],
            allItemsLoaded: false,

            appointment_details: {},
            suqare_location_id: "",
            showSignature: false,
            selected_event: {},
            searchQuery: "",
            appointmentsFilters: [],
            hideLoadMore: false,

            selectedStylistId: 0,
        };
    },
    mounted: function () {
        this.user = auth.user;
        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });

        this.getAppointmensList();

        $("#completeCheckoutModal").on("hidden.bs.modal", this.onModalClose);
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },
    methods: {
        appointmentsLoadMore() {
            this.appointmentsDisplayedItems = _.slice(
                this.appointments,
                0,
                this.appointmentsOffset + this.appointmentsLimit
            );

            this.appointmentsOffset += this.appointmentsLimit;

            if (this.appointmentsOffset >= this.appointments.length) {
                this.allItemsLoaded = true;
            }
        },
        onAdminDetailsLoad: function () {
            this.getChargesList();
            this.getStatementsList();
        },
        getChargesList: function () {
            this.paymentsLoaded = false;
            this.$http.get(api.actions.get_stripe_charges_list).then(
                function (response) {
                    this.paymentsLoaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.orders != "undefined" &&
                        response.body.orders.length > 0
                    ) {
                        this.payments = response.body.orders;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStatementsList: function () {
            this.statementsLoaded = false;
            this.$http.get(api.actions.get_statements).then(
                function (response) {
                    this.statementsLoaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.statements != "undefined" &&
                        response.body.statements.length > 0
                    ) {
                        this.statements = response.body.statements;
                        this.statements.forEach((item) => {
                            item.items.forEach((month) => {
                                month.displayLimit = 10;
                            });
                        });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        getAppointmensList() {
            this.appointmentsLoaded = false;

            let currentDate = this.date;

            let data = {
                date: currentDate,
                weeks_before: this.weeks_before,
                weeks_after: this.weeks_after,
                include_canceled: false,
                exclude_paid: true,
                exclude_personal: true,
                order_by_date: "asc",
            };

            return this.$http.post(api.actions.get_stylist_appointments, data).then(
                function (response) {
                    this.appointmentsLoaded = true;

                    this.appointments = response.data.appointments;
                    this.appointmentsFilters = this.appointments;

                    this.appointmentsLoadMore();

                    if (response.data.error) {
                        return;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        openAppointmentDetails(id) {
            this.$http.get(api.actions.get_appointment_details + id + "?webRequest=1").then(
                function (response) {
                    if (response.data) {
                        this.appointment_details = response.data;
                        this.selectedServicesArray = response.data.services;
                        let details = response.data.details;

                        let selected_services = _.filter(response.data.services, {
                            selected: 1,
                        });
                        let selected_service_ids = _.map(selected_services, (el) => {
                            return el.service_id;
                        });

                        this.stripeCards = [];
                        this.squareCards = [];
                        this.stripe = null;
                        this.stripe_card = null;
                        this.square_card = null;
                        this.cardsLoaded = false;
                        this.addingCard = false;
                        this.stripe_client_secret = "";
                        this.getting_stripe_client_secret = false;
                        this.cards_errors = "";
                        this.amount = 0;
                        this.tips = 0;
                        this.payment_card = "";
                        this.paymentProcess = false;
                        this.showPaymentEmail = false;
                        this.showCheckout = false;

                        this.payment_email = "";
                        this.payment_email = false;
                        this.showSignature = false;
                        this.suqare_location_id = false;

                        this.amount =
                            parseFloat(this.appointment_details.total_price) -
                            parseFloat(
                                this.appointment_details.details.total_payments
                                    ? this.appointment_details.details.total_payments
                                    : 0
                            );
                        this.appointment_paid = this.appointment_details.details && this.amount <= 0 ? true : false;

                        this.payment_email = response.data.details.email;
                        this.bookData = {
                            id: details.id,
                            stylist_id: details.user_id,
                            client_id: details.client_id,
                            services: selected_service_ids,
                            date: moment(details.date).toDate(),
                            special_event: details.special_event,
                            notes: details.notes,
                            reminders: details.reminders.split(","),
                            personal: details.personal,
                        };

                        this.$http
                            .post(api.actions.get_square_locations, {
                                stylist_id: this.bookData.stylist_id,
                            })
                            .then((response) => {
                                if (response.data.error == false || response.data.locations) {
                                    this.suqare_location_id = response.data.locations[0].id;
                                }
                            });

                        this.notes = details.notes;

                        $("#completeCheckoutModal").modal("show");
                    }
                }.bind(this)
            );
        },
        filteredItems: function () {
            const query = this.searchQuery.toLowerCase().trim();

            if (this.selectedStylistId === 0) {
                this.appointmentsFilters = this.appointments;
            } else {
                this.appointmentsFilters = this.appointments.filter(
                    (appointment) => appointment.stylist_id === this.selectedStylistId
                );
            }

            this.appointmentsFilters = this.appointmentsFilters.filter(
                (appt) => appt.with_name.toLowerCase().includes(query) || appt.services.toLowerCase().includes(query)
            );
        },

        onModalClose() {
            this.appointment_details = {};
            this.getAppointmensList().then(() => {
                this.filteredItems();
            });

            $("#completeCheckoutModal").modal("hide");
        },
        formatEventDate(eventDate) {
            return moment(eventDate).format("MMMM D[,] YYYY [at] h:mm A");
        },
    },
    components: {
        CompleteCheckoutModal,
    },
};
</script>
