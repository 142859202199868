<template>
    <div class="book-component admin-book text-left">
        <h3>
            {{ $ml.get("book.title") }}
            <span v-if="headerDate && bookData.hour" class="header-timestamp"
                >{{ bookData.hour }} {{ $ml.get("general.on") }} {{ headerDate }}</span
            >
        </h3>
        <hr />
        <div class="book-progress">
            <ul>
                <li>
                    <span
                        class="book-progress-section active"
                        v-bind:class="{ selected: currentSection == 1 }"
                        v-on:click="chooseSection(1, $event)"
                        >{{ $ml.get("general.client") }}</span
                    >
                </li>
                <li><i class="fa fa-chevron-right progress-separator" aria-hidden="true"></i></li>
                <li>
                    <span
                        class="book-progress-section"
                        v-bind:class="{ active: bookData.client_id, selected: currentSection == 2 }"
                        v-on:click="chooseSection(2, $event)"
                        >{{ $ml.get("general.date") }}</span
                    >
                </li>
                <li><i class="fa fa-chevron-right progress-separator" aria-hidden="true"></i></li>
                <li>
                    <span
                        class="book-progress-section"
                        v-bind:class="{ active: bookData.client_id && bookData.date, selected: currentSection == 3 }"
                        v-on:click="chooseSection(3, $event)"
                        >{{ $ml.get("general.services") }}</span
                    >
                </li>
                <li><i class="fa fa-chevron-right progress-separator" aria-hidden="true"></i></li>
                <li v-if="isUserGroup">
                    <span
                        class="book-progress-section"
                        v-bind:class="{ active: bookData.services.length, selected: currentSection == 4 }"
                        v-on:click="chooseSection(4, $event)"
                        >{{ $ml.get("general.stylist") }}</span
                    >
                </li>
                <li v-if="isUserGroup"><i class="fa fa-chevron-right progress-separator" aria-hidden="true"></i></li>
                <li>
                    <span
                        class="book-progress-section"
                        v-bind:class="{ active: bookData.date && bookData.stylist_id, selected: currentSection == 5 }"
                        v-on:click="chooseSection(5, $event)"
                        >{{ $ml.get("general.time") }}</span
                    >
                </li>
                <li><i class="fa fa-chevron-right progress-separator" aria-hidden="true"></i></li>
                <li>
                    <span
                        class="book-progress-section"
                        v-bind:class="{ active: bookData.hour && user, selected: currentSection == 6 }"
                        v-on:click="chooseSection(6, $event)"
                        >{{ $ml.get("general.confirm") }}</span
                    >
                </li>
            </ul>
        </div>

        <div
            class="d-flex align-items-center justify-content-between my-4 pl-2"
            v-if="!showEditForm && currentSection == 1"
        >
            <div class="listing-filters d-flex align-items-center">
                <label for="client-search" class="mr-2 mt-2">{{ $ml.get("general.search") }}: </label>
                <input
                    type="text"
                    id="client-search"
                    class="form-control listing-filter"
                    v-model="search"
                    v-on:keyup.enter="
                        page = 1;
                        getClients();
                    "
                />
                <i class="ml-3 fa fa-times pointer" v-on:click="resetPage()"></i>
            </div>
            <div class="listing-filter">
                <span class="pointer ml-2" v-on:click="setFirstLetter('')">
                    {{ $ml.get("clients.all_first_letters") }}
                </span>
                <span
                    class="pointer ml-2"
                    v-for="(firstLetterFilter, index) in firstLetterFilters"
                    v-on:click="setFirstLetter(firstLetterFilter)"
                    :key="`letter-${index}`"
                    v-bind:class="{ 'text-primary': firstLetterFilter === selectedFirstLetter }"
                >
                    {{ firstLetterFilter }}
                </span>
            </div>
            <div>
                <button class="btn btn-primary" v-on:click="onNewClient">
                    <i class="fa fa-plus"></i> {{ $ml.get("general.addNew") }}
                </button>
            </div>
        </div>

        <div class="section-wrap items-edit-wrap pl-4 pt-4 mt-3" v-if="showEditForm">
            <h5 class="text-left mb-4">
                <i
                    v-on:click="
                        showEditForm = !showEditForm;
                        resetClientObj();
                    "
                    class="fa fa-chevron-left mr-3 pointer"
                ></i>
                <span v-if="!editClientObj.client_id">{{ $ml.get("clients.addNew") }}</span>
                <span v-if="editClientObj.client_id">
                    {{ $ml.get("clients.edit") }} {{ editClientObj.first_name }} {{ editClientObj.last_name }}
                </span>
            </h5>
            <div class="item-edit-fields text-left">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row d-flex align-items-center">
                            <div class="col-sm-4">{{ $ml.get("general.firstName") }} *</div>
                            <div class="col-sm-8">
                                <input class="form-control" v-model="editClientObj.first_name" />
                            </div>
                        </div>
                        <div class="row d-flex align-items-center">
                            <div class="col-sm-4">{{ $ml.get("general.lastName") }} *</div>
                            <div class="col-sm-8">
                                <input class="form-control" v-model="editClientObj.last_name" />
                            </div>
                        </div>
                        <div class="row d-flex align-items-center">
                            <div class="col-sm-4">{{ $ml.get("general.email") }}</div>
                            <div class="col-sm-8">
                                <input class="form-control" v-model="editClientObj.email" />
                            </div>
                        </div>
                        <div class="row d-flex align-items-center">
                            <div class="col-sm-4">{{ $ml.get("general.phone") }}</div>
                            <div class="col-sm-8">
                                <input class="form-control" v-model="editClientObj.phone" />
                            </div>
                        </div>
                        <div class="row" v-if="adminDetails.group_active && adminDetails.group_owner">
                            <div class="col-sm-4">{{ $ml.get("general.stylists") }}</div>
                            <div class="col-sm-8">
                                <div class="row member" v-for="(member, index) in groupMembers" v-bind:key="index">
                                    <div class="col-sm-9">{{ member.user.first_name }} {{ member.user.last_name }}</div>
                                    <div class="col-sm-1">
                                        <label class="switch">
                                            <input
                                                type="checkbox"
                                                v-bind:id="member.user.id"
                                                v-bind:value="member.user.id"
                                                v-model="editClientStylists"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <hr />
                                <button class="btn btn-primary" v-on:click="saveClient">
                                    {{ $ml.get("general.save") }}
                                </button>
                                <button
                                    class="btn btn-danger"
                                    v-on:click="
                                        showEditForm = !showEditForm;
                                        resetClientObj();
                                    "
                                >
                                    {{ $ml.get("general.cancel") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 pl-5" v-if="editClientObj.client_id">
                        <div v-if="editClientObj.appointments_total == 0">
                            <div>{{ editClientObj.appointments_total }} {{ $ml.get("clients.appointment") }}</div>
                        </div>
                        <div
                            v-if="editClientObj.appointments_total != 0 && editClientObj.future_appointments_total == 0"
                        >
                            <div>{{ editClientObj.appointments_total }} {{ $ml.get("clients.appointment") }}</div>
                            <div>
                                {{ $ml.get("clients.last_appointment_on") }}
                                {{ editClientObj.last_appointment_date | monthDateFormat }}
                            </div>
                        </div>
                        <div
                            v-if="editClientObj.appointments_total != 0 && editClientObj.future_appointments_total > 0"
                        >
                            <div>{{ editClientObj.appointments_total }} {{ $ml.get("clients.appointment") }}</div>
                            <div>
                                {{ editClientObj.future_appointments_total }}
                                {{ $ml.get("clients.upcoming_appointment_on") }} {{ editClientObj.recurringApptLine
                                }}{{ editClientObj.futureAppointmentsLine }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </div>

        <div class="book-sections">
            <div class="book-section-date-time" v-if="!showEditForm && currentSection == 1">
                <div class="clients-wrapper">
                    <div
                        class="row client"
                        v-for="(client, i) in pagedClients"
                        v-bind:key="i"
                        v-on:click="onSelectClient(client)"
                    >
                        <div class="col-sm-2 grid-col">{{ client.first_name }} {{ client.last_name }}</div>
                        <div class="col-sm-3 grid-col">{{ client.email }}</div>
                        <div class="col-sm-2 grid-col">{{ client.phone | phoneDashFormat }}</div>
                        <div class="col-sm-2 grid-col">
                            {{ client.appointments_total }} appt
                            <span v-if="client.appointments_total > 0"
                                >({{ getFormattedDate(client.last_appointment_date) }})</span
                            >
                        </div>
                        <div class="col-sm-3">
                            <div class="row m-0">
                                <div class="col-sm-6 p-0">
                                    <div v-if="client.stylist_names">
                                        <ul
                                            class="client-stylists-list"
                                            v-html="displayClientStylists(client.stylist_names)"
                                        ></ul>
                                    </div>
                                </div>
                                <div class="col-sm-2 p-0">
                                    <span
                                        class="button-edit"
                                        v-if="client.ebs == 1"
                                        v-on:click.stop="editClient(client)"
                                        ><i class="fa fa-pencil" aria-hidden="true"></i
                                    ></span>
                                </div>
                                <div class="col-sm-2 p-0">
                                    <span
                                        class="button-edit"
                                        v-if="
                                            client.ebs == 1 &&
                                            client.own_client &&
                                            (client.stylist_ids.length === 1 || adminDetails.group_owner)
                                        "
                                        v-on:click.stop="confirmDeleteClient(client)"
                                        ><i class="fa fa-times" aria-hidden="true"></i
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary" v-if="loadMore" v-on:click="getClients()">Load More</button>
            </div>
            <div class="book-section-date-time" v-if="currentSection == 2">
                <div class="datepicker-wrapper">
                    <datepicker
                        v-on:selected="onSelectDate"
                        :value="bookData.date"
                        :inline="true"
                        :disabledDates="disabledDates"
                    ></datepicker>
                </div>
            </div>
            <div class="book-section-services text-left" v-if="currentSection == 3">
                <div class="service-categories">
                    <div v-for="(category, i) in serviceCategories" v-bind:key="i">
                        <div v-if="services[category][0]">
                            <span class="category-name">{{ category }}</span>
                            <ul class="services">
                                <li v-for="(service, i) in services[category]" v-bind:key="i">
                                    <input
                                        type="checkbox"
                                        class="styled-checkbox"
                                        name="services"
                                        v-bind:id="service.id"
                                        v-bind:value="service.id"
                                        v-model="bookData.services"
                                        v-on:change="onSelectService(service)"
                                    />
                                    <label v-bind:for="service.id" class="day-label">{{ service.name }}</label>
                                    <span class="service-details"
                                        >${{ service.price }} {{ $ml.get("general.andUp") }}, {{ service.duration }}
                                        {{ $ml.get("general.minutes") }}</span
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="next-button text-right" v-if="!isUserGroup">
                    <input
                        type="button"
                        name="next"
                        value="Next"
                        class="btn btn-primary"
                        v-bind:class="{ active: bookData.services.length }"
                        v-on:click="
                            chooseSection(5, $event);
                            getStylistFreeHours(selectedStylist.user.id);
                        "
                    />
                </div>
            </div>
            <div class="book-section-stylist" v-if="currentSection == 4">
                <div class="members">
                    <div
                        class="member"
                        v-for="(member, index) in stylists"
                        v-bind:key="index"
                        v-bind:class="{ active: bookData.stylist_id == member.user.id, hidden: member.active != 1 }"
                        v-on:click="onSelectStylistServices(member)"
                    >
                        <div class="member-header-global">
                            <div class="member-avatar-global" v-if="member.profile.avatar">
                                <img v-bind:src="member.profile.avatar" />
                            </div>
                            <h5>{{ member.user.first_name }} {{ member.user.last_name }}</h5>
                        </div>
                    </div>
                </div>
                <div v-if="stylists && stylists.length == 0">{{ $ml.get("book.noStylistsAvailable") }}</div>
            </div>
            <div class="book-section-date-time" v-if="currentSection == 5">
                <div class="book-time-slots">
                    <span
                        class="time-slot"
                        v-for="(slot, index) in freeHours"
                        v-bind:key="index"
                        v-bind:class="{ selected: bookData.hour == slot }"
                        v-on:click="onSelectTime(slot)"
                        >{{ slot }}</span
                    >
                </div>
                <div v-if="freeHoursLoaded && freeHours.length == 0">{{ $ml.get("book.fullyBooked") }}</div>
            </div>
            <div class="book-section-confirm" v-if="currentSection == 6">
                <div class="confirmation-details text-left">
                    <div class="row">
                        <div class="col-sm-4">{{ $ml.get("general.client") }}:</div>
                        <div class="col-sm-8">{{ selectedClient.first_name }} {{ selectedClient.last_name }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">{{ $ml.get("general.stylist") }}:</div>
                        <div class="col-sm-8">{{ previewData.stylistName }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">{{ $ml.get("general.services") }}:</div>
                        <div class="col-sm-8">{{ previewData.services.join(",") }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">{{ $ml.get("general.date") }}:</div>
                        <div class="col-sm-8">{{ previewData.date }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">{{ $ml.get("general.time") }}:</div>
                        <div class="col-sm-8">{{ bookData.hour }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12"><hr /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">{{ $ml.get("book.specialEvent") }}:</div>
                        <div class="col-sm-8">
                            <input type="checkbox" value="1" name="special_event" v-model="bookData.special_event" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">{{ $ml.get("book.notes") }}:</div>
                        <div class="col-sm-12">
                            <textarea name="notes" rows="4" class="notes-field" v-model="bookData.notes"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">{{ $ml.get("book.reminders") }}:</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 reminder-col-header text-center">1</div>
                        <div class="col-sm-3 reminder-col-header text-center">3</div>
                        <div class="col-sm-3 reminder-col-header text-center">5</div>
                        <div class="col-sm-3 reminder-col-header text-center">10</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 text-center">
                            <label class="switch"
                                ><input
                                    type="checkbox"
                                    v-model="bookData.reminders"
                                    v-bind:value="1"
                                    class="appt-reminder" /><span class="slider round"></span
                            ></label>
                        </div>
                        <div class="col-sm-3 text-center">
                            <label class="switch"
                                ><input
                                    type="checkbox"
                                    v-model="bookData.reminders"
                                    v-bind:value="3"
                                    class="appt-reminder" /><span class="slider round"></span
                            ></label>
                        </div>
                        <div class="col-sm-3 text-center">
                            <label class="switch"
                                ><input
                                    type="checkbox"
                                    v-model="bookData.reminders"
                                    v-bind:value="5"
                                    class="appt-reminder" /><span class="slider round"></span
                            ></label>
                        </div>
                        <div class="col-sm-3 text-center">
                            <label class="switch"
                                ><input
                                    type="checkbox"
                                    v-model="bookData.reminders"
                                    v-bind:value="10"
                                    class="appt-reminder" /><span class="slider round"></span
                            ></label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12"><hr /></div>
                    </div>
                    <input
                        type="button"
                        name="book"
                        :value="$ml.get('book.bookButton')"
                        class="btn btn-primary"
                        v-on:click="saveAppointment"
                    />
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="services-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="services-modal"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            {{ $ml.get("book.additionalServices") }}:
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body book-sections">
                        <div v-if="selectedStylist" class="book-section-services text-left">
                            <div class="service-categories">
                                <div v-for="(category, i) in selectedStylist.service_categories" v-bind:key="i">
                                    <div v-if="selectedStylist.services[category.id]">
                                        <span class="category-name">{{ category.name }}</span>
                                        <ul class="services">
                                            <li
                                                v-for="(service, i) in selectedStylist.services[category.id]"
                                                v-bind:key="i"
                                                v-bind:class="{
                                                    hidden: service.restricted == 1 && service[bookData.week_day] != 1,
                                                }"
                                            >
                                                <input
                                                    type="checkbox"
                                                    class="styled-checkbox"
                                                    name="services"
                                                    v-bind:id="service.id"
                                                    v-bind:value="service.id"
                                                    v-model="additionalServices"
                                                    v-bind:disabled="additionalServices[0] == service.id"
                                                />
                                                <label v-bind:for="service.id" class="day-label">{{
                                                    service.name
                                                }}</label>
                                                <span class="service-details"
                                                    >${{ service.price }} and up, {{ service.duration }} minutes</span
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            v-on:click="onSelectStylist(selectedStylist.user, bookData.services)"
                            data-dismiss="modal"
                        >
                            {{ $ml.get("general.skip") }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            v-on:click="onSelectStylist(selectedStylist.user, additionalServices)"
                            data-dismiss="modal"
                        >
                            {{ $ml.get("general.continue") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import $ from "jquery";
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import Vue from "vue";

export default {
    name: "AdminBook",
    data: function () {
        return {
            user: null,
            members: [],
            clients: [],
            pagedClients: [],
            additionalServices: [],
            servicesMap: {},
            groupName: "",
            sections: [1, 2, 3, 4, 5, 6],
            weekDays: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
            disabledDates: {
                to: new Date(Date.now() - 8640000),
            },
            freeHoursLoaded: false,

            showEditForm: false,
            search: "",
            firstLetterFilters: [],
            selectedFirstLetter: "",
            getFirstLetterFilters: true,
            editClientStylists: [],
            page: 1,
            loadMore: false,
            headerDate: null,
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.resetData();

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
        previewData: {
            get: function () {
                return store.state.previewData;
            },
            set: function (value) {
                store.commit("setPreviewData", value);
            },
        },
        bookData: {
            get: function () {
                return store.state.bookData;
            },
            set: function (value) {
                store.commit("setBookData", value);
            },
        },
        serviceCategories: {
            get: function () {
                return store.state.serviceCategories;
            },
            set: function (value) {
                store.commit("setServiceCategories", value);
            },
        },
        services: {
            get: function () {
                return store.state.services;
            },
            set: function (value) {
                store.commit("setServices", value);
            },
        },
        stylists: {
            get: function () {
                return store.state.bookStylists;
            },
            set: function (value) {
                store.commit("setBookStylists", value);
            },
        },
        isUserGroup: {
            get: function () {
                return store.state.isUserGroup;
            },
            set: function (value) {
                store.commit("setIsUserGroup", value);
            },
        },
        currentSection: {
            get: function () {
                return store.state.currentBookSection;
            },
            set: function (value) {
                store.commit("setBookSection", value);
            },
        },
        selectedStylist: {
            get: function () {
                return store.state.selectedStylist;
            },
            set: function (value) {
                store.commit("setSelectedStylist", value);
            },
        },
        selectedClient: {
            get: function () {
                return store.state.selectedClient;
            },
            set: function (value) {
                store.commit("setSelectedClient", value);
            },
        },
        freeHours: {
            get: function () {
                return store.state.freeHours;
            },
            set: function (value) {
                store.commit("setFreeHours", value);
            },
        },
    },
    methods: {
        onAdminDetailsLoad: function () {
            //make header date
            if (this.bookData.date) {
                this.headerDate = moment(this.bookData.date).format("dddd, MMMM DD");
            }

            this.getClients();
            if (
                this.adminDetails.group_id &&
                this.adminDetails.group_active == 1 &&
                this.adminDetails.group_owner == 1
            ) {
                this.getGroupsMembers(this.adminDetails.group_id);
                this.isUserGroup = true;
                return;
            }

            let user = this.adminDetails;
            let services_array = [];
            this.members = [user];
            this.stylists = [user];
            this.selectedStylist = user;
            this.bookData.stylist_id = this.selectedStylist.user.id;
            this.previewData.stylistName = user.user.first_name + " " + user.user.last_name;
            this.services = {};

            let self = this;
            user.service_categories.map(function (categ) {
                if (self.serviceCategories.indexOf(categ.name) === -1) {
                    self.serviceCategories.push(categ.name);
                }

                self.services[categ.name] =
                    typeof self.services[categ.name] != "undefined" ? self.services[categ.name] : [];

                if (typeof user.services[categ.id] != "undefined") {
                    self.services[categ.name] = _.uniqBy(
                        _.concat(self.services[categ.name], user.services[categ.id]),
                        "id"
                    );
                    services_array = _.uniqBy(_.concat(services_array, user.services[categ.id]), "id");
                }
            });

            services_array.forEach(function (el) {
                self.servicesMap[el.id] = el;
            });

            this.serviceCategories = _.sortBy(this.serviceCategories);
        },
        getGroupsMembers: function (group_id) {
            this.serviceCategories = [];
            this.services = {};

            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        let self = this;
                        let services_array = [];
                        this.members = response.data.members;
                        this.groupName = response.data.group.name;

                        this.members.forEach(function (el) {
                            el.service_categories.map(function (categ) {
                                if (self.serviceCategories.indexOf(categ.name) === -1) {
                                    self.serviceCategories.push(categ.name);
                                }

                                self.services[categ.name] =
                                    typeof self.services[categ.name] != "undefined" ? self.services[categ.name] : [];

                                if (typeof el.services[categ.id] != "undefined") {
                                    self.services[categ.name] = _.uniqBy(
                                        _.concat(self.services[categ.name], el.services[categ.id]),
                                        "id"
                                    );
                                    services_array = _.uniqBy(_.concat(services_array, el.services[categ.id]), "id");
                                }
                                return el;
                            });
                        });

                        services_array.forEach(function (el) {
                            self.servicesMap[el.id] = el;
                        });

                        self.serviceCategories = _.sortBy(self.serviceCategories);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        isServiceDisabled: function (service) {
            return (
                (service.restricted == 1 && service[this.bookData.week_day] != 1) ||
                (service.group_id == 0 && this.freeStylists.indexOf(service.user_id) !== -1)
            );
        },
        hasEnabledServices: function (services) {
            let enabled = false;

            services.forEach((service) => {
                if (!this.isServiceDisabled(service)) {
                    enabled = true;
                }
            });

            return enabled;
        },
        getClients: function (pages = [], page = 1, currentPage = 1) {
            if (pages.length > 0) {
                this.page = page;
            }

            let data = {
                search: this.search,
                page: this.page,
                firstLetter: null,
                getFirstLetterFilters: false,
                additionalData: true,
            };

            if (this.selectedFirstLetter !== "") {
                data["firstLetter"] = this.selectedFirstLetter;
            }

            if (this.getFirstLetterFilters) {
                data["getFirstLetterFilters"] = true;
                this.getFirstLetterFilters = false;
            }

            let method = "get_clients";

            if (this.adminDetails.group_id && this.adminDetails.group_owner && this.adminDetails.group_active) {
                method = "get_group_clients";
                data.group_id = this.adminDetails.group_id;
            }

            this.$http.post(api.actions[method], data).then(
                function (response) {
                    if (typeof response.data.clients != "undefined") {
                        this.clients = response.data.clients;

                        if (this.page != 1) {
                            this.pagedClients = this.pagedClients.concat(this.clients);
                        } else {
                            //for first page, replace the results
                            this.pagedClients = this.clients;
                        }
                        this.page++;

                        //firstLetterFilter
                        if (
                            typeof response.data.firstLetterFilters != "undefined" &&
                            response.data.firstLetterFilters.length > 0
                        ) {
                            this.firstLetterFilters = response.data.firstLetterFilters;
                        }

                        page++;
                        if (pages.length > 0 && page <= currentPage) {
                            this.getClients(pages, page, currentPage);
                            return;
                        }

                        this.loadMore = response.data.loadMore;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        chooseSection: function (section, event) {
            if (this.sections.indexOf(section) === -1 || event.target.className.indexOf("active") === -1) {
                return;
            }

            this.currentSection = section;
        },
        onSelectClient: function (client) {
            this.bookData.client_id = client.id;
            this.selectedClient = client;
            this.currentSection = 2;
        },
        onSelectDate: function (date) {
            this.bookData.date = date;
            this.previewData.date = moment(date).format("MMMM Do, YYYY");
            this.bookData.week_day = this.weekDays[moment(date).day()];
            this.currentSection = 3;
        },
        onSelectService: function (service) {
            if (!this.isUserGroup) {
                let self = this;
                this.selectedServicesDuration = 0;
                this.previewData.services = [];
                this.bookData.services.forEach(function (el) {
                    self.previewData.services.push(self.servicesMap[el].name);
                    self.selectedServicesDuration += self.servicesMap[el].duration;
                });

                return;
            }

            this.bookData.services = [service.id];
            this.previewData.services = [service.name];
            this.stylists = this.members.filter(function (el) {
                return el.service_ids.indexOf(service.id) !== -1;
            });

            this.bookData.stylist_id = 0;
            this.currentSection = 4;
        },
        onSelectStylistServices: function (user) {
            this.selectedStylist = user;
            this.additionalServices = this.bookData.services;
            $("#services-modal").modal("show");
        },
        onSelectStylist: function (user, additionalServices) {
            this.bookData.stylist_id = user.id;
            this.previewData.stylistName = user.first_name + " " + user.last_name;

            this.bookData.services = additionalServices;

            let self = this;
            this.previewData.services = [];
            this.selectedServicesDuration = 0;
            this.bookData.services.forEach(function (el) {
                self.previewData.services.push(self.servicesMap[el].name);
                self.selectedServicesDuration += self.servicesMap[el].duration;
            });

            this.getStylistFreeHours(user.id);
            this.currentSection = 5;
        },
        onSelectTime: function (slot) {
            this.bookData.hour = slot;
            this.currentSection = 6;
            this.checkUserLink();
        },
        getStylistFreeHours: function (id) {
            if (!this.selectedServicesDuration) {
                let self = this;
                this.selectedServicesDuration = 0;
                this.previewData.services = [];
                this.bookData.services.forEach(function (el) {
                    self.previewData.services.push(self.servicesMap[el].name);
                    self.selectedServicesDuration += self.servicesMap[el].duration;
                });
            }

            let current_datetime = moment().format("YYYY-MM-DD h:mm a");
            let formattedDate = moment(this.bookData.date).format("YYYY-MM-DD");
            let query =
                "?include_taken=1&stylist_id=" +
                id +
                "&date=" +
                formattedDate +
                "&services_duration=" +
                this.selectedServicesDuration +
                "&current_datetime=" +
                current_datetime;
            this.freeHoursLoaded = false;
            this.freeHours = [];

            this.$http.get(api.actions.get_stylist_free_hours + query).then(
                function (response) {
                    if (typeof response.data.free_hours != "undefined") {
                        this.freeHours = response.data.free_hours;
                        this.freeHoursLoaded = true;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveAppointment: function () {
            let formattedDate = moment(this.bookData.date).format("YYYY-MM-DD");
            let data = {
                id: this.bookData.id,
                user_id: this.bookData.stylist_id,
                client_id: this.selectedClient.id,
                date_time: formattedDate,
                hour: this.bookData.hour,
                services: this.bookData.services,
                notes: this.bookData.notes,
                special_event: this.bookData.special_event,
                reminders: this.bookData.reminders.join(","),
                status: "unconfirmed",
                check_time_limit: 1,
            };

            this.$http.post(api.actions.save_appointment, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.resetData();
                        messages.successMessage(messages.GLOBAL_MESSAGES.APPOINTMENT_SAVED);
                        this.$router.push("/app");
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        checkUserLink: function () {
            if (!this.selectedStylist) {
                return;
            }

            this.$http.get(api.actions.check_user_link + "/" + this.selectedClient.id).then(
                function (response) {
                    if (response.data.linked !== true) {
                        this.$http.post(api.actions.send_invite, { phone: this.selectedClient.phone });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );

            if (this.adminDetails.group_id && this.adminDetails.group_owner && this.adminDetails.group_active) {
                let data = {
                    stylist_id: this.bookData.stylist_id,
                    client_id: this.selectedClient.id,
                };

                this.$http.post(api.actions.check_group_stylist_client_link, data);
            }
        },
        resetData: function () {
            this.bookData = {
                id: 0,
                stylist_id: 0,
                client_id: 0,
                services: [],
                date: this.bookData.date,
                hour: this.bookData.hour,
                special_event: false,
                notes: "",
                reminders: [],
            };
            this.previewData = {
                stylistName: "",
                services: [],
            };
            this.currentSection = 1;
            this.freeHours = [];
        },
        getFormattedDate(date) {
            return moment(date).format("MMMM Do");
        },
        displayClientStylists(stylists) {
            var list = stylists.split(",");
            var html = "<li>" + list.join("</li><li>") + "</li>";

            return html;
        },
        onNewClient: function () {
            this.resetClientObj();
            this.toggleOnMembers();
            this.showEditForm = true;
        },
        resetClientObj: function () {
            this.editClientObj = {
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                phone: "",

                appointments_total: 0,
                future_appointments_total: 0,
                futureAppointmentsLine: "",
                last_appointment_date: "",
                recurringApptLine: "",
            };
            this.editClientStylists = [];
        },
        toggleOnMembers: function () {
            this.groupMembers.forEach((el) => {
                this.editClientStylists.push(el.user.id);
            });
        },
        setFirstLetter: function (firstLetter) {
            this.page = 1;
            this.selectedFirstLetter = firstLetter;
            this.getClients();
        },
        resetPage: function () {
            //do nothing if search is empty
            if (!this.search) {
                return;
            }

            this.search = "";
            this.page = 1;
            this.getClients();
        },
        refreshClientList: function () {
            let currentPage = this.page - 1;
            let pages = [];
            for (let i = 1; i <= currentPage; i++) {
                pages.push(i);
            }
            this.getClients(pages, 1, currentPage);
        },
        saveClient: function () {
            let endpoint = this.editClientObj.id == 0 ? api.actions.add_client_user : api.actions.update_client_user;

            this.editClientObj.phone = this.editClientObj.phone ? this.editClientObj.phone : this.defaultPhone;
            let data = this.editClientObj;

            if (data.client_id != 0) {
                let client = _.filter(this.pagedClients, { id: data.client_id });

                //here include the group_user_id only if the logged in user is member of a group AND
                //is the admin of the group
                //and include it as the stylist id who actually owns the client
                if (client[0] && this.adminDetails.group_id && this.adminDetails.group_owner) {
                    let targetStylist = null;
                    for (let i = 0; i < client[0].stylist_ids.length; i++) {
                        targetStylist = _.filter(this.groupMembers, { user_id: client[0].stylist_ids[i] });
                        if (targetStylist[0]) {
                            break;
                        }
                    }
                    if (targetStylist) {
                        data.group_user_id = targetStylist[0]["user_id"];
                    } else {
                        //cancel the update
                        Vue.toasted.show("Something is not right. Please try again later.", {
                            type: "error",
                            position: "top-center",
                            duration: 2000,
                        });
                        return;
                    }
                }
            }

            this.$http.post(endpoint, data).then(
                function (response) {
                    if (response.data.error == false) {
                        if (this.adminDetails.group_active && this.adminDetails.group_owner) {
                            this.saveClientLink(response.data.user.id);
                            return;
                        }

                        this.showEditForm = false;
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.resetClientObj();
                        //refresh the list
                        this.refreshClientList();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveClientLink: function (client_id) {
            let data = {
                client_id: client_id,
                stylists: {},
            };

            this.groupMembers.forEach((el) => {
                data.stylists[el.user.id] = {
                    active: this.editClientStylists.indexOf(el.user.id) !== -1,
                };
            });

            this.$http.post(api.actions.save_group_stylists_client_link, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.showEditForm = false;
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.resetClientObj();

                        //refresh the list
                        this.refreshClientList();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        editClient: function (client) {
            let recurringApptLine = "";
            let futureAppointmentsLine = "";
            let self = this;
            if (client.future_appointments_total > 0) {
                if (client.recurringApptData.length > 0) {
                    let recurringApptLines = [];
                    client.recurringApptData.forEach(function (item) {
                        recurringApptLines.push(
                            self.getFormattedDate(item.apptDate) + " (recurring - " + item.apptCount + " occurrences)"
                        );
                        //August 30th (recurring - 29 occurrences)
                    });
                    recurringApptLine = recurringApptLines.join(", ");
                }

                if (client.futureAppointments.length > 0) {
                    let futureAppointmentsLines = [];
                    client.futureAppointments.forEach(function (item) {
                        futureAppointmentsLines.push(self.getFormattedDate(item.date));
                        //August 30th
                    });
                    futureAppointmentsLine = futureAppointmentsLines.join(", ") + ".";
                    if (recurringApptLine != "") {
                        futureAppointmentsLine = ", " + futureAppointmentsLine;
                    }
                }
            }

            this.editClientObj = {
                client_id: client.id,
                first_name: client.first_name,
                last_name: client.last_name,
                email: client.email,
                phone: client.phone,

                appointments_total: client.appointments_total,
                future_appointments_total: client.future_appointments_total,
                futureAppointmentsLine: futureAppointmentsLine,
                last_appointment_date: client.last_appointment_date,
                recurringApptLine: recurringApptLine,
            };

            if (typeof client.stylist_ids != "undefined") {
                this.editClientStylists = client.active_stylist_ids;
            }

            this.showEditForm = true;
        },
        confirmDeleteClient: function (client) {
            Vue.swal({
                title: this.$ml.get("adminDashboard.details.cofirmQuestion"),
                type: "warning",
                showCancelButton: true,
                confirmButtonText: this.$ml.get("clients.cofirmYes"),
                cancelButtonText: this.$ml.get("clients.cofirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.deleteClient(client);
                }
            });
        },
        deleteClient: function (client) {
            let data = {
                client_id: client.id,
            };
            this.$http.post(api.actions.delete_client, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_DELETE);
                        //refresh the list
                        this.refreshClientList();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
    },
    components: {
        Datepicker,
    },
};
</script>
