<template>
    <!--Refund and Transactions subpages-->
    <div class="admin-payments">
        <!--Refund subpage section-->
        <div v-if="user && isSectionVisible('refunds')">
            <!--Main contianer-->
            <div class="transactions-fees-container">
                <div class="transactions-heading">
                    <h3>{{ $ml.get("adminMenu.refunds") }}</h3>
                    <div class="paragraph-heading-container">
                        <p class="paragraph-heading">{{ $ml.get("payments.eligibleTransactions") }}</p>
                        <div class="show-info-modal-btn" v-on:click="showInfoModal()">
                            <i class="fa fa-info-circle info-button" aria-hidden="true"></i>
                            <div>{{ $ml.get("payments.refund") }}</div>
                        </div>
                    </div>
                </div>
                <div class="transactions-fees-wrap">
                    <div class="transactions-container">
                        <div class="transactions section-wrap">
                            <div v-if="paymentsLoaded && payments.length == 0" class="no-transactions">
                                {{ $ml.get("payments.noTransactions") }}
                            </div>
                            <div v-if="paymentsLoaded && payments.length > 0" class="transactions-list">
                                <div
                                    v-for="(trans, index) in paymentsDisplayedItems"
                                    :key="`transaction${index}`"
                                    class="transaction-item highlighted-box"
                                >
                                    <div class="left-col">
                                        <div class="trans-name">{{ trans.name }}</div>
                                        <div class="trans-date">{{ trans.date }}</div>
                                        <div
                                            v-on:click="openAppointmentDetails(trans.appointment_id)"
                                            class="trans-appt-id transaction-button"
                                        >
                                            {{ $ml.get("payments.appointmentId") }} {{ trans.appointment_id }}
                                        </div>
                                    </div>
                                    <div class="right-col">
                                        <div class="trans-amount">{{ trans.amount }}</div>
                                        <div
                                            v-on:click="refundPayment(trans.order_id)"
                                            class="trans-refund-payment transaction-button"
                                        >
                                            {{ $ml.get("payments.refund") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="transaction-load-more">
                                <div
                                    class="primary-btn extra-small mobile"
                                    v-if="paymentsHasMoreItems"
                                    @click="paymentsLoadMore"
                                >
                                    {{ $ml.get("payments.loadMore") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fees-info-container">
                        <div class="section-wrap">
                            <p>{{ $ml.get("payments.thereAreNoFees") }}</p>
                            <p>{{ $ml.get("payments.yourCustomerSees") }}</p>
                            <p>{{ $ml.get("payments.refundCannotBeCanceled") }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--Modal-->
            <div
                class="modal fade"
                id="refunds-details-modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="date-modal"
                aria-hidden="true"
                data-backdrop="false"
            >
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div>
                                <b>{{ $ml.get("payments.refund") }}</b>
                            </div>
                            <div>
                                <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div class="modal-body book-sections">
                            <p>{{ $ml.get("payments.thereAreNoFees") }}</p>
                            <p>{{ $ml.get("payments.yourCustomerSees") }}</p>
                            <p>{{ $ml.get("payments.refundCannotBeCanceled") }}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-backdrop fade show"></div>
            </div>
        </div>
        <!--Transactions subpage section-->
        <div v-if="user && isSectionVisible('transactions')" class="transactions-main-container">
            <!--Transactions main container-->
            <div class="transcations-heading">
                <h3>{{ $ml.get("adminMenu.transactions") }}</h3>
            </div>
            <!--Transactions content-->
            <div class="transactions-content">
                <div id="accordion" class="statements section-wrap">
                    <div v-if="statementsLoaded && statements.length == 0" class="no-transactions">
                        {{ $ml.get("payments.noTransactions") }}
                    </div>
                    <div v-if="statementsLoaded && statements.length > 0">
                        <div
                            v-for="(statement, index) in statements"
                            :key="`statement-year${index}`"
                            class="accordion-group statements-year highlighted-box section-wrap"
                        >
                            <div class="card-header" :id="`headingOne${index}`">
                                <div class="card-wrap">
                                    <button
                                        class="btn btn-link"
                                        data-toggle="collapse"
                                        :data-target="`#collapseOne${index}`"
                                        aria-expanded="true"
                                        :aria-controls="`collapseOne${index}`"
                                    >
                                        {{ statement.year }} - {{ $ml.get("payments.statements") }}
                                    </button>

                                    <a
                                        v-on:click="sendStatementsEmail(statement.year)"
                                        href="javascript:void(0)"
                                        class="stantements-email-action"
                                        >{{ $ml.get("payments.email") }}</a
                                    >
                                </div>
                            </div>
                            <div
                                :id="`collapseOne${index}`"
                                class="collapse"
                                :class="{ show: index == 0 }"
                                :aria-labelledby="`headingOne${index}`"
                                data-parent="#accordion"
                            >
                                <div class="card-body">
                                    <div :id="`accordion-2${index}`" class="statements-months section-wrap">
                                        <div
                                            v-for="(month, i) in statement.items"
                                            :key="`statement-month${i}`"
                                            class="card statements-month"
                                        >
                                            <div class="card-header" :id="`heading-One${i}`">
                                                <div class="card-wrap">
                                                    <button
                                                        class="btn btn-link"
                                                        data-toggle="collapse"
                                                        :data-parent="`#accordion-2${index}`"
                                                        :data-target="`#collapse-One${i}`"
                                                        aria-expanded="true"
                                                        :aria-controls="`collapse-One${i}`"
                                                    >
                                                        {{ month.month }} - {{ $ml.get("payments.statement") }}
                                                    </button>

                                                    <a
                                                        v-on:click="sendStatementsEmail(statement.year, month.month)"
                                                        href="javascript:void(0)"
                                                        class="stantements-email-action"
                                                        >Email</a
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                :id="`collapse-One${i}`"
                                                class="collapse"
                                                :aria-labelledby="`heading-One${i}`"
                                                :data-parent="`#accordion-2${index}`"
                                            >
                                                <div class="card-body">
                                                    <div class="transactions">
                                                        <div
                                                            v-if="month.orders.length > 0"
                                                            class="transactions-list section-wrap"
                                                        >
                                                            <div
                                                                v-for="(trans, k) in month.orders.slice(
                                                                    0,
                                                                    month.displayLimit
                                                                )"
                                                                :key="`transaction${index}-${k}`"
                                                                class="transaction-item highlighted-box"
                                                            >
                                                                <div class="left-col">
                                                                    <div class="trans-name">{{ trans.name }}</div>
                                                                    <div class="trans-date">{{ trans.date }}</div>
                                                                    <div class="trans-date">
                                                                        {{ trans.payment_type_text }}
                                                                    </div>

                                                                    <div
                                                                        v-if="trans.appointment_id != 0"
                                                                        v-on:click="
                                                                            openAppointmentDetails(trans.appointment_id)
                                                                        "
                                                                        class="trans-signature transaction-button"
                                                                    >
                                                                        {{ $ml.get("payments.appointmentId") }}
                                                                        {{ trans.appointment_id }}
                                                                    </div>
                                                                </div>
                                                                <div class="right-col">
                                                                    <div
                                                                        class="trans-amount"
                                                                        :class="{ refuded: trans.status == 'refunded' }"
                                                                    >
                                                                        {{ trans.amount }}
                                                                    </div>
                                                                    <div class="trans-payment-type">
                                                                        {{
                                                                            trans.status == "refunded"
                                                                                ? trans.status
                                                                                : trans.payment_type
                                                                        }}
                                                                    </div>
                                                                    <div>
                                                                        <div
                                                                            v-if="
                                                                                !isDemoClient(trans.client_id) &&
                                                                                trans.signature != '' &&
                                                                                trans.status != 'refunded'
                                                                            "
                                                                            class="trans-signature transaction-button"
                                                                            data-toggle="modal"
                                                                            data-target="#retriveSignature"
                                                                            @click="openSignatureModal(trans)"
                                                                        >
                                                                            Retrieve signature
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="transaction-load-more">
                                                                <div
                                                                    class="primary-btn extra-small mobile"
                                                                    v-if="month.orders.length > month.displayLimit"
                                                                    @click="
                                                                        month.displayLimit += 10;
                                                                        $forceUpdate();
                                                                    "
                                                                >
                                                                    {{ $ml.get("payments.loadMore") }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Retrive signature modal-->
        <div
            class="modal fade"
            id="retriveSignature"
            tabindex="-1"
            role="dialog"
            aria-labelledby="retriveSignatureLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="retriveSignatureLabel">{{ currentTransaction.name }} Signature</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body signature-container">
                        <div class="signature-wrap section-wrap">
                            <div v-if="!loading" class="signature-image">
                                <img :src="viewSignature(currentTransaction.signature)" alt="signature img" />
                            </div>
                            <div v-if="loading" class="loader-wrap">
                                <div class="loader">
                                    <i class="fa fa-3x fa-spinner fa-spin"></i>
                                </div>
                            </div>
                            <div class="signature-download">
                                <a
                                    :href="viewSignature(currentTransaction.signature) + '&download=1'"
                                    download
                                    class="primary-btn extra-small mobile"
                                    >Download</a
                                >
                            </div>
                        </div>
                        <div v-if="currentTransaction.signature == '' && !loading" class="signature-wrap section-wrap">
                            No signature found
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";
import hash from "hash.js";
import $ from "jquery";

export default {
    name: "Payments",
    props: ["sections"],
    data: function () {
        return {
            user: null,
            message: "",
            payments: [],
            paymentsLimit: 10,
            paymentsOffset: 0,
            paymentsLoaded: false,
            statements: [],
            statementsLoaded: false,
            currentTransaction: {},
            signatures: [],
            loading: false,
        };
    },
    mounted: function () {
        this.user = auth.user;
        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        paymentsDisplayedItems() {
            const keys = Object.keys(this.payments);
            return keys.slice(0, this.paymentsOffset + this.paymentsLimit).reduce((obj, key) => {
                obj[key] = this.payments[key];
                return obj;
            }, {});
        },
        paymentsHasMoreItems() {
            const keys = Object.keys(this.payments);
            return !this.allItemsLoaded && this.paymentsOffset + this.paymentsLimit < keys.length;
        },
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },
    methods: {
        paymentsLoadMore() {
            this.paymentsOffset += this.paymentsLimit;
            if (this.paymentsOffset >= Object.keys(this.payments).length) {
                this.allItemsLoaded = true;
            }
        },
        isSectionVisible(section) {
            if (!this.sections) {
                return true;
            }

            if (this.sections.indexOf(section) != -1) {
                return true;
            }

            return false;
        },
        onAdminDetailsLoad: function () {
            this.getChargesList();
            this.getStatementsList();
        },
        getChargesList: function () {
            this.paymentsLoaded = false;
            this.$http.get(api.actions.get_stripe_charges_list).then(
                function (response) {
                    this.paymentsLoaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.orders != "undefined" &&
                        response.body.orders.length > 0
                    ) {
                        this.payments = response.body.orders;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStatementsList: function () {
            this.statementsLoaded = false;
            this.$http.get(api.actions.get_statements).then(
                function (response) {
                    this.statementsLoaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.statements != "undefined" &&
                        response.body.statements.length > 0
                    ) {
                        this.statements = response.body.statements;
                        this.statements.forEach((item) => {
                            item.items.forEach((month) => {
                                month.displayLimit = 10;
                            });
                        });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        refundPayment: function (order_id) {
            this.$swal({
                title: "Are you sure you want to refund this payment?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    let data = {
                        order_id: order_id,
                    };
                    this.$http.post(api.actions.refund_stripe_charge, data).then(
                        function (response) {
                            if (response.data.error) {
                                return;
                            }
                            messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_REFUND);
                            this.getChargesList();
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                }
            });
        },
        showInfoModal() {
            $("#refunds-details-modal").modal("show");
        },
        openAppointmentDetails(id) {
            sessionStorage.setItem("openEventId", id);
            window.scrollTo(0,0);
            this.$router.push("/app");
        },
        sendStatementsEmail(year, month) {
            let monthMap = {
                Jan: 1,
                Feb: 2,
                Mar: 3,
                Apr: 4,
                May: 5,
                Jun: 6,
                Jul: 7,
                Aug: 8,
                Sep: 9,
                Oct: 10,
                Nov: 11,
                Dec: 12,
            };
            let data = {
                year: year,
            };

            if (month) {
                data.month = monthMap[month];
            }

            this.$http.post(api.actions.send_statements_to_email, data).then(
                function (response) {
                    if (response && response.data.error) {
                        messages.errorMessage(messages.ERROR_MESSAGES.GENERAL_ERROR);
                        return;
                    }
                    messages.successMessage(messages.GLOBAL_MESSAGES.STATEMENTS_EMAIL_SENT + this.user.email);
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getClientSignatures(payment_id) {
            this.loading = true;
            this.$http
                .get(api.actions.get_signatures_payment + payment_id)

                .then(
                    function (response) {
                        this.signatures = response.data.signatures;
                        this.loading = false;
                    }.bind(this),
                    function () {
                        return false;
                    }
                );
        },
        viewSignature(signature) {
            let token = hash.sha1().update(signature).digest("hex");
            return api.actions.view_signature + "?file_name=" + signature + "&token=" + token;
        },

        // openSignatureModal(transaction) {
        //     this.currentTransaction = transaction;
        //     this.getClientSignatures(transaction.payment_id);
        // },

        isDemoClient(user_id) {
            return process.env.VUE_APP_DEMO_CLIENT_ID == user_id;
        },
        openSignatureModal(transaction) {
            this.viewSignature(transaction.signature);
            this.currentTransaction = transaction;
        },
    },
    components: {},
};
</script>
