<template>
  <div class="admin-login-component container">
    <div class="login-form-wrapper">
      <h4 class="login-box-title">Login</h4>
      <div class="login-form-inner row">
        <form class="col-12">
          <div class="row my-3">
            <div class="col-12">
              <input
                class="col-12 w-100 pl-3"
                v-model="email"
                v-on:keyup.enter="onLogin"
                type="email"
                name="email"
                placeholder="Please enter your email address"
                required
              />
              <span class="input-stripe-border"></span>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-12 text-center">
              <button class="login-button" type="button" v-on:click="onLogin">
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "./../services/api_endpoints.js";

export default {
  name: "Account",
  data: function () {
    return {
      email: "",
      remember: 0,
    };
  },
  mounted: function () {
    if (process.env.VUE_APP_SHORT_URL != window.location.host) {
      window.location = process.env.VUE_APP_BASE_URL;
    }
  },
  methods: {
    onLogin: function () {
      let data = {
        email: this.email,
      };
      this.$http.post(api.actions.get_login_url_by_user_email, data).then(
        function (response) {
          if (!response.data.error && response.data.login_url) {
            window.location =
              response.data.login_url +
              "?email=" +
              encodeURIComponent(this.email);
          }
        }.bind(this)
      );
    },
  },
};
</script>

<style scoped>
label {
  /*min-width: 140px;*/
  margin-right: 20px;
  text-align: right;
}
input {
  height: 38px;
  margin-bottom: 0px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: 2px;
  background-color: #fff;
  font-size: 16px;
  text-align: left;
}
input[type="email"],
input[type="password"] {
  outline: 0;
  padding-left: 1rem !important;
}
.input-stripe-border {
  margin-bottom: 25px;
  width: 100%;
  display: block;
  border-bottom: solid 1px #07ae9c;
}
input[type="email"]:focus + .input-stripe-border,
input[type="password"]:focus + .input-stripe-border {
  border-bottom: solid 2px #07ae9c;
  margin-bottom: 24px;
}
.login-form-wrapper {
  max-width: 600px;
  margin: auto;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #f3f3f3;
  margin-top: 15px;
}
.login-form-inner {
  padding: 50px 50px 40px;
}
.login-box-title {
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dfdfdf;
  background-color: #fff;
  font-family: museo-sans, sans-serif;
  color: #3b444d;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  margin: 0;
}
.forgot-password-text a {
  transition: color 0.2s ease;
  color: #5f6c80;
}
.forgot-password-text a:hover {
  color: #08ae9c;
  text-decoration: none;
}
.action-link {
  color: #0000ff;
  cursor: pointer;
}
.login-button {
  padding-top: 15px;
  padding-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #08ae9c;
  border-radius: 2px;
  background-color: #08ae9c;
  -webkit-transition: color 200ms ease, background-color 200ms ease;
  transition: color 200ms ease, background-color 200ms ease;
  font-size: 14px;
  width: 100%;
  color: #ffffff;
}
.login-button:hover {
  background-color: transparent;
  color: #333;
}
.remember-me-wrap {
  margin-top: -5px;
}

.overlay-title {
  margin: 20px 0;
  color: #fff;
}

@media (max-width: 767px) {
  input {
    padding-left: 1rem !important;
  }
}
</style>
