<template>
    <div class="contact-profile">
        <Book v-if="aliasChecked" />
    </div>
</template>

<script>
import api from './../services/api_endpoints.js';
import auth from './../services/auth.js';
import eventBus from './../services/event_bus.js';

export default {
    name: 'Profile',
    data: function(){
        return {
            aliasChecked: false
        }
    },
    mounted: function () {
        let alias = this.$route.path.substr(1);
        this.checkStylistAlias(alias);
    },
    methods: {
        checkStylistAlias: function(alias) {
            let data = {
                alias: alias
            };

            this.$http.post(api.actions.get_stylist_by_alias, data).then(function (response) {
                if(typeof response.data.user_id!='undefined') {
                    localStorage.setItem('stylist_id',  response.data.user_id);
                    localStorage.setItem('stylist_alias',  response.data.alias);
                    auth.stylist_alias = response.data.alias;

                    if(response.data.alias != alias){
                        this.$router.push(response.data.alias);
                    }

                    this.aliasChecked = true;
                    eventBus.$emit('new_profile_loaded', response.data.alias);
                } else {
                    this.$router.push('/');
                }
            }.bind(this));
        },
    }
}
</script>
