<template>
    <div class="admin-exception-time">
        <div class="user-select-wrap">
            <div class="title">
                <h3>{{ $ml.get("exceptionTime.title") }}</h3>
            </div>
            <div class="select-wrapper" v-if="adminDetails.group_owner == 1 && adminDetails.group_active == 1">
                <label
                    class="stylist-label"
                    for="stylist-select"
                    :class="generalOptions.app_id === 5 ? 'loctician' : ''"
                    >Select
                    {{
                        generalOptions.app_id === 1
                            ? "Stylist"
                            : generalOptions.app_id === 2
                            ? "Barber"
                            : generalOptions.app_id === 5
                            ? "Loctician"
                            : ""
                    }}</label
                >
                <select
                    id="stylist-select "
                    v-model="forStylistId"
                    class="selectInput selectInputSmall"
                    @change="
                        getExceptions();
                        showform = false;
                        resetModel();
                    "
                >
                    <option value="0" disabled>{{ adminDetails.group_name }}</option>
                    <option
                        v-for="(member, index) in groupMembers"
                        v-bind:key="index"
                        v-bind:value="member.user.id"
                        v-bind:disabled="member.active != 1"
                    >
                        {{ member.user.first_name }} {{ member.user.last_name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="section-wrap exception-days-main-wrapper">
            <div class="add-free-day-form">
                <div class="item-edit-fields section-wrap" v-if="showform">
                    <div class="item-edit-fields-wrap">
                        <div>{{ $ml.get("general.date") }}</div>
                        <div class="datepicker-wrapper" :class="{ refreshingDatePicker: refreshingDatePicker }">
                            <datepicker
                                v-if="!refreshingDatePicker"
                                :initial-dates="dateExceptionDayArray"
                                v-model="exceptionDay"
                                :inline="false"
                                format="YYYY/MM/DD"
                                :disabled-dates="disabledDates"
                                switch-button-label="Full Day"
                                :switch-button-initial="true"
                                @date-applied="dateApplied"
                                :date-input="datePickerOptions"
                                @select-date="onDateSelected"
                                @datepicker-opened="onDatePickerOpened"
                                ref="datepkr"
                            ></datepicker>
                        </div>
                    </div>
                    <div class="save-free-days-btns">
                        <div>
                            <button
                                class="primary-btn extra-small mobile"
                                @click="
                                    saveExceptionDay();
                                    resetModel();
                                "
                            >
                                {{ $ml.get("general.save") }}
                            </button>
                        </div>
                        <div>
                            <button class="secondary-btn small mobile" v-on:click="resetModel()">
                                {{ $ml.get("general.cancel") }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="add-new-btn-wrap">
                    <button class="primary-btn extra-small mobile" v-if="!showform" v-on:click="openCalendar()">
                        <i class="fa fa-plus"></i> {{ $ml.get("freeTime.addNew") }}
                    </button>
                </div>
            </div>
            <div class="has-loaderr">
                <div class="content-free-days section-wrap has-loaderr">
                    <template v-for="exception in exceptions">
                        <div
                            v-if="!activeDays[exception.day.toLowerCase()] && loading == true"
                            :key="'exeption-key' + exception.day"
                        >
                            <!-- Displaying the day for each exception -->
                            <div class="section-wrap no-shadow">
                                <h5>{{ exception.day }}</h5>
                                <div class="section-wrap exception-info-wrap">
                                    <div
                                        class="info-wrapper"
                                        v-for="date in exception.dates"
                                        :key="`${exception.day}-datekey-${date.id}`"
                                    >
                                        <p>
                                            <span class="date">{{ date.dateF }}</span> from: {{ date.work_from }} to:
                                            {{ date.work_to }}
                                        </p>
                                        <div class="buttons-wrapp">
                                            <div @click="editExceptionDay(date)" class="small-button" title="Edit">
                                                {{ $ml.get("freeTime.edit") }}
                                            </div>
                                            <div
                                                @click="deleteExceptionDay(date.id)"
                                                class="small-button"
                                                title="Delete"
                                            >
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="exception.dates.length === 0" class="info-wrapper">
                                        <p>Click +Add New to add an exception day</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-if="!hasInactiveDays && loading == true">
                        <div>You don't have days off.</div>
                        <div>
                            Please click
                            <router-link :to="{ path: '/app/work-settings/service-hours' }">
                                <span class="main-text-link">here</span>
                            </router-link>
                            to see Service Hours
                        </div>
                    </div>
                    <!---->
                    <div v-if="loading != true" class="loader-wrapp">
                        <div class="loaderr">
                            <i class="fa fa-3x fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import moment from "moment";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";
import $ from "jquery";

export default {
    name: "AdminExceptionTime",
    data: function () {
        return {
            forStylistId: 0,
            exceptions: [
                { day: "Monday", dates: [] },
                { day: "Tuesday", dates: [] },
                { day: "Wednesday", dates: [] },
                { day: "Thursday", dates: [] },
                { day: "Friday", dates: [] },
                { day: "Saturday", dates: [] },
                { day: "Sunday", dates: [] },
            ],
            user: null,
            showform: false,
            exceptionDay: "",
            dateExceptionDayArray: [],

            datePickerOptions: { placeholder: "Click to choose a date" },
            fullExceptionDay: true,
            timeFrom: {},
            timeTo: {},
            exceptionDayId: 0,
            exceptionDayUserId: 0,
            isFirstTimeSet: false,
            selectedExeptionDate: "",
            work_settings: {},
            weekDays: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
            workHours: {
                sunday_from: {},
                sunday_to: {},
                sunday_block_from: {},
                sunday_block_to: {},
                monday_from: {},
                monday_to: {},
                monday_block_from: {},
                monday_block_to: {},
                tuesday_from: {},
                tuesday_to: {},
                tuesday_block_from: {},
                tuesday_block_to: {},
                wednesday_from: {},
                wednesday_to: {},
                wednesday_block_from: {},
                wednesday_block_to: {},
                thursday_from: {},
                thursday_to: {},
                thursday_block_from: {},
                thursday_block_to: {},
                friday_from: {},
                friday_to: {},
                friday_block_from: {},
                friday_block_to: {},
                saturday_from: {},
                saturday_to: {},
                saturday_block_from: {},
                saturday_block_to: {},
            },
            activeDays: {
                sunday: 0,
                monday: 0,
                tuesday: 0,
                wednesday: 0,
                thursday: 0,
                friday: 0,
                saturday: 0,
            },
            hasInactiveDays: false,
            disabledDates: {
                to: new Date(Date.now() - 8640000),

                dates: [],
                custom: (date) => {
                    let weekDay = moment(date).format("dddd").toLowerCase();

                    if (typeof this.activeDays != "undefined" && this.activeDays[weekDay]) {
                        return true;
                    }
                    return false;
                },
            },
            loading: false,
            refreshingDatePicker: false,
            latestSelectedDate: new Date(),
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.forStylistId = this.user.id;
        this.getExceptions();
        this.onAdminDetailsLoad();

        eventBus.$on("admin_details_loaded", () => {
            this.onAdminDetailsLoad();
        });

        this.getWorkSettings();
    },
    beforeDestroy() {},
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
    },
    methods: {
        getExceptions() {
            let user_id = this.forStylistId != auth.user.id ? this.forStylistId : auth.user.id;

            this.$http.get(api.actions.get_exceptions_day + "?user_id=" + user_id).then(
                function (response) {
                    let days = response.data.days;

                    this.exceptions = [
                        { day: "Monday", dates: [] },
                        { day: "Tuesday", dates: [] },
                        { day: "Wednesday", dates: [] },
                        { day: "Thursday", dates: [] },
                        { day: "Friday", dates: [] },
                        { day: "Saturday", dates: [] },
                        { day: "Sunday", dates: [] },
                    ];

                    //eslint-disable-next-line
                    for (const day of days) {
                        let d = moment.utc(day.date).format("DD");
                        let m = moment.utc(day.date).format("M") - 1;
                        let y = moment.utc(day.date).format("YYYY");

                        let fullDate = new Date(Date.UTC(y, m, d));
                        let deleteDate = moment(day.date).format("MMM DD");

                        // Find the corresponding object in the exceptions array based on the day of the week
                        let exception = this.exceptions.find(
                            (ex) => ex.day.toLowerCase() === moment(day.date).format("dddd").toLowerCase()
                        );
                        if (exception) {
                            // Push data to the corresponding exception object
                            exception.dates.push({
                                date: fullDate,
                                dateF: deleteDate,
                                id: day.id,
                                work_from: day.work_from,
                                work_to: day.work_to,
                            });
                        }
                    }
                    this.getWorkSettings();

                    //manage disabling dates for selected stylist
                    this.disabledDates.dates = [];
                    if (days && days.length) {
                        const blockedDates = days.map((element) => new Date(element.date));
                        if (blockedDates && blockedDates.length) {
                            this.disabledDates.dates = this.disabledDates.dates.concat(blockedDates);
                        }
                    }
                }.bind(this)
            );
        },
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            }
        },
        getGroupsMembers: function (group_id) {
            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        openCalendar: function () {
            this.showform = !this.showform;
        },
        deleteExceptionDay(id, dates = false) {
            let data = {};
            if (dates) {
                data.dates = dates;
            } else {
                data.id = id;
            }

            if (this.forStylistId != auth.user.id) {
                data.group_user_id = this.forStylistId;
            }
            this.$http.post(api.actions.delete_exception_day, data).then(
                function (response) {
                    if (response.data.error == false && id) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getExceptions();
                    }
                }.bind(this)
            );
        },
        getDefaultWorkIntrval: function (date) {
            var dt = moment(date);
            var weekday = dt.format("dddd").toLowerCase();

            var def_from = "9:00 AM";
            var def_to = "6:00 PM";

            if (this.forStylistId != 0 && this.adminDetails.group_active) {
                this.groupMembers.forEach((el) => {
                    if (el.user_id == this.forStylistId) {
                        this.timeFrom = this.processHourFormat(
                            el.work_settings[weekday + "_from"] ? el.work_settings[weekday + "_from"] : def_from
                        );
                        this.timeTo = this.processHourFormat(
                            el.work_settings[weekday + "_to"] ? el.work_settings[weekday + "_to"] : def_to
                        );
                    }
                });
            } else {
                this.timeFrom = this.processHourFormat(
                    this.adminDetails.work_settings[weekday + "_from"]
                        ? this.adminDetails.work_settings[weekday + "_from"]
                        : def_from
                );

                this.timeTo = this.processHourFormat(
                    this.adminDetails.work_settings[weekday + "_to"]
                        ? this.adminDetails.work_settings[weekday + "_to"]
                        : def_to
                );
            }
        },
        dateApplied: function (startDate, endDate) {
            this.startExceptionDate = startDate;
            this.endExceptionDate = endDate;
            this.isFirstTimeSet = true;
            this.selectedExeptionDate = this.startExceptionDate;
        },
        editExceptionDay: function (day) {
            this.resetModel();
            this.exceptionDay = day.date;
            this.timeFrom = this.processHourFormat(day.work_from);
            this.timeTo = this.processHourFormat(day.work_to);
            this.exceptionDayId = day.id;
            this.exceptionDayUserId = day.user_id;
            this.refreshingDatePicker = true;
            this.selectedExeptionDate = day.date;
            this.showform = true;

            setTimeout(() => {
                this.refreshingDatePicker = false;
            }, 1);

            setTimeout(() => {
                $(".vdpr-datepicker > div > input").click();
            }, 100);

            day.date = moment(day.date).format("YYYY/MM/DD");

            //make date combo for datepicker [start, end]
            this.dateExceptionDayArray = [
                moment(
                    day.date +
                        " " +
                        (this.exceptionDay
                            ? "00:00 AM"
                            : this.timeFrom["hh"] + ":" + this.timeFrom["mm"] + " " + this.timeFrom["A"]),
                    "YYYY/MM/DD LT"
                ).toDate(),
                moment(
                    day.date +
                        " " +
                        (this.exceptionDay
                            ? "11:59 PM"
                            : this.timeTo["hh"] + ":" + this.timeTo["mm"] + " " + this.timeTo["A"]),
                    "YYYY/MM/DD LT"
                ).toDate(),
            ];
            this.scrollToTop();
        },
        resetModel: function () {
            this.exceptionDay = "";
            this.fullExceptionDay = true;
            this.timeFrom = {};
            this.timeTo = {};
            this.exceptionDayId = 0;
            this.exceptionDayUserId = 0;
            this.dateExceptionDayArray = [];
            this.showform = false;
        },
        saveExceptionDay: function () {
            let data = {};
            this.selectedExeptionDate;
            data.dates = {
                0: {
                    work_from:
                        this.selectedExeptionDate != "" && !this.fullExceptionDay
                            ? moment(this.selectedExeptionDate).format("h:mm A")
                            : "",
                    date: moment(this.selectedExeptionDate).format("YYYY/MM/DD"),
                    work_to:
                        this.endExceptionDate != "" && !this.fullExceptionDay
                            ? moment(this.endExceptionDate).format("h:mm A")
                            : "",
                },
            };
            if (this.forStylistId != auth.user.id) {
                data.group_user_id = this.forStylistId;
            }
            if (this.exceptionDayId > 0) {
                let data = {
                    id: this.exceptionDayId,
                    work_from:
                        this.selectedExeptionDate != "" && !this.fullExceptionDay
                            ? moment(this.selectedExeptionDate).format("h:mm A")
                            : "",
                    date: moment(this.selectedExeptionDate).format("YYYY/MM/DD"),
                    work_to:
                        this.endExceptionDate != "" && !this.fullExceptionDay
                            ? moment(this.endExceptionDate).format("h:mm A")
                            : "",
                };

                this.$http.post(api.actions.edit_exception_day, data).then(
                    function (response) {
                        if (response.data.error == false) {
                            messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                            this.getExceptions();
                        }
                    }.bind(this)
                );
            } else {
                this.$http.post(api.actions.save_exception_days, data).then(
                    function (response) {
                        if (response.data.error == false) {
                            messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                            this.getExceptions();
                        }
                    }.bind(this)
                );
            }
        },
        getWorkSettings: function () {
            this.loading = false;
            let stylist_id = this.forStylistId != auth.user.id ? "?user_id=" + this.forStylistId : "";

            let getAllSettings = "getAllSettings=1";
            if (stylist_id != "") {
                getAllSettings = "&" + getAllSettings;
            } else {
                getAllSettings = "?" + getAllSettings;
            }

            this.$http.get(api.actions.get_work_settings + stylist_id + getAllSettings).then(
                function (response) {
                    this.loading = true;

                    if (typeof response.data.work_settings != "undefined") {
                        this.work_settings = response.data.work_settings;
                        this.work_settings_clone = Object.assign({}, this.work_settings);
                        this.work_settings.accept_new_clients = Boolean(this.work_settings.accept_new_clients);
                        this.work_settings.prevent_clients_reschedule = !Boolean(
                            this.work_settings.prevent_clients_reschedule
                        );
                        this.work_settings.prevent_clients_book = !Boolean(this.work_settings.prevent_clients_book);
                        this.settings = response.data.settings;
                        this.settings.auto_confirm_appointments = Boolean(this.settings.auto_confirm_appointments);
                        this.settings.accept_website_booking = Boolean(this.settings.accept_website_booking);
                        this.portfolio_url = response.data.portfolio_url;
                        this.timeSlotBooking = response.data.settings.default_slot_time;
                        this.notification_settings = response.data.notification_settings;
                        this.notification_settings.invite = Boolean(this.notification_settings.invite);
                        this.processHours();
                        this.hasInactiveDays = false;
                        for (let day in this.activeDays) {
                            this.activeDays[day] = this.work_settings[day];
                            if (!this.activeDays[day]) {
                                this.hasInactiveDays = true;
                            }
                        }
                    }
                }.bind(this)
            );
            this.loading = false;
        },
        processHours: function () {
            let self = this;
            this.weekDays.forEach(function (day) {
                self.workHours[day + "_from"] = self.processHourFormat(self.work_settings[day + "_from"]);
                self.workHours[day + "_to"] = self.processHourFormat(self.work_settings[day + "_to"]);
                self.workHours[day + "_block_from"] = self.processHourFormat(self.work_settings[day + "_block_from"]);
                self.workHours[day + "_block_to"] = self.processHourFormat(self.work_settings[day + "_block_to"]);
            });
        },
        processHourFormat: function (value) {
            if (!value) {
                return {
                    hh: "",
                    mm: "",
                    A: "AM",
                };
            }

            let obj = moment(value, ["hh:mh A"]);
            let hour = {
                hh: obj.format("hh"),
                mm: obj.format("mm"),
                A: obj.format("A"),
            };

            return hour;
        },
        scrollToTop() {
            const duration = 600; // Scroll duration in milliseconds
            const start = window.scrollY;
            const startTime = "now" in window.performance ? performance.now() : new Date().getTime();

            const scrollToTop = (timestamp) => {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
                window.scrollTo(0, start + (0 - start) * easeInOutCubic(progress));

                if (elapsed < duration) {
                    requestAnimationFrame(scrollToTop);
                }
            };

            requestAnimationFrame(scrollToTop);
        },
        onDateSelected(from, to) {
            let date = this.latestSelectedDate.getTime() < to.getTime() ? to : from;

            if (this.$refs.datepkr.$children[1].isAllDay) {
                this.getDefaultWorkIntrval(date);
                let fromDate =
                    moment(date).format("YYYY/MM/DD") +
                    " " +
                    (this.timeFrom["hh"] ? this.timeFrom["hh"] : "") +
                    ":" +
                    (this.timeFrom["mm"] ? this.timeFrom["mm"] : "") +
                    " " +
                    (this.timeFrom["A"] ? this.timeFrom["A"] : "");
                let toDate =
                    moment(date).format("YYYY/MM/DD") +
                    " " +
                    (this.timeTo["hh"] ? this.timeTo["hh"] : "") +
                    ":" +
                    (this.timeTo["mm"] ? this.timeTo["mm"] : "") +
                    " " +
                    (this.timeTo["A"] ? this.timeTo["A"] : "");

                this.$refs.datepkr.$children[1].applyOrSwapApply(moment(fromDate).toDate(), moment(toDate).toDate());
                this.fullExceptionDay = true;
            } else {
                this.$refs.datepkr.$children[1].applyOrSwapApply(date, date);
                this.fullExceptionDay = false;
            }

            this.latestSelectedDate = date;
        },
        onDatePickerOpened() {
            $(".vdpr-datepicker__calendar-input-date-elem").attr("readonly", true);
        },
    },
    components: {
        datepicker: DatePicker,
    },
};
</script>
