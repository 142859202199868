<template>
    <div
        id="app"
        class="fixed-menu"
        v-bind:class="{
            'rms-app': generalOptions.app_id == 1,
            'rmb-app': generalOptions.app_id == 2,
            'rml-app': generalOptions.app_id == 5,
        }"
    >
        <Header v-if="!is_admin" />
        <AdminHeader v-if="is_admin" />
        <Sidebar v-if="is_admin" />
        <div
            class="container main-container full-width"
            :class="{
                'has-side-menu': is_admin,
                'sidebar-collapsed': sidebarCollapse,
            }"
        >
            <div class="router-container">
                <router-view :key="$route.fullPath"></router-view>
            </div>
        </div>
        <Footer
            :class="{
                'admin-footer': is_admin,
                'sidebar-collapsed': sidebarCollapse,
            }"
        />
    </div>
</template>

<script>
import Header from "./components/header.vue";
import AdminHeader from "./components/admin/header.vue";
import Sidebar from "./components/admin/sidebar";
import Footer from "./components/footer.vue";
import eventBus from "./services/event_bus.js";
import api from "./services/api_endpoints.js";
import store from "./services/vuex.js";

export default {
    name: "app",
    data: function () {
        return {
            is_admin: false,
            sidebarCollapse: true,
        };
    },
    mounted: function () {
        document.title = this.generalOptions.app_title + " | " + this.generalOptions.app_name;
        this.checkAdmin();

        this.$nextTick(function () {
            var link = document.querySelector("link[rel*='icon']");
            if (this.generalOptions.app_id != 1) {
                link.href = "/fvicon-" + this.generalOptions.app_id + ".png";
            } else {
                link.href = "/fvicon.png";
            }
        });

        eventBus.$on("user_profile_loaded", this.checkAdmin);
        eventBus.$on("user_logged_out", this.checkAdmin);
        eventBus.$on("admin_route_loaded", this.checkAdmin);
        eventBus.$on("check_admin_section", this.checkAdmin);
        eventBus.$on("admin_sidebar_collapse", (collapse) => {
            this.sidebarCollapse = collapse;
        });

        var urlParams = new URLSearchParams(window.location.search);
        var params = Object.fromEntries(urlParams.entries());

        if (params.auth && params.profile) {
            window.location.href = window.location.origin + window.location.pathname;
        }

        //this.getTranslation();
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded", this.checkAdmin);
        eventBus.$off("user_logged_out", this.checkAdmin);
        eventBus.$off("admin_route_loaded", this.checkAdmin);
    },
    computed: {
        translation: {
            get: function () {
                return store.state.translation;
            },
            set: function (value) {
                store.commit("setTranslation", value);
            },
        },
    },
    methods: {
        checkAdmin: function () {
            let token = JSON.parse(localStorage.getItem("token"));

            if (this.$route.path.indexOf("/app") !== -1 && token) {
                this.is_admin = true;
            } else {
                this.is_admin = false;
            }
        },
        getTranslation: function () {
            this.$http.get(api.actions.get_translation).then(
                function (response) {
                    if (typeof response.data == "object") {
                        this.translation = response.data;
                    }
                }.bind(this)
            );
        },
    },
    components: {
        Header,
        AdminHeader,
        Sidebar,
        Footer,
    },
};
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
