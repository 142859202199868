<template>
    <!--Team Members-->
    <div class="team-members" v-if="adminDetails.group_active && adminDetails.group_owner">
        <h3>{{ $ml.get("general.members") }}</h3>
        <div class="">
            <div class="members-wrapper section-wrap" v-if="showTeamForm === false">
                <div class="invite-member-wrap">
                    <div class="invite-phone-field" v-if="invite">
                        <div class="invite-input-wrap">
                            <label for="invite-phone">{{ $ml.get("general.phone") }}: </label>
                            <input
                                class="text-field text-field-small text-field-extra-small mobile"
                                id="invite-phone"
                                placeholder="Enter phone number"
                                v-model="invitePhoneNumber"
                                v-on:keyup="filterPhone"
                                maxlength="10"
                            />
                        </div>
                        <span class="or-serapator">{{ $ml.get("general.or") }}</span>
                        <div class="invite-input-wrap">
                            <label for="invite-email">{{ $ml.get("general.email") }}: </label>
                            <input
                                class="text-field text-field-small text-field-extra-small mobile"
                                id="invite-email"
                                placeholder="Enter email"
                                v-model="inviteEmail"
                            />
                        </div>

                        <div class="btns-wrap">
                            <div>
                                <button class="primary-btn extra-small mobile" v-if="invite" v-on:click="sendInvite">
                                    <i class="fa fa-plus"></i> {{ $ml.get("general.sendInvite") }}
                                </button>
                            </div>
                            <div>
                                <button class="secondary-btn small mobile" v-if="invite" v-on:click="toggleInvite">
                                    <i class="fa fa-delete"></i>{{ $ml.get("general.cancel") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="expand-team-wrap" v-if="!invite">
                        <div>
                            <span class="expand-team">{{ $ml.get("team.expandYourTeam") }}</span>
                        </div>
                        <div class="invite-btn-wrap">
                            <div>
                                <button class="primary-btn extra-small mobile" v-if="!invite" @click="addMemberForm">
                                    <i class="fa fa-plus"></i> {{ $ml.get("general.add") }}
                                </button>
                            </div>
                            <div>
                                <button class="primary-btn extra-small mobile" v-if="!invite" v-on:click="toggleInvite">
                                    <i class="fa fa-plus"></i> {{ $ml.get("general.invite") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid-main-container section-wrap">
                    <!--Grid container-->
                    <div class="grid-members-wrapper">
                        <div class="nr-of-members-wrap">
                            <div v-if="activeMembersNumber > 0" class="number-of-members">
                                {{ activeMembersNumber }} active
                            </div>
                            <div v-if="inactiveMembersNumber - pendingMembers.length > 0" class="number-of-members">
                                {{ inactiveMembersNumber - pendingMembers.length }} inactive
                            </div>
                            <div v-if="pendingMembers.length > 0" class="number-of-members">
                                {{ pendingMembers.length }} pending
                            </div>
                        </div>

                        <div class="grid-members-container">
                            <!--Row members-->
                            <div
                                class="row-member section-wrap"
                                v-for="(member, index) in groupMembers"
                                v-bind:key="index"
                                :class="
                                    adminDetails &&
                                    showRemove &&
                                    member.activeNumber == 0 &&
                                    member.user_id != adminDetails.user.id
                                        ? 'row-member-inactive'
                                        : ''
                                "
                            >
                                <!--Color PIcker-->
                                <div
                                    v-if="showColorPicker[member.user.id]"
                                    class="color-picker"
                                    :style="{ background: color }"
                                >
                                    <sketch-picker
                                        @input="changeColor"
                                        :value="color"
                                        :preset-colors="[
                                            '#000000',
                                            '#FFFFFF',
                                            '#FF1900',
                                            '#FFE623',
                                            '#6EFF2A',
                                            '#2E81FF',
                                            '#FF89CF',
                                        ]"
                                    ></sketch-picker>
                                    <div class="colorpicker-btns-wrap">
                                        <button
                                            type="button"
                                            class="secondary-btn small extra mobile"
                                            v-on:click="closeColorPicker"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            class="secondary-btn small extra mobile"
                                            v-on:click="closeColorPicker(false)"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                <!--Member name-->
                                <div class="">
                                    <div class="member-wrap">
                                        <a
                                            v-if="member.user"
                                            target="_blank"
                                            v-bind:href="member.profile_url"
                                            class="main-text-link"
                                        >
                                            {{ member.user.first_name }} {{ member.user.last_name }}
                                            {{ member.activeNumber == 2 ? "(" + $ml.get("general.pending") + ")" : "" }}
                                            <span v-if="adminDetails.user.id == member.user.id">(ADMIN)</span>
                                        </a>
                                        <div class="active-member">
                                            <label
                                                class="switch"
                                                v-bind:class="{
                                                    'disabled-button':
                                                        member.activeNumber == 2 ||
                                                        adminDetails.user.id == member.user.id,
                                                }"
                                            >
                                                <input
                                                    type="checkbox"
                                                    v-bind:value="1"
                                                    v-model="member.active"
                                                    class="member-active"
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!--Member email-->
                                <div class="member-wrap">
                                    <div class="member-email">
                                        {{ member.user.email }}
                                    </div>
                                </div>
                                <!--Member phone-->
                                <div class="member-wrap">
                                    <div>
                                        {{ member.user.phone }}
                                    </div>
                                </div>
                                <div class="member-wrap">
                                    <div
                                        v-on:click="openColorPicker(member)"
                                        class="member-color-icon"
                                        v-bind:style="getMemberColor(member, index)"
                                    ></div>
                                    <div class="member-btn" @click="addMemberForm">stats</div>
                                    <div class="member-btn" @click="addMemberForm">edit</div>
                                    <div
                                        v-if="
                                            adminDetails &&
                                            showRemove &&
                                            member.activeNumber == 0 &&
                                            member.user_id != adminDetails.user.id
                                        "
                                    >
                                        <button
                                            v-on:click="removeMemberConfirmation(member.user_id)"
                                            type="button"
                                            class="remove-button"
                                        >
                                            {{ $ml.get("general.remove") }}
                                        </button>
                                    </div>
                                </div>
                                <!--Pending invite-->
                                <div v-if="member.activeNumber == 2" class="member-wrap">
                                    <span v-on:click="resendEmailInvite(member)" class="resend-invite">{{
                                        $ml.get("team.resendEmailInvite")
                                    }}</span>
                                    <span v-on:click="resendPhoneInvite(member)" class="resend-invite">{{
                                        $ml.get("team.resendPhoneInvite")
                                    }}</span>

                                    <div
                                        v-if="
                                            adminDetails &&
                                            member.activeNumber == 2 &&
                                            member.user_id != adminDetails.user.id
                                        "
                                        class="remove-button"
                                        v-on:click="removeMemberConfirmation(member.user_id)"
                                    >
                                        <span>{{ $ml.get("general.remove") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="save-team-member-btn"
                            v-if="
                                (adminDetails.group_id != 0 && adminDetails.group_owner) || adminDetails.group_id == 0
                            "
                        >
                            <div v-if="saveLoader">
                                <div class="loader-wrap">
                                    <i class="fa fa-3x fa-spinner fa-spin"></i>
                                </div>
                            </div>
                            <div class="">
                                <button
                                    type="button"
                                    class="primary-btn extra-small mobile"
                                    name="save-team"
                                    v-on:click="saveGroupDetails"
                                >
                                    {{ $ml.get("general.save") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-wrap coming-soon" v-if="showTeamForm === true">
                <div class="comming-soon-btn" @click="addMemberForm">
                    <div class="primary-btn extra-small mobile">Back</div>
                </div>
                <div class="section-wrap comming-soon-text"><h4>Coming soon</h4></div>
            </div>
        </div>
    </div>
</template>
<script>
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import api from "./../../services/api_endpoints.js";
import eventBus from "./../../services/event_bus.js";
import { Sketch } from "vue-color";
import messages from "./../../services/messages.js";
import general from "./../../services/general.js";
import Vue from "vue";

export default {
    data() {
        return {
            color: "#59c7f9",
            invite: false,
            invitePhoneNumber: "",
            showRemove: false,
            showColorPicker: {},
            preferredTimes: ["Morning", "Afternoon", "Evening"],
            inviteEmail: "",
            memberColorId: 0,
            showTeamForm: false,
            filterValue: 2,
            activeMembersNumber: 0,
            inactiveMembersNumber: 0,
            saveLoader: false,
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.name = this.user.first_name + " " + this.user.last_name;
        this.phone = this.user.phone;

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        if (
            typeof this.adminDetails.braintree_cards_loaded != "undefined" &&
            this.adminDetails.braintree_cards_loaded === false
        ) {
            this.$router.push("/app/subscription-payments");
        }
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
        pendingMembers() {
            return this.groupMembers.filter((member) => member.activeNumber === this.filterValue);
        },
    },

    methods: {
        toggleInvite: function () {
            this.invite = !this.invite;
            if (!this.invite) {
                this.invitePhoneNumber = "";
                this.inviteEmail = "";
            }
        },
        saveGroupDetails: function () {
            let data = {
                name: this.adminDetails.group_name,
                active: this.adminDetails.group_active ? 1 : 0,
                address: this.location.address,
                city: this.location.city,
                state: this.location.state,
                zip: this.location.zip,
                phone: this.location.phone,
                users: {},
            };

            this.groupMembers.forEach(function (el) {
                if (typeof el.user !== "undefined") {
                    data.users[el.user.id] = el.active;
                }
            });

            this.saveLoader = true;

            this.$http.post(api.actions.save_stylist_group, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.getStylistDetails(this.user.id);
                        let message = "";

                        if (response.data.updated_subscription == true) {
                            message =
                                ". " +
                                messages.GLOBAL_MESSAGES.SUBSCRIPTION_UPDATED.replace(
                                    ":num",
                                    response.data.active_members_number
                                );

                            this.activeMembers();
                            this.inActiveMembers();
                        }

                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE + message);
                        this.saveLoader = false;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getMemberColor: function (member, index) {
            if (typeof member.color == "undefined" || member.color == null || !member.color) {
                let color = general.colors[index];
                return "background-color:#" + color;
            }
            return "background-color:" + member.color;
        },
        getStylistDetails: function (id) {
            this.$http.get(api.actions.get_stylist_details + id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        // let allInfoDetails = response.data;
                        let adminDetails = response.data.details;
                        store.commit("setAdminDetails", adminDetails);
                        eventBus.$emit("admin_details_loaded");

                        if (store.state.adminDetails.group_id && store.state.adminDetails.group_owner) {
                            this.getGroupsMembers(store.state.adminDetails.group_id);
                        }
                    }
                }.bind(this)
            );
        },
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_location) {
                this.location = this.adminDetails.group_location;
            }

            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            }

            this.selectedPreferredTime = this.preferredTimes[0];

            if (typeof this.adminDetails.shop_url != "undefined" && this.adminDetails.shop_url != null) {
                this.shopUrl = this.adminDetails.shop_url;
            }

            this.getBraintreeCards();
        },
        getGroupsMembers: function (group_id) {
            this.serviceCategories = [];
            this.services = {};

            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                        this.groupMembers.forEach((el, index) => {
                            this.groupMembers[index]["activeNumber"] = el.active;
                            this.groupMembers[index]["active"] = el.active == 1 ? 1 : 0;

                            if (el.active == 0) {
                                this.showRemove = true;
                            }
                        });
                        this.activeMembers();
                        this.inActiveMembers();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getBraintreeCards() {
            this.$http.get(api.actions.get_braintree_customer_cards).then((response) => {
                this.loading = false;

                this.braintree_cards = response.data.response;
                this.braintree_cards_loaded = true;
                this.adminDetails.braintree_cards = response.data.response;
                this.adminDetails.braintree_cards_loaded = true;

                if (this.braintree_cards.length == 0) {
                    this.$router.push("/app/subscription-payments");
                }
            });
        },
        changeColor(color) {
            const { r, g, b, a } = color.rgba;
            this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        closeColorPicker(apply = true) {
            this.showColorPicker[this.memberColorId] = false;
            this.$forceUpdate();
            if (!apply) {
                return;
            }
            //send a request with member id and color to be saved
            let data = {
                color: this.color,
                member_id: this.memberColorId,
                group_id: this.adminDetails.group_id,
            };
            this.$http.post(api.actions.save_member_color, data).then(
                function (response) {
                    if (response.data.error == false) {
                        if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                            this.getGroupsMembers(this.adminDetails.group_id);
                        }
                        messages.successMessage(messages.GLOBAL_MESSAGES.MEMBER_COLOR_CHANGED);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        openColorPicker(member) {
            for (let i in this.showColorPicker) {
                if (i != member.user.id) {
                    this.showColorPicker[i] = false;
                }
            }

            this.showColorPicker[member.user.id] = !this.showColorPicker[member.user.id];
            this.memberColorId = member.user.id;

            this.$forceUpdate();
        },
        filterPhone: function () {
            this.invitePhoneNumber = this.invitePhoneNumber.replace(/[^\d]/, "");
        },
        sendInvite: function () {
            let data = {
                phone: this.invitePhoneNumber,
                group_id: this.adminDetails.group_id,
                email: this.inviteEmail,
                app_id: this.generalOptions.app_id,
            };

            this.$http.post(api.actions.send_invite, data).then(
                function (response) {
                    if (response.data.error != true) {
                        this.inviteEmail = "";
                        this.invite = false;
                        this.getGroupsMembers(this.adminDetails.group_id);
                        messages.successMessage(messages.GLOBAL_MESSAGES.RESEND_EMAIL);

                        if (this.invitePhoneNumber) {
                            let app_id = this.generalOptions.app_id;
                            let message = this.user.first_name + " " + messages.INVITE_TEXTS[app_id];
                            this.sendSMS(this.invitePhoneNumber, message);
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        sendSMS: function (phone, text) {
            let data = {
                phone: phone,
                text: text,
                email: "",
            };

            this.$http.post(api.actions.send_sms, data).then(function () {
                this.invitePhoneNumber = "";
                messages.successMessage(messages.GLOBAL_MESSAGES.RESEND_SMS);
            });
        },
        removeMemberConfirmation(memberId) {
            Vue.swal({
                title: "Are you sure you want to delete this team member?",
                text: "This action cannot be undone.",
                confirmButtonColor: "#00cdc1",
                cancelButtonColor: "#07b2c0",
                confirmButtonText: this.$ml.get("clients.cofirmYes"),
                cancelButtonText: this.$ml.get("clients.cofirmNo"),
                type: "error",
                customClass: {
                    confirmButton: "delete-account",
                    icon: "del-account-error",
                    title: "delete-account-color",
                },
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    this.removeMember(memberId);
                }
            });
        },
        removeMember: function (memberId) {
            //send a request with member id and color to be saved
            let data = {
                member_id: memberId,
                group_id: this.adminDetails.group_id,
            };
            this.$http.post(api.actions.remove_team_member, data).then(
                function (response) {
                    if (response.data.error == false) {
                        if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                            this.getGroupsMembers(this.adminDetails.group_id);
                        }
                        messages.successMessage(messages.GLOBAL_MESSAGES.MEMBER_REMOVED);
                        this.activeMembers();
                        this.inActiveMembers();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        resendPhoneInvite: function (member) {
            if (
                typeof member == "undefined" ||
                typeof member.user == "undefined" ||
                typeof member.user.phone == "undefined" ||
                member.user.phone == ""
            ) {
                return;
            }
            let phone = member.user.phone;
            this.invitePhoneNumber = phone;
            let app_id = this.generalOptions.app_id;
            let message = this.user.first_name + " " + messages.INVITE_TEXTS[app_id];
            this.sendSMS(this.invitePhoneNumber, message);
        },
        resendEmailInvite: function (member) {
            if (
                typeof member == "undefined" ||
                typeof member.user == "undefined" ||
                typeof member.user.email == "undefined" ||
                member.user.email == ""
            ) {
                return;
            }
            let email = member.user.email;
            this.inviteEmail = email;
            this.sendInvite();
        },
        SendEmail: function () {
            let data = {
                empNumber: this.selectedEmpNumber,
                date: this.teamDate,
                time: this.selectedPreferredTime,
                app_id: this.generalOptions.app_id,
                name: this.name,
                phone: this.phone,
            };
            this.teamRequestProgress = true;
            this.$http.post(api.actions.send_team_info_email, data).then(
                function (response) {
                    this.teamRequestProgress = false;
                    if (response.data.error != true) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.TEAM_REQUEST_SUCCESS);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        addMemberForm() {
            this.showTeamForm = !this.showTeamForm;
        },
        activeMembers() {
            this.activeMembersNumber = this.groupMembers.filter((member) => member.active).length;
        },

        inActiveMembers() {
            this.inactiveMembersNumber = this.groupMembers.filter((member) => !member.active).length;
        },
    },
    components: {
        "sketch-picker": Sketch,
    },
};
</script>
