<template>
    <div class="top-nav-menu-wrap" :class="{ 'sidebar-collapsed': sidebarCollapse }">
        <div class="header admin-header site-top">
            <div class="site-top-inner">
                <a v-on:click="changeSidebarState" class="nav-link" data-widget="pushmenu" href="#" role="button"
                    ><i class="fa fa-bars"></i
                ></a>
            </div>
        </div>
        <div
            v-if="adminDetails.web_trial == true && $route.path != '/app/subscription-payments'"
            class="trial-mode-notice"
        >
            <div class="trial-mode-text">
                Your account is in trial mode. Go to
                <span class="trial-subscribe"
                    ><router-link :to="{ path: '/app/subscription-payments' }"
                        >Subscription & Payments</router-link
                    ></span
                >
                to activate your subscription.
            </div>
        </div>
        <div
            class="trial-mode-notice"
            v-if="adminDetails.web_trial_expired == true && $route.path != '/app/subscription-payments'"
        >
            <div class="trial-mode-text">
                <span class="trial-subscribe">
                    Your trial has expired. Go to
                    <router-link :to="{ path: '/app/subscription-payments' }">Subscription & Payments</router-link>
                    to activate your subscription.
                </span>
            </div>
        </div>
        <div v-if="adminDetails.active == 2">
            <div class="team-group-invite-container">
                <div class="pending-group-request">
                    {{ $ml.get("general.pendingGroupRequest") }} "{{ adminDetails.group_name }}"
                </div>
                <div class="group-invite-btns">
                    <div>
                        <button class="primary-btn extra-small" v-on:click="acceptInvite(true)">Accept</button>
                    </div>
                    <div>
                        <button class="secondary-btn small" v-on:click="acceptInvite(false)">Decline</button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="
                adminDetails.sorted_payments_list &&
                adminDetails.sorted_payments_list.length > 0 &&
                adminDetails.sorted_payments_list[0]['event_type'] &&
                adminDetails.sorted_payments_list[0]['event_type'] == 'subscription_charged_unsuccessfully'
            "
            class="text-center"
        >
            <div class="col-12 subscription-charge-issue-notice">
                !Subscription charged unsuccessfully. Please Add New Card and restart your subscription!
            </div>
        </div>
        <!-- <div class="download-app-container" v-if="generalOptions.app_id === 1">
            <p>
                For a better experience feel free to download our app from<a
                    href="https://apps.apple.com/us/app/ring-my-stylist-booking-app/id1250537446"
                >
                    Apple Store</a
                >
                or<a href="https://play.google.com/store/apps/details?id=com.ringmystylist.app"> Google Play </a>
                !
            </p>
        </div>
        <div class="download-app-container" v-if="generalOptions.app_id === 2">
            <p>
                For a better experience feel free to download our app from<a
                    href="https://apps.apple.com/us/app/ring-my-barber-booking-app/id1451800946"
                >
                    Apple Store</a
                >
                or<a href="https://play.google.com/store/apps/details?id=com.ringmystylist.app.rmb"> Google Play </a>
                !
            </p>
        </div>
        <div class="download-app-container" v-if="generalOptions.app_id === 5">
            <p>
                For a better experience feel free to download our app from<a
                    href="https://apps.apple.com/us/app/ring-my-loctician-booking-app/id1558673025"
                >
                    Apple Store</a
                >
                or<a href="https://play.google.com/store/apps/details?id=com.ringmystylist.app.rml"> Google Play </a>
                !
            </p>
        </div> -->
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import $ from "jquery";
import moment from "moment";
import eventBus from "./../../services/event_bus.js";
import store from "./../../services/vuex.js";

export default {
    name: "AdminHeader",
    data: function () {
        return {
            user: null,
            isStylist: false,
            showTeam: false,
            sidebarCollapse: true,
        };
    },
    mounted: function () {
        this.user = auth.user;

        if (auth.user.role == "stylist") {
            this.isStylist = true;
            this.getCheckoutAppointmentsCount();
        }
        this.getStylistDetails(this.user.id);

        let self = this;
        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });
        eventBus.$on("reload_user_details", function () {
            self.getStylistDetails(self.user.id);
        });

        //close navbar when you click on a link
        $(document).on("click", ".navbar-collapse a", function () {
            $(".navbar-collapse").collapse("hide");
        });

        //get new messages status from support if any
        this.getSupportMessagesStatus();

        eventBus.$on("admin_sidebar_collapse", (collapse) => {
            this.sidebarCollapse = collapse;
        });
    },
    beforeDestroy() {
        // eventBus.$off('user_profile_loaded');
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        showClientsEditForm: {
            get: function () {
                return store.state.showClientsEditForm;
            },
            set: function (value) {
                store.commit("setShowClientsEditForm", value);
            },
        },
        localNotificationCount: {
            get: function () {
                return store.state.localNotificationCount;
            },
            set: function (value) {
                store.commit("setLocalNotificationCount", value);
            },
        },
        checkoutAppointentsCount: {
            get: function () {
                return store.state.checkoutAppointentsCount;
            },
            set: function (value) {
                store.commit("setCheckoutAppointentsCount", value);
            },
        },
        hasPolicyEnabled: {
            get: function () {
                return store.state.hasPolicyEnabled;
            },
            set: function (value) {
                store.commit("setHasPolicyEnabled", value);
            },
        },
    },
    methods: {
        logOut: function () {
            auth.logout(true);
            this.$router.push("/");
        },
        getCheckoutAppointmentsCount: function () {
            let data = {
                date: moment().format("YYYY-MM-DD hh:mm:ss"),
                weeks_before: 8,
                weeks_after: 1,
            };
            data = "?" + new URLSearchParams(data).toString();
            this.$http.get(api.actions.get_checkout_appointments_count + data).then(function (res) {
                this.checkoutAppointentsCount = res.data.count;
            });
        },
        getStylistDetails: function (id) {
            this.$http.get(api.actions.get_stylist_details + id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        let adminDetails = response.data.details;

                        if (adminDetails.user.role == "stylist") {
                            let user = JSON.parse(localStorage.getItem("user"));

                            if (!user.details.subscription) {
                                user.details.subscription = {};
                            }

                            if (
                                (!adminDetails.subscription ||
                                    (adminDetails.subscription && adminDetails.subscription.active == 0)) &&
                                this.$route.path != "/app/subscription-payments"
                            ) {
                                user.details.subscription.active = 0;
                                localStorage.setItem("user", JSON.stringify(user));
                                this.$router.push("/app/subscription-payments");
                            } else if (adminDetails.subscription && adminDetails.subscription.active == 1) {
                                user.details.subscription.active = 1;
                                localStorage.setItem("user", JSON.stringify(user));
                            }
                        }

                        adminDetails.subscription_active = true;

                        if (!adminDetails.group_active || (adminDetails.group_owner && adminDetails.group_active)) {
                            this.showTeam = true;
                        }
                        store.commit("setAdminDetails", adminDetails);

                        this.hasPolicyEnabled = this.adminDetails.policy_enabled;
                        eventBus.$emit("admin_details_loaded");
                    }
                }.bind(this)
            );
        },
        acceptInvite: function (decision) {
            let data = {
                stylist_response: decision,
                group_id: this.adminDetails.group_id,
            };
            this.$http.post(api.actions.accept_invite, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.getStylistDetails(this.user.id);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        showClientList: function () {
            this.showClientsEditForm = false;
        },
        getInitial: function (name) {
            if (!name) {
                return;
            }
            return name[0].toUpperCase();
        },
        getSupportMessagesStatus: function () {
            this.$http.get(api.actions.get_unread_support_messages_count).then(
                function (response) {
                    this.localNotificationCount = response.data.count;
                }.bind(this)
            );
        },
        goToSupport: function () {
            this.localNotificationCount = 0;
            this.$router.push("/app/contact-us");
        },
        changeSidebarState: function () {
            this.sidebarCollapse = !this.sidebarCollapse;
            eventBus.$emit("admin_sidebar_collapse", this.sidebarCollapse);
        },
    },
};
</script>
