<template>
    <div class="admin-team-member">
        <h3 class="page-title">{{ $ml.get("adminMenu.team") }}</h3>
        <p v-if="adminDetails.group_active" class="header-message">
            {{ $ml.get("teamMember.headerMessage").replace("%s", adminDetails.group_name) }}
        </p>

        <div v-if="adminDetails.group_active" class="section-wrap">
            <p>{{ $ml.get("teamMember.removeInfo") }}</p>
            <button
                type="button"
                class="primary-btn extra-small mobile remove-from-team-button"
                :class="{ 'action-disabled': loading }"
                v-on:click="onRemoveFromTeam"
            >
                {{ loading ? $ml.get("teamMember.removeButtonCTALoading") : $ml.get("teamMember.removeButtonCTA") }}
            </button>
        </div>
    </div>
</template>

<script>
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
import eventBus from "./../../services/event_bus.js";

export default {
    name: "AdminTeamMember",
    data: function () {
        return {
            loading: false,
        };
    },
    mounted: function () {
        if (this.adminDetails.group_active != 1 || this.adminDetails.active == 2) {
            this.$router.push("/app/profile");
        }
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },
    methods: {
        onRemoveFromTeam: function () {
            this.$swal({
                title: "Are you sure you want to exit the team?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    this.removeFromTeam();
                }
            });
        },
        removeFromTeam: function () {
            let data = {
                group_id: this.adminDetails.group_id,
            };
            this.loading = true;
            this.$http.post(api.actions.self_remove_from_team, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.LEFT_THE_TEAM);
                        eventBus.$emit("reload_user_details");
                        this.loading = false;
                        this.$router.push("/app/profile");
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
    },
};
</script>
