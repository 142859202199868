<template>
    <div v-if="user && user.role == 'stylist'" class="referral-payouts-page">
        <div class="referral-payouts-container">
            <div class="referral-payouts-heading">
                <h3>{{ $ml.get("payouts.title") }}</h3>
            </div>
            <div class="referral-payouts-content section-wrap">
                <div class="referral-payouts-section section-wrap">
                    <div class="referral-text">
                        <div>{{ $ml.get("payouts.currentBalance") }}:&nbsp;</div>
                        <div>${{ currentBalance }}</div>
                    </div>
                    <div class="referral-text">
                        <div>{{ $ml.get("payouts.paypalEmail") }}:&nbsp;</div>
                        <div v-if="!edit['paypal_email']">{{ paypal_email }}</div>
                        <div v-if="edit['paypal_email']">
                            <input
                                v-model="editValues.paypal_email"
                                type="text"
                                name="paypal_email"
                                class="text-field text-field-small"
                                placeholder="Enter Email"
                            />
                        </div>
                        <div v-if="!edit['paypal_email']" class="referral-text">
                            <span
                                v-on:click="editField('paypal_email')"
                                class="primary-btn extra-small"
                                aria-hidden="true"
                                >{{ $ml.get("payouts.edit") }}</span
                            >
                        </div>
                        <div v-if="edit['paypal_email']" class="referral-text">
                            <div>
                                <div v-on:click="saveField('paypal_email')" class="primary-btn extra-small">
                                    {{ $ml.get("payouts.save") }}
                                </div>
                            </div>
                            <div>
                                <div v-on:click="cancelField('paypal_email')" class="secondary-btn small">
                                    {{ $ml.get("payouts.cancel") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="payoutsLoaded" class="referral-payouts-section section-wrap">
                    <div>
                        <h5>{{ $ml.get("payouts.recentPayouts") }}</h5>
                    </div>
                    <div v-if="payouts.length > 0" class="payouts-row-wrap">
                        <div v-for="(item, i) in payouts" :key="`${i}_${item.id}`" class="payouts-row">
                            <div class="payouts-inner">
                                <div class="payout-text">{{ $ml.get("payouts.amount") }}:</div>
                                <div>${{ item.amount }}</div>
                            </div>
                            <div class="payouts-inner">
                                <div class="payout-text">{{ $ml.get("payouts.recipientEmail") }}:</div>
                                <div>{{ item.paypal_recipient_email }}</div>
                            </div>
                            <div class="payouts-inner">
                                <div class="payout-text">{{ $ml.get("payouts.paymentDate") }}:</div>
                                <div>{{ getFormattedDate(item.created_at, "MMMM D, Y") }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="totalPages >= page" class="load-more-wrap">
                        <div>
                            <button
                                type="button"
                                class="primary-btn extra-small"
                                v-on:click="getReferralPayouts"
                                :disabled="loading"
                            >
                                {{ $ml.get("payouts.loadMore") }}
                            </button>
                        </div>
                    </div>
                    <div v-if="payouts.length == 0" class="no-payouts-wrap section-wrap">
                        {{ $ml.get("payouts.noPayouts") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";
import moment from "moment";

export default {
    name: "Referral",
    data: function () {
        return {
            user: null,
            saving: false,
            loading: false,
            payouts: [],
            payoutsLoaded: false,
            page: 1,
            totalPages: 0,
            paypal_email: "",
            edit: {
                paypal_email: "",
            },
            editValues: {
                paypal_email: "",
            },
            currentBalance: 0,
        };
    },
    mounted: function () {
        this.user = auth.user;

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });

        this.getStylistDetails();
        this.getReferralPayouts();
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },
    methods: {
        getStylistDetails: function () {
            this.$http.get(api.actions.get_stylist_details + this.user.id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        if (response.data.details.referral_code) {
                            if (response.data.details.referral_code.terms_accepted === 0) {
                                this.$router.push("/app/profile");
                                return;
                            }

                            this.paypal_email = response.data.details.referral_code.paypal_email;
                        }

                        store.commit("setAdminDetails", response.data.details);
                        eventBus.$emit("admin_details_loaded");
                    }
                }.bind(this)
            );
        },
        getFormattedDate: function (date, format) {
            return moment(date, "YYYY-MM-DD hh:mm:ss").format(format);
        },
        getReferralPayouts: function () {
            this.loading = true;
            this.$http.get(api.actions.get_referral_payouts + "?page=" + this.page).then(
                function (response) {
                    if (!response.data.items) {
                        messages.errorMessage(messages.ERROR_MESSAGES.PAYOUT_LOAD_ERROR);
                        return;
                    }

                    this.payouts = _.concat(this.payouts, response.data.items);
                    this.page++;
                    this.totalPages = response.data.total_pages;
                    this.currentBalance = response.data.balance;
                    this.loading = false;
                    this.payoutsLoaded = true;
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        editField: function (field) {
            this.editValues[field] = this[field];
            this.edit[field] = true;
        },
        cancelField: function (field) {
            this.edit[field] = false;
        },
        saveField: function (field) {
            if (this[field] && this.editValues[field].trim() === this[field].trim()) {
                this.editValues[field] = this[field];
                this.cancelField(field);
                return;
            }

            let data = {
                [field]: this.editValues[field].trim(),
            };
            this.$http.post(api.actions.save_referral_code, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this[field] = this.editValues[field].trim();
                        this.cancelField(field);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
    },
};
</script>
