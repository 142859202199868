<template>
    <!--Per Client Restrictions Section-->
    <div class="policy-main-container" v-if="user && user.role === 'stylist'">
        <div class="header-container">
            <h3>{{ $ml.get("adminMenu.perClientRestrictions") }}</h3>
        </div>
        <div class="policy-settings-wrap section-wrap">
            <div class="policy-inner-wrap">
                <div class="clients-search-wrap has-search">
                    <input
                        v-model="searchTerm"
                        type="text"
                        name="clients-search"
                        class="search-field text-field text-field-extra-small text-field-small"
                        placeholder="Search by name email or phone"
                    />
                    <span class="clients-found" v-if="searchTerm !== '' && filterItemList.length > 0"
                        >({{ filterItemList.length }} <span v-if="filterItemList.length === 1">client</span
                        ><span v-if="filterItemList.length > 1">clients</span> found)</span
                    >
                </div>
                <div class="nr-of-clients-wrap">
                    <div
                        class="number-of-clients"
                        :class="{ 'active-filter': filterType === 'all' }"
                        @click="showFilteredPolicyUsers('all')"
                    >
                        {{ clients.length }} clients
                    </div>
                    <div
                        class="number-of-clients"
                        :class="{ 'active-filter': filterType === 'on' }"
                        @click="showFilteredPolicyUsers('on')"
                        v-if="restrictedClients.length !== filteredClients.length"
                    >
                        {{ restrictedClients.length }} with policy ON
                    </div>
                    <div
                        class="number-of-clients"
                        :class="{ 'active-filter': filterType === 'off' }"
                        @click="showFilteredPolicyUsers('off')"
                        v-if="restrictedClients.length !== filteredClients.length"
                    >
                        {{ filteredClients.length - restrictedClients.length }} with policy OFF
                    </div>
                </div>

                <div class="section-wrap no-clients" v-if="searchTerm != '' && filterItemList.length == 0">
                    {{ $ml.get("policy.noResults") }}
                </div>
                <div class="section-wrap no-clients has-loader" v-if="filterItemList.length == 0 && searchTerm == ''">
                    {{ $ml.get("policy.noClients") }}

                    <div class="loader-wrap" v-if="firstLoad !== true">
                        <div class="loader">
                            <i class="fa fa-3x fa-spinner fa-spin"></i>
                        </div>
                    </div>
                </div>
                <div class="clients-grid-container">
                    <div v-for="(client, i) in filterItemList" :key="`client-${client.id}`" class="section-wrap">
                        <div class="client-wrap">
                            <span class="client-name">{{ client.first_name }} {{ client.last_name }}</span>
                            <span class="client-appts"
                                >{{ client.appointments_last_year }} {{ $ml.get("policy.appointments") }}</span
                            >
                        </div>
                        <div class="section-wrap policy-label-option highlighted-box">
                            <div class="policy-label-row">{{ $ml.get("policy.depositCancellationPolicy") }}</div>
                            <div class="policy-options">
                                <div v-if="policy.deposit" class="policy-option-wrap">
                                    <p>{{ $ml.get("policy.applyDepositFee") }}</p>
                                    <div>
                                        <label class="switch">
                                            <input
                                                type="checkbox"
                                                v-model="client.policy.deposit"
                                                value="1"
                                                :disabled="loading"
                                                v-on:change="saveClientFeeOptions(client)"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="policy.cancel" class="policy-option-wrap">
                                    <p>{{ $ml.get("policy.applyCancellationFee") }}</p>
                                    <div>
                                        <label class="switch">
                                            <input
                                                type="checkbox"
                                                v-model="client.policy.cancel"
                                                value="1"
                                                :disabled="loading"
                                                v-on:change="saveClientFeeOptions(client)"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="policy.cancel && policy.being_late" class="policy-option-wrap">
                                    <p>{{ $ml.get("policy.applyLateFee") }}</p>
                                    <div>
                                        <label class="switch">
                                            <input
                                                type="checkbox"
                                                v-model="client.policy.being_late"
                                                value="1"
                                                :disabled="loading"
                                                v-on:change="saveClientFeeOptions(client)"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="policy.reschedule" class="policy-option-wrap">
                                    <p>{{ $ml.get("policy.applyRescheduleFee") }}</p>
                                    <div>
                                        <label class="switch">
                                            <input
                                                :disabled="loading"
                                                type="checkbox"
                                                v-model="client.policy.reschedule"
                                                value="1"
                                                v-on:change="saveClientFeeOptions(client)"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "./../../services/api_endpoints.js";
import messages from "../../services/messages.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";

export default {
    name: "PerClientRestrictions",

    data: function () {
        return {
            user: null,
            clients: [],
            filteredClients: [],
            searchTerm: "",
            restrictedClients: [],
            filterType: "all",
            allClients: [],
            firstLoad: false,
            policy: {
                deposit: 0,
                deposit_type: "",
                deposit_fee: 0,
                deposit_policy: "",
                cancel: 0,
                cancel_advance_hours: 0,
                cancel_fee: 0,
                noshow_fee: 0,
                cancel_policy: "",
                being_late: 0,
                late_minutes: 0,
                late_minutes_cancel: 0,
                late_fee: 0,
                type: "",
                age_limit: 0,
                reschedule: 0,
                reschedule_advance_hours: 0,
                reschedule_fee: 0,
                reschedule_policy: "",
            },
        };
    },

    mounted: function () {
        this.user = auth.user;
        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });
    },

    computed: {
        filterItemList() {
            let filteredClients = [];
            filteredClients = _.filter(this.clients, (client) => {
                var name = (client.first_name + " " + client.last_name).toLowerCase();
                return (
                    name.indexOf(this.searchTerm.toLowerCase()) !== -1 ||
                    client.email == this.searchTerm.toLowerCase() ||
                    client.phone == this.searchTerm
                );
            });

            if (
                this.filterType == "all" ||
                (this.policy.deposit == 0 && this.policy.cancel == 0 && this.policy.reschedule == 0)
            ) {
                return filteredClients;
            }

            let result = [];

            filteredClients.forEach((client) => {
                if (
                    (client.policy.deposit === 1 && this.policy.deposit === 1) ||
                    (client.policy.cancel === 1 && this.policy.cancel === 1) ||
                    (client.policy.being_late === 1 && this.policy.cancel === 1) ||
                    (client.policy.reschedule === 1 && this.policy.reschedule === 1)
                ) {
                    if (this.filterType == "on") {
                        result.push(client);
                    }
                } else {
                    if (this.filterType == "off") {
                        result.push(client);
                    }
                }
            });

            return result;
        },
    },

    methods: {
        onAdminDetailsLoad: function () {
            this.getStylistPolicy();
            this.getClientRestrictions();
        },
        getStylistPolicy: function () {
            this.loaded = false;
            this.$http.get(api.actions.get_stylist_policy).then(
                function (response) {
                    this.loaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.policy != "undefined"
                    ) {
                        for (let i in this.policy) {
                            if (typeof response.body.policy[i] != "undefined") {
                                this.policy[i] = response.body.policy[i];
                            }

                            if (this.policy.deposit_type == "fixed") {
                                this.deposit_fixed = this.policy.deposit_fee;
                            } else {
                                this.deposit_percentage = this.policy.deposit_fee;
                            }

                            this.stylistPolicy = response.body.policy;
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getClientRestrictions: function () {
            this.$http.post(api.actions.get_clients_list, {}).then(
                function (response) {
                    this.loaded = true;
                    this.loading = false;
                    this.firstLoad = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.clients != "undefined"
                    ) {
                        this.clients = [];

                        response.body.clients.forEach((el) => {
                            el.subClients.forEach((client) => {
                                this.clients.push(client);
                            });
                        });

                        this.filteredClients = this.clients;
                        this.allClients = this.clients;
                        this.calculateActivePolicyUsers(this.clients);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        calculateActivePolicyUsers(clients) {
            this.restrictedClients = [];

            if (this.policy.deposit == 0 && this.policy.cancel == 0 && this.policy.reschedule == 0) {
                return;
            }

            clients.forEach((client) => {
                if (
                    (client.policy.deposit === 1 && this.policy.deposit === 1) ||
                    (client.policy.cancel === 1 && this.policy.cancel === 1) ||
                    (client.policy.being_late === 1 && this.policy.cancel === 1) ||
                    (client.policy.reschedule === 1 && this.policy.reschedule === 1)
                ) {
                    this.restrictedClients.push(client);
                }
            });
        },
        saveClientFeeOptions(client) {
            let data = {
                client_id: client.id,
                being_late: client.policy.being_late ? 1 : 0,
                cancel: client.policy.cancel ? 1 : 0,
                deposit: client.policy.deposit ? 1 : 0,
                reschedule: client.policy.reschedule ? 1 : 0,
            };

            this.$http.post(api.actions.save_client_fee_options, data).then(
                function (response) {
                    if (response.data.error) {
                        return;
                    }

                    messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                    this.getClientRestrictions();
                }.bind(this),

                function () {
                    return false;
                }
            );
            this.loading = true;
        },
        showFilteredPolicyUsers(type) {
            this.filterType = type;
        },
    },

    components: {},
};
</script>
