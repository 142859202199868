import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import Toasted from "vue-toasted";
import dotenv from "dotenv";
import App from "./App.vue";
import UProfile from "./components/profile";
import Members from "./components/members";
import About from "./components/about";
import Contact from "./components/contact";
import AdminLogin from "./components/admin/login";
import AdminForgotPassword from "./components/admin/forgot_password";
import AdminChangePassword from "./components/admin/change_password";
import AdminDashboard from "./components/admin/dashboard";
import AdminShopDetails from "./components/admin/shop_details";
import AdminServices from "./components/admin/services";
import AdminSchedule from "./components/admin/schedule";
import AdminMarketing from "./components/admin/marketing";
import AdminFreeTime from "./components/admin/free_time";
import AdminExceptionTime from "./components/admin/exception_time";
import PortfolioPictures from "./components/admin/portfolio_pictures";
import PerClientRestrictions from "./components/admin/per_client_restrictions";

import AdminBusinessLicense from "./components/admin/business_license";
import Stylists from "./components/admin/stylists";
import Clients from "./components/admin/clients";
import Notifications from "./components/admin/notifications";
import OptimizeScheduling from "./components/admin/optimize_scheduling";
import AdminBook from "./components/admin/book";
import SubscriptionPayments from "./components/admin/subscription_payments";
import Referral from "./components/admin/referral";
import Payouts from "./components/admin/payouts";
import Search from "./components/search";
import Profile from "./components/admin/profile";
import CompleteCheckout from "./components/admin/complete_checkout";
import Accounts from "./components/admin/accounts";
import ContactUs from "./components/admin/contactUs";
import ClientBook from "./components/admin/client_book";
import ClientPayments from "./components/admin/client_payments";
import Reports from "./components/admin/reports";
import Payments from "./components/admin/payments";
import Policy from "./components/admin/policy";
import TeamMember from "./components/admin/team_member";
import AdminTeamMembers from "./components/admin/team_members";

import Salon from "./components/salon";
import ShortLogin from "./components/short_login";
import VueSweetalert2 from "vue-sweetalert2";
import VueLazyLoad from "vue-lazyload";
import api from "./services/api_endpoints";
import auth from "./services/auth.js";
import eventBus from "./services/event_bus.js";
import store from "./services/vuex.js";
import message from "./services/messages.js";
import config from "./services/config.js";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import "./ml";
import moment from "moment";
import Register from "./components/admin/register";
import VTooltip from "v-tooltip";
import ClientAppointmentsNotifications from "./components/admin/client_appointments_notifications";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueResource);
Vue.use(Toasted);
Vue.use(VueSweetalert2);
Vue.use(VueLazyLoad);
Vue.use(VTooltip);

dotenv.config();

/* Get the general app config */
Vue.use(config);

/* Register routes */
const routes = [
    { path: "/", component: Search },
    { path: "/about", component: About },
    { path: "/contact", component: Contact },
    { path: "/members", component: Members },
    { path: "/app", component: AdminDashboard },
    { path: "/app/login", component: AdminLogin },
    { path: "/app/register", component: Register },
    { path: "/app/forgot-password", component: AdminForgotPassword },
    { path: "/app/change-password", component: AdminChangePassword },
    { path: "/app/shop_details", component: AdminShopDetails },
    { path: "/app/team_members", component: AdminTeamMembers },

    { path: "/app/team-member", component: TeamMember },
    { path: "/app/services", component: AdminServices, props: { sections: ["services"], showProducts: false } },
    { path: "/app/products", component: AdminServices, props: { sections: ["services"], showProducts: true } },

    { path: "/app/client-appointments-notifications", component: ClientAppointmentsNotifications },

    {
        path: "/app/client-notifications-settings",
        component: Profile,
        props: { sections: ["client-notifications-settings"] },
    },

    {
        path: "/app/services/categories",
        component: AdminServices,
        props: { sections: ["categories"] },
    },

    {
        path: "/app/work-settings",
        component: AdminSchedule,
        props: { sections: ["general-settings"] },
    },

    {
        path: "/app/work-settings/service-hours",
        component: AdminSchedule,
        props: { sections: ["service-hours"] },
    },
    {
        path: "/app/work-settings/blockout-unblock",
        component: AdminSchedule,
        props: { sections: ["blockout-unblock"] },
    },
    {
        path: "/app/work-settings/business-license",
        component: AdminBusinessLicense,
    },
    { path: "/app/marketing", component: AdminMarketing },
    {
        path: "/app/marketing/push-notifications",
        component: AdminMarketing,
        props: { sections: ["push-notifications"], smsSection: false },
    },
    {
        path: "/app/marketing/text-messages",
        component: AdminMarketing,
        props: { sections: ["push-notifications"], smsSection: true },
    },
    {
        path: "/app/portfolio-pictures",
        component: PortfolioPictures,
    },
    {
        path: "/app/per-client-restrictions",
        component: PerClientRestrictions,
    },
    {
        path: "/app/marketing/special-offers",
        component: AdminMarketing,
        props: { sections: ["special-offers"] },
    },
    {
        path: "/app/marketing/social-links",
        component: AdminMarketing,
        props: { sections: ["social-links"] },
    },
    {
        path: "/app/payments/credit-cards",
        component: ClientPayments,
        props: { sections: ["credit-cards"] },
    },
    {
        path: "/app/payments/transactions",
        component: ClientPayments,
        props: { sections: ["transactions"] },
    },
    { path: "/app/block-out", component: AdminFreeTime },
    { path: "/app/exception-days", component: AdminExceptionTime },

    { path: "/app/book", component: AdminBook },
    { path: "/app/profile", component: Profile },

    { path: "/app/complete-checkout", component: CompleteCheckout },

    {
        path: "/app/payments/settings",
        component: Accounts,
        props: { sections: ["payment-settings"] },
    },
    {
        path: "/app/payments/refunds",
        component: Payments,
        props: { sections: ["refunds"] },
    },
    {
        path: "/app/payments/statements",
        component: Payments,
        props: { sections: ["transactions"] },
    },
    {
        path: "/app/policy/fees-and-policies",
        component: Policy,
        props: { sections: ["fees-and-policies"] },
    },
    {
        path: "/app/marketing/booking-widget",
        component: Accounts,
        props: { sections: ["widget-settings"] },
    },
    { path: "/app/contact-us", component: ContactUs },
    { path: "/app/stylists", component: Stylists },
    { path: "/app/payments", component: Payments },
    { path: "/app/clients", component: Clients },
    { path: "/app/work-settings/notifications", component: Notifications },
    { path: "/app/work-settings/optimize-scheduling", component: OptimizeScheduling },

    { path: "/app/subscription-payments", component: SubscriptionPayments },
    { path: "/app/referral", component: Referral },
    { path: "/app/payouts", component: Payouts },
    { path: "/app/book/:alias", component: ClientBook },
    {
        path: "/app/reports/appts",
        component: Reports,
        props: { sections: ["appts-reports"] },
    },
    {
        path: "/app/reports/products",
        component: Reports,
        props: { sections: ["products-reports"] },
    },
    { path: "/shop/:alias", component: Salon },
    { path: "/login", component: ShortLogin },
    { path: "*", component: UProfile },
    { path: "/app/*", redirect: "/app" },
    { path: "/admin", redirect: "/app" },
    { path: "/admin/*", redirect: "/app" },
];

const router = new VueRouter({
    mode: "history",
    routes: routes,
});

router.beforeEach((to, from, next) => {
    let token = JSON.parse(localStorage.getItem("token"));

    if (auth.private_routes.indexOf(to.path) !== -1 && !token) {
        router.push("/");
        return false;
    }

    if (auth.private_admin_routes.indexOf(to.path) !== -1 && !token) {
        router.push("/app/login");
        eventBus.$emit("user_logged_out");
        return false;
    }

    let user = JSON.parse(localStorage.getItem("user"));

    if (
        auth.private_admin_routes.indexOf(to.path) !== -1 &&
        to.path != "/app/subscription-payments" &&
        to.path != "/app/profile" &&
        typeof user == "object" &&
        user !== null && // Check if user is not null
        user.role == "stylist" &&
        user.details.subscription &&
        !user.details.subscription.active
    ) {
        router.push("/app/subscription-payments");
        return false;
    }

    if (to.path.indexOf("/app") != -1) {
        eventBus.$emit("admin_route_loaded");
    }

    if (token && new Date().getTime() / 1000 >= token.expire_time) {
        auth.refreshToken(next);
    } else {
        next();
    }
});

/* Auth and http interceptors */
auth.http = Vue.http;
auth.api = api;
auth.toasted = Vue.toasted;
auth.eventBus = eventBus;
auth.message = message;
auth.store = store;
auth.generalOptions = Vue.prototype.generalOptions;

Vue.http.interceptors.push(function (request, next) {
    let token = JSON.parse(localStorage.getItem("token"));

    if (!request.noApiKey) {
        request.headers.map["apikey"] = [api.API_KEY];
    }

    if (token && !request.noApiKey) {
        request.headers.map["Authorization"] = ["Bearer " + token.access_token];
    }

    next(function (response) {
        switch (response.status) {
            case 403:
            case 404:
            case 422:
                Vue.toasted.show(api.REQUEST_ERROR, {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                break;

            case 401:
                auth.logout(false);

                if (request.url.indexOf("/oauth/token") == -1) {
                    router.push("/app/login");
                }
                break;
        }

        if (typeof response.data.error != "undefined" && response.data.error === true && !request.noError) {
            Vue.toasted.show(response.data.message, {
                type: "error",
                position: "top-center",
                duration: 2000,
            });
        }
    });
});

// global filters
Vue.filter("phoneDashFormat", function (phone) {
    if (!phone) return "";
    phone = phone.toString();
    if (phone == null || phone.length < 10) {
        return phone;
    }
    var part1 = phone.substring(0, 3);
    var part2 = phone.substring(3, 6);
    var part3 = phone.substring(6, 11);
    return part1 + "-" + part2 + "-" + part3;
});
Vue.filter("capitalize", function (value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("monthDateFormat", function (date) {
    if (!date) return "";
    return moment(date).format("MMMM Do");
});
Vue.filter("dateFormat", function (date) {
    if (!date) return "";
    return moment(date).format("D MMM");
});
Vue.filter("dateFormatUS", function (date) {
    if (!date) return "";
    return moment(date).format("MMM D");
});

Vue.filter("dateFullFormat", function (date) {
    if (!date) return "";
    return moment(date).format("DD/MM/YYYY");
});

Vue.filter("hourMinutesFormat", function (minutes) {
    if (!minutes) return "N/A";

    var hours = minutes / 60;
    var rhours = Math.floor(hours);
    minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return (rhours ? rhours + "h " : "") + rminutes + " min";
});

Vue.filter("formatClientDate", function (value) {
    let today = moment().format("YYYY-MM-DD");
    let tomorrow = moment().add(1, "days");

    if (today == value) {
        return "Today";
    }

    if (tomorrow == value) {
        return "Tomorrow";
    }

    if (value) {
        return moment(String(value)).format("dddd, MMMM DD ,YYYY");
    }
});

/* Mount the main app component */
new Vue({
    render: (h) => h(App),
    router,
}).$mount("#app");
