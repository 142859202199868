<template>
    <div class="admin-free-time">
        <div class="user-select-wrap">
            <div class="title">
                <h3>{{ $ml.get("freeTime.title") }}</h3>
            </div>
            <div class="select-wrapper" v-if="adminDetails.group_owner == 1 && adminDetails.group_active == 1">
                <label
                    class="stylist-label"
                    for="stylist-select"
                    :class="generalOptions.app_id === 5 ? 'loctician' : ''"
                    >Select
                    {{
                        generalOptions.app_id === 1
                            ? "Stylist"
                            : generalOptions.app_id === 2
                            ? "Barber"
                            : generalOptions.app_id === 5
                            ? "Loctician"
                            : ""
                    }}</label
                >
                <select
                    id="stylist-select"
                    v-model="for_stylist_id"
                    v-on:change="onStylistChange"
                    class="selectInput selectInputSmall"
                >
                    <option value="0">{{ adminDetails.group_name }}</option>
                    <option
                        v-for="(member, index) in groupMembers"
                        v-bind:key="index"
                        v-bind:value="member.user.id"
                        v-bind:disabled="member.active != 1"
                    >
                        {{ member.user.first_name }} {{ member.user.last_name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="content-free-days section-wrap has-loaderr">
            <div class="add-free-day-form">
                <div class="item-edit-fields section-wrap" v-if="show_form">
                    <div class="item-edit-fields-wrap">
                        <div>{{ $ml.get("general.date") }}</div>
                        <div class="datepicker-wrapper" :class="{ refreshingDatePicker: refreshingDatePicker }">
                            <datepicker
                                v-if="!refreshingDatePicker"
                                :initial-dates="dp_free_day"
                                v-model="free_day"
                                :inline="false"
                                format="YYYY/MM/DD"
                                :disabled-dates="disabledDates"
                                v-on:selected="getDefaultWorkIntrval"
                                switch-button-label="Full Day"
                                :switch-button-initial="true"
                                @date-applied="dateApplied"
                                :date-input="datePickerOptions"
                                @datepicker-opened="onDatePickerOpened"
                            ></datepicker>
                        </div>
                    </div>
                    <div class="save-free-days-btns">
                        <div>
                            <button class="primary-btn extra-small mobile" v-on:click="saveFreeDay">
                                {{ $ml.get("general.save") }}
                            </button>
                        </div>
                        <div>
                            <button
                                class="secondary-btn small mobile"
                                v-on:click="
                                    show_form = !show_form;
                                    resetModel();
                                "
                            >
                                {{ $ml.get("general.cancel") }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="add-new-btn-wrap">
                    <button class="primary-btn extra-small mobile" v-if="!show_form" v-on:click="openCalendar()">
                        <i class="fa fa-plus"></i> {{ $ml.get("freeTime.addNew") }}
                    </button>
                </div>
            </div>
            <div class="free-days section-wrap">
                <div v-if="loaded && !group_free_days" class="free-days-wrapper">
                    <div v-if="loaded" class="free-day-item" v-for="(day, index) in free_days" v-bind:key="index">
                        <div>{{ day.date }}</div>
                        <div>
                            <span v-if="day.modified">{{ day.free_from }}</span>
                        </div>
                        <div>
                            <span v-if="day.modified">{{ day.free_to }}</span>
                        </div>
                        <div class="buttons-wrapp">
                            <div
                                class="small-button"
                                title="Edit"
                                v-on:click="
                                    editFreeDay(day);
                                    scrollToTop();
                                "
                            >
                                {{ $ml.get("freeTime.edit") }}
                            </div>
                            <div class="small-button" title="Delete" v-on:click="deleteFreeDay(day)">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div class="no-free-days" v-if="free_days.length == 0">
                        {{ $ml.get("freeTime.noFreeDays") }}
                    </div>
                </div>
                <div v-if="loaded && group_free_days" class="member-items-wrap">
                    <template v-for="(member, index) in groupMembers">
                        <div class="member-item section-wrap" :class="member.active != 1 ? 'disabled-button' : ''">
                            <div class="member-name">{{ member.user.first_name }} {{ member.user.last_name }}</div>
                            <div class="free-days-wrapper">
                                <div class="free-day-item-header">
                                    <div>{{ $ml.get("general.date") }}</div>
                                    <div v-if="freeTimeAvailable">{{ $ml.get("general.freeFrom") }}</div>
                                    <div v-if="freeTimeAvailable">{{ $ml.get("general.freeTo") }}</div>
                                </div>
                                <div
                                    class="free-day-item"
                                    v-for="(day, i) in group_free_days[member.user.id]"
                                    v-bind:key="i"
                                >
                                    <div>{{ day.date }}</div>
                                    <div>
                                        <span v-if="day.modified">{{ day.free_from }}</span>
                                    </div>
                                    <div>
                                        <span v-if="day.modified">{{ day.free_to }}</span>
                                    </div>
                                    <div class="buttons-wrapp">
                                        <div
                                            class="small-button"
                                            v-on:click="
                                                editFreeDay(day);
                                                scrollToTop();
                                            "
                                        >
                                            {{ $ml.get("freeTime.edit") }}
                                        </div>
                                        <div class="small-button" title="Delete" v-on:click="deleteFreeDay(day)">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!group_free_days[member.user.id]" class="no-free-days">
                                    {{ $ml.get("freeTime.noFreeDays") }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-if="loaded != true" class="loader-wrapp">
                    <div class="loaderr">
                        <i class="fa fa-3x fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
import moment from "moment";
import $ from "jquery";
import eventBus from "./../../services/event_bus.js";
import DatePicker from "vue-time-date-range-picker/dist/vdprDatePicker";

export default {
    name: "AdminFreeTime",
    data: function () {
        return {
            user: null,
            free_days: [],
            group_free_days: null,
            loaded: false,
            show_form: false,
            free_day: "",
            free_day_id: 0,
            free_day_user_id: 0,
            full_day: true,
            time_from: {},
            time_to: {},
            for_stylist_id: 0,
            activeDays: {
                sunday: 0,
                monday: 0,
                tuesday: 0,
                wednesday: 0,
                thursday: 0,
                friday: 0,
                saturday: 0,
            },
            disabledDates: {
                to: new Date(Date.now() - 8640000),

                dates: [],
                custom: (date) => {
                    let weekDay = moment(date).format("dddd").toLowerCase();

                    if (typeof this.activeDays != "undefined" && this.activeDays[weekDay]) {
                        return true;
                    }
                    return false;
                },
            },

            freeTimeAvailable: false,
            startFreeDate: null,
            endFreeDate: null,
            dp_free_day: [],
            datePickerOptions: { placeholder: "Click to choose a date" },
            isFirstTimeSet: true,
            refreshingDatePicker: false,
        };
    },
    mounted: function () {
        this.user = auth.user;

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        $(document).on(
            "keyup",
            ".vdpr-datepicker__calendar-input-wrapper--end:nth-child(3) .vdpr-datepicker__calendar-input-time-elem",
            function () {
                let value = parseInt($(this).val());

                if (!self.isFirstTimeSet || value == 0 || value > 23) {
                    return;
                }

                let controlsWrap = $(this).siblings(".vdpr-datepicker__calendar-input-time-control");

                for (let i = 0; i <= value; i++) {
                    controlsWrap.find(".vdpr-datepicker__calendar-input-time-control-up").click();
                }

                self.isFirstTimeSet = false;
            }
        );
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
    },
    methods: {
        scrollToIndex(index) {
            // Get the element that was clicked using the ref
            const scrollItems = this.$refs.scrollItems;

            // Check if the index is valid (within the array bounds)
            if (index >= 0 && index < scrollItems.length) {
                // Get the element at the specified index
                const targetElement = scrollItems[index];

                // Scroll to the top position of the element
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
        },

        scrollToTop() {
            window.scroll({ top: 0, left: 0, behavior: "smooth" });
        },
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            } else {
                this.getFreeDays();
            }
        },
        getGroupsMembers: function (group_id) {
            this.serviceCategories = [];
            this.services = {};

            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                        this.getGroupFreeDays();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getFreeDays: function () {
            this.loaded = false;
            let stylist_id =
                this.for_stylist_id && this.for_stylist_id != auth.user.id ? "?user_id=" + this.for_stylist_id : "";

            this.$http.get(api.actions.get_free_days + stylist_id).then(
                function (response) {
                    if (typeof response.data.days != "undefined") {
                        this.free_days = response.data.days;
                        this.checkAvailabilityOfFreeTime(this.free_days);

                        //manage disabling dates for selected stylist
                        this.disabledDates.dates = [];
                        if (this.free_days && this.free_days.length) {
                            const blockedDates = this.free_days.map((element) => new Date(element.date));
                            if (blockedDates && blockedDates.length) {
                                this.disabledDates.dates = this.disabledDates.dates.concat(blockedDates);
                            }
                        }

                        let workSettings = this.adminDetails.work_settings;

                        if (this.for_stylist_id != 0) {
                            this.groupMembers.forEach((el) => {
                                if (el.user_id == this.for_stylist_id) {
                                    workSettings = el.work_settings;
                                }
                            });
                        }

                        for (let day in this.activeDays) {
                            this.activeDays[day] = !workSettings[day];
                        }

                        this.loaded = true;
                    }
                }.bind(this)
            );
        },
        getGroupFreeDays: function () {
            this.loaded = false;

            this.$http.get(api.actions.get_group_free_days + "?group_id=" + this.adminDetails.group_id).then(
                function (response) {
                    this.group_free_days = {};
                    if (typeof response.data.days != "undefined") {
                        this.group_free_days = response.data.days;
                        this.checkAvailabilityOfFreeTime(this.group_free_days);

                        //manage disabling dates for selected stylist
                        this.disabledDates.dates = [];
                        let selectedStylistId = this.adminDetails.user.id;
                        if (this.for_stylist_id != 0) {
                            selectedStylistId = this.for_stylist_id;
                        }
                        if (
                            this.group_free_days &&
                            this.group_free_days[selectedStylistId] &&
                            this.group_free_days[selectedStylistId].length
                        ) {
                            const blockedDates = this.group_free_days[selectedStylistId].map(
                                (element) => new Date(element.date)
                            );
                            if (blockedDates && blockedDates.length) {
                                this.disabledDates.dates = this.disabledDates.dates.concat(blockedDates);
                            }
                        }

                        for (let day in this.activeDays) {
                            this.activeDays[day] = !this.adminDetails.work_settings[day];
                        }

                        this.loaded = true;
                    }
                }.bind(this)
            );
        },
        saveFreeDay: function () {
            let startFreeDateString = moment(this.startFreeDate).format("YYYY/MM/DD");
            let endFreeDateString = moment(this.endFreeDate).format("YYYY/MM/DD");
            let startFreeTimeObject = moment(this.startFreeDate).format("hh:mm A");
            let endFreeTimeObject = moment(this.endFreeDate).format("hh:mm A");

            if (startFreeDateString == "Invalid date") {
                messages.errorMessage(messages.GLOBAL_MESSAGES.REQUIRED_FIELDS);

                return;
            }

            let date = null;

            if (startFreeDateString === endFreeDateString) {
                date = {
                    date: moment(this.startFreeDate).format("YYYY/MM/DD"),
                    from: moment(this.startFreeDate).format("hh:mm A"),
                    to: moment(this.endFreeDate).format("hh:mm A"),
                };

                this.time_from = moment(this.startFreeDate).format("hh:mm A");
                this.time_to = moment(this.endFreeDate).format("hh:mm A");

                this.full_day = false;
                if (startFreeTimeObject === "12:00 AM" && endFreeTimeObject === "11:59 PM") {
                    this.full_day = true;
                }

                if (this.full_day) {
                    date.from = "";
                    date.to = "";
                }

                this.makeSaveFreeDaysRequest(date);
                return;
            }

            let dates = this.getAllDates(startFreeDateString, endFreeDateString);

            dates.forEach((dateObject, index) => {
                date = {
                    date: moment(dateObject).format("YYYY/MM/DD"),
                    from: moment(dateObject).format("hh:mm A"),
                    to: moment(dateObject).format("hh:mm A"),
                };

                if (this.full_day) {
                    date.from = "";
                    date.to = "";
                }

                this.full_day = true;

                if (index === dates.length - 1) {
                    this.makeSaveFreeDaysRequest(date);
                } else {
                    this.makeSaveFreeDaysRequest(date, false);
                }
            });
        },

        getAllDates: function (startFreeDateString, endFreeDateString) {
            let date = startFreeDateString.split("-");
            date = date[1] + "-" + date[2] + "-" + date[0];
            let startFreeDateObject = new Date(date);

            date = endFreeDateString.split("-");
            date = date[1] + "-" + date[2] + "-" + date[0];
            let endFreeDateObject = new Date(date);

            let dates = [];
            let currDate = moment(startFreeDateObject).startOf("day");
            let lastDate = moment(endFreeDateObject).startOf("day");
            do {
                dates.push(currDate.clone().toDate());
            } while (currDate.add(1, "days").diff(lastDate) < 0);
            dates.push(currDate.clone().toDate());

            return dates;
        },

        makeSaveFreeDaysRequest: function (date, fetchData = true) {
            if (this.full_day) {
                date.from = "";
                date.to = "";
            } else {
                date.from = this.time_from;
                date.to = this.time_to;
            }

            if (!this.full_day && (!date.from || !date.to)) {
                messages.errorMessage(messages.GLOBAL_MESSAGES.REQUIRED_FIELDS);
                return;
            }

            let data = {};
            let endpoint = api.actions.save_free_days;

            if (this.free_day_id) {
                data = date;
                data.id = this.free_day_id;
                endpoint = api.actions.edit_free_day;
            } else {
                data.dates = [date];
            }

            if (this.for_stylist_id != auth.user.id) {
                data.group_user_id = this.for_stylist_id;
            }

            if (this.free_day_user_id && this.free_day_user_id != auth.user.id) {
                data.group_user_id = this.free_day_user_id;
            }

            this.$http.post(endpoint, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.show_form = false;
                        this.resetModel();

                        if (fetchData) {
                            messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);

                            if (
                                this.adminDetails.group_id != 0 &&
                                this.adminDetails.group_owner == 1 &&
                                this.for_stylist_id == 0
                            ) {
                                this.startFreeDate = null;
                                this.endFreeDate = null;
                                this.getGroupFreeDays();
                                return;
                            }

                            this.getFreeDays();
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        formatter: function (date) {
            return moment(date).format("YYYY/MM/DD");
        },
        getProcessedHour: function (value) {
            if (!value["hh"] || !value["mm"] || !value["A"]) {
                return "";
            }

            return value["hh"] + ":" + value["mm"] + " " + value["A"];
        },
        processHourFormat: function (value) {
            if (!value) {
                return {
                    hh: "",
                    mm: "",
                    A: "",
                };
            }

            let obj = moment(value, ["hh:mh A"]);
            let hour = {
                hh: obj.format("hh"),
                mm: obj.format("mm"),
                A: obj.format("A"),
            };

            return hour;
        },
        deleteFreeDay: function (day) {
            let data = {
                id: day.id,
            };

            if (day.user_id != auth.user.id) {
                data.group_user_id = day.user_id;
            }

            this.$http.post(api.actions.delete_free_day, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_DELETE);

                        if (
                            this.adminDetails.group_id != 0 &&
                            this.for_stylist_id == 0 &&
                            this.adminDetails.group_owner
                        ) {
                            this.getGroupFreeDays();
                            return;
                        }

                        this.getFreeDays();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        onStylistChange: function () {
            if (this.adminDetails.group_id != 0 && this.for_stylist_id == 0) {
                this.getGroupFreeDays();
                return;
            }

            this.group_free_days = null;
            this.getFreeDays();
        },
        dateApplied: function (startDate, endDate) {
            this.startFreeDate = startDate;
            this.endFreeDate = endDate;
            this.isFirstTimeSet = true;
        },
        editFreeDay: function (day) {
            this.resetModel();
            this.free_day = day.date;
            this.full_day = !day.modified;
            this.time_from = this.processHourFormat(day.free_from);
            this.time_to = this.processHourFormat(day.free_to);
            this.free_day_id = day.id;
            this.free_day_user_id = day.user_id;
            this.show_form = true;
            this.refreshingDatePicker = true;

            setTimeout(() => {
                this.refreshingDatePicker = false;
            }, 1);

            setTimeout(() => {
                $(".vdpr-datepicker > div > input").click();
            }, 100);

            day.date = moment(day.date).format("YYYY/MM/DD");

            //make date combo for datepicker [start, end]
            this.dp_free_day = [
                moment(
                    day.date +
                        " " +
                        (this.full_day
                            ? "00:00 AM"
                            : this.time_from["hh"] + ":" + this.time_from["mm"] + " " + this.time_from["A"]),
                    "YYYY/MM/DD LT"
                ).toDate(),
                moment(
                    day.date +
                        " " +
                        (this.full_day
                            ? "11:59 PM"
                            : this.time_to["hh"] + ":" + this.time_to["mm"] + " " + this.time_to["A"]),
                    "YYYY/MM/DD LT"
                ).toDate(),
            ];
        },
        resetModel: function () {
            this.free_day = "";
            this.full_day = true;
            this.time_from = {};
            this.time_to = {};
            this.free_day_id = 0;
            this.free_day_user_id = 0;
        },
        checkAvailabilityOfFreeTime: function (freeDays) {
            this.freeTimeAvailable = false;
            //check if incoming parameter is array and treat accoordingly
            let isArr = Object.prototype.toString.call(freeDays) == "[object Array]";
            let self = this;
            if (isArr) {
                freeDays.forEach(function (day) {
                    if (day.modified) {
                        self.freeTimeAvailable = true;
                    }
                });
            } else {
                for (let key in freeDays) {
                    freeDays[key].forEach(function (day) {
                        if (day.modified) {
                            self.freeTimeAvailable = true;
                        }
                    });
                }
            }
        },
        getDefaultWorkIntrval: function (date) {
            var dt = moment(date);
            var weekday = dt.format("dddd").toLowerCase();

            var def_from = "9:00 AM";
            var def_to = "6:00 PM";

            if (this.for_stylist_id != 0) {
                this.groupMembers.forEach((el) => {
                    if (el.user_id == this.for_stylist_id) {
                        this.time_from = this.processHourFormat(
                            el.work_settings[weekday + "_from"] ? el.work_settings[weekday + "_from"] : def_from
                        );
                        this.time_to = this.processHourFormat(
                            el.work_settings[weekday + "_to"] ? el.work_settings[weekday + "_to"] : def_to
                        );
                    }
                });
            } else {
                this.time_from = this.processHourFormat(
                    this.adminDetails.work_settings[weekday + "_from"]
                        ? this.adminDetails.work_settings[weekday + "_from"]
                        : def_from
                );
                this.time_to = this.processHourFormat(
                    this.adminDetails.work_settings[weekday + "_to"]
                        ? this.adminDetails.work_settings[weekday + "_to"]
                        : def_to
                );
            }
        },

        openCalendar: function () {
            this.show_form = !this.show_form;
            this.dp_free_day = [];
        },
        getFormattedDate(date, format) {
            return moment(date).format(format);
        },
        onDatePickerOpened() {
            $(".vdpr-datepicker__calendar-input-date-elem").attr("readonly", true);
        },
    },
    components: {
        datepicker: DatePicker,
    },
};
</script>
