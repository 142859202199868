import Vue from "vue";

export default {
    GLOBAL_MESSAGES: {
        LOGIN_ERROR: "Wrong email or password.",
        RESET_PASS_ERROR: "Something went wrong, please try again later",
        REGISTER_ERROR: "There was an error processing the register request.",
        REGISTER_PASSWORD_MATCH: "The confirm password does't match",
        APPOINTMENT_SAVED: "The appointment was successfuly saved",
        APPOINTMENT_SCHEDULED: "Your appointment was scheduled!",
        ADMIN_LOGIN_ERROR: "Only stylists can login here",
        INVITATION_SENT: "Invitation sent",
        GENERAL_SAVE: "Successfully saved",
        GENERAL_DELETE: "Successfully deleted",
        GENERAL_REFUND: "Successfully refunded",
        REQUIRED_FIELDS: "Fill out the required fields",
        PASSWORD_CHANGED: "Your password has been changed",
        PHONE_SEND_CODE_MESSAGE_WAIT: "Please wait while we send you the verification code...",
        PHONE_SEND_CODE_MESSAGE:
            "We have sent you a verification code, please enter it above to verify your phone number.",
        PHONE_CHANGE_MESSAGE: "Your phone number has been changed successfully.",
        PROFESSION_CHANGE_MESSAGE: "Your profession has been changed.",
        USER_DATA_SAVED_MESSAGE: "The changes have been saved",
        PORTFOLIO_TEMPLATE_CHANGED: "The portfolio template has been changed successfully.",
        PORTFOLIO_IMAGE_CHANGED: "The portfolio image has been changed",
        IMAGE_TAG_SAVED: "The image tags has been saved",
        PROFILE_LIKE: "Profile like saved",
        PROFILE_LIKE_EXISTING: "You already liked the profile",
        PROFILE_LIKE_REMOVED: "Profile like removed",
        PORTFOLIO_IMAGE_DELETED: "Portfolio Image has been deleted",
        PROFILE_IMAGE_CHANGED: "Profile Image has been changed",
        LOGO_IMAGE_CHANGED: "Logo team image has been changed",
        SAVE_IMPORT_CLIENT_FILE_SUCCESS: "Import Client File has been submitted for processing",
        PASSWORD_CHANGE_SUCCESS: "Password has been changed successfully",
        PAYPAL_SAVED: "Your paypal account has been successfully connected",
        SQUARE_SAVED: "Your square account has been successfully connected",
        PAYMENT_CHOICES_SAVED: "Your payment choices has been successfully saved",
        SERVICES_ORDER_CHANGED: "Services order has been changes successfully",
        SERVICE_DURATION_ERROR: "duration needs to be more than 0 min",
        TEAM_REQUEST_SUCCESS: "Your request has been submitted",
        CLIENTS_MERGE_SUCCESS: "The clients were successfuly merged",
        CLIENTS_UNMERGE_SUCCESS: "The clients were successfuly unmerged",
        MEMBER_COLOR_CHANGED: "The member color has been changed",
        MEMBER_REMOVED: "The member has been removed from the team",
        CUSTOM_NOTIFICATION_SENT: "We have sent the custom notification",
        MESSAGE_SENT: "Message sent!",
        SAVE_OFFER_SUCCESS: "Special offer has been saved",
        SOCIAL_LINKS_SAVED: "Your social links have been saved",
        SUBSCRIPTION_CREATED: "Your subscription has been created",
        SUBSCRIPTION_CANCELED: "Your subscription has been canceled",
        SUBSCRIPTION_UPDATED: "Your subscription has been updated for :num users",
        WIDGET_SETTINGS_SAVED: "Widget Settings have been saved successfully",
        WIDGET_SNIPPET_COPIED: "Widget Snippet has been copied successfully",
        LINK_COPIED: "The link has been copied successfully",
        MESSAGE_SENT_TO_SUPPORT: "Your message has been sent to support",
        CARD_ADDED: "The card was successfully saved",
        REFERRAL_TERMS_DECLNED: "The referral terms were declined",
        PAYMENT_SUCCESS: "The payment has been successful",
        COPIED_TO_CLIPBOARD: "Copied to clipboard",
        STATEMENTS_EMAIL_SENT: "Statement sent to ",
        CLIENT_ADDED_TO_BLOCKLIST: "Client has been added to the blocklist",
        CLIENT_ADDED_TO_THELIST: "Client has been added to the list",
        LEFT_THE_TEAM: "You successfully left the team",
        DELETE_PROFILE_PICTURE: "You successfully removed the profile picture",
        DELETE_TEAM_LOGO: "You successfully removed the team logo",
        RESEND_EMAIL: "You successfully resend email invite",
        RESEND_SMS: "You successfully resend sms invite",
    },
    ERROR_MESSAGES: {
        ADD_CLIENT_STYLISTS_ERROR: "You need to choose at least one stylist",
        SUBSCRIPTION_USERS_NUMBER: "The number of users can't be less than ",
        USERS_LOAD_ERROR: "Error loading the users",
        PAYOUT_LOAD_ERROR: "Error loading the payouts",
        TRIAL_USER_MARKETING:
            "Your account is in trial mode. Please start your subscription to be able to send notifications.",
        PAYMENT_ERROR: "Error making the payment",
        GENERAL_ERROR: "Error loading the data",
        IMAGE_TO_BIG: "Please select an image less than 4MB.",
    },
    INVITE_TEXTS: {
        1: "invites you to Ring My Stylist, the new Appointment Booking App! https://ringmystylist.com/openapp",
        2: "invites you to Ring My Barber, the new Appointment Booking App! https://ringmybarber.com/openapp",
        3: "invites you to Ring My Barber, the new Appointment Booking App! https://ringmygroomer.com/openapp",
        5: "invites you to Ring My Barber, the new Appointment Booking App! https://ringmyloctician.com/openapp",
    },
    errorMessage: function (message) {
        Vue.toasted.show(message, {
            type: "error",
            position: "top-center",
            duration: 4000,
        });
    },
    successMessage: function (message) {
        Vue.toasted.show(message, {
            type: "success",
            position: "top-center",
            duration: 4000,
        });
    },
    infoMessage: function (message) {
        Vue.toasted.show(message, {
            type: "info",
            position: "top-center",
            duration: 4000,
        });
    },
};
