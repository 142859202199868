<template>
  <div class="about-component text-left">
    <h3>About Ring My Stylist</h3>
    <p>Ring My Stylist is an Appointment Booking Software Solution for Independent Stylists. Organizing a busy business life can prove to be a herculean task especially when there’s only a little time available. Hectic Stylists life with numerous responsibilities require the Ring My Stylist app for careful planning to make sure they run smoothly and without hassles. It is the first app designed to manage every aspect of an Independent Stylist’s business.</p>
    <ul>
        <li class="text-list-item">
            <div class="list-item-text">Your Client Base</div>
        </li>
        <li class="text-list-item">
            <div class="list-item-text">Appointments</div>
        </li>
        <li class="text-list-item">
            <div class="list-item-text">Services</div>
        </li>
        <li class="text-list-item">
            <div class="list-item-text">Confirmations</div>
        </li>
        <li class="text-list-item">
            <div class="list-item-text">Reminders</div>
        </li>
        <li class="text-list-item">
            <div class="list-item-text">Daily Schedule</div>
        </li>
        <li class="text-list-item">
            <div class="list-item-text">Special Offers</div>
        </li>
        <li class="text-list-item">
            <div class="list-item-text">Referrals</div>
        </li>
        <li class="text-list-item">
            <div class="list-item-text">Calendar Sync and more!</div>
        </li>
    </ul>
    <p>Every Independent Stylist’s Top Choice!
      <br><br>
      Ring My Stylist is not only the best, but also the first of its kind. Available in English, Spanish, Italian and Portuguese, Ring My Stylist is made for all! Our solution is unique and specially optimized for Independent Stylists (Hair Stylist, Esthetician, Makeup Artist, Skincare Specialist, Nail Artist, Hairdresser, Fashion Designer, Barber, Tattoo Artist, Masseuse, Image Consultant/Stylist, Foot Masseuse, Body pierce, Manicurist, Eyelash Technician, Photo and Film Stylist, Bridal Make-Up Specialist, e.t.c.) - people working independently in salons or people renting booths.
      <br><br>
      We believe in the power of one-to-one connection between stylist and clients!
    </p>
    <p>With all aspects of your business being carefully managed, you get to focus more on what really matters like improving on your services or even taking your dog out for a walk! The app features a full blown, impressive and remarkably user-friendly interface which takes little or no time to get accustomed to. Thereby making life more enjoyable, organized and hassle-free.</p>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
  }
}
</script>
