<template>
    <!--Dashboard main container-->
    <div class="admin-dashboard">
        <!--Page Heading-->
        <div class="title-bell-wrap">
            <h2 class="page-title" v-if="user && user.role === 'client'">
                {{ $ml.get("adminDashboard.clientTitle") }}
            </h2>
            <h2 class="page-title" v-if="user && user.role === 'stylist'">
                {{ $ml.get("adminDashboard.title") }}
            </h2>
            <!--Notification Messeges-->
            <div v-if="localNotificationCount && user && user.role === 'client'" class="unread-messages">
                <router-link :to="{ path: '/app/contact-us' }">
                    <div class="unread-messages-icon">
                        <i class="fa fa-bell" aria-hidden="true"></i> {{ localNotificationCount }}
                        <span
                            >unread <span v-if="localNotificationCount === 1">message</span
                            ><span v-if="localNotificationCount > 1">messages</span>
                        </span>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="loader-wrapper">
            <div class="user-details" v-if="user && calendar_view">
                <!--Calendar Team-->
                <div class="calendar-team-name-container" v-if="adminDetails.group_owner || groupMemberAdmin">
                    <div>
                        <div class="calendar-team-name">
                            <div class="user-select-wrap" v-if="newIsGroup || groupMemberDisplayAll">
                                <label class="stylist-select-wrap" for="stylist-select"
                                    >{{ newUsers[0].group_name }}
                                </label>
                                <select
                                    v-if="
                                        (newCurrentView == 'timeGridWeek' ||
                                            newCurrentView == 'dayGridMonth' ||
                                            newCurrentView == 'timeGrid' ||
                                            newCurrentView == 'resourceTimeGridDay') &&
                                        showStylistSpecificAppointments
                                    "
                                    id="stylist-select"
                                    class="select-group-name"
                                    v-model="newCurrentStylistId"
                                    v-on:change="newOnStylistChange"
                                >
                                    <option
                                        v-for="(member, index) in newUsers"
                                        v-bind:key="index"
                                        v-bind:value="member.user.id"
                                        v-bind:disabled="member.active != 1"
                                    >
                                        {{ member.user.first_name }}
                                        {{ member.user.last_name }}
                                    </option>
                                </select>
                            </div>
                            <div class="control-btns-container">
                                <div
                                    v-if="
                                        (newIsGroup || groupMemberDisplayAll) &&
                                        (newCurrentView == 'timeGridWeek' ||
                                            newCurrentView == 'dayGridMonth' ||
                                            newCurrentView == 'timeGrid' ||
                                            newCurrentView == 'resourceTimeGridDay') &&
                                        showStylistSpecificAppointments
                                    "
                                    class="controls-wrap"
                                >
                                    <button type="button" v-on:click="showAllAppts" class="primary-btn extra-small">
                                        Show All Appts.
                                    </button>
                                </div>
                                <div
                                    v-if="
                                        (newIsGroup || groupMemberDisplayAll) &&
                                        (newCurrentView == 'timeGridWeek' ||
                                            newCurrentView == 'dayGridMonth' ||
                                            newCurrentView == 'timeGrid' ||
                                            newCurrentView == 'resourceTimeGridDay') &&
                                        !showStylistSpecificAppointments
                                    "
                                    class="controls-wrap"
                                >
                                    <button
                                        type="button"
                                        v-on:click="showStylistSpecificAppts"
                                        class="primary-btn extra-small"
                                    >
                                        <span>Show </span>
                                        <span v-if="generalOptions.app_id == 1">Stylist</span>
                                        <span v-if="generalOptions.app_id == 2">Barber</span>
                                        <span> Specific Appt.</span>
                                    </button>
                                </div>
                                <div
                                    class="controls-wrap"
                                    v-if="
                                        (newIsGroup || groupMemberDisplayAll) &&
                                        (newCurrentView == 'timeGridWeek' ||
                                            newCurrentView == 'dayGridMonth' ||
                                            newCurrentView == 'timeGrid' ||
                                            newCurrentView == 'resourceTimeGridDay') &&
                                        showStylistSpecificAppointments
                                    "
                                >
                                    <button class="primary-btn extra-small" v-on:click="newOnStylistChange">
                                        <i class="fa fa-refresh" v-bind:class="{ 'fa-spin': loading }"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Calendar search and datepicker-->
                <div class="calendar-search-and-datepicker">
                    <div v-if="user.role != 'client'">
                        <div class="form-group has-search calendar-search-results">
                            <input
                                v-model="searchQuery"
                                v-on:input="searchAppointment"
                                placeholder="Search by first name, last name, phone (minimum 3 characters are required)"
                                type="search"
                                class="text-field text-field-small text-field-extra-small mobile"
                            />
                            <span
                                v-if="
                                    filteredAppointments.length &&
                                    searchQuery &&
                                    !(searchQuery.length < 3) &&
                                    adminDetails.group_owner
                                "
                                class="entries-found"
                                v-cloak
                            >
                                ({{ filteredAppointments.length }} entries found)
                            </span>
                            <span
                                v-if="
                                    filteredAppointments.length &&
                                    searchQuery &&
                                    !(searchQuery.length < 3) &&
                                    !adminDetails.group_owner
                                "
                                class="entries-found-nogrup"
                                v-cloak
                            >
                                ({{ filteredAppointments.length }} entries found)
                            </span>
                        </div>
                        <!--Calendar search results-->
                        <div v-if="searchQuery && !(searchQuery.length < 3) && !loading">
                            <div class="calendar-search-results calendar-search-container section-wrap">
                                <ul
                                    v-for="(item, index) in filteredAppointments"
                                    class="list-group"
                                    :key="filteredAppointments.id + '-' + index"
                                >
                                    <li class="list-group-item section-wrap">
                                        <div class="search-list-container">
                                            <span>
                                                Appt date:
                                                <span class="search-list-item-date"
                                                    >{{ item.appt.date | dateFullFormat }}
                                                    <span style="font-weight: bold">
                                                        ({{ item.appt.hour_begin }}
                                                        -
                                                        {{ item.appt.hour_end }})</span
                                                    >
                                                </span>
                                            </span>
                                            <span>
                                                Duration:
                                                <span class="search-list-item-date"
                                                    >{{ item.appt.total_duration }}
                                                    min
                                                </span>
                                            </span>
                                            <span>
                                                Name:
                                                <span class="search-list-item-date">{{
                                                    item.appt.client.full_name
                                                }}</span>
                                            </span>
                                            <span>
                                                Email:
                                                <span class="search-list-item-date">{{
                                                    item.appt.client.email || "-"
                                                }}</span>
                                            </span>
                                            <span>
                                                Phone:
                                                <span class="search-list-item-date">{{
                                                    item.appt.client.full_phone_number_stripped || "-"
                                                }}</span>
                                            </span>
                                            <span>
                                                Status:
                                                <span class="search-list-item-date">{{ item.appt.status }}</span>
                                            </span>
                                        </div>
                                        <div>
                                            <span
                                                type="button"
                                                class="primary-btn extra-small mobile"
                                                v-bind:style="
                                                    item.appt.color
                                                        ? {
                                                              backgroundColor: `${item.appt.color}`,
                                                              borderWidth: 1,
                                                              borderColor: `${item.appt.color}`,
                                                          }
                                                        : {}
                                                "
                                                v-on:click="
                                                    newOnEventClick({
                                                        event: { id: item.appt.id },
                                                        appt: item.appt,
                                                    })
                                                "
                                            >
                                                View appointment
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                                <div
                                    v-if="!filteredAppointments.length && searchError && !loading"
                                    style="
                                        padding: 10px;
                                        min-height: 100px;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                    "
                                >
                                    <div
                                        style="
                                            display: flex;
                                            justify-content: center;
                                            text-align: center;
                                            font-weight: bold;
                                            color: rgb(178, 34, 34);
                                        "
                                    >
                                        {{ searchError }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Calendar appointments-->
                    <div class="calendar-appts">
                        <div v-if="appointmentRefreshTime && false" class="last-calendar-refresh-info">
                            <span class="last-refresh-appts-count">{{ appointmentRefreshTimeCounter }}</span>
                            <span>Refreshed at </span>
                            <span class="last-refresh-time">{{ appointmentRefreshTime }}</span>
                        </div>
                        <div class="calendar-choose-date-wrap">
                            <div
                                v-on:click="showCalendarDateChoosePopup = !showCalendarDateChoosePopup"
                                class="calendar-choose-date-action"
                            >
                                <div>
                                    <img
                                        width="50"
                                        height="50"
                                        src="../../assets/images/calendar.svg"
                                        alt="Calendar Icon"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="showCalendarDateChoosePopup"
                                class="calendar-choose-date"
                                :class="user && user.role == 'client' ? 'client' : 'stylist'"
                            >
                                <div class="datepicker-weeksminths-container">
                                    <div class="datepicker-container">
                                        <div class="datepicker-left-wrap">
                                            <datepicker
                                                v-on:selected="newOnDateClickFromFullCalendarDatePicker"
                                                :value="bookData.date"
                                                :inline="true"
                                            >
                                            </datepicker>
                                        </div>
                                        <div class="datepicker-right-wrap">
                                            <datepicker
                                                v-on:selected="newOnDateClickFromFullCalendarDatePicker"
                                                :value="secondaryDatepickerDate"
                                                :inline="true"
                                            >
                                            </datepicker>
                                        </div>
                                    </div>
                                    <div class="weeks-minths-options-wrap">
                                        <div class="options-wrap week-options-wrap">
                                            <span
                                                v-for="(opt, i) in 14"
                                                :key="`week-opt${i}`"
                                                v-on:click="
                                                    newOnDateClickFromFullCalendarDatePicker(
                                                        addTimeToDate(new Date(), opt, 'W')
                                                    )
                                                "
                                                >{{ opt }} weeks out</span
                                            >
                                        </div>
                                        <div class="options-wrap months-options-wrap">
                                            <span
                                                v-for="(opt, i) in 14"
                                                :key="`week-opt${i}`"
                                                v-on:click="
                                                    newOnDateClickFromFullCalendarDatePicker(
                                                        addTimeToDate(new Date(), opt, 'M')
                                                    )
                                                "
                                                >{{ opt }} months out</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="listing-view" v-if="user && user.role == 'client'">
                                <div @click="calendar_view = 0">
                                    <div class="primary-btn extra-small mobile">Listing View</div>
                                </div>
                            </div>
                        </div>
                        <div class="filters-unread-messages">
                            <div class="status-filters">
                                <div v-for="(status, i) in statusFilterTypes" :key="`status-button-${i}`">
                                    <div
                                        v-if="statusFilterTypeAppts[status].length != 0"
                                        v-on:click="openEventsByStatusModal(status)"
                                        :class="status"
                                        class="status-filter-button"
                                    >
                                        {{ status }} ({{ statusFilterTypeAppts[status].length }})
                                    </div>
                                </div>
                                <!-- <div class="recurring-appts status-filter-button"  v-on:click="openEventsByStatusModal(status)">Recurring</div> -->
                            </div>

                            <!--Notification Messeges-->
                            <div v-if="localNotificationCount" class="unread-messages">
                                <router-link :to="{ path: '/app/contact-us' }">
                                    <div class="unread-messages-icon">
                                        <i class="fa fa-bell" aria-hidden="true"></i> {{ localNotificationCount }}
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Appointments Calendar-->
                <div
                    class="calendar-wrap section-wrap"
                    :class="
                        newCurrentView == 'resourceTimeGridDay' || newCurrentView == 'timeGridDay' ? 'onlyDayView' : ''
                    "
                >
                    <div>
                        <div
                            class="day-before-date"
                            v-if="newCurrentView == 'resourceTimeGridDay'"
                            :style="{ right: dayBeforeDateRight + '%' }"
                        >
                            {{ currentDay }},
                        </div>
                        <!--Calendar Component-->
                        <FullCalendar
                            ref="fullCalendar"
                            defaultView="dayGridMonth"
                            :header="{
                                left: 'prev,next today',
                                center: 'title',
                                right: rightButtonLayout,
                            }"
                            :customButtons="customCalendarButtons"
                            :plugins="calendarPlugins"
                            :weekends="calendarWeekends"
                            :events="newEvents"
                            :slotDuration="'00:15:00'"
                            :displayEventTime="displayEventTime"
                            :minTime="minTime"
                            :maxTime="maxTime"
                            :datesRender="newOnDateRender"
                            :eventLimit="true"
                            :resources="newResources"
                            :firstDay="currentDayNumber"
                            :defaultDate="bookData.date"
                            @dateClick="newOnDateClick"
                            @eventClick="newOnEventClick"
                            :schedulerLicenseKey="fullCalendarLicenseKey"
                            :editable="true"
                            @eventResize="eventResize"
                            @eventDrop="eventDrop"
                            @eventRender="eventRender"
                            @resourceRender="resourceRender"
                            :nowIndicator="true"
                            class="custom-calendar"
                        />
                    </div>
                    <!-- Pick-date modal -->
                    <div v-if="newShowFullCalendarDatePicker" class="fullcalendar-datepicker-wrapper">
                        <datepicker
                            v-on:selected="newOnDateClickFromFullCalendarDatePicker"
                            :value="bookData.date"
                            :inline="true"
                        >
                        </datepicker>
                    </div>
                </div>
            </div>
            <!--Loader-->
            <div v-if="user && user.role === 'client' && !calendar_view && firstLoad === true" class="loader-wrap">
                <div class="loader">
                    <i class="fa fa-3x fa-spinner fa-spin"></i>
                </div>
            </div>
        </div>
        <!--Client listings-->
        <div id="client-listing-view" v-if="!calendar_view && user && user.role == 'client'">
            <div class="client-listing-view-container">
                <div class="book-listing-client-notifications-container">
                    <div class="book-new-appt-container">
                        <div class="book-btn-container">
                            <div class="book-btn" v-on:click="onBook()">
                                <div class="primary-btn extra-small mobile">Book</div>
                            </div>
                        </div>
                        <!--Client Listing and Quick Book appt container-->
                        <div
                            v-if="
                                user.role == 'client' &&
                                clientWidgetSettings &&
                                clientWidgetSettings.stylist_name &&
                                Object.keys(dateAppointments).length == 0
                            "
                            class="client-book-widget section-wrap"
                        >
                            <!--Client Widget-->
                            <div class="client-widget-header">
                                <div class="client-avatar-widget-header-container">
                                    <div v-if="clientWidgetSettings.stylist_avatar" class="stylist-avatar">
                                        <img
                                            :src="clientWidgetSettings.stylist_avatar"
                                            :alt="clientWidgetSettings.stylist_name"
                                        />
                                    </div>
                                    <div class="client-header-text">
                                        <p>
                                            Next available spots at
                                            <span>{{ clientWidgetSettings.stylist_name }}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="widget-listing-wrap">
                                    <div class="client-widget-service">
                                        <v-select
                                            v-model="clientWidgetSelectedService"
                                            :options="clientWidgetServices"
                                            :reduce="(label) => label.value"
                                            @input="onClientWidgetServiceSelected"
                                        />
                                    </div>
                                </div>
                            </div>
                            <!--Client widget quick appt days-->
                            <div class="client-widget-days">
                                <div v-for="(d, index) in clientWidgetDays" :key="index" class="client-widget-day">
                                    <div class="day-label">{{ d.date }}</div>
                                    <div v-if="d.slots.length > 0" class="day-slots" :class="{ loading: loading }">
                                        <span
                                            v-for="(s, i) in d.slots"
                                            :key="`${index}_${i}`"
                                            v-on:click="onClientWidgetSlotSlick(d.dateUnformatted, s)"
                                            >{{ s }}</span
                                        >
                                    </div>
                                    <div v-if="d.slots.length == 0" class="day-slots">-</div>
                                </div>
                            </div>
                            <div class="client-more-dates-btn-container">
                                <router-link
                                    class="primary-btn extra-small mobile more-dates"
                                    :to="{ path: '/app/stylists' }"
                                    >Click to see more dates</router-link
                                >
                            </div>
                        </div>
                        <div v-if="Object.keys(dateAppointments).length > 0" class="book-appts-container section-wrap">
                            <div
                                class="book-appt-wrapp"
                                v-for="key in Object.keys(groupedEvents).sort()"
                                v-bind:key="key"
                            >
                                <div
                                    v-for="appt in groupedEvents[key]"
                                    v-bind:key="appt.id"
                                    class="appt-list-wrap no-shadow"
                                >
                                    <div class="appt-title-header">
                                        <h5 class="appt-title">
                                            {{ key | formatClientDate }}
                                        </h5>
                                        <div class="appt-status-wrap">
                                            <span
                                                v-if="
                                                    (appt.status !== 'unconfirmed' && appt.booked_by !== 'client') ||
                                                    (appt.status === 'unconfirmed' && appt.booked_by === 'client')
                                                "
                                                :class="
                                                    appt.status == 'unconfirmed'
                                                        ? 'unconfirm-text'
                                                        : 'hidden book-status-state'
                                                "
                                                >{{ appt.status | capitalize }}</span
                                            >

                                            <div
                                                v-if="appt.status == 'unconfirmed' && appt.booked_by !== 'client'"
                                                class="primary-btn extra-small mobile"
                                                v-on:click="confirmAppointment(appt.id, false)"
                                            >
                                                <i class="fa fa-calendar-check-o"></i>
                                                {{ $ml.get("adminDashboard.confirm") }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="appt-list-inner">
                                        <div class="appt-date-time">{{ appt.start }} - {{ appt.end }}</div>
                                    </div>

                                    <div class="service-wrapp">
                                        <div v-for="(service, index) in appt.services_array" v-bind:key="index">
                                            <div class="service-name">
                                                <span v-if="appt.services_qty[index] > 1" class="qty"
                                                    >({{ appt.services_qty[index] }})
                                                </span>
                                                <span class="serv-title">{{ service }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="name-view-details-wrap">
                                        <div class="stylist-name">
                                            <a :href="appt.portfolio_page_url" class="main-text-link" target="_blank">{{
                                                appt.with_name
                                            }}</a>
                                        </div>

                                        <div class="appt-actions-wrap">
                                            <div
                                                v-on:click="viewAppointmentDetails(appt)"
                                                class="primary-btn extra-small mobile"
                                            >
                                                View details
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="event-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="event-modal"
            aria-hidden="true"
            data-backdrop="false"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <!--Flex element don't delete-->
                        <div v-if="showCheckout !== true"></div>
                        <div v-if="showCheckout === true"><h5>Complete Checkout</h5></div>
                        <div>
                            <button
                                type="button"
                                v-on:click="resetData()"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <!--Book Modal-->
                    <div class="modal-body book-sections no-padding-top">
                        <!-- Add Date and Time Edit -->
                        <div v-if="user && user.role == 'stylist' && !showCheckout" class="book-time-row">
                            <div class="">
                                <div
                                    class="member-avatar-global"
                                    v-if="selected_event && !selected_event.avatar && initials.length"
                                >
                                    <div>
                                        <span>{{ initials[0] }}</span>
                                        <span v-if="initials.length > 1">{{ initials[1] }}</span>
                                    </div>
                                </div>
                                <div
                                    class="member-avatar-global"
                                    v-if="selected_event && !selected_event.avatar && !initials.length"
                                >
                                    <img src="../../assets/images/rmb-logo.png" />
                                </div>
                            </div>
                            <div
                                class=""
                                v-if="bookData.personal != 1 && selected_event"
                                :class="selected_event.status === 'PERSONAL TIME OFF' ? 'hide-date-picker' : ''"
                            >
                                <div class="book-modal-day" v-on:click="openModal('pickApptDate')">
                                    <img
                                        width="25"
                                        height="25"
                                        src="../../assets/images/rounded-edit-icon.png"
                                        alt="Edit Icon"
                                    />
                                    <span
                                        ><strong>{{ this.date | monthDateFormat }} </strong></span
                                    >
                                </div>
                                <!-- pick-date modal -->
                                <div v-if="openApptDetailsDateModal" class="datepicker-wrapper appt-details-calendar">
                                    <datepicker v-on:selected="onDateClickFromDatePicker" :value="date" :inline="true">
                                    </datepicker>
                                </div>
                                <div v-if="!newShowTimeSpinner" class="dropdown time-dropdown">
                                    <button
                                        class="dropdown-toggle book-dropdown-btn"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <img
                                            width="25"
                                            height="25"
                                            src="../../assets/images/rounded-edit-icon.png"
                                            alt="Edit Icon"
                                        />
                                        <span class="show-time">
                                            {{ this.hour }}
                                        </span>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="time-dropdown">
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            v-for="(timeObj, i) in newTimeArray"
                                            v-bind:key="i"
                                            v-bind:class="{
                                                'free-time': timeObj.free,
                                                'taken-time': !timeObj.free,
                                                active: timeObj.value == hour,
                                            }"
                                            v-on:click="changeTime(timeObj.value)"
                                        >
                                            {{ timeObj.value }}
                                            <span class="ref"></span>
                                        </a>
                                    </div>
                                </div>
                                <div v-if="newShowTimeSpinner">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>
                            </div>
                            <div class="">
                                <template v-if="selected_event && selected_event.status != 'canceled'">
                                    <div
                                        class="book-time-btn"
                                        v-if="
                                            user &&
                                            user.role !== selected_event.booked_by &&
                                            selected_event.status == 'unconfirmed'
                                        "
                                    >
                                        <button
                                            class="primary-btn extra-small mobile"
                                            v-on:click="confirmAppointment(selected_event.id)"
                                        >
                                            <i class="fa fa-calendar-check-o"></i>
                                            {{ $ml.get("adminDashboard.confirm") }}
                                        </button>
                                    </div>

                                    <span
                                        v-else
                                        class="appt-status"
                                        :class="{
                                            'bg-unconfirm': selected_event.status == 'unconfirmed',
                                            'bg-confirm': selected_event.status != 'unconfirmed',
                                        }"
                                        >{{ selected_event.status | capitalize }}</span
                                    >
                                </template>
                            </div>
                        </div>

                        <div
                            v-if="user && user.role == 'client'"
                            class="book-time-row"
                            :class="
                                user && user.role !== selected_event.booked_by && selected_event.status == 'unconfirmed'
                                    ? 'book-time-client'
                                    : ''
                            "
                        >
                            <!--flex element dont delete-->
                            <div class="flex-elem"></div>
                            <div class="">
                                <template v-if="selected_event.status != 'canceled'">
                                    <div
                                        class="book-time-btn"
                                        v-if="
                                            user &&
                                            user.role !== selected_event.booked_by &&
                                            selected_event.status == 'unconfirmed'
                                        "
                                    >
                                        <div
                                            class="primary-btn extra-small mobile"
                                            v-on:click="confirmAppointment(selected_event.id)"
                                        >
                                            <i class="fa fa-calendar-check-o"></i>
                                            {{ $ml.get("adminDashboard.confirm") }}
                                        </div>
                                    </div>
                                    <span
                                        v-else-if="user && user.role !== 'client'"
                                        class="appt-status"
                                        :class="{
                                            'bg-unconfirm': selected_event.status == 'unconfirmed',
                                            'bg-confirm': selected_event.status != 'unconfirmed',
                                        }"
                                        >{{ selected_event.status | capitalize }}</span
                                    >
                                </template>
                            </div>
                        </div>
                        <div class="heading-checkout" v-if="showCheckout === true">
                            <div class="primary-btn extra-small mobile" @click="showCheckout = false">Back</div>
                        </div>
                        <!--Demo Client Checkout-->
                        <div v-if="user && user.role !== 'client' && !showCheckout"><hr /></div>
                        <div class="book-checkout" v-if="!showCheckout">
                            <div class="wrapper">
                                <div class="star-and-stylist-name">
                                    <div class="align-star-name">
                                        <div
                                            class="event-image"
                                            v-if="
                                                user &&
                                                user.role === 'stylist' &&
                                                appointment_details.client_months_from_register < 6
                                            "
                                        >
                                            <img width="15" src="../../assets/images/002-star.svg" alt="Star Image" />
                                        </div>
                                        <div class="event-name" v-if="selected_event">
                                            {{ selected_event.with_name }}
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            selected_event &&
                                            selected_event.client_phone &&
                                            selected_event.client_phone.indexOf('0000000000') == -1
                                        "
                                        class="event-name"
                                    >
                                        {{ selected_event.client_phone }}
                                    </div>
                                    <div
                                        v-if="
                                            selected_event &&
                                            selected_event.client_email &&
                                            selected_event.client_email.indexOf('ringmystylist.com') == -1
                                        "
                                        class="event-name"
                                    >
                                        {{ selected_event.client_email }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    v-if="
                                        !appointment_paid &&
                                        user &&
                                        user.role == 'stylist' &&
                                        selected_event &&
                                        selected_event.status != 'canceled' &&
                                        selected_event.status != 'PERSONAL TIME OFF'
                                    "
                                    @click="showCheckout = true"
                                    class="primary-btn extra-small mobile"
                                >
                                    CHECKOUT
                                </div>
                            </div>
                            <div
                                v-if="
                                    (selected_event && selected_event.status == 'PERSONAL TIME OFF') ||
                                    (selected_event &&
                                        selected_event.status != 'PERSONAL TIME OFF' &&
                                        bookData.personal == 1)
                                "
                            >
                                <div @click="editPersonalAppt(selected_event)" class="primary-btn extra-small mobile">
                                    Edit
                                </div>
                            </div>
                        </div>
                        <div v-if="!showCheckout"><hr /></div>
                        <CompleteCheckoutModal
                            v-if="appointment_details && appointment_details.details && showCheckout === true"
                            :suqare_location_id="suqare_location_id"
                            :appointment_details="appointment_details"
                            @on-modal-close="onModalClose"
                        ></CompleteCheckoutModal>

                        <!--Payment-summary-->
                        <div class="payment-summary" v-if="appointment_details.details && !showCheckout">
                            <div class="payment-wrap" v-if="bookData.personal != false">
                                <h5>Payment Summary</h5>
                                <div class="payment-summary-label">
                                    <label>Total: </label
                                    ><span>{{ appointment_details.total_price }} {{ currency }}</span>
                                </div>
                                <div class="payment-summary-label">
                                    <label>Paid: </label
                                    ><span
                                        >{{
                                            appointment_details.details.total_payments
                                                ? appointment_details.details.total_payments
                                                : 0
                                        }}
                                        {{ currency }}</span
                                    >
                                </div>
                                <div class="payment-summary-label">
                                    <label>Balance: </label
                                    ><span
                                        >{{
                                            parseFloat(appointment_details.total_price) -
                                            parseFloat(
                                                appointment_details.details.total_payments
                                                    ? appointment_details.details.total_payments
                                                    : 0
                                            )
                                        }}
                                        {{ currency }}</span
                                    >
                                </div>
                            </div>
                            <div>
                                <h5>Notes Summary</h5>
                                <div
                                    v-if="selected_event && selected_event.notes"
                                    :class="selected_event.notes.length < 100 ? 'bigger-text' : ''"
                                >
                                    {{ selected_event.notes }}
                                </div>
                            </div>
                        </div>
                        <div v-if="!showCheckout"><hr /></div>
                        <div
                            class="scheduled"
                            v-if="
                                selected_event &&
                                selected_event.status != 'PERSONAL TIME OFF' &&
                                bookData.personal == 0 &&
                                !showCheckout
                            "
                        >
                            <div
                                v-if="user && user.role == 'stylist' && adminDetails.group_members.length > 1"
                                class="scheduled-stylist-name"
                            >
                                <span>
                                    scheduled by {{ selected_event.booked_by | getBookedType(generalOptions.app_id) }}
                                </span>
                                <span>with {{ selected_event.stylist_name }}</span>
                            </div>
                            <span v-if="user && user.role == 'client'">
                                scheduled by {{ selected_event.booked_by | getBookedType(generalOptions.app_id) }}
                            </span>
                            <span
                                v-if="
                                    user &&
                                    user.role == 'stylist' &&
                                    (adminDetails.group_members.length == 0 || adminDetails.group_members.length == 1)
                                "
                            >
                                scheduled by {{ selected_event.booked_by }}
                            </span>
                        </div>
                        <template v-if="!showCheckout">
                            <div
                                class="event-modal-actions-wrap"
                                v-if="
                                    !appointment_paid &&
                                    selected_event &&
                                    selected_event.status != 'canceled' &&
                                    selected_event.status != 'PERSONAL TIME OFF'
                                "
                            >
                                <div class="" v-if="prevent_clients_reschedule == 0">
                                    <div
                                        v-if="!isPastEvent(selected_event.date)"
                                        class="primary-btn extra-small mobile"
                                        v-on:click="onRescheduleAppointment(selected_event.id)"
                                    >
                                        <i class="fa fa-calendar-plus-o"></i>
                                        {{ $ml.get("adminDashboard.reschedule") }}
                                    </div>

                                    <div
                                        v-if="isPastEvent(selected_event.date) && user && user.role === 'client'"
                                        @click="bookAgain(selected_event.userId)"
                                        class="primary-btn extra-small mobile"
                                    >
                                        <i class="fa fa-retweet" aria-hidden="true"></i>
                                        {{ $ml.get("adminDashboard.bookAgain") }}
                                    </div>
                                </div>
                                <div v-else></div>
                                <div class="">
                                    <div
                                        v-if="displayCancelButton(selected_event)"
                                        class="secondary-btn small mobile"
                                        v-on:click="onCancelAppointment(selected_event.id)"
                                    >
                                        <i class="fa fa-calendar-times-o"></i>
                                        {{ $ml.get("adminDashboard.cancel") }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="recurring-active"
                                v-if="
                                    recurringDetails &&
                                    recurringDetails.repeat_every > 0 &&
                                    selected_event &&
                                    selected_event.status != 'canceled'
                                "
                            >
                                <span
                                    >Every {{ recurringDetails.repeat_every }} {{ recurringDetails.repeat_period }},
                                    ends {{ recurringDetails.end_date | formatClientDate }}</span
                                >
                            </div>
                            <div
                                class="add-services-container"
                                v-if="newSelectedServices && newSelectedServices.length && selected_event"
                                id="services-listing"
                            >
                                <template v-if="!addServiceModal">
                                    <div class="add-service-wrapp">
                                        <div class="">
                                            Selected services (
                                            {{ newTotalDurationServices | hourMinutesFormat }})
                                        </div>
                                        <div
                                            class=""
                                            v-if="
                                                !appointment_paid &&
                                                user &&
                                                user.role == 'stylist' &&
                                                selected_event.status != 'canceled'
                                            "
                                        >
                                            <a
                                                class="link primary-btn extra-small mobile"
                                                v-if="!editServices"
                                                @click="editServices = true"
                                                >Edit</a
                                            >
                                            <a
                                                class="link primary-btn extra-small mobile"
                                                v-else
                                                @click="editServices = false"
                                                >Save</a
                                            >
                                        </div>
                                    </div>

                                    <div class="services-container scroll-bar">
                                        <div
                                            class=""
                                            v-for="(service, index) in newSelectedServices"
                                            v-bind:key="index"
                                        >
                                            <div class="selected-service-container">
                                                <div class="qty-service-name">
                                                    <span v-if="!editServices">{{ service.qty }} x </span
                                                    >{{ service.name }}
                                                    <span v-if="service.user_id != user.id"
                                                        >(Shared
                                                        {{ service.is_product == 1 ? "Product" : "Service" }})</span
                                                    >
                                                </div>
                                                <div class="service-duration">
                                                    <div class="service-duration-price">
                                                        <div class="modal-appt-service-select-wrap">
                                                            <!-- <span v-if="!editServices">
                                                                {{ service.qty }}
                                                                qty</span
                                                            > -->
                                                            <template v-if="editServices">
                                                                <select
                                                                    class="selectInput select-height"
                                                                    v-if="service.selected"
                                                                    v-model="selectedServiceQty[service.id]"
                                                                    v-on:change="
                                                                        setServiceQty($event, service.id);
                                                                        saveService(service, index);
                                                                    "
                                                                >
                                                                    <option
                                                                        :value="num"
                                                                        v-for="(num, i) in 10"
                                                                        :key="`opt-${i}`"
                                                                    >
                                                                        {{ num }}
                                                                    </option>
                                                                </select>
                                                                <span></span>
                                                                <div class="service-duration-minutes">
                                                                    <span id="basic-addon-qty">qty</span>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                typeof service.is_product == 'undefined' ||
                                                                service.is_product == 0
                                                            "
                                                        >
                                                            <span v-if="!editServices">
                                                                {{ service.duration | hourMinutesFormat }}
                                                                -
                                                            </span>
                                                            <template v-else>
                                                                <div class="service-duration-wrapp">
                                                                    <input
                                                                        class="service-duration-input"
                                                                        type="number"
                                                                        @blur="saveService(service, index)"
                                                                        v-model="service.duration"
                                                                    />
                                                                    <div class="service-duration-minutes">
                                                                        <span id="basic-addon">min</span>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div>
                                                            <span v-if="!editServices">
                                                                <span v-if="currency == 'USD'"
                                                                    >${{
                                                                        service.price
                                                                            ? service.price
                                                                            : service.default_price
                                                                    }}</span
                                                                >
                                                            </span>
                                                            <span v-if="!editServices">
                                                                <span v-if="currency != 'USD'"
                                                                    >{{
                                                                        service.price
                                                                            ? service.price
                                                                            : service.default_price
                                                                    }}
                                                                    {{ currency }}</span
                                                                >
                                                            </span>
                                                            <template v-else>
                                                                <div class="service-duration-wrapp">
                                                                    <input
                                                                        class="service-duration-input"
                                                                        type="number"
                                                                        @blur="saveService(service, index)"
                                                                        v-model="service.price"
                                                                    />
                                                                    <div class="service-duration-wrapp">
                                                                        <span
                                                                            v-if="currency == 'USD'"
                                                                            class="service-duration-minutes"
                                                                            id="basic-addon2"
                                                                            >$</span
                                                                        >
                                                                        <span v-else class="service-duration-minutes">
                                                                            {{ currency }}</span
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div
                                                            class="service-delete"
                                                            v-if="editServices"
                                                            @click="deSelectService(index)"
                                                        >
                                                            Delete
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="service-image-desc-container">
                                                    <div v-if="service.image != ''" class="service-image-modal">
                                                        <img
                                                            width="80"
                                                            :src="getProductImage(service.image, service.user_id)"
                                                            class="product-image"
                                                        />
                                                    </div>
                                                    <div>
                                                        {{ service.description }}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="add-services-container scroll-bar">
                                <template v-if="addServiceModal">
                                    <div
                                        v-for="(service, s) in selectedServicesArray"
                                        v-bind:key="'key' + s"
                                        :id="'key' + s"
                                    >
                                        <div>
                                            <div class="toggle-wrapp">
                                                <div class="">
                                                    <div class="">
                                                        <label class="switch">
                                                            <input
                                                                type="checkbox"
                                                                v-model="service.selected"
                                                                v-bind:id="service.id"
                                                                v-bind:value="service.id"
                                                                v-on:change="newSaveService(false)"
                                                                class="appt-reminder"
                                                            />
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="inner-toggle-container">
                                                    <!-- v-on:click="newAddServiceHere(service)"-->
                                                    <div class="inner-toggle-wrapp">
                                                        <div
                                                            v-if="service.service_name.length > 40"
                                                            v-tooltip="service.service_name"
                                                        >
                                                            <span
                                                                >{{ service.service_name.substr(0, 40) + "..." }}
                                                                <span v-if="service.user_id != user.id"
                                                                    >(Shared
                                                                    {{
                                                                        service.is_product == 1 ? "Product" : "Service"
                                                                    }})</span
                                                                >
                                                            </span>
                                                        </div>
                                                        <div v-if="service.service_name.length <= 40">
                                                            <span
                                                                >{{ service.service_name }}
                                                                <span v-if="service.user_id != user.id"
                                                                    >(Shared
                                                                    {{
                                                                        service.is_product == 1 ? "Product" : "Service"
                                                                    }})</span
                                                                >
                                                            </span>
                                                        </div>
                                                        <div v-if="displayPrices[newCurrentStylistId]">
                                                            <div v-if="currency == 'USD'">
                                                                ${{
                                                                    service.default_price > 0
                                                                        ? service.default_price
                                                                        : service.service_price
                                                                }}
                                                            </div>
                                                            <div v-else>
                                                                {{
                                                                    service.default_price > 0
                                                                        ? service.default_price
                                                                        : service.service_price
                                                                }}
                                                                {{ currency }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <div
                                    class="add-service-col"
                                    v-if="
                                        !addServiceModal &&
                                        !appointment_paid &&
                                        user &&
                                        user.role == 'stylist' &&
                                        selected_event &&
                                        selected_event.status != 'canceled' &&
                                        selected_event.status != 'PERSONAL TIME OFF'
                                    "
                                >
                                    <a class="add-service" @click="addServiceApp(selected_event.id)"
                                        ><i class="fa fa-plus pr-2"></i>Add Service</a
                                    >
                                </div>
                            </div>
                            <div class="add-service-btn-save" v-if="addServiceModal">
                                <a class="link primary-btn extra-small mobile" href="#" @click="saveServices">Save</a>
                            </div>

                            <div
                                class="save-row"
                                v-if="
                                    !addServiceModal &&
                                    !editServices &&
                                    !appointment_paid &&
                                    user &&
                                    user.role == 'stylist' &&
                                    selected_event &&
                                    selected_event.status != 'canceled' &&
                                    selected_event.status != 'PERSONAL TIME OFF'
                                "
                            >
                                <div class="">
                                    <button
                                        type="button"
                                        class="primary-btn extra-small mobile"
                                        v-on:click="newSaveAppointment"
                                    >
                                        {{ $ml.get("adminDashboard.save") }}
                                    </button>
                                </div>
                            </div>
                        </template>
                        <div
                            class=""
                            v-if="
                                (selected_event && selected_event.status == 'PERSONAL TIME OFF') ||
                                (selected_event &&
                                    selected_event.status != 'PERSONAL TIME OFF' &&
                                    bookData.personal == 1)
                            "
                        >
                            <button
                                v-if="displayCancelButton(selected_event)"
                                class="secondary-btn"
                                v-on:click="onCancelAppointment(selected_event.id)"
                            >
                                {{ $ml.get("adminDashboard.cancel") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- cancel appt modal stylist -->
        <div
            class="modal fade"
            id="cancel-appt-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="cancel-appt-modal"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-cancel-title">
                        <h5>Cancel appointment</h5>
                    </div>
                    <div class="modal-cancel-text">
                        <p>Why do you want to cancel?</p>
                    </div>
                    <div class="cancel-modal-body">
                        <div class="cancel-modal-btns-wrapper">
                            <button
                                v-if="user && user.role != 'client'"
                                v-on:click="cancelAppointment(false, true)"
                                class="primary-btn extra-small mobile"
                            >
                                No show
                                <span v-if="stylistPolicy && stylistPolicy.cancel && stylistPolicy.noshow_fee > 0"
                                    >({{ stylistPolicy.noshow_fee }}%)</span
                                >
                            </button>

                            <button v-on:click="cancelAppointment(false, false)" class="primary-btn extra-small mobile">
                                Canceled
                            </button>
                        </div>
                        <div class="cancel-recurring-wrap" v-if="recurring != 0">
                            <span id="cancelRecurring">Cancel reccuring</span>
                        </div>
                        <button
                            type="button"
                            data-dismiss="modal"
                            aria-label="Close"
                            class="secondary-btn small mobile"
                        >
                            Don't cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- date-modal  -->
        <div
            class="modal fade"
            id="date-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="date-modal"
            aria-hidden="true"
            data-backdrop="false"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-appt-header-container">
                            <!--Save btn modal-->
                            <div>
                                <button
                                    type="button"
                                    class="modal-appt-btn"
                                    v-bind:class="{
                                        'action-disabled': newDisableSave,
                                    }"
                                    v-on:click="newSaveAppointment"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {{ $ml.get("adminDashboard.save") }}
                                </button>
                            </div>
                            <div v-if="newCurrentStylist" class="modal-appt-btn">
                                {{ newCurrentStylist.user.first_name }} {{ newCurrentStylist.user.last_name }}
                            </div>
                            <!--Cancel btn modal-->
                            <div>
                                <button
                                    type="button"
                                    v-on:click="resetAppointmentModalData"
                                    class="modal-appt-btn"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {{ $ml.get("adminDashboard.cancel") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body book-sections scroll-bar">
                        <div class="modal-toggle-btn-wrapp">
                            <div class="can-toggle">
                                <input v-on:change="appSwitch" id="appointment-type-selector" type="checkbox" />
                                <label for="appointment-type-selector">
                                    <div
                                        class="can-toggle__switch"
                                        data-checked="CLIENT APPT"
                                        data-unchecked="PERSONAL APPT"
                                    ></div>
                                </label>
                            </div>
                        </div>
                        <div class="time-row">
                            <div class="time-row-container" v-on:click="openModal('pickDate')">
                                <div class="time-row-icon-container">
                                    <img width="25" height="25" src="../../assets/images/rounded-edit-icon.png" />
                                </div>
                                <div class="time-row-text-container">
                                    <span class="">{{ this.day }}, {{ this.date }}</span>
                                </div>
                            </div>
                            <!-- pick-date modal -->
                            <div v-if="openDateModal" class="datepicker-wrapper">
                                <datepicker v-on:selected="onDateClickFromDatePicker" :value="date" :inline="true">
                                </datepicker>
                            </div>
                            <!-- time drop down -->
                            <div class="">
                                <div v-if="!newShowTimeSpinner" class="dropdown time-dropdown">
                                    <button
                                        class="btn dropdown-toggle time-dropdown-container"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <div class="dropdown-icon-button">
                                            <img
                                                width="25"
                                                height="25"
                                                src="../../assets/images/rounded-edit-icon.png"
                                                alt="Edit Icon"
                                            />
                                        </div>
                                        <div>
                                            <span class="show-time">
                                                {{ this.hour }}
                                            </span>
                                        </div>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="time-dropdown">
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            v-for="(timeObj, i) in newTimeArray"
                                            v-bind:key="i"
                                            v-bind:class="{
                                                'free-time': timeObj.free,
                                                'taken-time': !timeObj.free,
                                                active: timeObj.value == hour,
                                            }"
                                            v-on:click="changeTime(timeObj.value)"
                                        >
                                            {{ timeObj.value }}
                                            <span class="ref"></span>
                                        </a>
                                    </div>
                                </div>
                                <div v-if="newShowTimeSpinner">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </div>
                            </div>
                        </div>
                        <div v-if="!disable" class="modal-client" v-bind:class="{ 'action-disabled': disable }">
                            <div
                                v-on:click="
                                    openModal('client');
                                    resetSearchClient();
                                "
                                v-if="!newIsClientSelected"
                                class="add-client-service"
                            >
                                {{ $ml.get("adminDashboard.dateModel.addClient") }}
                            </div>
                            <div v-on:click="openModal('client')" v-if="newIsClientSelected" class="add-client-service">
                                <div class="add-client-service">
                                    <img
                                        width="25"
                                        height="25"
                                        src="../../assets/images/rounded-edit-icon.png"
                                        alt="Edit Icon"
                                    />
                                </div>

                                <div>
                                    {{ newSelectedModalClient.first_name }}
                                    {{ newSelectedModalClient.last_name }}
                                </div>
                                <!-- </div> -->
                            </div>
                            <!-- ADD client modal -->
                            <div
                                id="add-client-modal"
                                aria-labelledby="add-client-modal"
                                aria-hidden="true"
                                data-backdrop="false"
                            >
                                <div role="document">
                                    <div class="modal-content client-modal">
                                        <div class="modal-body book-sections scroll-bar">
                                            <div v-if="!displayNewClientForm">
                                                <div class="form-group">
                                                    <span
                                                        class="fa fa-search form-control-feedback add-client-search"
                                                    ></span>
                                                    <input
                                                        v-model="newSearch"
                                                        v-on:keyup="getFreshClients()"
                                                        type="text"
                                                        class="text-field text-field-small text-field-extra-small"
                                                        placeholder="Search"
                                                    />
                                                </div>
                                                <div class="add-new-client">
                                                    <span v-on:click="displayAddClientForm"
                                                        ><i class="fa fa-plus" aria-hidden="true"></i> Add New</span
                                                    >
                                                </div>
                                                <div>
                                                    <div>
                                                        <div
                                                            v-for="(newPagedClient, index) in newPagedClients"
                                                            v-bind:key="index"
                                                            v-on:click="newSelectedClientF(newPagedClient)"
                                                            class="add-client-initials-container"
                                                        >
                                                            <div
                                                                class="avatar-img"
                                                                v-if="
                                                                    !newPagedClient.first_name &&
                                                                    !newPagedClient.last_name
                                                                "
                                                            >
                                                                <img src="../../assets/images/rmb-logo.png" />
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    newPagedClient.first_name ||
                                                                    newPagedClient.last_name
                                                                "
                                                            >
                                                                <div class="name-initials-container">
                                                                    <p class="name-initials mb-0">
                                                                        {{
                                                                            newPagedClient.first_name
                                                                                ? newPagedClient.first_name[0]
                                                                                : ""
                                                                        }}{{
                                                                            newPagedClient.last_name
                                                                                ? newPagedClient.last_name[0]
                                                                                : ""
                                                                        }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-10 pl-3 d-flex align-items-center">
                                                                {{
                                                                    newPagedClient.first_name
                                                                        ? newPagedClient.first_name
                                                                        : ""
                                                                }}
                                                                {{
                                                                    newPagedClient.last_name
                                                                        ? newPagedClient.last_name
                                                                        : ""
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-3">
                                                    <button
                                                        class="btn load-more"
                                                        v-if="newLoadMore[newCurrentStylistId]"
                                                        v-on:click="newGetClients(newCurrentStylist)"
                                                    >
                                                        Load More
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="new-client-form" v-if="displayNewClientForm">
                                                <div class="new-client-input">
                                                    <div class="min-width-field">
                                                        {{ $ml.get("general.firstName") }}
                                                        *
                                                    </div>
                                                    <div>
                                                        <input
                                                            placeholder="Enter first name"
                                                            class="text-field text-field-small text-field-extra-small"
                                                            maxlength="20"
                                                            v-model="editClientObj.first_name"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="new-client-input">
                                                    <div class="min-width-field">
                                                        {{ $ml.get("general.lastName") }}
                                                        *
                                                    </div>
                                                    <div>
                                                        <input
                                                            placeholder="Enter last name"
                                                            class="text-field text-field-small text-field-extra-small"
                                                            maxlength="20"
                                                            v-model="editClientObj.last_name"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="new-client-input">
                                                    <div class="min-width-field">{{ $ml.get("general.phone") }}*</div>
                                                    <div class="min-width-field">
                                                        <input
                                                            placeholder="Enter client phone"
                                                            class="text-field text-field-small text-field-extra-small"
                                                            type="number"
                                                            v-model="editClientObj.phone"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="new-client-input">
                                                    <div class="min-width-field">
                                                        {{ $ml.get("general.email") }}
                                                    </div>
                                                    <div>
                                                        <input
                                                            placeholder="Enter client email"
                                                            class="text-field text-field-small text-field-extra-small"
                                                            v-model="editClientObj.email"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="new-client-input"
                                                    v-if="adminDetails.group_active && adminDetails.group_owner"
                                                >
                                                    <div>
                                                        {{ $ml.get("general.stylists") }}
                                                    </div>
                                                    <div>
                                                        <div
                                                            class="new-client-toggle-container"
                                                            v-for="(member, index) in groupMembers"
                                                            v-bind:key="index"
                                                        >
                                                            <div class="toggle-btn-width">
                                                                {{ member.user.first_name }}
                                                                {{ member.user.last_name }}
                                                            </div>
                                                            <div>
                                                                <label class="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        v-bind:id="member.user.id"
                                                                        v-bind:value="member.user.id"
                                                                        v-model="editClientStylists"
                                                                        :disabled="
                                                                            newCurrentStylist.user.id == member.user.id
                                                                        "
                                                                    />
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="new-client-input">
                                                    <button
                                                        class="primary-btn extra-small mobile"
                                                        v-on:click="saveClient"
                                                    >
                                                        {{ $ml.get("general.save") }}
                                                    </button>
                                                    <button
                                                        class="secondary-btn small mobile"
                                                        v-on:click="resetClientObj()"
                                                    >
                                                        {{ $ml.get("general.cancel") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!disable" v-bind:class="{ 'action-disabled': disable }" class="modal-client">
                            <div>
                                <div v-if="!newShowSelectedServices">
                                    <div v-on:click="openModal('service')" class="add-client-service">
                                        {{ $ml.get("adminDashboard.dateModel.addService") }}
                                    </div>
                                </div>
                                <div v-if="newShowSelectedServices">
                                    <div v-on:click="openModal('service')">
                                        <div class="add-client-service">
                                            <div class="add-client-service-icon">
                                                <img
                                                    class="selected-client"
                                                    width="25"
                                                    height="25 "
                                                    src="../../assets/images/rounded-edit-icon.png"
                                                    alt="Edit Icon"
                                                />
                                            </div>
                                            <div class="selected-client">
                                                <div
                                                    v-if="
                                                        newSelectedServicesString &&
                                                        newSelectedServicesString.length <= 40
                                                    "
                                                >
                                                    {{ newSelectedServicesString }}
                                                </div>
                                                <div
                                                    v-if="
                                                        newSelectedServicesString &&
                                                        newSelectedServicesString.length > 40
                                                    "
                                                >
                                                    {{ newSelectedServicesString }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ADD service modal      -->
                                <div
                                    id="add-service-modal"
                                    aria-labelledby="add-service-modal"
                                    aria-hidden="true"
                                    data-backdrop="false"
                                >
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div>
                                                <div class="services-and-products">
                                                    <h5>Services and Products</h5>
                                                </div>
                                            </div>

                                            <div class="modal-body book-sections scroll-bar">
                                                <div>
                                                    <span class="fa fa-search inner-service-search"></span>
                                                    <input
                                                        v-on:keyup="newResultQuery"
                                                        type="text"
                                                        class="text-field text-field-small text-field-extra-small"
                                                        placeholder="Search"
                                                        v-model="newSearchQuery"
                                                    />
                                                </div>

                                                <div>
                                                    <div class="services-products-heading">
                                                        <h5>
                                                            {{ $ml.get("adminDashboard.addListServicesTitle") }}
                                                        </h5>
                                                    </div>
                                                    <div
                                                        v-for="(service, i) in newServicesArray"
                                                        v-bind:key="i"
                                                        :id="i"
                                                    >
                                                        <div>
                                                            <div
                                                                class="modal-appt-service-category"
                                                                v-if="service.is_product === 0"
                                                            ></div>
                                                            <div class="toggle-service" v-if="service.is_product === 0">
                                                                <div>
                                                                    <div>
                                                                        <label class="switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                v-model="service.selected"
                                                                                v-bind:id="service.id"
                                                                                v-bind:value="service.id"
                                                                                v-on:change="
                                                                                    newSaveService(false, true)
                                                                                "
                                                                                class="appt-reminder"
                                                                            />
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="modal-appt-service-select-wrap">
                                                                    <select
                                                                        class="selectInput selectInputExtraSmall"
                                                                        v-if="service.selected"
                                                                        v-on:change="setServiceQty($event, service.id)"
                                                                        v-model="selectedServiceQty[service.id]"
                                                                    >
                                                                        <option
                                                                            :value="num"
                                                                            v-for="(num, i) in 10"
                                                                            :key="`item-${i}`"
                                                                        >
                                                                            {{ num }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <div>
                                                                    <div class="modal-appt-service-price-wrapp">
                                                                        <div
                                                                            v-if="service.name.length > 30"
                                                                            v-tooltip="service.name"
                                                                        >
                                                                            <span
                                                                                >{{
                                                                                    service.name.substr(0, 30) + "..."
                                                                                }}
                                                                                <span v-if="service.user_id != user.id"
                                                                                    >(Shared Service)</span
                                                                                >
                                                                            </span>
                                                                        </div>
                                                                        <div v-if="service.name.length <= 30">
                                                                            <span
                                                                                >{{ service.name }}
                                                                                <span v-if="service.user_id != user.id"
                                                                                    >(Shared Service)</span
                                                                                ></span
                                                                            >
                                                                        </div>
                                                                        <div v-if="displayPrices[newCurrentStylistId]">
                                                                            <div v-if="currency == 'USD'">
                                                                                ${{ service.price }}
                                                                                <span v-if="service.price_and_up === 1"
                                                                                    >and up</span
                                                                                >
                                                                            </div>
                                                                            <div v-if="currency != 'USD'">
                                                                                {{ service.price }}
                                                                                {{ currency }}
                                                                                <span v-if="service.price_and_up === 1"
                                                                                    >and up</span
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="services-products-heading">
                                                    <h5>
                                                        {{ $ml.get("adminDashboard.addListProductsTitle") }}
                                                    </h5>
                                                </div>
                                                <div
                                                    v-for="(service, j) in newServicesArray"
                                                    v-bind:key="'p' + j"
                                                    :id="'p' + j"
                                                >
                                                    <div>
                                                        <div
                                                            class="modal-appt-service-category"
                                                            v-if="service.is_product === 1"
                                                        ></div>
                                                        <div class="toggle-service" v-if="service.is_product === 1">
                                                            <div>
                                                                <div>
                                                                    <label class="switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            v-model="service.selected"
                                                                            v-bind:id="service.id"
                                                                            v-bind:value="service.id"
                                                                            v-on:change="newSaveService(false, true)"
                                                                            class="appt-reminder"
                                                                        />
                                                                        <span class="slider round"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="modal-appt-service-select-wrap">
                                                                <select
                                                                    class="selectInput selectInputExtraSmall"
                                                                    v-if="service.selected"
                                                                    v-on:change="setServiceQty($event, service.id)"
                                                                    v-model="selectedServiceQty[service.id]"
                                                                >
                                                                    <option
                                                                        :value="num"
                                                                        v-for="(num, i) in 10"
                                                                        :key="`item-${i}`"
                                                                    >
                                                                        {{ num }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <div class="modal-appt-service-price-wrapp">
                                                                    <div
                                                                        v-if="service.name.length > 30"
                                                                        class=""
                                                                        v-tooltip="service.name"
                                                                    >
                                                                        <span
                                                                            >{{ service.name.substr(0, 30) + "..." }}
                                                                            <span v-if="service.user_id != user.id"
                                                                                >(Shared Product)</span
                                                                            >
                                                                        </span>
                                                                    </div>
                                                                    <div v-if="service.name.length <= 30" class="">
                                                                        <span
                                                                            >{{ service.name }}
                                                                            <span v-if="service.user_id != user.id"
                                                                                >(Shared Product)</span
                                                                            ></span
                                                                        >
                                                                    </div>
                                                                    <div v-if="displayPrices[newCurrentStylistId]">
                                                                        <div v-if="currency == 'USD'">
                                                                            ${{ service.price }}
                                                                            <span v-if="service.price_and_up === 1"
                                                                                >and up</span
                                                                            >
                                                                        </div>
                                                                        <div v-if="currency != 'USD'">
                                                                            {{ service.price }}
                                                                            {{ currency }}
                                                                            <span v-if="service.price_and_up === 1"
                                                                                >and up</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!disable"
                            class="service-cost-container"
                            v-bind:class="{ 'action-disabled': disable }"
                        >
                            <div>
                                <div v-if="!newShowTotalCost" class="service-cost-wrapper">
                                    <div>
                                        {{ $ml.get("adminDashboard.dateModel.serviceCost") }}
                                    </div>
                                    <div v-if="currency == 'USD'">$0.00</div>
                                    <div v-if="currency != 'USD'">0.00 {{ currency }}</div>
                                </div>

                                <div v-if="newShowTotalCost" class="service-cost-wrapper">
                                    <div>
                                        {{ $ml.get("adminDashboard.dateModel.serviceCost") }}
                                    </div>
                                    <div v-if="currency == 'USD'">${{ this.newTotalCostServices }}</div>
                                    <div v-if="currency != 'USD'">
                                        {{ this.newTotalCostServices }}
                                        {{ currency }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!disable"
                            class="service-client-confirmed-appt-container"
                            v-bind:class="{ 'action-disabled': disable }"
                        >
                            <div>
                                <div class="service-client-confirmed-appt-wrapper">
                                    <label for="client-confirmed">{{
                                        $ml.get("adminDashboard.dateModel.clientConfirmed")
                                    }}</label>
                                    <label class="switch">
                                        <input
                                            type="checkbox"
                                            v-model="clientConfirmed"
                                            :value="clientConfirmed"
                                            id="client-confirmed"
                                            class="service-client-confirmed"
                                        />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!disable"
                            class="recurring-event-container"
                            v-bind:class="{
                                'action-disabled': disable || newRescheduleInProgress,
                            }"
                        >
                            <div>
                                <div class="recurring-event-wrapper">
                                    <div>Recurring Event</div>
                                    <label class="switch">
                                        <input
                                            type="checkbox"
                                            v-model="recurring"
                                            v-bind:value="recurring"
                                            class="appt-reminder"
                                        />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!disable"
                            class="recurring-inner-content-container"
                            v-bind:class="{
                                'action-disabled': disable || newRescheduleInProgress,
                            }"
                        >
                            <div>
                                <div>
                                    <div v-if="recurring">
                                        <div class="recurring-inner-content-wrapper">
                                            <div class="label-width">
                                                <label for="repeat-every">Repeat every: </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="number"
                                                    class="text-field text-field-small recurring-input-width"
                                                    id="repeat-every"
                                                    v-model="repeatEvery"
                                                    min="0"
                                                    oninput="validity.valid||(value='');"
                                                    max="99"
                                                />
                                            </div>
                                            <div class="label-width">
                                                <div class="dropdown">
                                                    <button
                                                        class="selectInput selectInputSmall dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {{ repeatPeriod }}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a
                                                            class="dropdown-item"
                                                            v-for="(period, index) in repeatPeriods"
                                                            v-bind:key="index"
                                                            v-on:click="changeRepeatPeriod(period)"
                                                            >{{ period }}</a
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="recurring-inner-content-wrapper">
                                            <div class="label-width">
                                                <label for="occurrences">Ends after:</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="number"
                                                    class="text-field text-field-small recurring-input-width"
                                                    id="occurrences"
                                                    v-model="repeatTimes"
                                                    min="0"
                                                    max="99"
                                                    oninput="validity.valid||(value='');"
                                                />
                                            </div>
                                            <div>
                                                <label for="occurrences">occurrences</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!disable" class="note-row-container">
                            <div>
                                <div class="note-row-wrapper">
                                    <div>
                                        {{ $ml.get("adminDashboard.dateModel.note") }}
                                    </div>
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="text-field text-field-small text-field-extra-small"
                                            placeholder="Enter notes"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            v-model="notes"
                                            maxlength="100"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- notes for personal appointment -->
                        <div v-if="disable" class="note-row">
                            <label for="personalNotes"
                                >{{ $ml.get("adminDashboard.dateModel.notes") }} ({{
                                    $ml.get("adminDashboard.dateModel.optional")
                                }})</label
                            >
                            <textarea
                                class="form-control"
                                id="personalNotes"
                                rows="9"
                                v-model="notes"
                                placeholder="Write your notes!"
                                maxlength="1000"
                            ></textarea>
                        </div>
                        <div class="duration-row-container duration-row">
                            <div>
                                <div class="duration-row-wrapper">
                                    <div>
                                        {{ $ml.get("adminDashboard.dateModel.duration") }}
                                    </div>
                                    <div class="duration-row-plus-minus-container">
                                        <div v-on:click="changeDuration(false)" class="minus">
                                            <i class="fa fa-minus"></i>
                                        </div>
                                        <div v-on:click="changeDuration(true)" class="plus">
                                            <i class="fa fa-plus"></i>
                                        </div>
                                    </div>
                                    <div class="duration-minutes-box">{{ newTotalDurationServices }} min</div>
                                </div>
                            </div>
                        </div>
                        <div class="save-row-container">
                            <button
                                v-bind:class="{
                                    'action-disabled': newDisableSave,
                                }"
                                type="button"
                                class="primary-btn extra-small"
                                v-on:click="newSaveAppointment"
                            >
                                {{ $ml.get("adminDashboard.save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="event-by-status-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="event-by-status-modal"
            aria-hidden="true"
            data-backdrop="false"
        >
            <div class="modal-dialog event-by-status-modal" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-header">
                        {{ currentStatusFilter === "recurring" ? "Next Recurring Appts" : currentStatusFilter }}
                    </div>
                    <div class="modal-body book-sections scroll-bar">
                        <div class="event-filter-range">
                            {{ currentStatusFilterInterval }}
                        </div>
                        <table class="table-container">
                            <tbody class="filtered-events">
                                <tr
                                    v-for="(event, i) in statusFilterTypeAppts[currentStatusFilter]"
                                    :key="`filtered-event-${i}`"
                                    class="filtered-event section-wrap"
                                >
                                    <td class="filtered-event-num">{{ i + 1 }}. {{ event.stylist_name }}</td>
                                    <td class="filtered-event-meta">
                                        <div>{{ event.with_name }}</div>
                                        <div>
                                            {{ processServicesDisplay(event.services) }}
                                        </div>
                                        <div class="event-date">
                                            <span>{{ formatEventDate(event.date) }}</span> <br />
                                            <span v-if="event.recurring">
                                                <span
                                                    >Every {{ event.repeat_every }} {{ event.repeat_period }}, ends
                                                    {{ event.end_date | formatClientDate }}</span
                                                ></span
                                            >
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            v-if="event.status == 'unconfirmed'"
                                            class="filtered-event-actions-container"
                                        >
                                            <div class="confirm-btn" v-if="user && user.role !== event.booked_by">
                                                <div
                                                    class="primary-btn extra-small mobile"
                                                    v-on:click="confirmAppointment(event.id)"
                                                >
                                                    <i class="fa fa-calendar-check-o"></i>
                                                    {{ $ml.get("adminDashboard.confirm") }}
                                                </div>
                                            </div>
                                            <div class="view-appt-btn">
                                                <div
                                                    class="primary-btn extra-small mobile"
                                                    v-on:click="
                                                        newOnEventClick({
                                                            event: {
                                                                id: event.id,
                                                                extendedProps: { user_id: event.stylist_id },
                                                            },
                                                            appt: event.id,
                                                        })
                                                    "
                                                >
                                                    View appointment
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            v-if="event.status != 'unconfirmed'"
                                            class="filtered-event-actions-container"
                                        >
                                            <div>
                                                <div
                                                    class="primary-btn extra-small mobile"
                                                    v-on:click="
                                                        newOnEventClick({
                                                            event: { id: event.id },
                                                            appt: event.id,
                                                        })
                                                    "
                                                >
                                                    View appointment
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import $ from "jquery";
import "bootstrap";
import Vue from "vue";
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import moment from "moment";
import messages from "./../../services/messages.js";

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Datepicker from "vuejs-datepicker";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import general from "./../../services/general.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import vueSignature from "vue-signature";
import CompleteCheckoutModal from "./complete_checkout_modal.vue";

export default {
    name: "AdminDashboard",
    data: function () {
        return {
            firstLoad: false,
            calendarApptsReady: false,
            calendar_view: 1,
            calendarWeekends: true,
            displayEventTime: true,
            calendarPlugins: [
                // plugins must be defined in the JS
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin, // needed for dateClick
                resourceTimeGridPlugin,
            ],
            minTime: "00:00:00",
            maxTime: "24:00:00",
            rightButtonLayout: "dayGridMonth,timeGridWeek,timeGrid,timeGridDay",
            user: null,
            day: null,
            date: null,
            hour: null,
            newClientServices: {},
            newClients: {},
            newAppointments: {},
            dateAppointments: {},
            newDisabledTimes: {},
            newUsers: [],
            newCurrentView: null,
            newCurrentStylist: null,
            newCurrentDate: null,
            newIsGroup: false,
            groupMemberDisplayAll: false,
            groupAdminId: 0,
            newGroupId: 0,
            numberOfDataFetched: 0,
            totalNumberOfDataToFetchPerUser: 6,
            totalNumberOfDataToFetch: 0, //this will be variable = no of users * totalNumberOfDataToFetchPerUser
            newPauseBetweenAppointments: {},
            newBreakHours: {},
            newFreeDays: {},
            newFreeDaysTime: {},
            newExceptionDaysList: {},
            newServiceCategoryMapping: {},
            newColorCategoryMapping: {},
            newColors: [],
            newEvents: [],
            newResources: [],
            initialDetailsFetched: false,
            newCurrentStylistId: 0,
            newBusinessHours: {},
            newShowFullCalendarDatePicker: false,
            editServices: false,
            addService: true,
            addServiceModal: false,

            prevent_clients_reschedule: 0,

            defaultWorkSettings: {
                sunday_block_from: "",
                sunday_block_to: "",
                sunday_from: "09:00 AM",
                sunday_to: "05:00 PM",

                monday_block_from: "",
                monday_block_to: "",
                monday_from: "09:00 AM",
                monday_to: "05:00 PM",

                tuesday_block_from: "",
                tuesday_block_to: "",
                tuesday_from: "09:00 AM",
                tuesday_to: "05:00 PM",

                wednesday_block_from: "",
                wednesday_block_to: "",
                wednesday_from: "09:00 AM",
                wednesday_to: "05:00 PM",

                thursday_block_from: "",
                thursday_block_to: "",
                thursday_from: "09:00 AM",
                thursday_to: "05:00 PM",

                friday_block_from: "",
                friday_block_to: "",
                friday_from: "09:00 AM",
                friday_to: "05:00 PM",

                saturday_block_from: "",
                saturday_block_to: "",
                saturday_from: "09:00 AM",
                saturday_to: "05:00 PM",

                pause_between_appointments: 15,
                display_prices: 1,
            },

            newShowTimeSpinner: false,
            newTimeArray: [],
            newPage: {},
            newPagedClients: [],
            newLoadMore: {},
            originalLoadMore: {},
            newIsClientSelected: false,
            newSelectedModalClient: {},
            newSelectedServices: [],
            selectedServiceQty: {},
            newDisableSave: true,
            newSearch: "",
            newServicesArray: [],
            newSearchQuery: "",
            newFillteredService: [],
            newTotalCostServices: 0,
            newSelectedServicesString: "",
            newTotalDurationServices: 0,
            newShowSelectedServices: false,
            newShowTotalCost: false,
            newShowTotalDuration: false,
            newDisableSaveServices: true,
            selectedServicesArray: [],
            disable: false,
            selected_event: {},
            loading: false,
            apptClientSelected: true,
            openDateModal: false,
            notes: "",
            initials: [],
            newRescheduleInProgress: false,
            displayPrices: {},
            currentDay: "",
            currency: "USD",
            clientConfirmed: 0,

            recurring: 0,
            repeatEvery: 0,
            recurringDetails: [],
            repeatPeriod: "week",
            repeatPeriods: ["week", "month"],
            repeatTimes: 0,
            fullCalendarLicenseKey: "",
            cancelRecurringButton: null,
            cancelAppointmentInProgressId: 0,
            memberColors: {},
            showStylistSpecificAppointments: true,
            displayNewClientForm: false,
            editClientStylists: [],
            editClientObj: {
                name: "",
                email: "",
                phone: "",
            },

            weeks_before: 6,
            weeks_after: 6,
            lastFetchedAppointmentDate: null,

            openApptDetailsDateModal: false,
            eventModalIsOpened: false,
            showRescheduleSave: false,

            personal: false,
            clientWidgetSettings: {
                stylist_id: 0,
                stylist_name: "",
                stylist_avatar: "",
            },
            clientWidgetDays: [],
            clientWidgetServices: [],
            clientWidgetSelectedService: null,
            clientWidgetRegularService: null,
            clientWidgetServiceDuration: 0,
            clientWidgetServicePrice: 0,
            dayBeforeDateRight: 0,
            showCheckout: false,
            appointment_details: [],

            stripeCards: [],
            squareCards: [],
            stripe: null,
            stripe_card: null,
            square_card: null,
            cardsLoaded: false,
            addingCard: false,
            stripe_client_secret: "",
            getting_stripe_client_secret: false,
            cards_errors: "",
            amount: 0,
            tips: 0,
            payment_email: "",
            payment_card: "",
            paymentProcess: false,
            showPaymentEmail: false,
            showSignature: false,
            suqare_location_id: false,
            appointment_paid: false,

            signatureOption: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },

            supportMessages: [],
            searchQuery: "",
            filteredAppointments: [],
            searchError: false,
            executeNextLoopIn: 11000,
            initCalendar: false,
            appointmentRefreshTime: "",
            appointmentRefreshTimeCounter: 10,
            showCalendarDateChoosePopup: false,
            secondaryDatepickerDate: new Date(),
            statusFilterTypes: [
                "unconfirmed",
                "confirmed",
                "checked-in",
                "checked-out",
                "no-show",
                "cancellation",
                "total",
                "recurring",
            ],
            statusFilterTypeAppts: {
                unconfirmed: [],
                confirmed: [],
                "checked-in": [],
                "checked-out": [],
                "no-show": [],
                cancellation: [],
                total: [],
                recurring: [],
            },
            currentStatusFilter: "",
            currentStatusFilterInterval: "",
            eventRendering: false,
            eventRenderTimeout: null,
            currentCalendarView: "",
            customCalendarButtons: {
                next: { click: () => {} },
                prev: { click: () => {} },
                today: { text: "Today", click: () => {} },
                dayGridMonth: { text: "month", click: () => {} },
                timeGridWeek: { text: "week", click: () => {} },
                timeGrid: { text: "3 days", click: () => {} },
                resourceTimeGridDay: { text: "day", click: () => {} },
            },
            currentDayNumber: 0,
            calendarViews: {
                DAY: 1,
                THREE_DAYS: 3,
                WEEK: 7,
                MONTH: 30,
            },
            apptPast: false,
            bookAgainStatus: false,
            editPersonalAppointment: false,
            groupMemberAdmin: 0,
        };
    },
    mounted: function () {
        this.user = auth.user;

        if (this.user && this.user.role == "client") {
            this.calendar_view = 0;
        }
        this.initCalendar = true;
        //STEP 1
        //determine if we are dealing with a group or a single user
        this.populateCurrentViewAndDate();
        this.getUserSupportMessages();

        this.getSupportMessagesStatus();
        if (this.user.role == "client") {
            this.getLoggedInUserDetails();
            this.firstLoad = true;
        }

        //handling of various events
        //to open and close the calendar from the big date in center
        $(document).on("click", ".fc-toolbar.fc-header-toolbar .fc-center", () => {
            this.newShowFullCalendarDatePicker = !this.newShowFullCalendarDatePicker;
        });

        //to center to the time of the time dropdown selected value
        let self = this;
        $(document).on("shown.bs.dropdown", ".time-dropdown", function () {
            $(this).find(".dropdown-menu a.active").focus();
            //close the date picker if its opened
            self.openApptDetailsDateModal = false;
        });

        //to have personal appt duration always as 10 min by default
        $("#date-modal").on("show.bs.modal", () => {
            if (!this.apptClientSelected && !this.editPersonalAppointment) {
                this.newTotalDurationServices = 10;
            }
        });

        $("#date-modal").on("hide.bs.modal", () => {
            if (this.newRescheduleInProgress) {
                this.hour = null;
            }
            this.newRescheduleInProgress = false;
            this.newSearch = "";
        });

        //set data on open and close event-modal
        $("#event-modal").on("show.bs.modal", () => {
            this.eventModalIsOpened = true;
        });

        $("#event-modal").on("hide.bs.modal", () => {
            this.eventModalIsOpened = false;
            this.showRescheduleSave = false;
        });

        //add licence to the calendar
        if (typeof api.fullCalendarLicenseKey !== "undefined") {
            this.fullCalendarLicenseKey = api.fullCalendarLicenseKey;
        }

        //add cancel recurring button click event
        $(document).on("click", "#cancelRecurring", () => {
            Vue.swal.close();
            $("#cancel-appt-modal").modal("hide");

            Vue.swal({
                title: "Are you sure you want to cancel the recurring appointment?",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: this.$ml.get("adminDashboard.details.cancelAppointmentYes"),
                cancelButtonText: this.$ml.get("adminDashboard.details.cancelAppointmentNo"),
            }).then((result) => {
                if (result.value) {
                    this.cancelAppointment(true);
                    return;
                }
                this.cancelAppointmentInProgressId = 0;
            });
        });

        $(document).on("click", "#cancelAppointment", () => {
            this.cancelAppointment();
            Vue.swal.close();
        });

        $(document).on("click", "#dontCancelAppointment", () => {
            Vue.swal.close();
        });

        $(document).on("click", "#noShowAppointment", () => {
            //ask and implement no show
            const noShow = true;
            const cancelRecurring = false;
            this.cancelAppointment(cancelRecurring, noShow);
            Vue.swal.close();
        });

        $(document).on("click", ".fc-next-button", () => {
            this.handleNextButtonClick();
            this.currentDayNumber = this.getDayNumber();
        });

        $(document).on("click", ".fc-prev-button", () => {
            this.handlePrevButtonClick();
            this.currentDayNumber = this.getDayNumber();
        });

        $(document).on("click", ".fc-dayGridMonth-button", () => {
            this.handleMonthButtonClick();
            this.currentDayNumber = this.getDayNumber();
        });

        $(document).on("click", ".fc-timeGridWeek-button", () => {
            this.handleWeekButtonClick();
            this.currentDayNumber = this.getDayNumber();
        });

        $(document).on("click", ".fc-timeGrid-button", () => {
            this.handleThreeDaysButtonClick();
            this.currentDayNumber = this.getDayNumber();
        });

        $(document).on("click", ".fc-resourceTimeGridDay-button", () => {
            this.handleDayButtonClick();
            this.currentDayNumber = this.getDayNumber();
        });

        $(document).on("click", ".fc-today-button", () => {
            this.handleTodayButtonClick();
            this.currentDayNumber = this.getDayNumber();
        });

        //get the settings for the client widget
        if (this.user.role == "client") {
            this.getClientWidgetSettings();
        }

        //execute these lines after the calendar loads
        $(document).on("load", ".calendar-wrap", () => {});

        $(window).resize(() => {
            this.setDateLabelPos();
        });

        eventBus.$on("admin_sidebar_collapse", () => {
            setTimeout(() => {
                this.setDateLabelPos();
            }, 300);
        });

        //set data on open and close event-modal
        $("#event-modal").on("show.bs.modal", () => {
            this.eventModalIsOpened = true;
        });

        $("#event-modal").on("hide.bs.modal", () => {
            this.eventModalIsOpened = false;
            this.showRescheduleSave = false;
        });

        this.currentDayNumber = this.getDayNumber();

        //Get the saved calendar view
        this.getCalendarView();

        $("#completeCheckoutModal").on("hidden.bs.modal", this.onModalClose);

        this.openDateModalWithClientSet();
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
        eventBus.$off("user_profile_loaded");
    },

    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
        bookData: {
            get: function () {
                return store.state.bookData;
            },
            set: function (value) {
                store.commit("setBookData", value);
            },
        },
        previewData: {
            get: function () {
                return store.state.previewData;
            },
            set: function (value) {
                store.commit("setPreviewData", value);
            },
        },
        currentSection: {
            get: function () {
                return store.state.currentBookSection;
            },
            set: function (value) {
                store.commit("setBookSection", value);
            },
        },
        selectedStylist: {
            get: function () {
                return store.state.selectedStylist;
            },
            set: function (value) {
                store.commit("setSelectedStylist", value);
            },
        },
        selectedClient: {
            get: function () {
                return store.state.selectedClient;
            },
            set: function (value) {
                store.commit("setSelectedClient", value);
            },
        },
        stylistPolicy: {
            get: function () {
                return store.state.stylistPolicy;
            },
            set: function (value) {
                store.commit("setStylistPolicy", value);
            },
        },
        groupedEvents() {
            for (let i in this.dateAppointments) {
                if (this.dateAppointments[i].status == "canceled") {
                    delete this.dateAppointments[i];
                }
            }

            return _.groupBy(this.dateAppointments, "dayDate");
        },
        localNotificationCount: {
            get: function () {
                return store.state.localNotificationCount;
            },
            set: function (value) {
                store.commit("setLocalNotificationCount", value);
            },
        },
    },
    methods: {
        getFullCalendarApi() {
            if (this.$refs.fullCalendar) {
                return this.$refs.fullCalendar.getApi();
            }

            if (this.$children[0] && typeof this.$children[0].getApi == "function") {
                return this.$children[0].getApi();
            } else if (this.$children[1] && typeof this.$children[1].getApi == "function") {
                return this.$children[1].getApi();
            }

            return {};
        },
        handleNextButtonClick() {
            if (this.calendarApptsReady == false) {
                return;
            }

            if (this.newCurrentView == "dayGridMonth") {
                this.newCurrentDate = moment(this.newCurrentDate).add(1, "M").startOf("month").format("YYYY-MM-DD");
                this.getFullCalendarApi().next();
                return;
            }

            let currentDate = moment(this.newCurrentDate).add(1, "days").format("YYYY-MM-DD");
            this.newCurrentDate = currentDate;

            if (this.newCurrentView == "timeGrid") {
                this.threeDaysView(currentDate);
            }

            this.bookData.date = currentDate;
            this.getFullCalendarApi().gotoDate(currentDate);

            setTimeout(() => {
                this.getFullCalendarApi().gotoDate(currentDate);
                this.bookData.date = currentDate;
                this.newCurrentDate = currentDate;
                this.currentDayNumber = this.getDayNumber();
            }, 50);

            if (!this.showStylistSpecificAppointments) {
                this.filterMemberDisplayed(0);
            } else {
                this.filterMemberDisplayed(this.newCurrentStylistId);
            }
        },
        handlePrevButtonClick() {
            if (this.calendarApptsReady == false) {
                return;
            }

            if (this.newCurrentView == "dayGridMonth") {
                this.newCurrentDate = moment(this.newCurrentDate).add(-1, "M").startOf("month").format("YYYY-MM-DD");
                this.getFullCalendarApi().prev();
                return;
            }

            let currentDate = moment(this.newCurrentDate).add(-1, "days").format("YYYY-MM-DD");
            this.newCurrentDate = currentDate;

            if (this.newCurrentView == "timeGrid") {
                this.threeDaysView(currentDate);
            }

            if (this.newCurrentView != "timeGridWeek") {
                this.bookData.date = currentDate;
                this.getFullCalendarApi().gotoDate(currentDate);
            }

            setTimeout(() => {
                this.getFullCalendarApi().gotoDate(currentDate);
                this.bookData.date = currentDate;
                this.newCurrentDate = currentDate;
                this.currentDayNumber = this.getDayNumber();
            }, 50);

            if (!this.showStylistSpecificAppointments) {
                this.filterMemberDisplayed(0);
            } else {
                this.filterMemberDisplayed(this.newCurrentStylistId);
            }
        },
        handleTodayButtonClick() {
            if (this.calendarApptsReady == false) {
                return;
            }

            this.getFullCalendarApi().today();

            if (this.newCurrentView == "timeGridWeek") {
                setTimeout(() => {
                    this.newCurrentDate = moment().format("YYYY-MM-DD");
                    this.bookData.date = this.newCurrentDate;
                    this.currentDayNumber = this.getDayNumber();
                }, 50);
            }

            if (this.newCurrentView == "timeGrid") {
                this.newCurrentDate = moment().format("YYYY-MM-DD");
                this.threeDaysView(this.newCurrentDate);
                this.currentDayNumber = this.getDayNumber();
            }

            this.saveCalendarView(this.calendarViews.DAY);

            if (!this.showStylistSpecificAppointments) {
                this.filterMemberDisplayed(0);
            } else {
                this.filterMemberDisplayed(this.newCurrentStylistId);
            }
        },
        handleMonthButtonClick() {
            if (this.calendarApptsReady == false) {
                return;
            }

            this.newCurrentDate = moment().format("YYYY-MM-DD");
            this.getFullCalendarApi().gotoDate(this.newCurrentDate);
            this.getFullCalendarApi().changeView("dayGridMonth", this.newCurrentDate);

            setTimeout(() => {
                this.newCurrentDate = moment().format("YYYY-MM-DD");
                this.bookData.date = this.newCurrentDate;
                this.getFullCalendarApi().gotoDate(this.newCurrentDate);
                this.currentDayNumber = this.getDayNumber();
            }, 50);

            this.saveCalendarView(this.calendarViews.MONTH);
        },
        handleWeekButtonClick() {
            if (this.calendarApptsReady == false) {
                return;
            }

            this.newCurrentDate = moment().format("YYYY-MM-DD");
            this.bookData.date = this.newCurrentDate;
            this.getFullCalendarApi().gotoDate(this.newCurrentDate);
            this.getFullCalendarApi().changeView("timeGridWeek", this.newCurrentDate);

            setTimeout(() => {
                this.newCurrentDate = moment().format("YYYY-MM-DD");
                this.bookData.date = this.newCurrentDate;
                this.getFullCalendarApi().gotoDate(this.newCurrentDate);
                this.currentDayNumber = this.getDayNumber();
            }, 50);

            this.saveCalendarView(this.calendarViews.WEEK);
        },
        handleThreeDaysButtonClick() {
            if (this.calendarApptsReady == false) {
                return;
            }

            this.newCurrentDate = moment().format("YYYY-MM-DD");
            this.getFullCalendarApi().gotoDate(this.newCurrentDate);
            this.threeDaysView(this.newCurrentDate);

            setTimeout(() => {
                this.newCurrentDate = moment().format("YYYY-MM-DD");
                this.bookData.date = this.newCurrentDate;
                this.getFullCalendarApi().gotoDate(this.newCurrentDate);
                this.threeDaysView(this.newCurrentDate);
                this.currentDayNumber = this.getDayNumber();
            }, 50);

            this.saveCalendarView(this.calendarViews.THREE_DAYS);
        },
        handleDayButtonClick() {
            if (this.calendarApptsReady == false) {
                return;
            }

            this.newCurrentDate = moment().format("YYYY-MM-DD");
            this.getFullCalendarApi().gotoDate(this.newCurrentDate);
            this.getFullCalendarApi().changeView("resourceTimeGridDay", this.newCurrentDate);

            setTimeout(() => {
                this.newCurrentDate = moment().format("YYYY-MM-DD");
                this.bookData.date = this.newCurrentDate;
                this.getFullCalendarApi().gotoDate(this.newCurrentDate);
                this.currentDayNumber = this.getDayNumber();
            }, 50);

            if (!this.showStylistSpecificAppointments) {
                this.filterMemberDisplayed(0);
            } else {
                this.filterMemberDisplayed(this.newCurrentStylistId);
            }
        },
        getDayNumber() {
            const date = moment(this.newCurrentDate);
            return date.day();
        },
        threeDaysView: function (date) {
            let endDay = moment(date).add(3, "days").format("YYYY-MM-DD");

            this.getFullCalendarApi().changeView("timeGrid", {
                start: date,
                end: endDay,
            });
        },

        populateCurrentViewAndDate: function () {
            let currentDate = moment().format("YYYY-MM-DD");

            this.newCurrentDate = currentDate;
            this.bookData.date = moment(currentDate).toDate();
            this.secondaryDatepickerDate = this.addTimeToDate(this.bookData.date, 1, "M");

            let currentView = localStorage.getItem("calendarView");
            if (typeof currentView !== "undefined" && currentView !== null) {
                if (currentView === "timeGridDay" && (this.newIsGroup || this.groupMemberDisplayAll)) {
                    currentView = "resourceTimeGridDay";
                }
            } else {
                if (this.user.role === "client") {
                    currentView = "dayGridMonth";
                } else {
                    if (this.user.details.group_owner && this.user.details.group_active) {
                        currentView = "resourceTimeGridDay";
                    } else {
                        currentView = "timeGridWeek";
                    }
                }
            }

            this.newCurrentView = currentView;
        },
        getLoggedInUserDetails: function () {
            this.$http.get(api.actions.get_stylist_details + this.user.id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        let userDetails = response.data.details;
                        if (
                            (userDetails.group_owner && userDetails.group_active) ||
                            (userDetails.group_member_admin && userDetails.group_active)
                        ) {
                            this.newIsGroup = true;
                            this.newGroupId = userDetails.group_id;
                            this.rightButtonLayout = "dayGridMonth,timeGridWeek,timeGrid,resourceTimeGridDay";
                        }

                        if (userDetails.group_member_display_all) {
                            this.groupMemberDisplayAll = true;
                            this.newGroupId = userDetails.group_id;
                            this.rightButtonLayout = "dayGridMonth,timeGridWeek,timeGrid,resourceTimeGridDay";
                            this.newCurrentStylistId = this.user.id;
                            this.groupAdminId = userDetails.group_owner_id;
                            this.showStylistSpecificAppointments = false;
                        }

                        this.groupMemberAdmin = userDetails.group_member_admin;
                        this.newColors = userDetails.colors;
                        this.newCurrentStylist = userDetails;
                        this.newCurrentStylistId = this.newCurrentStylist.user.id;

                        if (this.user.role == "client") {
                            this.newGetAppointments(this.newCurrentStylist);
                        }

                        //STEP 2
                        //create user array with one or multiple users
                        //based upon its a group or a single user
                        this.newUsers.push(userDetails);
                        this.createUserArray();

                        //get the currency
                        if (response.data.details.profile.currency) {
                            this.currency = response.data.details.profile.currency;
                        }
                    }
                }.bind(this)
            );
        },
        createUserArray: function () {
            if (!this.newIsGroup && !this.groupMemberDisplayAll) {
                this.getData();
                return;
            }
            this.$http.get(api.actions.get_stylist_group_members + this.newGroupId).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.newResources = [];
                        response.data.members.forEach((member, index) => {
                            if (member.active != 1) {
                                return;
                            }

                            this.memberColors[member.user.id] = this.getMemberColor(member, index);

                            if (member.user.id != this.newUsers[0].user.id) {
                                this.newUsers.push(member);
                            }
                            let resource = {
                                id: member.user.id,
                                title: member.user.first_name,
                                imageurl: member.profile.avatar,
                            };
                            this.newResources.push(resource);
                            this.newPage[member.user.id] = 1;
                            this.newClients[member.user.id] = [];
                        });

                        this.newResourcesClone = _.clone(this.newResources);
                        this.filterMemberDisplayed(this.newCurrentStylistId, 0);

                        this.newUsers = _.uniqBy(this.newUsers, function (el) {
                            return el.user.id;
                        });

                        this.getData();
                    }
                }.bind(this)
            );
        },

        filterMemberDisplayed(currentStylistId, timeout = 200) {
            if (currentStylistId == 0) {
                this.newResources = _.clone(this.newResourcesClone);
                setTimeout(this.stylistAvatarImg, timeout);
                return;
            }

            this.newResources = _.filter(this.newResourcesClone, (member) => member.id == currentStylistId);

            setTimeout(this.stylistAvatarImg, timeout);
        },

        getData: function () {
            //set calendar view to know if to fetch disabled times or not

            this.totalNumberOfDataToFetch = this.newUsers.length * this.totalNumberOfDataToFetchPerUser;

            this.newUsers.forEach((user) => {
                //make break hours, block from and block to
                this.newMakeDisabledData(user); //some static data to be used later

                this.newMakeServiceCategoryMapping(user);
                this.newMakeColorCategoryMapping(user);
                this.newGetServices(user);
                this.newGetClients(user, true);
                this.newGetAppointments(user, null, true);
                this.newGetDisabledTimes(user);

                //fetch disabled times only for week or day view
                if (this.newCurrentView == "dayGridMonth") {
                    this.numberOfDataFetched++;
                    this.newMakeEvents();
                }
            });
        },
        newMakeServiceCategoryMapping: function (user) {
            let userId = user.user.id;
            let serviceCategoryMapping = {};
            _.each(user.services, function (services, categoryId) {
                if (!services.length) {
                    return;
                }
                services.forEach(function (service) {
                    serviceCategoryMapping[service.id] = categoryId;
                });
            });
            this.newServiceCategoryMapping[userId] = serviceCategoryMapping;
            this.numberOfDataFetched++;
            this.newMakeEvents();
        },
        newMakeColorCategoryMapping: function (user) {
            let userId = user.user.id;
            let colorCategoryMapping = {};
            user.service_categories.forEach((category) => {
                let targetColor = _.find(this.newColors, function (colorObject) {
                    return colorObject.id == category.color_id;
                });
                if (typeof targetColor == "undefined") {
                    colorCategoryMapping[category.id] = null;
                    return;
                }
                colorCategoryMapping[category.id] = "#" + targetColor.code;
            });
            this.newColorCategoryMapping[userId] = colorCategoryMapping;
            this.numberOfDataFetched++;
            this.newMakeEvents();
        },
        newSetCurrentViewAndDate: function () {
            //change the view based upon the local storage setting
            let currentView = localStorage.getItem("calendarView");

            if (typeof currentView !== "undefined" && currentView !== null) {
                if (currentView === "timeGridDay" && (this.newIsGroup || this.groupMemberDisplayAll)) {
                    currentView = "resourceTimeGridDay";
                }
            } else {
                if (this.user.role === "client") {
                    currentView = "dayGridMonth";
                } else {
                    if (this.user.details.group_owner && this.user.details.group_active) {
                        currentView = "resourceTimeGridDay";
                    } else {
                        currentView = "timeGridWeek";
                    }
                }
            }

            this.newCurrentView = currentView;

            let currentDate = moment().format("YYYY-MM-DD");

            this.newCurrentDate = currentDate;

            if (this.newCurrentView == "timeGrid") {
                this.threeDaysView(this.newCurrentDate);
            } else {
                this.getFullCalendarApi().changeView(this.newCurrentView);
            }

            this.$refs.fullCalendar.getApi().gotoDate(moment(this.newCurrentDate + "T00:00:00").toDate()); //2020-06-10
        },
        changeCurrentView: function (view) {
            //change the view based upon the local storage setting
            localStorage.setItem("calendarView", view);
            this.newCurrentView = view;
        },
        newGetServices: function (user) {
            let userId = user.user.id;
            this.newClientServices[userId] = {};
            this.$http.get(api.actions.get_stylist_services + "?stylist_id=" + userId).then(
                function (response) {
                    if (typeof response.data.services != "undefined") {
                        response.data.services.forEach((serv) => {
                            let targetCategory = _.find(response.data.category_services, function (category) {
                                return category.category_id == serv.category_id;
                            });
                            if (typeof targetCategory == "undefined") {
                                return;
                            }
                            if (typeof this.newClientServices[userId][targetCategory.category] == "undefined") {
                                this.newClientServices[userId][targetCategory.category] = [];
                            }
                            this.newClientServices[userId][targetCategory.category].push(serv);
                            // set default quantity value for selected services be 1
                            // make sure you dont override existing selected services quantity
                            // tried to set the selectedServiceQty to serv.service_quantity but there's no service_quantity coming from backend ...
                            if (typeof this.selectedServiceQty[serv.id] !== "number") {
                                this.selectedServiceQty[serv.id] = 1;
                            }
                        });
                    }
                    this.numberOfDataFetched++;
                    this.newMakeEvents();
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        newGetClients: function (user, firstTime = false) {
            let userId = user.user.id;

            if (firstTime) {
                this.newPagedClients = [];
            }

            this.$http
                .get(
                    api.actions.get_stylist_clients +
                        "?stylist_id=" +
                        userId +
                        "&page=" +
                        this.newPage[userId] +
                        "&search=" +
                        this.newSearch
                )
                .then(
                    function (response) {
                        if (typeof response.data.clients != "undefined") {
                            if (firstTime) {
                                this.newClients[userId] = response.data.clients;
                                if (userId == this.newCurrentStylistId) {
                                    this.newPagedClients = response.data.clients;
                                }
                                this.originalLoadMore[userId] = response.data.loadMore;
                            } else {
                                if (this.newPage[userId] != 1) {
                                    this.newPagedClients = this.newPagedClients.concat(response.data.clients);
                                } else {
                                    //for first page, replace the results
                                    this.newPagedClients = response.data.clients;
                                }
                            }

                            this.newPage[userId]++;

                            this.newLoadMore[userId] = response.data.loadMore;
                            //in case we are landing here from clients page
                            //then client should be auto selected
                            if (
                                typeof this.selectedClient != "undefined" &&
                                this.selectedClient != null &&
                                this.selectedClient
                            ) {
                                this.newManageSelectedClient();
                            }
                        }
                        this.numberOfDataFetched++;
                        this.newMakeEvents();
                    }.bind(this),
                    function () {
                        return false;
                    }
                );
        },
        openEventsByStatusModal(status) {
            this.currentStatusFilter = status;

            var calendarApi = this.getFullCalendarApi();
            var dates = calendarApi.state.dateProfile.currentRange;
            var startTime = moment(dates.start).format("MMMM DD");
            var endTime = moment(dates.end).subtract(12, "hours").format("MMMM DD");
            this.currentStatusFilterInterval = startTime + " - " + endTime;

            $("#event-by-status-modal").modal("show");
        },
        resetStatusFilterTypeAppts() {
            this.statusFilterTypeAppts = {
                unconfirmed: [],
                confirmed: [],
                "checked-in": [],
                "checked-out": [],
                "no-show": [],
                cancellation: [],
                total: [],
                recurring: [],
            };
        },
        getAppointmentsByStatus: function () {
            var calendarApi = this.getFullCalendarApi();
            var dates = calendarApi.state.dateProfile.currentRange;
            var startTime = moment(dates.start).unix();
            var endTime = moment(dates.end).unix();
            var appointments = [];
            this.resetStatusFilterTypeAppts();

            if (this.user.role == "stylist" && this.showStylistSpecificAppointments) {
                appointments = this.newAppointments[this.newCurrentStylistId];
            } else if (this.user.role == "stylist" && !this.showStylistSpecificAppointments) {
                this.newUsers.forEach((userProfile) => {
                    appointments = _.concat(appointments, this.newAppointments[userProfile.user.id]);
                });
            } else {
                appointments = this.newAppointments[this.user.id];
            }

            if (!appointments || _.isEmpty(appointments)) {
                this.resetStatusFilterTypeAppts();
                return;
            }

            appointments.forEach((el) => {
                var aptTime = moment(el.date).unix();
                if (aptTime >= startTime && aptTime <= endTime) {
                    if (el.status == "unconfirmed") {
                        this.statusFilterTypeAppts["unconfirmed"].push(el);
                    } else if (el.status == "confirmed") {
                        this.statusFilterTypeAppts["confirmed"].push(el);
                    } else if (el.status == "paid") {
                        this.statusFilterTypeAppts["checked-in"].push(el);
                    } else if (el.status == "canceled" && !el.cancel_details) {
                        this.statusFilterTypeAppts["cancellation"].push(el);
                    } else if (el.status == "canceled" && el.cancel_details) {
                        this.statusFilterTypeAppts["no-show"].push(el);
                    }

                    this.statusFilterTypeAppts["total"].push(el);

                    if (el.recurring) {
                        this.statusFilterTypeAppts["recurring"].push(el);
                    }
                }
            });
        },
        newGetAppointments: function (user, targetDate = null, executeLoop = false) {
            this.resetLoop();
            if (
                targetDate == null &&
                typeof this.lastFetchedAppointmentDate != "undefined" &&
                this.lastFetchedAppointmentDate != null
            ) {
                targetDate = this.lastFetchedAppointmentDate;
            }
            if (
                targetDate == null &&
                typeof this.bookData != "undefined" &&
                typeof this.bookData.date != "undefined" &&
                this.bookData.date != null
            ) {
                targetDate = this.bookData.date;
            }

            this.lastFetchedAppointmentDate = targetDate;

            let userId = user.user.id;
            this.newAppointments[userId] = [];
            let data = {
                date:
                    targetDate != null
                        ? moment(targetDate).format("YYYY-MM-DD hh:mm:ss")
                        : moment().format("YYYY-MM-DD hh:mm:ss"),
                weeks_before: this.weeks_before,
                weeks_after: this.weeks_after,
                include_canceled: false,
            };

            if (this.newIsGroup) {
                data.group_user_id = userId;
            } else if (this.groupMemberDisplayAll) {
                data.group_user_id = userId;
                data.group_admin_id = this.groupAdminId;
            }

            var method = this.user.role == "stylist" ? "get_stylist_appointments" : "get_client_appointments";
            this.calendarApptsReady = false;
            this.$http.post(api.actions[method], data).then(
                function (response) {
                    this.weeks_before = 6;
                    this.weeks_after = 6;
                    this.loading = false;
                    let self = this;
                    if (!response.data.error) {
                        let today = moment();
                        this.appointmentRefreshTime = today.format("h:mm:ss a");

                        if (this.user.role == "client") {
                            let finalObj = {};

                            this.firstLoad = false;
                            response.data.appointments.forEach((appt, key) => {
                                appt.dayDate = appt.date.split(" ")[0];
                                if (self.user && self.user.role == "client") {
                                    appt.services_array = appt.services ? appt.services.split(";") : [];
                                    appt.services_qty = appt.services_qty ? appt.services_qty.split(";") : [];

                                    appt.services_images_array = appt.services_images
                                        ? appt.services_images.split(";")
                                        : [];
                                    appt.services_description_array = appt.services_description
                                        ? appt.services_description.split(";")
                                        : [];

                                    let duration = appt.duration ? appt.duration : 15;
                                    let endDate = moment(appt.date).add(duration, "minutes");
                                    let start = moment(appt.date).format("hh:mm A");
                                    let end = endDate.format("hh:mm A");
                                    appt.end_date = endDate.format("YYYY-MM-DD HH:mm:ss");
                                    appt.start = start;
                                    appt.end = end;
                                }

                                const myDate = moment(appt.dayDate, "YYYY-MM-DD");
                                //if date is not in past
                                if (today.diff(myDate, "days") <= 0) {
                                    finalObj[key] = appt;
                                }
                            });
                            this.dateAppointments = finalObj;
                        }
                        this.newAppointments[userId] = response.data.appointments;
                    }

                    this.numberOfDataFetched++;

                    this.newMakeEvents(executeLoop);

                    let openEventId = sessionStorage.getItem("openEventId");

                    if (openEventId) {
                        sessionStorage.setItem("openEventId", "");

                        this.selected_event = _.find(this.newAppointments[this.newCurrentStylistId], {
                            id: openEventId,
                        });

                        if (this.user.role == "client") {
                            this.viewAppointmentDetails({ id: openEventId });
                        }

                        if (this.selected_event) {
                            this.newOnEventClick({
                                event: { id: openEventId },
                            });
                        } else {
                            this.$http
                                .post(api.actions.get_stylist_appointments, {
                                    appointment_id: openEventId,
                                })
                                .then(function (response) {
                                    if (!response.data.error) {
                                        if (!response.data.appointments[0]) {
                                            return;
                                        }

                                        this.newAppointments[userId].push(response.data.appointments[0]);
                                        this.newOnEventClick({
                                            event: {
                                                id: openEventId,
                                                date: response.data.appointments[0].date,
                                            },
                                        });
                                    }
                                });
                        }
                    }

                    this.resetLoop();
                    if (this.initCalendar || executeLoop) {
                        let intId = setInterval(() => {
                            this.appointmentRefreshTimeCounter -= 1;
                            this.$data.executeNextLoopIn -= 1000;
                            if (this.executeNextLoopIn < 1000) {
                                if (!this.$refs.fullCalendar) {
                                    return;
                                }
                                var calendarApi = this.getFullCalendarApi();

                                var dates = calendarApi.state.dateProfile.currentRange;
                                this.newGetAppointments(this.getCurrentUserObject(user), dates.start, true);
                                clearInterval(intId);
                            }
                        }, 1000);
                        this.initCalendar = false;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
            this.calendarApptsReady = true;
        },

        getCurrentUserObject(cUser) {
            if (!this.newIsGroup) {
                return cUser;
            }

            let currentUser = cUser;

            this.newUsers.forEach((userProfile) => {
                if (userProfile.user.id == this.newCurrentStylistId) {
                    currentUser = userProfile;
                }
            });

            return currentUser;
        },
        resetLoop() {
            this.appointmentRefreshTimeCounter = 10;
            this.$data.executeNextLoopIn = 10000;
        },
        zeroPadNum(num, places) {
            var zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
        },
        setDisabledWeekDays(days, user) {
            days.forEach((day) => {
                if (user.work_settings[day] === 0) {
                    let dayShort = day.substring(0, 3);

                    if (this.newCurrentView == "resourceTimeGridDay") {
                        $(
                            ".fc-day.fc-" +
                                dayShort +
                                "[data-resource-id='" +
                                user.user.id +
                                "'],.fc-day-top.fc-" +
                                dayShort
                        ).addClass("fc-disabled");
                    } else {
                        $(".fc-day.fc-" + dayShort + ",.fc-day-top.fc-" + dayShort).addClass("fc-disabled");
                    }
                }
            });

            window.$ = $;

            if (user.free_days.length > 0) {
                user.free_days.forEach((date) => {
                    if (this.newCurrentView == "resourceTimeGridDay") {
                        $('.fc-day[data-date="' + date + '"][data-resource-id="' + user.user.id + '"]').addClass(
                            "fc-disabled"
                        );
                    } else {
                        $('.fc-day[data-date="' + date + '"]').addClass("fc-disabled");
                    }
                });
            }

            if (user.exception_days.length > 0) {
                user.exception_days.forEach((date) => {
                    if (this.newCurrentView == "resourceTimeGridDay") {
                        $('.fc-day[data-date="' + date + '"][data-resource-id="' + user.user.id + '"]').removeClass(
                            "fc-disabled"
                        );
                    } else {
                        $('.fc-day[data-date="' + date + '"]').removeClass("fc-disabled");
                    }
                });
            }
        },
        disableWeekDays(user) {
            let days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

            if (user.user.id != this.newCurrentStylistId && this.newCurrentView != "resourceTimeGridDay") {
                return;
            }

            $(".calendar-wrap .fc-disabled").removeClass("fc-disabled");

            setTimeout(() => {
                this.setDisabledWeekDays(days, user);
            }, 200);

            setTimeout(() => {
                this.setDisabledWeekDays(days, user);
            }, 800);
        },
        newGetDisabledTimes: function (user) {
            let userId = user.user.id;
            this.newDisabledTimes[userId] = [];

            //4 things to manage here, this function will be responsible for
            //showing the grey area on the calendar

            //free_days and free_days_time
            let objectsFreeTime = this.newManageFreeTime(user);
            if (objectsFreeTime && objectsFreeTime.length) {
                objectsFreeTime.forEach((object) => {
                    this.newDisabledTimes[userId].push(object);
                });
            }

            //get_stylist_free_hours to make before and after the business hour time area as grey - only http request here
            let activeDateRange;
            if (this.user && this.user.role === "client") {
                activeDateRange = {
                    start: new Date(),
                    end: moment().add("days", 7).toDate(),
                };
            } else {
                activeDateRange = this.getFullCalendarApi().component.props.dateProfile.activeRange;
            }

            let datesArray = this.newGetDates(activeDateRange.start, activeDateRange.end);
            this.newCurrentDate = datesArray[0];
            let startBusinessHour = 23;
            let currentStartHour = this.minTime;

            datesArray.forEach((date, index) => {
                // let formattedDate = moment(date).format('YYYY-MM-DD');
                let formattedDate = date;

                // let dateForBusinessHours = new Date(moment(date).format('LL'));
                let dateForBusinessHours = moment(formattedDate + "T00:00:00").toDate();
                let businessDayIndex = dateForBusinessHours.getDay();
                let objectBusinessHours = this.newManageBusinessHours(date, businessDayIndex, userId);
                if (objectBusinessHours) {
                    this.newDisabledTimes[userId] = this.newDisabledTimes[userId].concat(objectBusinessHours);
                }

                //make break hours grey area data (newDisabledTimes)
                let dateForBreak = moment(formattedDate + "T00:00:00").toDate();
                let dayIndex = dateForBreak.getDay();
                let objectBreakHours = this.newManageBreakHours(formattedDate, dayIndex, userId);
                if (objectBreakHours) {
                    this.newDisabledTimes[userId].push(objectBreakHours);
                }
                if (index === datesArray.length - 1) {
                    this.numberOfDataFetched++;
                    //this.newMakeEvents();
                }

                if (this.newBusinessHours[userId] && this.newBusinessHours[userId][dayIndex]) {
                    let startHourArr = this.newBusinessHours[userId][dayIndex]["start"].split(":");
                    let startHour = parseInt(startHourArr[0]) - 2;

                    if (startHour < startBusinessHour) {
                        startBusinessHour = startHour;
                        currentStartHour =
                            this.zeroPadNum(startHour, 2) + ":" + startHourArr[1] + ":" + startHourArr[2];
                    }
                }
            });

            this.minTime = currentStartHour;

            //Disable week days
            this.disableWeekDays(user);
        },
        newGetDates: function (startDate, stopDate) {
            let dateArray = [];
            let currentDate = moment(startDate);
            stopDate = moment(stopDate);
            while (currentDate <= stopDate) {
                dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
                currentDate = moment(currentDate).add(1, "days");
            }
            return dateArray;
        },
        newProcessMakeTimeResponse: function (response) {
            if (typeof response.data.free_hours == "undefined") {
                return [];
            }

            let freeHours = response.data.free_hours;
            let takenHours = response.data.taken_hours;
            let timeArray = [];
            let timeArray2 = [];

            timeArray = freeHours.map((timeString) => {
                let obj = {
                    value: timeString,
                    free: true,
                    // date: new Date(this.newCurrentDate+" "+timeString)
                    date: moment(this.newCurrentDate + " " + timeString, "YYYY-MM-DD hh:mm A").toDate(),
                };
                return obj;
            });

            timeArray2 = takenHours.map((timeString) => {
                let obj = {
                    value: timeString,
                    free: false,
                    // date: new Date(this.newCurrentDate+" "+timeString)
                    date: moment(this.newCurrentDate + " " + timeString, "YYYY-MM-DD hh:mm A").toDate(),
                };
                return obj;
            });

            timeArray = timeArray.concat(timeArray2);
            timeArray.sort(function (obj1, obj2) {
                return obj1.date - obj2.date;
            });
            return timeArray;
        },
        newMakeDisabledData: function (user) {
            let userId = user.user.id;
            let workSetting = user.work_settings;

            if (typeof workSetting == "undefined" || workSetting == null) {
                workSetting = this.defaultWorkSettings;
            }
            this.newPauseBetweenAppointments[userId] = 0;
            if (typeof workSetting.pause_between_appointments != "undefined") {
                this.newPauseBetweenAppointments[userId] = workSetting.pause_between_appointments;
            } else {
                this.newPauseBetweenAppointments[userId] = this.defaultWorkSettings.pause_between_appointments;
            }
            if (typeof user.free_days != "undefined") {
                this.newFreeDays[userId] = user.free_days;
            }
            if (typeof user.free_days_time != "undefined") {
                this.newFreeDaysTime[userId] = user.free_days_time;
            }
            this.newBreakHours[userId] = [
                {
                    start: this.newGetFormattedBreakTimes(workSetting.sunday_block_from, "sunday_block_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.sunday_block_to, "sunday_block_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.monday_block_from, "monday_block_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.monday_block_to, "monday_block_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.tuesday_block_from, "tuesday_block_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.tuesday_block_to, "tuesday_block_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.wednesday_block_from, "wednesday_block_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.wednesday_block_to, "wednesday_block_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.thursday_block_from, "thursday_block_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.thursday_block_to, "thursday_block_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.friday_block_from, "friday_block_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.friday_block_to, "friday_block_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.saturday_block_from, "saturday_block_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.saturday_block_to, "saturday_block_to"),
                },
            ];

            this.newBusinessHours[userId] = [
                {
                    start: this.newGetFormattedBreakTimes(workSetting.sunday_from, "sunday_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.sunday_to, "sunday_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.monday_from, "monday_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.monday_to, "monday_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.tuesday_from, "tuesday_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.tuesday_to, "tuesday_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.wednesday_from, "wednesday_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.wednesday_to, "wednesday_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.thursday_from, "thursday_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.thursday_to, "thursday_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.friday_from, "friday_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.friday_to, "friday_to"),
                },
                {
                    start: this.newGetFormattedBreakTimes(workSetting.saturday_from, "saturday_from"),
                    end: this.newGetFormattedBreakTimes(workSetting.saturday_to, "saturday_to"),
                },
            ];

            this.newExceptionDaysList[userId] = user.exception_days_list;

            if (typeof workSetting.display_prices != "undefined") {
                this.displayPrices[userId] = workSetting.display_prices;
            } else {
                this.displayPrices[userId] = this.defaultWorkSettings.display_prices;
            }
        },
        newGetFormattedBreakTimes: function (timeString, type) {
            if (typeof timeString == "undefined" || timeString == "") {
                timeString = this.defaultWorkSettings[type];
            }

            // timeString = moment(new Date(this.newCurrentDate+' '+timeString)).format('HH:mm:ss');
            if (!timeString) {
                timeString = moment(this.newCurrentDate + "T00:00:00").format("HH:mm:ss");
            } else {
                timeString = moment(this.newCurrentDate + " " + timeString, "YYYY-MM-DD hh:mm A").format("HH:mm:ss");
            }

            return timeString;
        },
        newManageBreakHours: function (date, dayIndex, userId) {
            if (this.user.role == "client") {
                return;
            }
            if (
                typeof this.newBreakHours[userId][dayIndex] !== "undefined" &&
                typeof this.newBreakHours[userId][dayIndex]["start"] !== "undefined" &&
                this.newBreakHours[userId][dayIndex]["start"] !== "00:00:00" &&
                this.newBreakHours[userId][dayIndex]["start"] != "" &&
                typeof this.newBreakHours[userId][dayIndex]["end"] !== "undefined" &&
                this.newBreakHours[userId][dayIndex]["end"] !== "00:00:00" &&
                this.newBreakHours[userId][dayIndex]["end"] != ""
            ) {
                let object = {
                    start: date + " " + this.newBreakHours[userId][dayIndex]["start"],
                    end: date + " " + this.newBreakHours[userId][dayIndex]["end"],
                    type: "disabledTime",
                };
                return object;
            }
        },
        newManageBusinessHours: function (date, dayIndex, userId) {
            if (this.user.role == "client") {
                return;
            }

            if (
                typeof this.newBusinessHours[userId][dayIndex] !== "undefined" &&
                typeof this.newBusinessHours[userId][dayIndex]["start"] !== "undefined" &&
                this.newBusinessHours[userId][dayIndex]["start"] != "" &&
                typeof this.newBusinessHours[userId][dayIndex]["end"] !== "undefined" &&
                this.newBusinessHours[userId][dayIndex]["end"] != ""
            ) {
                //Set the exception day working hours
                if (this.newExceptionDaysList[userId] && this.newExceptionDaysList[userId].length > 0) {
                    let currentException = _.filter(this.newExceptionDaysList[userId], { date: date });

                    if (currentException.length > 0) {
                        let timeFrom =
                            currentException[0].work_from != ""
                                ? (timeFrom = currentException[0].work_from)
                                : this.newBusinessHours[userId][dayIndex]["start"];
                        let timeTo =
                            currentException[0].work_to != ""
                                ? currentException[0].work_to
                                : this.newBusinessHours[userId][dayIndex]["end"];

                        let exceptionFrom = moment(date + " " + timeFrom, "YYYY-MM-DD hh:mm A").format(
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        let exceptionTo = moment(date + " " + timeTo, "YYYY-MM-DD hh:mm A").format(
                            "YYYY-MM-DD HH:mm:ss"
                        );

                        let objects = [
                            {
                                start: date + " 00:00:00",
                                end: exceptionFrom,
                                type: "disabledTime",
                            },
                            {
                                start: exceptionTo,
                                end: date + " 23:59:59",
                                type: "disabledTime",
                            },
                        ];

                        return objects;
                    }
                }

                //Otherwise go with the default working hours
                let objects = [
                    {
                        start: date + " 00:00:00",
                        end: date + " " + this.newBusinessHours[userId][dayIndex]["start"],
                        type: "disabledTime",
                    },
                    {
                        start: date + " " + this.newBusinessHours[userId][dayIndex]["end"],
                        end: date + " 23:59:59",
                        type: "disabledTime",
                    },
                ];

                return objects;
            }
        },
        newManageFreeTime: function (user) {
            let userId = user.user.id;

            let objects = [];
            if (this.newFreeDays[userId].length) {
                this.newFreeDays[userId].forEach((freeDate) => {
                    let date = moment(freeDate).format("YYYY-MM-DD");
                    let freeDayObject = {
                        start: date + " 00:00:00",
                        end: date + " 23:59:59",
                        type: "disabledTime",
                    };
                    objects.push(freeDayObject);
                });
            }

            // this.newFreeDaysTime[userId] = [];
            if (this.newFreeDaysTime[userId].length) {
                this.newFreeDaysTime[userId].forEach((freeDateT) => {
                    // let start = moment(freeDateT.date+' '+freeDateT.free_from).format("YYYY-MM-DD HH:mm:ss");
                    let start = moment(freeDateT.date + " " + freeDateT.free_from, "YYYY-MM-DD hh:mm A").format(
                        "YYYY-MM-DD HH:mm:ss"
                    );
                    // let end = moment(freeDateT.date+' '+freeDateT.free_to).format("YYYY-MM-DD HH:mm:ss");
                    let end = moment(freeDateT.date + " " + freeDateT.free_to, "YYYY-MM-DD hh:mm A").format(
                        "YYYY-MM-DD HH:mm:ss"
                    );
                    let freeDayObject = {
                        start: start,
                        end: end,
                        type: "disabledTime",
                    };
                    objects.push(freeDayObject);
                });
            }
            return objects;
        },
        newMakeEvents: function (newMakeEvents = false) {
            if (!newMakeEvents && this.numberOfDataFetched !== this.totalNumberOfDataToFetch) {
                return;
            }
            //we have all the data we need at this moment

            let appointments = {};
            let disabledTimes = {};

            //create events based upon the data
            if (
                (this.newCurrentView == "dayGridMonth" ||
                    this.newCurrentView == "timeGridWeek" ||
                    this.newCurrentView == "timeGrid") &&
                this.showStylistSpecificAppointments
            ) {
                appointments[this.newCurrentStylist.user.id] = !_.isEmpty(
                    this.newAppointments[this.newCurrentStylist.user.id]
                )
                    ? this.newAppointments[this.newCurrentStylist.user.id].slice(0)
                    : [];
                if (this.newCurrentView == "timeGridWeek" || this.newCurrentView == "timeGrid") {
                    disabledTimes[this.newCurrentStylist.user.id] =
                        this.newDisabledTimes[this.newCurrentStylist.user.id].slice(0);
                }
            } else {
                if (this.newIsGroup || this.groupMemberDisplayAll) {
                    appointments = { ...this.newAppointments };
                    disabledTimes = { ...this.newDisabledTimes };
                } else {
                    appointments[this.user.id] =
                        this.newAppointments[this.user.id] != null ? this.newAppointments[this.user.id].slice(0) : [];
                    disabledTimes[this.user.id] = this.newDisabledTimes[this.user.id].slice(0);
                }
            }

            //refresh the events
            this.newEvents = [];

            let startEndRegister = [];

            //merge disabledTimes to appointments
            _.each(disabledTimes, (disabledTimeObjects, userId) => {
                let insertDisabledTimeObjects = [];
                disabledTimeObjects.forEach((disabledTimeObject) => {
                    if (disabledTimeObject.start == disabledTimeObject.end) {
                        return;
                    }
                    let startEndRegisterEntry = disabledTimeObject.start + "-" + disabledTimeObject.end + "-" + userId;
                    if (startEndRegister.indexOf(startEndRegisterEntry) != -1) {
                        return;
                    }
                    startEndRegister.push(startEndRegisterEntry);
                    insertDisabledTimeObjects.push(disabledTimeObject);
                });

                appointments[userId] = !_.isEmpty(appointments[userId])
                    ? appointments[userId].concat(insertDisabledTimeObjects)
                    : insertDisabledTimeObjects;
            });

            _.each(appointments, (appts, userId) => {
                appts.forEach((el) => {
                    if (el.status == "canceled") {
                        return;
                    }

                    el.user_id = userId;
                    //add services in the week and daily view (will be in monthly view too but wont be visible because of the space)
                    if (typeof el.services == "string" && el.services) {
                        el.services_array = el.services.split(";");
                    } else {
                        el.services_array = [];
                    }
                    el.showServices = el.services_array.join(", ");

                    let eventObject = this.newGetEventObject(el);
                    this.newEvents.push(eventObject);

                    //make pause event
                    let pauseEventObject = this.newGetPauseEventObject(eventObject);
                    if (pauseEventObject) {
                        this.newEvents.push(pauseEventObject);
                    }

                    //the loading on refresh button
                    this.loading = false;
                });
            });

            this.initialDetailsFetched = true;
        },
        newGetEventObject: function (el) {
            if (typeof el.type !== "undefined" && el.type === "disabledTime") {
                let eventObject = {
                    id: 0,
                    start: el.start,
                    end: el.end,
                    backgroundColor: "#D0D0D0 !important",
                    title: "Not Available",
                    rendering: "background",
                    user_id: el.user_id,
                    type: "disabledTime",
                };
                if (this.newIsGroup || this.groupMemberDisplayAll) {
                    eventObject.resourceId = el.user_id;
                }
                return eventObject;
            }

            let duration = el.duration ? el.duration : 15;
            let endDate = moment(el.date).add(duration, "minutes");
            let start = moment(el.date).format("hh:mm A");
            let end = endDate.format("hh:mm A");
            el.end_date = endDate.format("YYYY-MM-DD HH:mm:ss");
            el.start = start;
            el.end = end;

            let eventObject = {
                id: el.id,
                start: el.date,
                end: el.end_date,
                title: el.with_name + " - From: " + start + " To: " + end + " " + el.showServices,
                backgroundColor: "#e0e0f0 !important",
                textColor: "#000000 !important",
                user_id: el.user_id,
                prevent_clients_reschedule: el.prevent_clients_reschedule,
                status: el.status,
                cancel_details: el.cancel_details,
                className: "",
            };

            if (this.newCurrentView == "resourceTimeGridDay" || this.newCurrentView == "timeGridDay") {
                eventObject["borderColor"] = this.memberColors[el.user_id] + " !important";
                eventObject["className"] += " eventCustomClass";
            }

            if (typeof el.recurring !== "undefined" && el.recurring == 1) {
                eventObject["className"] += " recurringEvent";
                eventObject["recurring"] = 1;
            }

            if (this.newIsGroup || this.groupMemberDisplayAll) {
                eventObject.resourceId = el.user_id;
                eventObject.constraint = {
                    resourceIds: [el.user_id], // constrain dragging to these columns
                };
            }
            if (el.services_ids == null) {
                return eventObject;
            }

            var targetColorCode = "000000";

            //Get the event color from service
            if (el.services_colors != null) {
                let targetServiceColor = el.services_colors.split(";");
                targetColorCode = targetServiceColor[0];
            }

            if (typeof targetColorCode !== "undefined" && targetColorCode !== null) {
                eventObject["backgroundColor"] = "#" + targetColorCode + " !important";
                eventObject["textColor"] = "#ffffff !important";
            }
            return eventObject;
        },
        newGetPauseEventObject: function (eventObject) {
            if (
                this.newPauseBetweenAppointments[eventObject.user_id] <= 0 ||
                (typeof eventObject.type != "undefined" && eventObject.type == "disabledTime")
            ) {
                return;
            }
            let endDate = moment(eventObject.end).add(this.newPauseBetweenAppointments[eventObject.user_id], "minutes");
            let pauseEventObject = {
                id: 0,
                start: eventObject.end,
                end: endDate.format("YYYY-MM-DD HH:mm:ss"),
                backgroundColor: "#D0D0D0 !important",
                title: "Not Available",
                rendering: "background",
                user_id: eventObject.user_id,
            };
            if (this.newIsGroup || this.groupMemberDisplayAll) {
                pauseEventObject.resourceId = eventObject.user_id;
            }
            return pauseEventObject;
        },
        newOnDateRender: function (info) {
            this.currentDay = moment(this.getFullCalendarApi().getDate()).format("ddd");
            this.resetStatusFilterTypeAppts();

            //refresh the events
            this.newEvents = [];

            if (
                typeof info.view != "undefined" &&
                typeof info.view.activeStart != "undefined" &&
                typeof info.view.type !== "undefined" &&
                info.view.type != "dayGridMonth" &&
                info.view.type != "timeGridWeek" &&
                info.view.type != "timeGrid"
            ) {
                localStorage.setItem("calendarDate", moment(info.view.activeStart).format("YYYY-MM-DD"));
            }

            if (
                info.view.type == "timeGrid" &&
                info.view.type != this.newCurrentView &&
                typeof info.view.type != "undefined"
            ) {
                this.threeDaysView(this.newCurrentDate);
            }
            if (!this.initialDetailsFetched && info.view.type != this.newCurrentView) {
                this.newSetCurrentViewAndDate();
                return;
            }

            if (!this.initialDetailsFetched) {
                if (this.user.role == "stylist") {
                    this.getLoggedInUserDetails();
                }
                this.initialDetailsFetched = true;
                return;
            }

            this.changeCurrentView(info.view.type);

            let targetDate = null;
            if (
                typeof info != "undefined" &&
                typeof info.view != "undefined" &&
                typeof info.view.currentStart != "undefined"
            ) {
                targetDate = info.view.currentStart;
            }

            this.currentCalendarView = info.view.type;

            switch (info.view.type) {
                case "dayGridMonth":
                    this.weeks_before = 6;
                    this.weeks_after = 6;
                    this.numberOfDataFetched = 0;
                    this.totalNumberOfDataToFetch = 2;
                    this.newGetDisabledTimes(this.newCurrentStylist);
                    this.newGetAppointments(this.newCurrentStylist, targetDate);
                    break;
                case "timeGridWeek":
                    this.weeks_before = 1;
                    this.weeks_after = 1;
                    this.numberOfDataFetched = 0;
                    this.totalNumberOfDataToFetch = 2;
                    this.newGetDisabledTimes(this.newCurrentStylist);
                    this.newGetAppointments(this.newCurrentStylist, targetDate);
                    break;
                case "timeGrid":
                    this.weeks_before = 1;
                    this.weeks_after = 1;
                    this.numberOfDataFetched = 0;
                    this.totalNumberOfDataToFetch = 2;
                    this.newGetDisabledTimes(this.newCurrentStylist);
                    this.newGetAppointments(this.newCurrentStylist, targetDate);
                    break;
                case "timeGridDay":
                case "resourceTimeGridDay":
                    this.weeks_before = 1;
                    this.weeks_after = 1;
                    this.numberOfDataFetched = 0;
                    this.totalNumberOfDataToFetch = 2 * this.newUsers.length;
                    this.newUsers.forEach((user) => {
                        this.newGetDisabledTimes(user);
                        this.newGetAppointments(user, targetDate);
                    });
                    break;
            }
        },
        stylistAvatarImg() {
            if (this.user.role == "stylist") {
                // if(currentView === "resourceTimeGridDay" && !this.showStylistSpecificAppointments) {
                //     return;
                // }

                $("img.stylist-avatar.calendar-avatar").remove();
                this.newResources.forEach((item) => {
                    let el = $(`th[data-resource-id = '${item.id}']`);
                    el.addClass("center-table-head");
                    if (item.imageurl !== "") {
                        el.prepend(`<img class="stylist-avatar calendar-avatar" src='${item.imageurl}'>`);
                        el.parent().addClass("calendar-head-row");
                    }
                });
            }
        },
        newOnStylistChange: function () {
            this.loading = true;
            this.newCurrentStylist = this.newUsers.find((user) => user.user.id == this.newCurrentStylistId);
            this.filterMemberDisplayed(this.newCurrentStylistId);
            this.numberOfDataFetched = 0;
            this.totalNumberOfDataToFetch = 2;
            this.newGetDisabledTimes(this.newCurrentStylist);
            this.newGetAppointments(this.newCurrentStylist);
            this.newPagedClients = this.newClients[this.newCurrentStylistId];
            this.newPage[this.newCurrentStylistId] = 2;
            this.newLoadMore[this.newCurrentStylistId] = this.originalLoadMore[this.newCurrentStylistId];
        },
        newOnDateClickFromFullCalendarDatePicker: function (date) {
            this.newCurrentDate = moment(date).format("YYYY-MM-DD");
            this.newShowFullCalendarDatePicker = false;
            this.bookData.date = date;
            this.getFullCalendarApi().gotoDate(date);
            this.showCalendarDateChoosePopup = false;
            this.secondaryDatepickerDate = this.addTimeToDate(this.bookData.date, 1, "M");

            if (this.newCurrentView == "timeGridWeek") {
                setTimeout(() => {
                    this.newCurrentDate = moment(date).format("YYYY-MM-DD");
                    this.bookData.date = this.newCurrentDate;
                    this.getFullCalendarApi().gotoDate(this.newCurrentDate);
                }, 50);
            }

            if (this.newCurrentView == "timeGrid") {
                this.threeDaysView(date);
            }
        },
        newOnDateClick: function (arg, noResetTime = false) {
            this.newShowTimeSpinner = true;
            this.bookData.personal = false;

            let type = localStorage.getItem("calendarView");
            if (typeof arg.view !== "undefined" && typeof arg.view.type !== "undefined") {
                type = arg.view.type;
            }
            if (typeof type == "undefined") {
                type = "dayGridMonth";
            }

            if (
                (this.newIsGroup || this.groupMemberDisplayAll) &&
                (type == "timeGridDay" || type == "resourceTimeGridDay")
            ) {
                if (typeof arg.resource !== "undefined" && typeof arg.resource.id !== "undefined") {
                    this.newCurrentStylistId = arg.resource.id;
                    this.newCurrentStylist = this.newUsers.find((user) => user.user.id == this.newCurrentStylistId);

                    this.newPagedClients = this.newClients[this.newCurrentStylistId];
                    this.newPage[this.newCurrentStylistId] = 2;
                    this.newLoadMore[this.newCurrentStylistId] = this.originalLoadMore[this.newCurrentStylistId];
                }
            }

            let date = arg.date;
            this.bookData.date = date;
            this.day = moment(date).format("ddd");
            this.date = moment(date).format("l");

            if (!noResetTime) {
                this.hour = moment(date).format("hh:mm A");
            }

            //get the time array to show the time dropdown for the clicked date
            let currentDatetime = moment().format("YYYY-MM-DD h:mm a");
            let currentDate = moment(this.bookData.date).format("YYYY-MM-DD");
            let query =
                "?include_taken=1&stylist_id=" +
                this.newCurrentStylistId +
                "&date=" +
                currentDate +
                "&current_datetime=" +
                currentDatetime;
            this.$http.get(api.actions.get_stylist_free_hours + query).then((response) => {
                if (typeof response.data.free_hours != "undefined") {
                    this.newTimeArray = this.newProcessMakeTimeResponse(response);

                    let missingTimeArray = this.fillInMissingTimes(
                        this.newTimeArray[0],
                        this.newTimeArray[this.newTimeArray.length - 1]
                    );

                    this.newTimeArray = this.newTimeArray.concat(missingTimeArray);
                    this.newTimeArray.sort(function (obj1, obj2) {
                        return obj1.date - obj2.date;
                    });

                    //get the closest hour to be the selected one
                    let allFormattedTime = [];
                    this.newTimeArray.forEach(function (element) {
                        // allFormattedTime.push(new Date(element.date));
                        allFormattedTime.push(element.date);
                    });

                    this.newShowTimeSpinner = false;
                }
            });

            let selectedDate = moment(date);
            if (this.user.role == "client" && moment().diff(selectedDate, "days") > 0) {
                return;
            }
            if (this.user.role !== "client") {
                if (!this.eventModalIsOpened) {
                    $("#date-modal").modal("show");
                }
            } else {
                this.$router.push("/app/stylists");
            }
            return;
        },
        fillInMissingTimes: function (time1, time2) {
            let x = 15; //minutes interval
            let times = []; // time array
            let tt = 0; // start time
            let ap = ["AM", "PM"]; // AM-PM

            //loop to increment the time and push results in array
            for (let i = 0; tt < 24 * 60; i++) {
                let hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
                let mm = tt % 60; // getting minutes of the hour in 0-55 format

                let nextTime =
                    (hh == 12 ? "12" : "0" + (hh % 12)).slice(-2) +
                    ":" +
                    ("0" + mm).slice(-2) +
                    " " +
                    ap[Math.floor(hh / 12)];

                if (
                    moment(this.newCurrentDate + " " + nextTime, "YYYY-MM-DD hh:mm A").toDate() >= time1.date &&
                    moment(this.newCurrentDate + " " + nextTime, "YYYY-MM-DD hh:mm A").toDate() <= time2.date
                ) {
                    tt = tt + x;
                    continue;
                }
                tt = tt + x;
                times.push({
                    date: moment(this.newCurrentDate + " " + nextTime, "YYYY-MM-DD hh:mm A").toDate(),
                    free: true,
                    value: nextTime,
                });
            }
            return times;
        },
        newManageSelectedClient: function () {
            if (this.selectedClient) {
                this.newSelectedClientF(this.selectedClient);
            }

            //trigger open the book appointment modal
            this.newOnDateClick({
                date: moment().toDate(),
            });
        },
        newSelectedClientF: function (pagedClient) {
            this.newIsClientSelected = true;
            this.newSelectedModalClient = pagedClient;
            $("#add-client-modal").slideUp();
            this.newManageSaveAppointmentButton();
        },
        newManageSaveAppointmentButton: function () {
            let active = false;
            active = this.bookData.date && this.hour;

            if (this.apptClientSelected) {
                active = this.newSelectedServices.length && this.newSelectedModalClient.id;
            }
            this.newDisableSave = !active;
        },
        getFreshClients: function () {
            this.searchDebouncedClients(this);
        },
        onDateClickFromFullCalendarDatePicker: function (date) {
            this.newShowFullCalendarDatePicker = false;
            this.getFullCalendarApi().gotoDate(date);
        },
        searchDebouncedClients: _.debounce((self) => {
            self.newPage[self.newCurrentStylist["user"]["id"]] = 1;
            self.newGetClients(self.newCurrentStylist, true);
        }, 500),
        openModal: function (type) {
            switch (type) {
                case "client":
                    $("#add-client-modal").slideToggle();
                    $("#add-service-modal").slideUp();
                    break;
                case "service":
                    $("#add-service-modal").slideToggle();
                    $("#add-client-modal").slideUp();
                    this.newMakeListServices();
                    break;
                case "pickDate":
                    this.openDateModal = true;
                    break;
                case "pickApptDate":
                    this.openApptDetailsDateModal = !this.openApptDetailsDateModal;
                    break;
            }
        },
        newMakeListServices: function () {
            //turn selected as true for those services in array
            let selected_service_ids = _.map(this.newSelectedServices, (el) => {
                return el.id;
            });

            let servicesArray = [];

            _.each(this.newClientServices[this.newCurrentStylistId], function (category, categoryName) {
                if (category.length) {
                    category.forEach(function (element) {
                        let serviceObject = {
                            name: element.name,
                            price: element.price,
                            selected: false,
                            id: element.id,
                            user_id: element.user_id,
                            duration: element.duration,
                            categoryName: categoryName,
                            price_and_up: element.price_and_up,
                            featured: element.featured,
                            is_product: element.is_product,
                            qty: element.qty,
                        };

                        if (selected_service_ids.indexOf(element.id) != -1) {
                            serviceObject.selected = true;
                        }
                        servicesArray.push(serviceObject);
                    });
                }
            });

            //make feature ones come at the top in alphabetica order
            var featuredServices = servicesArray.filter(function (serv) {
                return serv.featured;
            });
            //sort them into alphabetical order
            // descending order
            featuredServices.sort(function (serv1, serv2) {
                if (serv1.name < serv2.name) {
                    return -1;
                }
                if (serv2.name < serv1.name) {
                    return 1;
                }
                return 0;
            });

            var unfeaturedServices = servicesArray.filter(function (serv) {
                return !serv.featured;
            });
            this.newServicesArray = featuredServices.concat(unfeaturedServices);

            this.newServicesArray = _.uniqBy(this.newServicesArray, function (el) {
                return el.id;
            });

            // this.servicesArray = servicesArray;
            this.newFillteredService = this.newServicesArray.slice();
        },
        newAddServiceHere: function (service) {
            $("#add-service-modal").slideUp();
            // servicesArray
            this.newServicesArray.forEach(function (element) {
                if (element.id !== service.id) {
                    element.selected = false;
                } else {
                    element.selected = true;
                }
            });

            this.newShowSelectedServices = true;
            this.newSelectedServicesString = service.name;
            this.newSelectedServices = [service];
            this.newTotalCostServices = service.price;
            this.newTotalDurationServices = service.duration;
            this.newShowTotalCost = true;
            this.newShowTotalDuration = true;
            this.newManageSaveAppointmentButton();
        },
        newResultQuery() {
            this.newServicesArray = this.newFillteredService.slice();
            if (this.newSearchQuery) {
                this.newServicesArray = this.newServicesArray.filter((item) => {
                    return this.newSearchQuery
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.name.toLowerCase().includes(v));
                });
            }
        },
        newSaveService: function (closeModal = false, resetQty = false) {
            if (closeModal) {
                $("#add-service-modal").slideUp();
            }

            let existingQty = {};

            if (this.newSelectedServices.length > 0) {
                this.newSelectedServices.forEach((service) => {
                    if (service.selected) {
                        existingQty[service.id] = parseInt(service.qty);
                    }
                });
            }

            this.newTotalCostServices = 0;
            this.newSelectedServices = [];
            this.newShowSelectedServices = false;
            this.newSelectedServicesString = "";

            let targetServices = [];
            this.newServicesArray.forEach((service) => {
                if (service.selected) {
                    let newService = _.clone(service);
                    newService.qty = existingQty[newService.id] ? existingQty[newService.id] : 1;
                    targetServices.push(newService);
                } else if (resetQty == true) {
                    this.selectedServiceQty[service.id] = 1;
                }
            });

            if (!Object.keys(targetServices).length) {
                return;
            }
            this.newSelectedServices = targetServices;
            //making selected services in string
            let allServices;
            this.newSelectedServices.forEach((element) => {
                if (allServices) {
                    allServices = allServices + ", " + element.name;
                } else {
                    allServices = element.name;
                }
                this.newSelectedServicesString = allServices;
            });

            //here starts logic for total cost
            let totalCost;
            this.newSelectedServices.forEach((element) => {
                const serviceQuantity = this.selectedServiceQty[element.id];

                if (totalCost) {
                    totalCost = totalCost + element.price * serviceQuantity;
                } else {
                    totalCost = element.price * serviceQuantity;
                }
                this.newTotalCostServices = totalCost;
            });
            //total cost logic ends

            //logic for duration

            let totalDuration;
            this.newSelectedServices.forEach((element) => {
                if (totalDuration) {
                    totalDuration = totalDuration + element.duration;
                } else {
                    totalDuration = element.duration;
                }
                this.newTotalDurationServices = totalDuration;
            });

            if (this.newSelectedServices.length) {
                this.newShowSelectedServices = true;
            } else {
                this.newShowSelectedServices = false;
            }

            if (this.newTotalCostServices > 0) {
                this.newShowTotalCost = true;
            } else {
                this.newShowTotalCost = false;
            }

            if (this.newTotalDurationServices > 0) {
                this.newShowTotalDuration = true;
            } else {
                this.newShowTotalDuration = false;
            }

            //enable save button
            if (this.newSelectedServices.length) {
                this.newDisableSaveServices = false;
            } else {
                this.newDisableSaveServices = true;
            }

            this.newManageSaveAppointmentButton();
            this.calculateServiceTotals();
        },
        changeDuration: function (increase) {
            if (increase) {
                this.newTotalDurationServices = this.newTotalDurationServices + 10;
                return;
            }
            //you cant have duration in minus
            if (this.newTotalDurationServices > 0) {
                this.newTotalDurationServices = this.newTotalDurationServices - 10;
            }
        },

        deSelectService(index) {
            this.newSelectedServices.splice(index, 1);
            this.selectedServicesArray[index].selected = 0;
            this.calculateServiceTotals();
        },

        setServiceQty(event, service_id) {
            this.selectedServiceQty[service_id] = event.target.value;
            let currentService = _.filter(this.newServicesArray, { id: service_id });

            this.newSelectedServices.forEach((service) => {
                if (service.id === service_id) {
                    service.qty = event.target.value;

                    if (currentService.length > 0) {
                        currentService = currentService[0];
                        service.default_duration = currentService.duration;
                        service.duration = service.qty * currentService.duration;
                        service.default_price = currentService.price;
                        service.price = service.qty * currentService.price;
                    } else {
                        service.duration = service.qty * service.default_duration;
                        service.price = service.qty * service.default_price;
                    }
                }
            });

            this.calculateServiceTotals();
        },

        calculateServiceTotals() {
            this.newTotalCostServices = 0;
            this.newTotalDurationServices = 0;
            this.newSelectedServices.forEach((service) => {
                // service.qty seems to be undefined so we have to rely on this.selectedServiceQty[service.id]
                let qty = service.qty
                    ? parseInt(service.qty)
                    : this.selectedServiceQty[service.id]
                    ? this.selectedServiceQty[service.id]
                    : 1;

                this.newTotalCostServices += (service.default_price ? service.default_price : service.price) * qty;
                this.newTotalDurationServices +=
                    (service.default_duration ? service.default_duration : service.duration) * qty;
                // Warning: Prone to errors
                if (typeof this.selectedServiceQty[service.id] !== "number") {
                    this.selectedServiceQty[service.id] = qty;
                }
            });

            this.appointment_details.total_price = this.newTotalCostServices;
            if (!this.appointment_details.details) {
                this.appointment_details.details = { total_payments: 0 };
            }
            this.amount =
                parseFloat(this.appointment_details.total_price) -
                parseFloat(
                    this.appointment_details.details.total_payments
                        ? this.appointment_details.details.total_payments
                        : 0
                );
        },

        getServicesQty() {
            let response = {};

            this.newSelectedServices.forEach((service) => {
                let duration =
                    this.bookData.id == 0
                        ? service.qty * (service.default_duration ? service.default_duration : service.duration)
                        : service.duration;
                let price =
                    this.bookData.id == 0
                        ? service.qty * (service.default_price ? service.default_price : service.price)
                        : service.price;

                response[service.id] = {
                    quantity: service.qty,
                    duration: duration,
                    price: price,
                };
            });

            return response;
        },

        newSaveAppointment: function () {
            if (this.newTotalDurationServices <= 0) {
                Vue.toasted.show("Select duration", {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                return;
            }

            //validate recurring related inputs
            if (this.recurring) {
                this.repeatEvery = parseInt(this.repeatEvery);
                if (this.repeatEvery < 1 || this.repeatEvery > 99) {
                    Vue.toasted.show("Repeat every value should be between 0 and 100", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }

                this.repeatTimes = parseInt(this.repeatTimes);
                if (this.repeatTimes < 1 || this.repeatTimes > 99) {
                    Vue.toasted.show("Occurrences value should be between 0 and 100", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }
            }

            //so that user doenst multi click
            this.newDisableSave = true;
            let selectedServicesID = [];

            this.newSelectedServices.forEach(function (serviceId) {
                selectedServicesID.push(serviceId.id);
            });

            let formattedDate = moment(this.bookData.date).format("YYYY-MM-DD");
            let stylist_id = this.newCurrentStylistId;

            if (this.user.role == "client" && stylist_id == this.user.id) {
                stylist_id = this.bookData.stylist_id;
            }

            //preparing object for personal appointment
            let data = {
                personal: true,
                user_id: stylist_id,
                client_id: 0,
                date_time: formattedDate,
                hour: this.hour,
                services: [],
                new_services: this.getServicesQty(),
                notes: this.notes,

                special_event: "",
                reminders: "",
                status: "unconfirmed",
                check_time_limit: 1,
                total_duration: this.newTotalDurationServices,
                update_recurring: 0,
                recurring: this.recurring,
            };

            //change required keys if its client appointment
            if (this.apptClientSelected || !this.personal) {
                data["personal"] = typeof this.bookData.personal != "undefined" ? this.bookData.personal : false;
                data["client_id"] = this.newSelectedModalClient.id;
                data["services"] = selectedServicesID;
            } else {
                data["status"] = "confirmed";
                data["current_user_id"] = data["user_id"];
            }

            if (this.bookData.id) {
                data["id"] = this.bookData.id;
            }

            if (this.clientConfirmed) {
                data["status"] = "confirmed";
            }

            if (data["recurring"]) {
                data["repeat_every"] = this.repeatEvery;
                data["repeat_period"] = this.repeatPeriod;
                data["repeat_times"] = this.repeatTimes;
            }

            //if its an update request, always have update_recurring 1
            if (typeof data["id"] !== "undefined" && data["id"] && data["recurring"]) {
                data["update_recurring"] = 1;
            }

            let policy = this.clientWidgetSettings.stylist_policy;

            if (this.user.role == "client" && data["id"] != 0 && policy.reschedule == 1 && policy.reschedule_fee > 0) {
                let appt_date_time = moment(this.bookData.original_date).unix();
                let current_date_time = moment().unix();
                let hours_diff = (appt_date_time - current_date_time) / 3600;

                if (hours_diff <= policy.reschedule_advance_hours && this.newTotalCostServices > 0) {
                    data["reschedule_fee"] = this.newTotalCostServices * (policy.reschedule_fee / 100);
                }
            }

            this.$http.post(api.actions.save_appointment, data).then(
                function (response) {
                    if (this.personal === true) {
                        this.appSwitch();
                        $("#appointment-type-selector").prop("checked", false);
                    }
                    this.personal = false;
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.APPOINTMENT_SAVED);

                        this.newDisableSave = true;

                        $("#event-modal").modal("hide");
                        if (this.user.role == "stylist") {
                            this.newCurrentStylist = this.newUsers.find(
                                (user) => user.user.id == this.newCurrentStylistId
                            );
                        }
                        this.numberOfDataFetched = 0;
                        this.totalNumberOfDataToFetch = 2;
                        this.newGetDisabledTimes(this.newCurrentStylist);
                        this.newGetAppointments(this.newCurrentStylist);
                        this.resetData();
                        this.editServices = false;
                        this.addServiceModal = false;
                        $("#date-modal").modal("hide");
                        $("#add-service-modal").slideUp();
                        $("#add-client-modal").slideUp();
                        this.resetAppointmentModalData();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        appSwitch: function () {
            this.apptClientSelected = !this.apptClientSelected;
            if (this.apptClientSelected) {
                this.disable = false;
                this.newManageSaveAppointmentButton();
                this.resetData();
                this.personal = false;
                return;
            }
            this.disable = true;
            this.newManageSaveAppointmentButton();
            this.resetData();
            this.newTotalDurationServices = 10;
            this.personal = true;
        },
        resetSearchClient() {
            this.newSearch = "";
            this.resetClientObj();
            this.resetData();
        },
        resetAppointmentModalData() {
            this.resetData();
            this.editServices = false;
            this.addServiceModal = false;
            this.clientConfirmed = 0;
            this.newSearchQuery = "";
            this.newSearch = "";
            this.newDisableSave = true;
            this.getFreshClients();
            if (this.personal === true) {
                this.appSwitch();
                $("#appointment-type-selector").prop("checked", false);
            }
            $("#date-modal").modal("hide");
            $("#add-service-modal").slideUp();
            $("#add-client-modal").slideUp();
        },
        resetData: function () {
            this.bookData.id = 0;
            this.newSelectedModalClient = {};
            this.newIsClientSelected = false;
            this.newShowSelectedServices = false;
            this.newSelectedServicesString = "";
            this.newTotalCostServices = 0;
            this.newShowTotalCost = false;
            this.newTotalDurationServices = 0;
            this.newShowTotalDuration = false;
            this.newSelectedServices = [];
            this.notes = "";
            this.editServices = false;
            this.addServiceModal = false;

            //reset services
            this.newUsers.forEach((user) => {
                _.each(this.newClientServices[user.user.id], (services) => {
                    services.forEach((service) => {
                        service.selected = false;
                        service.qty = 0;
                    });
                });
            });

            this.newServicesArray.forEach((service) => {
                this.selectedServiceQty[service.id] = 1;
            });

            this.recurring = 0;
            this.repeatEvery = 0;
            this.repeatPeriod = "week";
            this.repeatTimes = 0;
        },
        changeTime: function (timeString) {
            if (typeof timeString == "undefined") {
                return;
            }
            this.hour = timeString;

            if (this.eventModalIsOpened) {
                this.showRescheduleSave = true;
            }
        },
        onDateClickFromDatePicker: function (date) {
            this.newCurrentDate = moment(date).format("YYYY-MM-DD");
            this.openDateModal = false;

            if (this.eventModalIsOpened) {
                this.openApptDetailsDateModal = false;
                this.showRescheduleSave = true;
            }

            this.newOnDateClick(
                {
                    date: date,
                },
                true
            );
        },
        editPersonalAppt(appt) {
            if (appt.status === "PERSONAL TIME OFF") {
                this.editPersonalAppointment = true;
                $("#event-modal").modal("hide");

                setTimeout(() => {
                    $("#date-modal").modal("show");
                }, 200);

                this.newTotalDurationServices = parseInt(appt.duration);
                this.disable = true;
                this.personal = true;

                this.apptClientSelected = !this.apptClientSelected;
                $("#appointment-type-selector").prop("checked", true);

                this.newDisableSave = false;
            }
        },
        newOnEventClick: function (info) {
            let userId = this.newCurrentStylistId;

            if (
                typeof info.event != "undefined" &&
                typeof info.event.extendedProps != "undefined" &&
                typeof info.event.extendedProps.user_id != "undefined"
            ) {
                userId = info.event.extendedProps.user_id;
            }
            let calendarEvent = info.event;

            if (calendarEvent.id == 0) {
                return;
            }

            this.selected_event = {};
            this.selected_event = _.find(this.newAppointments[userId], {
                id: parseInt(calendarEvent.id),
            });

            if (info.appt) {
                this.searchQuery = null;
                this.filteredAppointments = [];
            }

            if (!this.selected_event && info.appt) {
                this.selected_event = info.appt;
                this.selected_event = {
                    booked_by: info.appt.booked_by,
                    date: info.appt.date,
                    duration: info.appt.total_duration,
                    end: info.appt.hour_end,
                    end_date: moment(info.appt.date).add(info.appt.total_duration, "minutes"),
                    id: info.appt.id,
                    notes: info.appt.notes,
                    price: info.appt.total_cost,
                    recurring: info.appt.recurring,
                    services: info.appt.services,
                    services_array: info.appt.services_array,
                    services_colors: [],
                    services_full: [],
                    services_ids: "",
                    showServices: info.appt.show,
                    start: info.appt.hour_begin,
                    status: info.appt.status,
                    user_id: info.appt.user_id,
                    with_name: info.appt.client.full_name,
                    client_email: info.appt.client.email,
                    client_phone: info.appt.client.phone,
                };
            }
            if (!this.selected_event) {
                return;
            }

            //round the price
            if (this.selected_event.price) {
                this.selected_event.price = parseFloat(this.selected_event.price.toFixed(2));
            }

            if (typeof this.selected_event.services == "string" && this.selected_event.services) {
                this.selected_event.services_array = this.selected_event.services.split(";");
            } else {
                this.selected_event.services_array = [];
            }
            if (!this.selected_event.notes) {
                this.selected_event.notes = "There were no notes made";
            }

            if (
                (!this.selected_event.services_array || !this.selected_event.services_array.length) &&
                this.selected_event.status == "confirmed"
            ) {
                this.selected_event.status = "PERSONAL TIME OFF";
            }

            if (
                !this.selected_event.services_array ||
                (!this.selected_event.services_array.length && this.selected_event.status == "unconfirmed")
            ) {
                this.selected_event.status = "PERSONAL TIME OFF";
            }

            this.initials = [];
            let name = this.selected_event.with_name;
            let nameArray = name.split(" ");
            if (nameArray.length && typeof nameArray[0][0] !== "undefined") {
                this.initials.push(nameArray[0][0].toUpperCase());
            }
            if (nameArray.length > 1 && typeof nameArray[1][0] !== "undefined") {
                this.initials.push(nameArray[1][0].toUpperCase());
            }

            $("#event-by-status-modal").modal("hide");

            //get appointment details heree
            this.$http.get(api.actions.get_appointment_details + this.selected_event.id + "?webRequest=1").then(
                function (response) {
                    if (response.data) {
                        this.appointment_details = response.data;
                        this.selectedServicesArray = response.data.services;

                        let details = response.data.details;

                        let selected_services = _.filter(response.data.services, {
                            selected: 1,
                        });
                        let selected_service_ids = _.map(selected_services, (el) => {
                            return el.service_id;
                        });
                        let selected_service_names = _.map(selected_services, (el) => {
                            return el.service_name;
                        });

                        this.stripeCards = [];
                        this.squareCards = [];
                        this.stripe = null;
                        this.stripe_card = null;
                        this.square_card = null;
                        this.cardsLoaded = false;
                        this.addingCard = false;
                        this.stripe_client_secret = "";
                        this.getting_stripe_client_secret = false;
                        this.cards_errors = "";
                        this.amount = 0;
                        this.tips = 0;
                        this.payment_card = "";
                        this.paymentProcess = false;
                        this.showPaymentEmail = false;
                        this.showCheckout = false;

                        this.payment_email = "";
                        this.payment_email = false;
                        this.showSignature = false;
                        this.suqare_location_id = false;

                        this.amount =
                            parseFloat(this.appointment_details.total_price) -
                            parseFloat(
                                this.appointment_details.details.total_payments
                                    ? this.appointment_details.details.total_payments
                                    : 0
                            );
                        this.appointment_paid = this.appointment_details.details && this.amount <= 0 ? true : false;

                        this.payment_email = response.data.details.email;
                        this.bookData = {
                            id: details.id,
                            stylist_id: details.user_id,
                            client_id: details.client_id,
                            services: selected_service_ids,
                            date: moment(details.date).toDate(),
                            special_event: details.special_event,
                            notes: details.notes,
                            reminders: details.reminders.split(","),
                            personal: details.personal,
                        };

                        this.$http
                            .post(api.actions.get_square_locations, {
                                stylist_id: this.bookData.stylist_id,
                            })
                            .then((response) => {
                                if (response.data.error == false || response.data.locations) {
                                    this.suqare_location_id = response.data.locations[0].id;
                                }
                            });

                        this.selected_event.services_full = selected_services;

                        this.notes = details.notes;

                        this.selected_event.services_full = selected_services;

                        this.notes = details.notes;

                        //select the date
                        this.day = moment(this.bookData.date).format("ddd");
                        this.date = moment(this.bookData.date).format("l");
                        this.hour = moment(this.bookData.date).format("hh:mm A");

                        //manage recurring
                        if (typeof details.recurring !== "undefined" && details.recurring) {
                            this.recurring = details.recurring;
                        }
                        if (this.recurring && typeof details.recurring_details !== "undefined") {
                            if (typeof details.recurring_details.repeat_every !== "undefined") {
                                this.repeatEvery = details.recurring_details.repeat_every;
                            }
                            if (typeof details.recurring_details.repeat_period !== "undefined") {
                                this.repeatPeriod = details.recurring_details.repeat_period;
                            }
                            if (typeof details.recurring_details.repeat_times !== "undefined") {
                                this.repeatTimes = details.recurring_details.repeat_times;
                            }
                        }

                        this.previewData = {
                            stylistName: "",
                            services: selected_service_names,
                        };

                        if (details.user_id == auth.user.id) {
                            this.selectedStylist = auth.user;
                        } else {
                            let stylist = _.filter(this.newUsers, {
                                user: { id: details.user_id },
                            });

                            if (stylist.length > 0) {
                                this.selectedStylist = stylist[0].user;
                            }
                        }

                        let client = _.filter(this.newClients, {
                            id: details.client_id,
                        });

                        if (client.length > 0) {
                            this.selectedClient = client[0];
                        }

                        $("#event-modal").modal("show");

                        this.recurringDetails = details.recurring_details;

                        if (auth.user.role == "client") {
                            this.currency = details.currency;
                        }

                        if (auth.user.role == "stylist") {
                            this.currentSection = 2;

                            this.newCurrentStylistId = this.selectedStylist.id;
                            this.newCurrentStylist = this.newUsers.find(
                                (user) => user.user.id == this.newCurrentStylistId
                            );
                            //select the client
                            if (
                                typeof details.client !== "undefined" &&
                                typeof details.client.first_name !== "undefined" &&
                                typeof details.client.last_name !== "undefined" &&
                                typeof details.client.id !== "undefined"
                            ) {
                                this.newSelectedModalClient = {
                                    first_name: details.client.first_name,
                                    last_name: details.client.last_name,
                                    id: details.client.id,
                                };
                            } else {
                                this.newSelectedModalClient = _.find(this.newPagedClients, function (client) {
                                    return client.id == details.client_id;
                                });
                            }

                            if (this.newSelectedModalClient) {
                                this.newIsClientSelected = true;
                            }

                            //select the services
                            this.newTotalCostServices = 0;
                            this.newSelectedServices = [];
                            // this.newGetServices(this.selectedStylist.id);
                            this.newMakeListServices();

                            let targetService = _.filter(this.newServicesArray, function (service) {
                                return selected_service_ids.indexOf(service.id) != -1;
                            });

                            this.personal = details.personal;
                            this.newTotalDurationServices = details.total_duration;

                            if (Object.keys(targetService).length) {
                                this.newSelectedServices = targetService;
                                let allServices;
                                let totalCost;
                                this.newSelectedServices.forEach((element) => {
                                    const serviceQuantity = this.selectedServiceQty[element.id];

                                    //making selected services in string
                                    if (allServices) {
                                        allServices = allServices + ", " + element.name;
                                    } else {
                                        allServices = element.name;
                                    }
                                    //make total cost
                                    if (totalCost) {
                                        totalCost = totalCost + element.price * serviceQuantity;
                                    } else {
                                        totalCost = element.price * serviceQuantity;
                                    }
                                });
                                this.newSelectedServicesString = allServices;
                                this.newTotalCostServices = totalCost;

                                //total cost logic ends

                                if (this.newSelectedServices.length) {
                                    this.newShowSelectedServices = true;
                                } else {
                                    this.newShowSelectedServices = false;
                                }

                                if (this.newTotalCostServices > 0) {
                                    this.newShowTotalCost = true;
                                } else {
                                    this.newShowTotalCost = false;
                                }

                                if (this.newTotalDurationServices > 0) {
                                    this.newShowTotalDuration = true;
                                } else {
                                    this.newShowTotalDuration = false;
                                }

                                //enable save button
                                if (this.newSelectedServices.length) {
                                    this.newDisableSaveServices = false;
                                } else {
                                    this.newDisableSaveServices = true;
                                }
                            }

                            //select the date
                            this.day = moment(this.bookData.date).format("ddd");
                            this.date = moment(this.bookData.date).format("l");
                            this.hour = moment(this.bookData.date).format("hh:mm A");

                            this.newOnDateClick({
                                date: this.bookData.date,
                            });

                            this.newManageSaveAppointmentButton();
                        }
                        this.updateSelectedServices();

                        if (sessionStorage.getItem("showCheckout")) {
                            this.showCheckout = true;
                            sessionStorage.removeItem("showCheckout");
                        }
                    }
                }.bind(this)
            );

            if (this.user.role == "client") {
                this.prevent_clients_reschedule = this.selected_event.prevent_clients_reschedule;
            }

            $("#event-modal").modal("show");
        },
        confirmAppointment: function (id, isCalendar = true) {
            let data = {
                id: id,
            };

            this.$http.post(api.actions.confirm_appointment, data).then(function (response) {
                if (!response.data.error) {
                    this.newCurrentStylist = this.newUsers.find((user) => user.user.id == this.newCurrentStylistId);
                    this.numberOfDataFetched = 0;
                    this.totalNumberOfDataToFetch = 2;
                    if (isCalendar) {
                        this.newGetDisabledTimes(this.newCurrentStylist);
                    }
                    this.newGetAppointments(this.newCurrentStylist);
                    this.resetData();

                    $("#event-modal").modal("hide");
                    $("#event-by-status-modal").modal("hide");
                    Vue.swal(
                        this.$ml.get("adminDashboard.details.titleConfirmed"),
                        this.$ml.get("adminDashboard.details.msgConfirmed"),
                        "success"
                    );
                }
            });
        },
        cancelAppointment: function (allRecurring = false, noShow = false) {
            let data = {
                id: this.cancelAppointmentInProgressId,
            };

            if (!this.cancelAppointmentInProgressId) {
                return;
            }

            if (noShow) {
                data.cancel_details = "no_show";
            }

            if (allRecurring) {
                data.cancel_recurring = 1;
            }

            if (noShow) {
                data.cancel_details = "no_show";
            }

            this.$http.post(api.actions.cancel_appointment, data).then(function (response) {
                if (!response.data.error) {
                    this.cancelAppointmentInProgressId = 0;
                    this.newCurrentStylist = this.newUsers.find((user) => user.user.id == this.newCurrentStylistId);
                    this.numberOfDataFetched = 0;
                    this.totalNumberOfDataToFetch = 2;
                    this.newGetDisabledTimes(this.newCurrentStylist);
                    this.newGetAppointments(this.newCurrentStylist);
                    this.resetData();

                    $("#event-modal").modal("hide");
                    $("#cancel-appt-modal").modal("hide");
                    Vue.swal(
                        this.$ml.get("adminDashboard.details.titleCanceled"),
                        this.$ml.get("adminDashboard.details.msgCanceled"),
                        "success"
                    );
                }
            });
        },

        onCancelAppointment: function (id) {
            this.cancelAppointmentInProgressId = id;

            this.$http.get(api.actions.get_appointment_details + id + "?webRequest=1").then(function (response) {
                if (response.data.details.personal == 1) {
                    this.cancelAppointment();
                    return;
                }

                let html = this.getButtons();

                let cancelRecurringHtml = "";

                if (auth.user.role == "client" && this.appointment_details.details.cancel_fee) {
                    cancelRecurringHtml +=
                        '<div class="mt-3">You will be charged with ' +
                        this.appointment_details.details.cancel_fee +
                        " " +
                        this.appointment_details.details.currency +
                        ".</div>";
                }
                if (
                    typeof response.data != "undefined" &&
                    typeof response.data.details != "undefined" &&
                    typeof response.data.details.recurring != "undefined" &&
                    response.data.details.recurring
                ) {
                    //cancelRecurringHtml = this.addCancelRecurringButton();
                }
                $("#event-modal").modal("hide");

                if (this.user.role == "stylist") {
                    $("#cancel-appt-modal").modal("show");
                    return;
                }

                let customClassApp = "rms-app";
                document.querySelector("body").classList.add("rms-app");

                if (this.generalOptions.app_id === 2) {
                    customClassApp = "rmb-app";
                    document.querySelector("body").classList.add("rmb-app");
                } else if (this.generalOptions.app_id === 5) {
                    customClassApp = "rml-app";
                    document.querySelector("body").classList.add("rml-app");
                }

                Vue.swal({
                    title: this.$ml.get("adminDashboard.details.cancelAppointment"),
                    customClass: customClassApp,
                    showConfirmButton: false,
                    showCancelButton: false,
                    confirmButtonText: this.$ml.get("adminDashboard.details.cancelAppointmentYes"),
                    cancelButtonText: this.$ml.get("adminDashboard.details.cancelAppointmentNo"),
                    html: html.append(cancelRecurringHtml),
                }).then((result) => {
                    if (result.value) {
                        this.cancelAppointment();
                        return;
                    }
                    this.cancelAppointmentInProgressId = 0;
                });
            });
        },
        getButtons: function () {
            var buttons = $("<div>").append(
                $(
                    '<div class="mb-3">' +
                        this.$ml.get("adminDashboard.details.cancelAppointmentText") +
                        "</button></div>"
                )
            );

            if (auth.user.role != "client") {
                $(buttons).append(
                    $(
                        '<div class="border-bottom border-top"><button id="noShowAppointment" type="button" class="primary-btn extra-small">' +
                            this.$ml.get("adminDashboard.details.cancelAppointmentNoShow") +
                            "</button></div>"
                    )
                );
            }

            $(buttons).append(
                $(
                    ' <div class="cancel-appt-btns-wrapper"><div class=""><button id="cancelAppointment" type="button" class="primary-btn">' +
                        this.$ml.get("adminDashboard.details.cancelAppointmentYes") +
                        "</button></div>" +
                        '<div class=""><button id="dontCancelAppointment" type="button" class="secondary-btn">' +
                        this.$ml.get("adminDashboard.details.cancelAppointmentNo") +
                        "</button></div> </div>"
                )
            );

            return buttons;
        },
        addCancelRecurringButton: function () {
            this.cancelRecurringButton = $("<div>").append(
                this.createCancelRecurringButton(this.$ml.get("adminDashboard.details.cancelRecurring"))
            );
            return this.cancelRecurringButton;
        },

        createCancelRecurringButton(text) {
            return $('<button id="cancelRecurring" type="button" class="primary-btn">' + text + "</button>");
        },

        onRescheduleAppointment: function (id) {
            this.newRescheduleInProgress = true;
            this.$http.get(api.actions.get_appointment_details + id + "?webRequest=1").then(
                function (response) {
                    if (response.data) {
                        let details = response.data.details;
                        let selected_services = _.filter(response.data.services, {
                            selected: 1,
                        });

                        let selected_service_ids = _.map(selected_services, (el) => {
                            return el.service_id;
                        });
                        let selected_service_names = _.map(selected_services, (el) => {
                            return el.service_name;
                        });

                        selected_services.map((service) => {
                            this.selectedServiceQty[service.service_id] = service.service_quantity;
                        });

                        this.bookData = {
                            id: details.id,
                            stylist_id: details.user_id,
                            client_id: details.client_id,
                            services: selected_service_ids,
                            selected_service_qty: this.selectedServiceQty,
                            date: moment(details.date).toDate(),
                            original_date: moment(details.date).toDate(),
                            special_event: details.special_event,
                            notes: details.notes,
                            reminders: details.reminders.split(","),
                        };

                        this.bookData.reschedule = true;

                        this.notes = details.notes;

                        //manage recurring
                        if (typeof details.recurring !== "undefined" && details.recurring) {
                            this.recurring = details.recurring;
                        }
                        if (this.recurring && typeof details.recurring_details !== "undefined") {
                            if (typeof details.recurring_details.repeat_every !== "undefined") {
                                this.repeatEvery = details.recurring_details.repeat_every;
                            }
                            if (typeof details.recurring_details.repeat_period !== "undefined") {
                                this.repeatPeriod = details.recurring_details.repeat_period;
                            }
                            if (typeof details.recurring_details.repeat_times !== "undefined") {
                                this.repeatTimes = details.recurring_details.repeat_times;
                            }
                        }

                        this.previewData = {
                            stylistName: "",
                            services: selected_service_names,
                        };

                        if (details.user_id == auth.user.id) {
                            this.selectedStylist = auth.user;
                        } else {
                            let stylist = _.filter(this.newUsers, {
                                user: { id: details.user_id },
                            });

                            if (stylist.length > 0) {
                                this.selectedStylist = stylist[0].user;
                            }
                        }

                        let client = _.filter(this.newClients, {
                            id: details.client_id,
                        });

                        if (client.length > 0) {
                            this.selectedClient = client[0];
                        }

                        $("#event-modal").modal("hide");

                        if (auth.user.role == "stylist") {
                            this.currentSection = 2;

                            this.newCurrentStylistId = this.selectedStylist.id;
                            this.newCurrentStylist = this.newUsers.find(
                                (user) => user.user.id == this.newCurrentStylistId
                            );
                            //select the client
                            if (
                                typeof details.client !== "undefined" &&
                                typeof details.client.first_name !== "undefined" &&
                                typeof details.client.last_name !== "undefined" &&
                                typeof details.client.id !== "undefined"
                            ) {
                                this.newSelectedModalClient = {
                                    first_name: details.client.first_name,
                                    last_name: details.client.last_name,
                                    id: details.client.id,
                                };
                            } else {
                                this.newSelectedModalClient = _.find(this.newPagedClients, function (client) {
                                    return client.id == details.client_id;
                                });
                            }

                            if (this.newSelectedModalClient) {
                                this.newIsClientSelected = true;
                            }

                            //select the services
                            this.newTotalCostServices = 0;
                            this.newSelectedServices = [];
                            // this.newGetServices(this.selectedStylist.id);
                            this.newMakeListServices();

                            let targetService = _.filter(this.newServicesArray, function (service) {
                                return selected_service_ids.indexOf(service.id) != -1;
                            });

                            if (Object.keys(targetService).length) {
                                this.newSelectedServices = targetService;
                                let allServices;
                                let totalCost;
                                this.newSelectedServices.forEach((element) => {
                                    const serviceQuantity = this.selectedServiceQty[element.id];

                                    //making selected services in string
                                    if (allServices) {
                                        allServices = allServices + ", " + element.name;
                                    } else {
                                        allServices = element.name;
                                    }
                                    //make total cost
                                    if (totalCost) {
                                        totalCost = totalCost + element.price * serviceQuantity;
                                    } else {
                                        totalCost = element.price * serviceQuantity;
                                    }
                                });
                                this.newSelectedServicesString = allServices;
                                this.newTotalCostServices = totalCost;

                                //total cost logic ends
                                this.newTotalDurationServices = details.total_duration;

                                if (this.newSelectedServices.length) {
                                    this.newShowSelectedServices = true;
                                } else {
                                    this.newShowSelectedServices = false;
                                }

                                if (this.newTotalCostServices > 0) {
                                    this.newShowTotalCost = true;
                                } else {
                                    this.newShowTotalCost = false;
                                }

                                if (this.newTotalDurationServices > 0) {
                                    this.newShowTotalDuration = true;
                                } else {
                                    this.newShowTotalDuration = false;
                                }

                                //enable save button
                                if (this.newSelectedServices.length) {
                                    this.newDisableSaveServices = false;
                                } else {
                                    this.newDisableSaveServices = true;
                                }
                            }

                            //select the date
                            this.day = moment(this.bookData.date).format("ddd");
                            this.date = moment(this.bookData.date).format("l");
                            this.hour = moment(this.bookData.date).format("hh:mm A");

                            //show the modal
                            $("#date-modal").modal("show");

                            this.newOnDateClick({
                                date: this.bookData.date,
                            });

                            this.newManageSaveAppointmentButton();
                        } else {
                            this.currentSection = 1;
                            this.$router.push("/app/book/" + details.user_id);
                        }
                    }
                }.bind(this)
            );
        },
        changeRepeatPeriod: function (period) {
            this.repeatPeriod = period;
        },
        getMemberColor: function (member, index) {
            if (typeof member.color == "undefined" || member.color == null || !member.color) {
                let color = general.colors[index];
                return "#" + color;
            }
            return member.color;
        },
        eventResize: function (eventDropInfo) {
            this.confirmRescheduleEvent(eventDropInfo);
        },
        eventDrop: function (eventDropInfo) {
            this.confirmRescheduleEvent(eventDropInfo);
        },
        resourceRender: function (resourceObj) {
            if (
                typeof resourceObj == "undefined" ||
                typeof resourceObj.resource == "undefined" ||
                typeof resourceObj.resource.id == "undefined" ||
                typeof this.memberColors[resourceObj.resource.id] == "undefined"
            ) {
                return;
            }
            let memberColor = this.memberColors[resourceObj.resource.id];
            $(resourceObj.el).css({
                outline: "1px solid " + memberColor,
                color: memberColor,
            });
        },
        confirmRescheduleEvent: function (eventDropInfo) {
            let userId = eventDropInfo.event.extendedProps.user_id;
            let calendarEvent = eventDropInfo.event;
            this.selected_event = {};
            this.selected_event = _.find(this.newAppointments[userId], {
                id: parseInt(calendarEvent.id),
            });

            if (eventDropInfo.event.extendedProps.prevent_clients_reschedule == 1 && this.user.role == "client") {
                messages.errorMessage(this.$ml.get("adminDashboard.preventRescheduleMessage"));
                eventDropInfo.revert();
                return;
            }

            let customClassApp = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClassApp = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClassApp = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }

            this.$swal({
                title: this.$ml.get("adminDashboard.details.modifyMyAppointment"),
                type: "warning",
                customClass: customClassApp,
                showCancelButton: true,
                confirmButtonText: this.$ml.get("adminDashboard.details.cofirmModifyApptYes"),
                cancelButtonText: this.$ml.get("adminDashboard.details.cofirmModifyApptNo"),
            }).then((result) => {
                if (result.value) {
                    //for the data for save appointment
                    /*
                    let userId = eventDropInfo.event.extendedProps.user_id;
                    let calendarEvent = eventDropInfo.event;

                    this.selected_event = {};
                    this.selected_event = _.find(this.newAppointments[userId], {id: parseInt(calendarEvent.id)});
                    */

                    this.newCurrentStylistId = userId;
                    this.newCurrentStylist = this.newUsers.find((user) => user.user.id == this.newCurrentStylistId);

                    this.bookData.date = eventDropInfo.event.start;
                    this.hour = moment(this.bookData.date).format("hh:mm A");

                    if (this.selected_event.notes) {
                        this.notes = this.selected_event.notes;
                    }

                    this.newTotalDurationServices = moment
                        .duration(moment(eventDropInfo.event.end).diff(moment(eventDropInfo.event.start)))
                        .asMinutes();

                    this.$http.get(api.actions.get_appointment_details + this.selected_event.id + "?webRequest=1").then(
                        function (response) {
                            if (response.data) {
                                let details = response.data.details;
                                let selected_services = _.filter(response.data.services, {
                                    selected: 1,
                                });
                                let selected_service_ids = _.map(selected_services, (el) => {
                                    return el.service_id;
                                });
                                this.newTotalCostServices = 0;
                                selected_services.forEach((el) => {
                                    this.newTotalCostServices += el.service_price;
                                });

                                this.bookData.id = details.id;
                                this.bookData.stylist_id = details.user_id;
                                this.bookData.client_id = details.client_id;
                                this.bookData.services = selected_service_ids;
                                this.bookData.special_event = details.special_event;
                                this.bookData.notes = details.notes;
                                this.bookData.reminders = details.reminders.split(",");
                                this.bookData.personal = details.personal;
                                this.bookData.original_date = details.date;

                                //manage recurring
                                if (typeof details.recurring !== "undefined" && details.recurring) {
                                    this.recurring = details.recurring;
                                }
                                if (this.recurring && typeof details.recurring_details !== "undefined") {
                                    if (typeof details.recurring_details.repeat_every !== "undefined") {
                                        this.repeatEvery = details.recurring_details.repeat_every;
                                    }
                                    if (typeof details.recurring_details.repeat_period !== "undefined") {
                                        this.repeatPeriod = details.recurring_details.repeat_period;
                                    }
                                    if (typeof details.recurring_details.repeat_times !== "undefined") {
                                        this.repeatTimes = details.recurring_details.repeat_times;
                                    }
                                }

                                if (details.user_id == auth.user.id) {
                                    this.selectedStylist = auth.user;
                                } else {
                                    let stylist = _.filter(this.newUsers, {
                                        user: { id: details.user_id },
                                    });

                                    if (stylist.length > 0) {
                                        this.selectedStylist = stylist[0].user;
                                    }
                                }

                                let client = _.filter(this.newClients, {
                                    id: details.client_id,
                                });

                                if (client.length > 0) {
                                    this.selectedClient = client[0];
                                }

                                //select the client
                                if (
                                    typeof details.client !== "undefined" &&
                                    typeof details.client.first_name !== "undefined" &&
                                    typeof details.client.last_name !== "undefined" &&
                                    typeof details.client.id !== "undefined"
                                ) {
                                    this.newSelectedModalClient = {
                                        first_name: details.client.first_name,
                                        last_name: details.client.last_name,
                                        id: details.client.id,
                                    };
                                } else {
                                    this.newSelectedModalClient = _.find(this.newPagedClients, function (client) {
                                        return client.id == details.client_id;
                                    });
                                }

                                if (this.user.role == "client" && !this.newSelectedModalClient) {
                                    this.newSelectedModalClient = {
                                        first_name: this.user.first_name,
                                        last_name: this.user.last_name,
                                        id: this.user.id,
                                    };
                                    this.newCurrentStylistId = details.user_id;
                                }

                                if (this.newSelectedModalClient) {
                                    this.newIsClientSelected = true;
                                }

                                //select the services
                                this.newSelectedServices = [];
                                // this.newGetServices(this.selectedStylist.id);
                                this.newMakeListServices();

                                let targetService = _.filter(this.newServicesArray, function (service) {
                                    return selected_service_ids.indexOf(service.id) != -1;
                                });

                                if (Object.keys(targetService).length) {
                                    this.newSelectedServices = targetService;
                                }

                                if (this.user.role == "client" && this.newSelectedServices.length == 0) {
                                    this.newSelectedServices = selected_services;
                                    this.newSelectedServices.forEach((srv, k) => {
                                        this.newSelectedServices[k].id = srv.service_id;
                                    });
                                }

                                //select the date
                                this.day = moment(this.bookData.date).format("ddd");
                                this.date = moment(this.bookData.date).format("l");

                                let policy = response.data.policy;

                                //Check the reschedule policy and display the confirmation modal
                                if (
                                    this.user.role == "client" &&
                                    details.id != 0 &&
                                    policy.reschedule == 1 &&
                                    policy.reschedule_fee > 0
                                ) {
                                    let appt_date_time = moment(details.date).unix();
                                    let current_date_time = moment().unix();
                                    let hours_diff = (appt_date_time - current_date_time) / 3600;

                                    if (
                                        hours_diff <= policy.reschedule_advance_hours &&
                                        this.newTotalCostServices > 0
                                    ) {
                                        this.$swal({
                                            title: "Reschedule Fee Required",
                                            type: "warning",
                                            showCancelButton: true,
                                            confirmButtonText: this.$ml.get("adminDashboard.details.cofirmYesGeneral"),
                                            cancelButtonText: this.$ml.get("adminDashboard.details.cofirmNoGeneral"),
                                        }).then((result) => {
                                            if (result.value) {
                                                this.newSaveAppointment();
                                            } else {
                                                //revert the changes
                                                eventDropInfo.revert();
                                            }
                                        });
                                        return;
                                    }
                                }

                                this.newSaveAppointment();
                            }
                        }.bind(this)
                    );
                } else {
                    //revert the changes
                    eventDropInfo.revert();
                }
            });
        },
        eventRender: function (info) {
            if (typeof info.event.extendedProps.recurring != "undefined" && info.event.extendedProps.recurring == 1) {
                $(info.el).append('<i class="static-recurring-sign fa fa-refresh"></i>');
            }
            this.setDateLabelPos();

            if (this.eventRendering) {
                clearTimeout(this.eventRenderTimeout);
                this.eventRenderTimeout = setTimeout(() => {
                    this.getAppointmentsByStatus();
                    this.eventRendering = false;
                }, 500);
                return;
            }

            this.eventRendering = true;
        },
        showAllAppts: function () {
            this.showStylistSpecificAppointments = false;
            this.filterMemberDisplayed(0);
            this.showAllApptsLogic();
        },
        showStylistSpecificAppts: function () {
            this.showStylistSpecificAppointments = true;
            this.filterMemberDisplayed(this.newCurrentStylistId);
            this.showStylistSpecificApptsLogic();
        },
        showAllApptsLogic: function () {
            this.numberOfDataFetched = 0;
            this.totalNumberOfDataToFetch = 2 * this.newUsers.length;
            this.newUsers.forEach((user) => {
                this.newGetDisabledTimes(user);
                this.newGetAppointments(user);
            });
        },
        showStylistSpecificApptsLogic: function () {
            this.numberOfDataFetched = 0;
            this.totalNumberOfDataToFetch = 2;
            this.newGetDisabledTimes(this.newCurrentStylist);
            this.newGetAppointments(this.newCurrentStylist);
        },
        displayAddClientForm: function () {
            if (this.adminDetails.group_active && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            }
            this.displayNewClientForm = true;
        },
        getGroupsMembers: function (group_id) {
            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = _.filter(response.data.members, (el) => {
                            return el.active == 1;
                        });
                        this.editClientStylists.push(this.user.id);
                        this.editClientStylists.push(this.newCurrentStylist.user.id);
                    }
                }.bind(this)
            );
        },
        resetClientObj: function () {
            this.editClientObj = {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
            };
            this.editClientStylists = [];
            this.displayNewClientForm = false;
        },
        saveClientLink: function (client_id) {
            let data = {
                client_id: client_id,
                stylists: {},
            };

            this.groupMembers.forEach((el) => {
                data.stylists[el.user.id] = {
                    active: this.editClientStylists.indexOf(el.user.id) !== -1,
                };
            });

            this.$http.post(api.actions.save_group_stylists_client_link, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.showClientsEditForm = false;
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.resetClientObj();
                        this.newPage[this.newCurrentStylist["user"]["id"]] = 1;
                        this.newGetClients(this.newCurrentStylist);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        checkExistingTeamMemberUser: function () {
            var data = {
                phone: this.editClientObj.phone,
                email: this.editClientObj.email,
            };

            return this.$http.post(api.actions.verify_existing_user, data);
        },
        sendInvite: function () {
            let data = {
                phone: this.invitePhoneNumber,
                group_id: this.adminDetails.group_id,
                email: this.inviteEmail,
                app_id: this.generalOptions.app_id,
            };

            this.$http.post(api.actions.send_invite, data).then(
                function (response) {
                    if (response.data.error != true) {
                        this.displayNewClientForm = false;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveClient: function () {
            if (
                this.adminDetails.group_active &&
                this.adminDetails.group_owner &&
                this.editClientStylists.length == 0
            ) {
                messages.errorMessage(messages.ERROR_MESSAGES.ADD_CLIENT_STYLISTS_ERROR);
                return;
            }

            let first_name = this.editClientObj.first_name;
            let last_name = this.editClientObj.last_name;

            let data = {
                first_name: first_name,
                last_name: last_name,
                phone: this.editClientObj.phone,
                email: this.editClientObj.email,
            };

            this.checkExistingTeamMemberUser().then((response) => {
                if (response.data.exists == true) {
                    this.inviteEmail = this.editClientObj.email;
                    this.invitePhoneNumber = this.editClientObj.phone;
                    this.sendInvite();
                    this.newSelectedClientF(response.data.user);
                    this.resetClientObj();
                    this.newPage[this.newCurrentStylist["user"]["id"]] = 1;
                    this.newGetClients(this.newCurrentStylist);
                    return;
                } else {
                    this.$http.post(api.actions.add_client_user, data).then(
                        function (response) {
                            if (response.data.error == false) {
                                this.newSelectedClientF(response.data.user);

                                if (this.adminDetails.group_active && this.adminDetails.group_owner) {
                                    this.saveClientLink(response.data.user.id);
                                    return;
                                }

                                this.displayNewClientForm = false;
                                messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);

                                this.resetClientObj();
                                this.newPage[this.newCurrentStylist["user"]["id"]] = 1;
                                this.newGetClients(this.newCurrentStylist);
                            }
                        }.bind(this)
                    );
                }
            });
        },
        displayCancelButton(event) {
            var date = moment(event.date, "YYYY-MM-DD hh:mm A");
            var isFutureEvent = date.isAfter(moment().toDate());

            return isFutureEvent || auth.user.role == "stylist";
        },
        setDateLabelPos() {
            var toolbarWidth = $(".fc-toolbar.fc-header-toolbar .fc-center").width();
            var toolbarCenterWidth = $(".fc-toolbar.fc-header-toolbar").width();
            this.dayBeforeDateRight = ((toolbarCenterWidth / 2 + toolbarWidth / 2 + 15) / toolbarCenterWidth) * 100;
        },
        onClientWidgetServiceSelected() {
            this.getClientWidgetSettings();
        },
        onClientWidgetSlotSlick(date, hour) {
            if (this.loading) {
                return false;
            }

            let policy = this.clientWidgetSettings.stylist_policy;
            let deposit_fee = 0;

            if (policy && policy.deposit != 0 && policy.deposit_fee != 0) {
                if (policy.deposit_type == "fixed") {
                    deposit_fee = policy.deposit_fee;
                } else {
                    deposit_fee = (this.clientWidgetServicePrice * (policy.deposit_fee / 100)).toFixed(2);
                }
            }

            let formattedDate = moment(date + " " + hour, "YYYY-MM-DD hh:mm A").format("LLL");
            let message = '<div style="font-size:17px; color: black;">';
            message +=
                "Are you sure you want to book an appointment with " +
                this.clientWidgetSettings.stylist_name +
                " on " +
                formattedDate +
                "? ";

            if (deposit_fee) {
                message += "<br>Deposit Fee: <b>" + deposit_fee + this.clientWidgetSettings.stylist_currency + "</b>";
            }
            message += "</div>";

            let customClassApp = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClassApp = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClassApp = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }
            this.$swal({
                title: message,
                showCancelButton: true,
                customClass: customClassApp,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    let formattedDate = moment(date).format("YYYY-MM-DD");
                    let data = {
                        user_id: this.clientWidgetSettings.stylist_id,
                        client_id: this.user.id,
                        date_time: formattedDate,
                        hour: hour,
                        status: "unconfirmed",
                        total_duration: this.clientWidgetServiceDuration,
                        services: [this.clientWidgetSelectedService],
                        new_services: this.getServicesQty(),
                        notes: "",
                        special_event: "",
                        reminders: "",
                    };
                    this.loading = true;

                    if (deposit_fee) {
                        data.deposit_fee = deposit_fee;
                    }

                    this.$http.post(api.actions.save_appointment, data).then(
                        function (response) {
                            if (response.data.error == false) {
                                this.loading = false;
                                messages.successMessage(messages.GLOBAL_MESSAGES.APPOINTMENT_SCHEDULED);
                                this.getClientWidgetSettings();
                                this.newOnStylistChange();
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                }
            });
        },
        getClientWidgetSettings() {
            let data = {
                client_id: this.user.id,
                service_id: this.clientWidgetSelectedService,
                days: 14,
            };

            if (this.clientWidgetSettings && this.clientWidgetSettings.stylist_id) {
                data.stylist_id = this.clientWidgetSettings.stylist_id;
            }

            this.$http.post(api.actions.get_next_days_available_slots, data, { noError: 1 }).then(
                function (res) {
                    if (res.data.error == false) {
                        if (!this.clientWidgetSelectedService) {
                            this.clientWidgetRegularService = res.data.service_id;
                        }

                        this.clientWidgetSettings.stylist_id = res.data.stylist_id;
                        this.clientWidgetSettings.stylist_name = res.data.stylist_name;
                        this.clientWidgetSettings.stylist_avatar = res.data.stylist_avatar;
                        this.clientWidgetSelectedService = res.data.service_id;
                        this.clientWidgetSettings.stylist_policy = res.data.profile.policy;
                        this.clientWidgetSettings.stylist_currency = res.data.profile.currency
                            ? res.data.profile.currency
                            : "USD";
                        this.clientWidgetDays = [];

                        for (let day in res.data.next_days_slots) {
                            if (
                                res.data.next_days_slots[day].length > 0 &&
                                this.clientWidgetDays.length === 0 &&
                                this.clientWidgetDays.length < 3
                            ) {
                                this.clientWidgetDays.push({
                                    date: moment(day).format("MMM Do"),
                                    dateUnformatted: day,
                                    slots: res.data.next_days_slots[day],
                                });
                            }
                        }

                        //get the stylist services
                        this.$http.get(api.actions.get_stylist_services + "?stylist_id=" + res.data.stylist_id).then(
                            function (response) {
                                if (typeof response.data.services != "undefined") {
                                    let services = _.filter(response.data.services, (srv) => {
                                        return srv.active == 1;
                                    });
                                    this.clientWidgetServices = [];
                                    services.forEach((el) => {
                                        let serviceName =
                                            this.clientWidgetRegularService == el.id ? el.name + " (regular)" : el.name;
                                        this.clientWidgetServices.push({
                                            label: serviceName,
                                            value: el.id,
                                        });

                                        if (this.clientWidgetSelectedService == el.id) {
                                            this.clientWidgetServiceDuration = el.duration;
                                            this.clientWidgetServicePrice = el.price;
                                        }
                                    });
                                }
                            }.bind(this)
                        );
                    } else {
                        this.$router.push("/app/stylists");
                    }
                }.bind(this)
            );
        },
        saveService: function (service) {
            let data = {
                duration: service.duration,
                service_price: service.price,
                service_id: service.id,
                service_quantity: service.qty,
                appointment_id: this.selected_event.id,
            };
            let index = this.selectedServicesArray.findIndex((x) => x.service_id === service.id);

            this.$http.post(api.actions.save_appointment_service, data).then(
                function () {
                    this.addServiceModal = false;
                    this.selectedServicesArray[index].duration = service.duration;
                    this.selectedServicesArray[index].price = service.price;
                    this.selectedServicesArray[index].service_price = service.price;
                    this.selectedServicesArray[index].service_quantity = parseInt(service.qty);

                    this.updateSelectedServices();
                }.bind(this)
            );
        },
        addServiceApp: function () {
            this.newServicesArray.forEach((service, index) => {
                let selected = this.selected_event.services_full.find((x) => x.service_id === service.id);

                this.newServicesArray[index].selected = selected ? true : false;
                this.$forceUpdate();
            });

            this.addServiceModal = true;
        },
        saveServices: function () {
            this.updateSelectedServices();

            this.editServices = false;
            this.addServiceModal = false;
            this.calculateServiceTotals();
        },
        updateSelectedServices: function () {
            this.newSelectedServices = [];
            this.newTotalDurationServices = 0;
            let self = this;

            this.selectedServicesArray.forEach((service) => {
                let image = "";
                let description = "";
                let user_id = "";

                self.appointment_details.category_services.forEach(function (cat) {
                    cat.services.forEach(function (serv) {
                        if (service.service_id == serv.id) {
                            image = serv.image;
                            description = serv.description;
                            user_id = serv.user_id;
                        }
                    });
                });

                let data = {
                    categoryName: "",
                    duration: service.duration,
                    featured: 1,
                    id: service.service_id,
                    name: service.service_name,
                    price: service.service_price,
                    price_and_up: 0,
                    is_product: service.is_product,
                    qty: service.service_quantity,
                    default_duration: service.default_duration,
                    default_price: service.default_price,
                    selected: true,
                    image: image,
                    description: description,
                    user_id: service.user_id,
                };
                if (service.selected) {
                    this.newTotalDurationServices =
                        parseInt(this.newTotalDurationServices) + parseInt(service.duration);
                    this.newSelectedServices.push(data);
                }
                // Changed the service.id part with service.service_id to make things work ...
                this.selectedServiceQty[service.service_id] = service.service_quantity;
            });
        },

        getProductImage(image_url, user_id = false) {
            if (!image_url) {
                return "";
            }
            return (
                api.API_ENDPOINT + "/uploads/images/services/" + (!user_id ? this.user.id : user_id) + "/" + image_url
            );
        },
        getUserSupportMessages: function () {
            this.$http.get(api.actions.get_user_support_messages + "?ordering=desc").then(
                function (response) {
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.supportMessages != "undefined" &&
                        response.body.supportMessages.length > 0
                    ) {
                        this.supportMessages = response.body.supportMessages;
                    }
                }.bind(this),

                function () {
                    return false;
                }
            );
        },
        getSupportMessagesStatus: function () {
            this.$http.get(api.actions.get_unread_support_messages_count).then(
                function (response) {
                    this.localNotificationCount = response.data.count;
                }.bind(this)
            );
        },
        searchAppointment() {
            if (this.searchQuery.length < 3) {
                return;
            }

            let user_ids = [];
            this.newUsers.forEach((user) => {
                user_ids.push(user.user.id);
            });

            this.loading = true;
            this.$http
                .post(api.actions.search_appointments, {
                    search_string: this.searchQuery,
                    user_ids: user_ids,
                })
                .then(
                    function (response) {
                        this.filteredAppointments = response.data.filteredAppointments;
                        if (!response.data.filteredAppointments.length) {
                            this.searchError = "There are no search results for this query.";
                        }
                        this.loading = false;
                    }.bind(this),
                    function () {
                        this.loading = false;
                        return false;
                    }
                );
        },
        addTimeToDate(date, timeInterval, intervalType) {
            return moment(date).add(timeInterval, intervalType).toDate();
        },
        processServicesDisplay(services) {
            if (!services) {
                return "";
            }

            return services.replace(/;/g, ", ");
        },
        onBook: function () {
            this.$router.push("/app/stylists");
        },
        bookAgain: function (stylistId) {
            this.bookData.id = 0;
            this.bookData.book_again = true;
            this.bookData.selected_service_qty = this.selectedServiceQty;

            $("#event-modal").modal("hide");
            this.$router.push("/app/book/" + stylistId);
        },
        saveCalendarView(view) {
            let allowed_views = [1, 3, 7, 30];

            if (allowed_views.indexOf(view) == -1) {
                return;
            }

            let data = {
                calendar_view: view,
            };

            this.$http.post(api.actions.save_calendar_view, data);
        },
        getCalendarView() {
            this.$http.get(api.actions.get_calendar_view).then((res) => {
                let allowed_views = {
                    1: "resourceTimeGridDay",
                    3: "timeGrid",
                    7: "timeGridWeek",
                    30: "dayGridMonth",
                };

                if (!allowed_views[res.data.calendar_view]) {
                    return;
                }

                this.changeCurrentView(allowed_views[res.data.calendar_view]);
            });
        },

        onModalClose() {
            this.appointment_details = {};

            $("#event-modal").modal("hide");
        },
        viewAppointmentDetails(appt) {
            this.newOnEventClick({
                event: {
                    id: appt.id,
                },
                extendedProps: {
                    user_id: this.user.id,
                },
            });
        },

        isPastEvent(selectEvent) {
            let selectedEventDate = moment(selectEvent).unix();
            let currentDate = moment().unix();

            if (selectedEventDate < currentDate) {
                return true;
            }

            return false;
        },
        formatEventDate(eventDate) {
            return moment(eventDate).format("MMMM D[,] YYYY [at] h:mm A");
        },

        openDateModalWithClientSet() {
            const currentDate = moment().format("YYYY-MM-DD");
            const currentHour = moment().hours();
            const formattedHour = moment().hours(currentHour).format("hh:mm A");

            const openClientId = parseInt(sessionStorage.getItem("bookClientId"));
            const openClientFirstName = sessionStorage.getItem("bookClientFirstName");
            const openClientLastName = sessionStorage.getItem("bookClientLastName");

            if (!openClientId) {
                return;
            }

            sessionStorage.removeItem("bookClientId");
            sessionStorage.removeItem("bookClientFirstName");
            sessionStorage.removeItem("bookClientLastName");

            let client = {
                id: openClientId,
                first_name: openClientFirstName,
                last_name: openClientLastName,
            };

            this.changeTime(formattedHour);
            this.onDateClickFromDatePicker(currentDate);
            this.newSelectedClientF(client);
            $("#date-modal").modal("show");
        },
    },

    filters: {
        getBookedType: function (value, type) {
            if (!value) {
                return;
            }

            if (value == "client") {
                return value;
            }

            let val = "";

            switch (type) {
                case 1:
                    val = "stylist";
                    break;
                case 2:
                    val = "barber";
                    break;
                case 3:
                    val = "groomer";
                    break;
                case 5:
                    val = "loctician";
                    break;
                default:
                    val = value;
            }

            return val;
        },
    },
    components: {
        FullCalendar, // make the <FullCalendar> tag available
        Datepicker,
        vueSignature,
        CompleteCheckoutModal,
    },
};
</script>
