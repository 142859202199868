export default {
    colors: [
        '800000','9A6324','808000','469990','000075','000000','e6194B','f58231','ffe119','bfef45','3cb44b','42d4f4,4363d8','911eb4','f032e6','a9a9a9'
    ],
    methods: {
        getProfileLikes() {
            let profileLikes = this.getCookie('profile_likes');

            if(profileLikes){
                profileLikes = profileLikes.split(',').map((id) => { return (id) ? parseInt(id) : 0 });
            } else {
                profileLikes = [];
            }

            return profileLikes;
        },
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');

            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
    },
}