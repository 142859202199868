import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'
import EN from './languages/en'
import ES from './languages/es'

Vue.use(MLInstaller)

export default new MLCreate({
    initial: 'english',
    save: true,
    languages: [
        new MLanguage('english').create(EN),
        new MLanguage('spanish').create(ES),
    ]
})