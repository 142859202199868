<template>
    <!--General Settings, Service Hours main container-->
    <div class="admin-schedule">
        <!--Main container for General Settings-->
        <div class="main-container-work-settings">
            <div class="heading-container">
                <!--Title-->
                <div>
                    <h3 v-if="isSectionVisible('general-settings')">{{ $ml.get("schedule.general") }}</h3>
                    <h3 v-if="isSectionVisible('service-hours')">{{ $ml.get("schedule.serviceHours") }}</h3>
                </div>
                <!--Team select-->
                <div
                    v-if="
                        adminDetails.group_owner == 1 &&
                        adminDetails.group_active == 1 &&
                        !isSectionVisible('blockout-unblock')
                    "
                    class="select-input-wrap"
                >
                    <label
                        class="stylist-label"
                        for="stylist-select"
                        :class="generalOptions.app_id === 5 ? 'loctician' : ''"
                        >Select
                        {{
                            generalOptions.app_id === 1
                                ? "Stylist"
                                : generalOptions.app_id === 2
                                ? "Barber"
                                : generalOptions.app_id === 5
                                ? "Loctician"
                                : ""
                        }}</label
                    >
                    <select
                        id="stylist-select"
                        v-model="for_stylist_id"
                        v-on:change="onStylistChange"
                        class="selectInput selectInputSmall"
                    >
                        <option
                            v-for="(member, index) in groupMembers"
                            v-bind:key="index"
                            v-bind:value="member.user.id"
                            v-bind:disabled="member.active != 1"
                        >
                            {{ member.user.first_name }} {{ member.user.last_name }}
                        </option>
                    </select>
                </div>
            </div>
            <!--General Settings main content-->
            <div class="content-work-settings section-wrap" v-if="isSectionVisible('general-settings')">
                <!--General Settings wrapper-->
                <div v-if="isSectionVisible('general-settings')" class="work-settings-wrap">
                    <!--Row wrapper for Pause between appointments and time slot booking -->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <!--Pause between appointments-->
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.pause") }}</span>
                            <div class="input-wrap">
                                <label class="radio-label">
                                    <input
                                        class="text-field text-field-small"
                                        type="number"
                                        min="0"
                                        value="0"
                                        oninput="validity.valid||(value='');"
                                        v-model="work_settings.pause_between_appointments"
                                    />
                                </label>
                                <span class="">{{ $ml.get("general.min") }}</span>
                            </div>
                        </div>
                        <!--time slot booking-->
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.timeSlot") }}</span>

                            <div class="input-wrap">
                                <label class="radio-label">
                                    <input
                                        class="text-field text-field-small"
                                        v-model.number="timeSlotBooking"
                                        placeholder="Add Time"
                                        type="number"
                                    />
                                </label>
                                <span>{{ $ml.get("general.min") }}</span>
                            </div>
                        </div>
                    </div>
                    <!--BLOCK OUT (vacation days or time off)-->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.blockOut") }}</span>

                            <div class="input-wrap">
                                <div class="link">
                                    <router-link :to="{ path: '/app/block-out' }">{{
                                        $ml.get("schedule.blockOutUrl")
                                    }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Guests allowed to book-->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.guests") }}</span>
                            <div class="input-wrap">
                                <div class="radio-label">
                                    <input
                                        type="number"
                                        min="0"
                                        class="text-field text-field-small"
                                        v-model="work_settings.hours_limit"
                                    />
                                    <span>{{ $ml.get("schedule.hoursAdvance") }}</span>
                                </div>
                                <div>
                                    <label class="switch">
                                        <input type="checkbox" v-model="work_settings.hours_limit_active" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="input-wrap">
                                <div class="radio-label">
                                    <input
                                        type="number"
                                        min="0"
                                        class="text-field text-field-small"
                                        v-model="work_settings.available_future_days"
                                    />
                                    <span>{{ $ml.get("schedule.daysInFuture") }}</span>
                                </div>
                            </div>
                            <div class="input-wrap">
                                <div class="settings-field-note">
                                    <i>{{ $ml.get("schedule.daysInFutureNote") }}</i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Prevent clients from booking-->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.preventClients") }} </span>

                            <div class="input-wrap">
                                <div class="radio-label">
                                    <span>
                                        {{ $ml.get("schedule.preventClientsText") }}
                                        <strong>{{ $ml.get("general.yes") }}.</strong>
                                        <br />
                                        {{ $ml.get("schedule.otherwise") }}
                                        <strong>{{ $ml.get("general.no") }}.</strong>
                                    </span>
                                </div>
                                <select
                                    v-model="work_settings.prevent_clients_book"
                                    class="selectInput selectInputExtraSmall"
                                >
                                    <option :value="true">{{ $ml.get("general.yes") }}</option>
                                    <option :value="false">{{ $ml.get("general.no") }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--Prevent clients to reschedule-->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <div class="row-section">
                            <span class="section-headline-work">
                                {{ $ml.get("schedule.preventClientsReschedule") }}</span
                            >
                            <div class="input-wrap">
                                <div class="radio-label">
                                    <span>
                                        {{ $ml.get("schedule.preventClientsRescheduleText") }}
                                        <strong>{{ $ml.get("general.yes") }}.</strong>
                                        <br />
                                        {{ $ml.get("schedule.otherwise") }}
                                        <strong>{{ $ml.get("general.no") }}.</strong>
                                    </span>
                                </div>
                                <select
                                    v-model="work_settings.prevent_clients_reschedule"
                                    class="selectInput selectInputExtraSmall"
                                >
                                    <option :value="true">{{ $ml.get("general.yes") }}</option>
                                    <option :value="false">{{ $ml.get("general.no") }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--Accept new clients-->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.acceptNewClients") }}</span>
                            <div class="input-wrap">
                                <div class="radio-label">
                                    <span>
                                        {{ $ml.get("schedule.acceptNewClientsText") }}
                                        <strong>{{ $ml.get("general.yes") }}</strong
                                        >.
                                        <br />
                                        {{ $ml.get("schedule.otherwise") }}
                                        <strong>{{ $ml.get("general.no") }}</strong
                                        >, {{ $ml.get("schedule.acceptNewClientsText2") }}.
                                    </span>
                                </div>
                                <select
                                    v-model="work_settings.accept_new_clients"
                                    class="selectInput selectInputExtraSmall"
                                >
                                    <option :value="true">{{ $ml.get("general.yes") }}</option>
                                    <option :value="false">{{ $ml.get("general.no") }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row-section section-wrap" v-if="!work_settings.accept_new_clients">
                            <div class="input-wrap">
                                <div>
                                    {{ $ml.get("schedule.acceptNewClientsOffText1") }}
                                    <strong>{{ $ml.get("schedule.acceptNewClientsOffText2") }}</strong>
                                    {{ $ml.get("schedule.acceptNewClientsOffText3") }}
                                </div>
                            </div>
                            <div class="input-wrap">
                                <div class="textarea-wrap">
                                    <textarea
                                        class="text-field new-clients-message"
                                        :placeholder="$ml.get('schedule.acceptNewClientsOffMessage')"
                                        :maxlength="120"
                                        v-on:keyup="countChar('accept_new_clients_text')"
                                        v-model="work_settings.accept_new_clients_text"
                                    ></textarea>
                                    <div class="small text-secondary align-self-end">
                                        <span>{{ counter.accept_new_clients_text }}/120 char.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-wrap">
                                <div class="link">
                                    <a :href="portfolio_url" target="_blank">
                                        {{ $ml.get("schedule.acceptNewClientsOffLinkText") }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Confirm Appointments-->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.confirmAppt") }}</span>

                            <div class="input-wrap">
                                <div class="radio-label">
                                    <span>
                                        {{ $ml.get("schedule.confirmApptText") }}
                                        <strong>{{ $ml.get("general.yes") }}.</strong>
                                        <br />
                                        {{ $ml.get("schedule.confirmApptTex2") }}
                                        <strong>{{ $ml.get("general.no") }}.</strong>
                                    </span>
                                </div>
                                <select
                                    v-model="settings.auto_confirm_appointments"
                                    class="selectInput selectInputExtraSmall"
                                >
                                    <option :value="true">{{ $ml.get("general.yes") }}</option>
                                    <option :value="false">{{ $ml.get("general.no") }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--NOTIFY CLIENTS-->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.notifyClients") }}</span>

                            <div class="input-wrap">
                                <router-link class="link" :to="{ path: '/app/work-settings/notifications' }">{{
                                    $ml.get("schedule.notificationsUrl")
                                }}</router-link>
                            </div>
                        </div>
                        <span class="section-note">{{ $ml.get("schedule.notifyClientsInviteText") }}</span>
                        <div class="row-section">
                            <div class="input-wrap">
                                <div class="radio-label">
                                    {{ $ml.get("schedule.notifyInvite") }}
                                </div>
                                <select
                                    v-model="notification_settings.invite"
                                    class="selectInput selectInputExtraSmall"
                                >
                                    <option :value="true">{{ $ml.get("general.yes") }}</option>
                                    <option :value="false">{{ $ml.get("general.no") }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--Enable Online Booking from Portfolio page-->
                    <div class="row-section-wrap highlighted-box section-wrap">
                        <div class="row-section">
                            <span class="section-headline-work">{{ $ml.get("schedule.onlineBooking") }}</span>
                            <div class="input-wrap">
                                <div class="radio-label">
                                    {{ $ml.get("schedule.onlineBookingYes") }}
                                </div>
                                <select
                                    v-model="settings.accept_website_booking"
                                    class="selectInput selectInputExtraSmall"
                                >
                                    <option :value="true">{{ $ml.get("general.yes") }}</option>
                                    <option :value="false">{{ $ml.get("general.no") }}</option>
                                </select>
                            </div>
                            <div class="input-wrap">
                                <div class="radio-label">
                                    <div class="link">
                                        <a :href="portfolio_url" target="_blank">
                                            {{ $ml.get("schedule.porfolioPage") }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Save button for General-->
                    <div class="save-work-setting-btn">
                        <div>
                            <button class="primary-btn extra-small" v-on:click="saveWorkSettings">
                                {{ $ml.get("general.save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Service Hours section-->
        <div class="main-container-work-settings add-margin-top" v-if="isSectionVisible('service-hours')">
            <div v-if="isSectionVisible('service-hours')" class="content-work-settings section-wrap">
                <div class="work-settings-wrap">
                    <div class="row-section-wrap section-wrap">
                        <span class="section-headline-work">{{ $ml.get("schedule.noteClients") }}</span>
                        <div class="heading-text-wrap">
                            <label for="notes-for-clients" class="text-for-clients"
                                >{{ $ml.get("schedule.shortNoteClients") }}
                                <a class="main-text-link" target="_blank" :href="portfolio_url">{{
                                    $ml.get("schedule.yourPortfolioPage")
                                }}</a
                                >{{ $ml.get("schedule.underWorkingHoursSection") }}</label
                            >
                            <textarea
                                id="notes-for-clients"
                                maxlength="150"
                                v-model="work_settings.notes_for_clients"
                                class="text-field"
                                placeholder="Write a short note for your clients"
                            ></textarea>
                        </div>
                        <div class="btn-wrapper">
                            <div>
                                <button class="primary-btn extra-small" v-on:click="saveWorkSettings">
                                    {{ $ml.get("general.save") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row-section-wrap section-wrap">
                        <div class="row-section-inner-wrap">
                            <span class="section-headline-work">{{ $ml.get("schedule.workDays") }}</span>
                            <div
                                class="row-section-week highlighted-box section-wrap"
                                v-for="(day, index) in weekDays"
                                v-bind:key="index"
                            >
                                <div class="">
                                    <span class="day-label">{{ $ml.get("days." + day) }}</span>
                                </div>
                                <div class="section-wrap day-wrapper">
                                    <!--Active-->
                                    <div class="switch-wrap">
                                        <label :for="'active-day' + index">{{ $ml.get("general.active") }}:</label>
                                        <div>
                                            <label class="switch">
                                                <input
                                                    :id="'active-day' + index"
                                                    type="checkbox"
                                                    :disabled="loading"
                                                    v-model="work_settings[day]"
                                                    @change="saveWorkSettings"
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <!--Scheduled from - to-->
                                    <div v-if="work_settings[day]" class="day-settings-row">
                                        <div class="hours-wrap">
                                            <div>{{ $ml.get("schedule.from") }}:</div>
                                            <div>
                                                <timepicker
                                                    format="hh:mm A"
                                                    v-if="work_hours[day + '_from']"
                                                    v-model="work_hours[day + '_from']"
                                                    @input="handleTimeSave($event)"
                                                ></timepicker>
                                            </div>
                                        </div>
                                        <div class="hours-wrap">
                                            <div>To:</div>
                                            <div>
                                                <timepicker
                                                    format="hh:mm A"
                                                    v-if="work_hours[day + '_to']"
                                                    v-model="work_hours[day + '_to']"
                                                    @input="handleTimeSave($event)"
                                                ></timepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Blocked from - to-->
                                    <div v-if="work_settings[day]" class="day-settings-row blocked-row">
                                        <div class="hours-wrap">
                                            <div>{{ $ml.get("schedule.blockFrom") }}:</div>
                                            <div>
                                                <timepicker
                                                    format="hh:mm A"
                                                    v-if="work_hours[day + '_block_from']"
                                                    v-model="work_hours[day + '_block_from']"
                                                    @input="handleTimeSave($event)"
                                                ></timepicker>
                                            </div>
                                        </div>
                                        <div class="hours-wrap">
                                            <div>{{ $ml.get("schedule.blockTo") }}:</div>
                                            <div>
                                                <timepicker
                                                    format="hh:mm A"
                                                    v-if="work_hours[day + '_block_to']"
                                                    v-model="work_hours[day + '_block_to']"
                                                    @input="handleTimeSave($event)"
                                                ></timepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Walks-Ins-Only-->
                                    <div class="walk-in-container" v-if="work_settings[day]">
                                        <div class="walk-checkbox">
                                            <label class="switch">
                                                <input
                                                    :id="'walks-ins-checked' + index"
                                                    type="checkbox"
                                                    v-model="work_settings[day + '_walkin']"
                                                    @change="saveWorkSettings"
                                                />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div class="walks-content walk-label">
                                            <label :for="'walks-ins-checked' + index">Walk-Ins Only</label>
                                        </div>

                                        <div class="walks-content walk-info">
                                            <span><i class="fa fa-info-circle" v-tooltip="walksInsInfo"></i></span>
                                        </div>
                                    </div>

                                    <!--Exeptions-->
                                    <div v-if="!work_settings[day]" class="exeptions-row">
                                        <div class="exeptions-btn-wrap">
                                            <div>
                                                <button
                                                    :disabled="work_settings[day] != work_settings_clone[day]"
                                                    v-on:click="openModal(day)"
                                                    type="button"
                                                    class="primary-btn extra-small"
                                                    :class="{
                                                        'disabled-button':
                                                            work_settings[day] != work_settings_clone[day],
                                                    }"
                                                >
                                                    {{ $ml.get("schedule.exceptions") }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="datepicker-main-wrapper">
                                            <div v-if="exceptions[day].openDateModal" class="datepicker-wrapper">
                                                <datepicker
                                                    v-on:selected="onDateClickFromDatePicker($event, day)"
                                                    :value="exceptions[day].date"
                                                    :inline="true"
                                                    :disabledDates="disableDates(day)"
                                                    :highlighted="{ dates: exceptions[day].highlighted }"
                                                >
                                                </datepicker>
                                            </div>
                                        </div>
                                        <div
                                            class="exeptions-day-wrapper section-wrap"
                                            v-if="exceptions[day].dates.length != 0"
                                        >
                                            <div
                                                v-for="(date, key) in exceptions[day].dates"
                                                v-bind:key="key"
                                                class="section-wrap"
                                            >
                                                <div class="exception-day">
                                                    {{ date.dateF }}
                                                    <span @click="delete_exception_day(date.id)" class="button-edit"
                                                        ><i aria-hidden="true" class="fa fa-times"></i
                                                    ></span>
                                                    <i
                                                        @click="openTimeModal(date)"
                                                        aria-hidden="true"
                                                        class="fa fa-clock-o"
                                                    ></i>
                                                </div>
                                                <a class="exeption-link" @click="openTimeModal(date)"
                                                    >{{ date.work_from }} - <br />{{ date.work_to }}</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Change Exeption Hour Modal-->
        <div class="modal" tabindex="-1" role="dialog" id="time-modal">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="title">
                            <h5>{{ $ml.get("schedule.changeHours") }}</h5>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-hour-input-wrap">
                            <div class="timepicker-wrap">
                                <label>From</label>
                                <timepicker
                                    format="hh:mm A"
                                    v-if="timeObj.work_from"
                                    v-model="timeObj.work_from"
                                ></timepicker>
                            </div>
                            <div class="timepicker-wrap">
                                <label>To</label>
                                <timepicker
                                    format="hh:mm A"
                                    v-if="timeObj.work_to"
                                    v-model="timeObj.work_to"
                                ></timepicker>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="btn-save">
                            <div>
                                <button @click="changeTime()" type="button" class="primary-btn extra-small">
                                    {{ $ml.get("schedule.saveChanges") }}
                                </button>
                            </div>
                            <div>
                                <button type="button" class="secondary-btn small" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Block Out / Unblock-->
        <div class="main-container-work-settings" v-if="isSectionVisible('blockout-unblock')">
            <div class="heading-container">
                <!--Title-->
                <div>
                    <h3 v-if="isSectionVisible('blockout-unblock')">{{ $ml.get("adminMenu.blockoutUnblock") }}</h3>
                </div>
            </div>
            <div class="content-work-settings section-wrap blockout-wrap">
                <router-link :to="{ path: '/app/block-out' }">
                    <div class="section-wrap blockout-card">
                        <h5>Block Out</h5>
                        <p>
                            Block certain days you normally work, but you don't want to be booked by clients.
                            <span class="main-text-link">Click here</span>
                        </p>
                    </div>
                </router-link>
                <router-link :to="{ path: '/app/exception-days' }">
                    <div class="section-wrap blockout-card">
                        <h5>Unlock Appt. Times</h5>
                        <p>
                            Choose certain days to unlock-days when you normally don't work.
                            <span class="main-text-link">Click here</span>
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
import TimePicker from "vuejs-timepicker";
import moment from "moment";
import eventBus from "./../../services/event_bus.js";
import Vue from "vue";
import Datepicker from "vuejs-datepicker";

export default {
    name: "AdminSchedule",
    props: ["sections"],
    data: function () {
        return {
            user: null,
            walksInsInfo: "It means that your shop is open but only accept Walk-Ins(no online appointments).",
            work_settings: {},
            work_settings_clone: {},
            work_hours: {
                sunday_from: {},
                sunday_to: {},
                sunday_block_from: {},
                sunday_block_to: {},
                monday_from: {},
                monday_to: {},
                monday_block_from: {},
                monday_block_to: {},
                tuesday_from: {},
                tuesday_to: {},
                tuesday_block_from: {},
                tuesday_block_to: {},
                wednesday_from: {},
                wednesday_to: {},
                wednesday_block_from: {},
                wednesday_block_to: {},
                thursday_from: {},
                thursday_to: {},
                thursday_block_from: {},
                thursday_block_to: {},
                friday_from: {},
                friday_to: {},
                friday_block_from: {},
                friday_block_to: {},
                saturday_from: {},
                saturday_to: {},
                saturday_block_from: {},
                saturday_block_to: {},
            },
            weekDays: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
            for_stylist_id: 0,
            settings: {},
            notification_settings: {},
            portfolio_url: "",
            timeSlotBooking: "",
            problemsInHours: false,
            counter: {
                accept_new_clients_text: 0,
            },
            timeObj: {
                work_from: false,
                date: "",
                work_to: false,
            },
            exceptions: {
                monday: { dates: [], openDateModal: false, highlighted: [] },
                tuesday: { dates: [], openDateModal: false, highlighted: [] },
                wednesday: { dates: [], openDateModal: false, highlighted: [] },
                thursday: { dates: [], openDateModal: false, highlighted: [] },
                friday: { dates: [], openDateModal: false, highlighted: [] },
                saturday: { dates: [], openDateModal: false, highlighted: [] },
                sunday: { dates: [], openDateModal: false, highlighted: [] },
            },
            loading: false,
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.for_stylist_id = auth.user.id;
        this.getWorkSettings();

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
    },
    methods: {
        isSectionVisible(section) {
            if (!this.sections) {
                return true;
            }

            if (this.sections.indexOf(section) != -1) {
                return true;
            }

            return false;
        },
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            }
        },
        getGroupsMembers: function (group_id) {
            this.serviceCategories = [];
            this.services = {};

            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getWorkSettings: function () {
            let stylist_id = this.for_stylist_id != auth.user.id ? "?user_id=" + this.for_stylist_id : "";

            let getAllSettings = "getAllSettings=1";
            if (stylist_id != "") {
                getAllSettings = "&" + getAllSettings;
            } else {
                getAllSettings = "?" + getAllSettings;
            }

            this.$http.get(api.actions.get_work_settings + stylist_id + getAllSettings).then(
                function (response) {
                    this.loading = false;

                    if (typeof response.data.work_settings != "undefined") {
                        this.adminDetails.work_settings = response.data.work_settings;
                        this.work_settings = response.data.work_settings;
                        this.work_settings_clone = Object.assign({}, this.work_settings);
                        this.work_settings.accept_new_clients = Boolean(this.work_settings.accept_new_clients);
                        this.work_settings.prevent_clients_reschedule = !Boolean(
                            this.work_settings.prevent_clients_reschedule
                        );
                        this.work_settings.prevent_clients_book = !Boolean(this.work_settings.prevent_clients_book);
                        this.settings = response.data.settings;
                        this.settings.auto_confirm_appointments = Boolean(this.settings.auto_confirm_appointments);
                        this.settings.accept_website_booking = Boolean(this.settings.accept_website_booking);
                        this.portfolio_url = response.data.portfolio_url;
                        this.timeSlotBooking = response.data.settings.default_slot_time;
                        this.notification_settings = response.data.notification_settings;
                        this.notification_settings.invite = Boolean(this.notification_settings.invite);
                        this.processHours();
                        this.getExceptions();
                    }
                }.bind(this)
            );
        },

        saveWorkSettings: function () {
            this.getHours();
            if (this.problemsInHours) {
                this.problemsInHours = false;
                return;
            }

            if (this.for_stylist_id != auth.user.id) {
                this.work_settings.group_user_id = this.for_stylist_id;
            }
            //add accept_website_booking
            this.work_settings.accept_website_booking = this.settings.accept_website_booking ? 1 : 0;

            //add default_slot_time
            this.work_settings.default_slot_time = this.timeSlotBooking;

            //auto_confirm_appointments
            this.work_settings.auto_confirm_appointments = this.settings.auto_confirm_appointments;

            //notification settings
            this.work_settings.notification_schedule = this.notification_settings.schedule;
            this.work_settings.notification_reschedule = this.notification_settings.reschedule;
            this.work_settings.notification_cancel = this.notification_settings.cancel;
            this.work_settings.notification_invite = this.notification_settings.invite;

            this.work_settings.prevent_clients_book = !this.work_settings.prevent_clients_book;
            this.work_settings.prevent_clients_reschedule = !this.work_settings.prevent_clients_reschedule;

            let weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
            let deleteExceptionsDates = [];
            weekDays.forEach((element) => {
                if (this.work_settings[element]) {
                    this.exceptions[element].highlighted.forEach((date) => {
                        deleteExceptionsDates.push(moment.utc(date).format("YYYY-MM-DD"));
                    });
                }
            });

            if (deleteExceptionsDates.length) {
                this.delete_exception_day(false, deleteExceptionsDates);
            }
            this.loading = true;
            this.$http.post(api.actions.save_work_settings, this.work_settings).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);

                        this.getWorkSettings();
                    }
                }.bind(this)
            );
        },

        processHours: function () {
            let self = this;
            this.weekDays.forEach(function (day) {
                self.work_hours[day + "_from"] = self.processHourFormat(self.work_settings[day + "_from"]);
                self.work_hours[day + "_to"] = self.processHourFormat(self.work_settings[day + "_to"]);
                self.work_hours[day + "_block_from"] = self.processHourFormat(self.work_settings[day + "_block_from"]);
                self.work_hours[day + "_block_to"] = self.processHourFormat(self.work_settings[day + "_block_to"]);
            });
        },
        processHourFormat: function (value) {
            if (!value) {
                return {
                    hh: "",
                    mm: "",
                    A: "AM",
                };
            }

            let obj = moment(value, ["hh:mh A"]);
            let hour = {
                hh: obj.format("hh"),
                mm: obj.format("mm"),
                A: obj.format("A"),
            };

            return hour;
        },
        getDateFromTimeToCompare: function (timeString) {
            if (typeof timeString == "undefined" || timeString == "") {
                return;
            }
            //timeString should be in hh:mm A format
            return moment(moment().format("YYYY-MM-DD") + " " + timeString, "YYYY-MM-DD hh:mm A").toDate();
        },
        getHours: function () {
            let self = this;
            this.weekDays.forEach(function (day) {
                if (self.problemsInHours) {
                    return;
                }
                let dayFrom = self.getProcessedHour(self.work_hours[day + "_from"]);
                let dayTo = self.getProcessedHour(self.work_hours[day + "_to"]);
                let dayBlockFrom = self.getProcessedHour(self.work_hours[day + "_block_from"]);
                let dayBlockTo = self.getProcessedHour(self.work_hours[day + "_block_to"]);

                if (!self.work_settings[day]) {
                    return;
                }

                let dayFromDate = self.getDateFromTimeToCompare(dayFrom);
                let dayToDate = self.getDateFromTimeToCompare(dayTo);
                if (typeof dayFromDate != "undefined" && typeof dayToDate != "undefined" && dayFromDate >= dayToDate) {
                    self.problemsInHours = true;
                    Vue.toasted.show("From Schedule Time needs to be lesser than To Schedule Time", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }
                if (
                    (typeof dayFromDate != "undefined" && typeof dayToDate == "undefined") ||
                    (typeof dayFromDate == "undefined" && typeof dayToDate != "undefined")
                ) {
                    self.problemsInHours = true;
                    Vue.toasted.show("From Schedule Time and To Schedule Time needs to be set together", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }

                let dayBlockFromDate = self.getDateFromTimeToCompare(dayBlockFrom);
                let dayBlockToDate = self.getDateFromTimeToCompare(dayBlockTo);
                if (
                    typeof dayBlockFromDate != "undefined" &&
                    typeof dayBlockToDate != "undefined" &&
                    dayBlockFromDate >= dayBlockToDate
                ) {
                    self.problemsInHours = true;
                    Vue.toasted.show("From Block Time needs to be lesser than To Block Time", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }
                if (
                    (typeof dayBlockFromDate != "undefined" && typeof dayBlockToDate == "undefined") ||
                    (typeof dayBlockFromDate == "undefined" && typeof dayBlockToDate != "undefined")
                ) {
                    self.problemsInHours = true;
                    Vue.toasted.show("From Block Time and To Block Time needs to be set together", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }

                self.work_settings[day + "_from"] = dayFrom;
                self.work_settings[day + "_to"] = dayTo;
                self.work_settings[day + "_block_from"] = dayBlockFrom;
                self.work_settings[day + "_block_to"] = dayBlockTo;
            });
        },
        getProcessedHour: function (value) {
            if (value["hh"] === "" || value["mm"] === "" || value["A"] === "") {
                return "";
            }

            return value["hh"] + ":" + value["mm"] + " " + value["A"];
        },
        onStylistChange() {
            this.getWorkSettings();
        },
        countChar: function (type) {
            this.counter[type] = this.work_settings[type].length;
        },
        openModal(day) {
            for (let i in this.exceptions) {
                if (i === day) {
                    continue;
                }
                this.exceptions[i].openDateModal = false;
            }
            this.exceptions[day].openDateModal = !this.exceptions[day].openDateModal;
        },
        onDateClickFromDatePicker: function (date, day) {
            this.exceptions[day].openDateModal = false;

            let data = {};
            data.dates = {
                0: {
                    work_from: "",
                    date: moment(date).format("YYYY-MM-DD"),
                    work_to: "",
                },
            };
            if (this.for_stylist_id != auth.user.id) {
                data.group_user_id = this.for_stylist_id;
            }
            this.$http.post(api.actions.save_exception_days, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getExceptions();
                    }
                }.bind(this)
            );
        },
        getExceptions() {
            let user_id = this.for_stylist_id != auth.user.id ? this.for_stylist_id : auth.user.id;

            this.exceptions = {
                monday: { dates: [], openDateModal: false, highlighted: [] },
                tuesday: { dates: [], openDateModal: false, highlighted: [] },
                wednesday: { dates: [], openDateModal: false, highlighted: [] },
                thursday: { dates: [], openDateModal: false, highlighted: [] },
                friday: { dates: [], openDateModal: false, highlighted: [] },
                saturday: { dates: [], openDateModal: false, highlighted: [] },
                sunday: { dates: [], openDateModal: false, highlighted: [] },
            };

            this.$http.get(api.actions.get_exceptions_day + "?user_id=" + user_id).then(
                function (response) {
                    let days = response.data.days;
                    //eslint-disable-next-line
                    for (const [key, day] of Object.entries(days)) {
                        let d = moment.utc(day.date).format("DD");
                        let m = moment.utc(day.date).format("M") - 1;
                        let y = moment.utc(day.date).format("YYYY");

                        let fullDate = new Date(Date.UTC(y, m, d));
                        let deleteDate = moment(day.date).format("MMM DD");
                        switch (moment(day.date).weekday()) {
                            case 0:
                                this.exceptions.sunday.dates.push({
                                    date: fullDate,
                                    dateF: deleteDate,
                                    id: day.id,
                                    work_from: day.work_from,
                                    work_to: day.work_to,
                                });
                                this.exceptions.sunday.highlighted.push(fullDate);
                                break;
                            case 1:
                                this.exceptions.monday.dates.push({
                                    date: fullDate,
                                    dateF: deleteDate,
                                    id: day.id,
                                    work_from: day.work_from,
                                    work_to: day.work_to,
                                });
                                this.exceptions.monday.highlighted.push(fullDate);
                                break;
                            case 2:
                                this.exceptions.tuesday.dates.push({
                                    date: fullDate,
                                    dateF: deleteDate,
                                    id: day.id,
                                    work_from: day.work_from,
                                    work_to: day.work_to,
                                });
                                this.exceptions.tuesday.highlighted.push(fullDate);
                                break;
                            case 3:
                                this.exceptions.wednesday.dates.push({
                                    date: fullDate,
                                    dateF: deleteDate,
                                    id: day.id,
                                    work_from: day.work_from,
                                    work_to: day.work_to,
                                });
                                this.exceptions.wednesday.highlighted.push(fullDate);
                                break;
                            case 4:
                                this.exceptions.thursday.dates.push({
                                    date: fullDate,
                                    dateF: deleteDate,
                                    id: day.id,
                                    work_from: day.work_from,
                                    work_to: day.work_to,
                                });
                                this.exceptions.thursday.highlighted.push(fullDate);
                                break;
                            case 5:
                                this.exceptions.friday.dates.push({
                                    date: fullDate,
                                    dateF: deleteDate,
                                    id: day.id,
                                    work_from: day.work_from,
                                    work_to: day.work_to,
                                });
                                this.exceptions.friday.highlighted.push(fullDate);
                                break;
                            case 6:
                                this.exceptions.saturday.dates.push({
                                    date: fullDate,
                                    dateF: deleteDate,
                                    id: day.id,
                                    work_from: day.work_from,
                                    work_to: day.work_to,
                                });
                                this.exceptions.saturday.highlighted.push(fullDate);
                                break;
                        }
                    }
                }.bind(this)
            );
        },
        delete_exception_day(id, dates = false) {
            let data = {};
            if (dates) {
                data.dates = dates;
            } else {
                data.id = id;
            }

            if (this.for_stylist_id != auth.user.id) {
                data.group_user_id = this.for_stylist_id;
            }
            this.$http.post(api.actions.delete_exception_day, data).then(
                function (response) {
                    if (response.data.error == false && id) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getExceptions();
                    }
                }.bind(this)
            );
        },
        disableDates(day) {
            switch (day) {
                case "monday":
                    return {
                        to: new Date(Date.now() - 8640000),
                        days: [0, 2, 3, 4, 5, 6],
                    };
                case "tuesday":
                    return {
                        to: new Date(Date.now() - 8640000),
                        days: [0, 1, 3, 4, 5, 6],
                    };
                case "wednesday":
                    return {
                        to: new Date(Date.now() - 8640000),
                        days: [0, 1, 2, 4, 5, 6],
                    };
                case "thursday":
                    return {
                        to: new Date(Date.now() - 8640000),
                        days: [0, 1, 2, 3, 5, 6],
                    };
                case "friday":
                    return {
                        to: new Date(Date.now() - 8640000),
                        days: [0, 1, 2, 3, 4, 6],
                    };
                case "saturday":
                    return {
                        to: new Date(Date.now() - 8640000),
                        days: [0, 1, 2, 3, 4, 5],
                    };
                case "sunday":
                    return {
                        to: new Date(Date.now() - 8640000),
                        days: [1, 2, 3, 4, 5, 6],
                    };
            }
        },
        openTimeModal(date) {
            this.timeObj.date = moment.utc(date.date).format("YYYY-MM-DD");
            this.timeObj.work_from = this.processHourFormat(date.work_from);
            this.timeObj.work_to = this.processHourFormat(date.work_to);
            $("#time-modal").modal("show");
        },
        changeTime() {
            let data = {};
            data.dates = {
                0: {
                    work_from: this.getProcessedHour(this.timeObj.work_from),
                    date: moment.utc(this.timeObj.date).format("YYYY-MM-DD"),
                    work_to: this.getProcessedHour(this.timeObj.work_to),
                },
            };
            if (this.for_stylist_id != auth.user.id) {
                data.group_user_id = this.for_stylist_id;
            }
            this.$http.post(api.actions.save_exception_days, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getExceptions();
                        eventBus.$emit("reload_user_details");
                        $("#time-modal").modal("hide");
                    }
                }.bind(this)
            );
        },
        handleTimeSave(event) {
            if (event.mm != "" && event.hh != "") {
                this.saveWorkSettings();
            }
        },
    },
    components: {
        timepicker: TimePicker,
        Datepicker,
    },
};
</script>
<style>
.fa-clock-o {
    position: absolute;
    top: -6px;
    font-size: 17px;
    left: -4px;
    cursor: pointer;
}

#time-modal .modal-body {
    min-height: 190px;
}

.time-save {
    position: absolute;
    bottom: 0;
    right: 15px;
}
</style>
