<template>
    <div class="text-component">
        <div>
            <span v-if="!readMore">{{ limitText(getText) }}</span>
            <span v-if="readMore" v-html="text" class="full-text"></span>

            <span v-if="getText.length > stringLimit" class="read-more" @click="toggleReadMore">
                {{ !readMore ? " read more" : " read less" }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ["text", "stringLimit"],
    data() {
        return {
            readMore: false,
        };
    },
    computed: {
        getText() {
            const div = document.createElement("div");
            div.innerHTML = this.text;

            return div.innerText || "";
        },
    },
    mounted() {},
    methods: {
        limitText(text) {
            if (text.length > this.stringLimit) {
                if (!this.readMore) {
                    return text.substring(0, this.stringLimit) + "...";
                } else {
                    return text;
                }
            } else {
                return text;
            }
        },
        toggleReadMore() {
            this.readMore = !this.readMore;
        },
    },
};
</script>
