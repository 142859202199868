<template>
    <div class="admin-team">
        <h2>{{ $ml.get("team.title") }}</h2>
        <!--Logo Modal-->
        <div class="form-fields section-wrap">
            <!-- Shop logo wrap -->
            <div class="shop-logo-wrap">
                <div class="shop-logo" v-on:click="changeAvatar">
                    <div v-if="!groupAvatar && !logoImageUpload.preview">
                        {{ getInitial(adminDetails.group_name) }}
                    </div>
                    <div v-if="logoImageUpload.preview && loadingImage">
                        <img :src="logoImageUpload.preview" alt="prev avatar" />
                    </div>
                    <div v-if="groupAvatar && !logoImageUpload.preview && !loadingImage">
                        <img :src="groupAvatar" />
                    </div>
                </div>
                <div class="logo-inactive-wrap">
                    <div class="add-shop-logo main-text-link" v-on:click="changeAvatar">
                        <div v-if="groupAvatar == ''"><span>Add logo</span></div>
                        <div v-if="groupAvatar != ''"><span>Change logo</span></div>
                    </div>
                    <div class="shop-active">
                        <p>
                            Your shop page is
                            <span v-if="adminDetails.group_active">{{ $ml.get("general.active") }}</span>
                            <span v-if="!adminDetails.group_active">{{ $ml.get("general.inactive") }}</span>
                        </p>
                    </div>
                </div>

                <div v-if="location" class="text-center mt-2"></div>
            </div>
            <!-- Team form wrapper-->
            <div class="team-form-wrapper">
                <div v-if="shopUrl" class="view-shop-page-link">
                    <a target="_blank" :href="shopUrl" class="main-text-link">{{ $ml.get("general.viewShopPage") }}</a>
                </div>
                <!--Shop Name-->
                <div class="input-label-wrap">
                    <label for="name" class="">{{ $ml.get("team.shopName") }}</label>
                    <input
                        type="text"
                        class="text-field text-field-small text-field-extra-small mobile"
                        placeholder="Please enter shop name"
                        id="name"
                        v-model="adminDetails.group_name"
                        v-bind:disabled="adminDetails.group_id != 0 && !adminDetails.group_owner"
                    />
                </div>
                <!--Salon Name-->
                <!-- <div class="input-label-wrap">
                    <label for="name" class="">{{ $ml.get("team.salonName") }}</label>
                    <input
                        type="text"
                        class="text-field text-field-small text-field-extra-small mobile"
                        placeholder="Please enter shop name"
                        id="name"
                        v-model="salonName"
                        v-bind:disabled="adminDetails.group_id != 0 && !adminDetails.group_owner"
                    />
                </div> -->
                <!--Address-->
                <div class="input-label-wrap">
                    <label for="address" class="">{{ $ml.get("general.address") }}</label>
                    <input
                        type="text"
                        class="text-field text-field-small text-field-extra-small mobile"
                        placeholder="Please enter shop address"
                        id="address"
                        v-model="location.address"
                        v-bind:disabled="adminDetails.group_id != 0 && !adminDetails.group_owner"
                    />
                </div>
                <!--City-->
                <div class="input-label-wrap">
                    <label for="city" class="">{{ $ml.get("general.city") }}</label>
                    <input
                        type="text"
                        class="text-field text-field-small text-field-extra-small mobile"
                        placeholder="Please enter shop city"
                        id="city"
                        v-model="location.city"
                        v-bind:disabled="adminDetails.group_id != 0 && !adminDetails.group_owner"
                    />
                </div>
                <!--State-->
                <div class="input-label-wrap">
                    <label for="state" class="">{{ $ml.get("general.state") }}</label>
                    <input
                        type="text"
                        class="text-field text-field-small text-field-extra-small mobile"
                        placeholder="Please enter shop state"
                        id="state"
                        v-model="location.state"
                        v-bind:disabled="adminDetails.group_id != 0 && !adminDetails.group_owner"
                    />
                </div>
                <!--Zip-->
                <div class="input-label-wrap">
                    <label for="zip" class="">{{ $ml.get("general.zip") }}</label>
                    <input
                        type="text"
                        class="text-field text-field-small text-field-extra-small mobile"
                        placeholder="Please enter shop zip"
                        id="zip"
                        v-model="location.zip"
                        v-bind:disabled="adminDetails.group_id != 0 && !adminDetails.group_owner"
                    />
                </div>
                <!--Phone-->
                <div class="input-label-wrap">
                    <label for="selectPhone">{{ $ml.get("general.phone") }}</label>
                    <div class="select-input-wrap">
                        <div class="dropdown">
                            <select class="selectInput" v-model="location.phone_prefix">
                                <option :value="0" disabled>Prefix</option>
                                <option
                                    v-for="(country, index) in countries"
                                    v-bind:key="index"
                                    :value="country.dial_code"
                                >
                                    {{ country.name }} {{ country.dial_code }}
                                </option>
                            </select>
                        </div>
                        <input
                            type="text"
                            class="text-field text-field-small text-field-extra-small mobile"
                            placeholder="Please enter shop phone"
                            id="phone"
                            v-model="location.phone"
                            v-on:keyup="filterLocationPhone"
                            maxlength="10"
                            v-bind:disabled="adminDetails.group_id != 0 && !adminDetails.group_owner"
                        />
                    </div>
                </div>

                <!--Save btn-->
                <div class="save-btn">
                    <div>
                        <button
                            type="button"
                            class="primary-btn extra-small mobile"
                            name="save-team"
                            v-on:click="saveGroupDetails"
                        >
                            {{ $ml.get("general.save") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="changeLogoImage" class="client-profile-image-container">
            <div class="image-upload-overlay"></div>
            <div class="image-upload-overlay-content">
                <div class="heading-modal">
                    <div v-if="groupAvatar == null"><h3>Add logo</h3></div>
                    <div v-if="groupAvatar != null"><h3>Change logo</h3></div>
                </div>
                <input
                    class="profile-image-input-file"
                    type="file"
                    ref="logoImageInputFile"
                    name="img[]"
                    accept="image/*"
                    @change="logoImageChange"
                />
                <div class="client-input-group">
                    <input
                        type="text"
                        class="text-field"
                        disabled
                        placeholder="Upload File"
                        v-model="logoImageUpload.fileName"
                    />
                    <div class="input-group-append">
                        <button
                            v-on:click="openLogoImageInputFileBrowser"
                            type="button"
                            class="primary-btn extra-small mobile"
                        >
                            Browse...
                        </button>
                    </div>
                </div>
                <div class="profile-image-upload-preview" v-if="logoImageUpload.preview">
                    <div class="image-upload-preview">
                        <vue-cropper
                            ref="cropper"
                            :aspect-ratio="1 / 1"
                            :src="logoImageUpload.preview"
                            preview=".preview"
                        />
                    </div>
                    <section class="preview-area d-block d-flex justify-content-end align-items-center">
                        <div class="preview cropped-image">
                            <div class="crop-placeholder" />
                        </div>
                    </section>
                </div>
                <div v-if="logoImageUpload.preview" class="modal-btns-actions">
                    <a href="javascript:void(0)" role="button" title="Zoom In" @click.prevent="zoom(0.2)">
                        <i class="fa fa-search-plus" aria-hidden="true"></i>
                    </a>
                    <a href="javascript:void(0)" role="button" title="Zoom Out" @click.prevent="zoom(-0.2)">
                        <i class="fa fa-search-minus" aria-hidden="true"></i>
                    </a>

                    <a href="javascript:void(0)" role="button" title="Rotate Left" @click.prevent="rotate(-90)">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                    </a>
                    <a href="javascript:void(0)" role="button" title="Rotate Right" @click.prevent="rotate(90)">
                        <i class="fa fa-repeat" aria-hidden="true"></i>
                    </a>
                    <a ref="flipX" href="javascript:void(0)" role="button" title="Flip X" @click.prevent="flipX">
                        <i class="fa fa-arrows-h" aria-hidden="true"></i>
                    </a>
                    <a ref="flipY" href="javascript:void(0)" role="button" title="Flip Y" @click.prevent="flipY">
                        <i class="fa fa-arrows-v" aria-hidden="true"></i>
                    </a>
                    <a href="javascript:void(0)" role="button" title="Refresh" @click.prevent="reset">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="modal-btns">
                    <button
                        v-if="logoImageUpload.preview"
                        v-on:click="savelogoImageUpload"
                        type="button"
                        class="primary-btn extra-small mobile"
                    >
                        Save
                    </button>
                    <button v-on:click="cancelLogoImageUpload" type="button" class="secondary-btn small mobile">
                        Cancel
                    </button>
                </div>
                <div class="delete-profile-image-wrap" v-if="!logoImageUpload.preview && groupAvatar">
                    <span class="main-text-link" @click="removeTeamLogo()"> Delete the current team logo</span>
                </div>
            </div>
        </div>
        <!--End Logo Modal-->
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
import general from "./../../services/general.js";
import eventBus from "./../../services/event_bus.js";
import moment from "moment";
import colorPicker from "@caohenghu/vue-colorpicker";
import Vue from "vue";
import VueCropper from "vue-cropperjs";

export default {
    name: "AdminTeam",
    data: function () {
        return {
            user: null,
            invite: false,
            invitePhoneNumber: "",
            location: {
                address: "",
                city: "",
                state: "",
                zip: "",
                phone: "",
                prefix: "",
                avatar: null,
            },
            groupAvatar: "",
            inviteEmail: "",
            showDatePicker: false,
            teamDate: moment(new Date()).format("MM/DD/YYYY"),
            preferredTimes: ["Morning", "Afternoon", "Evening"],
            selectedPreferredTime: null,
            disabledDates: {
                to: new Date(Date.now() - 8640000),
            },
            selectedEmpNumber: "1",
            empNumbers: ["1", "2", "3", "4", "5", "6+"],
            teamRequestProgress: false,
            name: "",
            phone: "",

            color: "#59c7f9",
            isSucking: false,
            showColorPicker: false,
            memberColorId: 0,
            shopUrl: null,
            showRemove: false,
            braintree_cards_loaded: false,
            braintree_cards: [],
            //from here
            logoImageUpload: {
                preview: null,
                fileName: null,
                file: null,
                croppedImage: null,
            },
            changeLogoImage: false,
            prefixes: [],
            countries: [],
            defaultPhone: "0000000000",
            loadingImage: false,
            salonName: "",
            //to here
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.name = this.user.first_name + " " + this.user.last_name;
        this.phone = this.user.phone;

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        if (
            typeof this.adminDetails.braintree_cards_loaded != "undefined" &&
            this.adminDetails.braintree_cards_loaded === false
        ) {
            this.$router.push("/app/subscription-payments");
        }
        this.getCountries();
        this.getGroupsMembers();
        this.getProfile();
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
    },
    methods: {
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_location) {
                this.location = this.adminDetails.group_location;
            }

            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            }

            this.selectedPreferredTime = this.preferredTimes[0];

            if (typeof this.adminDetails.shop_url != "undefined" && this.adminDetails.shop_url != null) {
                this.shopUrl = this.adminDetails.shop_url;
            }

            this.getBraintreeCards();
        },
        getGroupsMembers: function (group_id) {
            this.serviceCategories = [];
            this.services = {};
            this.loadingImage = true;

            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                        this.groupMembers.forEach((el, index) => {
                            this.groupMembers[index]["activeNumber"] = el.active;
                            this.groupMembers[index]["active"] = el.active == 1 ? 1 : 0;

                            if (el.active == 0) {
                                this.showRemove = true;
                            }
                        });
                    }
                    if (response.data.group && response.data.group.image) {
                        this.groupAvatar = response.data.group.image;
                        this.loadingImage = false;
                    }

                    // this.$forceUpdate();
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveGroupDetails: function () {
            let data = {
                name: this.adminDetails.group_name,
                active: this.adminDetails.group_active ? 1 : 0,
                address: this.location.address,
                city: this.location.city,
                state: this.location.state,
                zip: this.location.zip,
                phone: this.location.phone,
                phone_prefix: this.location.phone_prefix,
                users: {},
            };

            this.groupMembers.forEach(function (el) {
                if (typeof el.user !== "undefined") {
                    data.users[el.user.id] = el.active;
                }
            });

            this.saveSalon();

            this.$http.post(api.actions.save_stylist_group, data).then(
                function (response) {
                    if (response.data.error == false) {
                        this.getStylistDetails(this.user.id);
                        let message = "";

                        if (response.data.updated_subscription == true) {
                            message =
                                ". " +
                                messages.GLOBAL_MESSAGES.SUBSCRIPTION_UPDATED.replace(
                                    ":num",
                                    response.data.active_members_number
                                );
                        }

                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE + message);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStylistDetails: function (id) {
            this.$http.get(api.actions.get_stylist_details + id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        // let allInfoDetails = response.data;
                        let adminDetails = response.data.details;
                        store.commit("setAdminDetails", adminDetails);
                        eventBus.$emit("admin_details_loaded");

                        if (store.state.adminDetails.group_id && store.state.adminDetails.group_owner) {
                            this.getGroupsMembers(store.state.adminDetails.group_id);
                        }
                    }
                }.bind(this)
            );
        },

        filterPhone: function () {
            this.invitePhoneNumber = this.invitePhoneNumber.replace(/[^\d]/, "");
        },
        filterPreferredPhone: function () {
            this.phone = this.phone.replace(/[^\d]/, "");
        },
        filterLocationPhone: function () {
            this.location.phone = this.location.phone.replace(/[^\d]/, "");
        },
        onDateClickDatePicker: function (date) {
            this.showDatePicker = false;
            this.teamDate = moment(date).format("MM/DD/YYYY");
        },
        changePreferredTime: function (preferredTime) {
            this.selectedPreferredTime = preferredTime;
        },
        changeEmpNumber: function (empNumber) {
            this.selectedEmpNumber = empNumber;
        },
        getBraintreeCards() {
            this.$http.get(api.actions.get_braintree_customer_cards).then((response) => {
                this.loading = false;

                this.braintree_cards = response.data.response;
                this.braintree_cards_loaded = true;
                this.adminDetails.braintree_cards = response.data.response;
                this.adminDetails.braintree_cards_loaded = true;

                if (this.braintree_cards.length == 0) {
                    this.$router.push("/app/subscription-payments");
                }
            });
        },
        //from here
        changeAvatar() {
            this.changeLogoImage = true;
        },
        getInitial: function (name) {
            if (!name) {
                return;
            }
            return name[0].toUpperCase();
        },
        setDefaultCountryPrefix() {
            if (!this.location.phone_prefix) {
                this.countries.forEach((country) => {
                    if (country.id == this.adminDetails.profile.country_id) {
                        this.location.phone_prefix = country.dial_code;
                    }
                });
            }
        },
        getCountries: function () {
            this.$http.get(api.actions.get_countries).then(
                function (response) {
                    if (typeof response.data.countries != "undefined") {
                        this.countries = response.data.countries;
                        this.prefixes = this.countries.map(function (el) {
                            return el.dial_code.replace(" ", "");
                        });
                        this.prefixes = _.sortBy(_.uniqBy(this.prefixes));
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        logoImageChange: function (e) {
            const file = e.target.files[0];

            //check how big is the image
            if (file != "") {
                const fileSize = file.size;
                const fileMb = fileSize / 1024 ** 2;

                if (fileMb >= 4) {
                    messages.errorMessage(messages.ERROR_MESSAGES.IMAGE_TO_BIG);

                    return;
                }
            }

            if (file.type.indexOf("image/") === -1) {
                return;
            }
            this.logoImageUpload.file = file;
            this.logoImageUpload.fileName = file.name;
            this.resizeImage(file);
        },
        openLogoImageInputFileBrowser: function () {
            this.$refs.logoImageInputFile.click();
        },
        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute("data-scale", scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute("data-scale", scale);
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
        resizeImage: function (file) {
            // Ensure it's an image
            if (!file.type.match(/image.*/)) {
                return;
            }

            // Load the image
            var reader = new FileReader();
            reader.onload = (readerEvent) => {
                var image = new Image();
                image.onload = () => {
                    // Resize the image
                    var canvas = document.createElement("canvas"),
                        max_size = 600, // TODO : pull max size from a site config
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0, width, height);
                    let dataUrl = canvas.toDataURL();
                    this.uploadResizedImage(dataUrl);
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        },
        uploadResizedImage: function (dataUrl) {
            if (this.logoImageUpload.preview != null) {
                // get loaded data and render thumbnail.
                this.logoImageUpload.preview = dataUrl;
                // rebuild cropperjs with the updated source
                this.$refs.cropper.replace(dataUrl);
            } else {
                // get loaded data and render thumbnail.
                this.logoImageUpload.preview = dataUrl;
            }
        },
        cancelLogoImageUpload: function () {
            this.logoImageUpload = {
                fileName: null,
                preview: null,
                file: null,
                croppedImage: null,
            };
            this.changeLogoImage = false;
            this.enableLogoPicSave = true;
        },
        savelogoImageUpload: function () {
            //do not send request if no file is available
            if (!this.logoImageUpload.file) {
                return;
            }
            this.loadingImage = true;
            this.enableLogoPicSave = false;
            this.logoImageUpload.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.location.image = this.logoImageUpload.croppedImage;
            this.location.dataImage = true;
            this.location.fileName = this.logoImageUpload.fileName;
            this.changeLogoImage = false;

            let formData = new FormData();
            formData.append("image", this.logoImageUpload.file);

            this.$http.post(api.actions.save_stylist_group_image, formData).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.LOGO_IMAGE_CHANGED);
                        this.cancelLogoImageUpload();
                        this.enableLogoPicSave = true;
                    }
                    this.getGroupsMembers(this.adminDetails.group_id);
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        removeTeamLogo() {
            this.$http.post(api.actions.delete_team_love, {}).then(
                function () {
                    this.groupAvatar = "";
                    localStorage.setItem("user", JSON.stringify(this.user));
                    messages.successMessage(messages.GLOBAL_MESSAGES.DELETE_TEAM_LOGO);
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        //to here

        getProfile() {
            this.$http.get(api.actions.get_profile_info).then(
                function (response) {
                    if (typeof response.data.user_profile !== "undefined") {
                        this.salonName = response.data.user_profile.business_name;
                    }
                }.bind(this)
            );
        },
        saveSalon() {
            //make request to save the new profession
            let data = {
                business_name: this.salonName.trim(),
            };
            this.$http.post(api.actions.save_profile_info, data).then(
                function (response) {
                    if (response.data.error == false) {
                        // messages.successMessage(
                        //   messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE
                        // );
                        // this.profile[field] = this.editValues[field].trim();
                        this.getProfile();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
    },
    components: {
        colorPicker,
        VueCropper,
    },
};
</script>
