<template>
    <div class="admin-reset-password-component container">
        <div class="reset-password-form-wrapper" v-if="!showSuccessNotification">
            <h4 class="reset-password-box-title">{{ $ml.get('adminMenu.recoverPassword') }}</h4>
            <div class="reset-password-form-inner row">
                <form v-if="!showRequestCode" v-on:submit.prevent class="col-12">
                    <div class="row my-3">
                        <div class="col-12">
                            {{ $ml.get('adminMenu.forgotPasswordText') }}
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <input class="col-12 w-100 pl-3" v-model="email" type="email" name="email" placeholder="Email">
                            <span class="input-stripe-border"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-6">
                            <button class="reset-password-button p-0" type="button">
                                <router-link class="d-block" :to="{path: '/app/login'}">{{ $ml.get('adminMenu.backToLogin') }}</router-link>
                            </button>
                        </div>
                        <div class="col-6">
                            <button :class="!email?'action-disabled':''" class="reset-password-button" type="submit" v-on:click="sendEmail">{{ $ml.get('adminMenu.sendEmail') }}</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="reset-pass-options">
                                <span class="link-separator"> -or- </span>
                                <a class="book-action-link" href="#" @click="showRequestCode=true">Request Code</a>
                            </div>
                        </div>
                    </div>
                </form>

                <div v-if="showRequestCode && requestCodeScreen==''" class="login-section form-section">
                    <phone-login @back="showRequestCode=false"></phone-login>
                </div>
            </div>
        </div>
        <div class="reset-password-form-wrapper" v-if="showSuccessNotification">
            <div class="reset-password-form-inner row">
                <form v-on:submit.prevent class="col-12">
                    <div class="row my-3">
                        <div class="col-12 text-center">
                            {{ $ml.get('adminMenu.forgotPasswordSuccessText') }}
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-12">
                            <button class="reset-password-button p-0" type="button">
                                <router-link class="d-block" :to="{path: '/app/login'}">{{ $ml.get('adminMenu.ok') }}</router-link>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import auth from './../../services/auth.js';
import api from './../../services/api_endpoints.js';
import eventBus from './../../services/event_bus.js';
import phoneLogin from './phone_login.vue';
import VueMask from 'v-mask';

Vue.use(VueMask);

export default {
    name: 'AdminForgotPassword',
    data: function(){
        return {
            email: '',
            showSuccessNotification: false,
            showRequestCode: false,
            requestCodeScreen: '',
        };
    },
    mounted: function () {
        if(auth.user){
            this.$router.push('/app');
        }

        eventBus.$on('reset_password_email_sent', this.resetPasswordEmailSendSuccess);
        eventBus.$on('user_profile_loaded', this.onLoggedIn);
    },
    beforeDestroy () {
        eventBus.$off('reset_password_email_sent', this.resetPasswordEmailSendSuccess);
        eventBus.$off('user_profile_loaded', this.onLoggedIn);
    },
    components: {
        phoneLogin
    },
    methods: {
        resetPasswordEmailSendSuccess: function() {
            //show the success notification on success of sending forgot password email
            this.showSuccessNotification = true;
        },
        sendEmail: function() {
            auth.resetPassword({email: this.email, app_id: this.generalOptions.app_id});
        },
        onLoggedIn() {
            this.$router.push('/app');
        }
    },
}
</script>

<style scoped>
label {
    /*min-width: 140px;*/
    margin-right: 20px;
    text-align: right;
}
input {
    height: 38px;
    margin-bottom: 0px;
    padding-top: 9px;
    padding-bottom: 9px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    border-radius: 2px;
    background-color: #fff;
    font-size: 13px;
    text-align: left;
}
input[type='email'] {
    outline: 0;
}
.input-stripe-border {
    margin-bottom: 25px;
    width: 100%;
    display: block;
    border-bottom: solid 1px #07ae9c;
}
input[type='email']:focus + .input-stripe-border {
    border-bottom: solid 2px #07ae9c;
    margin-bottom: 24px;
}
.reset-password-form-wrapper {
    max-width: 600px;
    margin: auto;
    box-shadow: 0 0 6px 0 rgba(0,0,0,.08);
    background-color: #f3f3f3;
    margin-top: 15px;
}
.reset-password-form-inner {
    padding: 50px 50px 40px;
}
.reset-password-box-title {
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #dfdfdf;
    background-color: #fff;
    font-family: museo-sans,sans-serif;
    color: #3b444d;
    font-size: 1.1rem;
    line-height: 1.3rem;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0;
    text-transform: capitalize;
    margin: 0;
}
.reset-password-button {
    padding-top: 15px;
    padding-bottom: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: #08ae9c;
    border-radius: 2px;
    background-color: #08ae9c;
    -webkit-transition: color 200ms ease, background-color 200ms ease;
    transition: color 200ms ease, background-color 200ms ease;
    font-size: 14px;
    width: 100%;
    color: #ffffff;
}
.reset-password-button a{
    padding: 15px 7px;
    color: #ffffff;
    text-decoration: none;
}
.reset-password-button:hover {
    background-color: transparent;
    color: #333;
}
.reset-password-button a:hover {
    background-color: transparent;
    color: #333;
}
.remember-me-wrap {
    margin-top: -5px;
}
.overlay-title {
    margin: 20px 0;
    color: #fff;
}
</style>
