<template>
    <div class="complete-checkout-modal">
        <!-- <h5 v-if="user && user.role == 'stylist'">Client Name</h5> -->
        <div class="book-checkout" v-if="appointment_details && appointment_details.details">
            <div class="wrapper">
                <div class="star-and-stylist-name">
                    <div class="event-image" v-if="appointment_details.client_months_from_register < 6">
                        <img width="15" src="../../assets/images/002-star.svg" alt="Star Image" />
                    </div>
                    <div class="event-name" v-if="appointment_details">
                        {{ appointment_details.details.client.first_name }}
                        {{ appointment_details.details.client.last_name }}
                    </div>
                </div>
                <div v-if="appointment_details" class="email-phone">
                    <div
                        v-if="
                            appointment_details.details.client.phone &&
                            appointment_details.details.client.phone.indexOf('0000000000') == -1
                        "
                        class="event-name"
                    >
                        {{ appointment_details.details.client.phone }}
                    </div>
                    <div
                        v-if="
                            appointment_details.details.client.email &&
                            appointment_details.details.client.email.indexOf('ringmystylist.com') == -1
                        "
                        class="event-name"
                    >
                        {{ appointment_details.details.client.email }}
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <!--Payment summary-->
        <div class="payment-summary" v-if="appointment_details.details">
            <div class="payment-wrap" v-if="bookData.personal != 1">
                <h5>Payment Summary</h5>
                <div class="payment-summary-label">
                    <label>Total: </label><span>{{ appointment_details.total_price }} {{ currency }}</span>
                </div>
                <div class="payment-summary-label">
                    <label>Paid: </label
                    ><span
                        >{{
                            appointment_details.details.total_payments ? appointment_details.details.total_payments : 0
                        }}
                        {{ currency }}</span
                    >
                </div>
                <div class="payment-summary-label">
                    <label>Balance: </label
                    ><span
                        >{{
                            parseFloat(appointment_details.total_price) -
                            parseFloat(
                                appointment_details.details.total_payments
                                    ? appointment_details.details.total_payments
                                    : 0
                            )
                        }}
                        {{ currency }}</span
                    >
                </div>
            </div>
            <div class="">
                <h5>Notes Summary</h5>
                <div class="notes">
                    <span v-if="appointment_details.details.notes && appointment_details.details.notes.length === 0"
                        >There were no notes made</span
                    >
                    <span v-else>{{ appointment_details.details.notes }}</span>
                </div>
            </div>
        </div>
        <hr />
        <!--Checkout payments container-->
        <div class="checkout-payments-container">
            <div class="cards-alert-error" v-if="cards_errors">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {{ cards_errors }}
                </div>
            </div>
            <div class="checkout-payments-wrapper">
                <div v-if="!paymentProcess">
                    <label>Amount</label>
                    <div class="checkout-payment-wrapp">
                        <input
                            class="text-field text-field-small text-field-extra-small mobile no-border-radius-right"
                            type="number"
                            v-model="amount"
                        />
                        <div class="input-group-append">
                            <span v-if="currency === 'USD'" class="input-group-text" id="basic-addon">$</span>
                            <span v-if="currency !== 'USD'" class="input-group-text" id="basic-addon">{{
                                currency
                            }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!paymentProcess">
                    <label>Tips</label>
                    <div class="checkout-payment-wrapp">
                        <input
                            class="text-field text-field-small text-field-extra-small mobile no-border-radius-right"
                            type="number"
                            v-model="tips"
                        />
                        <div class="input-group-append">
                            <span v-if="currency === 'USD'" class="input-group-text" id="basic-addon">$</span>
                            <span v-if="currency !== 'USD'" class="input-group-text" id="basic-addon">{{
                                currency
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <template
                v-if="
                    !paymentProcess &&
                    amount &&
                    (appointment_details.square_authorized || appointment_details.stripe_authorized)
                "
            >
                <div class="checkout-payments-paragraph">
                    <span>Credit card payment, pay now: </span>
                </div>
                <div class="checkout-payments-wrapper">
                    <div>
                        <button @click="getUserCards()" :disabled="cardsLoaded" class="primary-btn extra-small mobile">
                            <span v-if="!cardsLoaded"> Use existing card</span>
                            <span v-if="cardsLoaded">Existing cards</span>
                        </button>
                    </div>
                    <div>
                        <button v-on:click="displayCardForm()" class="primary-btn extra-small mobile">
                            Enter a credit card
                        </button>
                    </div>
                </div>

                <div class="credit-cards">
                    <div class="stylist-row">
                        <div class="cards-wrap">
                            <div v-if="cardsLoaded && appointment_details.stripe_authorized" class="stripe-cards">
                                <template v-if="stripeCards.length">
                                    <div
                                        v-for="(card, i) in stripeCards"
                                        :key="`card-${stripeCards.id}-${i}`"
                                        class="card-wrap cursor-pointer mt-3"
                                        @click="makePaymentCard(card.id)"
                                    >
                                        <div class="card-brand-img">
                                            <img
                                                v-if="card.card.brand == 'visa'"
                                                src="../../assets/images/visa-logo.png"
                                                class="card-logo"
                                            />
                                            <img
                                                v-if="card.card.brand == 'mastercard'"
                                                src="../../assets/images/mastercard-logo.png"
                                                class="card-logo"
                                            />
                                            <img
                                                v-if="card.card.brand == 'discover'"
                                                src="../../assets/images/discover-logo.png"
                                                class="card-logo"
                                            />
                                            <img
                                                v-if="card.card.brand == 'amex'"
                                                src="../../assets/images/americanexpress-logo.png"
                                                class="card-logo"
                                            />
                                        </div>
                                        <div class="card-detail">
                                            <div class="card-brand">
                                                {{ card.card.brand }}
                                            </div>
                                            <div class="card-last4">
                                                **** **** ****
                                                {{ card.card.last4 }}
                                            </div>
                                            <div class="card-exp">
                                                <span>{{ card.card.exp_month }}/{{ card.card.exp_year }}</span>
                                                <span class="continue">Continue</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="section-wrap no-shadow">
                                        <strong>No card, please add one.</strong>
                                    </div>
                                </template>
                            </div>
                            <div v-if="cardsLoaded && appointment_details.square_authorized" class="square-cards">
                                <template v-if="squareCards.length">
                                    <div
                                        v-for="(card, i) in squareCards"
                                        :key="`card-${stripeCards.id}-${i}`"
                                        class="card-wrap"
                                        @click="makePaymentCard(card.id)"
                                    >
                                        <div class="card-brand-img">
                                            <img
                                                v-if="card.card_brand == 'VISA'"
                                                src="../../assets/images/visa-logo.png"
                                                class="card-logo"
                                            />
                                            <img
                                                v-if="card.card_brand == 'MASTERCARD'"
                                                src="../../assets/images/mastercard-logo.png"
                                                class="card-logo"
                                            />
                                            <img
                                                v-if="card.card_brand == 'DISCOVER'"
                                                src="../../assets/images/discover-logo.png"
                                                class="card-logo"
                                            />
                                            <img
                                                v-if="card.card_brand == 'AMERICAN_EXPRESS'"
                                                src="../../assets/images/americanexpress-logo.png"
                                                class="card-logo"
                                            />
                                        </div>
                                        <div class="card-detail">
                                            <div class="card-brand">
                                                {{ card.card_brand }}
                                            </div>
                                            <div class="card-last4">
                                                **** **** ****
                                                {{ card.last_4 }}
                                            </div>
                                            <div class="card-exp">
                                                <span>{{ card.exp_month }}/{{ card.exp_year }}</span>
                                                <span class="continue">Continue</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="section-wrap no-shadow">
                                        <strong>No card, please add one.</strong>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template
                v-if="
                    paymentProcess &&
                    amount &&
                    !showPaymentEmail &&
                    !showSignature &&
                    (appointment_details.square_authorized || appointment_details.stripe_authorized)
                "
            >
                <div class="credit-cards">
                    <div v-if="addingCard">
                        <div
                            v-if="appointment_details.stripe_authorized"
                            class="card-form section-wrap"
                            ref="card_form"
                        ></div>
                        <div
                            v-if="appointment_details.square_authorized"
                            class="card-form card-form-square"
                            ref="card_form_square"
                        >
                            <div id="card-container"></div>
                        </div>
                        <div class="card-logos">
                            <img class="logos" src="../../assets/images/card-logos.png" />
                            <img class="verified" src="../../assets/images/verified-secured.jpg" />
                        </div>
                        <div class="card-errors">
                            {{ cards_errors }}
                        </div>
                        <div class="card-btns-wrapper">
                            <div>
                                <button
                                    class="primary-btn extra-small mobile"
                                    v-on:click="saveCard()"
                                    :disabled="loading"
                                >
                                    <span v-if="!loading">Save Card</span>
                                    <span v-if="loading">Saving...</span>
                                </button>
                            </div>
                            <div>
                                <button
                                    class="secondary-btn small mobile"
                                    v-on:click="
                                        paymentProcess = false;
                                        addingCard = false;
                                        cards_errors = '';
                                    "
                                    :disabled="loading"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!paymentProcess && amount">
                <div class="checkout-payments-paragraph">
                    <span>Offline payment, the client paid with: </span>
                </div>
                <div class="checkout-payments-wrapper">
                    <div>
                        <button class="primary-btn extra-small mobile" @click="oflinePayment('cash')">Paid Cash</button>
                    </div>
                    <div>
                        <button class="primary-btn extra-small mobile" @click="oflinePayment('check')">
                            Paid with Check
                        </button>
                    </div>
                </div>
            </template>
            <div class="signature-wrapper" v-if="paymentProcess && amount && showSignature">
                <h5>Transaction Signature</h5>
                <div id="app-signature">
                    <div class="signature-container">
                        <vueSignature
                            class="signature"
                            ref="signature"
                            :sigOption="signatureOption"
                            :w="'310px'"
                            :h="'150px'"
                        ></vueSignature>
                    </div>
                    <hr />
                    <div class="signature-btns-container">
                        <button class="primary-btn extra-small mobile" @click="saveSignature">Save</button>
                        <button class="secondary-btn small mobile" @click="clearSignature">Clear</button>
                        <button class="secondary-btn small mobile" @click="undoSignature">Undo</button>
                    </div>
                </div>
            </div>

            <div class="receipt-wrapper" v-if="showPaymentEmail">
                <h5>How do you want to receive your digital receipt?</h5>
                <div class="receipt-input-wrapp">
                    <label for="payment-email">Email</label>
                    <input
                        id="payment-email"
                        v-model="payment_email"
                        class="text-field text-field-small text-field-extra-small mobile"
                        type="email"
                        placeholder="Please enter your email"
                    />
                </div>
                <button
                    @click="continueToSignature"
                    :disabled="payment_email && payment_email == ''"
                    class="primary-btn extra-small mobile"
                >
                    Continue
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import "bootstrap";
import _ from "lodash";
import vueSignature from "vue-signature";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import $ from "jquery";
import messages from "./../../services/messages.js";
import auth from "./../../services/auth.js";

export default {
    name: "CompleteCheckoutModal",
    props: {
        appointment_details: {
            type: Object,
            requierd: true,
        },

        suqare_location_id: {
            Type: String,
            require: false,
        },
    },

    emits: ["on-modal-close"],
    data: function () {
        return {
            cards_errors: "",
            amount: null,
            currency: "",
            tips: 0,
            showPaymentEmail: "",
            appointmentDetailsValue: {},
            paymentProcess: false,
            cardsLoaded: false,
            addingCard: false,
            loading: false,
            payment_email: "",
            signatureOption: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            showSignature: false,
            stripeCards: [],
            squareCards: [],
            newCurrentStylist: null,
            selected_event: {},
            appointment_paid: false,
            user: null,
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.appointmentDetailsValue = this.appointment_details;

        this.currency = this.appointmentDetailsValue.details.currency;

        this.amount = this.appointment_details.total_price - this.appointment_details.details.total_payments;
    },
    updated: function () {},

    beforeDestroy() {},
    computed: {
        bookData: {
            get: function () {
                return store.state.bookData;
            },
            set: function (value) {
                store.commit("setBookData", value);
            },
        },
    },
    methods: {
        getUserCards() {
            var data = {};
            if (this.appointment_details.stripe_authorized) {
                this.$http
                    .get(
                        api.actions.get_stripe_payment_methods +
                            "?user_id=" +
                            this.appointmentDetailsValue.details.client_id +
                            "&stylist_id=" +
                            this.appointmentDetailsValue.details.user_id
                    )
                    .then((response) => {
                        this.cardsLoaded = true;

                        if (response.data.error) {
                            return;
                        }
                        this.stripeCards = response.data.payment_methods;
                    });
            } else if (this.appointment_details.square_authorized) {
                data = {
                    stylist_id: this.appointmentDetailsValue.details.user_id,
                    client_id: this.appointmentDetailsValue.details.client_id,
                };

                this.$http.post(api.actions.get_square_customer_cards, data).then((response) => {
                    this.cardsLoaded = false;
                    setTimeout(() => {
                        this.cardsLoaded = true;
                    }, 100);

                    if (response.data.error) {
                        return;
                    }

                    this.squareCards = response.data.response;
                });
            }
        },

        oflinePayment(type) {
            let data = {
                stylistId: this.appointmentDetailsValue.details.user_id,
                amount: parseFloat(this.amount),
                customerId: this.appointmentDetailsValue.details.client_id,
                paymentType: type,
                currency: this.currency,
                appointment_id: this.appointmentDetailsValue.details.id,
            };

            this.$http.post(api.actions.offline_charge, data).then((response) => {
                if (response.body.error == true) {
                    return;
                }

                this.$emit("on-modal-close");
                this.loading = false;
                messages.successMessage("The payment has been made successfully!");
            });
        },
        displayCardForm() {
            this.addingCard = true;
            this.paymentProcess = true;
            this.cards_errors = "";

            let self = this;

            async function initializeCard(payments) {
                const card = await payments.card();
                await card.attach("#card-container");

                return card;
            }

            function loadSquareForm() {
                const payments = window.Square.payments(
                    self.appointment_details.square_app_id,
                    self.suqare_location_id
                );

                try {
                    self.square_card_obj = initializeCard(payments);
                } catch (e) {
                    self.cards_errors = "Initializing Card failed";
                    return;
                }
            }

            if (this.appointment_details.stripe_authorized) {
                if (!this.$refs.card_form) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (!this.appointment_details.stripe_public_key) {
                    return;
                }

                this.stripe = window.Stripe(this.appointment_details.stripe_public_key);
                var elements = this.stripe.elements();
                this.stripe_card = elements.create("card");
                this.stripe_card.mount(this.$refs.card_form);

                this.stripe_card.addEventListener("change", (event) => {
                    if (event.error) {
                        this.cards_errors = event.error.message;
                    } else {
                        this.cards_errors = "";
                    }
                });

                this.getStripeSetupIntent();

                //Generate the Square card Form
            } else if (this.appointment_details.square_authorized) {
                if (!this.$refs.card_form_square) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }
                loadSquareForm();
            }
        },
        getStripeSetupIntent() {
            if (this.getting_stripe_client_secret) {
                return;
            }

            this.getting_stripe_client_secret = true;

            this.$http
                .post(api.actions.get_stripe_setup_intent, {
                    user_id: this.appointmentDetailsValue.details.client_id,
                    stylist_id: this.appointmentDetailsValue.details.user_id,
                })
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }
                    this.stripe_client_secret = response.data.client_secret;
                    this.getting_stripe_client_secret = false;
                });
        },
        saveCard() {
            this.cards_errors = "";
            this.paymentProcess = true;
            this.loading = true;

            let self = this;
            async function tokenize(paymentMethod) {
                const tokenResult = await paymentMethod.tokenize();
                if (tokenResult.status === "OK") {
                    self.loading = false;
                    return tokenResult.token;
                }
            }

            //Save the card in Stripe
            if (this.appointment_details.stripe_authorized) {
                this.stripe
                    .confirmCardSetup(this.stripe_client_secret, {
                        payment_method: {
                            card: this.stripe_card,
                            billing_details: {},
                        },
                    })
                    .then((result) => {
                        if (result.error) {
                            this.cards_errors = result.error.message;
                            this.loading = false;
                            return;
                        } else {
                            this.loading = false;
                            this.makePaymentCard(result.setupIntent.payment_method);
                            this.$http.post(api.actions.save_stylist_policy_agreement, {
                                stylist_id: this.appointmentDetailsValue.details.user_id,
                                client_id: this.appointmentDetailsValue.details.client_id,
                                agree: 1,
                            });
                        }
                    });

                //Save the card in Square
            } else if (this.appointment_details.square_authorized) {
                this.square_card_obj.then((card) => {
                    try {
                        tokenize(card).then((nonce) => {
                            if (!nonce) {
                                return;
                            }
                            var data = {
                                card_nonce: nonce,
                                stylist_id: this.appointmentDetailsValue.details.user_id,
                                client_id: this.appointmentDetailsValue.details.client_id,
                            };

                            this.loading = true;

                            this.$http.post(api.actions.save_square_customer_card, data).then((response) => {
                                this.loading = false;
                                if (response.data.error == true) {
                                    this.cards_errors = "Invalid card data.";
                                    return;
                                }
                                this.makePaymentCard(response.body.card_id);
                                this.$http.post(api.actions.save_stylist_policy_agreement, {
                                    stylist_id: this.appointmentDetailsValue.details.user_id,
                                    client_id: this.appointmentDetailsValue.details.client_id,
                                    agree: 1,
                                });
                            });
                        });
                    } catch (e) {
                        self.cards_errors = e.message;
                    }
                });
            }
        },
        makePaymentCard(card) {
            this.cards_errors = "";
            this.payment_card = card;
            this.paymentProcess = true;
            this.showPaymentEmail = true;
        },
        continueToSignature() {
            this.showPaymentEmail = false;
            this.showSignature = true;
        },
        makePayment() {
            this.loading = true;

            if (this.appointment_details.stripe_authorized) {
                let data = {
                    stylist_id: this.appointmentDetailsValue.details.user_id,
                    client_id: this.appointmentDetailsValue.details.client_id,
                    amount: parseFloat(this.amount) + parseFloat(this.tips),
                    currency: this.currency,
                    receipt_email: this.payment_email,
                    appointment_id: this.appointmentDetailsValue.details.id,
                    payment_type: "payment",
                    payment_method_id: this.payment_card,
                };
                this.$http.post(api.actions.get_stripe_payment_intent, data).then((response) => {
                    if (response.body.error == true) {
                        this.paymentProcess = false;
                        this.payment_card = false;
                        this.showPaymentEmail = false;
                        this.$emit("on-modal-close");
                        this.cards_errors = response.body.message;
                        return;
                    }
                    this.showPaymentEmail = false;
                    this.showSignature = true;
                    this.loading = false;
                    this.$emit("on-modal-close");
                });

                //Save the card in Square
            } else if (this.appointment_details.square_authorized) {
                let data = {
                    stylist_id: this.appointmentDetailsValue.details.user_id,
                    client_id: this.appointmentDetailsValue.details.client_id,
                    amount: parseFloat(this.amount) + parseFloat(this.tips),
                    currency: this.currency,
                    appointment_id: this.appointmentDetailsValue.details.id,
                    location_id: this.suqare_location_id,
                    card_id: this.payment_card,
                };

                this.$http.post(api.actions.charge_square_payment, data).then((response) => {
                    if (response.body.error == true) {
                        this.paymentProcess = false;
                        this.payment_card = false;
                        this.showPaymentEmail = false;
                        this.cards_errors = response.body.message;
                        return;
                    }

                    this.save_payment_details(response.body.order_id);
                    this.$emit("on-modal-close");
                });
            }
        },
        save_payment_details(order_id) {
            let data = {
                appointment_id: this.appointmentDetailsValue.details.id,
                amount: parseFloat(this.amount),
                type: "payment",
                order_id: order_id,
            };

            this.$http.post(api.actions.save_payment_details, data).then((response) => {
                if (response.body.error == true) {
                    return;
                }
                this.showPaymentEmail = false;
                this.showSignature = true;
                this.loading = false;
            });
        },
        saveSignature() {
            var _this = this;
            var png = _this.$refs.signature.save();

            let data = {
                appointment_id: this.appointmentDetailsValue.details.id,
                user_id: this.appointmentDetailsValue.details.client_id,
                signature: png,
                dataImage: 1,
            };

            this.loading = false;

            this.$http.post(api.actions.save_signature, data).then((response) => {
                if (response.body.error == true) {
                    return;
                }
                this.makePayment();
                messages.successMessage("The payment has been made successfully!");
                this.showCheckout = false;
                $("#event-modal").modal("hide");
            });
        },
        clearSignature() {
            var _this = this;
            _this.$refs.signature.clear();
        },
        undoSignature() {
            var _this = this;
            _this.$refs.signature.undo();
        },
    },
    components: { vueSignature },
};
</script>
