<template>
    <div class="client-listing-notifications section-wrap" v-if="supportMessages.length > 0">
        <div class="notification-container">
            <h5 class="appt-title">{{ $ml.get("adminMenu.messageHistory") }}</h5>
            <div v-if="supportMessages.length > 0">
                <div class="messages-wrap">
                    <div v-for="(supportMessage, index) in supportMessagesItems" v-bind:key="index">
                        <div
                            class="support-message-history section-wrap highlighted-box"
                            :class="supportMessage.from_label == 'support' ? 'user-box' : 'support-box'"
                        >
                            <div class="timestamp notification-date">
                                {{ supportMessage.created_at }}
                            </div>
                            <div>
                                {{ supportMessage.message }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="load-more">
                <div class="primary-btn extra-small mobile" v-if="messagesHasMoreItems" @click="messagesLoadMore">
                    {{ $ml.get("payments.loadMore") }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "./../../services/api_endpoints.js";
import moment from "moment";

export default {
    name: "ClientNotifications",
    props: {},

    data: function () {
        return {
            supportMessages: [],
            supportMessagesItems: [],
            messagesLimit: 10,
            messagesOffset: 0,
            messagesHasMoreItems: false,
        };
    },
    mounted: function () {
        this.getUserSupportMessages();
    },

    methods: {
        calculateMoreItems() {
            const keys = Object.keys(this.supportMessages);
            this.messagesHasMoreItems = !this.allItemsLoaded && this.messagesOffset + this.messagesLimit < keys.length;
        },
        messagesLoadMore() {
            this.messagesOffset += this.messagesLimit;
            if (this.messagesOffset >= Object.keys(this.supportMessages).length) {
                this.allItemsLoaded = true;
            }

            this.supportMessagesItems = this.supportMessages.slice(0, this.messagesOffset);
            this.calculateMoreItems();
        },
        getUserSupportMessages: function () {
            this.$http.get(api.actions.get_user_support_messages + "?ordering=desc").then(
                function (response) {
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.supportMessages != "undefined" &&
                        response.body.supportMessages.length > 0
                    ) {
                        this.supportMessages = response.body.supportMessages;

                        this.supportMessages.forEach((msg) => {
                            let localTime = moment.utc(msg.created_at).toDate();
                            localTime = moment(localTime).format("YYYY-MM-DD HH:mm:ss");
                            msg.created_at = localTime;
                        });

                        this.messagesLoadMore();
                        this.calculateMoreItems();
                        this.makeSupportMessagesRead();
                    }
                }.bind(this),

                function () {
                    return false;
                }
            );
        },
        makeSupportMessagesRead: function () {
            this.$http
                .post(api.actions.set_support_message_read, {
                    read: 1,
                })
                .then(
                    function (response) {
                        if (response.data.error) {
                            this.localNotificationCount = 0;
                            return;
                        }
                    }.bind(this),
                    function () {
                        return false;
                    }
                );
        },
    },
};
</script>
