<template>
    <!--Reports main container-->
    <div class="reports-component">
        <!--Reports appts container-->
        <div v-if="isSectionVisible('appts-reports')" class="appts-reports">
            <div class="reports-title">
                <h3>{{ $ml.get("adminMenu.apptsReports") }}</h3>
            </div>
            <div class="appts-reports-wrap section-wrap">
                <div class="report-interval-wrap">
                    <div class="report-interval-sel">
                        <div
                            v-for="(opt, i) in reportIntervals"
                            :key="`interval-${i}`"
                            class="report-interval"
                            :class="{ sel: i == currentReportInterval }"
                            v-on:click="selectIntervalFilter(i)"
                        >
                            {{ opt.num }}{{ opt.interval[0] }}
                        </div>
                    </div>
                </div>
                <div>
                    <div class="reports-row" :class="{ loading: loading }">
                        <div class="loading-overlay">Loading report...</div>
                        <!--Reports row left-->
                        <div class="reports-row-left section-wrap highlighted-box">
                            <div class="top-clients-section">
                                <div class="reports-header">
                                    <div class="reports-header-title">
                                        Top
                                        <span v-if="reportData.top_clients && reportData.top_clients.length >= 5"
                                            >5</span
                                        >
                                        Clients
                                    </div>
                                </div>
                                <template v-if="reportData.top_clients && reportData.top_clients.length > 0">
                                    <div
                                        v-for="(row, i) in reportData.top_clients"
                                        :key="`top-clients-row-${i}`"
                                        class="top-clients-row"
                                    >
                                        <div class="top-clients-col">{{ row.client_name }}</div>
                                        <div class="top-clients-col text-center">
                                            {{ row.total_appointments }} appts.
                                        </div>
                                        <div class="top-clients-col text-center">
                                            {{ currency }} {{ numberFormat(row.total_payments) }}
                                        </div>
                                    </div>
                                </template>
                                <template v-if="reportData.top_clients && reportData.top_clients.length == 0">
                                    <div class="top-clients-row">
                                        <div class="top-clients-col">N/A</div>
                                    </div>
                                </template>
                            </div>
                            <div class="reports-header">
                                <div class="reports-header-title">Sales Summary: Overview</div>
                                <div class="reports-header-subtitle">{{ startDate }} - {{ endDate }}</div>
                            </div>
                            <div class="reports-sales-row">
                                <div class="reports-box">
                                    <div class="rep-value">
                                        {{ currency }}{{ numberFormat(reportData.gross_sales) }}
                                    </div>
                                    <div class="rep-label">Gross Sales</div>
                                    <div
                                        class="rep-percent"
                                        :class="{ negative: reportData.gross_sales_increase_percent < 0 }"
                                    >
                                        {{ reportData.gross_sales_increase_percent }}%
                                    </div>
                                </div>
                                <div class="reports-box">
                                    <div class="rep-value">{{ currency }}{{ numberFormat(reportData.net_sales) }}</div>
                                    <div class="rep-label">Net Sales</div>
                                    <div
                                        class="rep-percent"
                                        :class="{ negative: reportData.net_sales_increase_percent < 0 }"
                                    >
                                        {{ reportData.net_sales_increase_percent }}%
                                    </div>
                                </div>
                                <div class="reports-box">
                                    <div class="rep-value">{{ currency }}{{ numberFormat(reportData.sales) }}</div>
                                    <div class="rep-label">Sales</div>
                                    <div
                                        class="rep-percent"
                                        :class="{ negative: reportData.sales_increase_percent < 0 }"
                                    >
                                        {{ reportData.sales_increase_percent }}%
                                    </div>
                                </div>
                                <div class="reports-box">
                                    <div class="rep-value">
                                        {{ currency }}{{ numberFormat(reportData.average_sale) }}
                                    </div>
                                    <div class="rep-label">Average Sale</div>
                                    <div
                                        class="rep-percent"
                                        :class="{ negative: reportData.average_sale_increase_percent < 0 }"
                                    >
                                        {{ reportData.average_sale_increase_percent }}%
                                    </div>
                                </div>
                            </div>
                            <div class="reports-sales-row"></div>
                            <div class="reports-appts-row">
                                <div class="reports-appts-row-head">
                                    <div class="row-title">Total Appointments</div>
                                    <div class="row-num">{{ reportData.total_appts }}</div>
                                </div>
                                <div class="reports-appts-item">
                                    <div class="reports-appts-item-label">
                                        {{ reportData.confirmed_appts }} confirmed appts
                                    </div>
                                    <div class="reports-appts-item-value">
                                        <span
                                            :style="{ width: getApptPercent('confirmed_appts') + '%' }"
                                            class="blue"
                                        ></span>
                                    </div>
                                </div>
                                <div class="reports-appts-item">
                                    <div class="reports-appts-item-label">
                                        {{ reportData.confirmed_paid_appts }} confirmed appts &amp; paid
                                    </div>
                                    <div class="reports-appts-item-value">
                                        <span
                                            :style="{ width: getApptPercent('confirmed_paid_appts') + '%' }"
                                            class="green"
                                        ></span>
                                    </div>
                                </div>
                                <div class="reports-appts-item">
                                    <div class="reports-appts-item-label">
                                        {{ reportData.unconfirmed_appts }} unconfirmed appts
                                    </div>
                                    <div class="reports-appts-item-value">
                                        <span
                                            :style="{ width: getApptPercent('unconfirmed_appts') + '%' }"
                                            class="blue"
                                        ></span>
                                    </div>
                                </div>
                                <div class="reports-appts-item">
                                    <div class="reports-appts-item-label">
                                        {{ reportData.unconfirmed_paid_appts }} unconfirmed appts &amp; paid
                                    </div>
                                    <div class="reports-appts-item-value">
                                        <span
                                            :style="{ width: getApptPercent('unconfirmed_paid_appts') + '%' }"
                                            class="green"
                                        ></span>
                                    </div>
                                </div>
                                <div class="reports-appts-item">
                                    <div class="reports-appts-item-label">
                                        {{ reportData.canceled_appts }} canceled appts
                                    </div>
                                    <div class="reports-appts-item-value">
                                        <span
                                            :style="{ width: getApptPercent('canceled_appts') + '%' }"
                                            class="red"
                                        ></span>
                                    </div>
                                </div>
                            </div>
                            <div class="reports-appts-row">
                                <div class="reports-appts-row-head">
                                    <div class="row-title">Most requested services</div>
                                </div>
                                <div
                                    v-for="(service, i) in reportData.most_requested_services"
                                    :key="`req-service-${i}`"
                                    class="reports-appts-item"
                                >
                                    <div class="reports-appts-item-label">{{ service.name }}</div>
                                    <div class="reports-appts-item-value">
                                        <span
                                            :style="{ width: service.request_percent + '%' }"
                                            :class="{ blue: i % 2 == 0, green: i % 2 != 0 }"
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Report row right-->
                        <div class="reports-row-right section-wrap highlighted-box">
                            <div class="gross-sales-row">
                                <div class="row-title">Gross Sales</div>
                                <div class="reports-sales-row">
                                    <div class="reports-box">
                                        <div class="rep-value">
                                            {{ currency }}{{ numberFormat(reportData.gross_sales_current_year) }}
                                        </div>
                                        <div class="rep-label">This Year</div>
                                        <div
                                            class="rep-percent"
                                            :class="{ negative: reportData.gross_sales_this_year_increase_percent < 0 }"
                                        >
                                            {{ reportData.gross_sales_this_year_increase_percent }}%
                                        </div>
                                    </div>
                                    <div class="reports-box">
                                        <div class="rep-value">
                                            {{ currency }}{{ numberFormat(reportData.gross_sales_past_year) }}
                                        </div>
                                        <div class="rep-label">Previous Year</div>
                                        <div class="rep-percent">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="chart-wrap">
                                    <bar-chart v-if="chartData.datasets.length" :data="chartData"></bar-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isSectionVisible('products-reports')" class="products-reports">
            <div class="products-heading">
                <h3>{{ $ml.get("adminMenu.productsReports") }}</h3>
            </div>
            <div class="products-content section-wrap"><p>Coming Soon</p></div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import auth from "./../../services/auth.js";
import messages from "./../../services/messages.js";
import api from "./../../services/api_endpoints.js";
import moment from "moment";
import { Bar } from "vue-chartjs";

Vue.component("bar-chart", {
    extends: Bar,
    props: ["data"],
    mounted() {
        this.renderChart(this.data, {
            responsive: true,
            maintainAspectRatio: false,
        });
    },
});

export default {
    name: "Reports",
    props: ["sections"],
    data: function () {
        return {
            user: "",
            reportIntervals: [
                { num: 1, interval: "weeks" },
                { num: 2, interval: "weeks" },
                { num: 4, interval: "weeks" },
                { num: 3, interval: "months" },
                { num: 6, interval: "months" },
                { num: 12, interval: "months" },
            ],
            currentReportInterval: 2,
            startDate: "",
            endDate: "",
            currency: "",
            reportData: {},
            chartData: {
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                datasets: [],
            },
            loading: false,
        };
    },
    mounted: function () {
        if (!auth.user) {
            this.$router.push("/app");
        }

        this.user = auth.user;
        this.getReportInterval();
    },
    methods: {
        isSectionVisible(section) {
            if (!this.sections) {
                return true;
            }

            if (this.sections.indexOf(section) != -1) {
                return true;
            }

            return false;
        },
        getReportInterval() {
            var startDate = moment()
                .subtract(
                    this.reportIntervals[this.currentReportInterval].num,
                    this.reportIntervals[this.currentReportInterval].interval
                )
                .format("YYYY-MM-DD");
            var endDate = moment().format("YYYY-MM-DD");

            this.startDate = moment(startDate).format("MMM D YYYY");
            this.endDate = moment(endDate).format("MMM D YYYY");
            this.loading = true;

            this.$http.get(api.actions.get_appt_reports + `?start_date=${startDate}&end_date=${endDate}`).then(
                function (response) {
                    this.loading = false;
                    if (response.data.error) {
                        messages.errorMessage(messages.ERROR_MESSAGES.GENERAL_ERROR);
                        return;
                    }

                    this.reportData = response.data;
                    this.currency =
                        !this.reportData.currency ||
                        this.reportData.currency == "USD" ||
                        this.reportData.currency == "usd"
                            ? "$"
                            : this.reportData.currency;

                    this.chartData.datasets = [];

                    var thisYearData = {
                        label: "This Year",
                        backgroundColor: "#60B033",
                        data: [],
                    };

                    this.reportData.gross_sales_current_year_monthly.forEach((el) => {
                        thisYearData.data.push(el.gross_sales);
                    });

                    this.chartData.datasets.push(thisYearData);

                    var pastYearData = {
                        label: "Previous Year",
                        backgroundColor: "#bfc6da",
                        data: [],
                    };

                    this.reportData.gross_sales_past_year_monthly.forEach((el) => {
                        pastYearData.data.push(el.gross_sales);
                    });

                    this.chartData.datasets.push(pastYearData);
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        selectIntervalFilter(filter) {
            this.currentReportInterval = filter;
            this.getReportInterval();
        },
        getApptPercent(type) {
            return (this.reportData[type] / this.reportData.total_appts) * 100;
        },
        numberFormat(number) {
            return new Intl.NumberFormat().format(number);
        },
    },
};
</script>
