const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const FULL_CALENDAR_LICENSE_KEY = process.env.VUE_APP_FULL_CALENDAR_LICENSE_KEY;
this.API_KEY = process.env.VUE_APP_API_KEY;
this.CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
this.CLIENT_SECRET = process.env.VUE_APP_CLIENT_SECRET;
this.DEMO_CLIENT = process.env.VUE_APP_DEMO_CLIENT;
this.STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
this.REQUEST_ERROR = "There was an error while talking to the server.";
this.API_ENDPOINT = API_ENDPOINT;

this.fullCalendarLicenseKey = FULL_CALENDAR_LICENSE_KEY;

this.actions = {
    login: API_ENDPOINT + "oauth/token",
    logout: API_ENDPOINT + "web/logout",
    register: API_ENDPOINT + "register",
    get_countries: API_ENDPOINT + "getcountries",
    get_profile: API_ENDPOINT + "web/profile",
    delete_profile_image: API_ENDPOINT + "web/deleteprofileimage",
    delete_team_love: API_ENDPOINT + "web/deletestylistgroupimage",
    get_stylist_groups: API_ENDPOINT + "web/getstylistgroups",
    get_stylist_group_members: API_ENDPOINT + "web/getstylistgroupmembers/",
    get_stylist_free_hours: API_ENDPOINT + "web/getbusinesshours",
    get_stylist_details: API_ENDPOINT + "web/getstylistdetails/",
    save_appointment: API_ENDPOINT + "web/saveappointmentv2",
    save_appointment_service: API_ENDPOINT + "web/saveappointmentservice",
    send_invite: API_ENDPOINT + "web/saveinvite",
    check_user_link: API_ENDPOINT + "web/checkuserlink",
    check_user_client_link: API_ENDPOINT + "web/checkuserclientlink",
    check_group_stylist_client_link: API_ENDPOINT + "web/checkgroupstylistclientlink",
    get_stylist_subaccount_email: API_ENDPOINT + "web/getstylistsubaccountemail",
    save_stylist_group: API_ENDPOINT + "web/savestylistgroup",
    save_service: API_ENDPOINT + "web/saveservice",
    save_service_image: API_ENDPOINT + "web/saveserviceimage",
    save_service_category: API_ENDPOINT + "web/saveservicecategory",
    get_services: API_ENDPOINT + "web/getservices",
    get_service_categories: API_ENDPOINT + "web/getservicecategories",
    save_service_users: API_ENDPOINT + "web/savestylistgroupserviceusers",
    get_stylist_by_alias: API_ENDPOINT + "web/getstylistbyalias",
    save_work_settings: API_ENDPOINT + "web/saveworksettings",
    get_work_settings: API_ENDPOINT + "web/getworksettings",
    detach_service_category: API_ENDPOINT + "web/detatchservicecategory",
    edit_exception_day: API_ENDPOINT + "web/editexceptionday",

    get_business_licenses: API_ENDPOINT + "web/getbusinesslicenses",
    get_business_license: API_ENDPOINT + "web/getbusinesslicense",
    save_business_license: API_ENDPOINT + "web/savebusinesslicense",
    delete_business_license: API_ENDPOINT + "web/deletebusinesslicense",
    save_business_license_image: API_ENDPOINT + "web/savebusinesslicenseimage",
    delete_license_image: API_ENDPOINT + "web/deletelicenseimage",

    delete_service_image: API_ENDPOINT + "web/deleteserviceimage",
    get_exceptions_day: API_ENDPOINT + "web/getexceptiondays",
    save_exception_day: API_ENDPOINT + "web/saveexceptionday",
    save_exception_days: API_ENDPOINT + "web/saveexceptiondays",
    delete_exception_day: API_ENDPOINT + "web/deleteexceptionday",

    get_timezoneslist: API_ENDPOINT + "gettimezoneslist/",
    get_clientnotifications: API_ENDPOINT + "web/getclientnotifications",
    save_clientnotifications: API_ENDPOINT + "web/saveclientnotifications",

    get_free_days: API_ENDPOINT + "web/getfreedays",
    save_free_days: API_ENDPOINT + "web/savefreedays",
    edit_free_day: API_ENDPOINT + "web/editfreeday",
    delete_free_day: API_ENDPOINT + "web/deletefreeday",
    send_sms: API_ENDPOINT + "web/sendsms",
    get_stylist_appointments: API_ENDPOINT + "web/getstylistappointmentslist",
    get_client_appointments: API_ENDPOINT + "web/getclientappointmentslist",
    get_random_profile_review: API_ENDPOINT + "web/getrandomprofilereview",
    get_clients: API_ENDPOINT + "web/getclients",
    get_clients_list: API_ENDPOINT + "web/getclientslist",
    get_group_clients: API_ENDPOINT + "web/getgroupclients",
    get_group_free_days: API_ENDPOINT + "web/getgroupfreedays",
    get_stylists: API_ENDPOINT + "web/getstylists",
    cancel_appointment: API_ENDPOINT + "web/cancelappointment",
    confirm_appointment: API_ENDPOINT + "web/confirmappointment",
    get_appointment_details: API_ENDPOINT + "web/getappointmentdetails/",
    add_client_user: API_ENDPOINT + "web/addclientuser",
    update_client_user: API_ENDPOINT + "web/updateclientuser",
    block_group_client: API_ENDPOINT + "web/blockgroupclient",
    save_group_stylists_client_link: API_ENDPOINT + "web/savegroupstylistsclientlink",
    delete_service: API_ENDPOINT + "web/deleteservice",
    delete_service_category: API_ENDPOINT + "web/deleteservicecategory",
    delete_client: API_ENDPOINT + "web/deleteclient",
    delete_stylist: API_ENDPOINT + "web/deletestylist",
    get_deposit: API_ENDPOINT + "web/getdeposit",
    get_profiles_list: API_ENDPOINT + "getstylistprofileslist",
    get_featured_items: API_ENDPOINT + "getfeatureditems",
    update_profile_likes: API_ENDPOINT + "updateprofilelikes",
    get_active_professions: API_ENDPOINT + "getactiveprofessions",
    send_contact_email: API_ENDPOINT + "sendcontactemail",
    send_app_link: API_ENDPOINT + "sendapplink",
    accept_invite: API_ENDPOINT + "web/acceptinvite",
    get_profile_info: API_ENDPOINT + "web/getprofileinfo",
    get_service_price_range: API_ENDPOINT + "web/getservicepricerange",
    send_password_reset_code: API_ENDPOINT + "web/sendpasswordresetcode",
    verify_reset_code_change_password: API_ENDPOINT + "web/verifyresetcodechangepassword",
    send_verification_code: API_ENDPOINT + "web/sendverificationcode",
    change_phone_number: API_ENDPOINT + "web/changephonenumber",
    get_professions: API_ENDPOINT + "web/getprofessions",
    get_user_profession: API_ENDPOINT + "web/getuserprofession",
    save_user_profession: API_ENDPOINT + "web/saveuserprofession",
    save_user_accounts: API_ENDPOINT + "web/saveuseraccounts",
    get_portfolio_images: API_ENDPOINT + "web/getportfolioimages",
    save_portfolio_image: API_ENDPOINT + "web/saveportfolioimage",
    save_profile_info: API_ENDPOINT + "web/saveprofileinfo",
    save_image_tags: API_ENDPOINT + "web/saveimagetags",

    stripe_connect: API_ENDPOINT + "web/stripeconnect",
    get_stripe_details: API_ENDPOINT + "web/getstripedetails",
    get_express_account_login_link: API_ENDPOINT + "web/getexpressaccountloginlink",
    delete_portfolio_image: API_ENDPOINT + "web/deleteportfolioimage",
    rotate_portfolio_image: API_ENDPOINT + "web/rotateportfolioimage",
    search_appointments: API_ENDPOINT + "web/searchappointmentslighttable",

    save_invite_portfolio: API_ENDPOINT + "web/save-invite-portfolio",
    save_phone_check_portfolio: API_ENDPOINT + "web/save-phone-check-portfolio",
    save_client_user_portfolio: API_ENDPOINT + "web/saveclientuserportfolio",
    check_existing_appointments: API_ENDPOINT + "web/checkexistingappointments",
    verify_phone_code: API_ENDPOINT + "web/verify-phone-code",
    get_stylist_profile: API_ENDPOINT + "web/getstylistprofile/",
    get_latest_profile_reviews: API_ENDPOINT + "web/getlatestprofilereviews",
    get_salon_details: API_ENDPOINT + "web/getsalondetails/",
    get_salon_details_new: API_ENDPOINT + "web/getsalondetailsnew/",
    get_free_hours: API_ENDPOINT + "getbusinesshours",

    get_checkout_appointments_count: API_ENDPOINT + "web/getcheckoutappointmentscount",
    unlink_group_clients: API_ENDPOINT + "web/unlinkgroupclients",
    check_unlinked_group_clients: API_ENDPOINT + "web/checkunlinkedgroupclients/",
    check_linked_group_clients: API_ENDPOINT + "web/checklinkedgroupclients/",
    update_unlinked_group_clients: API_ENDPOINT + "web/updateunlinkedgroupclients",
    get_next_available_dates: API_ENDPOINT + "/getnextavailabledates",
    get_client_policy_agreement: API_ENDPOINT + "web/getclientpolicyagreement/",
    save_client_stylist_link: API_ENDPOINT + "web/saveclientstylistlink",
    get_customer_detalis: API_ENDPOINT + "web/getcustomerdetalis",
    check_customer_cards: API_ENDPOINT + "web/checkcustomercards",
    get_square_customer_cards: API_ENDPOINT + "web/square/getcustomercards",
    get_square_locations: API_ENDPOINT + "web/square/getlocations",
    save_square_customer_card: API_ENDPOINT + "web/square/savecustomercard",
    charge_square_payment: API_ENDPOINT + "web/square/chargepayment",
    get_paypal_customer_cards: API_ENDPOINT + "web/paypal/getcustomercards",
    save_paypal_customer_card: API_ENDPOINT + "web/paypal/savecustomercard",
    charge_paypal_payment: API_ENDPOINT + "web/paypal/chargepayment",
    get_paypal_client_token: API_ENDPOINT + "web/paypal/getclienttoken",
    verify_existing_user: API_ENDPOINT + "web/verifyexistinguser",
    save_card_token: API_ENDPOINT + "web/savecardtoken",
    set_default_card: API_ENDPOINT + "web/setdefaultcard",
    delete_card: API_ENDPOINT + "web/deletecard",
    save_stylist_policy_agreement: API_ENDPOINT + "web/savestylistpolicyagreement",
    send_phone_verify_code: API_ENDPOINT + "web/sendphoneverifycode",
    stripe_charge: API_ENDPOINT + "web/stripecharge",
    get_stripe_setup_intent: API_ENDPOINT + "web/getstripesetupintent",
    get_stripe_payment_intent: API_ENDPOINT + "web/getstripepaymentintent",
    get_stripe_payment_methods: API_ENDPOINT + "web/getstripepaymentmethods",
    delete_stripe_payment_method: API_ENDPOINT + "web/deletestripepaymentmethod",
    set_default_stripe_payment_method: API_ENDPOINT + "web/setdefaultstripepaymentmethod",
    save_payment_details: API_ENDPOINT + "web/savepaymentdetails",
    save_stripe_subscription: API_ENDPOINT + "web/savestripesubscription",
    cancel_stripe_subscription: API_ENDPOINT + "web/cancelstripesubscription",
    get_stylist_cards: API_ENDPOINT + "web/getstylistcards",
    add_new_group_member: API_ENDPOINT + "web/addnewgroupmember",
    update_group_member: API_ENDPOINT + "web/updategroupmember",
    set_device_info: API_ENDPOINT + "web/setdeviceinfo",
    save_stylist_group_name: API_ENDPOINT + "web/savestylistgroupname",
    get_campaign_payment_intent: API_ENDPOINT + "web/getcampaignpaymentintent",
    charge_marketing_campaign_payment: API_ENDPOINT + "web/braintree/chargemarketingcampaignpayment",
    save_signature: API_ENDPOINT + "web/savesignature",
    offline_charge: API_ENDPOINT + "web/offlinecharge",
    get_client_appointments_history: API_ENDPOINT + "web/getclientappointmentshistory",

    get_braintree_client_token: API_ENDPOINT + "web/braintree/getclienttoken",
    save_braintree_customer_card: API_ENDPOINT + "web/braintree/savesubscriptioncustomercard",
    get_braintree_customer_cards: API_ENDPOINT + "web/braintree/getsubscriptioncustomercards",
    set_default_braintree_customer_card: API_ENDPOINT + "web/braintree/setdfaultsubscriptioncustomercard",
    delete_braintree_customer_cards: API_ENDPOINT + "web/braintree/deletesubscriptioncustomercard",
    save_braintree_subscription: API_ENDPOINT + "web/braintree/savebraintreesubscription",
    cancel_braintree_subscription: API_ENDPOINT + "web/braintree/cancelbraintreesubscription",
    get_braintree_customer_card_nonce: API_ENDPOINT + "web/braintree/getsubscriptioncustomercardnonce",
    save_marketing_campaign_payment_method: API_ENDPOINT + "web/savemarketingcampaignpaymentmethod",
    save_referral_code: API_ENDPOINT + "web/savereferralcode",
    get_referred_users: API_ENDPOINT + "web/getreferredusers",
    get_referral_payouts: API_ENDPOINT + "web/getreferralpayouts",
    check_referral_code: API_ENDPOINT + "web/checkreferralcode",
    check_existing_user_data: API_ENDPOINT + "web/checkexistinguserdata",
    get_login_url_by_user_email: API_ENDPOINT + "web/getloginurlbyuseremail",
    get_profile_qr: API_ENDPOINT + "web/getprofileqr",
    get_clients_number: API_ENDPOINT + "web/getclientsnumber",
    get_payments_client: API_ENDPOINT + "web/getstripedetailsclient",
    get_appt_reports: API_ENDPOINT + "web/getapptreports",
    get_stripe_charges_list: API_ENDPOINT + "web/getstripechargeslist",
    get_statements: API_ENDPOINT + "web/getstatements",
    refund_stripe_charge: API_ENDPOINT + "web/refundstripecharge",

    get_stylist_policy: API_ENDPOINT + "web/getstylistpolicy",
    save_stylist_policy: API_ENDPOINT + "web/savestylistpolicy",
    save_client_fee_options: API_ENDPOINT + "web/saveclientfeeoptions",
    get_client_fee_options: API_ENDPOINT + "web/getclientfeeoptions/",
    send_statements_to_email: API_ENDPOINT + "web/sendstatementstoemail",

    get_translation: API_ENDPOINT + "web/gettranslation/web",
    get_various_variables: API_ENDPOINT + "web/getvariousvariables",
    save_user_profile_active: API_ENDPOINT + "web/saveuserprofileactive",

    save_profile_image: API_ENDPOINT + "web/saveprofileimage",
    save_stylist_group_image: API_ENDPOINT + "web/savestylistgroupimage",
    save_import_client_file: API_ENDPOINT + "web/saveimportclientfile",
    reset_password: API_ENDPOINT + "web/resetpassword",
    set_password: API_ENDPOINT + "web/setpassword",
    get_unavailable_dates: API_ENDPOINT + "web/getunavailabledates",
    save_paypal_api_credentials: API_ENDPOINT + "web/paypal/saveapicredentials",
    save_square_api_credentials: API_ENDPOINT + "web/square/saveapicredentials",
    delete_square_customer_card: API_ENDPOINT + "web/square/deletecustomercard",
    refund_square_payment: API_ENDPOINT + "web/square/refundpayment",

    save_accept_payments: API_ENDPOINT + "web/saveacceptpayments",
    set_multiple_service_order: API_ENDPOINT + "web/setmultipleserviceorder",
    block_client: API_ENDPOINT + "web/blockclient",
    send_team_info_email: API_ENDPOINT + "web/sendteaminfoemail",
    save_currency: API_ENDPOINT + "web/savecurrency",

    get_paypal_api_credentials: API_ENDPOINT + "web/paypal/getapicredentials",
    get_square_api_credentials: API_ENDPOINT + "web/square/getapicredentials",
    get_stripe_api_credentials: API_ENDPOINT + "web/getstripeapicredentials",
    get_stylist_clients: API_ENDPOINT + "web/getstylistclients",
    get_stylist_services: API_ENDPOINT + "web/getstylistservices",
    save_member_color: API_ENDPOINT + "web/savemembercolor",

    get_last_custom_notification: API_ENDPOINT + "web/getlastcustomnotification",
    send_custom_notification: API_ENDPOINT + "web/sendcustomnotification",
    get_special_offer: API_ENDPOINT + "web/getspecialoffer",
    save_special_offer: API_ENDPOINT + "web/savespecialoffer",

    remove_team_member: API_ENDPOINT + "web/removeteammember",
    self_remove_from_team: API_ENDPOINT + "web/selfremovefromteam",

    get_services_preview: API_ENDPOINT + "/web/getservicespreview",

    get_book_now_settings: API_ENDPOINT + "widget/getbooknowsettings",
    save_book_now_settings: API_ENDPOINT + "widget/savebooknowsettings",

    get_profile_widget_settings: API_ENDPOINT + "widget/getprofilewidgetsettingsadmin",
    save_profile_widget_settings: API_ENDPOINT + "widget/saveprofilewidgetsettings",

    send_message_to_support: API_ENDPOINT + "web/sendmessagetosupport",
    get_user_support_messages: API_ENDPOINT + "web/getusersupportmessages",

    get_marketing_campaign_settings: API_ENDPOINT + "web/getmarketingcampaignsettings",
    send_marketing_campaign_message: API_ENDPOINT + "web/sendmarketingcampaignmessage",
    get_marketing_campaign_log: API_ENDPOINT + "web/getmarketingcampaignlog",
    get_next_days_available_slots: API_ENDPOINT + "web/getnextdaysavailableslots",
    get_unread_support_messages_count: API_ENDPOINT + "web/getunreadsupportmessagescount",
    set_support_message_read: API_ENDPOINT + "web/setsupportmessageread",
    mark_read_all_messages: API_ENDPOINT + "web/markreadallmessages",
    get_marketing_campaign_log_sms: API_ENDPOINT + "web/getmarketingcampaignlogsms",
    save_autoreload_sms_settings: API_ENDPOINT + "web/saveautoreloadsmssettings",

    save_calendar_view: API_ENDPOINT + "web/savecalendarview",
    get_calendar_view: API_ENDPOINT + "web/getcalendarview",
    get_signatures: API_ENDPOINT + "web/getsignatures/",
    get_signatures_payment: API_ENDPOINT + "web/getsignaturespayment/",
    view_signature: API_ENDPOINT + "web/viewsignature",
};
