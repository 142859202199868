<template>
  <div>
    <div v-if="!isShortLogin" class="header site-top top-front">
      <div class="site-top-inner-wrap">
        <div class="site-top-inner">
          <a
            v-on:click="onMobileMenuToggle"
            href="#"
            role="button"
            class="nav-link mobile-bamburger"
            ><i class="fa fa-bars"></i
          ></a>

          <router-link :to="{ path: '/' }" class="header-logo-link">
            <img
              class="site-top-logo"
              src="../assets/images/rms-logo.png"
              alt=""
              v-if="generalOptions.app_id == 1"
            />
            <img
              class="site-top-logo"
              src="../assets/images/rmb-logo.png"
              alt=""
              v-if="generalOptions.app_id == 2"
            />
            <img
              class="site-top-logo"
              src="../assets/images/rmg-logo.png"
              alt=""
              v-if="generalOptions.app_id == 3"
            />
            <img
              class="site-top-logo"
              src="../assets/images/rml-logo.png"
              alt=""
              v-if="generalOptions.app_id == 5"
            />
            <span class="app-name">{{ generalOptions.app_name }}</span>
          </router-link>

          <div class="sign-in-block">
            <ul class="top-nav-menu desktop-nav">
              <li v-if="!user">
                <router-link
                  class="top-menu-link"
                  :to="{ path: '/app/register' }"
                  >List Your Business</router-link
                >
              </li>
              <li>
                <router-link
                  class="top-menu-link"
                  :to="{ path: '/app/login' }"
                  >{{ user ? "App" : "Login" }}</router-link
                >
              </li>
              <!-- <li><a href="#">Prices</a></li> -->
              <li>
                <a :href="generalOptions.blog_url" target="_blank">Blog</a>
              </li>
              <li>
                <a :href="generalOptions.help_url" target="_blank">Help</a>
              </li>
            </ul>
            <ul class="top-nav-menu mobile-nav">
              <li>
                <router-link
                  class="top-menu-link"
                  :to="{ path: '/app/login' }"
                  >{{ user ? "App" : "Login" }}</router-link
                >
              </li>
              <li v-if="!user">
                <router-link
                  class="top-menu-link"
                  :to="{ path: '/app/register' }"
                  >Register</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mobile-hamburger-menu"
      :class="{ active: showMobileMenu, visible: mobileMenuClass }"
    >
      <ul>
        <li v-on:click="onMobileMenuToggle" v-if="!user">
          <router-link class="top-menu-link" :to="{ path: '/app/register' }"
            >List Your Business</router-link
          >
        </li>
        <li v-on:click="onMobileMenuToggle">
          <router-link class="top-menu-link" :to="{ path: '/app/login' }">{{
            user ? "App" : "Login"
          }}</router-link>
        </li>
        <!-- <li><a href="#">Prices</a></li> -->
        <li><a :href="generalOptions.blog_url" target="_blank">Blog</a></li>
        <li><a :href="generalOptions.help_url" target="_blank">Help</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import auth from "./../services/auth.js";
import eventBus from "./../services/event_bus.js";

export default {
  name: "Header",
  data: function () {
    return {
      stylist_id: 0,
      stylist_alias: "",
      user: null,
      isShortLogin: false,
      showMobileMenu: false,
      mobileMenuClass: false,
    };
  },
  mounted: function () {
    this.stylist_id = auth.stylist_id;
    this.stylist_alias = auth.stylist_alias;
    this.user = auth.user;
    this.isShortLogin = this.$route.fullPath == "/login";

    eventBus.$on("new_profile_loaded", this.newProfileLoaded);
  },
  beforeDestroy() {
    eventBus.$off("new_profile_loaded", this.newProfileLoaded);
  },
  methods: {
    newProfileLoaded: function (stylist_alias) {
      this.stylist_alias = stylist_alias;
    },
    onMobileMenuToggle() {
      if (!this.showMobileMenu) {
        this.showMobileMenu = true;

        setTimeout(() => {
          this.mobileMenuClass = true;
        }, 50);
        return;
      }

      this.mobileMenuClass = false;

      setTimeout(() => {
        this.showMobileMenu = false;
      }, 500);
    },
  },
};
</script>
