<template>
    <div class="side-bar-section">
        <div class="work-location-map map-wrapper" :class="{'hidden': !marker_position}">
            <GmapMap :ref="`mapRef${id}`" 
                :center="{lat:-34.397, lng:150.644}" 
                :zoom="15" 
                :options="{
                    zoomControl:false,
                    fullscreenControl:false,
                    disableDefaultUI:false,
                    mapTypeControl:false,
                    streetViewControl:false
                }" 
                style="width: 250px; height: 250px">
                <GmapMarker v-if="marker_position" ref="mapMarkerRef" :position="marker_position" />
            </GmapMap> 
        </div>
        <div class="address-title"></div>
        <div v-if="salon.address" class="business-adress">
            <a class="business-adress-link" target="_blank" :href="`http://www.google.com/maps/place/${addressLat},${addressLng}`">
                <div>{{salon.address}}</div>
                <div>
                    <span v-if="salon.city">{{salon.city}}, </span> 
                    <span v-if="salon.state">{{salon.state}} </span> 
                    <span v-if="salon.zip">{{salon.zip}} </span> 
                    <span v-if="salon.country && ['United States', 'USA', 'US'].indexOf(salon.country) == -1">
                        {{salon.country}}
                    </span>
                </div>
            </a>
        </div>
        <div class="contact-info">
            <div v-if="salon.call_shop">{{ salon.call_shop }}</div>
            <div v-if="salon.email_shop"><a :href="'mailto:'+salon.email_shop">{{ salon.email_shop }}</a></div>
        </div>
        <div class="social-icons-wrap">
            <a v-if="twitter_url" :href="twitter_url"
                ><img class="social-icon" src="../assets/images/twitter-button.svg"
            /></a>
            <a v-if="facebook_url" :href="facebook_url"
                ><img class="social-icon" src="../assets/images/facebook-button.svg"
            /></a>
            <a v-if="instagram_url" :href="instagram_url"
                ><img class="social-icon" src="../assets/images/instagram-button.png"
            /></a>
            <a v-if="yelp_url" :href="yelp_url"
                ><img class="social-icon" src="../assets/images/yelp-button.png"
            /></a>
        </div>
        <hr>
        <div v-if="generalOptions.app_id==1" class="app-links">
            <a rel="noreferrer" href="https://itunes.apple.com/us/app/ring-my-stylist/id1250537446" target="_blank">
                <img src="../assets/images/app-store-new.png" alt="Download Ring My Stylist App from App Store">
            </a>
            <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.ringmystylist.app&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
                <img src="../assets/images/google-play-new.png" alt="Download Ring My Stylist App from Google Play">
            </a>
        </div>
        <div v-if="generalOptions.app_id==2" class="app-links">
            <a rel="noreferrer" href="https://itunes.apple.com/us/app/ring-my-barber-appointments/id1451800946" target="_blank">
                <img src="../assets/images/app-store-new.png" alt="Download Ring My Barber App from App Store">
            </a>
            <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.ringmystylist.app.rmb" target="_blank">
                <img src="../assets/images/google-play-new.png" alt="Download Ring My Barber App from Google Play">
            </a>
        </div>
        <div v-if="generalOptions.app_id==5" class="app-links">
            <a rel="noreferrer" href="https://apps.apple.com/us/app/ring-my-loctician-booking-app/id1558673025" target="_blank">
                <img class="app-store" src="../assets/images/app-store-new.png" alt="Download Ring My Loctician App from App Store">
            </a>
            <a rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.ringmystylist.app.rml" target="_blank">
                <img src="../assets/images/google-play-new.png" alt="Download Ring My Loctician App from Google Play">
            </a>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'SalonSidebarSection',
    props: [
        'salon',
        'id',
        'twitter_url',
        'facebook_url',
        'instagram_url',
        'yelp_url'
    ],
    data: function() {
        return {
            marker_position: null,
            addressLat: 0,
            addressLng: 0
        }
    },
    mounted: function() {
        _.delay(() => {
            this.initMap();
        }, 1000);
    },
    methods: {
        initMap() {
            var map = this.$refs['mapRef'+this.id];
            var geocoder = new window.google.maps.Geocoder();
            var address = this.salon.address + ', ' + this.salon.city + ', ' + this.salon.state + ', ' + this.salon.zip + ', ' + this.salon.country;
            geocoder.geocode({'address': address}, (results, status) => {
                if (status === 'OK') {
                    this.addressLat = results[0].geometry.location.lat();
                    this.addressLng = results[0].geometry.location.lng();
                    map.panTo(results[0].geometry.location);
                    this.marker_position = results[0].geometry.location;
                }
            });
        },
    }
}
</script>