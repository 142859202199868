<template>
    <div class="admin-clients text-left">
        <div class="notification-container">
            <div class="heading-container">
                <h3>
                    {{ $ml.get("notifications.notificationsSettings") }}
                </h3>
            </div>
            <div class="notification-wrapper section-wrap">
                <div class="content-wrap section-wrap">
                    <div class="content-inner-wrap">
                        <!--Timezone-->
                        <div class="select-wrap">
                            <div>
                                {{ $ml.get("notifications.Timezone") }}
                            </div>
                            <div>
                                <select class="selectInput selectInputSmall" v-model="notification_settings.timezone">
                                    <option value="0">{{ $ml.get("notifications.selectTimezone") }}</option>
                                    <option
                                        v-for="(timezone, index) in timezonesList"
                                        v-bind:key="index"
                                        v-bind:value="index"
                                    >
                                        {{ timezone }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!--Date time format-->
                        <div class="select-wrap">
                            <div>
                                {{ $ml.get("notifications.dateTimeFormat") }}
                            </div>
                            <div>
                                <select
                                    class="selectInput selectInputSmall"
                                    v-model="notification_settings.datetime_format"
                                >
                                    <option
                                        v-for="(datetime_format, index) in datetime_formats"
                                        v-bind:key="index"
                                        v-bind:value="index"
                                    >
                                        {{ datetime_format }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!--Language-->
                        <div class="select-wrap">
                            <div>
                                {{ $ml.get("notifications.Language") }}
                            </div>

                            <div>
                                <select
                                    class="selectInput selectInputSmall"
                                    v-model="notification_settings.language"
                                    v-on:change="reloadClientsNotifications"
                                >
                                    <option value="el">el</option>
                                    <option value="en">en</option>
                                    <option value="es">es</option>
                                    <option value="it">it</option>
                                    <option value="pt">pt</option>
                                    <option value="fr">fr</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!--Reload All To Default-->
                    <div class="reload-default-wrap">
                        <div v-if="this.language != this.notification_settings.language && !gettingSettings">
                            <button class="primary-btn extra-small" v-on:click="reloadALLDefaults()">
                                {{
                                    $ml
                                        .get("notifications.reloadAllDefaults")
                                        .replace(":language", notification_settings.language.toLocaleUpperCase())
                                }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--Notifications Variables-->
                <div class="notifications-variables-container section-wrap">
                    <div>
                        <strong>
                            {{ $ml.get("notifications.globalVariablesText") }}
                        </strong>
                    </div>
                    <div class="notifications-variables-content">
                        <div class="notifications-var-wrap">
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_CLIENT_FNAME')"
                                v-on:click="copyToClipboard('%CLIENT_FNAME%')"
                                class="notificationvars"
                            >
                                %CLIENT_FNAME%
                            </div>
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_CLIENT_LNAME')"
                                v-on:click="copyToClipboard('%CLIENT_LNAME%')"
                                class="notificationvars"
                            >
                                %CLIENT_LNAME%
                            </div>
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_MY_NAME')"
                                v-on:click="copyToClipboard('%MY_NAME%')"
                                class="notificationvars"
                            >
                                %MY_NAME%
                            </div>
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_SALON')"
                                v-on:click="copyToClipboard('%SALON%')"
                                class="notificationvars"
                            >
                                %SALON%
                            </div>
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_APP_DATE_TIME')"
                                v-on:click="copyToClipboard('%APP_DATE_TIME%')"
                                class="notificationvars"
                            >
                                %APP_DATE_TIME%
                            </div>
                        </div>
                        <div class="notifications-var-wrap">
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_RESCHEDULE_DATE_TIME')"
                                v-on:click="copyToClipboard('%RESCHEDULE_DATE_TIME%')"
                                class="notificationvars"
                            >
                                %RESCHEDULE_DATE_TIME%
                            </div>
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_CONFIRM_URL')"
                                v-on:click="copyToClipboard('%APPCONFIRM_URL%')"
                                class="notificationvars"
                            >
                                %APPCONFIRM_URL%
                            </div>
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_DETAILS_URL')"
                                v-on:click="copyToClipboard('%APPDETAILS_URL%')"
                                class="notificationvars"
                            >
                                %APPDETAILS_URL%
                            </div>
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_THIS_APP')"
                                v-on:click="copyToClipboard('%THIS_APP%')"
                                class="notificationvars"
                            >
                                %THIS_APP%
                            </div>
                            <div
                                v-tooltip="$ml.get('notifications.globalVariable_THIS_APP_LINK')"
                                v-on:click="copyToClipboard('%THIS_APP_LINK%')"
                                class="notificationvars"
                            >
                                %THIS_APP_LINK%
                            </div>
                        </div>
                    </div>
                </div>
                <!--When appt is put by Stylist-->
                <div class="appointments-container section-wrap">
                    <div class="section-note">
                        <span>{{ $ml.get("notifications.notifyClientsTextPart1") }}</span>
                        <span>{{ $ml.get("notifications.notifyClientsTextPart2") }}</span>
                    </div>
                    <div class="cards-container">
                        <!--First card | Section 1-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    {{ $ml.get("notifications.notifySchedule") }}
                                </div>

                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" v-model="notification_settings.schedule" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.schedule_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.schedule_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('schedule')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('schedule')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Secound Card | Section 1-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    {{ $ml.get("notifications.notifyReschedule") }}
                                </div>
                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}

                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" v-model="notification_settings.reschedule" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch m-0">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.reschedule_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.reschedule_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('reschedule')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('reschedule')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Third Card | Section 1-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    {{ $ml.get("notifications.notifyCancel") }}
                                </div>
                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" v-model="notification_settings.cancel" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.cancel_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.cancel_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('cancel')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('cancel')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Forth Card | Section 1-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    {{ $ml.get("notifications.notifyConfirm") }}
                                </div>
                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" v-model="notification_settings.confirm" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.confirm_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.confirm_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('confirm')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('confirm')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--When appt is put by Client-->
                <div class="appointments-container section-wrap">
                    <div class="section-note">
                        <span> {{ $ml.get("notifications.notifyClientsText2") }}</span>
                        <span>{{ $ml.get("notifications.notifyClientsTextPart2") }}</span>
                    </div>
                    <div class="cards-container">
                        <!--First Card | Section 2-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    {{ $ml.get("notifications.notifyScheduleClient") }}
                                </div>

                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.scheduleclient"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.scheduleclient_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.scheduleclient_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('scheduleclient')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('scheduleclient')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Secound Card | Section 2-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    {{ $ml.get("notifications.notifyRecheduleClient") }}
                                </div>

                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.rescheduleclient"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.rescheduleclient_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.rescheduleclient_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('rescheduleclient')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="runTest('rescheduleclient')"
                                        >
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Third Card | Section 2-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    {{ $ml.get("notifications.notifyCancelClient") }}
                                </div>
                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.cancelclient"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.cancelclient_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.cancelclient_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('cancelclient')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('cancelclient')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Forth Card | Section 2-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    {{ $ml.get("notifications.notifyConfirmClient") }}
                                </div>

                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.confirmclient"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.confirmclient_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.confirmclient_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('confirmclient')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('confirmclient')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--For the newly added Clients-->
                <div class="appointments-container section-wrap">
                    <div class="cards-container">
                        <!--First Card | Section 3-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    <span>{{ $ml.get("notifications.activateNotificationHoursBeforeText") }}</span>
                                </div>
                                <div class="card-body-wrap section-wrap">
                                    <div class="hours-before-appt">
                                        <select
                                            v-model="notification_settings.notification_hours"
                                            class="selectInput selectInputExtraSmall"
                                        >
                                            <option
                                                v-for="(hours, i) in notifications_max_hours"
                                                :key="`hours-${i}`"
                                                :value="hours"
                                            >
                                                {{ hours }}
                                            </option>
                                        </select>
                                        <div>{{ $ml.get("notifications.activateNotificationHoursBefore") }}</div>
                                    </div>

                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typeSMS") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.notification"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.typePush") }}
                                            <div>
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="notification_settings.notification_push"
                                                    />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.notification_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('notification')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('notification')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Secound Card | Section 3-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    <span>{{ $ml.get("notifications.inviteToDownloadAppText") }}</span>
                                </div>
                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.inviteToDownloadApp") }}
                                        </div>
                                        <div class="card-button-wrap">
                                            <label class="switch">
                                                <input type="checkbox" v-model="notification_settings.invite" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.invite_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('invite')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('invite')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Third Card | Section 3-->
                        <div class="appt-content">
                            <div class="appt-inner-wrap highlighted-box section-wrap">
                                <div class="heading-card">
                                    <span>{{ $ml.get("notifications.inviteOnScheduleToDownloadAppText") }}</span>
                                </div>
                                <div class="card-body-wrap section-wrap">
                                    <div class="card-buttons-wrapper">
                                        <div class="card-button-wrap">
                                            {{ $ml.get("notifications.extraInviteToDownloadApp") }}
                                        </div>
                                        <div class="card-button-wrap">
                                            <label class="switch">
                                                <input type="checkbox" v-model="notification_settings.extrainvite" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="card-textarea">
                                        <textarea
                                            class="text-field notificationtext"
                                            placeholder="Message"
                                            aria-label="Message"
                                            v-model="notification_settings.extrainvite_text"
                                            maxlength="255"
                                        ></textarea>
                                    </div>
                                    <div class="card-bottom-btns">
                                        <button
                                            class="primary-btn extra-small"
                                            v-on:click="reloadDefaultText('extrainvite')"
                                        >
                                            {{ $ml.get("notifications.reloadDefault") }}
                                        </button>
                                        <button class="primary-btn extra-small" v-on:click="runTest('extrainvite')">
                                            {{ $ml.get("notifications.sendTest") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="save-client-notification-btn">
                    <button class="primary-btn extra-small" v-on:click="saveClientsNotifications">
                        {{ $ml.get("general.save") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import messages from "./../../services/messages.js";

export default {
    name: "AdminNotifications",
    data: function () {
        return {
            user: null,
            stylistProfile: {},
            timezonesList: {},
            datetime_formats: {},
            notification_settings: {
                schedule: 0,
                schedule_push: 0,
                scheduleclient: 0,
                scheduleclient_push: 0,
                reschedule: 0,
                reschedule_push: 0,
                rescheduleclient: 0,
                rescheduleclient_push: 0,
                cancel: 0,
                cancel_push: 0,
                cancelclient: 0,
                cancelclient_push: 0,
                confirm: 0,
                confirm_push: 0,
                confirmclient: 0,
                confirmclient_push: 0,
                invite: 0,
                invite_onschedule: 0,
                notification: 1,
                notification_push: 1,
                notification_hours: 1,
                schedule_text: "",
                scheduleclient_text: "",
                reschedule_text: "",
                rescheduleclient_text: "",
                cancel_text: "",
                cancelclient_text: "",
                confirm_text: "",
                confirmclient_text: "",
                invite_text: "",
                invite_onschedule_text: "",
                notification_text: "",
                timezone: "",
                language: "",
                datetime_format: "",
            },
            language: "",
            notification_settings_defaults: {
                schedule_text: "",
                scheduleclient_text: "",
                reschedule_text: "",
                rescheduleclient_text: "",
                cancel_text: "",
                cancelclient_text: "",
                confirm_text: "",
                confirmclient_text: "",
                invite_text: "",
                invite_onschedule_text: "",
                notification_text: "",
            },
            notifications_max_hours: 5,
            gettingSettings: false,
        };
    },
    mounted: function () {
        this.user = auth.user;
        //let self = this;
        this.getStylistProfile();
    },
    beforeDestroy() {},
    computed: {},
    methods: {
        getTimezonesList: function () {
            this.$http.get(api.actions.get_timezoneslist + this.stylistProfile.details.profile.country_code).then(
                function (response) {
                    if (typeof response.data.timezones != "undefined") {
                        this.timezonesList = response.data.timezones;
                    }
                }.bind(this)
            );
        },
        getVariousVariables: function () {
            this.$http.get(api.actions.get_various_variables).then(
                function (response) {
                    if (typeof response.data.variables != "undefined") {
                        this.notifications_max_hours = response.data.variables.notifications_max_hours;
                    }
                }.bind(this)
            );
        },
        getClientsNotifications: function (lang_change) {
            this.gettingSettings = true;
            this.$http.get(api.actions.get_clientnotifications).then(
                function (response) {
                    this.gettingSettings = false;
                    if (typeof response.data.notification_settings != "undefined") {
                        this.notification_settings = response.data.notification_settings;
                        this.notification_settings_defaults = response.data.notification_settings_defaults;
                        this.datetime_formats = response.data.datetime_formats;

                        this.language = this.notification_settings.language;
                        if (lang_change == 1) {
                            this.language = "";
                        }

                        if (this.notification_settings.schedule_text == "")
                            this.notification_settings.schedule_text =
                                this.notification_settings_defaults.schedule_text;
                        if (this.notification_settings.scheduleclient_text == "")
                            this.notification_settings.scheduleclient_text =
                                this.notification_settings_defaults.scheduleclient_text;
                        if (this.notification_settings.reschedule_text == "")
                            this.notification_settings.reschedule_text =
                                this.notification_settings_defaults.reschedule_text;
                        if (this.notification_settings.rescheduleclient_text == "")
                            this.notification_settings.rescheduleclient_text =
                                this.notification_settings_defaults.rescheduleclient_text;
                        if (this.notification_settings.cancel_text == "")
                            this.notification_settings.cancel_text = this.notification_settings_defaults.cancel_text;
                        if (this.notification_settings.cancelclient_text == "")
                            this.notification_settings.cancelclient_text =
                                this.notification_settings_defaults.cancelclient_text;
                        if (this.notification_settings.confirm_text == "")
                            this.notification_settings.confirm_text = this.notification_settings_defaults.confirm_text;
                        if (this.notification_settings.confirmclient_text == "")
                            this.notification_settings.confirmclient_text =
                                this.notification_settings_defaults.confirmclient_text;

                        if (this.notification_settings.notification_text == "")
                            this.notification_settings.notification_text =
                                this.notification_settings_defaults.notification_text;
                        if (this.notification_settings.extrainvite_text == "")
                            this.notification_settings.extrainvite_text =
                                this.notification_settings_defaults.extrainvite_text;

                        if (this.notification_settings.invite_text == "")
                            this.notification_settings.invite_text = this.notification_settings_defaults.invite_text;
                    }
                }.bind(this)
            );
        },
        saveClientsNotifications: function () {
            this.$http.post(api.actions.save_clientnotifications, this.notification_settings).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getClientsNotifications();
                    }
                }.bind(this)
            );
        },
        reloadClientsNotifications: function () {
            this.gettingSettings = true;
            this.$http
                .post(api.actions.save_clientnotifications, { language: this.notification_settings.language })
                .then(
                    function (response) {
                        if (response.data.error == false) {
                            //messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                            this.getClientsNotifications(1);
                            this.gettingSettings = false;
                        }
                    }.bind(this)
                );
        },
        copyToClipboard: function (text) {
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            messages.successMessage(this.$ml.get("notifications.copiedToClipboardMessage"));
        },
        reloadALLDefaults: function () {
            this.language = this.notification_settings.language;
            this.notification_settings.schedule_text = this.notification_settings_defaults.schedule_text;
            this.notification_settings.scheduleclient_text = this.notification_settings_defaults.scheduleclient_text;
            this.notification_settings.reschedule_text = this.notification_settings_defaults.reschedule_text;
            this.notification_settings.rescheduleclient_text =
                this.notification_settings_defaults.rescheduleclient_text;
            this.notification_settings.cancel_text = this.notification_settings_defaults.cancel_text;
            this.notification_settings.cancelclient_text = this.notification_settings_defaults.cancelclient_text;
            this.notification_settings.confirm_text = this.notification_settings_defaults.confirm_text;
            this.notification_settings.confirmclient_text = this.notification_settings_defaults.confirmclient_text;
            this.notification_settings.invite_text = this.notification_settings_defaults.invite_text;
            this.notification_settings.notification_text = this.notification_settings_defaults.notification_text;
            this.notification_settings.extrainvite_text = this.notification_settings_defaults.extrainvite_text;
        },
        reloadDefaultText: function (notif_type) {
            if (notif_type == "schedule")
                this.notification_settings.schedule_text = this.notification_settings_defaults.schedule_text;
            if (notif_type == "scheduleclient")
                this.notification_settings.scheduleclient_text =
                    this.notification_settings_defaults.scheduleclient_text;
            if (notif_type == "reschedule")
                this.notification_settings.reschedule_text = this.notification_settings_defaults.reschedule_text;
            if (notif_type == "rescheduleclient")
                this.notification_settings.rescheduleclient_text =
                    this.notification_settings_defaults.rescheduleclient_text;
            if (notif_type == "cancel")
                this.notification_settings.cancel_text = this.notification_settings_defaults.cancel_text;
            if (notif_type == "cancelclient")
                this.notification_settings.cancelclient_text = this.notification_settings_defaults.cancelclient_text;
            if (notif_type == "confirm")
                this.notification_settings.confirm_text = this.notification_settings_defaults.confirm_text;
            if (notif_type == "confirmclient")
                this.notification_settings.confirmclient_text = this.notification_settings_defaults.confirmclient_text;
            if (notif_type == "invite")
                this.notification_settings.invite_text = this.notification_settings_defaults.invite_text;
            if (notif_type == "notification")
                this.notification_settings.notification_text = this.notification_settings_defaults.notification_text;
            if (notif_type == "extrainvite")
                this.notification_settings.extrainvite_text = this.notification_settings_defaults.extrainvite_text;
        },
        runTest: function (notif_type) {
            let SMSbody = "";
            if (notif_type == "schedule") SMSbody = this.notification_settings.schedule_text;
            if (notif_type == "scheduleclient") SMSbody = this.notification_settings.scheduleclient_text;
            if (notif_type == "reschedule") SMSbody = this.notification_settings.reschedule_text;
            if (notif_type == "rescheduleclient") SMSbody = this.notification_settings.rescheduleclient_text;
            if (notif_type == "cancel") SMSbody = this.notification_settings.cancel_text;
            if (notif_type == "cancelclient") SMSbody = this.notification_settings.cancelclient_text;
            if (notif_type == "confirm") SMSbody = this.notification_settings.confirm_text;
            if (notif_type == "confirmclient") SMSbody = this.notification_settings.confirmclient_text;
            if (notif_type == "notification") SMSbody = this.notification_settings.notification_text;
            if (notif_type == "invite") SMSbody = this.notification_settings.invite_text;
            if (notif_type == "extrainvite") SMSbody = this.notification_settings.extrainvite_text;

            let salon = this.stylistProfile.details.profile.business_name;
            if (this.stylistProfile.details.group_name != "") salon = this.stylistProfile.details.group_name;

            let app_id = this.generalOptions.app_id;
            let link_type = "stylist";
            let app_name = this.generalOptions.app_name;

            if (app_id == 2) link_type = "barber";
            if (app_id == 3) link_type = "groomer";
            if (app_id == 5) link_type = "loctician";

            SMSbody = SMSbody.replace("%CLIENT_FNAME%", "John");
            SMSbody = SMSbody.replace("%CLIENT_LNAME%", "Smith");
            SMSbody = SMSbody.replace("%MY_NAME%", this.user.first_name + " " + this.user.last_name);
            SMSbody = SMSbody.replace("%SALON%", salon);
            SMSbody = SMSbody.replace(
                "%APP_DATE_TIME%",
                this.datetime_formats[this.notification_settings.datetime_format]
            );
            SMSbody = SMSbody.replace(
                "%RESCHEDULE_DATE_TIME%",
                this.datetime_formats[this.notification_settings.datetime_format]
            );
            SMSbody = SMSbody.replace("%APPCONFIRM_URL%", "https://www.ringmy" + link_type + ".com/confirm/OA%3D%3D");
            SMSbody = SMSbody.replace("%APPDETAILS_URL%", "https://www.ringmy" + link_type + ".com/a/OA%3D%3D");
            SMSbody = SMSbody.replace("%THIS_APP%", app_name);
            SMSbody = SMSbody.replace("%THIS_APP_LINK%", "https://ringmy" + link_type + ".com/openapp");

            var data = {
                to_user_id: this.user.id,
                text: SMSbody,
            };

            this.$http.post(api.actions.send_sms, data).then((res) => {
                if (!res.data.error) {
                    messages.successMessage(messages.GLOBAL_MESSAGES.MESSAGE_SENT);
                }
            });
        },
        getStylistProfile: function () {
            this.$http.get(api.actions.get_profile).then(
                function (response) {
                    if (typeof response.data.user != "undefined") {
                        this.stylistProfile = response.data.user;
                        this.getTimezonesList();
                        this.getClientsNotifications();
                        this.getVariousVariables();
                    }
                }.bind(this)
            );
        },
    },
    components: {},
};
</script>
