<template>
    <div class="admin-reset-password-component container">
        <div class="reset-password-form-wrapper">
            <div class="reset-password-form-inner row">
                <div v-if="requestCodeScreen==''" class="login-section form-section">
                    <div class="login-form-row login-phone-row text-center">
                        <input v-model="login_phone_mask" v-mask="'(###) ###-####'" type="text" name="phone" placeholder="Mobile Phone Number" class="login-phone-input">
                        <span class="input-stripe-border"></span>
                    </div>
                    <div class="text-center login-form-row">
                        <button type="button" class="reset-password-button" @click="onRequestCode" tabindex="10">Request Code</button>
                    </div>
                    <div class="text-center">
                        <span class="action-link" v-on:click="onBack">Back to Login</span>
                    </div>
                    <div v-if="phone_check_message" v-html="phone_check_message"></div>
                </div>

                <div v-if="requestCodeScreen=='verify-phone'" class="verify-phone-section form-section">
                    <p>To confirm the identity of your account ( associated with {{ register_phone }}) we sent you an SMS with a validation code.</p>

                    <div class="verifiy-code-fields text-center">
                        <input type="text" v-model="phone_check_code" class="phone-code-field" autocomplete="off" v-on:keyup.13="checkVerificationCode">
                    </div>
                    <div class="text-center">
                        <button id="check-verification-code" type="button" class="reset-password-button" @click="checkVerificationCode" tabindex="10">Continue</button>

                        <div class="book-action-links">
                            <a class="book-action-link" href="#" @click="verifyPhoneNumber">Resend Code</a>
                            <span class="link-separator">|</span>
                            <a class="book-action-link" href="#" @click="requestCodeScreen=''">Edit Number</a>
                        </div>
                    </div>
                    <div v-if="phone_check_message" v-html="phone_check_message"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import auth from './../../services/auth.js';
import api from './../../services/api_endpoints.js';
import eventBus from './../../services/event_bus.js';
import VueMask from 'v-mask';

Vue.use(VueMask);

export default {
    name: 'PhoneLogin',
    emits: ['back'],
    data: function(){
        return {
            email: '',
            requestCodeScreen: '',
            login_phone_mask: '',
            phonePrefix: '',
            register_phone: '',
            phone_check_message: '',
            phone_check_code: '',
        };
    },
    mounted: function () {
        if(auth.user){
            this.$router.push('/app');
        }

        eventBus.$on('user_profile_loaded', this.onLoggedIn);
    },
    beforeDestroy () {
        eventBus.$off('user_profile_loaded', this.onLoggedIn);
    },
    computed: {
    },
    methods: {
        onRequestCode() {
            var data = {
                phone: this.login_phone_mask.replace(/\D/g,'')
            };

            this.$http.post(api.actions.verify_existing_user, data).then(response => {
                if(response.error == true){
                    this.phone_check_message = '<div class="alert alert-danger">Error checking the phone</div>';
                    return;
                }

                if(response.data.error == true) {
                    this.phone_check_message = '<div class="alert alert-danger">'+response.data.message+'</div>';
                    return;
                }

                if(response.data.exists == true){
                    this.existing_user = true;
                    this.phonePrefix = response.data.phone_prefix;
                    this.register_phone = response.data.phone;
                    this.verifyPhoneNumber();
                } else {
                    this.phone_check_message = '<div class="alert alert-danger">The phone does not match any user</div>';
                }
            });
        },
        verifyPhoneNumber() {
            var data = {
                'phone_prefix': this.phonePrefix,
                'phone': this.register_phone
            };

            this.phone_check_message = '';

            this.$http.post(api.actions.send_phone_verify_code, data).then(response => {
                if(response.error == true){
                    this.phone_check_message = '<div class="alert alert-danger">There is an error</div>';
                    return;
                }

                if(response.data.error==true) {
                    this.phone_check_message = '<div class="alert alert-danger">'+response.data.message+'</div>';
                }

                this.phone_check_message = '<div class="alert alert-success">The code has been sent</div>';
                this.requestCodeScreen = 'verify-phone';
            });
        },
        checkVerificationCode() {
            var data = {
                phone: this.register_phone,
                code: this.phone_check_code,
                passport: 1
            }

            this.$http.post(api.actions.verify_phone_code,data).then(response => {
                if(response.error == true){
                    this.phone_check_message = '<div class="alert alert-danger">There is an error</div>';
                    return;
                }

                if(response.data.error==true) {
                    this.phone_check_message = '<div class="alert alert-danger">'+response.data.message+'</div>';
                } else {
                    this.phone_verified = true;

                    if(response.data.login){
                        auth.logout(false);
                        auth.onLogin(response.data.login, 1);
                        auth.getProfile(true);
                     } else {
                        this.phone_check_message = '<div class="alert alert-danger">There is an error</div>';
                        return;
                    }
                }
            });
        },
        onLoggedIn() {
            this.$router.push('/app');
        },
        onBack() {
            this.$emit('back');
        }
    },
}
</script>