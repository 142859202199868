<template>
  <div class="contact-component text-left">
    <h3>Reach Out and Learn More About The Ring My Stylist Difference!</h3>
    <p>Do you want to know more about the Ring My Stylist App? Do you wish to make a complaint or suggest an improvement? Or perhaps you just want to tell us how helpful the Ring My Stylist App has been to your business. Regardless of your purpose, we’d definitely love to hear from you!</p>

    <h3>Here are the options for contacting us:</h3>
    <p>Email Address: We are always excited to get emails, so feel free to write to us at: info@ringmystylist.com</p>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {
  }
}
</script>
