<template>
    <div class="client-payments-component">
        <div v-if="isSectionVisible('credit-cards')" class="credit-cards">
            <h3>{{ $ml.get("adminMenu.creditCards") }}</h3>
            <div v-if="stylistsLoaded && paymentStylists.length > 0 && !addingCard" class="stylists-wrap section-wrap">
                <div
                    v-for="(stylist, i) in paymentStylists"
                    :key="`stylist-${i}`"
                    class="client-stylist-row section-wrap highlighted-box"
                >
                    <div v-if="stylist.stripe_authorized || stylist.square_authorized">
                        <div class="top-wrap">
                            <div class="name">{{ stylist.first_name }} {{ stylist.last_name }}</div>
                            <span
                                v-on:click="
                                    addNewCard(stylist);
                                    scrollToTop();
                                "
                                class="primary-btn extra-small mobile"
                            >
                                {{ $ml.get("clientPayment.addCard") }}</span
                            >
                        </div>
                        <div class="cards-wrap">
                            <div v-if="cardsLoaded || stripeCards[stylist.id + '']" class="stripe-cards">
                                <div
                                    v-for="(card, i) in stripeCards[stylist.id + '']"
                                    :key="`card-${stylist.id}-${i}`"
                                    class="card-wrap"
                                >
                                    <div class="card-brand-img">
                                        <img
                                            v-if="card.card.brand == 'visa'"
                                            src="../../assets/images/visa-logo.png"
                                            class="card-logo"
                                        />
                                        <img
                                            v-if="card.card.brand == 'mastercard'"
                                            src="../../assets/images/mastercard-logo.png"
                                            class="card-logo"
                                        />
                                        <img
                                            v-if="card.card.brand == 'discover'"
                                            src="../../assets/images/discover-logo.png"
                                            class="card-logo"
                                        />
                                        <img
                                            v-if="card.card.brand == 'amex'"
                                            src="../../assets/images/americanexpress-logo.png"
                                            class="card-logo"
                                        />
                                    </div>
                                    <div class="card-detail">
                                        <div class="card-brand">{{ card.card.brand }}</div>
                                        <div class="card-last4">**** **** **** {{ card.card.last4 }}</div>
                                        <div class="card-exp">
                                            <span>{{ card.card.exp_month }}/{{ card.card.exp_year }}</span>
                                        </div>
                                    </div>
                                    <div class="card-actions">
                                        <span
                                            v-on:click="deleteCard(card.id, stylist, 'stripe')"
                                            class="card-delete-button"
                                            ><i aria-hidden="true" class="fa fa-trash-o"></i
                                        ></span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="cardsLoaded || squareCards[stylist.id + '']" class="square-cards">
                                <div
                                    v-for="(card, i) in squareCards[stylist.id + '']"
                                    :key="`card-${stylist.id}-${i}`"
                                    class="card-wrap"
                                >
                                    <div class="card-brand-img">
                                        <img
                                            v-if="card.card_brand == 'VISA'"
                                            src="../../assets/images/visa-logo.png"
                                            class="card-logo"
                                        />
                                        <img
                                            v-if="card.card_brand == 'MASTERCARD'"
                                            src="../../assets/images/mastercard-logo.png"
                                            class="card-logo"
                                        />
                                        <img
                                            v-if="card.card_brand == 'DISCOVER'"
                                            src="../../assets/images/discover-logo.png"
                                            class="card-logo"
                                        />
                                        <img
                                            v-if="card.card_brand == 'AMERICAN_EXPRESS'"
                                            src="../../assets/images/americanexpress-logo.png"
                                            class="card-logo"
                                        />
                                    </div>
                                    <div class="card-detail">
                                        <div class="card-brand">{{ card.card_brand }}</div>
                                        <div class="card-last4">**** **** **** {{ card.last_4 }}</div>
                                        <div class="card-exp">
                                            <span> {{ $ml.get("clientPayment.squareCard") }}</span>
                                            <span>{{ card.exp_month }}/{{ card.exp_year }}</span>
                                        </div>
                                    </div>
                                    <div class="card-actions">
                                        <span
                                            v-on:click="deleteCard(card.id, stylist, 'square')"
                                            class="card-delete-button"
                                            ><i aria-hidden="true" class="fa fa-trash-o"></i
                                        ></span>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    cardsLoaded &&
                                    ((squareCards[stylist.id + ''] && squareCards[stylist.id + ''].length == 0) ||
                                        (stripeCards[stylist.id + ''] && stripeCards[stylist.id + ''].length == 0))
                                "
                                class="no-cards section-wrap"
                            >
                                {{ $ml.get("clientPayment.addOneCard") }}
                            </div>
                            <div v-if="!cardsLoaded" class="no-cards">{{ $ml.get("clientPayment.loadingCards") }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="addingCard" class="adding-card-wrap section-wrap">
                <div class="section-wrap">
                    <div
                        v-if="addingCardUser.stripe_authorized"
                        class="client-card-form section-wrap"
                        ref="card_form"
                    ></div>
                    <div
                        v-if="addingCardUser.square_authorized"
                        class="client-card-form card-form-square"
                        ref="card_form_square"
                    >
                        <div id="card-container"></div>
                    </div>
                    <div class="card-errors">{{ cards_errors }}</div>
                    <div class="card-logos">
                        <img class="logos" src="../../assets/images/card-logos.png" />
                        <img class="verified" src="../../assets/images/verified-secured.jpg" />
                    </div>
                </div>

                <div class="card-btns">
                    <div>
                        <button class="primary-btn extra-small mobile" v-on:click="saveCard()" :disabled="loading">
                            <span v-if="!loading">{{ $ml.get("clientPayment.saveCard") }}</span>
                            <span v-if="loading">{{ $ml.get("clientPayment.saving") }}</span>
                        </button>
                    </div>
                    <div>
                        <button class="secondary-btn small mobile" v-on:click="cancelCard" :disabled="loading">
                            {{ $ml.get("clientPayment.cancel") }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-wrap no-stylist-cards" v-if="stylistsLoaded && paymentStylists.length == 0">
                <div class="search-stylist-text" v-if="stylistsLoaded && paymentStylists.length == 0">
                    <p>You don't have any active stylists yet.</p>
                </div>
                <div class="search-stylist-text">
                    Click
                    <a class="main-text-link" href="https://book.ringmystylist.com/" target="_blank">here</a> to search
                    for stylists, barbers, or locticians.
                </div>
            </div>
        </div>
        <div v-if="isSectionVisible('transactions')" class="transactions">
            <h3>{{ $ml.get("clientPayment.transactions") }}</h3>
            <div class="section-wrap">
                <div v-if="paymentsLoaded && payments.length == 0" class="no-transactions">
                    {{ $ml.get("clientPayment.noTransactions") }}
                </div>
                <div v-if="paymentsLoaded && payments.length > 0" class="transactions-list">
                    <div
                        v-for="(trans, index) in paymentsItems"
                        :key="`transaction${index}`"
                        class="transaction-item section-wrap highlighted-box"
                    >
                        <div class="left-col">
                            <div class="trans-name">{{ trans.name }}</div>
                            <div class="trans-date">{{ trans.date }}</div>
                            <div class="trans-date">{{ trans.payment_type_text }}</div>
                            <div
                                v-if="trans.appointment_id != 0"
                                v-on:click="openAppointmentDetails(trans.appointment_id)"
                               class="primary-btn extra-small mobile"
                            >
                                {{ $ml.get("payments.appointmentId") }}
                                {{ trans.appointment_id }}
                            </div>
                        </div>
                        <div class="right-col">
                            <div class="trans-amount">{{ trans.amount }}</div>
                            <div v-if="trans.status != 'refunded'" class="trans-payment-type">
                                {{ trans.payment_type }}
                            </div>
                            <div v-if="trans.status == 'refunded'" class="trans-payment-type">{{ trans.status }}</div>
                            <div>
                                <div
                                    v-if="trans.signature != ''"
                                    class="primary-btn extra-small mobile"
                                    data-toggle="modal"
                                    data-target="#retriveSignature"
                                    @click="openSignatureModal(trans)"
                                >
                                    Retrieve signature
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="load-more">
                    <div class="primary-btn extra-small mobile" v-if="paymentsHasMoreItems" @click="paymentsLoadMore">
                        {{ $ml.get("payments.loadMore") }}
                    </div>
                </div>
            </div>
        </div>
        <!--Retrive signature modal-->
        <div
            class="modal fade"
            id="retriveSignature"
            tabindex="-1"
            role="dialog"
            aria-labelledby="retriveSignatureLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="retriveSignatureLabel">{{ currentTransaction.name }} Signature</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body signature-container">
                        <div class="signature-wrap section-wrap">
                            <div class="signature-image">
                                <img :src="viewSignature(currentTransaction.signature)" alt="signature img" />
                            </div>
                            <div class="signature-download">
                                <a
                                    :href="viewSignature(currentTransaction.signature) + '&download=1'"
                                    download
                                    class="primary-btn extra-small mobile"
                                    >Download</a
                                >
                            </div>
                        </div>
                        <div v-if="currentTransaction.signature == ''" class="signature-wrap section-wrap">
                            No signature found
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import hash from "hash.js";
import _ from "lodash";

export default {
    name: "ClientPayments",
    props: ["sections"],
    data: function () {
        return {
            user: null,
            payments: [],
            paymentsLoaded: false,
            stylistsLoaded: false,
            cardsLoaded: false,
            addingCard: false,
            addingCardUser: {},
            stripe: null,
            stripe_card: null,
            square_card: null,
            cards_errors: "",
            stripe_client_secret: "",
            getting_stripe_client_secret: false,
            square_application_id: "",
            suqare_location_id: "",
            square_card_obj: null,
            loading: false,

            paymentsItems: [],
            paymentsLimit: 10,
            paymentsOffset: 0,
            paymentsHasMoreItems: false,
            currentTransaction: {},
        };
    },
    mounted: function () {
        this.user = auth.user;
        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        paymentStylists: {
            get: function () {
                return store.state.paymentStylists;
            },
            set: function (value) {
                store.commit("setPaymentStylists", value);
            },
        },
        loadingPaymentStylists: {
            get: function () {
                return store.state.loadingPaymentStylists;
            },
            set: function (value) {
                store.commit("setLoadingPaymentStylists", value);
            },
        },
        squareCards: {
            get: function () {
                return store.state.squareCards;
            },
            set: function (value) {
                store.commit("setSquareCards", value);
            },
        },
        stripeCards: {
            get: function () {
                return store.state.stripeCards;
            },
            set: function (value) {
                store.commit("setStripeCards", value);
            },
        },
        totalCards: {
            get: function () {
                return store.state.totalCards;
            },
            set: function (value) {
                store.commit("setTotalCards", value);
            },
        },
        clientPaymentsTotal: {
            get: function () {
                return store.state.clientPaymentsTotal;
            },
            set: function (value) {
                store.commit("setClientPaymentsTotal", value);
            },
        },
    },
    methods: {
        calculateMoreItems() {
            const keys = Object.keys(this.payments);
            this.paymentsHasMoreItems = !this.allItemsLoaded && this.paymentsOffset + this.paymentsLimit < keys.length;
        },
        paymentsLoadMore() {
            this.paymentsItems = _.slice(this.payments, 0, this.paymentsOffset + this.paymentsLimit);

            this.paymentsOffset += this.paymentsLimit;

            if (this.paymentsOffset >= this.payments.length) {
                this.allItemsLoaded = true;
            }

            this.calculateMoreItems();
        },
        isSectionVisible(section) {
            if (!this.sections) {
                return true;
            }

            if (this.sections.indexOf(section) != -1) {
                return true;
            }

            return false;
        },
        onAdminDetailsLoad: function () {
            this.getPaymentsList();
            this.getStylists();
        },
        getPaymentsList: function () {
            this.$http.post(api.actions.get_payments_client, { limit: 100 }).then(
                function (response) {
                    this.paymentsLoaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.orders != "undefined" &&
                        response.body.orders.length > 0
                    ) {
                        this.payments = response.body.orders;
                        this.paymentsLoadMore();
                        this.calculateMoreItems();
                        this.clientPaymentsTotal = response.body.orders.length;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStylists: function () {
            if (this.loadingPaymentStylists) {
                return;
            }

            this.paymentStylists = [];
            this.loadingPaymentStylists = true;
            this.totalCards = 0;
            this.$http.get(api.actions.get_stylists).then(
                function (response) {
                    this.stylistsLoaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.stylists != "undefined"
                    ) {
                        this.loadingPaymentStylists = false;
                        response.body.stylists.forEach((stylist) => {
                            if (stylist.stripe_authorized || stylist.square_authorized) {
                                this.paymentStylists.push(stylist);
                                this.getUserCards(stylist);
                            }
                        });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStripeSetupIntent() {
            if (this.getting_stripe_client_secret) {
                return;
            }

            this.getting_stripe_client_secret = true;

            this.$http
                .post(api.actions.get_stripe_setup_intent, {
                    user_id: this.user.id,
                    stylist_id: this.addingCardUser.id,
                })
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    this.stripe_client_secret = response.data.client_secret;
                    this.getting_stripe_client_secret = false;
                });
        },
        displayCardForm() {
            let self = this;

            async function initializeCard(payments) {
                const card = await payments.card();
                await card.attach("#card-container");

                return card;
            }

            function loadSquareForm() {
                const payments = window.Square.payments(self.addingCardUser.square_app_id, self.suqare_location_id);

                try {
                    self.square_card_obj = initializeCard(payments);
                } catch (e) {
                    self.cards_errors = "Initializing Card failed";
                    return;
                }
            }

            //Generate the Stripe card form
            if (this.addingCardUser.stripe_authorized) {
                if (!this.$refs.card_form) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (!this.addingCardUser.stripe_public_key) {
                    return;
                }

                this.stripe = window.Stripe(this.addingCardUser.stripe_public_key);
                var elements = this.stripe.elements();
                this.stripe_card = elements.create("card");
                this.stripe_card.mount(this.$refs.card_form);

                this.stripe_card.addEventListener("change", (event) => {
                    if (event.error) {
                        this.cards_errors = event.error.message;
                    } else {
                        this.cards_errors = "";
                    }
                });

                this.getStripeSetupIntent();

                //Generate the Square card Form
            } else if (this.addingCardUser.square_authorized) {
                if (!this.$refs.card_form_square) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                this.$http
                    .post(api.actions.get_square_locations, { stylist_id: this.addingCardUser.id })
                    .then((response) => {
                        if (response.data.error == true || !response.data.locations) {
                            this.cards_errors = "Error receiving the Square location";
                            return;
                        }

                        this.suqare_location_id = response.data.locations[0].id;
                        loadSquareForm();
                    });
            }
        },
        saveCard() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.cards_errors = "";
            let self = this;
            async function tokenize(paymentMethod) {
                const tokenResult = await paymentMethod.tokenize();
                if (tokenResult.status === "OK") {
                    return tokenResult.token;
                }
            }

            //Save the card in Stripe
            if (this.addingCardUser.stripe_authorized) {
                this.stripe
                    .confirmCardSetup(this.stripe_client_secret, {
                        payment_method: {
                            card: this.stripe_card,
                            billing_details: {},
                        },
                    })
                    .then((result) => {
                        if (result.error) {
                            this.cards_errors = result.error.message;
                            this.loading = false;
                            return;
                        } else {
                            this.has_card = true;
                            this.loading = true;
                            this.$http.post(api.actions.save_stylist_policy_agreement, {
                                stylist_id: this.addingCardUser.id,
                                client_id: this.user.id,
                                agree: 1,
                            });
                            this.loading = false;
                            this.getUserCards(this.addingCardUser);
                        }
                    });

                //Save the card in Square
            } else if (this.addingCardUser.square_authorized) {
                this.square_card_obj.then((card) => {
                    try {
                        tokenize(card).then((nonce) => {
                            if (!nonce) {
                                this.loading = false;
                                return;
                            }

                            var data = {
                                card_nonce: nonce,
                                stylist_id: this.addingCardUser.id,
                                client_id: this.client_id,
                            };

                            this.loading = true;

                            this.$http.post(api.actions.save_square_customer_card, data).then((response) => {
                                this.loading = false;

                                if (response.error == true) {
                                    return;
                                }

                                if (response.data.error == true) {
                                    return;
                                }

                                this.has_card = true;
                                this.$http.post(api.actions.save_stylist_policy_agreement, {
                                    stylist_id: this.addingCardUser.id,
                                    client_id: this.user.id,
                                    agree: 1,
                                });
                                this.getUserCards(this.addingCardUser);
                            });
                        });
                    } catch (e) {
                        self.cards_errors = e.message;
                    }
                });
            }
        },
        cancelCard() {
            this.addingCard = false;
            this.cards_errors = "";
        },
        getUserCards(stylist) {
            var data = {};

            if (stylist.stripe_authorized) {
                this.$http
                    .get(
                        api.actions.get_stripe_payment_methods +
                            "?user_id=" +
                            this.user.id +
                            "&stylist_id=" +
                            stylist.id
                    )
                    .then((response) => {
                        this.addingCard = false;
                        this.cardsLoaded = false;

                        setTimeout(() => {
                            this.cardsLoaded = true;
                        }, 100);

                        if (response.data.error) {
                            return;
                        }

                        this.stripeCards[stylist.id] = response.data.payment_methods;
                        this.getCardsTotal();
                    });
            } else if (stylist.square_authorized) {
                data = {
                    stylist_id: stylist.id,
                    client_id: this.user.id,
                };

                this.$http.post(api.actions.get_square_customer_cards, data).then((response) => {
                    this.addingCard = false;
                    this.cardsLoaded = false;

                    setTimeout(() => {
                        this.cardsLoaded = true;
                    }, 100);

                    if (response.data.error) {
                        return;
                    }

                    this.squareCards[stylist.id] = response.data.response;
                    this.getCardsTotal();
                });
            }
        },
        getCardsTotal() {
            this.totalCards = 0;

            if (this.squareCards) {
                for (let i in this.squareCards) {
                    this.totalCards += this.squareCards[i].length;
                }
            }

            if (this.stripeCards) {
                for (let i in this.stripeCards) {
                    this.totalCards += this.stripeCards[i].length;
                }
            }
        },
        addNewCard(stylist) {
            this.addingCardUser = stylist;
            this.addingCard = true;
            this.cards_errors = "";
            this.displayCardForm();
        },
        deleteCard: function (card_id, stylist, type) {
            this.$swal({
                title: "Are you sure you want to delete the payment method?",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    this.loading = true;
                    var method = "";
                    var data = "";

                    if (type == "stripe") {
                        method = api.actions.delete_stripe_payment_method;
                        data = { id: card_id, stylist_id: stylist.id };
                    } else if (type == "square") {
                        method = api.actions.delete_square_customer_card;
                        data = { card_id: card_id, stylist_id: stylist.id };
                    } else {
                        return;
                    }

                    this.$http.post(method, data).then(() => {
                        this.loading = false;
                        this.getUserCards(stylist);
                    });
                }
            });
        },
        scrollToTop() {
            const duration = 800; // Scroll duration in milliseconds
            const start = window.scrollY;
            const startTime = "now" in window.performance ? performance.now() : new Date().getTime();

            const scrollToTop = (timestamp) => {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
                window.scrollTo(0, start + (0 - start) * easeInOutCubic(progress));

                if (elapsed < duration) {
                    requestAnimationFrame(scrollToTop);
                }
            };

            requestAnimationFrame(scrollToTop);
        },
        viewSignature(signature) {
            let token = hash.sha1().update(signature).digest("hex");
            return api.actions.view_signature + "?file_name=" + signature + "&token=" + token;
        },

        openSignatureModal(transaction) {
            this.viewSignature(transaction.signature);
            this.currentTransaction = transaction;
        },
        openAppointmentDetails(id) {
            sessionStorage.setItem("openEventId", id);
            window.scrollTo(0,0);
            this.$router.push("/app");
        },
    },
    components: {},
};
</script>
