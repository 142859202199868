<template>
  <div class="random-review-component">
    <div v-if="details" class="random-review-content">
      <div class="review-title-wrap">
        <Rating :rate="details.review_rate" />
        <h3 class="review-title">{{ details.review_title }}</h3>
      </div>
      <div class="review-details">
        {{ details.review_details }}
      </div>
      <div class="review-from">
        {{ details.from_user_first_name }}
        {{ details.from_user_last_name.substr(0, 1) }}. | {{ reviewDate }}
      </div>
    </div>
    <ProfileListingItem
      v-if="details"
      :stylist="details"
      :index="'review_' + details.id"
      v-on:modalProfileSelected="modalProfileSelected"
    />
  </div>
</template>

<script>
import api from "./../services/api_endpoints.js";
import Rating from "./rating";
import ProfileListingItem from "./profile_listing_item";
import moment from "moment";

export default {
  name: "RandomReview",
  data: function () {
    return {
      details: "",
      reviewDate: "",
    };
  },
  mounted: function () {
    this.getRandomReview();
  },
  methods: {
    getRandomReview: function () {
      this.$http.get(api.actions.get_random_profile_review).then(
        function (response) {
          if (response.data.error == true) {
            return false;
          }

          this.details = response.data.details;

          this.details.lightbox_images = this.details.portfolio_images.map(
            function (img) {
              return {
                thumb: img,
                src: img,
              };
            }
          );

          let date = moment(this.details.review_date);
          this.reviewDate = date.format("MMMM DD, YYYY");
        }.bind(this)
      );
    },
    modalProfileSelected(profile) {
      this.$emit("modalProfileSelected", profile);
    },
  },
  components: {
    Rating,
    ProfileListingItem,
  },
};
</script>
