<template>
    <div class="admin-register-component container">
        <div v-if="!confirmPhoneScreen" class="register-form-wrapper">
            <h4 class="register-box-title">
                {{ $ml.get("register.createAccount") }}
            </h4>
            <div class="register-form-inner row pt-4">
                <div class="register-choice col-12">
                    <div class="can-toggle mb-4">
                        <input id="register-choice-label" type="checkbox" />
                        <label class="register-choice-label">
                            <label
                                v-if="choiceToggled"
                                class="can-toggle__switch"
                                v-on:click="choiceSwitch"
                                for="register-choice-label"
                            >
                                <div class="before before-client-selected text-left pl-3 py-2 pointer">
                                    <span class="choice-side-label">I'm a </span>
                                    <span class="choice-main-label d-block">{{ generalOptions.user_role_name }}</span>
                                </div>
                                <div
                                    class="after after-client-selected text-left pl-3 py-2 selected-choice pointer"
                                    :style="getStyle()"
                                >
                                    <span class="choice-side-label">I'm a </span>
                                    <span class="choice-main-label d-block">Client</span>
                                </div>
                            </label>
                            <label
                                v-if="!choiceToggled"
                                class="can-toggle__switch"
                                v-on:click="choiceSwitch"
                                for="register-choice-label"
                            >
                                <div class="before before-business-selected text-left pl-3 py-2 pointer">
                                    <span class="choice-side-label">I'm a </span>
                                    <span class="choice-main-label d-block">Client</span>
                                </div>
                                <div
                                    class="after after-business-selected text-left pl-3 py-2 selected-choice pointer"
                                    :style="getStyle()"
                                >
                                    <span class="choice-side-label">I'm a </span>
                                    <span class="choice-main-label d-block">{{ generalOptions.user_role_name }}</span>
                                </div>
                            </label>
                        </label>
                    </div>
                </div>
                <div v-if="showLearnMoreModel">
                    <div class="learn-more-overlay"></div>
                    <div class="learn-more-overlay-content p-4 text-center">
                        <div
                            v-on:click="showHideLearnMore"
                            class="
                                pointer
                                learn-more-modal-close
                                d-flex
                                align-items-center
                                justify-content-center
                                rounded-circle
                            "
                        >
                            <i class="fa fa-times"></i>
                        </div>
                        <h3 class="learn-more-popup-header">Learn More</h3>
                        <span class="input-stripe-border modal"></span>
                        <h4 class="mt-4 learn-more-popup-header">Web Access</h4>
                        <p>
                            <span>You are registering for </span>
                            <span v-if="generalOptions.app_id == 1">Ring My Stylist </span>
                            <span v-if="generalOptions.app_id == 2">Ring My Barber </span>
                            <span
                                >Web Access where we are offering 1-year free access to the Calendar, Services, Clients,
                                and more.</span
                            >
                        </p>
                    </div>
                </div>

                <form class="col-12">
                    <div v-if="!choiceToggled && referralCode" class="row my-3">
                        <div class="col-12">
                            <span v-if="referralCode && referralCodeChecked" class="field-valid-icon">
                                <i v-if="referralCodeValid" class="fa fa-check field-valid"></i>
                                <i v-if="!referralCodeValid" class="fa fa-times field-invalid"></i>
                            </span>
                            <input
                                v-on:keyup.enter="onRegister"
                                v-on:keyup="checkReferralCode"
                                class="col-12 w-100 pl-3"
                                v-model="referralCode"
                                type="text"
                                name="referral_code"
                                placeholder="Have a referral code?"
                            />
                            <span class="input-stripe-border"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <input
                                v-on:keyup.enter="onRegister"
                                class="col-12 w-100 pl-3"
                                v-model="name"
                                type="text"
                                name="name"
                                placeholder="First Name & Last Name"
                            />
                            <span v-if="!nameError" class="input-stripe-border"></span>
                            <span v-if="nameError" class="input-stripe-border-red"></span>
                        </div>
                    </div>
                    <div v-if="!choiceToggled" class="row my-3">
                        <div class="col-12">
                            <input
                                v-on:keyup.enter="onRegister"
                                class="col-12 w-100 pl-3"
                                v-model="business_name"
                                type="text"
                                name="business_name"
                                placeholder="Business Name (Optional)"
                            />
                            <span class="input-stripe-border"></span>
                        </div>
                    </div>
                    <div v-if="!choiceToggled && professions.length > 1" class="row my-3">
                        <div class="col-12">
                            <div class="dropdown">
                                <button
                                    class="profession-dropdown w-100 btn btn-light dropdown-toggle text-left"
                                    v-bind:class="{ grey_text: !selectedProfession }"
                                    type="button"
                                    id="professions"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {{
                                        selectedProfession ? selectedProfession.profession : "What is your profession?"
                                    }}
                                </button>
                                <div class="prefixes-dropdown dropdown-menu" aria-labelledby="professions">
                                    <a
                                        class="dropdown-item"
                                        v-for="(profession, index) in professions"
                                        v-bind:key="index"
                                        v-on:click="changeProfession(profession)"
                                        >{{ profession.profession }}</a
                                    >
                                </div>
                            </div>
                            <span v-if="!selectedProfessionError" class="input-stripe-border"></span>
                            <span v-if="selectedProfessionError" class="input-stripe-border-red"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <input
                                v-on:keyup.enter="onRegister"
                                class="col-12 w-100 pl-3"
                                v-model="email"
                                type="email"
                                name="email"
                                placeholder="E-mail Address"
                            />
                            <span v-if="!emailError" class="input-stripe-border"></span>
                            <span v-if="emailError" class="input-stripe-border-red"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <input
                                v-on:keyup.enter="onRegister"
                                class="w-100 pl-3"
                                v-model="password"
                                type="password"
                                name="password"
                                placeholder="Password"
                            />
                            <span v-if="!passwordError" class="input-stripe-border"></span>
                            <span v-if="passwordError" class="input-stripe-border-red"></span>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="dropdown">
                                    <button
                                        class="btn btn-light dropdown-toggle"
                                        type="button"
                                        id="phonePrefixes"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {{ phone_prefix }}
                                    </button>
                                    <div class="prefixes-dropdown dropdown-menu" aria-labelledby="phonePrefixes">
                                        <a
                                            class="dropdown-item"
                                            v-for="(prefix, index) in prefixes"
                                            v-bind:key="index"
                                            v-on:click="changePhonePrefix(prefix)"
                                            >{{ prefix }}</a
                                        >
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center phone-dash p-2">-</div>
                                <input
                                    v-on:keyup.enter="onRegister"
                                    class="w-100"
                                    v-model="phone"
                                    type="number"
                                    name="phone"
                                />
                            </div>
                            <span v-if="!phoneError" class="input-stripe-border"></span>
                            <span v-if="phoneError" class="input-stripe-border-red"></span>
                        </div>
                        <div class="col-12 mb-2">
                            <label class="flex-label">
                                <input v-model="sms_optin" type="checkbox" name="sms-optin" value="1" />
                                {{ $ml.get("register.sms_optin") }}
                            </label>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">
                            <input
                                v-on:keyup.enter="onRegister"
                                class="w-100 pl-3"
                                v-model="zip"
                                type="text"
                                name="zip"
                                placeholder="ZIP Code"
                            />
                            <span v-if="!zipError" class="input-stripe-border"></span>
                            <span v-if="zipError" class="input-stripe-border-red"></span>
                        </div>
                    </div>
                    <div v-if="!choiceToggled" class="row my-3">
                        <div class="col-12">
                            <gmap-autocomplete
                                v-on:keyup.enter="onRegister"
                                @place_changed="onAddressChanged"
                                class="w-100 pl-3"
                                placeholder="Business Address"
                            ></gmap-autocomplete>
                            <span v-if="!addressError" class="input-stripe-border"></span>
                            <span v-if="addressError" class="input-stripe-border-red"></span>
                        </div>
                    </div>

                    <div v-if="!choiceToggled" class="row my-3">
                        <div class="col-12">
                            <div class="dropdown">
                                <button
                                    class="profession-dropdown w-100 btn btn-light dropdown-toggle text-left"
                                    v-bind:class="{ grey_text: !selectedReferralSource }"
                                    type="button"
                                    id="referral-sources"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {{
                                        selectedReferralSource ? selectedReferralSource : "Where did you hear about us?"
                                    }}
                                </button>
                                <div class="prefixes-dropdown dropdown-menu" aria-labelledby="referralSources">
                                    <a
                                        class="dropdown-item"
                                        v-for="(source, index) in referralSources"
                                        v-bind:key="index"
                                        v-on:click="selectedReferralSource = source"
                                        >{{ source }}</a
                                    >
                                </div>
                            </div>
                            <span v-if="!selectedReferralSourceError" class="input-stripe-border"></span>
                            <span v-if="selectedReferralSourceError" class="input-stripe-border-red"></span>
                        </div>
                    </div>
                    <div v-if="!choiceToggled" class="row my-3">
                        <div class="col-12 text-center plan-select-section">
                            <div class="plan-sel-headline">
                                <span>Enjoy a FREE 7-day trial.</span>
                                <span>Then only $9.99/mo.</span>
                            </div>
                            <span>No contract, cancellation, setup, or signup fees</span>
                            <!-- <label>
                                  <input type="radio" name="plan" value="yearly" v-model="subscriptionPlan">
                                  <span v-if="referralCode && referralCodeValid"><b>${{ generalOptions.subscriptionPriceYearReferral }} for the first year(50% OFF)</b>, then only ${{ generalOptions.subscriptionPriceYear }}/year</span>
                                  <span v-if="!referralCode || !referralCodeValid">after 30-day trial, only ${{ generalOptions.subscriptionPriceYear }}/year</span>
                              </label>
                              <div class="or-separator">-or-</div>
                              <label>
                                  <input type="radio" name="plan" value="monthly" v-model="subscriptionPlan">
                                  <span v-if="referralCode && referralCodeValid"><b>${{ generalOptions.subscriptionPriceReferral }}/month for the first 3 months</b>, then only ${{ generalOptions.subscriptionPrice }}/month</span>
                                  <span v-if="!referralCode || !referralCodeValid">after 30-day trial, only ${{ generalOptions.subscriptionPrice }}/month</span>
                              </label> -->
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12 text-center">
                            <button
                                v-on:keyup.enter="onRegister"
                                class="register-button"
                                type="button"
                                v-on:click="onRegister"
                                :style="getButtonStyle()"
                            >
                                Register
                            </button>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12 text-center">
                            By continuing you are indicating that you have read and agree to the
                        </div>
                        <div class="col-12 text-center">
                            <a target="_blank" href="https://www.ringmystylist.com/terms-and-condition"
                                >Terms of Service</a
                            >
                            &
                            <a target="_blank" href="https://www.ringmystylist.com/privacy-policy">Privacy Policy</a>
                        </div>
                    </div>
                    <div class="row my-3">
                        <div class="col-12 forgot-password-text text-center">
                            <span>Already have an account? </span>
                            <router-link :to="{ path: '/app/login' }">{{ $ml.get("general.logIn") }}</router-link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="confirmPhoneScreen" class="register-form-wrapper">
            <h4 class="register-box-title">Confirm Your Phone Number</h4>
            <div class="verify-phone-section form-section">
                <p>
                    To confirm the identity of your account ( associated with {{ phone }}) we sent you an SMS with a
                    validation code.
                </p>

                <div class="verifiy-code-fields text-center">
                    <input
                        type="text"
                        v-model="phoneCheckCode"
                        class="phone-code-field"
                        autocomplete="off"
                        v-on:keyup.13="checkVerificationCode"
                    />
                </div>
                <div class="text-center">
                    <button
                        id="check-verification-code"
                        type="button"
                        class="reset-password-button"
                        @click="checkVerificationCode"
                        tabindex="10"
                    >
                        Continue
                    </button>

                    <div class="book-action-links">
                        <a class="book-action-link" href="#" @click="verifyPhoneNumber">Resend Code</a>
                        <span class="link-separator">|</span>
                        <a
                            class="book-action-link"
                            href="#"
                            @click="
                                confirmPhoneScreen = false;
                                confirmedPhone = false;
                                phoneCheckCode = '';
                            "
                            >Edit Number</a
                        >
                    </div>
                </div>
                <div v-if="phoneCheckMessage" v-html="phoneCheckMessage"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import api from "./../../services/api_endpoints.js";
import eventBus from "./../../services/event_bus.js";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_MAPS_API_KEY,
        libraries: "places",
    },
    installComponents: true,
});

export default {
    name: "Register",
    data: function () {
        return {
            email: "",
            password: "",
            name: "",
            business_name: "",
            selectedProfession: null,
            phone: "",
            sms_optin: 0,
            zip: "",
            address: "",
            business_address_city: "",
            business_address_country: "",
            business_address_state: "",

            remember: 0,
            choiceToggled:
                window.location.hash != "#barber" &&
                window.location.hash != "#stylist" &&
                window.location.hash != "#groomer" &&
                window.location.hash != "#loctician",
            prefixes: [],
            countries: [],
            phone_prefix: "+1",
            professions: [],
            showLearnMoreModel: false,

            emailError: false,
            passwordError: false,
            nameError: false,
            selectedProfessionError: false,
            phoneError: false,
            zipError: false,
            addressError: false,
            subscriptionPlan: "monthly",
            referralCode: "",
            referralCodeValid: false,
            referralCodeChecked: false,
            confirmPhoneScreen: false,
            confirmedPhone: false,
            phoneCheckMessage: "",
            phoneCheckCode: "",
            referralSources: [
                "Facebook",
                "Referred by a friend",
                "Instagram",
                "Referred by my client",
                "Google",
                "App Store",
                "Other",
            ],
            selectedReferralSource: "",
            selectedReferralSourceError: "",
        };
    },
    mounted: function () {
        if (auth.user) {
            this.$router.push("/app");
        }
        this.getCountries();
        this.getProfessions();

        let referral_code = this.getParameterByName("referral");

        if (referral_code) {
            this.referralCode = referral_code;
            this.choiceToggled = false;
            this.checkReferralCode();
        }

        eventBus.$on("user_profile_loaded", this.onRegisterSuccess);
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded", this.onRegisterSuccess);
    },
    computed: {
        selectedStylist: {
            get: function () {
                return store.state.selectedStylist;
            },
            set: function (value) {
                store.commit("setSelectedStylist", value);
            },
        },
    },
    methods: {
        onRegister: function () {
            let role = this.choiceToggled ? "client" : "stylist";

            let emailError = this.checkError("email");
            let passwordError = this.checkError("password");
            let nameError = this.checkError("name");
            let phoneError = this.checkError("phone");
            let zipError = this.checkError("zip");
            let addressError = false;

            if (!this.choiceToggled) {
                addressError = this.checkError("address");
            }

            let selectedProfessionError = false;
            if (role === "stylist") {
                selectedProfessionError = this.checkError("selectedProfession");
                this.selectedReferralSourceError = this.checkError("selectedReferralSource");
            }

            if (
                emailError ||
                passwordError ||
                nameError ||
                selectedProfessionError ||
                phoneError ||
                zipError ||
                addressError ||
                this.selectedReferralSourceError
            ) {
                return;
            }

            if (role == "stylist" && !this.confirmedPhone) {
                let d = {
                    email: this.email,
                    phone: this.phone,
                    phone_prefix: this.phone_prefix,
                };

                this.$http.post(api.actions.check_existing_user_data, d).then(
                    function (response) {
                        if (response.data.error != true) {
                            this.verifyPhoneNumber();
                        }
                    }.bind(this),
                    function () {
                        return false;
                    }
                );

                return;
            }

            let data = {
                full_name: this.name,
                email: this.email,
                password: this.password,
                role: this.choiceToggled ? "client" : "stylist",
                zip: this.zip,
                phone: this.phone,
                phone_prefix: this.phone_prefix,
                sms_optin: this.sms_optin ? 1 : 0,
                app_id: this.generalOptions.app_id,
            };
            if (role === "stylist") {
                data["business_name"] = this.business_name;
                data["profession_ids"] = [this.selectedProfession.id];
                data["subscription_plan"] = this.subscriptionPlan;
                data["referral_code"] = this.referralCode;
                data["referral_source"] = this.selectedReferralSource;
                data["business_address_street"] = this.address;
                data["business_address_city"] = this.business_address_city;
                data["business_address_state"] = this.business_address_state;
                data["business_address_country"] = this.business_address_country;
            }
            auth.register(data);
        },
        checkError: function (field) {
            if (!this[field]) {
                this[field + "Error"] = true;
                return true;
            }
            this[field + "Error"] = false;
            return false;
        },
        onRegisterSuccess: function () {
            if (auth.user.role == "client" && auth.user.time_from_register < 10) {
                this.$router.push("/app/client-appointments-notifications");
            } else {
                this.$router.push("/app");
            }
        },
        choiceSwitch: function () {
            this.choiceToggled = !this.choiceToggled;
        },
        getCountries: function () {
            this.$http.get(api.actions.get_countries).then(
                function (response) {
                    if (typeof response.data.countries != "undefined") {
                        this.countries = response.data.countries;
                        this.prefixes = this.countries.map(function (el) {
                            return el.dial_code;
                        });
                        this.prefixes = _.sortBy(_.uniqBy(this.prefixes));
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        changePhonePrefix: function (prefix) {
            this.phone_prefix = prefix;
        },
        getProfessions: function () {
            this.$http.get(api.actions.get_professions + "/" + this.generalOptions.app_id).then(
                function (response) {
                    if (typeof response.data.professions != "undefined") {
                        this.professions = response.data.professions;
                        if (this.professions.length == 1) {
                            this.selectedProfession = this.professions[0];
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        checkReferralCode: _.debounce(function () {
            this.referralCodeChecked = false;
            this.$http.get(api.actions.check_referral_code + "?code=" + this.referralCode).then(
                function (response) {
                    this.referralCodeValid = response.data.active;
                    this.referralCodeChecked = true;
                }.bind(this),
                function () {
                    return false;
                }
            );
        }, 500),
        changeProfession: function (profession) {
            this.selectedProfession = profession;
        },
        showHideLearnMore: function () {
            this.showLearnMoreModel = !this.showLearnMoreModel;
        },
        getStyle: function () {
            if (this.generalOptions.app_id == 2) {
                return {
                    "background-color": "#b6001f !important",
                    color: "#FFFFFF !important",
                };
            }
            return {};
        },
        getButtonStyle: function () {
            if (this.generalOptions.app_id == 2) {
                return {
                    "background-color": "#b6001f !important",
                    "border-color": "#b6001f !important",
                };
            }
            return {};
        },
        getParameterByName(name) {
            //eslint-disable-next-line
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(window.location.href);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        verifyPhoneNumber() {
            var data = {
                phone_prefix: this.phone_prefix,
                phone: this.phone,
            };

            this.phoneCheckMessage = "";

            this.$http.post(api.actions.send_phone_verify_code, data).then((response) => {
                if (response.error == true) {
                    this.phoneCheckMessage = '<div class="alert alert-danger">There is an error</div>';
                    return;
                }

                if (response.data.error == true) {
                    this.phoneCheckMessage = '<div class="alert alert-danger">' + response.data.message + "</div>";
                }

                this.phoneCheckMessage = '<div class="alert alert-success">The code has been sent</div>';
                this.confirmPhoneScreen = true;
            });
        },
        checkVerificationCode() {
            var data = {
                phone: this.phone,
                code: this.phoneCheckCode,
            };

            this.$http.post(api.actions.verify_phone_code, data).then((response) => {
                if (response.error == true) {
                    this.phoneCheckMessage = '<div class="alert alert-danger">There is an error</div>';
                    return;
                }

                if (response.data.error == true) {
                    this.phoneCheckMessage = '<div class="alert alert-danger">' + response.data.message + "</div>";
                } else {
                    this.confirmedPhone = true;
                    this.onRegister();
                }
            });
        },

        onAddressChanged(place) {
            if (!place || (place && !place.address_components)) {
                return;
            }

            this.address = place.name;

            for (let key in place.address_components) {
                let component = place.address_components[key];

                if (!component.types) {
                    continue;
                }

                if (component.types.indexOf("country") != -1) {
                    this.business_address_country = component.long_name;
                } else if (component.types.indexOf("locality") != -1) {
                    this.business_address_city = component.long_name;
                } else if (component.types.indexOf("administrative_area_level_1") != -1) {
                    this.business_address_state = component.long_name;
                }
            }
        },
    },
};
</script>

<style scoped></style>
