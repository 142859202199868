export default {
    install: function (Vue) {
        let allOptions = {
            "book.ringmystylist.com": {
                app_id: 1,
                stylist_id: 0,
                profile_url: "https://www.ringmystylist.com/stylist-profile/",
                short_url: "https://ringmy.pro/",
                site_url: "https://www.ringmystylist.com/",
                blog_url: "https://www.ringmystylist.com/blog",
                help_url: "https://www.ringmystylist.com/support",
                terms_url: "https://www.ringmystylist.com/terms-and-conditions",
                privacy_url: "https://www.ringmystylist.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmystylist.com/contact",
                prices_url: "#",
                app_name: "Ring My Stylist",
                app_title: "Online Appointments Booking System for Beauty Professionals and Salons",
                user_role_name: "Stylist",
                profession_id: 0,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmystylist.com",
                instagram: "ringmystylist",
                defaultColor: "#00cab3",
                appstore_url: "https://itunes.apple.com/us/app/ring-my-stylist/id1250537446",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app",
            },
            "book.ringmybarber.com": {
                app_id: 2,
                stylist_id: 0,
                profile_url: "https://www.ringmybarber.com/barber-profile/",
                short_url: "https://ringmy.pro/",
                site_url: "https://www.ringmybarber.com/",
                blog_url: "https://www.ringmybarber.com/blog/",
                help_url: "https://www.ringmystylist.com/support/",
                terms_url: "https://www.ringmystylist.com/terms-and-conditions",
                privacy_url: "https://www.ringmystylist.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmybarber.com/contact/",
                prices_url: "#",
                app_name: "Ring My Barber",
                app_title: "Online Appointments Booking System for Barbers and Barbershops",
                user_role_name: "Barber",
                profession_id: 2,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmybarber.com",
                instagram: "ringmybarber",
                defaultColor: "#b40024",
                appstore_url: "https://itunes.apple.com/us/app/ring-my-barber-appointments/id1451800946",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app.rmb",
            },
            "book.ringmygroomer.com": {
                app_id: 3,
                stylist_id: 0,
                profile_url: "https://www.ringmygroomer.com/groomer-profile/",
                short_url: "https://ringmy.pro/",
                site_url: "https://www.ringmygroomer.com/",
                blog_url: "https://www.ringmystylist.com/blog",
                help_url: "https://www.ringmystylist.com/support",
                terms_url: "https://www.ringmystylist.com/terms-and-conditions",
                privacy_url: "https://www.ringmystylist.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmystylist.com/contact",
                prices_url: "#",
                app_name: "Ring My Groomer",
                app_title: "Online Appointments Booking System for Groomers",
                user_role_name: "Groomer",
                profession_id: 0,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmygroomer.com",
                instagram: "ringmygroomer",
                defaultColor: "#e7c53f",
                appstore_url: "",
                googleplay_url: "",
            },
            "book.ringmyloctician.com": {
                app_id: 5,
                stylist_id: 0,
                profile_url: "https://www.ringmyloctician.com/loctician-profile/",
                short_url: "https://ringmy.pro/",
                site_url: "https://www.ringmyloctician.com/",
                blog_url: "https://www.ringmystylist.com/blog",
                help_url: "https://www.ringmystylist.com/support",
                terms_url: "https://www.ringmyloctician.com/terms-and-conditions",
                privacy_url: "https://www.ringmyloctician.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmyloctician.com/contact",
                prices_url: "#",
                app_name: "Ring My Loctician",
                app_title: "Online Appointments Booking System for Locticians and Hair Salons",
                user_role_name: "Loctician",
                profession_id: 0,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmyloctician.com",
                instagram: "ringmyloctician",
                defaultColor: "#0449b7",
                appstore_url: "https://apps.apple.com/us/app/ring-my-loctician-booking-app/id1558673025",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app.rml",
            },
            "book.sandbox.ringmybarber.com": {
                app_id: 2,
                stylist_id: 0,
                profile_url: "https://www.ringmybarber.com/barber-profile/",
                short_url: "https://s.ringmy.pro/",
                site_url: "https://www.ringmybarber.com/",
                blog_url: "https://www.ringmybarber.com/blog/",
                help_url: "https://www.ringmystylist.com/support/",
                terms_url: "https://www.ringmystylist.com/terms-and-conditions",
                privacy_url: "https://www.ringmystylist.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmybarber.com/contact/",
                prices_url: "#",
                app_name: "Ring My Barber",
                app_title: "Online Appointments Booking System for Barbers and Barbershops",
                user_role_name: "Barber",
                profession_id: 2,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmybarber.com",
                instagram: "ringmybarber",
                defaultColor: "#b40024",
                appstore_url: "https://itunes.apple.com/us/app/ring-my-barber-appointments/id1451800946",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app.rmb",
            },
            "book.sandbox.ringmyloctician.com": {
                app_id: 5,
                stylist_id: 0,
                profile_url: "https://www.ringmyloctician.com/loctician-profile/",
                short_url: "https://s.ringmy.pro/",
                site_url: "https://www.ringmyloctician.com/",
                blog_url: "https://www.ringmystylist.com/blog",
                help_url: "https://www.ringmystylist.com/support",
                terms_url: "https://www.ringmyloctician.com/terms-and-conditions",
                privacy_url: "https://www.ringmyloctician.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmyloctician.com/contact",
                prices_url: "#",
                app_name: "Ring My Loctician",
                app_title: "Online Appointments Booking System for Locticians and Hair Salons",
                user_role_name: "Loctician",
                profession_id: 2,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmyloctician.com",
                instagram: "ringmyloctician",
                defaultColor: "#0449b7",
                appstore_url: "https://apps.apple.com/us/app/ring-my-loctician-booking-app/id1558673025",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app.rml",
            },
            default: {
                app_id: 1,
                stylist_id: 0,
                profile_url: "https://www.ringmystylist.com/stylist-profile/",
                site_url: "https://www.ringmystylist.com/",
                blog_url: "https://www.ringmystylist.com/blog",
                help_url: "https://www.ringmystylist.com/support",
                terms_url: "https://www.ringmystylist.com/terms-and-conditions",
                privacy_url: "https://www.ringmystylist.com/privacy-policy",
                refund_url: "https://www.ringmystylist.com/support/refund-policy/",
                contact_url: "https://www.ringmystylist.com/contact",
                prices_url: "#",
                short_url: "https://s.ringmy.pro/",
                app_name: "Ring My Stylist",
                app_title: "Online Appointments Booking System for Beauty Professionals and Salons",
                user_role_name: "Stylist",
                profession_id: 0,
                related_professions: { 2: 24 },
                subscriptionPrice: 9.99,
                subscriptionPriceReferral: 5,
                subscriptionPriceYear: 96.99,
                subscriptionPriceYearReferral: 48.99,
                email: "info@ringmystylist.com",
                instagram: "ringmystylist",
                defaultColor: "#b40024",
                appstore_url: "https://itunes.apple.com/us/app/ring-my-stylist/id1250537446",
                googleplay_url: "https://play.google.com/store/apps/details?id=com.ringmystylist.app",
            },
        };

        let options = allOptions[window.location.host] ? allOptions[window.location.host] : allOptions.default;
        Vue.prototype.generalOptions = options;
    },
};
