<template>
    <!--Main Conatiner for Referral Page-->
    <div v-if="user && user.role == 'stylist'" class="referral-page">
        <div class="referral-wrapper">
            <div class="referral-heading">
                <h3>{{ $ml.get("adminMenu.referral") }}</h3>
            </div>
            <div class="referral-content section-wrap">
                <!--Stylists save together | section-->
                <div class="referral-section section-wrap highlighted-box">
                    <h5 class="referral-inner-heading">
                        {{ $ml.get("referral.titlePlural" + generalOptions.app_id) }}
                        {{ $ml.get("referral.headline") }}
                    </h5>
                    <span>
                        Know a {{ $ml.get("referral.titleSingular" + generalOptions.app_id).toLowerCase() }} who could
                        use a better mobile booking app? Well good news, when you refer your
                        {{ $ml.get("referral.titleSingular" + generalOptions.app_id).toLowerCase() }} to
                        {{ generalOptions.app_name }} you can make $30 and the barber can take 50% off the bill for the
                        first year. Got a lot of
                        {{ $ml.get("referral.titlePlural" + generalOptions.app_id).toLowerCase() }} ? send us an email
                        at {{ generalOptions.email }} and we will see what can we do better for you.
                    </span>
                </div>
                <!--Get Referral Code | section-->
                <div v-if="adminDetails.user && !referralCode" class="referral-section">
                    <div class="get-referrer-btn-wrap">
                        <div>
                            <button
                                type="button"
                                class="primary-btn extra-small"
                                v-on:click="saveReferralCode"
                                :disabled="saving"
                            >
                                {{ $ml.get("referral.getReferrerButtonLabel") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--Referral Code | section-->
                <div v-if="adminDetails.user && referralCode" class="referral-section section-wrap highlighted-box">
                    <div class="referral-code">
                        <div class="referral-title">{{ $ml.get("referral.yourReferralCode") }}:</div>
                        <div>
                            <strong>{{ referralCode }}</strong>
                        </div>
                    </div>
                    <div class="referral-code">
                        <div class="referral-title">{{ $ml.get("referral.yourReferralLink") }}:</div>
                        <div class="referal-input-wrap">
                            <div>
                                <input type="text" class="text-field" :value="getReferralLink()" disabled />
                            </div>
                            <div>
                                <button
                                    type="button"
                                    class="primary-btn extra-small"
                                    v-on:click="copyToClipboard(getReferralLink())"
                                >
                                    {{ $ml.get("referral.copy") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--code that i don't know what it does-->
                    <div class="row d-flex align-items-center">
                        <div class="ml-3">
                            <button
                                v-if="statusChanged"
                                type="button"
                                class="primary-btn extra-small"
                                v-on:click="saveReferralCode"
                                :disabled="saving"
                            >
                                {{ $ml.get("referral.save") }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--FAQ | section-->
                <div class="referral-section section-wrap highlighted-box">
                    <div class="referral-inner-heading">
                        <h5>{{ $ml.get("referral.faq") }}</h5>
                    </div>
                    <div class="section-wrap">
                        <h6>{{ $ml.get("referral.whoCanIRefer") }}</h6>
                        <span>
                            {{ $ml.get("referral.youCanRefer") }}
                            {{ generalOptions.app_name }} {{ $ml.get("referral.customers") }}.
                        </span>
                    </div>
                    <div class="section-wrap">
                        <h6>{{ $ml.get("referral.howManyRefereesCanIRefer") }}</h6>
                        <span>{{ $ml.get("referral.thereIsNoLimit") }}</span>
                    </div>
                    <div class="section-wrap">
                        <h6>{{ $ml.get("referral.whatDoesMyRefereeNeed") }}</h6>
                        <span>{{ $ml.get("referral.tellYourReferees") }}</span>
                    </div>
                    <div class="section-wrap">
                        <h6>{{ $ml.get("referral.howLongDoesItTake") }}</h6>
                        <span>
                            {{ $ml.get("referral.theFirstMonthly") }}
                            {{ generalOptions.app_name }} {{ $ml.get("referral.theFirstMonthlyEnd") }}
                            {{ $ml.get("referral.theSubsequent") }}
                        </span>
                    </div>
                </div>
                <!--Referral terms - Payouts | section-->
                <div class="referral-links-wrap">
                    <div>
                        <span v-on:click="showTermsModal" class="main-text-link">{{ $ml.get("referral.terms") }}</span>
                    </div>
                    <div>
                        <router-link :to="{ path: '/app/payouts' }" class="main-text-link">{{
                            $ml.get("payouts.title")
                        }}</router-link>
                    </div>
                </div>
                <!--Referred Users | section-->
                <div v-if="referredUsersLoaded" class="referral-section section-wrap highlighted-box">
                    <div class="referral-inner-heading">
                        <h5>{{ $ml.get("referral.referredUsers") }}</h5>
                    </div>
                    <div v-if="referredUsers.length > 0" class="referred-users section-wrap">
                        <div v-for="(user, i) in referredUsers" :key="`${i}_${user.id}`" class="row-referred-user">
                            <div class="referred-user-data section-wrap">
                                <div class="referred-users-head">{{ $ml.get("referral.name") }}</div>
                                <div class="referred-users-info">{{ user.first_name }} {{ user.last_name }}</div>
                            </div>
                            <div class="referred-user-data section-wrap">
                                <div class="referred-users-head">{{ $ml.get("referral.email") }}</div>
                                <div class="referred-users-info">{{ user.email }}</div>
                            </div>
                            <div class="referred-user-data section-wrap">
                                <div class="referred-users-head">{{ $ml.get("referral.subscriptionActive") }}</div>
                                <div class="referred-users-info">
                                    {{ user.active ? "Yes" : "No" }}
                                    <span v-if="user.active && !user.first_payment_date"
                                        >({{ $ml.get("referral.trial") }})</span
                                    >
                                </div>
                            </div>
                            <div class="referred-user-data section-wrap">
                                <div class="referred-users-head">{{ $ml.get("referral.subscriptionCreated") }}</div>
                                <div class="referred-users-info">
                                    {{ getFormattedDate(user.created_at, "MMMM D, Y") }}
                                </div>
                            </div>
                            <div class="referred-user-data section-wrap">
                                <div class="referred-users-head">{{ $ml.get("referral.firstPaymentDate") }}</div>
                                <div class="referred-users-info" v-if="user.first_payment_date">
                                    {{ getFormattedDate(user.first_payment_date, "MMMM D, Y") }}
                                </div>
                                <div class="referred-users-info" v-if="!user.first_payment_date">-</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="totalUsersPages >= usersPage" class="">
                        <button type="button" class="btn btn-primary" v-on:click="getReferredUsers" :disabled="loading">
                            {{ $ml.get("referral.loadMore") }}
                        </button>
                    </div>
                    <div v-if="referredUsers.length == 0" class="referred-users section-wrap">
                        <span
                            ><strong>{{ $ml.get("referral.youHaveNoReferredUsers") }}</strong></span
                        >
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="terms-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="date-modal"
            aria-hidden="true"
            data-backdrop="false"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="w-100">
                            <b>{{ $ml.get("referral.disclaimers") }}</b>
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body book-sections">
                        <div class="referral-terms-wrap section-wrap">
                            <div class="terms-list">
                                {{ $ml.get("referral.termsText") }}
                            </div>
                            <div v-if="termsAccepted != 1" class="terms-actions">
                                <div>
                                    <span class="primary-btn extra-small" v-on:click="saveTermsStatus(1)">{{
                                        $ml.get("referral.termsAccept")
                                    }}</span>
                                </div>

                                <div>
                                    <span class="secondary-btn small" v-on:click="saveTermsStatus(0)">{{
                                        $ml.get("referral.termsDecline")
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";
import moment from "moment";
import $ from "jquery";

export default {
    name: "Referral",
    data: function () {
        return {
            user: null,
            codeActive: true,
            referralCode: "",
            statusChanged: false,
            saving: false,
            loading: false,
            referredUsers: [],
            referredUsersLoaded: false,
            usersPage: 1,
            totalUsersPages: 0,
            termsAccepted: 2,
            getButtonActive: false,
        };
    },
    mounted: function () {
        this.user = auth.user;

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });

        this.getStylistDetails();
        this.getReferredUsers();
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },
    methods: {
        getStylistDetails: function () {
            this.$http.get(api.actions.get_stylist_details + this.user.id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        if (
                            response.data.details.referral_code &&
                            typeof response.data.details.referral_code.code != "undefined"
                        ) {
                            this.referralCode = response.data.details.referral_code.code;
                            this.codeActive = response.data.details.referral_code.active;
                            this.termsAccepted = response.data.details.referral_code.terms_accepted;

                            if (this.termsAccepted === 0) {
                                this.$router.push("/app/profile");
                                return;
                            }
                        }

                        store.commit("setAdminDetails", response.data.details);
                        eventBus.$emit("admin_details_loaded");
                    }
                }.bind(this)
            );
        },
        getFormattedDate: function (date, format) {
            return moment(date, "YYYY-MM-DD hh:mm:ss").format(format);
        },
        getReferralLink: function () {
            return this.generalOptions.short_url + this.referralCode;
        },
        saveReferralCode: function () {
            if (this.termsAccepted != 1) {
                this.getButtonActive = true;
                $("#terms-modal").modal("show");
                return;
            }

            let data = {
                active: this.codeActive,
            };
            this.saving = true;
            this.$http.post(api.actions.save_referral_code, data).then(
                function (response) {
                    this.saving = false;
                    this.statusChanged = false;

                    if (response.data.referral_code) {
                        this.referralCode = response.data.referral_code.code;
                        this.codeActive = response.data.referral_code.active;
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        copyToClipboard: function (text) {
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            messages.successMessage(messages.GLOBAL_MESSAGES.LINK_COPIED);
        },
        getReferredUsers: function () {
            this.loading = true;
            this.$http.get(api.actions.get_referred_users + "?page=" + this.usersPage).then(
                function (response) {
                    if (!response.data.users) {
                        messages.errorMessage(messages.ERROR_MESSAGES.USERS_LOAD_ERROR);
                        return;
                    }

                    this.referredUsers = _.concat(this.referredUsers, response.data.users);
                    this.usersPage++;
                    this.totalUsersPages = response.data.total_pages;
                    this.loading = false;
                    this.referredUsersLoaded = true;
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        showTermsModal: function () {
            $("#terms-modal").modal("show");
            this.getButtonActive = false;
        },
        saveTermsStatus: function (status) {
            let data = {
                terms_accepted: status,
            };
            this.saving = true;
            this.$http.post(api.actions.save_referral_code, data).then((response) => {
                this.saving = false;
                this.termsAccepted = response.data.referral_code.terms_accepted;
                $("#terms-modal").modal("hide");

                if (this.getButtonActive && this.termsAccepted == 1) {
                    this.saveReferralCode();
                }
            });
        },
    },
};
</script>
