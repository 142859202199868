<template>
    <!--Business license main container-->
    <div class="admin-bussines-license">
        <!--Heading-->
        <div class="heading-container">
            <div class="heading">
                <h3>{{ $ml.get("businessLicense.businessLicense") }}</h3>
            </div>
            <div class="select-team-container" v-if="listing">
                <div v-if="adminDetails.group_owner == 1 && adminDetails.group_active == 1" class="select-team-wrap">
                    <label
                        class="stylist-label"
                        for="stylist-select"
                        :class="generalOptions.app_id === 5 ? 'loctician' : ''"
                        >Select
                        {{
                            generalOptions.app_id === 1
                                ? "Stylist"
                                : generalOptions.app_id === 2
                                ? "Barber"
                                : generalOptions.app_id === 5
                                ? "Loctician"
                                : ""
                        }}</label
                    >
                    <select
                        id="stylist-select"
                        class="selectInput selectInputSmall"
                        v-model="for_stylist_id"
                        v-on:change="onStylistChange"
                    >
                        <option
                            v-for="(member, index) in groupMembers"
                            v-bind:key="index"
                            v-bind:value="member.user.id"
                            v-bind:disabled="member.active != 1"
                        >
                            {{ member.user.first_name }} {{ member.user.last_name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <!--Page content-->
        <div class="business-license-content" v-if="listing">
            <div class="add-new-license-wrap">
                <button v-if="listing" @click="clearLicense" class="primary-btn extra-small">
                    <i class="fa fa-plus"></i> {{ $ml.get("businessLicense.addNewLicense") }}
                </button>
            </div>
            <template v-if="licenses.length">
                <div class="cards-body-wrap section-wrap">
                    <div v-for="license in licenses" :key="license.id">
                        <div class="card-body highlighted-box section-wrap">
                            <div class="card-content">
                                <h5 class="card-title">{{ license.first_name }} {{ license.last_name }}</h5>
                                <h6 class="card-subtitle">#{{ license.number }}</h6>
                                <p class="card-text" v-html="license.information"></p>
                            </div>
                            <div class="license-btns-wrapper">
                                <div class="license-btns">
                                    <a href="#" @click="editLicense(license.id)" class="primary-btn extra-small">
                                        {{ $ml.get("businessLicense.view") }}</a
                                    >
                                    <a href="#" @click="deleteLicense(license.id)" class="secondary-btn small">{{
                                        $ml.get("businessLicense.delete")
                                    }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="section-wrap no-license">
                    <h5>{{ $ml.get("businessLicense.noLicensesForThisStylist") }}</h5>
                </div>
            </template>
        </div>
        <!--Back to licenses / Add New License-->
        <div v-else class="add-new-license-container">
            <div class="content-wrap section-wrap">
                <div class="back-btn-wrap">
                    <button @click="listing = true" class="primary-btn extra-small">
                        <i class="fa fa-chevron-left"></i><span> {{ $ml.get("businessLicense.backToLicenses") }}</span>
                    </button>
                </div>
                <form @submit="addLicense" method="POST">
                    <div class="form-wrapper section-wrap">
                        <div class="input-wrap">
                            <label for="license-number" class="form-label"
                                >{{ $ml.get("businessLicense.licenseNumber") }} *</label
                            >
                            <div>
                                <input
                                    placeholder="License Number"
                                    type="text"
                                    class="text-field"
                                    id="license-number"
                                    v-model="license.number"
                                />
                            </div>
                        </div>
                        <div class="input-wrap">
                            <label for="first-name" class="form-label"
                                >{{ $ml.get("businessLicense.firstName") }}*</label
                            >
                            <div class="">
                                <input
                                    placeholder="First Name"
                                    type="text"
                                    required
                                    class="text-field"
                                    id="first-name"
                                    v-model="license.first_name"
                                />
                            </div>
                        </div>
                        <div class="input-wrap">
                            <label for="last-name" class="form-label">{{ $ml.get("businessLicense.lastName") }}*</label>
                            <div>
                                <input
                                    placeholder="Last Name"
                                    type="text"
                                    class="text-field"
                                    id="last-name"
                                    v-model="license.last_name"
                                />
                            </div>
                        </div>
                        <div class="input-wrap">
                            <label for="country" class="form-label"
                                >{{ $ml.get("businessLicense.stateOfIssueCountry") }}*</label
                            >
                            <div>
                                <input
                                    placeholder="State of Issue / Country"
                                    type="text"
                                    class="text-field"
                                    id="country"
                                    v-model="license.country"
                                />
                            </div>
                        </div>
                        <div class="input-wrap">
                            <label for="information" class="form-label"
                                >{{ $ml.get("businessLicense.licenseInformation") }}*</label
                            >
                            <div>
                                <vue-editor
                                    v-model="license.information"
                                    :editorToolbar="customToolbar"
                                    placeholder="Write license information"
                                ></vue-editor>
                            </div>
                        </div>
                        <div class="input-wrap" v-if="canUploadImage()">
                            <label for="information" class="form-label">{{ $ml.get("businessLicense.images") }}</label>
                            <div class="custom-section-wrap" v-if="canUploadImage()">
                                <div class="">
                                    <div class="license-image-container-wrapper">
                                        <div class="license-image-container">
                                            <div
                                                class="license-img-wrap"
                                                title="Add Photo"
                                                v-on:click="openFileBrowserOverlay(null)"
                                            >
                                                <img
                                                    class="license-images add-image"
                                                    src="../../assets/images/image.svg"
                                                />
                                                <span>{{ $ml.get("businessLicense.addPhoto") }}</span>
                                            </div>
                                        </div>
                                        <div
                                            class="license-image-container"
                                            v-for="(image, index) in license.images"
                                            :key="index"
                                            v-bind:class="{ 'no-padding-bottom': !image.image }"
                                        >
                                            <!--    <img v-if="image.image"  v-on:click="openFileBrowserOverlay(image.id)" class="license-images pointer" :src="'https://storage.sandbox.ringmystylist.com/images/605c96eb7d0bf_package-1426094.jpg'"> -->
                                            <div class="inner-image">
                                                <img
                                                    title="Change Photo"
                                                    v-if="image.image"
                                                    v-on:click="openFileBrowserOverlay(image.id)"
                                                    class="license-images"
                                                    :src="domain + image.image"
                                                />
                                            </div>

                                            <div
                                                v-if="image.image"
                                                class="inner-image-action-block"
                                                v-on:click="onDeleteLicenseImage(image)"
                                            >
                                                <i class="pointer fa fa-trash"></i>
                                                {{ $ml.get("businessLicense.delete") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="licenseImageUpload.show">
                            <div class="image-upload-overlay"></div>
                            <div class="image-upload-overlay-content">
                                <div>
                                    <span>Add Photo</span>
                                    <input
                                        class="image-input-file"
                                        type="file"
                                        ref="licenseImageInputFile"
                                        name="img[]"
                                        accept="image/*"
                                        v-on:change="licenseImageChange($event)"
                                    />
                                </div>
                                <div class="input-group-license">
                                    <div class="input-browse">
                                        <input
                                            type="text"
                                            class="text-field"
                                            disabled
                                            placeholder="Upload File"
                                            v-model="licenseImageUpload.fileName"
                                        />
                                    </div>
                                    <div class="inner-input-group-append">
                                        <button v-on:click="openFileBrowser" type="button" class="primary-btn">
                                            {{ $ml.get("businessLicense.browse") }}
                                        </button>
                                    </div>
                                </div>
                                <div class="license-image-upload-preview" v-if="licenseImageUpload.preview">
                                    <img :src="licenseImageUpload.preview" class="license-images" />
                                </div>
                                <div class="modal-btns">
                                    <button v-on:click="savelicenseImageUpload" type="button" class="primary-btn">
                                        {{ $ml.get("businessLicense.save") }}
                                    </button>
                                    <button v-on:click="cancellicenseImageUpload" type="button" class="secondary-btn">
                                        {{ $ml.get("businessLicense.cancel") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="license-btn-wrap">
                    <div class="license-btn" @click="addLicense">
                        <button class="primary-btn extra-small" type="submit">
                            {{ $ml.get("businessLicense.saveLicense") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import { VueEditor } from "vue2-editor";
import messages from "./../../services/messages.js";
import Vue from "vue";

export default {
    name: "AdminBusinessLicense",
    data: function () {
        return {
            listing: true,
            domain: "",
            user: null,
            stylistProfile: {},
            for_stylist_id: 0,
            licenses: [],
            license: {
                id: 0,
                number: "",
                first_name: "",
                last_name: "",
                stylist_id: "",
                country: "",
                information: "",
                images: [],
            },
            licenseImageUpload: {
                show: false,
                index: 0,
                preview: null,
                fileName: null,
                file: null,
            },
            customToolbar: [
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                    { list: "ordered" },
                    { list: "bullet" },
                ],
            ],
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.for_stylist_id = auth.user.id;

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }

        this.getBusinessLicenses();
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });
    },
    beforeDestroy() {},
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
    },
    methods: {
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.getGroupsMembers(this.adminDetails.group_id);
            }
        },
        getGroupsMembers: function (group_id) {
            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getBusinessLicenses: function () {
            this.$http.get(api.actions.get_business_licenses + "/" + this.for_stylist_id).then(
                function (response) {
                    if (typeof response.data.licenses != "undefined") {
                        this.licenses = response.data.licenses;
                    }
                }.bind(this)
            );
        },
        onStylistChange: function () {
            this.listing = true;
            this.getBusinessLicenses();
        },
        openFileBrowser: function () {
            this.$refs.licenseImageInputFile.click();
        },
        openFileBrowserOverlay: function (index) {
            this.licenseImageUpload.index = index;
            this.licenseImageUpload.show = true;
        },
        licenseImageChange: function (e) {
            let file = e.target.files[0];

            this.licenseImageUpload.file = file;
            this.licenseImageUpload.fileName = file.name;

            let self = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                // get loaded data and render thumbnail.
                self.licenseImageUpload.preview = e.target.result;
            };
            // read the image file as a data URL.
            reader.readAsDataURL(file);
        },
        cancellicenseImageUpload: function () {
            this.licenseImageUpload = {
                index: 0,
                show: false,
                fileName: null,
                preview: null,
                file: null,
            };
        },
        savelicenseImageUpload: function () {
            let formData = new FormData();
            formData.append("image", this.licenseImageUpload.file);
            formData.append("license_id", this.license.id);
            let id = this.license.id;

            if (!id) {
                this.saveLicense(true);
                return;
            }
            this.uploadImageRequest();
        },
        uploadImageRequest: function () {
            let formData = new FormData();
            formData.append("image", this.licenseImageUpload.file);
            formData.append("license_id", this.license.id);

            this.$http.post(api.actions.save_business_license_image, formData).then(function () {
                return false;
            });

            messages.successMessage(messages.GLOBAL_MESSAGES.license_IMAGE_CHANGED);
            this.cancellicenseImageUpload();
        },
        addLicense: function (e) {
            e.preventDefault();
            this.saveLicense();
        },
        saveLicense: function (upload = false) {
            this.license.stylist_id = this.for_stylist_id;
            this.$http.post(api.actions.save_business_license, this.license).then(
                function (response) {
                    if (response.data.error == false) {
                        if (!upload) {
                            this.listing = true;
                            this.getBusinessLicenses();
                            messages.successMessage("The business license has been saved.");
                        } else {
                            this.license.id = response.data.license.id;
                            this.uploadImageRequest();
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        editLicense: function (id) {
            this.listing = false;
            this.$http.get(api.actions.get_business_license + "/" + id).then(
                function (response) {
                    if (typeof response.data.license != "undefined") {
                        let license = response.data.license;
                        this.license = {
                            id: license.id,
                            number: license.number,
                            first_name: license.first_name,
                            last_name: license.last_name,
                            country: license.country,
                            information: license.information,
                            images: license.images,
                        };

                        this.domain = response.data.domain;
                    }
                }.bind(this)
            );
        },
        deleteLicense: function (id) {
            let customClass = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClass = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClass = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }

            Vue.swal({
                title: this.$ml.get("portfolio.confirmQuestion"),
                type: "warning",
                showCancelButton: true,
                customClass: customClass,
                confirmButtonText: this.$ml.get("portfolio.confirmYes"),
                cancelButtonText: this.$ml.get("portfolio.confirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.$http.post(api.actions.delete_business_license, { id: id }).then(
                        function (response) {
                            if (response.data.error == false) {
                                this.listing = true;
                                this.getBusinessLicenses();
                                messages.successMessage("The business license has been deleted.");
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                }
            });
        },
        onDeleteLicenseImage: function (image) {
            let customClass = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClass = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClass = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }

            Vue.swal({
                title: this.$ml.get("portfolio.confirmQuestion"),
                type: "warning",
                showCancelButton: true,
                customClass: customClass,
                confirmButtonText: this.$ml.get("portfolio.confirmYes"),
                cancelButtonText: this.$ml.get("portfolio.confirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.deleteLicenseImage(image);
                }
            });
        },
        deleteLicenseImage: function (image) {
            let data = {
                id: image.id,
            };
            this.$http.post(api.actions.delete_license_image, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PORTFOLIO_IMAGE_DELETED);
                        this.editLicense(this.license.id);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        clearLicense: function () {
            this.license = {
                id: 0,
                number: "",
                first_name: "",
                last_name: "",
                country: "",
                information: "",
                images: [],
            };
            this.listing = false;
        },
        canUploadImage: function () {
            if (
                this.license.number == "" ||
                this.license.first_name == "" ||
                this.license.last_name == "" ||
                this.license.country == ""
            ) {
                return false;
            }
            return true;
        },
    },
    components: {
        VueEditor,
    },
};
</script>
