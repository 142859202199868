<template>
    <aside
        class="admin-sidebar"
        :class="{
            'sidebar-collapsed': sidebarCollapse && !sidebarExpandHover,
            'sidebar-collapsing': sidebarCollapsing,
        }"
    >
        <div v-on:mousedown="onMobileOverlayClick" class="sidebar-mobile-overlay"></div>
        <div>
            <!--v-on:mouseenter="onSidebarHover" v-on:mouseleave="onSidebarOut"-->
            <router-link :to="{ path: '/app' }" class="brand-link brand-link-colors">
                <img
                    class="site-top-logo"
                    src="../../assets/images/rms-logo.png"
                    alt="RMS Logo"
                    v-if="generalOptions.app_id == 1"
                />
                <img
                    class="site-top-logo"
                    src="../../assets/images/rmb-logo.png"
                    alt="RMB Logo"
                    v-if="generalOptions.app_id == 2"
                />
                <img
                    class="site-top-logo"
                    src="../../assets/images/rmg-logo.png"
                    alt="RMG logo"
                    v-if="generalOptions.app_id == 3"
                />
                <img
                    class="site-top-logo"
                    src="../../assets/images/rml-logo.png"
                    alt="RML Logo"
                    v-if="generalOptions.app_id == 5"
                />
            </router-link>
            <perfect-scrollbar class="sidebar-inner">
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="image">
                        <div class="header-member-avatar" v-if="user && user.avatar">
                            <img v-bind:src="user.avatar" />
                        </div>
                        <div
                            class="
                                header-member-avatar
                                sidebar-initials
                                d-flex
                                justify-content-center
                                align-items-center
                            "
                            v-if="user && !user.avatar"
                        >
                            <div>{{ getInitial(user.first_name) }}</div>
                            <div v-if="user.last_name">{{ getInitial(user.last_name) }}</div>
                        </div>
                    </div>
                    <div v-if="user" class="info">
                        <router-link :to="{ path: '/app' }" class="d-block"
                            >{{ user.first_name }} {{ user.last_name }}</router-link
                        >
                        <div v-if="localNotificationCount" class="user-unread-messages">
                            <router-link :to="{ path: '/app/contact-us' }">
                                {{ localNotificationCount }}
                                <span
                                    >unread <span v-if="localNotificationCount === 1">message</span
                                    ><span v-if="localNotificationCount > 1">messages</span>
                                </span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column">
                        <!--Dashboard-->
                        <li class="nav-item">
                            <router-link :to="{ path: '/app' }" class="nav-link" v-on:click.native="closeMobileMenu">
                                <i class="nav-icon fa fa-calendar"></i>
                                <span v-if="user && user.role == 'client'">{{
                                    $ml.get("adminMenu.appointments")
                                }}</span>
                                <span v-else>{{ $ml.get("adminMenu.dashboard") }}</span>
                            </router-link>
                        </li>
                        <!--Profile-->
                        <li class="nav-item">
                            <router-link
                                :to="{ path: '/app/profile' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-user"></i>
                                <span>{{ $ml.get("adminMenu.profile") }}</span>
                            </router-link>
                        </li>
                        <!--Services-->
                        <li class="nav-item" v-if="isStylist" :class="{ 'menu-open': submenusExpanded.services }">
                            <a
                                v-on:click="submenusExpanded.services = !submenusExpanded.services"
                                :class="{ 'router-link-active': submenusExpanded.services }"
                                href="javascript:void(0)"
                                class="nav-link has-subnav"
                            >
                                <i class="nav-icon fa fa fa-handshake-o"></i>
                                <span>{{ $ml.get("adminMenu.servicesAndProducts") }}</span>
                                <i class="pull-right fa fa-angle-left nav-icon-arrow"></i>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/services' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.services") }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/products' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.products") }}</span>
                                    </router-link>
                                </li>

                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/services/categories' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.categories") }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <!--Book-->
                        <li class="nav-item" v-if="!isStylist">
                            <router-link
                                :to="{ path: '/app/stylists' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-users"></i>
                                <span>{{ $ml.get("adminMenu.book") }}</span>
                            </router-link>
                        </li>
                        <!--Clients-->
                        <li class="nav-item" v-if="isStylist">
                            <router-link
                                :to="{ path: '/app/clients' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-users"></i>
                                <span>{{ $ml.get("adminMenu.myClients") }}</span>
                            </router-link>
                        </li>
                        <!--Complete Checkout-->
                        <li class="nav-item" v-if="isStylist">
                            <router-link
                                :to="{ path: '/app/complete-checkout' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-money" aria-hidden="true"></i>
                                <span>{{ $ml.get("adminMenu.completeCheckout") }}({{ checkoutAppointentsCount }})</span>
                            </router-link>
                        </li>
                        <!--Marketing-->
                        <li class="nav-item" v-if="isStylist" :class="{ 'menu-open': submenusExpanded.marketing }">
                            <a
                                v-on:click="submenusExpanded.marketing = !submenusExpanded.marketing"
                                :class="{ 'router-link-active': submenusExpanded.marketing }"
                                href="javascript:void(0)"
                                class="nav-link has-subnav"
                            >
                                <i class="nav-icon fa fa-line-chart"></i>
                                <span>{{ $ml.get("adminMenu.marketing") }}</span>
                                <i class="pull-right fa fa-angle-left nav-icon-arrow"></i>
                            </a>
                            <ul class="nav nav-treeview">
                                <!--Special Offers-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/marketing/special-offers' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.specialOffers") }}</span>
                                    </router-link>
                                </li>
                                <!--Push Notifications-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/marketing/push-notifications' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.pushNotifications") }}</span>
                                    </router-link>
                                </li>
                                <!--Text Messages-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/marketing/text-messages' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.smsNotifications") }}</span>
                                    </router-link>
                                </li>
                                <!--Portfolio Images-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/portfolio-pictures' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.portfolioPictures") }}</span>
                                    </router-link>
                                </li>
                                <!--Social Links-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/marketing/social-links' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.socialLinks") }}</span>
                                    </router-link>
                                </li>
                                <!--Referral-->
                                <li v-if="user && user.role == 'stylist'" class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/referral' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.referral") }}</span>
                                    </router-link>
                                </li>
                                <!--Booking Widget-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/marketing/booking-widget' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.bookingWidget") }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <!--Payments and Deposits-->
                        <li
                            class="nav-item"
                            v-if="isStylist"
                            :class="{ 'menu-open': submenusExpanded.paymentsDeposits }"
                        >
                            <a
                                v-on:click="submenusExpanded.paymentsDeposits = !submenusExpanded.paymentsDeposits"
                                :class="{
                                    'router-link-active': submenusExpanded.paymentsDeposits,
                                }"
                                href="javascript:void(0)"
                                class="nav-link has-subnav"
                            >
                                <i class="nav-icon fa fa-credit-card"></i>
                                <span>{{ $ml.get("adminMenu.paymentsDeposits") }}</span>
                                <i class="pull-right fa fa-angle-left nav-icon-arrow"></i>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/payments/settings' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.paymentSettings") }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/payments/refunds' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.refunds") }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/payments/statements' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.transactions") }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item sub-menu-item" v-if="hasPolicyEnabled">
                                    <router-link
                                        :to="{ path: '/app/per-client-restrictions' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.perClientRestrictions") }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <!--Fees and policies-->
                        <li class="nav-item" v-if="isStylist">
                            <router-link
                                :to="{ path: '/app/policy/fees-and-policies' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-book" aria-hidden="true"></i>
                                <span>{{ $ml.get("adminMenu.feesAndPolicies") }}</span>
                            </router-link>
                        </li>
                        <!--Team-->
                        <!-- <li class="nav-item" v-if="isStylist && showTeam">
                            <router-link
                                :to="{ path: '/app/team' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-users"></i>
                                <span>{{ $ml.get("adminMenu.team") }}</span>
                            </router-link>
                        </li> -->
                        <!------------------------------------------------------->
                        <li
                            class="nav-item"
                            v-if="isStylist && showTeam"
                            :class="{ 'menu-open': submenusExpanded.team }"
                        >
                            <a
                                v-on:click="submenusExpanded.team = !submenusExpanded.team"
                                :class="{ 'router-link-active': submenusExpanded.team }"
                                href="javascript:void(0)"
                                class="nav-link has-subnav"
                            >
                                <i class="nav-icon fa fa-users"></i>
                                <span>{{ $ml.get("adminMenu.team") }}</span>
                                <i class="pull-right fa fa-angle-left nav-icon-arrow"></i>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/shop_details' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.shopDetails") }}</span>
                                    </router-link>
                                </li>
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/team_members' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.teamMembers") }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <!----------------------------------------------------------------------------------------->
                        <!--Team Member-->
                        <li class="nav-item" v-if="isStylist && showTeamMember">
                            <router-link
                                :to="{ path: '/app/team-member' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-users"></i>
                                <span>{{ $ml.get("adminMenu.team") }}</span>
                            </router-link>
                        </li>
                        <!--Payments-->
                        <li class="nav-item" v-if="!isStylist" :class="{ 'menu-open': submenusExpanded.payments }">
                            <a
                                v-on:click="submenusExpanded.payments = !submenusExpanded.payments"
                                :class="{ 'router-link-active': submenusExpanded.payments }"
                                href="javascript:void(0)"
                                class="nav-link has-subnav"
                            >
                                <i class="nav-icon fa fa-credit-card"></i>
                                <span>{{ $ml.get("adminMenu.payments") }}</span>
                                <i class="pull-right fa fa-angle-left nav-icon-arrow"></i>
                            </a>
                            <!--Credit Cards-->
                            <ul class="nav nav-treeview">
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/payments/credit-cards' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.creditCards") }} ({{ totalCards }})</span>
                                    </router-link>
                                </li>
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/payments/transactions' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.transactions") }} ({{ clientPaymentsTotal }})</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <!--Work Settings-->
                        <li class="nav-item" v-if="isStylist" :class="{ 'menu-open': submenusExpanded.workSettings }">
                            <a
                                v-on:click="submenusExpanded.workSettings = !submenusExpanded.workSettings"
                                :class="{ 'router-link-active': submenusExpanded.workSettings }"
                                href="javascript:void(0)"
                                class="nav-link has-subnav"
                            >
                                <i class="nav-icon fa fa-calendar-check-o"></i>
                                <span>{{ $ml.get("adminMenu.workSettings") }}</span>
                                <i class="pull-right fa fa-angle-left nav-icon-arrow"></i>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/work-settings' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.general") }}</span>
                                    </router-link>
                                </li>
                                <!--Service Hours-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/work-settings/service-hours' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.serviceHours") }}</span>
                                    </router-link>
                                </li>
                                <!--Block Out / Unblock-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/work-settings/blockout-unblock' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.blockoutUnblock") }}</span>
                                    </router-link>
                                </li>
                                <!--Business License-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/work-settings/business-license' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.businessLicense") }}</span>
                                    </router-link>
                                </li>
                                <!--Optimize Scheduling-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/work-settings/optimize-scheduling' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.optimizeScheduling") }}</span>
                                    </router-link>
                                </li>
                                <!--Notifications-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/work-settings/notifications' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.notifications") }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <!--Reports-->
                        <li class="nav-item" v-if="isStylist" :class="{ 'menu-open': submenusExpanded.reports }">
                            <a
                                v-on:click="submenusExpanded.reports = !submenusExpanded.reports"
                                :class="{ 'router-link-active': submenusExpanded.reports }"
                                href="javascript:void(0)"
                                class="nav-link has-subnav"
                            >
                                <i class="nav-icon fa fa-bar-chart"></i>
                                <span>{{ $ml.get("adminMenu.reports") }}</span>
                                <i class="pull-right fa fa-angle-left nav-icon-arrow"></i>
                            </a>
                            <ul class="nav nav-treeview">
                                <!--Reports-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/reports/appts' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.apptsReports") }}</span>
                                    </router-link>
                                </li>
                                <!--Products-->
                                <li class="nav-item sub-menu-item">
                                    <router-link
                                        :to="{ path: '/app/reports/products' }"
                                        class="nav-link"
                                        v-on:click.native="closeMobileMenu"
                                    >
                                        <i class="fa fa-circle-o nav-icon"></i>
                                        <span>{{ $ml.get("adminMenu.productsReports") }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <!--Scubscription Payments-->
                        <li v-if="user && user.role == 'stylist'" class="nav-item">
                            <router-link
                                :to="{ path: '/app/subscription-payments' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-credit-card-alt" aria-hidden="true"></i>
                                <span>{{ $ml.get("adminMenu.subscriptionSettings") }}</span>
                            </router-link>
                        </li>
                        <!--Client Notifications Settings-->
                        <li class="nav-item" v-if="!isStylist">
                            <router-link
                                :to="{ path: '/app/client-appointments-notifications' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-bell" aria-hidden="true"></i>
                                <span> {{ $ml.get("adminMenu.clientNotificationsSettings") }}</span>
                            </router-link>
                        </li>
                        <!--Contact Us-->
                        <li class="nav-item">
                            <router-link
                                :to="{ path: '/app/contact-us' }"
                                class="nav-link"
                                v-on:click.native="closeMobileMenu"
                            >
                                <i class="nav-icon fa fa-envelope" aria-hidden="true"></i>
                                <span>{{ $ml.get("adminMenu.contactUs") }}</span>
                            </router-link>
                        </li>
                        <!--Log Out-->
                        <li class="nav-item"><hr /></li>
                        <li class="nav-item log-out-padding">
                            <a href="#" class="nav-link" v-on:click="logOut">
                                <i class="nav-icon fa fa-sign-out" aria-hidden="true"></i>
                                <span>{{ $ml.get("adminMenu.logout") }}</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </perfect-scrollbar>
        </div>
    </aside>
</template>

<script>
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import api from "./../../services/api_endpoints.js";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

export default {
    name: "AdminHeader",
    data: function () {
        return {
            user: null,
            isStylist: false,
            showTeam: false,
            showTeamMember: false,
            accountNavExpanded: true,
            sidebarCollapse: true,
            sidebarExpandHover: false,
            sidebarCollapsing: false,
            submenusExpanded: {
                services: false,
                marketing: false,
                workSettings: false,
                payments: false,
                reports: false,
                paymentsDeposits: false,
                feesAndPolicies: false,
                team: false,
            },
        };
    },
    mounted: function () {
        this.user = auth.user;

        if (auth.user.role == "stylist") {
            this.isStylist = true;
        }

        eventBus.$on("admin_details_loaded", () => {
            if (!this.adminDetails.group_active || (this.adminDetails.group_owner && this.adminDetails.group_active)) {
                this.showTeam = true;
            }

            if (
                this.adminDetails.group_active == 1 &&
                this.adminDetails.active == 1 &&
                !this.adminDetails.group_owner
            ) {
                this.showTeamMember = true;
            }

            if (this.user.role == "client") {
                this.getClientPaymentsTotal();
                this.getStylists();
            } else {
                this.getStylistPolicy();
            }
        });

        eventBus.$on("admin_sidebar_collapse", (collapse) => {
            this.sidebarCollapse = collapse;
            this.sidebarCollapsing = true;

            setTimeout(() => {
                this.sidebarCollapsing = false;
            }, 300);
        });
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        paymentStylists: {
            get: function () {
                return store.state.paymentStylists;
            },
            set: function (value) {
                store.commit("setPaymentStylists", value);
            },
        },
        loadingPaymentStylists: {
            get: function () {
                return store.state.loadingPaymentStylists;
            },
            set: function (value) {
                store.commit("setLoadingPaymentStylists", value);
            },
        },
        squareCards: {
            get: function () {
                return store.state.squareCards;
            },
            set: function (value) {
                store.commit("setSquareCards", value);
            },
        },
        stripeCards: {
            get: function () {
                return store.state.stripeCards;
            },
            set: function (value) {
                store.commit("setStripeCards", value);
            },
        },
        totalCards: {
            get: function () {
                return store.state.totalCards;
            },
            set: function (value) {
                store.commit("setTotalCards", value);
            },
        },
        clientPaymentsTotal: {
            get: function () {
                return store.state.clientPaymentsTotal;
            },
            set: function (value) {
                store.commit("setClientPaymentsTotal", value);
            },
        },
        stylistPolicy: {
            get: function () {
                return store.state.stylistPolicy;
            },
            set: function (value) {
                store.commit("setStylistPolicy", value);
            },
        },
        localNotificationCount: {
            get: function () {
                return store.state.localNotificationCount;
            },
            set: function (value) {
                store.commit("setLocalNotificationCount", value);
            },
        },
        checkoutAppointentsCount: {
            get: function () {
                return store.state.checkoutAppointentsCount;
            },
            set: function (value) {
                store.commit("setCheckoutAppointentsCount", value);
            },
        },
        hasPolicyEnabled: {
            get: function () {
                return store.state.hasPolicyEnabled;
            },
            set: function (value) {
                store.commit("setHasPolicyEnabled", value);
            },
        },
    },
    methods: {
        logOut: function () {
            eventBus.$emit("admin_sidebar_collapse", true);
            auth.logout(true);
            this.$router.push("/");
        },
        getInitial: function (name) {
            if (!name) {
                return;
            }
            return name[0].toUpperCase();
        },
        isActiveRoute(routePath) {
            return this.$router.currentRoute.path.indexOf(routePath) != -1;
        },
        onSidebarHover: function () {
            if (!this.sidebarCollapse || window.innerWidth <= 991) {
                return;
            }

            this.sidebarExpandHover = true;
            this.sidebarCollapsing = true;

            setTimeout(() => {
                this.sidebarCollapsing = false;
            }, 300);
        },
        onSidebarOut: function () {
            this.sidebarExpandHover = false;
        },
        onMobileOverlayClick: function () {
            this.sidebarCollapse = true;
            eventBus.$emit("admin_sidebar_collapse", this.sidebarCollapse);
        },
        getClientPaymentsTotal: function () {
            this.$http.post(api.actions.get_payments_client, { limit: 100 }).then(
                function (response) {
                    this.paymentsLoaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.orders != "undefined" &&
                        response.body.orders.length > 0
                    ) {
                        this.clientPaymentsTotal = response.body.orders.length;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStylists: function () {
            if (this.loadingPaymentStylists) {
                return;
            }

            this.paymentStylists = [];
            this.loadingPaymentStylists = true;
            this.totalCards = 0;
            this.$http.get(api.actions.get_stylists).then(
                function (response) {
                    this.stylistsLoaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.stylists != "undefined"
                    ) {
                        this.loadingPaymentStylists = false;
                        response.body.stylists.forEach((stylist) => {
                            if (stylist.stripe_authorized || stylist.square_authorized) {
                                this.paymentStylists.push(stylist);
                                this.getUserCards(stylist);
                            }
                        });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getUserCards(stylist) {
            var data = {};

            if (stylist.stripe_authorized) {
                this.$http
                    .get(
                        api.actions.get_stripe_payment_methods +
                            "?user_id=" +
                            this.user.id +
                            "&stylist_id=" +
                            stylist.id
                    )
                    .then((response) => {
                        this.addingCard = false;
                        this.cardsLoaded = false;

                        setTimeout(() => {
                            this.cardsLoaded = true;
                        }, 100);

                        if (response.data.error) {
                            return;
                        }

                        this.stripeCards[stylist.id] = response.data.payment_methods;
                        this.getCardsTotal();
                    });
            } else if (stylist.square_authorized) {
                data = {
                    stylist_id: stylist.id,
                    client_id: this.user.id,
                };

                this.$http.post(api.actions.get_square_customer_cards, data).then((response) => {
                    this.addingCard = false;
                    this.cardsLoaded = false;

                    setTimeout(() => {
                        this.cardsLoaded = true;
                    }, 100);

                    if (response.data.error) {
                        return;
                    }

                    this.squareCards[stylist.id] = response.data.response;
                    this.getCardsTotal();
                });
            }
        },
        getCardsTotal() {
            this.totalCards = 0;

            if (this.squareCards) {
                for (let i in this.squareCards) {
                    this.totalCards += this.squareCards[i].length;
                }
            }

            if (this.stripeCards) {
                for (let i in this.stripeCards) {
                    this.totalCards += this.stripeCards[i].length;
                }
            }
        },
        getStylistPolicy: function () {
            this.loaded = false;
            this.$http.get(api.actions.get_stylist_policy).then(
                function (response) {
                    this.loaded = true;
                    if (response.data.error) {
                        return;
                    }
                    if (
                        typeof response != "undefined" &&
                        typeof response.body != "undefined" &&
                        typeof response.body.policy != "undefined"
                    ) {
                        this.stylistPolicy = response.body.policy;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        closeMobileMenu() {
            // if (window.innerWidth > 991) {
            //     this.onSidebarOut();
            //     return;
            // }

            this.onMobileOverlayClick();
        },
    },
    components: {
        PerfectScrollbar,
    },
};
</script>
