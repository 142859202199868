<template>
    <!--Portfolio Pictures Container-->
    <section id="portfolio-pictures">
        <div v-if="user && user.role == 'stylist'" class="portfolio-container">
            <div class="portfolio-heading">
                <h2>{{ $ml.get("portfolio.pictures") }}</h2>
            </div>
            <!--Portfolio Images Container-->
            <div class="container-wrapper section-wrap">
                <!--Add Image-->
                <div v-on:click="openFileBrowserOverlay(null)" class="portfolio-add-image">
                    <div class="">
                        <div>
                            <img class="add-portfolio-images pointer" src="../../assets/images/image.svg" />
                        </div>
                        <div class="text-style add-img">
                            <span>+ Add Image</span>
                        </div>
                    </div>
                </div>
                <!--Portfolio Images-->
                <div
                    class="img-inner-container"
                    v-for="(image, index) in portfolioImages"
                    :key="index"
                    v-bind:class="{ 'no-padding-bottom': !image.image }"
                >
                    <div class="img-inner">
                        <img
                            v-if="image.image"
                            @click.prevent="portfolioImgPreview(image)"
                            class="portfolio-images pointer"
                            :data-img-id="image.id"
                            :src="image.image"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            title="Open Big Picture"
                        />
                    </div>
                    <div v-if="image.image" class="image-tags-block">
                        <div class="image-tags-wrapper">
                            <input
                                @keydown="onImageTagChange(image, 'tag1')"
                                v-model.trim="image.tag1"
                                type="text"
                                placeholder="Enter tag"
                                class="tag"
                                maxlength="15"
                            />
                            <input
                                @keydown="onImageTagChange(image, 'tag2')"
                                v-model.trim="image.tag2"
                                type="text"
                                placeholder="Enter tag"
                                class="tag"
                                maxlength="15"
                            />
                            <input
                                @keydown="onImageTagChange(image, 'tag3')"
                                v-model.trim="image.tag3"
                                type="text"
                                placeholder="Enter tag"
                                class="tag"
                                maxlength="15"
                            />
                        </div>
                    </div>
                    <div v-if="image.image" class="portfolio-image-action-block">
                        <div
                            v-on:click="openFileBrowserOverlay(image.id, image)"
                            title="Change Picture"
                            class="image-actions"
                            :class="
                                (image.tag1change && image.tag1 != '') ||
                                (image.tag2change && image.tag2 != '') ||
                                (image.tag3change && image.tag3 != '')
                                    ? 'disable-icon'
                                    : ''
                            "
                        >
                            <img src="../../assets/images/replace-icon.png" />
                        </div>
                        <div
                            v-on:click="rotateExistingImage(image.id, -90, image)"
                            title="Rotate Left"
                            class="image-actions"
                            :class="
                                (image.tag1change && image.tag1 != '') ||
                                (image.tag2change && image.tag2 != '') ||
                                (image.tag3change && image.tag3 != '')
                                    ? 'disable-icon'
                                    : ''
                            "
                        >
                            <img class="image-left" src="../../assets/images/rotate-left.svg" alt="Image Left" />
                        </div>
                        <div
                            v-on:click="rotateExistingImage(image.id, 90, image)"
                            title="Rotate Right"
                            class="image-actions"
                            :class="
                                (image.tag1change && image.tag1 != '') ||
                                (image.tag2change && image.tag2 != '') ||
                                (image.tag3change && image.tag3 != '')
                                    ? 'disable-icon'
                                    : ''
                            "
                        >
                            <img class="image-right" src="../../assets/images/rotate-right.svg" alt="" />
                        </div>
                        <div
                            v-on:click="onDeletePortfolioImage(image)"
                            title="Delete"
                            class="image-actions"
                            :class="
                                (image.tag1change && image.tag1 != '') ||
                                (image.tag2change && image.tag2 != '') ||
                                (image.tag3change && image.tag3 != '')
                                    ? 'disable-icon'
                                    : ''
                            "
                        >
                            <img class="image-delete" src="../../assets/images/trash-bin.svg" />
                        </div>
                        <div
                            v-if="image.likes > 0"
                            class="image-actions image-action-portfolio-likes"
                            :class="
                                (image.tag1change && image.tag1 != '') ||
                                (image.tag2change && image.tag2 != '') ||
                                (image.tag3change && image.tag3 != '')
                                    ? 'disable-icon'
                                    : ''
                            "
                        >
                            <img
                                v-if="generalOptions.app_id == 1"
                                class="image-delete"
                                title="Likes"
                                src="../../assets/images/Property1RMS.png"
                                alt="Ring for RMS"
                            />
                            <img
                                v-if="generalOptions.app_id == 2"
                                class="image-delete"
                                title="Likes"
                                src="../../assets/images/RMB.png"
                                alt="Ring for RMS"
                            />
                            <img
                                v-if="generalOptions.app_id == 5"
                                class="image-delete"
                                title="Likes"
                                src="../../assets/images/RMP.png"
                                alt="Ring for RMS"
                            />
                            <div class="image-likes">{{ formatLikes(image.likes) }}</div>
                        </div>
                        <div
                            v-if="
                                (image.tag1change && image.tag1 != '') ||
                                (image.tag2change && image.tag2 != '') ||
                                (image.tag3change && image.tag3 != '')
                            "
                            class="image-actions"
                            title="Save Tags"
                            v-on:click="saveImageTags(image)"
                        >
                            <i class="fa fa-floppy-o" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="modal fade bd-example-modal-lg"
                id="image-modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body modal-position-wrapper">
                            <button type="button" class="close position-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="modal-content-big-img">
                                <img id="inner-modal-preview-image" alt="Preview Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Modal-->
            <div v-if="portfolioImageUpload.show">
                <div class="image-upload-overlay"></div>
                <div class="image-upload-overlay-content">
                    <div>
                        <h4 v-if="portfolioImageUpload.index">Change Picture</h4>
                        <h4 v-if="!portfolioImageUpload.index">Add Portfolio Picture</h4>
                    </div>
                    <input
                        class="portfolio-image-input-file"
                        type="file"
                        ref="portfolioImageInputFile"
                        name="img[]"
                        accept="image/*"
                        v-on:change="portfolioImageChange($event)"
                    />
                    <div class="modal-upload">
                        <input
                            type="text"
                            class="selectInput selectInputExtraSmall selectInputSmall"
                            disabled
                            placeholder="Upload File"
                            v-model="portfolioImageUpload.fileName"
                        />
                        <div class="input-group-append">
                            <button
                                v-on:click="openFileBrowser"
                                type="button"
                                class="browse primary-btn primary-btn extra-small mobile"
                            >
                                Browse...
                            </button>
                        </div>
                    </div>
                    <div class="portfolio-image-upload-preview" v-if="portfolioImageUpload.preview">
                        <div ref="portfolioImagePreviewContainer" class="preview-image-container">
                            <img
                                :src="portfolioImageUpload.preview"
                                class="portfolio-images"
                                ref="portfolioImagePreview"
                            />
                        </div>
                    </div>
                    <div v-if="portfolioImageUpload.preview" class="image-preview-controls">
                        <div class="image-actions">
                            <div v-on:click="rotateImage('left')" class="image-rotate rotate-left" title="Rotate Left">
                                <img src="../../assets/images/rotate-left.svg" class="image-left" />
                            </div>
                        </div>
                        <div class="image-actions">
                            <div
                                v-on:click="rotateImage('right')"
                                class="image-rotate rotate-right"
                                title="Rotate Right"
                            >
                                <img src="../../assets/images/rotate-right.svg" class="fa fa-solid fa-rotate-right" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-btns">
                        <button
                            v-on:click="savePortfolioImageUpload"
                            type="button"
                            class="primary-btn primary-btn extra-small mobile"
                        >
                            {{ this.savingPortfolioImageUpload ? "Saving..." : "Save" }}
                        </button>
                        <button
                            v-on:click="cancelPortfolioImageUpload"
                            type="button"
                            class="secondary-btn small mobile"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "../../services/messages.js";
import Vue from "vue";
import $ from "jquery";

export default {
    name: "PortfolioPictures",

    data: function () {
        return {
            user: null,
            portfolioImages: [],
            portfolioImageUpload: {
                show: false,
                index: 0,
                preview: null,
                fileName: null,
                file: null,
                rotate: 0,
                width: 0,
                height: 0,
            },
            savingPortfolioImageUpload: false,
        };
    },

    mounted: function () {
        this.user = auth.user;

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });
    },

    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },

    methods: {
        onAdminDetailsLoad: function () {
            this.getProfile();
            this.getPortfolioImages();
            this.public_address = this.adminDetails.public_address;
        },
        getProfile: function () {
            this.$http.get(api.actions.get_profile_info).then(
                function (response) {
                    if (typeof response.data.user_profile !== "undefined") {
                        this.profile = response.data.user_profile;
                        this.profile.country_id = this.profile.country_id.toString();
                        this.adminDetails.profile.country_id = this.profile.country_id;
                        for (var key in this.editValues) {
                            this.editValues[key] = this.profile[key];
                        }
                    }
                }.bind(this)
            );
        },
        getPortfolioImages: function () {
            this.$http.get(api.actions.get_portfolio_images).then(
                function (response) {
                    if (typeof response.data.images !== "undefined") {
                        this.portfolioImages = [];

                        response.data.images.forEach((image) => {
                            this.portfolioImages.push({
                                image: image.image,
                                pic_order: image.pic_order,
                                id: image.id,
                                likes: image.likes,
                                tag1: image.tag1,
                                tag2: image.tag2,
                                tag3: image.tag3,
                            });
                        });
                        this.portfolioImages = this.portfolioImages.reverse();
                    }
                }.bind(this)
            );
        },
        portfolioImgPreview(img) {
            const modalImg = document.querySelector(".modal-content-big-img img");
            modalImg.src = img.image;
            modalImg.setAttribute("data-image-id", img.id);
        },
        openFileBrowser: function () {
            this.$refs.portfolioImageInputFile.click();
        },
        openFileBrowserOverlay(index, image) {
            if (
                (image && image.tag1change && image.tag1 != "") ||
                (image && image.tag2change && image.tag2 != "") ||
                (image && image.tag3change && image.tag3 != "")
            ) {
                return;
            }

            this.portfolioImageUpload.index = index;
            this.portfolioImageUpload.show = true;
        },
        portfolioImageChange: function (e) {
            let file = e.target.files[0];

            // check how big is the image
            if (file != "") {
                const fileSize = file.size;
                const fileMb = fileSize / 1024 ** 2;

                if (fileMb >= 4) {
                    messages.errorMessage(messages.ERROR_MESSAGES.IMAGE_TO_BIG);

                    return;
                }
            }

            this.portfolioImageUpload.file = file;
            this.portfolioImageUpload.fileName = file.name;

            let self = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                // get loaded data and render thumbnail.
                self.portfolioImageUpload.preview = e.target.result;

                // get the image dimensions
                setTimeout(() => {
                    var element = $(self.$refs.portfolioImagePreview);
                    self.portfolioImageUpload.width = element.width();
                    self.portfolioImageUpload.height = element.width();
                    self.portfolioImageUpload.rotate = 0;
                }, 200);
            };
            // read the image file as a data URL.
            reader.readAsDataURL(file);
        },
        cancelPortfolioImageUpload: function () {
            this.portfolioImageUpload = {
                index: 0,
                show: false,
                fileName: null,
                preview: null,
                file: null,
            };
        },
        savePortfolioImageUpload: function () {
            //do not send request if no file is available
            if (!this.portfolioImageUpload.file || this.savingPortfolioImageUpload) {
                return;
            }

            this.savingPortfolioImageUpload = true;

            //make request to match reset code and change password
            let formData = new FormData();
            formData.append("image", this.portfolioImageUpload.file);
            formData.append("id", this.portfolioImageUpload.index);
            formData.append("rotate", this.portfolioImageUpload.rotate);
            this.$http.post(api.actions.save_portfolio_image, formData).then(
                function (response) {
                    this.savingPortfolioImageUpload = false;

                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PORTFOLIO_IMAGE_CHANGED);
                        this.cancelPortfolioImageUpload();
                        this.getPortfolioImages();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        rotateImage(direction) {
            var rotateDegrees = parseInt(this.portfolioImageUpload.rotate);

            if (direction == "left") {
                rotateDegrees = (rotateDegrees - 90) % 360;
            } else {
                rotateDegrees = (rotateDegrees + 90) % 360;
            }

            this.portfolioImageUpload.rotate = rotateDegrees;
            var element = $(this.$refs.portfolioImagePreview);
            var container = $(this.$refs.portfolioImagePreviewContainer);

            container.css({
                width: this.portfolioImageUpload.width,
                height: this.portfolioImageUpload.height,
                position: "relative",
            });

            element.css({
                transform: "translateX(-50%) translateY(-50%) rotate(" + rotateDegrees + "deg)",
                top: "50%",
                left: "50%",
                position: "absolute",
            });
        },
        rotateLeftModalImage() {
            const modalImage = document.querySelector("#inner-modal-preview-image");
            const imageId = modalImage.getAttribute("data-image-id");

            this.rotateExistingImage(imageId, -90);
        },
        rotateRightModalImage() {
            const modalImage = document.querySelector("#inner-modal-preview-image");
            const imageId = modalImage.getAttribute("data-image-id");

            this.rotateExistingImage(imageId, 90);
        },
        rotateExistingImage(id, rotate, image) {
            if (
                (image.tag1change && image.tag1 != "") ||
                (image.tag2change && image.tag2 != "") ||
                (image.tag3change && image.tag3 != "")
            ) {
                return;
            }

            let data = {
                id: id,
                rotate: rotate,
            };
            this.$http.post(api.actions.rotate_portfolio_image, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getPortfolioImages();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        onDeletePortfolioImage: function (image) {
            if (
                (image.tag1change && image.tag1 != "") ||
                (image.tag2change && image.tag2 != "") ||
                (image.tag3change && image.tag3 != "")
            ) {
                return;
            }

            let customClass = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClass = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClass = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }

            Vue.swal({
                title: this.$ml.get("portfolio.confirmQuestion"),
                text: "You won't be able to revert this!",
                confirmButtonColor: "#00cdc1",
                cancelButtonColor: "#07b2c0",
                customClass: customClass,
                type: "warning",
                showCancelButton: true,
                confirmButtonText: this.$ml.get("portfolio.confirmYes"),
                cancelButtonText: this.$ml.get("portfolio.confirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.deletePortfolioImage(image);
                }
            });
        },
        deletePortfolioImage: function (image) {
            let data = {
                id: image.id,
            };
            this.$http.post(api.actions.delete_portfolio_image, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PORTFOLIO_IMAGE_DELETED);
                        this.getPortfolioImages();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        formatLikes(image_likes) {
            if (image_likes >= 1000) {
                return (image_likes / 1000).toFixed(1) + "k";
            } else {
                return image_likes.toString();
            }
        },
        saveImageTags(image) {
            let data = {
                id: image.id,
                firstTag: image.tag1.toLowerCase(),
                secondTag: image.tag2.toLowerCase(),
                thirdTag: image.tag3.toLowerCase(),
            };
            this.$http.post(api.actions.save_image_tags, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.IMAGE_TAG_SAVED);
                        this.getPortfolioImages();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        disableActionsImage(image, grades) {
            if (
                (image.tag1change && image.tag1 != "") ||
                (image.tag2change && image.tag2 != "") ||
                (image.tag3change && image.tag3 != "")
            ) {
                console.log("do nothing");
            } else {
                this.rotateExistingImage(image.id, grades);
            }
        },
        onImageTagChange(image, tag) {
            image[tag + "change"] = true;
        },
    },

    components: {},
};
</script>
