<template>
    <div class="download-app-component">
        <div class="download-app-cta" v-if="this.generalOptions.stylist_id==0" :class="{'barber-app': (generalOptions.app_id==2)}">
            <div class="cta-block">
                <h2 class="cta-slogan">{{ $ml.with(this.generalOptions.app_name).get('downloadApp.title') }}</h2>
                <p>{{ $ml.get('downloadApp.subtitle') }}</p>
                <div class="cta-form-block w-form">
                    <div class="cta-form">
                        <input v-model="email" type="email" class="cta-text-field w-input" maxlength="256" name="email" :placeholder="$ml.get('downloadApp.emailPlaceholder')">
                        <div class="cta-or-text">{{ $ml.get('downloadApp.inputSeaprator') }}</div>
                        <input v-model="phone" type="text" class="cta-text-field w-input" maxlength="256" name="phone" :placeholder="$ml.get('downloadApp.phonePlaceholder')">
                        <input v-on:click="sendAppLink" type="submit" :value="$ml.get('downloadApp.buttonLabel')" class="cta-submit-button w-button">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from './../services/api_endpoints.js';
import message from './../services/messages.js';

export default {
    name: 'DownloadApp',
    data: function() {
        return {
            email: '',
            phone: '',
        };
    },
    mounted: function () {
    },
    methods: {
        sendAppLink: function() {
            if(!this.email && !this.phone){
                message.errorMessage(this.$ml.get('downloadApp.errorMessage'));
                return;
            }

            let data = {
                email: this.email,
                phone: this.phone,
                app_id: this.generalOptions.app_id
            };

            this.$http.post(api.actions.send_app_link, data).then(function (response) {
                if (response.data.error == true) {
                    return false;
                }

                this.email = '';
                this.phone = '';
                message.successMessage(this.$ml.get('downloadApp.successMessage'));
            }.bind(this));
        },
    },
}
</script>