<template>
    <!--Client Notifications Page-->
    <section id="client-profile">
        <div v-if="user && user.role == 'client'">
            <div class="client-heading">
                <h3>{{ $ml.get("notifications.notificationSettings") }}</h3>
            </div>
            <div class="notifications-message-wrap section-wrap">
                <div class="notifications-container section-wrap">
                    <span class="notifications-paragraph">
                        {{ $ml.get("notifications.chooseNotifications") }}
                    </span>
                    <div class="checkbox-wrapper section-wrap highlighted-box">
                        <div class="checkbox-wrapp">
                            <div><p>Text Message</p></div>
                            <label class="switch">
                                <input
                                    id="text"
                                    type="checkbox"
                                    v-model="receive_sms"
                                    v-on:change="saveNotificationSettings"
                                />
                                <label for="text" class="slider round"></label>
                            </label>
                        </div>
                        <div class="checkbox-wrapp">
                            <div><p>Push notification</p></div>
                            <label class="switch">
                                <input
                                    id="push"
                                    type="checkbox"
                                    v-model="receive_pushnotification"
                                    v-on:change="saveNotificationSettings"
                                />
                                <label for="push" class="slider round"></label>
                            </label>
                        </div>
                        <div class="checkbox-wrapp">
                            <div><p>Email</p></div>
                            <label class="switch">
                                <input
                                    id="email"
                                    type="checkbox"
                                    v-model="receive_email"
                                    v-on:change="saveNotificationSettings"
                                />
                                <label for="email" class="slider round"></label>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="book-btn" v-on:click="onBook()">
                    <div class="primary-btn extra-small mobile">Book a new appointment</div>
                </div>
                <client-notifications></client-notifications>
            </div>
        </div>
    </section>
</template>

<script>
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import ClientNotifications from "./client_notifications.vue";
import messages from "../../services/messages.js";
import store from "../../services/vuex.js";
import eventBus from "../../services/event_bus.js";

export default {
    name: "ClientAppointmentsNotifications",

    data: function () {
        return {
            user: null,
            receive_sms: 0,
            receive_pushnotification: 0,
            receive_email: 0,
        };
    },

    mounted: function () {
        this.user = auth.user;

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });
    },

    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },

    methods: {
        onAdminDetailsLoad: function () {
            this.receive_sms = !this.adminDetails.sms_off;
            this.receive_pushnotification = !this.adminDetails.pushnotifications_off;
            this.receive_email = !this.adminDetails.emails_off;
        },

        saveNotificationSettings() {
            let data = {
                emails_off: !this.receive_email,
                pushnotifications_off: !this.receive_pushnotification,
                sms_off: !this.receive_sms,
            };
            this.$http.post(api.actions.save_user_accounts, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        onBook: function () {
            this.$router.push("/app/stylists");
        },
    },

    components: {
        ClientNotifications,
    },
};
</script>
