<template>
    <!--Services main container-->
    <div class="admin-services">
        <!--Services Heading-->
        <div class="services-heading">
            <h2 v-if="isSectionVisible('services')">
                {{ showProducts ? $ml.get("adminMenu.products") : $ml.get("services.title") }}
            </h2>
            <h2 v-if="isSectionVisible('categories')">
                {{ $ml.get("services.serviceCategories") }}
            </h2>
        </div>
        <!-- Services and Services Categories Container-->
        <div class="services-services-categories-container">
            <!--Services Container-->
            <div class="services-list-container" v-if="isSectionVisible('services')">
                <div class="services-list-heading">
                    <!--Display Prices-->
                    <div class="display-main-prices-container">
                        <div v-if="work_settings" class="display-prices-container">
                            <div class="display-prices-label">
                                <label for="prices-input">{{ $ml.get("services.displayPrices") }}</label>
                            </div>
                            <div class="display-prices-check-input">
                                <label class="switch prices-switch">
                                    <input
                                        id="prices-input"
                                        type="checkbox"
                                        v-bind:value="1"
                                        v-model="displayPrices"
                                        v-on:change="saveDisplayPrices"
                                    />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div v-if="displayPrices === 0 || displayPrices === false" class="display-prices-warning">
                            <span>Your clients will not be able to view the prices of the services.</span>
                        </div>
                    </div>
                </div>
                <div
                    class="section-wrap services-list-wrap"
                    :class="showServiceForm && !editServiceObj.active ? 'inactive-services' : ''"
                >
                    <div>
                        <!-- Add New Service Container-->
                        <div class="add-edit-service-container" v-if="showServiceForm">
                            <div class="service-form" v-if="!showAddCategoryFromServiceForm">
                                <!--Back button and Title-->
                                <div class="add-edit-heading-wrap">
                                    <div class="add-edit-back-btn">
                                        <button
                                            class="primary-btn extra-small mobile"
                                            aria-hidden="true"
                                            v-on:click="onServiceBack"
                                        >
                                            <i class="fa fa-angle-left" aria-hidden="true"></i>&nbsp;Back
                                        </button>
                                    </div>
                                    <div class="add-edit-title">
                                        <h6>
                                            {{
                                                showProducts
                                                    ? $ml.get("services.editProduct")
                                                    : $ml.get("services.editService")
                                            }}
                                        </h6>
                                    </div>
                                </div>
                                <hr />
                                <!--Select Category-->
                                <div class="service-inner-row" v-if="categoriesDropdown != 0">
                                    <div class="service-title">{{ $ml.get("general.category") }}</div>
                                    <div class="add-new-category-select-wrap">
                                        <select
                                            class="selectInput selectInputSmall selectInputExtraSmall scroll-bar"
                                            v-model="editServiceObj.category_id"
                                            @change="onOrderNumber"
                                        >
                                            <option
                                                v-for="(category, index) in categoriesDropdown"
                                                v-bind:value="category.id"
                                                v-bind:key="index"
                                                :disabled="category.active === 0 || category.id == 'shared'"
                                            >
                                                {{
                                                    category.id == 0 && showProducts
                                                        ? "Unassigned products"
                                                        : category.id == 0 && !showProducts
                                                        ? "Unassigned services"
                                                        : category.id == "shared" && showProducts
                                                        ? "Shared Products"
                                                        : category.id == "shared" && !showProducts
                                                        ? "Shared Services"
                                                        : category.name
                                                }}
                                            </option>
                                        </select>
                                        <button
                                            class="primary-btn extra-small mobile"
                                            v-if="showServiceForm"
                                            @click="toggleAddCateogryFromServicesForm"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="add-new-service-btn-container" v-if="categoriesDropdown == 0">
                                    <div class="add-new-service-btn" v-on:click="toggleForm">
                                        <button
                                            class="primary-btn extra-small mobile"
                                            v-if="!showCategoryForm"
                                            v-on:click="onNewCategory"
                                        >
                                            <i class="fa fa-plus"></i> {{ $ml.get("general.addNewCategory") }}
                                        </button>
                                    </div>
                                </div>
                                <!--Service Name-->
                                <div class="service-inner-row">
                                    <div class="service-title">
                                        <span>
                                            <label for="service-name">{{
                                                showProducts
                                                    ? $ml.get("services.productName")
                                                    : $ml.get("services.serviceName")
                                            }}</label>
                                        </span>
                                        <span class="small">&nbsp;(255 char)</span>
                                    </div>
                                    <div>
                                        <input
                                            v-model.trim="editServiceObj.name"
                                            id="service-name"
                                            maxlength="255"
                                            class="text-field"
                                            :class="{ error: isFieldValidated && editServiceObj.name === '' }"
                                            :placeholder="`Enter ${
                                                showProducts
                                                    ? $ml.get('services.productName')
                                                    : $ml.get('services.serviceName')
                                            }`"
                                        />
                                    </div>
                                </div>
                                <!--Service Description-->
                                <div class="service-inner-row">
                                    <div class="service-title">
                                        <span>
                                            <label for="service-desc">{{ $ml.get("services.description") }}</label>
                                        </span>
                                        <span class="small">&nbsp;(500 char)</span>
                                    </div>
                                    <div class="description-wrap">
                                        <textarea
                                            v-model="editServiceObj.description"
                                            id="service-desc"
                                            maxlength="500"
                                            rows="4"
                                            class="text-field inner-service-textarea scroll-bar"
                                            placeholder="Write description"
                                        ></textarea>
                                        <span>{{ editServiceObj.description.length }}/500</span>
                                    </div>
                                </div>
                                <!--Service isPrivate-->
                                <div class="service-inner-row mobile-wrap" v-if="!showProducts">
                                    <div class="service-title">
                                        <label for="is-private"> {{ $ml.get("services.isPrivate") }}</label>
                                    </div>
                                    <div class="private-service-container">
                                        <label class="switch">
                                            <input
                                                id="is-private"
                                                type="checkbox"
                                                v-bind:value="1"
                                                v-model="editServiceObj.is_private"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="service-inner-row" v-if="!showProducts">
                                    <!--Grid element don't delete-->
                                    <div class="grid-elem"></div>
                                    <div>
                                        <span class="field-desc">{{ $ml.get("services.isPrivateDesc") }}</span>
                                    </div>
                                </div>
                                <!--Price-->
                                <div class="service-inner-row-price-up">
                                    <div class="service-title">
                                        <label for="serv-price"> {{ $ml.get("general.price") }}</label>
                                    </div>
                                    <div>
                                        <input
                                            v-model.trim="editServiceObj.price"
                                            id="serv-price"
                                            type="number"
                                            class="text-field"
                                            :class="{ error: isFieldValidated && editServiceObj.price === '' }"
                                            placeholder="Enter price"
                                        />
                                    </div>
                                    <div v-if="!editServiceObj.is_product" class="service-price-and-up-wrap">
                                        <span>
                                            <label for="price-up">{{ $ml.get("services.price_and_up") }}</label></span
                                        >
                                        <label class="switch">
                                            <input
                                                v-model="editServiceObj.price_and_up"
                                                id="price-up"
                                                type="checkbox"
                                                v-bind:value="1"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="!editServiceObj.is_product" class="service-inner-row">
                                    <div class="service-title">
                                        <span>
                                            <label for="serv-duration">{{ $ml.get("general.duration") }}</label></span
                                        >
                                        <span class="small">&nbsp;(min)</span>
                                    </div>
                                    <div>
                                        <input
                                            v-model.trim="editServiceObj.duration"
                                            id="serv-duration"
                                            type="number"
                                            class="text-field"
                                            :class="{ error: isFieldValidated && editServiceObj.duration === '' }"
                                            placeholder="Enter duration"
                                        />
                                    </div>
                                </div>
                                <!--Service Image-->
                                <div class="service-inner-row" :class="showProducts ? 'mobile-view' : ''">
                                    <div class="service-title">{{ $ml.get("services.image") }}</div>
                                    <div class="service-add-image-container">
                                        <!--Image 1-->
                                        <div class="image-wrapper">
                                            <div class="product-image-wrap">
                                                <img
                                                    v-if="editServiceObj.image && !previewImageObj.image_content"
                                                    :src="getProductImage(editServiceObj.image, editServiceObj.user_id)"
                                                    v-on:click="showModal.image = true"
                                                    class="product-image"
                                                />
                                                <img
                                                    v-if="previewImageObj.image_content"
                                                    v-on:click="showModal.image = true"
                                                    :src="previewImageObj.image_content"
                                                    class="product-image"
                                                />
                                            </div>
                                            <div class="product-image-actions">
                                                <div v-on:click="showModal.image = true" class="img-btn">
                                                    <span
                                                        class="uploadImage"
                                                        v-if="!editServiceObj.image && !previewImageObj.image_content"
                                                        >{{ $ml.get("services.uploadImage") }}</span
                                                    >
                                                    <span v-if="editServiceObj.image || previewImageObj.image_content"
                                                        ><i class="fa fa-refresh" aria-hidden="true"></i
                                                    ></span>
                                                </div>
                                                <div
                                                    v-if="editServiceObj.image"
                                                    v-on:click="deleteProductImage('image')"
                                                    class="delete-link img-btn"
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </div>
                                                <div
                                                    v-if="previewImageObj.image_content"
                                                    v-on:click="removeProductImagePreview('image_content')"
                                                    class="delete-link img-btn"
                                                >
                                                    <i class="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Image 2-->
                                        <div class="image-wrapper" v-if="showProducts">
                                            <div class="product-image-wrap">
                                                <img
                                                    v-if="editServiceObj.image1 && !previewImageObj.image1_content"
                                                    :src="
                                                        getProductImage(editServiceObj.image1, editServiceObj.user_id)
                                                    "
                                                    v-on:click="showModal.image1 = true"
                                                    class="product-image"
                                                />
                                                <img
                                                    v-if="previewImageObj.image1_content"
                                                    v-on:click="showModal.image1 = true"
                                                    :src="previewImageObj.image1_content"
                                                    class="product-image"
                                                />
                                            </div>
                                            <div class="product-image-actions">
                                                <div v-on:click="showModal.image1 = true" class="img-btn">
                                                    <span
                                                        class="uploadImage"
                                                        v-if="!editServiceObj.image1 && !previewImageObj.image1_content"
                                                        >{{ $ml.get("services.uploadImage") }}</span
                                                    >
                                                    <span v-if="editServiceObj.image1 || previewImageObj.image1_content"
                                                        ><i class="fa fa-refresh" aria-hidden="true"></i
                                                    ></span>
                                                </div>
                                                <div
                                                    v-if="editServiceObj.image1"
                                                    v-on:click="deleteProductImage('image1')"
                                                    class="delete-link img-btn"
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </div>
                                                <div
                                                    v-if="previewImageObj.image1_content"
                                                    v-on:click="removeProductImagePreview('image1_content')"
                                                    class="delete-link img-btn"
                                                >
                                                    <i class="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Image 3-->
                                        <div class="image-wrapper" v-if="showProducts">
                                            <div class="product-image-wrap">
                                                <img
                                                    v-if="editServiceObj.image2 && !previewImageObj.image2_content"
                                                    :src="
                                                        getProductImage(editServiceObj.image2, editServiceObj.user_id)
                                                    "
                                                    v-on:click="showModal.image2 = true"
                                                    class="product-image"
                                                />
                                                <img
                                                    v-if="previewImageObj.image2_content"
                                                    v-on:click="showModal.image2 = true"
                                                    :src="previewImageObj.image2_content"
                                                    class="product-image"
                                                />
                                            </div>
                                            <div class="product-image-actions">
                                                <div v-on:click="showModal.image2 = true" class="img-btn">
                                                    <span
                                                        title="Upload Image"
                                                        class="uploadImage"
                                                        v-if="!editServiceObj.image2 && !previewImageObj.image2_content"
                                                        >{{ $ml.get("services.uploadImage") }}</span
                                                    >
                                                    <span
                                                        v-if="editServiceObj.image2 || previewImageObj.image2_content"
                                                        title="Change Image"
                                                        ><i class="fa fa-refresh" aria-hidden="true"></i
                                                    ></span>
                                                </div>
                                                <div
                                                    v-if="editServiceObj.image2"
                                                    v-on:click="deleteProductImage('image2')"
                                                    class="delete-link img-btn"
                                                >
                                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                </div>
                                                <div
                                                    v-if="previewImageObj.image2_content"
                                                    v-on:click="removeProductImagePreview('image2_content')"
                                                    title="Delete Image"
                                                    class="delete-link img-btn"
                                                >
                                                    <i class="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Active Days-->
                                <div
                                    v-if="!editServiceObj.is_product"
                                    class="service-inner-row"
                                    :class="!showProducts ? 'mobile-view' : ''"
                                >
                                    <div class="service-title">
                                        {{ $ml.get("services.activeDays") }}
                                    </div>
                                    <div class="inner-days-wrapper">
                                        <span class="service-day" v-for="(day, index) in weekDays" v-bind:key="index">
                                            <div
                                                class="day"
                                                :class="
                                                    editServiceObj.restrictions[day] ? 'enable-user' : 'disabled-user'
                                                "
                                                v-on:click="changeRestriction(day)"
                                            >
                                                <span>{{ day[0] }} </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <!--Service Color-->
                                <div
                                    class="service-inner-row"
                                    v-if="!showProducts"
                                    :class="!showProducts ? 'mobile-view' : ''"
                                >
                                    <div class="service-title">
                                        {{ $ml.get("services.serviceColor") }}
                                    </div>
                                    <div class="inner-days-wrapper">
                                        <span
                                            v-for="(color, i) in categoryColors"
                                            :key="i"
                                            class="category-color"
                                            :style="{ 'background-color': '#' + color.code }"
                                            v-bind:class="{ selected: color.id == editServiceObj.color_id }"
                                            v-on:click="editServiceObj.color_id = color.id"
                                        >
                                        </span>
                                    </div>
                                </div>
                                <!--Active-->
                                <div class="service-inner-row mobile-wrap">
                                    <div class="service-title">
                                        <label for="serv-active">{{ $ml.get("general.active") }}</label>
                                    </div>
                                    <div>
                                        <label class="switch">
                                            <input
                                                id="serv-active"
                                                type="checkbox"
                                                v-bind:value="1"
                                                v-model="editServiceObj.active"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div
                                    class="service-inner-row mobile-wrap"
                                    v-if="adminDetails.group_owner && displayServiceUsers"
                                >
                                    <div>Share {{ !showProducts ? "Service" : "Product" }} with</div>
                                    <div>
                                        <div class="member">
                                            <div v-for="(member, index) in groupMembers" v-bind:key="index">
                                                <div
                                                    class="user-member"
                                                    :class="
                                                        editServiceUsers.indexOf(member.user.id) == -1
                                                            ? 'disabled-user'
                                                            : 'enable-user'
                                                    "
                                                    v-on:click="manageUser(member.user.id)"
                                                >
                                                    <span>{{ getInitial(member.user.first_name) }} </span>
                                                    <span v-if="member.user.last_name">{{
                                                        getInitial(member.user.last_name)
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--Product URL-->
                                <div class="service-inner-row" v-if="showProducts">
                                    <div class="service-title">
                                        <span>
                                            <label for="service-name">{{ $ml.get("services.productUrl") }}</label>
                                        </span>
                                        <span class="small">&nbsp;(500 char)</span>
                                    </div>
                                    <div>
                                        <input
                                            v-model.trim="editServiceObj.product_url"
                                            id="service-name"
                                            type="url"
                                            maxlength="500"
                                            class="text-field"
                                            :class="{
                                                error:
                                                    !!editServiceObj.product_url &&
                                                    this.isValidUrl(this.editServiceObj.product_url) == false,
                                            }"
                                            :placeholder="`Enter ${showProducts ? $ml.get('services.productUrl') : ''}`"
                                        />
                                    </div>
                                </div>
                                <div class="inner-service-btns-container">
                                    <hr />
                                    <div class="inner-service-btns-wrap">
                                        <div class="inner-service-btn-width">
                                            <button
                                                class="primary-btn extra-small mobile"
                                                :class="disableAction.service ? 'action-disabled' : ''"
                                                v-on:click="saveService()"
                                            >
                                                {{ $ml.get("general.save") }}
                                            </button>
                                        </div>
                                        <div class="inner-service-btn-width">
                                            <button
                                                class="secondary-btn small mobile"
                                                :class="disableAction.service ? 'action-disabled' : ''"
                                                v-on:click="onServiceBack"
                                            >
                                                {{ $ml.get("general.cancel") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Add category from service form-->
                            <div class="add-category-from-service-form" v-if="showAddCategoryFromServiceForm">
                                <!--Name-->
                                <div class="category-field-simple">
                                    <div>
                                        <label for="name">
                                            {{ $ml.get("general.categoryName") }}
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            id="name"
                                            type="text"
                                            maxlength="50"
                                            class="text-field"
                                            placeholder="Enter category name"
                                            v-model="editCategoryObj.name"
                                        />
                                    </div>
                                </div>
                                <div class="inner-categories-btns-container">
                                    <div class="inner-width-btns">
                                        <button
                                            class="primary-btn extra-small mobile"
                                            :class="disableAction.category ? 'action-disabled' : ''"
                                            v-on:click="saveAddCategoryFromServiceForm()"
                                        >
                                            {{ $ml.get("general.save") }}
                                        </button>
                                    </div>
                                    <div class="inner-width-btns">
                                        <button
                                            class="secondary-btn small mobile"
                                            :class="disableAction.category ? 'action-disabled' : ''"
                                            v-on:click="toggleAddCateogryFromServicesForm()"
                                        >
                                            {{ $ml.get("general.cancel") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="add-service-btn-show-category-container" v-if="!showServiceForm">
                            <!--Show category select-->
                            <div class="filter-category" v-if="categoriesDropdown != 0">
                                <div class="filter-category-title">{{ $ml.get("general.category") }}</div>

                                <div>
                                    <select
                                        class="selectInput selectInputExtraSmall selectInputSmall scroll-bar"
                                        v-model="serviceCategoryFillter"
                                        @change="setCategoryFilter"
                                    >
                                        <option value="All">All</option>
                                        <option
                                            v-for="(category, index) in categoriesDropdown"
                                            v-bind:value="category.id"
                                            v-bind:key="index"
                                            :disabled="category.active === 0"
                                        >
                                            {{
                                                category.id == 0 && showProducts
                                                    ? "Unassigned products"
                                                    : category.id == 0 && !showProducts
                                                    ? "Unassigned services"
                                                    : category.id == "shared" && showProducts
                                                    ? "Shared Products"
                                                    : category.id == "shared" && !showProducts
                                                    ? "Shared Services"
                                                    : category.name
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="add-new-service-btn">
                                <button class="primary-btn extra-small mobile" v-on:click="onNewService">
                                    <i class="fa fa-plus"></i>
                                    {{ showProducts ? $ml.get("general.addProduct") : $ml.get("general.addService") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="!showServiceForm">
                        <div class="section-wrap disabled-category" v-if="serviceCategoryFillterObj.active == 0">
                            This category is not active. <br />
                            Go to
                            <router-link :to="{ path: '/app/services/service-categories' }" class="enable-category">
                                Service Categories
                            </router-link>
                            and activate it.
                        </div>
                        <div class="services-wrap" v-if="serviceCategoryFillterObj.active != 0">
                            <template v-for="(category, i) in serviceCategories">
                                <div
                                    class="services-service"
                                    v-if="
                                        services[category.id] &&
                                        (serviceCategoryFillter === 'All' ||
                                            (serviceCategoryFillter !== 'All' &&
                                                serviceCategoryFillter === category.id))
                                    "
                                    v-bind:key="i"
                                >
                                    <div class="category-name-wrap">
                                        <div v-if="services[category.id]" class="category-nr-wrap">
                                            <div class="service-category-name">
                                                <span
                                                    class="category-name"
                                                    :style="{ color: categoryColorsMap[category.color_id] }"
                                                ></span>
                                                <span>
                                                    {{
                                                        category.id == 0 && showProducts
                                                            ? "Unassigned products"
                                                            : category.id == 0 && !showProducts
                                                            ? "Unassigned services"
                                                            : category.id == "shared" && showProducts
                                                            ? "Shared Products"
                                                            : category.id == "shared" && !showProducts
                                                            ? "Shared Services"
                                                            : category.name
                                                    }}
                                                </span>
                                            </div>
                                            <div
                                                class="service-order-overlay"
                                                v-if="updateServiceOrder && updatingCategoryId == category.id"
                                            >
                                                <i class="fa fa-spinner fa-spin"></i>
                                            </div>
                                            <div v-if="services[category.id].length > 1" class="nr-services">
                                                Number of {{ showProducts ? "products" : "services" }}
                                                {{ services[category.id].length }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inner-service-content-wrap">
                                        <div
                                            class="service-row"
                                            :id="`service-${service.id}`"
                                            v-for="(service, index) in services[category.id]"
                                            v-bind:key="index"
                                            :class="{
                                                'inactive-services': !service.active,
                                                'service-row-featured': service.featured,
                                                'service-wrap-heading-border': !service.featured,
                                                'shared-service-background': service.user_id != user.id,
                                            }"
                                        >
                                            <div>
                                                <div
                                                    class="services-wrap-header"
                                                    :class="
                                                        service.featured
                                                            ? 'featured-border-heading'
                                                            : 'service-wrap-heading-border'
                                                    "
                                                >
                                                    <div>{{ $ml.get("general.name") }}</div>
                                                    <div>{{ $ml.get("general.price") }}</div>
                                                    <div v-if="!showProducts">{{ $ml.get("general.duration") }}</div>
                                                    <div>{{ $ml.get("general.active") }}</div>
                                                </div>
                                                <div :class="!service.active ? 'inactive-services' : ''">
                                                    <div class="service-name name-row">{{ service.name }}</div>
                                                    <div
                                                        class="service-name-price-duration-active"
                                                        :class="showProducts ? 'three-columns' : ''"
                                                    >
                                                        <div class="currency-price-wrap">
                                                            <span v-if="selectedCurrency == 'USD'"
                                                                >${{ service.price }}</span
                                                            >
                                                            <span v-if="selectedCurrency != 'USD'"
                                                                >{{ service.price }} {{ selectedCurrency }}</span
                                                            >
                                                            <span v-if="service.price_and_up && !service.is_product">
                                                                and up</span
                                                            >
                                                        </div>
                                                        <div v-if="!service.is_product">
                                                            {{ service.duration }} {{ $ml.get("general.min") }}
                                                        </div>
                                                        <div v-if="service.is_product && !showProducts">-</div>
                                                        <div
                                                            :class="
                                                                !service.active
                                                                    ? 'service-active-status'
                                                                    : 'service-active-status'
                                                            "
                                                        >
                                                            {{
                                                                service.active
                                                                    ? $ml.get("general.yes")
                                                                    : $ml.get("general.no")
                                                            }}
                                                        </div>
                                                    </div>

                                                    <!-- Three Dots, Service Image Container and Service Description-->
                                                    <div class="service-image-service-description">
                                                        <div
                                                            class="service-image-service-description-wrap"
                                                            :class="showProducts ? 'product-description' : ''"
                                                        >
                                                            <!--Service Image-->
                                                            <div
                                                                v-if="service.image || service.image1 || service.image2"
                                                                class="service-image"
                                                                :class="showProducts ? 'product-images-wrap' : ''"
                                                            >
                                                                <img
                                                                    v-if="service.image"
                                                                    :src="
                                                                        getProductImage(
                                                                            'thumb_' + service.image,
                                                                            service.user_id
                                                                        )
                                                                    "
                                                                />
                                                                <img
                                                                    v-if="service.image1"
                                                                    :src="
                                                                        getProductImage(
                                                                            'thumb_' + service.image1,
                                                                            service.user_id
                                                                        )
                                                                    "
                                                                />
                                                                <img
                                                                    v-if="service.image2"
                                                                    :src="
                                                                        getProductImage(
                                                                            'thumb_' + service.image2,
                                                                            service.user_id
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                            <!--Service Description-->
                                                            <div class="description-service-wrap">
                                                                <text-toggle
                                                                    :text="service.description"
                                                                    :stringLimit="60"
                                                                ></text-toggle>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--Private Service-->
                                                    <div class="private-service-wrap" v-if="service.is_private">
                                                        <div class="service-desc">
                                                            {{ $ml.get("services.isPrivateDesc") }}.
                                                        </div>
                                                    </div>
                                                    <!--Available Days and Feature Container-->
                                                    <div
                                                        class="service-available-days-feature-container"
                                                        v-if="service.active && service.user_id == user.id"
                                                    >
                                                        <!--Available Days Wrapper-->
                                                        <div class="available-days-wrap">
                                                            <div v-if="!service.is_product" class="available-on">
                                                                {{ $ml.get("services.availableOn") }}
                                                            </div>
                                                            <div v-if="!service.is_product" class="days-wrapper">
                                                                <div
                                                                    class="service-day-active"
                                                                    v-for="(day, index) in weekDays"
                                                                    v-bind:key="index"
                                                                >
                                                                    <div
                                                                        class="restricted-days"
                                                                        v-if="service.restrictions[day] == 0"
                                                                        v-on:click="saveActiveDays(service, day, true)"
                                                                    >
                                                                        {{
                                                                            $ml
                                                                                .get("days." + day)
                                                                                .charAt(0)
                                                                                .toUpperCase()
                                                                        }}
                                                                    </div>
                                                                    <div
                                                                        class="open-days"
                                                                        v-if="service.restrictions[day] == 1"
                                                                        v-on:click="saveActiveDays(service, day, false)"
                                                                    >
                                                                        {{
                                                                            $ml
                                                                                .get("days." + day)
                                                                                .charAt(0)
                                                                                .toUpperCase()
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="every-day"
                                                                    v-if="
                                                                        isServiceRestricted(service) &&
                                                                        !service.is_product
                                                                    "
                                                                    @click="setEveryDay(service)"
                                                                >
                                                                    <span>Every day</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--Times booked and Three service order contianer-->
                                                    <div class="service-times-booked-container">
                                                        <div>
                                                            {{ service.total_booked }}
                                                            {{ $ml.get("services.timesBooked") }} / ${{
                                                                service.total_earned
                                                            }}
                                                            {{ $ml.get("services.serviceRevenue") }}
                                                        </div>
                                                        <!--Product Url-->
                                                        <div v-if="service && service.product_url != ''">
                                                            <a
                                                                class="main-text-link"
                                                                :href="linkHasHttps(service.product_url)"
                                                                target="_blank"
                                                                >Click to see product</a
                                                            >
                                                        </div>
                                                        <!--Three service order-->
                                                        <div
                                                            class="three-service-order"
                                                            :class="{ 'three-service-order-inactive': !service.active }"
                                                            v-if="service.user_id == user.id"
                                                        >
                                                            <!--Service Feature-->
                                                            <div class="service-feature-wrap" v-if="service.active">
                                                                <div class="feature-wrap">
                                                                    <span
                                                                        class="primary-btn extra-small"
                                                                        v-if="!service.featured"
                                                                        v-on:click="saveFeatured(service, true)"
                                                                        >feature</span
                                                                    >
                                                                    <span
                                                                        v-if="service.featured"
                                                                        v-on:click="saveFeatured(service, false)"
                                                                        class="secondary-btn small"
                                                                        >featured</span
                                                                    >
                                                                </div>
                                                                <div class="feature-icon">
                                                                    <div>
                                                                        <i
                                                                            class="fa fa-info-circle"
                                                                            v-tooltip="featureDesc"
                                                                            @touchstart.prevent="
                                                                                showTooltip(service.id)
                                                                            "
                                                                        ></i>
                                                                    </div>
                                                                    <div
                                                                        v-if="
                                                                            showingTooltip &&
                                                                            activeService === service.id
                                                                        "
                                                                        class="tooltip-tablet"
                                                                    >
                                                                        <!-- Tooltip content goes here -->
                                                                        {{ featureDesc }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <select
                                                                id="orderBy"
                                                                class="selectInput"
                                                                v-model="service.orderby"
                                                                @change="saveServiceOrder(service)"
                                                                v-if="services[category.id].length != 0"
                                                            >
                                                                <option disabled>Order</option>
                                                                <!-- <option disabled>0</option> -->
                                                                <option
                                                                    v-for="index in services[category.id].length"
                                                                    v-bind:key="index"
                                                                >
                                                                    {{ index }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="service.user_id != user.id"
                                                        class="shared-service-wrapper"
                                                    >
                                                        This is a shared {{ showProducts ? "product" : "service" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="service-inner-content">
                                                <div :class="service.featured ? 'featured-border' : 'active-services'">
                                                    <div
                                                        :class="!service.active ? 'inactive-services' : ''"
                                                        class="service-edit-delete-btns-service-offeredby-container"
                                                        v-if="service.user_id == user.id"
                                                    >
                                                        <!--Service Edit and Delete buttons-->
                                                        <div class="service-edit-delete-btns">
                                                            <span
                                                                class="primary-btn extra-small extra"
                                                                :class="!service.active ? 'inactive-primary-btn' : ''"
                                                                v-on:click="onEditService(service)"
                                                                >Edit</span
                                                            >
                                                            <span
                                                                class="secondary-btn small extra"
                                                                :class="!service.active ? 'inactive-sec-btn' : ''"
                                                                title="Delete"
                                                                v-on:click="confirmDeleteService(service)"
                                                                >Delete</span
                                                            >
                                                        </div>
                                                        <div
                                                            class="service-offeredeby-initials-members-container"
                                                            v-if="
                                                                adminDetails.group_owner &&
                                                                adminDetails.group_active &&
                                                                service.active
                                                            "
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div
                            v-if="
                                serviceCategoryFillter >= 0 &&
                                (!services[serviceCategoryFillter] ||
                                    (services[serviceCategoryFillter] &&
                                        services[serviceCategoryFillter].length == 0)) &&
                                serviceCategoryFillterObj.active != 0
                            "
                            class="aici section-wrap disabled-category"
                        >
                            No {{ showProducts ? "products" : "services" }} added. <br />
                            Feel free to add at least one.
                        </div>
                    </div>
                </div>
            </div>
            <!--Categories Container-->
            <div class="service-categories-container" v-if="isSectionVisible('categories')">
                <div class="services-categories-heading"></div>
                <div class="section-wrap categories-list-wrap">
                    <!--Service Categories-->
                    <div class="add-new-category-container">
                        <div
                            class="categories-field-wrap"
                            v-if="showCategoryForm"
                            :class="
                                editCategoryObj.active && showCategoryForm ? 'active-category' : 'inactive-category'
                            "
                        >
                            <!--Name-->
                            <div class="category-field-simple">
                                <div>
                                    <label for="name">
                                        {{ $ml.get("general.categoryName") }}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        id="name"
                                        type="text"
                                        maxlength="50"
                                        class="text-field"
                                        placeholder="Enter category name"
                                        v-model="editCategoryObj.name"
                                    />
                                </div>
                            </div>
                            <!--Active-->
                            <div class="category-field-active-order">
                                <div class="active-order-category">
                                    <div>
                                        <label for="active">{{ $ml.get("general.active") }}</label>
                                    </div>
                                    <label class="switch">
                                        <input
                                            id="active"
                                            type="checkbox"
                                            v-bind:value="1"
                                            v-model="editCategoryObj.active"
                                            class="category-active"
                                        />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <!--Category colors-->
                            <div class="category-colors">
                                <span
                                    v-for="(color, i) in categoryColors"
                                    :key="`color-${i}`"
                                    class="category-color"
                                    :style="{ 'background-color': '#' + color.code }"
                                    v-bind:class="{ selected: color.id == editCategoryObj.color_id }"
                                    v-on:click="setCategoryColor(color)"
                                >
                                </span>
                            </div>
                            <!--Group-->
                            <div class="category-field" v-if="adminDetails.group_active && adminDetails.group_owner">
                                <div>
                                    <label for="group">{{ $ml.get("general.group") }}</label>
                                </div>
                                <div>
                                    <label class="switch">
                                        <input
                                            id="group"
                                            type="checkbox"
                                            v-bind:value="1"
                                            v-model="editCategoryObj.group_category"
                                        />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="inner-categories-btns-container">
                                <div class="inner-width-btns">
                                    <button
                                        class="primary-btn extra-small mobile"
                                        :class="disableAction.category ? 'action-disabled' : ''"
                                        v-on:click="saveCategory"
                                    >
                                        {{ $ml.get("general.save") }}
                                    </button>
                                </div>
                                <div class="inner-width-btns">
                                    <button
                                        class="secondary-btn small mobile"
                                        :class="disableAction.category ? 'action-disabled' : ''"
                                        v-on:click="showCategoryForm = !showCategoryForm"
                                    >
                                        {{ $ml.get("general.cancel") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="add-new-service-btn-container">
                            <div class="add-new-service-btn">
                                <button
                                    class="primary-btn extra-small mobile"
                                    v-if="!showCategoryForm"
                                    v-on:click="onNewCategory"
                                >
                                    <i class="fa fa-plus"></i> {{ $ml.get("general.addNewCategory") }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--Categories List-->
                    <div>
                        <div
                            v-if="!showCategoryForm && categoriesDropdown.length > 0"
                            class="categories-list-container"
                        >
                            <template v-for="(category, i) in categories">
                                <div
                                    class="category-list-container"
                                    :class="category.active === 1 ? 'active-category' : 'inactive-category'"
                                    v-if="category.id != 0 && category.id != 'shared'"
                                >
                                    <div class="category-list-wrapper" v-if="category.id">
                                        <div class="category-list">
                                            <div class="inner-category-list">
                                                <div>
                                                    <span
                                                        class="category-color"
                                                        :style="{ 'background-color': '#' + category.colorCode }"
                                                    ></span>
                                                </div>
                                                <div>
                                                    <span class="category-name"> {{ category.name }} </span>
                                                </div>
                                            </div>
                                            <!--Category order-->
                                            <div class="category-order">
                                                <div>{{ $ml.get("services.order") }}</div>
                                                <select
                                                    class="selectInput selectInputExtraSmall"
                                                    v-model="category.ord"
                                                    @change="onEditCategoryOrder(category)"
                                                >
                                                    <option disabled>Order</option>
                                                    <!-- <option value="0" disabled>0</option> -->

                                                    <option
                                                        v-for="index in orderLengthNumber"
                                                        v-bind:value="index"
                                                        v-bind:key="index + '_orderKey'"
                                                        v-if="index !== 0"
                                                    >
                                                        {{ index }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div
                                            class="category-btns"
                                            :class="{ 'align-end': category.services_count == 0 }"
                                        >
                                            <div v-if="category.services_count > 0">
                                                <div
                                                    v-if="
                                                        (category.services_count == 1 &&
                                                            category.products_count == 1) ||
                                                        (category.services_count == 0 &&
                                                            category.products_count == 1) ||
                                                        (category.services_count == 1 && category.products_count == 0)
                                                    "
                                                    class="associated-services"
                                                >
                                                    <div>
                                                        There is
                                                        <span v-if="category.services_count == 1">
                                                            {{ category.services_count }}
                                                            service
                                                        </span>
                                                        <span
                                                            v-if="
                                                                category.services_count == 1 &&
                                                                category.products_count == 1
                                                            "
                                                        >
                                                            and
                                                        </span>
                                                        <span v-if="category.products_count == 1"
                                                            >{{ category.products_count }}
                                                            product
                                                        </span>
                                                        associated with this category.
                                                    </div>
                                                    <div>
                                                        Click to
                                                        <a href="#" @click="unassignServices($event, category.id)"
                                                            >unassign</a
                                                        >
                                                        the service, if you want to
                                                        <span class="delete-service">delete</span> the category.
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="category.services_count > 1 || category.products_count > 1"
                                                    class="associated-services"
                                                >
                                                    <div>
                                                        There are
                                                        <span v-if="category.services_count > 1">
                                                            {{ category.services_count }}
                                                            services
                                                        </span>
                                                        <span
                                                            v-if="
                                                                category.services_count > 1 &&
                                                                category.products_count > 1
                                                            "
                                                        >
                                                            and
                                                        </span>
                                                        <span v-if="category.products_count > 1"
                                                            >{{ category.products_count }}
                                                            products
                                                        </span>
                                                        associated with this category.
                                                    </div>
                                                    <div>
                                                        Click to
                                                        <a href="#" @click="unassignServices($event, category.id)"
                                                            >unassign</a
                                                        >
                                                        those services, if you want to
                                                        <span class="delete-service">delete</span> the category.
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="associated-services"
                                                v-if="category.services_count == 0 && category.products_count == 0"
                                            >
                                                No services and products associated with this category.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="category-btns-wrap">
                                        <div>
                                            <span
                                                class="primary-btn extra-small extra mobile category-btn-width"
                                                v-on:click="onEditCategory(category)"
                                                >Edit</span
                                            >
                                        </div>
                                        <div>
                                            <button
                                                :disabled="category.services_count > 0"
                                                class="secondary-btn small mobile extra category-btn-width"
                                                title="Delete"
                                                :class="{ 'disabled-button': category.services_count > 0 }"
                                                v-on:click="confirmDeleteCategory(category)"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service-preview-and-price-currency-container" v-if="isSectionVisible('services')">
                <!-- Services Preview-->
                <div class="services-preview-container" v-if="servicesPreview.length > 0 && showServiceForm == false">
                    <div class="preview-heading-container">
                        <div>
                            <div class="section-headline">
                                {{
                                    showProducts
                                        ? $ml.get("services.productsPreview")
                                        : $ml.get("services.servicesPreview")
                                }}
                            </div>
                        </div>
                        <div>
                            <a class="main-text-link" target="_blank" :href="portfolio_url"> View Profile</a>
                        </div>
                    </div>
                    <div class="section-wrap service-preview-container-scroll scroll-bar">
                        <div
                            class="services-preview-wrapp"
                            v-for="(service, i) in servicesPreview"
                            v-bind:key="i"
                            :id="i"
                        >
                            <div
                                v-if="service.name.length > 30"
                                v-tooltip="service.name"
                                :class="service.featured ? 'service-name-color' : ''"
                            >
                                <span>{{ service.name.substr(0, 30) + "..." }}</span>
                                <span v-if="service.user_id != user.id"
                                    >(Shared {{ service.is_product == 1 ? "Product" : "Service" }})</span
                                >
                            </div>
                            <div v-if="service.name.length <= 30" :class="service.featured ? 'service-name-color' : ''">
                                <span>{{ service.name }}</span>
                                <span v-if="service.user_id != user.id"
                                    >(Shared {{ service.is_product == 1 ? "Product" : "Service" }})</span
                                >
                            </div>
                            <div class="services-preview-prices">
                                <div v-if="selectedCurrency == 'USD'">${{ service.price }}</div>
                                <div v-if="selectedCurrency != 'USD'">{{ service.price }} {{ selectedCurrency }}</div>
                                <div v-if="service.price_and_up">&nbsp;and up, {{ service.duration }} minutes</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="currency-displayPrices-wrapper" v-if="showServiceForm == false">
                    <!--Currency and Display Prices-->
                    <div class="section-headline">
                        {{ $ml.get("services.currencyDisplayPrices") }}
                    </div>
                    <!--Currency-->
                    <div class="services-currency-container section-wrap">
                        <span>{{ $ml.get("services.currency") }}</span>
                        <div>
                            <select
                                v-model="selectedCurrency"
                                class="selectInput selectInputExtraSmall"
                                v-on:change="saveCurrency"
                            >
                                <option
                                    v-for="(currency, index) in currencies"
                                    :key="`currency-${index}`"
                                    v-on:click="changeCurrency(currency)"
                                >
                                    {{ currency }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <!--Service Image 1-->
            <service-image-modal
                v-if="showModal.image"
                imageField="image"
                :editServiceObj="editServiceObj"
                @close-modal="showModal.image = false"
                @preview-image="getImagePreview"
            ></service-image-modal>
            <!--Service Image 2-->
            <service-image-modal
                v-if="showModal.image1"
                imageField="image1"
                :editServiceObj="editServiceObj"
                @close-modal="showModal.image1 = false"
                @preview-image="getImagePreview"
            ></service-image-modal>
            <!--Service Image 3-->
            <service-image-modal
                v-if="showModal.image2"
                imageField="image2"
                :editServiceObj="editServiceObj"
                @close-modal="showModal.image2 = false"
                @preview-image="getImagePreview"
            ></service-image-modal>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import auth from "./../../services/auth.js";
import api from "./../../services/api_endpoints.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
import Vue from "vue";
import eventBus from "./../../services/event_bus.js";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import ServiceImageModal from "./service_image_modal.vue";
import TextToggle from "./text_toggle.vue";

export default {
    name: "AdminServices",
    props: ["sections", "showProducts"],
    data: function () {
        return {
            user: null,
            portfolio_url: "",
            showServiceForm: false,
            editServiceObj: {
                name: "",
                category_id: 0,
                price: 0,
                duration: 0,
                active: 1,
                restricted: 1,
                restrictions: {
                    sunday: 1,
                    monday: 1,
                    tuesday: 1,
                    wednesday: 1,
                    thursday: 1,
                    friday: 1,
                    saturday: 1,
                },
                group_service: 0,
                group_users: [],
                order: 0,
                description: "",
                price_and_up: 1,
                image: "",
                image1: "",
                image2: "",
                product_url: "",
            },
            editServiceUsers: [],
            displayServiceUsers: false,
            showCategoryForm: false,
            editCategoryObj: {
                name: "",
                active: 1,
                group_category: 0,
                colorCode: "",
                color_id: null,
            },
            group_id: 0,
            services: {},
            categories: [],
            categoriesDropdown: [],
            dataLoaded: false,
            weekDays: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
            disableAction: {
                service: false,
                category: false,
            },
            categoryColors: [],
            categoryColorsMap: {},
            work_settings: {
                display_prices: 0,
            },
            updateServiceOrder: false,
            updatingCategoryId: 0,
            featureDesc: "Featured services will be listed first within the book appointment screen.",
            servicesArray: [],
            selectedCurrency: "USD",
            currency: "USD",
            currencies: ["USD", "Exy", "RUP"],
            displayPrices: 0,
            servicesPreview: [],
            productImageUpload: {
                show: false,
                preview: null,
                fileName: null,
                file: null,
                croppedImage: null,
            },
            serviceCategoryFillter: "All",
            serviceCategoryFillterObj: {},
            showingTooltip: false,
            activeService: null,
            showModal: { image: false, image1: false, image2: false },
            previewImageObj: {
                image_content: null,
                image_name: null,
                image1_content: null,
                image1_name: null,
                image2_content: null,
                image2_name: null,
            },
            deleteImageData: {},
            isFieldValidated: false,
            textNumber: 0,
            orderNumber: 0,
            showAddCategoryFromServiceForm: false,
            lastCategorySaved: "",
            categoryOrderNumber: 0,
            sharedServices: { services: [] },
            isSharedCategoryAdded: false,
            isSharedCategoryFilter: false,
            serviceCategories: [],
            orderLengthNumber: 0,
        };
    },
    watch: {
        $route() {
            this.serviceCategoryFillterObj = {};
        },
    },
    mounted: function () {
        this.user = auth.user;

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        this.getServices();
        this.getCategories();
        this.getWorkSettings();
        this.getCountries();
        this.getProfileLink();
    },

    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
        groupMembers: {
            get: function () {
                return store.state.stylists;
            },
            set: function (value) {
                store.commit("setStylists", value);
            },
        },
    },
    methods: {
        setCategoryFilter() {
            this.categoriesDropdown.forEach((category) => {
                if (category.id == this.serviceCategoryFillter) {
                    this.serviceCategoryFillterObj = category;
                }
            });

            this.onOrderNumber();

            if (
                this.serviceCategoryFillterObj.id == "shared" &&
                this.isSharedCategoryFilter == false &&
                this.serviceCategories[this.serviceCategories.length - 1].id != "shared"
            ) {
                this.serviceCategories.push(this.serviceCategoryFillterObj);
                this.isSharedCategoryFilter = true;
            }
        },

        isSectionVisible(section) {
            if (!this.sections) {
                return true;
            }

            if (this.sections.indexOf(section) != -1) {
                return true;
            }

            return false;
        },
        onAdminDetailsLoad: function () {
            if (this.adminDetails.group_id && this.adminDetails.group_owner) {
                this.group_id = this.adminDetails.group_id;
                this.getGroupsMembersFromApi(this.adminDetails.group_id);
            }

            this.serviceCategories = this.adminDetails.service_categories;
        },
        getGroupsMembersFromApi: function (group_id) {
            this.$http.get(api.actions.get_stylist_group_members + group_id).then(
                function (response) {
                    if (typeof response.data.members != "undefined") {
                        this.groupMembers = response.data.members;
                        this.dataLoaded = true;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getServices: function () {
            let filter = this.showProducts ? "products" : "services";
            this.$http.get(api.actions.get_services + "?filter=" + filter).then(
                function (response) {
                    this.getServicesPreview();
                    if (typeof response.data.currency != "undefined") {
                        this.selectedCurrency = response.data.currency;
                        this.currency = response.data.currency;
                    }
                    if (typeof response.data.services != "undefined") {
                        this.services = {};
                        response.data.services.forEach((el) => {
                            if (typeof this.services[el.category_id] == "undefined") {
                                this.services[el.category_id] = [];
                            }
                            this.services[el.category_id].push(el);
                        });
                        this.makeServicesList();

                        this.setSharedServices();
                        this.setSharedCategory();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getServicesPreview: function () {
            this.$http.get(api.actions.get_services_preview).then(
                function (response) {
                    if (typeof response.data.services != "undefined") {
                        this.servicesPreview = response.data.services.filter((service) => {
                            return service.is_product == this.showProducts;
                        });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        getCategories: function () {
            this.$http.get(api.actions.get_service_categories + "?include_other=1").then(
                function (response) {
                    if (typeof response.data.categories != "undefined") {
                        this.categories = [];

                        this.categoryColors = response.data.colors;
                        let targetColor = null;
                        let self = this;
                        response.data.categories.forEach(function (category) {
                            targetColor = null;
                            targetColor = _.filter(self.categoryColors, { id: category.color_id });
                            if (targetColor.length > 0) {
                                category.colorCode = targetColor[0].code;
                                category.color_id = targetColor[0].id;
                            }
                            self.categories.push(category);
                        });

                        this.categoryColors.forEach(function (category) {
                            self.categoryColorsMap[category.id] = "#" + category.code;
                        });

                        // this.categories = response.data.categories;
                        this.adminDetails.service_categories = this.categories;

                        this.makeServicesList();

                        // no more need to add other category here as its been added from back end now
                        // this.adminDetails.service_categories.push({id: 0, name: this.$ml.get('general.other')});

                        //sort the categories
                        this.categories.sort(function (cat1, cat2) {
                            if (cat1.ord < cat2.ord) {
                                return -1;
                            }
                            if (cat1.ord > cat2.ord) {
                                return 1;
                            }
                            if (cat1.name < cat2.name) {
                                return -1;
                            }
                            if (cat2.name < cat1.name) {
                                return 1;
                            }
                            return 0;
                        });

                        this.categoriesDropdown = this.categories;

                        //I substract the categories.length with one because we have other category that we dont show
                        this.orderLengthNumber = this.categories.length - 1;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        setSharedCategory() {
            this.sharedServices.id = "shared";

            this.sharedServices.name = "Shared";

            if (this.isSharedCategoryAdded == false && this.sharedServices.services.length > 0) {
                this.categoriesDropdown.push(this.sharedServices);
                this.isSharedCategoryAdded = true;
            }
        },
        setSharedServices() {
            this.sharedServices.services = [];

            Object.keys(this.services).forEach((key) => {
                this.services[key].forEach((service) => {
                    if (service.user_id != this.user.id) {
                        this.sharedServices.services.push(service);
                    }
                });
            });
            this.services["shared"] = this.sharedServices.services;
        },

        onServiceBack() {
            this.showServiceForm = false;
            this.isFieldValidated = false;
            this.editServiceObj.active = 1;
            this.serviceCategoryFillter = "All";
            setTimeout(() => {
                this.scrollToService(this.editServiceObj);
            }, 100);
        },

        scrollToService(service) {
            if (service.id) {
                let serviceId = document.getElementById(`service-${service.id}`);
                serviceId.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            }
        },

        scrollBackToAddService() {
            let addService = document.getElementById("add-edit-service-container");
            addService.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        },

        getProfileLink: function () {
            this.$http.get(api.actions.get_profile_info).then(
                function (response) {
                    if (response.data.error == false) {
                        let data = response;
                        this.portfolio_url = data.body.user_profile.portfolio_url;
                    }
                }.bind(this)
            );
        },
        serviceOrderNumber() {},

        saveService: function (service = null) {
            this.isSharedCategoryAdded = false;

            if (!this.validateInput() && this.showServiceForm) {
                messages.errorMessage(messages.GLOBAL_MESSAGES.REQUIRED_FIELDS);
                return;
            }

            let formData = new FormData();

            for (var key in this.editServiceObj) {
                if (key == "active") {
                    this.editServiceObj[key] = this.editServiceObj[key] ? 1 : 0;
                }
                if (key == "is_private") {
                    this.editServiceObj[key] = this.editServiceObj[key] ? 1 : 0;
                }
                if (key == "price_and_up") {
                    this.editServiceObj[key] = this.editServiceObj[key] ? 1 : 0;
                }

                formData.append(key, this.editServiceObj[key]);
            }

            formData.append("monday", this.editServiceObj.restrictions.monday);
            formData.append("tuesday", this.editServiceObj.restrictions.tuesday);
            formData.append("wednesday", this.editServiceObj.restrictions.wednesday);
            formData.append("thursday", this.editServiceObj.restrictions.thursday);
            formData.append("friday", this.editServiceObj.restrictions.friday);
            formData.append("saturday", this.editServiceObj.restrictions.saturday);
            formData.append("sunday", this.editServiceObj.restrictions.sunday);

            if (this.editServiceObj.duration <= 0 && !this.editServiceObj.is_product) {
                Vue.toasted.show(messages.GLOBAL_MESSAGES.SERVICE_DURATION_ERROR, {
                    type: "error",
                    position: "top-center",
                    duration: 2000,
                });
                return;
            }

            this.disableAction.service = true;
            //always restrict the data
            // formData.append("sunday", 1);

            this.$http.post(api.actions.save_service, formData).then(
                function (response) {
                    this.disableAction.service = false;
                    this.updateServiceOrder = false;
                    if (response.data.error == false) {
                        if (this.editServiceObj.group_service) {
                            this.saveServiceUsers(response.data.service.id);
                            this.getCategories();
                        } else {
                            this.getServices();
                            this.showServiceForm = false;
                            this.editServiceObj.active = 1;
                            messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        }
                        let promises = [];
                        if (this.previewImageObj.image_content) {
                            promises.push(
                                this.saveServiceImage(
                                    response.data.service.id,
                                    this.previewImageObj.image_content,
                                    this.previewImageObj.image_name,
                                    "image"
                                )
                            );
                        }
                        //service second image
                        if (this.previewImageObj.image1_content) {
                            promises.push(
                                this.saveServiceImage(
                                    response.data.service.id,
                                    this.previewImageObj.image1_content,
                                    this.previewImageObj.image1_name,
                                    "image1"
                                )
                            );
                        }
                        //service third image
                        if (this.previewImageObj.image2_content) {
                            promises.push(
                                this.saveServiceImage(
                                    response.data.service.id,
                                    this.previewImageObj.image2_content,
                                    this.previewImageObj.image2_name,
                                    "image2"
                                )
                            );
                        }

                        Promise.all(promises).then(this.getServices);
                        this.getCategories();
                        this.onAdminDetailsLoad();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        saveServiceImage(serviceId, imageContent, imageName, imageField) {
            let formData = new FormData();

            formData.append("service_id", serviceId);
            formData.append("content", imageContent);
            formData.append("name", imageName);
            formData.append("field", imageField);

            return this.$http.post(api.actions.save_service_image, formData);
        },
        saveCategory: function () {
            this.disableAction.category = true;

            //remove the color from editCategoryObj if its null
            if (this.editCategoryObj.color_id === null) {
                delete this.editCategoryObj.color_id;
            }

            if (!this.editCategoryObj.id) {
                this.editCategoryObj.order = this.orderLengthNumber + 1;
            }

            this.$http.post(api.actions.save_service_category, this.editCategoryObj).then(
                function (response) {
                    this.disableAction.category = false;
                    if (response.data.error == false) {
                        this.getServices();
                        this.getCategories();
                        this.showCategoryForm = false;
                        this.editServiceObj.category_id = response.data.id;
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveServiceUsers: function (service_id) {
            let data = {
                group_id: this.adminDetails.group_id,
                service_id: service_id,
                users: {},
            };

            this.groupMembers.forEach(
                function (el) {
                    let userId = null;
                    if (typeof el.user !== "undefined") {
                        userId = el.user.id;
                    } else if (typeof el.user_id !== "undefined") {
                        userId = el.user_id;
                    } else {
                        userId = el.id;
                    }
                    data.users[userId] = this.editServiceUsers.indexOf(userId) !== -1 ? 1 : 0;
                }.bind(this)
            );

            this.$http.post(api.actions.save_service_users, data).then(
                function () {
                    this.getServices();
                    this.showServiceForm = false;
                    messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        onNewService: function () {
            this.resetServiceObj();

            this.categoriesDropdown.forEach((category) => {
                if (category.active && this.editServiceObj.category_id == 0) {
                    this.editServiceObj.category_id = category.id;
                }
            });

            if (this.serviceCategoryFillter != "All" && this.serviceCategoryFillter != "shared") {
                this.editServiceObj.category_id = this.serviceCategoryFillter;
            }

            this.onOrderNumber();
            this.showServiceForm = true;
            this.isProduct();
            this.isFieldValidated = false;
        },
        onOrderNumber() {
            this.categoriesDropdown.forEach((category) => {
                if (this.editServiceObj.category_id == category.id) {
                    if (this.showProducts) {
                        this.orderNumber = category.products_count;
                    }

                    if (!this.showProducts) {
                        this.orderNumber = category.services_count;
                    }

                    this.editServiceObj.order = this.orderNumber + 1;
                }
            });
        },
        onNewCategory: function () {
            this.resetCategoryObj();
            this.showCategoryForm = true;
        },
        onEditCategoryOrder(category) {
            this.editCategoryObj = _.clone(category);
            this.editCategoryObj.order = this.editCategoryObj.ord;
            this.editCategoryObj.group_category = category.group_id ? 1 : 0;
            this.saveCategory();
        },
        onEditCategory: function (category) {
            this.showCategoryForm = true;
            this.editCategoryObj = _.clone(category);
            this.editCategoryObj.order = this.editCategoryObj.ord;
            this.editCategoryObj.group_category = category.group_id ? 1 : 0;

            this.scrollToTop();
        },

        scrollToCategory(category) {
            let categoryId = document.getElementById(`category-${category.id}`);
            categoryId.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        },

        onEditService: function (service) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 200);
            this.previewImageObj.image_content = null;
            this.previewImageObj.image_name = null;
            this.previewImageObj.image1_content = null;
            this.previewImageObj.image1_name = null;
            this.previewImageObj.image2_content = null;
            this.previewImageObj.image2_name = null;
            this.showServiceForm = true;
            this.editServiceObj = _.clone(service);
            this.editServiceObj.order = service.orderby;
            this.editServiceObj.group_service = service.group_id ? 1 : 0;
            if (this.adminDetails.group_active && this.adminDetails.group_owner) {
                this.editServiceObj.group_service = true;
            }
            this.displayServiceUsers = this.editServiceObj.group_service;
            this.editServiceUsers = this.editServiceObj.group_users ? this.editServiceObj.group_users : [];

            this.validateInput();
        },
        resetServiceObj: function () {
            this.editServiceObj = {
                name: "",
                category_id: 0,
                price: "",
                duration: "",
                active: 1,
                restricted: 1,
                restrictions: {
                    sunday: 1,
                    monday: 1,
                    tuesday: 1,
                    wednesday: 1,
                    thursday: 1,
                    friday: 1,
                    saturday: 1,
                },
                group_service: 0,
                group_users: [],
                order: 0,
                description: "",
                price_and_up: 1,
                image: "",
                product: "",
            };
            if (this.adminDetails.group_active && this.adminDetails.group_owner) {
                this.editServiceObj.group_service = true;
            }

            //this.populateRestrictions();
            this.displayServiceUsers = this.editServiceObj.group_service;

            this.editServiceUsers = [];
            this.groupMembers.forEach((member) => {
                //based upon user is a group admin/member of a group or a single user
                //we have diff structure for member
                if (typeof member.user !== "undefined") {
                    this.editServiceUsers.push(member.user.id);
                } else if (typeof member.user_id !== "undefined") {
                    this.editServiceUsers.push(member.user_id);
                } else {
                    this.editServiceUsers.push(member.id);
                }
            });
            this.previewImageObj.image_content = null;
            this.previewImageObj.image_name = null;
            this.previewImageObj.image1_content = null;
            this.previewImageObj.image1_name = null;
            this.previewImageObj.image2_content = null;
            this.previewImageObj.image2_name = null;
        },
        resetCategoryObj: function () {
            this.editCategoryObj = {
                name: "",
                active: 1,
                group_category: 0,
                colorCode: "",
                color_id: null,
            };
        },
        onServiceGroupSettingChange: function () {
            this.displayServiceUsers = false;
            this.displayServiceUsers = this.editServiceObj.group_service;
        },
        confirmDeleteService: function (service) {
            Vue.swal({
                title: this.$ml.get("adminDashboard.details.cofirmQuestion"),
                text: "The service will be permanently deleted, and this action cannot be undone!",
                confirmButtonColor: "#00cdc1",
                cancelButtonColor: "#07b2c0",
                confirmButtonText: this.$ml.get("clients.cofirmYes"),
                cancelButtonText: this.$ml.get("clients.cofirmNo"),
                type: "error",
                customClass: {
                    confirmButton: "delete-account",
                    icon: "del-account-error",
                    title: "delete-account-color",
                },
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    this.onDeleteService(service);
                }
            });
        },
        onDeleteService: function (service) {
            let data = {
                id: service.id,
            };

            this.$http.post(api.actions.delete_service, data).then(
                function () {
                    this.getServices();
                    messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_DELETE);
                    this.getCategories();
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        confirmDeleteCategory: function (category) {
            Vue.swal({
                title: this.$ml.get("adminDashboard.details.cofirmQuestion"),
                type: "warning",
                showCancelButton: true,
                confirmButtonText: this.$ml.get("clients.cofirmYes"),
                cancelButtonText: this.$ml.get("clients.cofirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.onDeleteCategory(category);
                }
            });
        },
        onDeleteCategory: function (category) {
            let data = {
                id: category.id,
            };

            this.$http.post(api.actions.delete_service_category, data).then(
                function (response) {
                    this.getCategories();
                    this.getServices();

                    if (response.data.error == false) {
                        this.orderLengthNumber - 1;

                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_DELETE);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        setCategoryColor: function (color) {
            this.editCategoryObj.color_id = color.id;
            this.editCategoryObj.colorCode = color.code;
        },
        getWorkSettings: function () {
            this.$http.get(api.actions.get_work_settings).then(
                function (response) {
                    if (typeof response.data.work_settings != "undefined") {
                        this.work_settings = response.data.work_settings;
                        this.displayPrices = this.work_settings.display_prices;
                    }
                }.bind(this)
            );
        },
        populateRestrictions: function () {
            let self = this;
            this.weekDays.forEach(function (weekDay) {
                self.editServiceObj.restrictions[weekDay] = self.work_settings[weekDay];
            });
        },
        saveActiveDays: function (service, day, status) {
            this.editServiceObj = _.clone(service);
            this.editServiceObj.order = service.orderby;
            this.editServiceObj.group_service = service.group_id ? 1 : 0;
            this.editServiceObj.restrictions[day] = status ? 1 : 0;
            this.saveService();
        },
        setEveryDay(service) {
            this.editServiceObj = _.clone(service);
            this.editServiceObj.order = service.orderby;
            this.editServiceObj.group_service = service.group_id ? 1 : 0;
            this.weekDays.forEach((day) => {
                this.editServiceObj.restrictions[day] = 1;
            });

            this.saveService();
        },

        isServiceRestricted(service) {
            let restricted = false;

            this.weekDays.forEach((day) => {
                if (service.restrictions[day] === 0) {
                    restricted = true;
                }
            });

            return restricted;
        },
        saveFeatured: function (service, featured) {
            this.editServiceObj = _.clone(service);
            this.editServiceObj.featured = featured ? 1 : 0;
            this.saveService();
        },
        saveServiceOrder: function (service) {
            this.editServiceObj = _.clone(service);
            this.editServiceObj.order = this.editServiceObj.orderby;
            this.saveService();
        },
        saveServiceColor: function (service, color_id) {
            this.editServiceObj = _.clone(service);
            this.editServiceObj.color_id = color_id;
            this.saveService();
        },
        getInitial: function (name) {
            if (!name) {
                return;
            }
            return name[0].toUpperCase();
        },
        manageUser: function (userId) {
            if (this.editServiceUsers.indexOf(userId) == -1) {
                this.editServiceUsers.push(userId);
            } else {
                let index = this.editServiceUsers.indexOf(userId);
                this.editServiceUsers.splice(index, 1);
            }
        },
        manageUserPerService: function (userId, service) {
            if (service.group_users.indexOf(userId) == -1) {
                service.group_users.push(userId);
            } else {
                let index = service.group_users.indexOf(userId);
                service.group_users.splice(index, 1);
            }
            service.group_service = true;
            service.order = service.orderby;

            this.updateServiceOrder = true;
            this.saveService(service);
        },
        changeRestriction: function (day) {
            this.editServiceObj.restrictions[day] = this.editServiceObj.restrictions[day] ? 0 : 1;
        },
        makeServicesList: function () {
            if (!this.services || !this.categories.length) {
                return;
            }
            let servicesArray = [];
            _.each(this.services, (category, categoryId) => {
                if (category.length) {
                    let targetCategory = this.categories.find((element) => element.id == categoryId);
                    category.sort(function (serv1, serv2) {
                        if (serv1.orderby < serv2.orderby) {
                            return -1;
                        }
                        if (serv1.orderby > serv2.orderby) {
                            return 1;
                        }
                        if (serv1.name < serv2.name) {
                            return -1;
                        }
                        if (serv2.name < serv1.name) {
                            return 1;
                        }
                        return 0;
                    });
                    category.forEach(function (element) {
                        if (!element.active) {
                            return;
                        }
                        servicesArray.push({
                            name: element.name,
                            price: element.price,
                            selected: element.selected,
                            id: element.id,
                            duration: element.duration,
                            categoryName: targetCategory ? targetCategory.name : "Other",
                            price_and_up: element.price_and_up,
                            featured: element.featured,
                            orderby: parseInt(element.orderby),
                        });
                    });
                }
            });

            //make feature ones come at the top in alphabetica order
            var featuredServices = servicesArray.filter(function (serv) {
                return serv.featured;
            });
            //sort them into alphabetical order
            // descending order
            featuredServices.sort(function (serv1, serv2) {
                if (serv1.name < serv2.name) {
                    return -1;
                }
                if (serv2.name < serv1.name) {
                    return 1;
                }
                return 0;
            });

            var unfeaturedServices = servicesArray.filter(function (serv) {
                return !serv.featured;
            });

            this.servicesArray = featuredServices.concat(unfeaturedServices);
        },
        changeCurrency: function (index) {
            this.selectedCurrency = this.currencies[index];
        },
        getCountries: function () {
            this.$http.get(api.actions.get_countries).then(
                function (response) {
                    if (typeof response.data.countries != "undefined") {
                        response.data.countries.forEach((country) => {
                            this.currencies.push(country.currency);
                        });
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveCurrency: function () {
            //if value is same as before then just cancel
            if (this.selectedCurrency == this.currency) {
                return;
            }
            //make request to save the new profession
            let data = {
                code: this.selectedCurrency,
            };
            this.$http.post(api.actions.save_currency, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
                        this.currency = this.selectedCurrency;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveDisplayPrices: function () {
            //if value is same as before then just cancel
            if (this.work_settings.display_prices == this.displayPrices) {
                return;
            }
            //make request to save the new profession
            let data = {
                display_prices: this.displayPrices,
            };
            this.$http.post(api.actions.save_user_accounts, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
                        this.work_settings.display_prices = this.displayPrices;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        openFileBrowser: function () {
            this.$refs.productImageInputFile.click();
        },
        openFileBrowserOverlay: function (index) {
            this.productImageUpload.index = index;
            this.productImageUpload.show = true;
        },
        productImageChange: function (e) {
            let file = e.target.files[0];
            this.productImageUpload.file = file;
            this.productImageUpload.fileName = file.name;

            //check how big is the image
            if (file != "") {
                const fileSize = file.size;
                const fileMb = fileSize / 1024 ** 2;

                if (fileMb >= 2) {
                    messages.errorMessage(messages.ERROR_MESSAGES.IMAGE_TO_BIG);

                    return;
                }
            }

            let self = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                // get loaded data and render thumbnail.
                // Resize the image
                var image = new Image();
                image.onload = () => {
                    var canvas = document.createElement("canvas"),
                        max_size = 1000,
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0, width, height);
                    let dataUrl = canvas.toDataURL();
                    self.productImageUpload.preview = dataUrl;
                };
                image.src = e.target.result;
            };
            // read the image file as a data URL.
            reader.readAsDataURL(file);
        },
        cancelProductImageUpload: function () {
            this.productImageUpload = {
                show: false,
                fileName: null,
                preview: null,
                file: null,
            };
        },

        getProductImage(image_url, user_id) {
            return api.API_ENDPOINT + "/uploads/images/services/" + user_id + "/" + image_url;
        },
        removeProductImagePreview(imageIdx) {
            this.previewImageObj[imageIdx] = null;
        },
        deleteProductImage(img) {
            if (img == "image") {
                this.deleteImageData = {
                    id: this.editServiceObj.id,
                };
            }

            if (img == "image1") {
                this.deleteImageData = {
                    id: this.editServiceObj.id,
                    image1: 1,
                };
            }

            if (img == "image2") {
                this.deleteImageData = {
                    id: this.editServiceObj.id,
                    image2: 1,
                };
            }

            Vue.swal({
                title: this.$ml.get("adminDashboard.details.cofirmQuestion"),
                type: "error",
                showCancelButton: true,
                confirmButtonText: this.$ml.get("clients.cofirmYes"),
                cancelButtonText: this.$ml.get("clients.cofirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.$http.post(api.actions.delete_service_image, this.deleteImageData).then(
                        function (response) {
                            if (response.data.error == false) {
                                if (img == "image") {
                                    this.previewImageObj.image_content = "";
                                    this.editServiceObj.image = "";
                                }

                                if (img == "image1") {
                                    this.previewImageObj.image1_content = "";
                                    this.editServiceObj.image1 = "";
                                }

                                if (img == "image2") {
                                    this.previewImageObj.image_content2 = "";
                                    this.editServiceObj.image2 = "";
                                }

                                this.getServices();
                                messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_DELETE);
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                }
            });
        },
        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute("data-scale", scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute("data-scale", scale);
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
        resizeImage: function (file) {
            // Ensure it's an image
            if (!file.type.match(/image.*/)) {
                return;
            }

            // Load the image
            var reader = new FileReader();
            reader.onload = (readerEvent) => {
                var image = new Image();
                image.onload = () => {
                    // Resize the image
                    var canvas = document.createElement("canvas"),
                        max_size = 600, // TODO : pull max size from a site config
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0, width, height);
                    let dataUrl = canvas.toDataURL();
                    this.uploadResizedImage(dataUrl);
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        },
        uploadResizedImage: function (dataUrl) {
            if (this.profileImageUpload.preview != null) {
                // get loaded data and render thumbnail.
                this.profileImageUpload.preview = dataUrl;
                // rebuild cropperjs with the updated source
                this.$refs.cropper.replace(dataUrl);
            } else {
                // get loaded data and render thumbnail.
                this.profileImageUpload.preview = dataUrl;
            }
        },

        unassignServices(e, categoryId) {
            e.preventDefault();
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                document.querySelector("body").classList.add("rml-app");
            }

            Vue.swal({
                title: this.$ml.get("portfolio.confirmQuestion"),
                text: "All services and products from this category will be moved to unassigned services or unassigned products",
                confirmButtonColor: "#00cdc1",
                cancelButtonColor: "#07b2c0",
                confirmButtonText: "I understand",
                cancelButtonText: "No",
                type: "error",
                customClass: {
                    confirmButton: "delete-account",
                    icon: "del-account-error",
                    title: "delete-account-color",
                },
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    this.$http.post(api.actions.detach_service_category, { id: categoryId }).then(
                        function (response) {
                            this.getCategories();
                            this.getServices();
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                }
            });
        },
        scrollToTop() {
            const duration = 800; // Scroll duration in milliseconds
            const start = window.scrollY;
            const startTime = "now" in window.performance ? performance.now() : new Date().getTime();

            const scrollToTop = (timestamp) => {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
                window.scrollTo(0, start + (0 - start) * easeInOutCubic(progress));

                if (elapsed < duration) {
                    requestAnimationFrame(scrollToTop);
                }
            };

            requestAnimationFrame(scrollToTop);
        },

        showTooltip(serviceId) {
            this.showingTooltip = !this.showingTooltip;
            this.activeService = serviceId;
        },
        isProduct() {
            if (this.showProducts) {
                this.editServiceObj.is_product = 1;
            }
        },
        toggleProductImageUpload() {
            this.productImageUpload.show = true;
        },
        getImagePreview(obj) {
            if (obj.image_content) {
                this.previewImageObj.image_content = obj.image_content;
                this.previewImageObj.image_name = obj.image_name;
            }
            //service second image
            if (obj.image1_content) {
                this.previewImageObj.image1_content = obj.image1_content;
                this.previewImageObj.image1_name = obj.image1_name;
            }
            //service third image
            if (obj.image2_content) {
                this.previewImageObj.image2_content = obj.image2_content;
                this.previewImageObj.image2_name = obj.image2_name;
            }
        },
        toggleForm() {
            this.showServiceForm = !this.showServiceForm;
        },

        isValidUrl(url) {
            const urlRegex =
                /^(https?:\/\/)?((([-a-z0-9]{1,63}\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\.[a-z]{2,63})|((\d{1,3}\.){3}\d{1,3}))(:\d{1,5})?(\/?([-a-zA-Z0-9@:%_\+.~#?&//=]*))?$/i;
            return urlRegex.test(url);
        },

        validateInput() {
            this.isFieldValidated = true;

            if (this.editServiceObj.name === "") {
                return false;
            }

            if (this.editServiceObj.price === "") {
                return false;
            }

            if (this.editServiceObj.product_url === null || this.editServiceObj.product_url === "") {
                return true;
            } else if (this.editServiceObj.product_url && !this.isValidUrl(this.editServiceObj.product_url)) {
                return false;
            }

            this.isFieldValidated = false;

            return true;
        },
        counterText(text) {
            return (this.textNumber = text.length);
        },
        linkHasHttps(productLink) {
            if (productLink.startsWith("http://") || productLink.startsWith("https://")) {
                return productLink;
            }

            if (!productLink.startsWith("https://")) {
                return `https://${productLink}`;
            }
        },
        toggleAddCateogryFromServicesForm() {
            this.showAddCategoryFromServiceForm = !this.showAddCategoryFromServiceForm;
            this.resetCategoryObj();
        },
        saveAddCategoryFromServiceForm() {
            this.saveCategory();
            this.showAddCategoryFromServiceForm = false;
            this.isSharedCategoryAdded = false;
            this.resetCategoryObj();
        },
    },
    components: {
        VueCropper,
        ServiceImageModal,
        TextToggle,
    },
};
</script>
