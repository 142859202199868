<template>
    <!--Edit Service Image Modal-->
    <div>
        <div class="image-upload-overlay"></div>
        <div class="image-upload-overlay-content image-upload-wrapper">
            <input
                class="product-image-input-file"
                type="file"
                ref="productImageInputFile"
                name="img[]"
                accept="image/*"
                v-on:change="productImageChange($event)"
            />
            <div class="service-image-modal-heading">
                <h4 v-if="!editServiceObj.image && !editServiceObj.image_preview">
                    {{ $ml.get("services.uploadImage") }}
                </h4>
                <h4 v-if="editServiceObj.image || editServiceObj.image_preview">
                    {{ $ml.get("services.changeImg") }}
                </h4>
            </div>
            <div class="service-image-modal-container" style="max-height: 750px; overflow: auto">
                <div class="service-upload-image">
                    <input
                        type="text"
                        class="selectInput"
                        disabled
                        placeholder="Upload File"
                        v-model="productImageUpload.fileName"
                    />
                    <div class="input-group-append">
                        <button
                            v-on:click="openFileBrowser"
                            type="button"
                            class="browse primary-btn extra-small mobile"
                        >
                            Browse...
                        </button>
                    </div>
                </div>
                <div class="profile-image-upload-preview d-flex align-items-center" v-if="productImageUpload.preview">
                    <div>
                        <vue-cropper
                            ref="cropper"
                            :aspect-ratio="1 / 1"
                            :src="productImageUpload.preview"
                            preview=".preview"
                        />
                    </div>
                    <section class="preview-area d-flex justify-content-end">
                        <div class="preview cropped-image">
                            <div class="crop-placeholder" />
                        </div>
                    </section>
                </div>
                <div v-if="productImageUpload.preview" class="modal-btns-actions">
                    <a href="javascript:void(0)" role="button" @click.prevent="zoom(0.2)">
                        <i class="fa fa-search-plus" aria-hidden="true"></i>
                    </a>
                    <a href="javascript:void(0)" role="button" @click.prevent="zoom(-0.2)">
                        <i class="fa fa-search-minus" aria-hidden="true"></i>
                    </a>
                    <a href="javascript:void(0)" role="button" @click.prevent="rotate(90)">
                        <i class="fa fa-repeat" aria-hidden="true"></i>
                    </a>
                    <a href="javascript:void(0)" role="button" @click.prevent="rotate(-90)">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                    </a>
                    <a ref="flipX" href="javascript:void(0)" role="button" @click.prevent="flipX">
                        <i class="fa fa-arrows-h" aria-hidden="true"></i>
                    </a>
                    <a ref="flipY" href="javascript:void(0)" role="button" @click.prevent="flipY">
                        <i class="fa fa-arrows-v" aria-hidden="true"></i>
                    </a>
                    <a href="javascript:void(0)" role="button" @click.prevent="reset">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                    </a>
                </div>
                <!--Modal Buttons-->
                <div class="service-modal-btns-container">
                    <button v-on:click="saveProductImageUpload" type="button" class="primary-btn extra-small mobile">
                        Save
                    </button>
                    <button v-on:click="cancelProductImageUpload" type="button" class="secondary-btn small mobile">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import Vue from "vue";
import messages from "./../../services/messages.js";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";

export default {
    props: ["imageField", "editServiceObj"],
    emits: ["close-modal"],
    data: function () {
        return {
            productImageUpload: {
                show: false,
                preview: null,
                fileName: null,
                file: null,
                croppedImage: null,
            },
            disableAction: {
                service: false,
                category: false,
            },
            user: null,
        };
    },

    mounted: function () {
        this.user = auth.user;
    },
    beforeDestroy() {},
    computed: {},
    methods: {
        openFileBrowser: function () {
            this.$refs.productImageInputFile.click();
        },
        openFileBrowserOverlay: function (index) {
            this.productImageUpload.index = index;
            this.productImageUpload.show = true;
        },
        saveProductImageUpload: function () {
            if (!this.productImageUpload.file) {
                return;
            }

            let imageData = this.$refs.cropper.getCroppedCanvas().toDataURL();

            if (this.imageField == "image") {
                this.$emit("preview-image", { image_content: imageData, image_name: this.productImageUpload.fileName });
                this.$emit("close-modal");
            }

            if (this.imageField == "image1") {
                this.$emit("preview-image", {
                    image1_content: imageData,
                    image1_name: this.productImageUpload.fileName,
                });
                this.$emit("close-modal");
            }
            if (this.imageField == "image2") {
                this.$emit("preview-image", {
                    image2_content: imageData,
                    image2_name: this.productImageUpload.fileName,
                });
                this.$emit("close-modal");
            }

            return;
        },
        cancelProductImageUpload: function () {
            this.productImageUpload = {
                show: false,
                fileName: null,
                preview: null,
                file: null,
            };

            this.$emit("close-modal");
        },
        productImageChange: function (e) {
            let file = e.target.files[0];
            this.productImageUpload.file = file;
            this.productImageUpload.fileName = file.name;

            let self = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                // get loaded data and render thumbnail.
                // Resize the image
                var image = new Image();
                image.onload = () => {
                    var canvas = document.createElement("canvas"),
                        max_size = 800,
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0, width, height);
                    let dataUrl = canvas.toDataURL();
                    self.productImageUpload.preview = dataUrl;
                };
                image.src = e.target.result;
            };
            //read the image file as a data URL.
            reader.readAsDataURL(file);
        },

        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute("data-scale", scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute("data-scale", scale);
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
        getProductImage(image_url) {
            return api.API_ENDPOINT + "/uploads/images/services/" + this.user.id + "/" + image_url;
        },
    },
    components: { VueCropper },
};
</script>
