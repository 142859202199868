<template>
    <div class="accounts">
        <div v-if="isSectionVisible('account-settings')" class="row text-left ml-3 ml-lg-0">
            <div class="col-12">
                <h3>{{ $ml.get("adminMenu.accountSettings") }}</h3>
            </div>
        </div>
        <!-- basic details -->
        <div v-if="user && isSectionVisible('account-settings')" class="row pl-3 mt-2 text-left section">
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-4 mb-2">
                        <div class="section-wrap">
                            <div class="row ml-1">
                                {{ $ml.get("notifications.Timezone") }}
                            </div>
                            <div class="row mt-2 ml-1">
                                <select
                                    class="selectorMaxWidth"
                                    v-model="notification_settings.timezone"
                                    v-on:change="saveClientsNotifications"
                                >
                                    <option value="0">{{ $ml.get("notifications.selectTimezone") }}</option>
                                    <option
                                        v-for="(timezone, index) in timezonesList"
                                        v-bind:key="index"
                                        v-bind:value="index"
                                    >
                                        {{ timezone }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 mb-2">
                        <div class="section-wrap">
                            <div class="row ml-1">
                                {{ $ml.get("notifications.Language") }}
                            </div>
                            <div class="row mt-2 ml-1">
                                <select v-model="notification_settings.language" v-on:change="saveClientsNotifications">
                                    <option value="el">el</option>
                                    <option value="en">en</option>
                                    <option value="es">es</option>
                                    <option value="it">it</option>
                                    <option value="pt">pt</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div v-if="user.role == 'stylist'" class="col-lg-4 mb-2">
                        <div class="section-wrap">
                            <div class="row ml-1">
                                {{ $ml.get("notifications.dateTimeFormat") }}
                            </div>
                            <div class="row mt-2 ml-1">
                                <select
                                    class="selectorMaxWidth"
                                    v-model="notification_settings.datetime_format"
                                    v-on:change="saveClientsNotifications"
                                >
                                    <option
                                        v-for="(datetime_format, index) in datetime_formats"
                                        v-bind:key="index"
                                        v-bind:value="index"
                                    >
                                        {{ datetime_format }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-12 ml-3 ml-lg-0">
                <div v-if="user.role == 'stylist'" class="element d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <div>{{ $ml.get("accounts.profession") }}:</div>
                        <div v-if="professions.length > 1" class="dropdown">
                            <button
                                class="btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {{ selectedProfessionShow }}
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a
                                    class="dropdown-item"
                                    href="#"
                                    v-on:click="changeSelectedProfession(index)"
                                    v-for="(profession, index) in professions"
                                    v-bind:key="index"
                                    >{{ profession.profession }}</a
                                >
                            </div>
                        </div>
                        <a
                            v-if="professions.length > 1"
                            class="text-primary"
                            href="#"
                            v-on:click="saveProfession"
                            :class="selectedProfessionShow == userProfession ? 'action-disabled' : ''"
                            >Save</a
                        >
                    </div>
                    <div class="ml-2" v-if="professions.length == 1">
                        {{ professions[0].profession }}
                    </div>
                </div>
                <div class="element d-flex align-items-center">
                    <div>{{ $ml.get("accounts.firstName") }}:&nbsp;</div>
                    <div v-if="!edit['first_name']">{{ user.first_name }}</div>
                    <div v-if="edit['first_name']" class="input-group w-50 mx-3">
                        <input v-model="editValues.first_name" type="text" name="first_name" class="form-control" />
                    </div>
                    <div v-if="!edit['first_name']" class="ml-3">
                        <i v-on:click="editField('first_name')" class="fa fa-pencil pointer" aria-hidden="true"></i>
                    </div>
                    <div v-if="edit['first_name']" class="ml-2">
                        <i v-on:click="saveField('first_name')" class="fa fa-check pointer"></i>
                        <i v-on:click="cancelField('first_name')" class="fa fa-times ml-3 pointer"></i>
                    </div>
                </div>
                <div class="element d-flex align-items-center">
                    <div>{{ $ml.get("accounts.lastName") }}:&nbsp;</div>
                    <div v-if="!edit['last_name']">{{ user.last_name }}</div>
                    <div v-if="edit['last_name']" class="input-group w-50 mx-3">
                        <input v-model="editValues.last_name" type="text" name="last_name" class="form-control" />
                    </div>
                    <div v-if="!edit['last_name']" class="ml-3">
                        <i v-on:click="editField('last_name')" class="fa fa-pencil pointer" aria-hidden="true"></i>
                    </div>
                    <div v-if="edit['last_name']" class="ml-2">
                        <i v-on:click="saveField('last_name')" class="fa fa-check pointer"></i>
                        <i v-on:click="cancelField('last_name')" class="fa fa-times ml-3 pointer"></i>
                    </div>
                </div>
                <div class="element d-flex align-items-center">
                    <div v-if="passwordState === 0">{{ $ml.get("accounts.password") }}:&nbsp;&nbsp;</div>
                    <div v-if="passwordState === 1">{{ $ml.get("accounts.newPassword") }}:&nbsp;&nbsp;</div>
                    <div v-if="passwordState === 2">{{ $ml.get("accounts.enterResetCode") }}:&nbsp;&nbsp;</div>
                    <div>
                        <div class="row h-100" v-if="passwordState === 0">
                            <div class="col-12">
                                <a v-on:click="resetPassword" class="text-primary" href="#">{{
                                    $ml.get("accounts.resetPassword")
                                }}</a>
                            </div>
                        </div>
                        <div class="row" v-if="passwordState === 1">
                            <div class="col-12 d-flex align-items-center">
                                <div class="input-group w-50 mx-3">
                                    <input
                                        v-model="password"
                                        type="text"
                                        name="new_password"
                                        class="form-control"
                                        placeholder="New Password"
                                    />
                                </div>
                                <a
                                    :class="!password || password.length < 5 ? 'action-disabled' : ''"
                                    v-on:click="resetPassword(true)"
                                    class="text-primary"
                                    href="#"
                                    >Save</a
                                >
                                <a v-on:click="resetPassword(false)" class="text-primary ml-3" href="#">Cancel</a>
                            </div>
                        </div>
                        <div class="row" v-if="passwordState === 2">
                            <div class="col-12 d-flex align-items-center">
                                <div class="input-group w-50 mx-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1"
                                            >{{ resetCodeInitials }} -</span
                                        >
                                    </div>
                                    <input
                                        v-model="resetCode"
                                        type="text"
                                        name="code"
                                        class="form-control"
                                        placeholder="Reset Code"
                                    />
                                </div>
                                <a
                                    :class="!resetCode ? 'action-disabled' : ''"
                                    v-on:click="resetPassword(true)"
                                    class="text-primary"
                                    href="#"
                                    >Confirm</a
                                >
                                <a v-on:click="resetPassword(false)" class="text-primary ml-3" href="#">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="element d-flex align-items-center mr-5 mr-sm-0 justify-content-end">
                    <div>{{ $ml.get("accounts.email") }}:&nbsp;</div>
                    <div v-if="!edit['email']">{{ user.email }}</div>
                    <div v-if="edit['email']" class="input-group w-50 mx-3">
                        <input v-model="editValues.email" type="text" name="email" class="form-control" />
                    </div>
                    <div v-if="!edit['email']" class="ml-3">
                        <i v-on:click="editField('email')" class="fa fa-pencil pointer" aria-hidden="true"></i>
                    </div>
                    <div v-if="edit['email']" class="ml-2">
                        <i v-on:click="saveField('email')" class="fa fa-check pointer"></i>
                        <i v-on:click="cancelField('email')" class="fa fa-times ml-3 pointer"></i>
                    </div>
                </div>
                <div class="element d-flex align-items-center mr-5 mr-sm-0 justify-content-end">
                    <span>{{ $ml.get("accounts.phone") }}:&nbsp;</span>
                    <span>{{ user.phone_prefix }} {{ user.phone }}</span>
                </div>
                <div class="element d-flex align-items-center mr-5 mr-sm-0 justify-content-end">
                    <div>{{ $ml.get("accounts.zip") }}:&nbsp;</div>
                    <div v-if="!edit['zip']">{{ user.zip }}</div>
                    <div v-if="edit['zip']" class="input-group w-50 mx-3">
                        <input v-model="editValues.zip" type="text" name="zip" class="form-control" />
                    </div>
                    <div v-if="!edit['zip']" class="ml-3">
                        <i v-on:click="editField('zip')" class="fa fa-pencil pointer" aria-hidden="true"></i>
                    </div>
                    <div v-if="edit['zip']" class="ml-2">
                        <i v-on:click="saveField('zip')" class="fa fa-check pointer"></i>
                        <i v-on:click="cancelField('zip')" class="fa fa-times ml-3 pointer"></i>
                    </div>
                </div>
                <div class="element d-flex align-items-center mr-5 mr-sm-0 justify-content-end align-items-center">
                    <div v-if="phoneState === 0">{{ $ml.get("accounts.cellPhone") }}:&nbsp;</div>
                    <div v-if="phoneState === 1">{{ $ml.get("accounts.newCellNumber") }}:&nbsp;</div>
                    <div v-if="phoneState === 2">{{ $ml.get("accounts.verificationCode") }}:&nbsp;</div>
                    <div>
                        <div class="row h-100" v-if="phoneState === 0">
                            <div class="col-12">
                                <a v-on:click="changePhone" class="text-primary" href="#">Change Phone Number</a>
                            </div>
                        </div>
                        <div class="row" v-if="phoneState === 1">
                            <div class="col-12 d-flex align-items-center">
                                <div class="dropdown">
                                    <button
                                        class="btn dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {{ selectedCountryShow }}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            v-on:click="changeSelectedCountry(index)"
                                            v-for="(country, index) in countries"
                                            v-bind:key="index"
                                            >{{ country.name }}({{ country.dial_code }})</a
                                        >
                                    </div>
                                </div>
                                <div class="input-group w-50 mx-3">
                                    <input
                                        v-model="phone"
                                        type="text"
                                        name="phone"
                                        class="form-control"
                                        placeholder="New Cell Number"
                                        pattern="[0-9]{1}[0-9]{9}"
                                        maxlength="10"
                                    />
                                </div>
                                <a
                                    :class="!phone || phoneProgress ? 'action-disabled' : ''"
                                    v-on:click="changePhone(true)"
                                    class="text-primary"
                                    href="#"
                                    >Save</a
                                >
                                <a v-on:click="changePhone(false)" class="text-primary ml-3" href="#">Cancel</a>
                            </div>
                        </div>
                        <div class="row" v-if="phoneState === 2">
                            <div class="col-12 d-flex align-items-center">
                                <div class="input-group w-100 mx-3">
                                    <input
                                        v-model="verificationCode"
                                        type="text"
                                        name="verificationCode"
                                        class="form-control"
                                        :placeholder="$ml.get('accounts.verificationCode')"
                                        pattern="[0-9]{1}[0-9]{9}"
                                        maxlength="10"
                                    />
                                </div>
                                <a
                                    :class="!verificationCode || phoneProgress ? 'action-disabled' : ''"
                                    v-on:click="changePhone(true)"
                                    class="text-primary"
                                    href="#"
                                    >Verify</a
                                >
                                <a v-on:click="changePhone(false)" class="text-primary ml-3" href="#">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="element text-right" v-if="phoneNotification">
                    <small class="font-italic">{{ phoneNotification }}</small>
                </div>
            </div>
        </div>

        <div
            v-if="user && user.role == 'client' && isSectionVisible('client-notification-settings')"
            class="row ml-3 ml-lg-0 text-left mt-4"
        >
            <div class="col-12">
                <h3>{{ $ml.get("notifications.notificationSettings") }}</h3>
            </div>
            <div class="col-12 col-lg-8 ml-3 mt-3">
                <p>
                    Choose your preferred method of contact.<br />(you may receive offers or notices from your stylist,
                    barber, or loctician)
                </p>
                <div class="d-flex align-items-center mt-3">
                    <div>Text Message</div>
                    <label class="switch mb-0 ml-3">
                        <input type="checkbox" v-model="receive_sms" v-on:change="saveNotificationSettings" />
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center mt-3">
                    <div>Push notification</div>
                    <label class="switch mb-0 ml-3">
                        <input
                            type="checkbox"
                            v-model="receive_pushnotification"
                            v-on:change="saveNotificationSettings"
                        />
                        <span class="slider round"></span>
                    </label>
                </div>
                <div class="d-flex align-items-center mt-3">
                    <div>Email</div>
                    <label class="switch mb-0 ml-3">
                        <input type="checkbox" v-model="receive_email" v-on:change="saveNotificationSettings" />
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <!--Payment Settings Subpage-->
        <div
            v-if="user && user.role == 'stylist' && isSectionVisible('payment-settings')"
            class="payments-settings-container"
        >
            <!--Heading Subpage-->
            <div class="payment-settings-heading">
                <h3>{{ $ml.get("adminMenu.paymentSettings") }}</h3>
                <div class="header-message">{{ $ml.get("accounts.acceptsCreditCards") }}</div>
            </div>
            <!-- Payment Settings Content -->
            <div class="payment-settings-content">
                <div class="enrolled-message" v-if="stripe_authorized && accept_payments && stripe_connect_update_url">
                    {{ $ml.get("accounts.enrolledStripe") }}
                </div>
                <div class="enrolled-message" v-if="square_authorized && square_access_token && square_application_id">
                    {{ $ml.get("accounts.enrolledSquare") }}
                </div>
                <div class="not-enrolled-message" v-if="!accept_payments || (!stripe_authorized && !square_authorized)">
                    {{ $ml.get("accounts.notEnrolled") }}
                </div>
                <!-- Stripe -->
                <div class="payment-stripe-square-container section-wrap">
                    <div class="logo-wrap">
                        <label for="stripe-logo" class="stripe-image">
                            <img class="tiktok-img" src="../../assets/images/stripe-logo.svg" width="100" />
                        </label>
                        <label class="switch">
                            <input
                                type="checkbox"
                                id="stripe-logo"
                                v-model="stripe_authorized"
                                v-on:change="manageStripeModal"
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="stripe-content highlighted-box section-wrap" v-if="stripe_authorized">
                        <div v-if="stripe_authorized" class="">
                            <div class="stripe-title">{{ $ml.get("accounts.engineeredGrowth") }}</div>
                            <div class="payment-stripe-wrap">
                                <div class="section-wrap">
                                    <div class="sub-title">{{ $ml.get("accounts.payAsYouGo") }}</div>
                                    <div class="stripe-text">{{ $ml.get("accounts.cardCharge") }}</div>
                                    <div class="stripe-text">{{ $ml.get("accounts.payOnlyForWhatYouUse") }}</div>
                                    <div class="stripe-text">{{ $ml.get("accounts.bankAccountNumber") }}</div>
                                </div>
                                <div class="section-wrap">
                                    <div class="sub-title">{{ $ml.get("accounts.payouts") }}</div>
                                    <div class="stripe-text">{{ $ml.get("accounts.accountWillArrive") }}</div>
                                    <div class="stripe-text">
                                        {{ $ml.get("accounts.forMoreDetails") }}
                                        <a
                                            href="https://stripe.com/docs/payouts"
                                            class="main-text-link"
                                            target="”_blank”"
                                            >https://stripe.com/docs/payouts</a
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="connect-to-stripe">
                                <button
                                    v-if="!stripe_connect_update_url && !loading"
                                    type="button"
                                    @click="open_stripe_modal = true"
                                    class="primary-btn extra-small"
                                >
                                    {{ $ml.get("accounts.connectToStripe") }}
                                </button>
                            </div>
                        </div>
                        <div
                            v-if="stripe_authorized && accept_payments && stripe_connect_update_url"
                            class="update-stripe"
                        >
                            <a :href="stripe_connect_update_url" class="main-text-link" target="_blank">{{
                                $ml.get("accounts.updateStripe")
                            }}</a>
                        </div>
                    </div>
                    <!-- Square -->
                    <div class="logo-wrap">
                        <label for="square-logo" class="stripe-image">
                            <img class="tiktok-img" src="../../assets/images/square-logo.svg" width="100" />
                        </label>
                        <label class="switch">
                            <input
                                type="checkbox"
                                id="square-logo"
                                v-model="square_authorized"
                                v-on:change="manageSquareModal(false)"
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <div class="payment-square-wrap highlighted-box section-wrap" v-if="square_authorized">
                        <div class="square-details">
                            <div class="section-wrap">
                                {{ $ml.get("accounts.forFees") }}
                                <a target="_blank" class="main-text-link" href="https://squareup.com/help/us/en"
                                    >https://squareup.com/help/us/en</a
                                >
                            </div>
                        </div>
                        <div v-if="square_authorized" class="section-wrap">
                            <div class="sub-title">{{ $ml.get("accounts.instructions") }}</div>
                            <div class="access-token">
                                <a
                                    class="main-text-link"
                                    href="https://www.ringmystylist.com/support/square-how-to-retrieve-application-id-and-access-token/"
                                    >{{ $ml.get("accounts.retrievedApplication") }}</a
                                >
                            </div>
                            <div class="square-input-wrap">
                                <div class="text-field text-field-small no-border-radius-right">
                                    <label for="square-app-id">
                                        {{ $ml.get("accounts.appId") }}
                                    </label>
                                </div>
                                <input
                                    type="text"
                                    class="text-field text-field-small no-border-radius-left"
                                    id="square-app-id"
                                    placeholder="Please enter your Square Application Id"
                                    aria-label="Username"
                                    aria-describedby="square-app-id"
                                    v-model="square_application_id"
                                />
                            </div>
                            <div class="square-input-wrap">
                                <div class="text-field text-field-small no-border-radius-right">
                                    <label for="square-access-token">
                                        {{ $ml.get("accounts.accessToken") }}
                                    </label>
                                </div>
                                <input
                                    type="text"
                                    id="square-access-token"
                                    class="text-field text-field-small no-border-radius-left"
                                    placeholder="Please enter your Square Access Token"
                                    aria-label="Username"
                                    aria-describedby="square-access-token"
                                    v-model="square_access_token"
                                />
                            </div>
                            <div class="square-save">
                                <button
                                    v-if="!save_square_progress"
                                    type="button"
                                    class="primary-btn extra-small"
                                    v-on:click="saveSquare"
                                >
                                    Save
                                </button>
                            </div>
                            <div v-if="save_square_progress" class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="save-payment-wrap">
                <button
                    type="button"
                    class="primary-btn extra-small"
                    :class="disable_save_payment_choices ? 'action-disabled' : ''"
                    v-on:click="savePaymentChoices(null)"
                >
                    {{ $ml.get("accounts.savePayment") }}
                </button>
            </div>
        </div>
        <!--Payment Settings Subpage-->
        <div v-if="stripe_authorized && accept_payments && open_stripe_modal && isSectionVisible('payment-settings')">
            <div class="stripe-connect-modal-overlay"></div>
            <div class="stripe-connect-modal rounded">
                <div v-on:click="cancelStripeConnect" class="stripe-connect-modal-close">
                    <i class="fa fa-times"></i>
                </div>
                <div v-html="stripe_connect_content"></div>
            </div>
        </div>
        <!--Widget Settings Main Subpage-->
        <div class="widget-settings-main-container">
            <!--Widget Settings subpage-->
            <div
                v-if="user && user.role == 'stylist' && isSectionVisible('widget-settings')"
                class="widget-settings-container"
            >
                <div class="widget-settings-heading">
                    <h3>{{ $ml.get("adminMenu.widgetSettings") }}</h3>
                </div>
                <div v-if="bookNowWidgetSettingActive" class="widget-settings-content section-wrap highlighted-box">
                    <div class="widget-wrap">
                        <!--Left side-->
                        <div class="widget-left-side section-wrap">
                            <div class="widget-field mobile-widget-field">
                                <span>Button Label: </span>
                                <input type="text" class="text-field text-field-small" v-model="buttonLabel" />
                            </div>

                            <div class="widget-field mobile-widget-field">
                                <span>Button Border Radius: </span>
                                <input type="number" class="text-field text-field-small" v-model="buttonBorderRadius" />
                            </div>

                            <div class="widget-field color-picker-container">
                                <span>Button Color: </span>
                                <div
                                    v-on:click="openColorPicker"
                                    class="widget-color-icon"
                                    v-bind:style="'background-color:' + buttonColor"
                                ></div>
                                <div v-if="showColorPicker" class="color-picker" :style="{ background: buttonColor }">
                                    <color-picker
                                        theme="dark"
                                        :color="buttonColor"
                                        :sucker-hide="true"
                                        @changeColor="changeColor"
                                        :colors-default="[
                                            '#000000',
                                            '#FFFFFF',
                                            '#FF1900',
                                            '#FFE623',
                                            '#6EFF2A',
                                            '#2E81FF',
                                            '#FF89CF',
                                        ]"
                                    />
                                    <div class="d-flex">
                                        <button
                                            type="button"
                                            class="btn btn-light d-block m-auto"
                                            v-on:click="closeColorPicker"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="widget-field color-picker-container">
                                <span>Button Text Color: </span>
                                <div
                                    v-on:click="openTextColorPicker"
                                    class="widget-color-icon"
                                    v-bind:style="'background-color:' + buttonTextColor"
                                ></div>
                                <div
                                    v-if="showTextColorPicker"
                                    class="color-picker"
                                    :style="{ background: buttonTextColor }"
                                >
                                    <color-picker
                                        theme="dark"
                                        :color="buttonTextColor"
                                        :sucker-hide="true"
                                        @changeColor="changeTextColor"
                                        :colors-default="[
                                            '#000000',
                                            '#FFFFFF',
                                            '#FF1900',
                                            '#FFE623',
                                            '#6EFF2A',
                                            '#2E81FF',
                                            '#FF89CF',
                                        ]"
                                    />
                                    <div class="d-flex">
                                        <button
                                            type="button"
                                            class="btn btn-light d-block m-auto"
                                            v-on:click="closeTextColorPicker"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="widget-field color-picker-container">
                                <span>Button Border Color: </span>
                                <div
                                    v-on:click="openBorderColorPicker"
                                    class="widget-color-icon"
                                    v-bind:style="'background-color:' + buttonBorderColor"
                                ></div>
                                <div
                                    v-if="showBorderColorPicker"
                                    class="color-picker"
                                    :style="{ background: buttonBorderColor }"
                                >
                                    <color-picker
                                        theme="dark"
                                        :color="buttonBorderColor"
                                        :sucker-hide="true"
                                        @changeColor="changeBorderColor"
                                        :colors-default="[
                                            '#000000',
                                            '#FFFFFF',
                                            '#FF1900',
                                            '#FFE623',
                                            '#6EFF2A',
                                            '#2E81FF',
                                            '#FF89CF',
                                        ]"
                                    />
                                    <div class="d-flex">
                                        <button
                                            type="button"
                                            class="btn btn-light d-block m-auto"
                                            v-on:click="closeBorderColorPicker"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="widget-field color-picker-container">
                                <span>Model background Color: </span>
                                <div
                                    v-on:click="openModelBgColorPicker"
                                    class="widget-color-icon"
                                    v-bind:style="'background-color:' + modelBackgroundColor"
                                ></div>
                                <div
                                    v-if="showModelBgColorPicker"
                                    class="color-picker"
                                    :style="{ background: modelBackgroundColor }"
                                >
                                    <color-picker
                                        theme="dark"
                                        :color="modelBackgroundColor"
                                        :sucker-hide="true"
                                        @changeColor="changeModelBgColor"
                                        :colors-default="[
                                            '#000000',
                                            '#FFFFFF',
                                            '#FF1900',
                                            '#FFE623',
                                            '#6EFF2A',
                                            '#2E81FF',
                                            '#FF89CF',
                                        ]"
                                    />
                                    <div class="d-flex">
                                        <button
                                            type="button"
                                            class="btn btn-light d-block m-auto"
                                            v-on:click="closeModelBgColorPicker"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="widget-field color-picker-container">
                                <span>Model Text Color: </span>
                                <div
                                    v-on:click="openModelTextColorPicker"
                                    class="widget-color-icon"
                                    v-bind:style="'background-color:' + modelTextColor"
                                ></div>
                                <div
                                    v-if="showModelTextColorPicker"
                                    class="color-picker"
                                    :style="{ background: modelTextColor }"
                                >
                                    <color-picker
                                        theme="dark"
                                        :color="modelTextColor"
                                        :sucker-hide="true"
                                        @changeColor="changeModelTextColor"
                                        :colors-default="[
                                            '#000000',
                                            '#FFFFFF',
                                            '#FF1900',
                                            '#FFE623',
                                            '#6EFF2A',
                                            '#2E81FF',
                                            '#FF89CF',
                                        ]"
                                    />
                                    <div class="d-flex">
                                        <button
                                            type="button"
                                            class="btn btn-light d-block m-auto"
                                            v-on:click="closeModelTextColorPicker"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="widget-field color-picker-container">
                                <span>Model Header Background Color: </span>
                                <div
                                    v-on:click="openModelHeaderBgColorPicker"
                                    class="widget-color-icon"
                                    v-bind:style="'background-color:' + modelHeaderBackgroundColor"
                                ></div>
                                <div
                                    v-if="showModelHeaderBgColorPicker"
                                    class="color-picker"
                                    :style="{ background: modelHeaderBackgroundColor }"
                                >
                                    <color-picker
                                        theme="dark"
                                        :color="modelHeaderBackgroundColor"
                                        :sucker-hide="true"
                                        @changeColor="changeModelHeaderBgColor"
                                        :colors-default="[
                                            '#000000',
                                            '#FFFFFF',
                                            '#FF1900',
                                            '#FFE623',
                                            '#6EFF2A',
                                            '#2E81FF',
                                            '#FF89CF',
                                        ]"
                                    />
                                    <div class="d-flex">
                                        <button
                                            type="button"
                                            class="btn btn-light d-block m-auto"
                                            v-on:click="closeModelHeaderBgColorPicker"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="widget-field color-picker-container">
                                <span>Model Header Text Color: </span>
                                <div
                                    v-on:click="openModelHeaderTextColorPicker"
                                    class="widget-color-icon"
                                    v-bind:style="'background-color:' + modelHeaderTextColor"
                                ></div>
                                <div
                                    v-if="showModelHeaderTextColorPicker"
                                    class="color-picker"
                                    :style="{ background: modelHeaderTextColor }"
                                >
                                    <color-picker
                                        theme="dark"
                                        :color="modelHeaderTextColor"
                                        :sucker-hide="true"
                                        @changeColor="changeModelHeaderTextColor"
                                        :colors-default="[
                                            '#000000',
                                            '#FFFFFF',
                                            '#FF1900',
                                            '#FFE623',
                                            '#6EFF2A',
                                            '#2E81FF',
                                            '#FF89CF',
                                        ]"
                                    />
                                    <div class="d-flex">
                                        <button
                                            type="button"
                                            class="btn btn-light d-block m-auto"
                                            v-on:click="closeModelHeaderTextColorPicker"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Right side-->
                        <div class="widget-right-side section-wrap">
                            <button
                                :style="{
                                    'border-radius': buttonBorderRadius + 'px',
                                    'background-color': buttonColor,
                                    color: buttonTextColor,
                                    border: '1px solid ' + buttonBorderColor,
                                }"
                            >
                                {{ buttonLabel }}
                            </button>
                            <div class="demo-modal-wrap">
                                <div class="demo-modal-heading">Model</div>
                                <div
                                    class="demo-model-header"
                                    :style="{
                                        'background-color': modelHeaderBackgroundColor,
                                        color: modelHeaderTextColor,
                                    }"
                                >
                                    Model Header
                                </div>
                                <div
                                    class="demo-model-body"
                                    :style="{ 'background-color': modelBackgroundColor, color: modelTextColor }"
                                >
                                    Model Body
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="save-widget-btn">
                        <div>
                            <button type="button" class="primary-btn extra-small" v-on:click="saveWidgetChoices">
                                {{ $ml.get("general.save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--Widget Snippet-->
            <div
                v-if="
                    bookNowWidgetSettingActive &&
                    idHash &&
                    user &&
                    user.role == 'stylist' &&
                    isSectionVisible('widget-settings')
                "
                class="widget-snippet-container section-wrap highlighted-box"
            >
                <div class="widget-snippet-heading">
                    <h4>{{ $ml.get("adminMenu.widgetSnippet") }}</h4>
                </div>
                <div class="widget-textarea-wrap">
                    <textarea class="widget-textarea" rows="2" disabled 
                        :value="widgetTextArea1 + widgetTextArea2"></textarea>
                </div>
                <div class="copy-widget-btn">
                    <div>
                        <button type="button" class="primary-btn extra-small" v-on:click="copyToClipboard">Copy</button>
                    </div>
                </div>
            </div>
            <!--Profile Widget Settings-->
            <div
                v-if="user && user.role == 'stylist' && isSectionVisible('widget-settings')"
                class="section-wrap highlighted-box"
            >
                <div>
                    <h4>{{ $ml.get("adminMenu.profileWidgetSettings") }}</h4>
                </div>
                <div class="members-wrapper section-wrap">
                    <div class="members-list">
                        <div class="member-header">
                            <div>{{ $ml.get("general.name") }}</div>
                            <div>{{ $ml.get("general.active") }}</div>
                        </div>
                        <div class="member" v-for="(settings, id) in profileWidgetSettings" v-bind:key="id">
                            <div class="member-row-cell">
                                {{ settings.name }}
                            </div>
                            <div class="input-btns-wrap">
                                <label class="switch">
                                    <input
                                        type="checkbox"
                                        v-bind:value="1"
                                        v-model="settings.active"
                                        v-on:change="displayProfileWidgetSaveButton = true"
                                        class="member-active"
                                    />
                                    <span class="slider round"></span>
                                </label>
                                <span v-if="settings.id_hash && settings.active" class="widget-snippet-button-wrap">
                                    <button
                                        type="button"
                                        class="primary-btn extra-small"
                                        v-on:click="
                                            copyWidgetCodeToClipboard(getProfileWidgetSnippet(settings.id_hash))
                                        "
                                    >
                                        Copy Snippet
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="displayProfileWidgetSaveButton" class="save-widget-btn">
                    <div>
                        <button type="button" class="primary-btn extra-small" v-on:click="saveProfileWidgetSettings">
                            {{ $ml.get("general.save") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import eventBus from "./../../services/event_bus.js";
import messages from "./../../services/messages.js";
import moment from "moment";
import LoadScript from "vue-plugin-load-script";
import Vue from "vue";

import colorPicker from "@caohenghu/vue-colorpicker";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.use(LoadScript);
Vue.loadScript("https://js.stripe.com/v3/");

export default {
    name: "AdminBook",
    props: ["sections"],
    data: function () {
        return {
            user: null,
            passwordState: 0,
            phoneState: 0,
            phoneNotification: null,
            password: null,
            phone: null,
            resetCode: null,
            resetCodeInitials: null,
            countries: [],
            prefixes: null,
            selectedCountry: {},
            selectedCountryShow: null,
            professions: [],
            selectedProfession: {},
            selectedProfessionShow: null,
            userProfession: null,
            edit: {
                first_name: false,
                last_name: false,
                email: false,
                zip: false,
            },
            editValues: {
                first_name: null,
                last_name: null,
                email: null,
                zip: null,
            },
            accept_payments: false,
            stripe_authorized: false,
            stripe_connect_content: "",
            stripe_connect_update_url: "",
            changeProfileImage: false,
            profileImageUpload: {
                preview: null,
                fileName: null,
                file: null,
                croppedImage: null,
            },
            enableProfilePicSave: true,
            verificationCode: null,
            phoneProgress: false,
            square_authorized: false,
            open_stripe_modal: false,
            open_stripe_info: false,
            square_access_token: null,
            square_application_id: null,
            open_square_modal: false,
            save_square_progress: false,

            stripe_authorized_original: false,
            square_authorized_original: false,
            disable_save_payment_choices: true,

            squareCredentials: {
                access_token: null,
                application_id: null,
            },
            stripeCredentials: {
                token: null,
            },
            stripeCards: [],
            stripeDefaultCard: "",
            stripe: null,
            stripe_card: null,
            cards_errors: "",
            loading: false,
            displayNewCardForm: false,
            showColorPicker: false,
            showTextColorPicker: false,
            showBorderColorPicker: false,
            showModelBgColorPicker: false,

            showModelTextColorPicker: false,
            showModelHeaderBgColorPicker: false,
            showModelHeaderTextColorPicker: false,

            bookNowWidgetSetting: {
                buttonLabel: "Book Now",
                buttonBorderRadius: "5",
                buttonColor: "rgb(255, 0, 0)",
                buttonTextColor: "rgb(0, 255, 0)",
                buttonBorderColor: "rgb(0, 0, 255)",
                modelBackgroundColor: "rgb(255, 0, 255)",
                modelTextColor: "rgb(255, 0, 0)",
                modelHeaderBackgroundColor: "rgb(0, 255, 0)",
                modelHeaderTextColor: "rgb(0, 0, 255)",
                bookNowWidgetSettingActive: false,
            },

            buttonLabel: "",
            buttonColor: "",
            buttonTextColor: "",
            buttonBorderColor: "",
            modelBackgroundColor: "",
            modelTextColor: "",
            modelHeaderBackgroundColor: "",
            modelHeaderTextColor: "",
            buttonBorderRadius: "",
            bookNowWidgetSettingActive: false,

            widgetTextArea1: "",
            widgetTextArea2: "",
            idHash: null,
            idHasBeenInserted: false,
            profileWidgetSettings: {},
            displayProfileWidgetSaveButton: false,
            stylistProfile: {},
            timezonesList: {},
            datetime_formats: {},
            notification_settings: {
                timezone: "",
                language: "",
                datetime_format: "",
            },
            receive_sms: 0,
            receive_pushnotification: 0,
            receive_email: 0,
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.populateEditValues();

        //check if adminDetails are either in store or loaded
        //if yes then do necessary logic
        if (store.state.adminDetails.user) {
            this.onAdminDetailsLoad();
        }
        let self = this;
        eventBus.$on("admin_details_loaded", function () {
            self.onAdminDetailsLoad();
        });

        eventBus.$on("user_profile_loaded", function () {
            self.user = auth.user;
        });

        this.getStylistDetails();
        this.getStripeConnectUrl();

        let widgetUrl = process.env.VUE_APP_WIDGET_RMS_ENDPOINT;
        if (this.generalOptions.app_id == 2) {
            widgetUrl = process.env.VUE_APP_WIDGET_RMB_ENDPOINT;
        }

        let scriptTag = 'script';
        this.widgetTextArea1 = '<link href="' + widgetUrl + 'css/app.css" rel="stylesheet"><vue-widget';
        this.widgetTextArea2 = '></vue-widget><script src="' + widgetUrl + 'js/app.js">' + `</${scriptTag}>`;

        if (this.user.role == "stylist") {
            this.getWidgetChoices();

            this.getSquareApiCredentials();
            this.getStripeApiCredentials();
            this.getProfileWidgetSettings();
        }

        this.getStylistProfile();
    },
    beforeDestroy() {
        eventBus.$off("admin_details_loaded");
        eventBus.$off("user_profile_loaded");
    },
    computed: {
        adminDetails: {
            get: function () {
                return store.state.adminDetails;
            },
            set: function (value) {
                store.commit("setAdminDetails", value);
            },
        },
    },
    methods: {
        isSectionVisible(section) {
            if (!this.sections) {
                return true;
            }

            if (this.sections.indexOf(section) != -1) {
                return true;
            }

            return false;
        },
        populateEditValues: function () {
            for (var key in this.editValues) {
                this.editValues[key] = this.user[key];
            }
        },
        onAdminDetailsLoad: function () {
            this.getCountries();
            this.getProfessions();
            this.getUserProfession();
        },
        getUserProfession: function () {
            this.$http.get(api.actions.get_user_profession + "/" + this.user.id).then(
                function (response) {
                    if (typeof response.data.user_profession != "undefined" && response.data.user_profession) {
                        this.selectedProfession = response.data.user_profession;
                        this.selectedProfessionShow = this.selectedProfession.profession;
                        this.userProfession = this.selectedProfessionShow;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getProfessions: function () {
            this.$http.get(api.actions.get_professions + "/" + this.generalOptions.app_id).then(
                function (response) {
                    if (typeof response.data.professions != "undefined") {
                        this.professions = response.data.professions;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getCountries: function () {
            this.$http.get(api.actions.get_countries).then(
                function (response) {
                    if (typeof response.data.countries != "undefined") {
                        this.countries = response.data.countries;
                        this.prefixes = this.countries.map(function (el) {
                            return el.dial_code.replace(" ", "");
                        });
                        this.prefixes = _.sortBy(_.uniqBy(this.prefixes));
                        this.changeSelectedCountry(0);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        resetPassword: function (confirm) {
            let data = {};
            switch (this.passwordState) {
                case 0:
                    this.passwordState = 1;
                    break;
                case 1:
                    if (!confirm) {
                        this.passwordState = 0;
                        return;
                    }
                    //if no password entered the just return
                    if (!this.password || this.password.length < 5) {
                        return;
                    }
                    //make request to send the reset code
                    data = {
                        password: this.password,
                        user_id: this.user.id,
                    };
                    // this.$http.post(api.actions.send_password_reset_code, data).then(function (response) {
                    //     if(response.data.error == false) {
                    //         this.resetCodeInitials = response.data.initials;
                    //         this.passwordState = 2;
                    //     }
                    // }.bind(this), function () {
                    //     return false;
                    // });

                    //change password directly
                    this.$http.post(api.actions.verify_reset_code_change_password, data).then(
                        function (response) {
                            if (response.data.error == false) {
                                messages.successMessage(messages.GLOBAL_MESSAGES.PASSWORD_CHANGED);
                                this.passwordState = 0;
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                    break;
                case 2:
                    if (!confirm) {
                        this.passwordState = 0;
                        return;
                    }
                    //if no reset code the just return
                    if (!this.resetCode) {
                        return;
                    }
                    //make request to match reset code and change password
                    data = {
                        password: this.password,
                        user_id: this.user.id,
                        reset_code: this.resetCodeInitials + "-" + this.resetCode,
                    };
                    this.$http.post(api.actions.verify_reset_code_change_password, data).then(
                        function (response) {
                            if (response.data.error == false) {
                                messages.successMessage(messages.GLOBAL_MESSAGES.PASSWORD_CHANGED);
                                this.passwordState = 0;
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                    break;
            }
        },
        changePhone: function (confirm) {
            let data = {};
            switch (this.phoneState) {
                case 0:
                    this.phoneState = 1;
                    this.phoneNotification = null;
                    break;
                case 1:
                    //if no phone the just return
                    if (!confirm || !this.phone) {
                        this.phoneState = 0;
                        return;
                    }
                    //make request to send the change phone email
                    data = {
                        prefix: this.selectedCountry.dial_code,
                        phone: this.phone,
                        user_id: this.user.id,
                    };
                    this.phoneProgress = true;
                    this.phoneNotification = messages.GLOBAL_MESSAGES.PHONE_SEND_CODE_MESSAGE_WAIT;
                    this.$http.post(api.actions.send_verification_code, data).then(
                        function (response) {
                            this.phoneProgress = false;
                            if (response.data.error == false) {
                                this.phoneState = 2;
                                this.phoneNotification = messages.GLOBAL_MESSAGES.PHONE_SEND_CODE_MESSAGE;
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                    break;
                case 2:
                    //if no phone or verification code the just return
                    if (!confirm || !this.verificationCode || !this.phone) {
                        this.phoneState = 0;
                        return;
                    }
                    //make request to send the change phone email
                    data = {
                        prefix: this.selectedCountry.dial_code,
                        phone: this.phone,
                        verificationCode: this.verificationCode,
                        user_id: this.user.id,
                    };
                    this.phoneProgress = true;
                    this.$http.post(api.actions.change_phone_number, data).then(
                        function (response) {
                            this.phoneProgress = false;
                            if (response.data.error == false) {
                                this.user.phone_prefix = this.selectedCountry.dial_code;
                                this.user.phone = this.phone;
                                let user = localStorage.getItem("user");
                                if (user) {
                                    user = JSON.parse(user);
                                    user.phone_prefix = this.selectedCountry.dial_code;
                                    user.phone = this.phone;
                                    localStorage.setItem("user", JSON.stringify(user));
                                }
                                this.phoneState = 0;
                                this.phoneNotification = messages.GLOBAL_MESSAGES.PHONE_CHANGE_MESSAGE;
                            }
                        }.bind(this),
                        function () {
                            return false;
                        }
                    );
                    break;
            }
        },
        changeSelectedCountry: function (index) {
            this.selectedCountry = this.countries[index];
            this.selectedCountryShow = this.countries[index]["code"] + " (" + this.countries[index]["dial_code"] + ")";
        },
        changeSelectedProfession: function (index) {
            this.selectedProfession = this.professions[index];
            this.selectedProfessionShow = this.professions[index].profession;
        },
        saveProfession: function () {
            //dont update if there was no change in dropdown
            if (this.userProfession == this.selectedProfession.profession) {
                return;
            }
            //make request to save the new profession
            let data = {
                user_id: this.user.id,
                profession_id: this.selectedProfession.id,
            };
            this.$http.post(api.actions.save_user_profession, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PROFESSION_CHANGE_MESSAGE);
                        this.userProfession = this.selectedProfession.profession;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        editField: function (field) {
            this.edit[field] = true;
        },
        cancelField: function (field) {
            //remove input field
            this.edit[field] = false;
        },
        saveField: function (field) {
            //if value is same as before then just cancel
            if (this.editValues[field].trim() === this.user[field].trim()) {
                this.editValues[field] = this.user[field].trim();
                this.cancelField(field);
                return;
            }
            //make request to save the new profession
            let data = {
                [field]: this.editValues[field].trim(),
            };
            this.$http.post(api.actions.save_user_accounts, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
                        //update local user
                        this.user[field] = this.editValues[field].trim();
                        //update the storage user
                        let storageUser = JSON.parse(localStorage.getItem("user"));
                        storageUser[field] = this.editValues[field].trim();
                        localStorage.setItem("user", JSON.stringify(storageUser));
                        //cancel the field, to hide input
                        this.cancelField(field);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        saveNotificationSettings() {
            let data = {
                emails_off: !this.receive_email,
                pushnotifications_off: !this.receive_pushnotification,
                sms_off: !this.receive_sms,
            };
            this.$http.post(api.actions.save_user_accounts, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.USER_DATA_SAVED_MESSAGE);
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStylistDetails: function () {
            this.$http.get(api.actions.get_stylist_details + this.user.id).then(
                function (response) {
                    if (typeof response.data.details !== "undefined") {
                        // let allInfoDetails = response.data;
                        let adminDetails = response.data.details;
                        store.commit("setAdminDetails", adminDetails);
                        eventBus.$emit("admin_details_loaded");
                        if (response.data.error == false) {
                            this.accept_payments = response.data.details.accept_payments;
                            this.stripe_authorized = response.data.details.stripe_authorized;
                            if (this.stripe_authorized) {
                                this.open_stripe_info = true;
                            }
                            this.square_authorized = response.data.details.square_authorized;
                            if (this.square_authorized) {
                                this.open_square_modal = true;
                            }

                            this.stripe_authorized_original = response.data.details.stripe_authorized;
                            this.square_authorized_original = response.data.details.square_authorized;
                            if (this.stripe_authorized) {
                                this.getExpressAccountLoginLink();
                            }

                            this.receive_sms = !response.data.details.sms_off;
                            this.receive_pushnotification = !response.data.details.pushnotifications_off;
                            this.receive_email = !response.data.details.emails_off;
                        }
                    }
                }.bind(this)
            );
        },
        getStripeConnectUrl: function () {
            this.$http.get(api.actions.stripe_connect + "/" + this.user.id + "/" + this.generateId(20)).then(
                function (response) {
                    if (typeof response.data != "undefined") {
                        this.stripe_connect_content = response.data;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getExpressAccountLoginLink: function () {
            this.loading = true;
            this.$http.get(api.actions.get_express_account_login_link, { noError: 1 }).then(
                function (response) {
                    this.loading = false;

                    if (typeof response.data != "undefined" && !response.data.error) {
                        this.stripe_connect_update_url = response.data.response.url;
                    }
                }.bind(this),
                function () {
                    this.loading = false;
                    return false;
                }
            );
        },
        dec2hex(dec) {
            return ("0" + dec.toString(16)).substr(-2);
        },
        // generateId :: Integer -> String
        generateId(len) {
            var arr = new Uint8Array((len || 40) / 2);
            window.crypto.getRandomValues(arr);
            return "backToWeb_" + Array.from(arr, this.dec2hex).join("");
        },
        cancelStripeConnect() {
            this.stripe_authorized = false;
            if (!this.stripe_authorized && !this.square_authorized) {
                this.accept_payments = false;
            }
        },
        changeAvatar() {
            this.changeProfileImage = true;
        },
        profileImageChange: function (e) {
            const file = e.target.files[0];
            if (file.type.indexOf("image/") === -1) {
                return;
            }
            this.profileImageUpload.file = file;
            this.profileImageUpload.fileName = file.name;
            this.resizeImage(file);
        },
        cancelProfileImageUpload: function () {
            this.profileImageUpload = {
                fileName: null,
                preview: null,
                file: null,
                croppedImage: null,
            };
            this.changeProfileImage = false;
        },
        saveProfileImageUpload: function () {
            //do not send request if no file is available
            if (!this.profileImageUpload.file) {
                return;
            }
            this.enableProfilePicSave = false;
            this.profileImageUpload.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
            //make request to match reset code and change password
            let data = {
                image: this.profileImageUpload.croppedImage,
                dataImage: true,
                fileName: this.profileImageUpload.fileName,
            };
            this.$http.post(api.actions.save_profile_image, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PROFILE_IMAGE_CHANGED);
                        this.cancelProfileImageUpload();
                        auth.getProfile(true);
                        this.enableProfilePicSave = true;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        openFileBrowser: function () {
            this.$refs.profileImageInputFile.click();
        },
        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute("data-scale", scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute("data-scale", scale);
        },
        reset() {
            this.$refs.cropper.reset();
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
        resizeImage: function (file) {
            // Ensure it's an image
            if (!file.type.match(/image.*/)) {
                return;
            }

            // Load the image
            var reader = new FileReader();
            reader.onload = (readerEvent) => {
                var image = new Image();
                image.onload = () => {
                    // Resize the image
                    var canvas = document.createElement("canvas"),
                        max_size = 600, // TODO : pull max size from a site config
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0, width, height);
                    let dataUrl = canvas.toDataURL();
                    this.uploadResizedImage(dataUrl);
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        },
        uploadResizedImage: function (dataUrl) {
            if (this.profileImageUpload.preview != null) {
                // get loaded data and render thumbnail.
                this.profileImageUpload.preview = dataUrl;
                // rebuild cropperjs with the updated source
                this.$refs.cropper.replace(dataUrl);
            } else {
                // get loaded data and render thumbnail.
                this.profileImageUpload.preview = dataUrl;
            }
        },
        getInitial: function (name) {
            if (!name) {
                return;
            }
            return name[0].toUpperCase();
        },
        manageStripeModal: function () {
            this.open_stripe_modal = false;
            this.open_stripe_info = !this.open_stripe_info;
            this.square_authorized = false;

            if (this.stripe_authorized && !this.stripe_connect_update_url) {
                this.getExpressAccountLoginLink();
            }
            this.manageSavePaymentChoicesButton();
        },
        manageSquareModal: function (show) {
            this.stripe_authorized = false;
            if (show) {
                this.open_square_modal = !this.open_square_modal;
                return;
            }
            this.manageSavePaymentChoicesButton();
        },
        manageSavePaymentChoicesButton: function () {
            this.accept_payments = true;
            if (
                (!this.stripe_authorized ||
                    (this.stripe_authorized && this.stripe_authorized == this.stripe_authorized_original) ||
                    (this.stripe_authorized && this.stripeCredentials.token)) &&
                (!this.square_authorized ||
                    (this.square_authorized && this.square_authorized == this.square_authorized_original) ||
                    (this.square_authorized &&
                        this.squareCredentials.access_token &&
                        this.squareCredentials.application_id))
            ) {
                //if only accept payment is on but no option is selected then
                //off the accepted payments as well
                if (this.accept_payments && !this.stripe_authorized && !this.square_authorized) {
                    this.accept_payments = false;
                    this.disable_save_payment_choices = false;
                    return;
                }
                //vice versa
                if (!this.accept_payments && (this.stripe_authorized || this.square_authorized)) {
                    this.accept_payments = true;
                    this.disable_save_payment_choices = false;
                    return;
                }
                this.disable_save_payment_choices = false;
            } else {
                this.disable_save_payment_choices = true;
            }
        },
        saveSquare: function () {
            if (!this.square_access_token || !this.square_application_id) {
                return;
            }
            //save square
            let data = {
                access_token: this.square_access_token,
                application_id: this.square_application_id,
            };
            this.save_square_progress = true;
            this.$http.post(api.actions.save_square_api_credentials, data).then(
                function (response) {
                    this.save_square_progress = false;
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.SQUARE_SAVED);
                        /*  this.savePaymentChoices({'accept_square': this.square_authorized}); */
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        savePaymentChoices: function (data = null) {
            if (data == null) {
                if (
                    (this.stripe_authorized &&
                        this.stripe_authorized != this.stripe_authorized_original &&
                        !this.stripeCredentials.token) ||
                    (this.square_authorized &&
                        this.square_authorized != this.square_authorized_original &&
                        !this.squareCredentials.access_token &&
                        !this.squareCredentials.application_id)
                ) {
                    return;
                }
                if (this.accept_payments && !this.stripe_authorized && !this.square_authorized) {
                    Vue.toasted.show("At least one of the options should be enabled to enable the accept payments.", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }

                if (!this.accept_payments && (this.stripe_authorized || this.square_authorized)) {
                    Vue.toasted.show("Accept payments should be enabled to enable any options.", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }

                //save square
                data = {
                    accept_payments: this.accept_payments,
                    accept_stripe: this.stripe_authorized,
                    accept_square: this.square_authorized,
                };
            } else {
                data["accept_payments"] = 1;
            }

            let numberOfPaymentChoices = 0;

            if (data["accept_payments"]) {
                if (this.stripe_authorized) {
                    numberOfPaymentChoices++;
                }
                if (this.square_authorized) {
                    numberOfPaymentChoices++;
                }

                if (numberOfPaymentChoices > 1) {
                    Vue.toasted.show("You can only enable one type of payment option.", {
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                    });
                    return;
                }
            }

            this.disable_save_payment_choices = true;
            this.$http.post(api.actions.save_accept_payments, data).then(
                function (response) {
                    this.disable_save_payment_choices = false;
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.PAYMENT_CHOICES_SAVED);
                        this.getStylistDetails();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getSquareApiCredentials: function () {
            this.$http.get(api.actions.get_square_api_credentials).then(
                function (response) {
                    if (
                        typeof response.data.response.access_token != "undefined" &&
                        response.data.response.access_token
                    ) {
                        this.squareCredentials.access_token = response.data.response.access_token;
                        this.square_access_token = this.squareCredentials.access_token;
                    }
                    if (
                        typeof response.data.response.application_id != "undefined" &&
                        response.data.response.application_id
                    ) {
                        this.squareCredentials.application_id = response.data.response.application_id;
                        this.square_application_id = this.squareCredentials.application_id;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getStripeApiCredentials: function () {
            this.$http.get(api.actions.get_stripe_api_credentials).then(
                function (response) {
                    if (typeof response.data.response.token != "undefined" && response.data.response.token) {
                        this.stripeCredentials.token = response.data.response.token;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        getFormattedDate: function (date, format) {
            return moment(date, "YYYY-MM-DD hh:mm:ss").format(format);
        },
        getTheWidget: function () {
            this.bookNowWidgetSettingActive = !this.bookNowWidgetSettingActive;
        },

        changeColor: function (color) {
            const { r, g, b, a } = color.rgba;
            this.buttonColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        changeTextColor: function (color) {
            const { r, g, b, a } = color.rgba;
            this.buttonTextColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        changeBorderColor: function (color) {
            const { r, g, b, a } = color.rgba;
            this.buttonBorderColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        changeModelBgColor: function (color) {
            const { r, g, b, a } = color.rgba;
            this.modelBackgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        changeModelTextColor: function (color) {
            const { r, g, b, a } = color.rgba;
            this.modelTextColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        changeModelHeaderBgColor: function (color) {
            const { r, g, b, a } = color.rgba;
            this.modelHeaderBackgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        changeModelHeaderTextColor: function (color) {
            const { r, g, b, a } = color.rgba;
            this.modelHeaderTextColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        },

        resetColorPicker() {
            this.showColorPicker = false;
            this.showTextColorPicker = false;
            this.showBorderColorPicker = false;
            this.showModelBgColorPicker = false;
            this.showModelTextColorPicker = false;
            this.showModelHeaderBgColorPicker = false;
            this.showModelHeaderTextColorPicker = false;
        },

        openColorPicker: function () {
            if (this.showColorPicker == false) {
                this.resetColorPicker();
            }
            this.showColorPicker = !this.showColorPicker;
        },
        openTextColorPicker: function () {
            if (this.showTextColorPicker == false) {
                this.resetColorPicker();
            }
            this.showTextColorPicker = !this.showTextColorPicker;
        },
        openBorderColorPicker: function () {
            if (this.showBorderColorPicker == false) {
                this.resetColorPicker();
            }
            this.showBorderColorPicker = !this.showBorderColorPicker;
        },
        openModelBgColorPicker: function () {
            if (this.showModelBgColorPicker == false) {
                this.resetColorPicker();
            }
            this.showModelBgColorPicker = !this.showModelBgColorPicker;
        },
        openModelTextColorPicker: function () {
            if (this.showModelTextColorPicker == false) {
                this.resetColorPicker();
            }
            this.showModelTextColorPicker = !this.showModelTextColorPicker;
        },
        openModelHeaderBgColorPicker: function () {
            if (this.showModelHeaderBgColorPicker == false) {
                this.resetColorPicker();
            }
            this.showModelHeaderBgColorPicker = !this.showModelHeaderBgColorPicker;
        },
        openModelHeaderTextColorPicker: function () {
            if (this.showModelHeaderTextColorPicker == false) {
                this.resetColorPicker();
            }
            this.showModelHeaderTextColorPicker = !this.showModelHeaderTextColorPicker;
        },

        closeColorPicker() {
            this.showColorPicker = false;
        },
        closeTextColorPicker() {
            this.showTextColorPicker = false;
        },
        closeBorderColorPicker() {
            this.showBorderColorPicker = false;
        },
        closeModelBgColorPicker() {
            this.showModelBgColorPicker = false;
        },
        closeModelTextColorPicker() {
            this.showModelTextColorPicker = false;
        },
        closeModelHeaderBgColorPicker() {
            this.showModelHeaderBgColorPicker = false;
        },
        closeModelHeaderTextColorPicker() {
            this.showModelHeaderTextColorPicker = false;
        },

        getWidgetChoices: function () {
            this.$http.get(api.actions.get_book_now_settings).then(
                function (response) {
                    if (typeof response.body.bookNowWidgetSetting != "undefined") {
                        this.bookNowWidgetSetting.buttonLabel = response.body.bookNowWidgetSetting.button_label;
                        this.bookNowWidgetSetting.buttonColor = response.body.bookNowWidgetSetting.button_color;
                        this.bookNowWidgetSetting.buttonTextColor =
                            response.body.bookNowWidgetSetting.button_text_color;
                        this.bookNowWidgetSetting.buttonBorderColor =
                            response.body.bookNowWidgetSetting.button_border_color;
                        this.bookNowWidgetSetting.modelBackgroundColor =
                            response.body.bookNowWidgetSetting.model_background_color;
                        this.bookNowWidgetSetting.modelTextColor = response.body.bookNowWidgetSetting.model_text_color;
                        this.bookNowWidgetSetting.modelHeaderBackgroundColor =
                            response.body.bookNowWidgetSetting.model_header_background_color;
                        this.bookNowWidgetSetting.modelHeaderTextColor =
                            response.body.bookNowWidgetSetting.model_header_text_color;
                        this.bookNowWidgetSetting.buttonBorderRadius =
                            response.body.bookNowWidgetSetting.button_border_radius;
                        this.bookNowWidgetSetting.bookNowWidgetSettingActive =
                            response.body.bookNowWidgetSetting.active;

                        if (!this.bookNowWidgetSetting.buttonColor) {
                            this.bookNowWidgetSetting.buttonColor = this.generalOptions.defaultColor;
                            this.bookNowWidgetSetting.buttonBorderColor = this.generalOptions.defaultColor;
                        }

                        if (
                            typeof response.body.bookNowWidgetSetting.id_hash != "undefined" &&
                            !this.idHasBeenInserted
                        ) {
                            this.idHash = response.body.bookNowWidgetSetting.id_hash;
                            this.widgetTextArea2 = ' id="' + this.idHash + '" ' + this.widgetTextArea2;
                            this.idHasBeenInserted = true;
                        }
                        this.resetBookNowWidgetSetting();

                        if (!response.body.bookNowWidgetSetting.active) {
                            this.bookNowWidgetSettingActive = true;
                            this.saveWidgetChoices();
                        }
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        resetBookNowWidgetSetting: function () {
            this.buttonLabel = this.bookNowWidgetSetting.buttonLabel;
            this.buttonColor = this.bookNowWidgetSetting.buttonColor;
            this.buttonTextColor = this.bookNowWidgetSetting.buttonTextColor;
            this.buttonBorderColor = this.bookNowWidgetSetting.buttonBorderColor;
            this.modelBackgroundColor = this.bookNowWidgetSetting.modelBackgroundColor;
            this.modelTextColor = this.bookNowWidgetSetting.modelTextColor;
            this.modelHeaderBackgroundColor = this.bookNowWidgetSetting.modelHeaderBackgroundColor;
            this.modelHeaderTextColor = this.bookNowWidgetSetting.modelHeaderTextColor;
            this.buttonBorderRadius = this.bookNowWidgetSetting.buttonBorderRadius;
            this.bookNowWidgetSettingActive = this.bookNowWidgetSetting.bookNowWidgetSettingActive;
        },

        saveWidgetChoices: function () {
            let data = {
                button_label: this.buttonLabel,
                button_color: this.buttonColor,
                button_text_color: this.buttonTextColor,
                button_border_color: this.buttonBorderColor,
                model_background_color: this.modelBackgroundColor,
                model_text_color: this.modelTextColor,
                model_header_background_color: this.modelHeaderBackgroundColor,
                model_header_text_color: this.modelHeaderTextColor,
                button_border_radius: this.buttonBorderRadius,
                active: this.bookNowWidgetSettingActive,
            };

            this.$http.post(api.actions.save_book_now_settings, data).then(
                (response) => {
                    if (typeof response.body.message != "undefined") {
                        this.getWidgetChoices();
                        messages.successMessage(response.body.message);
                        return;
                    }
                    this.getWidgetChoices();
                    messages.successMessage(messages.GLOBAL_MESSAGES.WIDGET_SETTINGS_SAVED);
                },
                function () {
                    return false;
                }
            );
        },

        getProfileWidgetSettings: function () {
            this.$http.get(api.actions.get_profile_widget_settings).then(
                function (response) {
                    if (typeof response.data != "undefined" && response.data) {
                        this.profileWidgetSettings = response.data;
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        saveProfileWidgetSettings: function () {
            var settings = {};

            for (let i in this.profileWidgetSettings) {
                settings[i] = {
                    active: this.profileWidgetSettings[i].active,
                };
            }

            this.$http.post(api.actions.save_profile_widget_settings, { settings: settings }).then(
                function () {
                    this.getProfileWidgetSettings();
                    messages.successMessage(messages.GLOBAL_MESSAGES.WIDGET_SETTINGS_SAVED);
                }.bind(this),
                function () {
                    return false;
                }
            );
        },

        getProfileWidgetSnippet: function (id_hash) {
            let widgetUrl = process.env.VUE_APP_WIDGET_PROFILE_RMS_ENDPOINT;
            if (this.generalOptions.app_id == 2) {
                widgetUrl = process.env.VUE_APP_WIDGET_PROFILE_RMB_ENDPOINT;
            }

            let scriptTag = 'script';
            let widget_code =
                '<link href="' + widgetUrl + 'css/app.css" rel="stylesheet"><profile-widget id="' + id_hash + '" ';
            widget_code += '></profile-widget><script src="' + widgetUrl + `js/app.js"></${scriptTag}>`;

            return widget_code;
        },

        copyWidgetCodeToClipboard: function (code) {
            const el = document.createElement("textarea");
            el.value = code;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            messages.successMessage(messages.GLOBAL_MESSAGES.WIDGET_SNIPPET_COPIED);
        },

        copyToClipboard: function () {
            const el = document.createElement("textarea");
            el.value = this.widgetTextArea1 + this.widgetTextArea2;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            messages.successMessage(messages.GLOBAL_MESSAGES.WIDGET_SNIPPET_COPIED);
        },
        getStylistProfile: function () {
            this.$http.get(api.actions.get_profile).then(
                function (response) {
                    if (typeof response.data.user != "undefined") {
                        this.stylistProfile = response.data.user;
                        this.getTimezonesList();
                        this.getClientsNotifications();
                    }
                }.bind(this)
            );
        },
        getTimezonesList: function () {
            this.$http.get(api.actions.get_timezoneslist + this.stylistProfile.details.profile.country_code).then(
                function (response) {
                    if (typeof response.data.timezones != "undefined") {
                        this.timezonesList = response.data.timezones;
                    }
                }.bind(this)
            );
        },
        getClientsNotifications: function () {
            this.$http.get(api.actions.get_clientnotifications).then(
                function (response) {
                    if (typeof response.data.notification_settings != "undefined") {
                        this.notification_settings = response.data.notification_settings;
                        if (this.user.role != "client") this.datetime_formats = response.data.datetime_formats;
                    }
                }.bind(this)
            );
        },
        saveClientsNotifications: function () {
            this.$http.post(api.actions.save_clientnotifications, this.notification_settings).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_SAVE);
                        this.getClientsNotifications();
                    }
                }.bind(this)
            );
        },
    },
    components: {
        colorPicker,
    },
};
</script>
