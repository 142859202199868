<template>
    <!-- app -->
    <div id="ModalBookingNew" class="client-invite-modal new-book-modal">
        <!-- use the modal component, pass in the prop -->
        <div>
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrap">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div>
                                        <img
                                            class="modal-logo"
                                            v-if="generalOptions.app_id == 1"
                                            src="../assets/images/rms-logo.png"
                                            alt="Logo"
                                        />
                                        <img
                                            class="modal-logo"
                                            v-if="generalOptions.app_id == 2"
                                            src="../assets/images/rmb-logo.png"
                                            alt="Logo"
                                        />
                                    </div>
                                    <div>
                                        <h3
                                            v-if="profile.first_name && screen != 'reset-password'"
                                            class="modal-headline"
                                        >
                                            Book with
                                            <span v-if="profile.group_member_aliases.length == 1"
                                                >{{ profile.first_name }} {{ profile.last_name }}</span
                                            >
                                            <b-dropdown
                                                v-if="profile.group_member_aliases.length > 1"
                                                :text="profile.first_name + ' ' + profile.last_name"
                                                class="stylist-name-dropdown"
                                            >
                                                <b-dropdown-item
                                                    v-for="(member, i) in profile.group_member_aliases"
                                                    :key="i"
                                                    v-on:click="onProfileChange(member.alias, member.name)"
                                                    >{{ member.name }}</b-dropdown-item
                                                >
                                            </b-dropdown>
                                        </h3>
                                        <h3 v-if="screen == 'reset-password'" class="modal-headline">
                                            Recover Password
                                        </h3>
                                    </div>
                                    <div>
                                        <button type="button" class="close" @click="closeModal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    class="modal-body scroll-div"
                                    :class="{
                                        'with-background':
                                            screen == 'login' ||
                                            screen == 'register' ||
                                            screen == 'verify-phone' ||
                                            screen == 'user-exists' ||
                                            screen == 'reset-password' ||
                                            screen == 'request-code',
                                    }"
                                >
                                    <slot v-if="!blocked" name="body text-left">
                                        <div v-if="screen == 'step-1' && profile && profile.id" class="step-1-screen">
                                            <div class="step-1-services-wrap">
                                                <div class="select-new-services-wrap">
                                                    <span
                                                        v-if="!appointment_saved"
                                                        class="action-link"
                                                        :class="{
                                                            'no-services': booking_service_names.length == 0,
                                                        }"
                                                        v-on:click="onAddService"
                                                        >+ Add Service</span
                                                    >
                                                </div>
                                                <div class="selected-services-detail">
                                                    <div
                                                        v-if="booking_selected_services_obj.length == 0"
                                                        class="service-names"
                                                    >
                                                        No services selected
                                                    </div>

                                                    <ul
                                                        v-if="booking_selected_services_obj.length > 0"
                                                        class="category-services-list"
                                                    >
                                                        <li
                                                            v-for="(service, index) in booking_selected_services_obj"
                                                            :key="`service-${index}`"
                                                        >
                                                            <div>
                                                                <span class="service-names">{{ service.name }}</span>
                                                                <p class="service-desc">
                                                                    <span v-if="profile.display_prices">
                                                                        <span v-if="profile.currency == 'USD'"
                                                                            >${{ service.price_default * service.qty }}
                                                                        </span>
                                                                        <span v-if="profile.currency != 'USD'"
                                                                            >{{ service.price_default * service.qty }}
                                                                            {{ profile.currency }}
                                                                        </span>
                                                                        {{ service.price_and_up ? " and up" : "" }}
                                                                    </span>
                                                                    <span v-if="service.duration_default">,</span>
                                                                    <span v-if="service.duration_default">
                                                                        {{
                                                                            getFormattedServiceDuration(
                                                                                service.duration_default * service.qty
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <select
                                                                v-model="service.qty"
                                                                v-if="!appointment_saved"
                                                                v-on:change="
                                                                    updateServiceTotals();
                                                                    resetDateChoose();
                                                                    calculateDepositFee();
                                                                "
                                                            >
                                                                <option v-for="i in 10" :value="i" :key="`opt-${i}`">
                                                                    {{ i }}
                                                                </option>
                                                            </select>
                                                            <span
                                                                class="delete-link"
                                                                v-if="!appointment_saved"
                                                                v-on:click="deleteService(index, service.id)"
                                                                >Delete</span
                                                            >
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div v-if="!showMoreDates" class="step-1-dates-wrap text-left">
                                                <div
                                                    v-for="(date, index) in available_dates"
                                                    :key="index"
                                                    class="available-date"
                                                    :data-up="slotsPosUpdates"
                                                >
                                                    <div class="date">
                                                        {{ getFormattedDate(date.date, "dddd, MMM D") }}
                                                    </div>
                                                    <div :ref="`slots-ref-1-${index}`" class="slots-wrap">
                                                        <div
                                                            v-if="date.slots.length > 5"
                                                            v-on:click="
                                                                slotsMoveLeft(
                                                                    index,
                                                                    $refs['slots-ref-1-' + index][0].clientWidth / 2
                                                                )
                                                            "
                                                            class="slots-left"
                                                        >
                                                            &lt;
                                                        </div>
                                                        <div
                                                            class="slots has-more"
                                                            :class="{ 'has-more': date.slots.length > 5 }"
                                                        >
                                                            <div
                                                                :ref="`slots-inner-ref-1-${index}`"
                                                                class="slots-inner"
                                                                :style="{
                                                                    width: 100 * date.slots.length + 'px',
                                                                    left: slotsPos[index] + 'px',
                                                                }"
                                                            >
                                                                <span
                                                                    v-on:click="onSelectTime(slot, date.date)"
                                                                    class="slot"
                                                                    v-for="(slot, i) in date.slots"
                                                                    :key="i"
                                                                    >{{ slot }}</span
                                                                >
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="date.slots.length > 5"
                                                            v-on:click="
                                                                slotsMoveRight(
                                                                    index,
                                                                    $refs['slots-ref-1-' + index][0].clientWidth / 2,
                                                                    -(
                                                                        $refs['slots-inner-ref-1-' + index][0]
                                                                            .clientWidth -
                                                                        $refs['slots-ref-1-' + index][0].clientWidth +
                                                                        20
                                                                    )
                                                                )
                                                            "
                                                            class="slots-right"
                                                        >
                                                            &gt;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                v-if="showMoreDates && !booking_date_time"
                                                class="step-1-more-dates-wrap text-left"
                                            >
                                                <div class="step-1-more-dates-wrap-inner">
                                                    <div class="book-caledar-wrapper">
                                                        <datepicker
                                                            @selected="getStylistFreeHours"
                                                            @changedMonth="onCalendarMonthChange"
                                                            :inline="true"
                                                            :disabledDates="disabledDates"
                                                        ></datepicker>
                                                        <div class="loader-wrap" v-if="loadingUnavailabeDates">
                                                            <div class="loading-icon-wrap">
                                                                <i class="loading-icon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="book-date-time">
                                                        <div v-if="booking_date" class="selected-date">
                                                            {{ getFormattedDate(booking_date, "ddd, MMM D, Y") }}
                                                        </div>
                                                        <div class="free-hours">
                                                            <div
                                                                v-for="(group, index) in free_hours_group_names"
                                                                :key="index"
                                                                class="free-hours-group"
                                                                :data-up="slotsPosUpdates"
                                                            >
                                                                <h4
                                                                    v-if="free_hours_groups[group].length > 0"
                                                                    class="group-name"
                                                                >
                                                                    {{ group }}
                                                                </h4>
                                                                <div
                                                                    :ref="`slots-ref-2-${index}`"
                                                                    v-if="free_hours_groups[group].length > 0"
                                                                    class="slots-wrap"
                                                                >
                                                                    <div
                                                                        v-if="free_hours_groups[group].length > 2"
                                                                        v-on:click="
                                                                            slotsMoveLeft(
                                                                                index,
                                                                                $refs['slots-ref-2-' + index][0]
                                                                                    .clientWidth / 2
                                                                            )
                                                                        "
                                                                        class="slots-left"
                                                                    >
                                                                        &lt;
                                                                    </div>
                                                                    <div
                                                                        class="slots has-more"
                                                                        :class="{
                                                                            'has-more':
                                                                                free_hours_groups[group].length > 2,
                                                                        }"
                                                                    >
                                                                        <div
                                                                            :ref="`slots-inner-ref-2-${index}`"
                                                                            class="slots-inner"
                                                                            :style="{
                                                                                width:
                                                                                    101 *
                                                                                        free_hours_groups[group]
                                                                                            .length +
                                                                                    'px',
                                                                                left: slotsPos[index] + 'px',
                                                                            }"
                                                                        >
                                                                            <span
                                                                                v-on:click="onSelectTime(slot)"
                                                                                class="slot"
                                                                                v-for="(slot, i) in free_hours_groups[
                                                                                    group
                                                                                ]"
                                                                                :key="i"
                                                                                >{{ slot }}</span
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        v-if="free_hours_groups[group].length > 2"
                                                                        v-on:click="
                                                                            slotsMoveRight(
                                                                                index,
                                                                                $refs['slots-ref-2-' + index][0]
                                                                                    .clientWidth / 2,
                                                                                -(
                                                                                    $refs[
                                                                                        'slots-inner-ref-2-' + index
                                                                                    ][0].clientWidth -
                                                                                    $refs['slots-ref-2-' + index][0]
                                                                                        .clientWidth +
                                                                                    40
                                                                                )
                                                                            )
                                                                        "
                                                                        class="slots-right"
                                                                    >
                                                                        &gt;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="unavailable_service_name_days.length > 0"
                                                    class="unavailable-service-days"
                                                >
                                                    <p v-for="(item, i) in unavailable_service_name_days" :key="i">
                                                        <span v-if="item.days.length > 0"
                                                            ><b>{{ item.service }}</b> is not available on
                                                            {{ item.days.join(", ") }}.</span
                                                        >
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                v-if="showMoreDates && booking_date_time"
                                                class="step-1-datetime-selected text-left"
                                            >
                                                <span
                                                    v-if="!appointment_saved"
                                                    v-on:click="onEditTime"
                                                    class="datetime-edit action-link"
                                                    >Edit</span
                                                >
                                                <h4 class="selected-date">
                                                    {{ getFormattedDate(booking_date_time, "dddd, MMM D") }}
                                                </h4>
                                                <p>
                                                    {{ getFormattedDate(booking_date_time, "h:mm a") }} -
                                                    {{ getFormattedDate(booking_end_date_time, "h:mm a") }}
                                                </p>
                                            </div>

                                            <div
                                                v-if="showMoreDates && booking_date_time && !appointment_saved"
                                                class="step-1-final text-left"
                                            >
                                                <div class="notes-wrap">
                                                    <div>Add notes to {{ profile.first_name }}</div>
                                                    <div class="notes-input-wrap">
                                                        <input
                                                            v-model="booking_notes"
                                                            class="notes-input"
                                                            type="text"
                                                            name="notes"
                                                        />
                                                    </div>
                                                </div>
                                                <div v-if="hasPolicyEnabled() && profile.logged_in" class="policy-note">
                                                    <div
                                                        v-if="
                                                            profile.logged_in &&
                                                            has_card &&
                                                            profile.policy.deposit != 0 &&
                                                            profile.policy.deposit_fee != 0 &&
                                                            !add_new_card
                                                        "
                                                        class="card-policy-wrap"
                                                    >
                                                        <p>
                                                            Your card
                                                            <span v-if="card_last_4"
                                                                >ending in <strong>{{ card_last_4 }}</strong></span
                                                            >
                                                            will be charged with the deposit fee:
                                                            <strong
                                                                ><span>{{
                                                                    profile.currency == "USD"
                                                                        ? "$"
                                                                        : profile.currency + " "
                                                                }}</span
                                                                >{{ deposit_fee }}</strong
                                                            >
                                                        </p>
                                                        <span v-on:click="addNewCard" class="action-link"
                                                            >Add other card</span
                                                        >
                                                    </div>

                                                    <div v-if="add_new_card">
                                                        <h4>Enter card</h4>
                                                        <div
                                                            v-if="profile.accept_stripe"
                                                            class="card-form"
                                                            ref="card_form"
                                                        ></div>
                                                        <div
                                                            v-if="profile.accept_square && !profile.accept_stripe"
                                                            class="card-form card-form-square"
                                                            ref="card_form_square"
                                                        >
                                                            <div id="card-container"></div>
                                                        </div>
                                                        <div class="card-logos">
                                                            <img class="logos" src="../assets/images/card-logos.png" />
                                                            <img
                                                                class="verified"
                                                                src="../assets/images/verified-secured.jpg"
                                                            />
                                                        </div>
                                                        <div class="card-errors">{{ cards_errors }}</div>
                                                        <br />
                                                        <div class="text-center">
                                                            <span
                                                                v-if="!loading"
                                                                v-on:click="saveNewCard"
                                                                class="action-link link-bold"
                                                                >Save card</span
                                                            >
                                                            <span v-if="loading" class="action-link">Saving...</span>
                                                            <span v-if="!loading">
                                                                |
                                                                <span v-on:click="cancelNewCardAdd" class="cancel-link"
                                                                    >Cancel</span
                                                                >
                                                            </span>
                                                        </div>
                                                        <br />
                                                    </div>

                                                    <div v-if="profile.logged_in && !has_card && !add_new_card">
                                                        <h4>
                                                            {{ profile.first_name }}
                                                            {{ profile.last_name }} requires a credit card to hold this
                                                            reservation
                                                        </h4>
                                                        <p
                                                            v-if="
                                                                profile.policy.deposit == 0 ||
                                                                profile.policy.deposit_fee == 0
                                                            "
                                                        >
                                                            Your card will not be charged, services payment will be
                                                            collected after rendering the services.
                                                        </p>
                                                        <p
                                                            v-if="
                                                                profile.policy.deposit != 0 &&
                                                                profile.policy.deposit_fee != 0
                                                            "
                                                        >
                                                            Your card will be charged with the deposit fee:
                                                            <strong
                                                                ><span>{{
                                                                    profile.currency == "USD"
                                                                        ? "$"
                                                                        : profile.currency + " "
                                                                }}</span
                                                                >{{ deposit_fee }}</strong
                                                            >
                                                        </p>

                                                        <div
                                                            v-if="profile.accept_stripe"
                                                            class="card-form"
                                                            ref="card_form"
                                                        ></div>
                                                        <div
                                                            v-if="profile.accept_square && !profile.accept_stripe"
                                                            class="card-form card-form-square"
                                                            ref="card_form_square"
                                                        >
                                                            <div id="card-container"></div>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                profile.accept_paypal &&
                                                                !profile.accept_stripe &&
                                                                !profile.accept_square
                                                            "
                                                            class="card-form card-form-paypal"
                                                            ref="card_form_paypal"
                                                        >
                                                            <div id="paypal-button"></div>
                                                        </div>
                                                        <div class="card-logos">
                                                            <img class="logos" src="../assets/images/card-logos.png" />
                                                            <img
                                                                class="verified"
                                                                src="../assets/images/verified-secured.jpg"
                                                            />
                                                        </div>
                                                        <div class="card-errors">{{ cards_errors }}</div>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            profile.policy &&
                                                            profile.policy.cancel == 1 &&
                                                            profile.policy.cancel_fee > 0 &&
                                                            !add_new_card
                                                        "
                                                    >
                                                        <h4>Cancellation Policy</h4>
                                                        <p>
                                                            You will not be charged if you cancel at least
                                                            {{ profile.policy.cancel_advance_hours }} hours before your
                                                            appointment starts. Otherwise, you will be charged up to
                                                            {{ profile.policy.cancel_fee }}% of the services booked.
                                                        </p>
                                                    </div>
                                                    <div class="cards-wrap" v-if="!add_new_card && has_card">
                                                        <h4>Select Card</h4>
                                                        <div
                                                            v-for="(card, i) in stripe_cards"
                                                            :key="`stripe-card${i}`"
                                                            class="card-item card-item-wrap"
                                                        >
                                                            <input
                                                                type="radio"
                                                                name="payment-method"
                                                                :id="`card-stripe-${i}`"
                                                                v-model="selected_card"
                                                                :value="card.id"
                                                            />
                                                            <label :for="`card-stripe-${i}`">
                                                                <div class="card-last4">
                                                                    **** **** **** {{ card.card.last4 }}
                                                                </div>
                                                                <div class="card-brand">
                                                                    {{ card.card.brand }}
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div
                                                            v-for="(card, i) in square_cards"
                                                            :key="`square-card${i}`"
                                                            class="card-item card-item-wrap"
                                                        >
                                                            <input
                                                                type="radio"
                                                                class="square-radio-checked"
                                                                name="payment-method"
                                                                :id="`card-square-${i}`"
                                                                v-model="selected_card"
                                                                :value="card.id"
                                                            />
                                                            <label :for="`card-square-${i}`">
                                                                <div class="card-last4">
                                                                    **** **** **** {{ card.last_4 }}
                                                                </div>
                                                                <div class="card-brand">
                                                                    {{ card.card_brand }}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            profile.policy.deposit != 0 &&
                                                            profile.policy.deposit_fee != 0
                                                        "
                                                        class="client-signature"
                                                        :class="add_new_card ? 'hide-signature' : ''"
                                                    >
                                                        <h4>
                                                            Sign to authorize
                                                            <span
                                                                v-if="
                                                                    profile.policy.deposit != 0 &&
                                                                    profile.policy.deposit_fee != 0
                                                                "
                                                            >
                                                                <strong
                                                                    ><span>{{
                                                                        profile.currency == "USD"
                                                                            ? "$"
                                                                            : profile.currency + " "
                                                                    }}</span
                                                                    >{{ deposit_fee }}</strong
                                                                >
                                                            </span>

                                                            payment.
                                                        </h4>
                                                        <div class="signature-container">
                                                            <vueSignature
                                                                class="signature"
                                                                ref="signature"
                                                                :sigOption="signatureOption"
                                                                :w="screenWidth"
                                                                :h="'150px'"
                                                                key="vue-signature-comp"
                                                            ></vueSignature>
                                                        </div>
                                                        <div class="signature-btns-container">
                                                            <button
                                                                class="secondary-btn small mobile"
                                                                @click="clearSignature"
                                                            >
                                                                Clear
                                                            </button>
                                                            <button
                                                                class="secondary-btn small mobile"
                                                                @click="undoSignature"
                                                            >
                                                                Undo
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="action-buttons-wrap">
                                                    <button
                                                        v-if="!profile.logged_in"
                                                        v-on:click="screen = 'login'"
                                                        type="button"
                                                        class="book-button"
                                                    >
                                                        Login or Register
                                                    </button>
                                                    <button
                                                        v-if="profile.logged_in && !add_new_card"
                                                        v-on:click="handleSaveAppointment"
                                                        :disabled="
                                                            booking_service_names.length == 0 ||
                                                            loading ||
                                                            ($refs.signature &&
                                                                $refs.signature.isEmpty() &&
                                                                profile.policy.deposit != 0 &&
                                                                profile.policy.deposit_fee != 0)
                                                        "
                                                        type="button"
                                                        class="primary-btn extra-small mobile book-appt"
                                                    >
                                                        {{ !loading ? "Book Now" : "Saving..." }}
                                                    </button>
                                                </div>
                                                <div
                                                    v-if="appointment_message"
                                                    v-html="appointment_message"
                                                    class="appt-message"
                                                ></div>
                                            </div>

                                            <div v-if="appointment_saved" class="appt-message-wrap text-center">
                                                <p>
                                                    Thank you
                                                    <span class="client-user-name">{{ user.first_name }}</span
                                                    >!
                                                </p>
                                                <p>
                                                    Your appointment was scheduled for
                                                    <span id="appt-date">{{
                                                        getFormattedDate(booking_date, "MMM D, Y")
                                                    }}</span>
                                                    at <span id="appt-time">{{ booking_hour }}</span>
                                                </p>
                                                <p v-if="profile.auto_confirm_appointments == 0">
                                                    As soon as {{ profile.first_name }} will confirm your appointment
                                                    you will receive a text message with the confirmation.
                                                </p>
                                                <span v-on:click="closeModal" class="action-link">Thank you!</span>
                                            </div>

                                            <div v-if="!showMoreDates" class="text-left">
                                                <span v-on:click="onShowMoreDates()" class="action-link"
                                                    >Click here to view more dates</span
                                                >
                                            </div>
                                        </div>

                                        <div v-if="screen == 'select-services'" class="select-services-screen">
                                            <div class="select-services-head">
                                                <span
                                                    v-on:click="onServicesClose"
                                                    class="action-link select-services-close"
                                                    >CLOSE</span
                                                >
                                                <span class="select-services-title">Select Services</span>
                                                <span
                                                    v-on:click="onServicesSave"
                                                    class="action-link select-services-save"
                                                    >SAVE</span
                                                >
                                            </div>
                                            <div class="select-services-wrap text-left">
                                                <div class="service-category">
                                                    <ul
                                                        v-if="profile.book_modal_template != 2"
                                                        class="category-services-list"
                                                    >
                                                        <li
                                                            v-for="(service, index) in profile.services"
                                                            :key="`service-list-${index}`"
                                                        >
                                                            <label class="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    class="book-service"
                                                                    :value="service.id"
                                                                    v-model="booking_services_edit"
                                                                    v-on:change="setSelectedServices"
                                                                />
                                                                <span class="slider round"></span>
                                                            </label>
                                                            <div>
                                                                <span class="service-title">{{ service.name }}</span>
                                                                <div v-if="service.description" class="service-desc">
                                                                    {{ service.description }}
                                                                </div>
                                                                <p class="service-desc">
                                                                    <span v-if="profile.display_prices">
                                                                        <span v-if="profile.currency == 'USD'"
                                                                            >${{ service.price_default * service.qty }}
                                                                        </span>
                                                                        <span v-if="profile.currency != 'USD'"
                                                                            >{{ service.price_default * service.qty }}
                                                                            {{ profile.currency }}
                                                                        </span>
                                                                        {{ service.price_and_up ? " and up" : "" }}
                                                                    </span>
                                                                    <span v-if="service.duration_default">,</span>
                                                                    <span v-if="service.duration_default">
                                                                        {{
                                                                            getFormattedServiceDuration(
                                                                                service.duration_default * service.qty
                                                                            )
                                                                        }}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <select
                                                                v-model="service.qty"
                                                                v-on:change="$forceUpdate()"
                                                                v-if="booking_services_edit.indexOf(service.id) != -1"
                                                            >
                                                                <option v-for="i in 10" :value="i" :key="`opt-${i}`">
                                                                    {{ i }}
                                                                </option>
                                                            </select>
                                                        </li>
                                                    </ul>

                                                    <div
                                                        v-if="profile.book_modal_template == 2"
                                                        class="service-category-list"
                                                    >
                                                        <template v-for="(category, index) in profile.categories">
                                                            <div
                                                                v-if="category.services.length > 0"
                                                                :key="`categ-${index}`"
                                                                class="stylist-services-block full-width"
                                                            >
                                                                <h3 class="service-block-title">
                                                                    {{ category.name }}
                                                                </h3>
                                                                <div class="line-sep"></div>
                                                                <ul class="category-services-list">
                                                                    <li
                                                                        v-for="(service, i) in category.services"
                                                                        :key="`serv-${i}`"
                                                                    >
                                                                        <label class="switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                class="book-service"
                                                                                :value="service.id"
                                                                                v-model="booking_services_edit"
                                                                                v-on:change="setSelectedServices"
                                                                            />
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                        <div>
                                                                            <span class="service-title">{{
                                                                                service.name
                                                                            }}</span>
                                                                            <div
                                                                                v-if="service.description"
                                                                                class="service-desc"
                                                                            >
                                                                                {{ service.description }}
                                                                            </div>
                                                                            <p class="service-desc">
                                                                                <span v-if="profile.display_prices">
                                                                                    <span
                                                                                        v-if="profile.currency == 'USD'"
                                                                                        >${{
                                                                                            service.price_default *
                                                                                            service.qty
                                                                                        }}
                                                                                    </span>
                                                                                    <span
                                                                                        v-if="profile.currency != 'USD'"
                                                                                        >{{
                                                                                            service.price_default *
                                                                                            service.qty
                                                                                        }}
                                                                                        {{ profile.currency }}
                                                                                    </span>
                                                                                    {{
                                                                                        service.price_and_up
                                                                                            ? " and up"
                                                                                            : ""
                                                                                    }}
                                                                                </span>
                                                                                <span v-if="service.duration_default"
                                                                                    >,</span
                                                                                >
                                                                                <span v-if="service.duration_default">
                                                                                    {{
                                                                                        service.duration_default *
                                                                                        service.qty
                                                                                    }}
                                                                                    minutes
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                        <select
                                                                            v-model="service.qty"
                                                                            v-on:change="$forceUpdate()"
                                                                            v-if="
                                                                                booking_services_edit.indexOf(
                                                                                    service.id
                                                                                ) != -1
                                                                            "
                                                                        >
                                                                            <option
                                                                                v-for="i in 10"
                                                                                :value="i"
                                                                                :key="`opt-${i}`"
                                                                            >
                                                                                {{ i }}
                                                                            </option>
                                                                        </select>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="screen == 'login'" class="login-section form-section">
                                            <div class="login-form-row email-login-row">
                                                <input
                                                    id="email-login"
                                                    type="email"
                                                    v-model="login_email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="login-form-row password-login-row">
                                                <input
                                                    id="password-login"
                                                    type="password"
                                                    v-model="login_password"
                                                    name="password"
                                                    placeholder="Password"
                                                />
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 action-buttons-wrap text-center">
                                                    <button @click="loginAppUser" class="book-button">Login</button>
                                                </div>
                                            </div>
                                            <div class="row login-form-row">
                                                <div
                                                    v-if="login_message"
                                                    v-html="login_message"
                                                    class="col-sm-12"
                                                ></div>
                                            </div>
                                            <div class="login-form-row text-center">
                                                <span class="action-link" v-on:click="screen = 'reset-password'"
                                                    >Forgot password?</span
                                                >
                                            </div>
                                            <div
                                                v-if="profile.work_settings.accept_new_clients !== 0"
                                                class="col-sm-12 forgot-password-text text-center"
                                            >
                                                <span>Don't have an account yet? </span
                                                ><span class="action-link" v-on:click="screen = 'register'"
                                                    >Register Now</span
                                                >
                                            </div>
                                        </div>

                                        <div v-if="screen == 'reset-password'" class="login-section form-section">
                                            <div class="login-form-row email-login-row text-left">
                                                Please enter your email address and we will send you an email with a
                                                link to reset your password.
                                            </div>
                                            <div class="login-form-row email-login-row">
                                                <input
                                                    id="email-login"
                                                    type="email"
                                                    v-model="login_email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6 action-buttons-wrap text-center">
                                                    <button @click="screen = 'login'" class="book-button">
                                                        Back to Login
                                                    </button>
                                                </div>
                                                <div class="col-sm-6 action-buttons-wrap text-center">
                                                    <button
                                                        @click="sendResetPasswordEmail"
                                                        :disabled="!login_email"
                                                        class="book-button"
                                                    >
                                                        Send Email
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="reset-pass-options">
                                                        <span class="link-separator"> -or- </span>
                                                        <a
                                                            class="book-action-link"
                                                            href="#"
                                                            @click="screen = 'request-code'"
                                                            >Request Code</a
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row login-form-row">
                                                <div v-html="reset_pass_message" class="col-sm-12"></div>
                                            </div>
                                        </div>

                                        <div v-if="screen == 'request-code'" class="login-section form-section">
                                            <div class="login-form-row login-phone-row text-center">
                                                <input
                                                    v-model="login_phone_mask"
                                                    v-mask="'(###) ###-####'"
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Mobile Phone Number"
                                                    class="login-phone-input"
                                                />
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="text-center login-form-row">
                                                <button
                                                    type="button"
                                                    class="book-button"
                                                    @click="onRequestCode"
                                                    tabindex="10"
                                                >
                                                    Request Code
                                                </button>
                                            </div>
                                            <div class="text-center">
                                                <span class="action-link" v-on:click="screen = 'login'"
                                                    >Back to Login</span
                                                >
                                            </div>
                                            <div v-if="phone_check_message" v-html="phone_check_message"></div>
                                        </div>

                                        <div v-if="screen == 'register'" class="register-section form-section">
                                            <div class="login-form-row email-login-row">
                                                <input
                                                    type="text"
                                                    v-model="register_name"
                                                    name="name"
                                                    placeholder="First Name & Last Name"
                                                />
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="login-form-row email-login-row">
                                                <input
                                                    type="email"
                                                    v-model="register_email"
                                                    v-on:focusout="checkExistingUser"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="login-form-row password-login-row">
                                                <input
                                                    type="password"
                                                    v-model="register_password"
                                                    name="password"
                                                    placeholder="Password"
                                                />
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="login-form-row password-login-row">
                                                <div class="d-flex">
                                                    <div class="dropdown">
                                                        <span
                                                            class="btn btn-light dropdown-toggle"
                                                            id="phonePrefixes"
                                                            tabindex="0"
                                                            v-on:focus="show_prefixes_dropdown = 1"
                                                            v-on:focusout="show_prefixes_dropdown = 0"
                                                        >
                                                            {{ phonePrefix }}
                                                        </span>
                                                        <div
                                                            v-if="countries.length > 0"
                                                            :class="{ show: show_prefixes_dropdown }"
                                                            class="prefixes-dropdown dropdown-menu"
                                                            aria-labelledby="phonePrefixes"
                                                        >
                                                            <a
                                                                class="dropdown-item"
                                                                v-for="(prefix, index) in countryPrefixes"
                                                                v-bind:key="index"
                                                                v-on:mousedown="
                                                                    phonePrefix = prefix;
                                                                    show_prefixes_dropdown == 0;
                                                                "
                                                                >{{ prefix }}</a
                                                            >
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="
                                                            d-flex
                                                            align-items-center
                                                            justify-content-center
                                                            phone-dash
                                                            p-2
                                                        "
                                                    >
                                                        -
                                                    </div>
                                                    <input
                                                        v-model="register_phone_mask"
                                                        v-mask="'(###) ###-####'"
                                                        v-on:change="
                                                            phone_verified = false;
                                                            updateRegisterPhone();
                                                        "
                                                        v-on:focusout="checkExistingUser"
                                                        type="text"
                                                        name="phone"
                                                    />
                                                </div>
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="login-form-row email-login-row">
                                                <input
                                                    type="text"
                                                    v-model="register_zip"
                                                    name="zip"
                                                    placeholder="ZIP Code"
                                                />
                                                <span class="input-stripe-border"></span>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 action-buttons-wrap text-center">
                                                    <button @click="registerAppUser" class="book-button">
                                                        Register
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row login-form-row">
                                                <div v-html="register_message" class="col-sm-12"></div>
                                            </div>
                                            <div class="login-form-row text-center">
                                                <div>
                                                    By continuing you are indicating that you have read and agree to the
                                                </div>
                                                <div>
                                                    <a
                                                        target="_blank"
                                                        class="action-link"
                                                        href="https://www.ringmystylist.com/terms-and-condition"
                                                        >Terms of Service</a
                                                    >
                                                    &amp;
                                                    <a
                                                        target="_blank"
                                                        class="action-link"
                                                        href="https://www.ringmystylist.com/privacy-policy"
                                                        >Privacy Policy</a
                                                    >
                                                </div>
                                            </div>
                                            <div class="col-sm-12 forgot-password-text text-center">
                                                <span>Already have an account? </span
                                                ><span class="action-link" v-on:click="screen = 'login'">Login</span>
                                            </div>
                                        </div>

                                        <div v-if="screen == 'verify-phone'" class="verify-phone-section form-section">
                                            <p>
                                                To confirm the identity of your account ( associated with
                                                {{ register_phone }}) we sent you an SMS with a validation code.
                                            </p>

                                            <div class="verifiy-code-fields text-center">
                                                <input
                                                    type="text"
                                                    v-model="phone_check_code"
                                                    class="phone-code-field"
                                                    autocomplete="off"
                                                    v-on:keyup.13="checkVerificationCode"
                                                />
                                            </div>
                                            <div class="text-center">
                                                <button
                                                    id="check-verification-code"
                                                    type="button"
                                                    class="book-button"
                                                    @click="checkVerificationCode"
                                                    tabindex="10"
                                                >
                                                    Continue
                                                </button>

                                                <div class="book-action-links">
                                                    <a class="book-action-link" href="#" @click="verifyPhoneNumber"
                                                        >Resend Code</a
                                                    >
                                                    <span class="link-separator">|</span>
                                                    <a class="book-action-link" href="#" @click="editPhoneNumber"
                                                        >Edit Number</a
                                                    >
                                                </div>
                                            </div>
                                            <div v-if="phone_check_message" v-html="phone_check_message"></div>
                                        </div>

                                        <div
                                            v-if="screen == 'user-exists'"
                                            class="user-exists-section form-section text-center"
                                        >
                                            <p>It looks like you’ve booked with Ring My Stylist before.</p>

                                            <div class="text-center">
                                                <div class="book-action-links">
                                                    <a class="book-action-link" href="#" @click="screen = 'login'"
                                                        >Click to Login</a
                                                    >
                                                    <span class="link-separator"> -or- </span>
                                                    <a class="book-action-link" href="#" @click="onSendCode"
                                                        >Request Code</a
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </slot>
                                    <slot v-if="blocked" name="body text-center">
                                        <div class="alert alert-danger" role="alert">
                                            The stylist has been blocked your account.
                                        </div>
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<style type="text/css">
.stylist-name-dropdown {
    margin-bottom: 5px;
}
.new-book-modal .stylist-name-dropdown.show {
    display: inline-flex !important;
}
.stylist-name-dropdown .btn-secondary,
.stylist-name-dropdown .btn-secondary:hover,
.stylist-name-dropdown .btn-secondary:focus,
.stylist-name-dropdown .btn-secondary:active,
.stylist-name-dropdown.show > .btn-secondary.dropdown-toggle {
    background-color: transparent !important;
    color: #333333 !important;
    border: none;
    font-weight: 700;
    padding: 0;
    font-size: 20px;
    outline: none;
    line-height: 20px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.select-services-wrap h3.service-block-title {
    margin-top: 0;
}
.select-services-wrap .stylist-services-block {
    margin-bottom: 10px;
}
.select-services-wrap .service-category-list .category-services-list {
    padding-left: 25px;
}
.new-book-modal .policy-note {
    padding: 10px 15px 1px;
}
.new-book-modal .category-services-list .switch {
    min-width: 40px;
}
@media (max-width: 767px) {
    .new-book-modal input[type="text"],
    .new-book-modal input[type="email"],
    .new-book-modal input[type="password"] {
        font-size: 16px !important;
    }

    body.modal-open {
        height: 100vh !important;
        position: fixed;
        touch-action: none;
        -webkit-overflow-scrolling: none;
    }

    .book-date-time .free-hours {
        margin-bottom: 100px;
    }
}
</style>

<script>
import Vue from "vue";
import api from "./../services/api_endpoints.js";
import auth from "./../services/auth.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueMask from "v-mask";
import { VueMaskDirective } from "v-mask";
import LoadScript from "vue-plugin-load-script";
import eventBus from "./../services/event_bus.js";
import { DropdownPlugin } from "bootstrap-vue";
import _ from "lodash";
import "bootstrap";
import vueSignature from "vue-signature";

Vue.use(DropdownPlugin);
Vue.use(LoadScript);
Vue.use(VueMask);
Vue.directive("mask", VueMaskDirective);

Vue.loadScript(process.env.VUE_APP_SQUARE_SDK);
Vue.loadScript("https://js.stripe.com/v3/");
Vue.loadScript("https://www.paypalobjects.com/api/checkout.js");
Vue.loadScript("https://js.braintreegateway.com/web/3.73.1/js/client.min.js");
Vue.loadScript("https://js.braintreegateway.com/web/3.73.1/js/paypal-checkout.min.js");

export default {
    name: "ModalBookingNew",
    data() {
        return {
            stylist_name: "",
            forgot_pass_url: "https://book.ringmystylist.com/app/forgot-password",
            profile: {},
            logo: "",
            templateDirectory: "",
            loading: false,
            showModal: false,
            countries: [],
            phonePrefix: "+1",
            phone_check_code: "",
            link: {},
            user: {},
            userFirstName: "",
            userLastName: "",
            userEmail: "",
            user_token: "",
            free_hours: [],
            free_hours_group_names: ["morning", "afternoon", "evening"],
            free_hours_groups: { morning: [], afternoon: [], evening: [] },
            booking_date: new Date(),
            booking_date_time: "",
            booking_end_date_time: "",
            booking_date_existing: "",
            booking_notes: "",
            booking_reminders: [],
            booking_client_id: 0,
            booking_hour: "",
            booking_hour_existing: "",
            booking_services: [],
            booking_services_edit: [],
            booking_service_names: [],
            booking_services_total_cost: 0,
            booking_services_total_duration: 0,
            booking_special_event: 0,
            booking_services_and_up: false,
            booking_selected_services_obj: [],
            available_dates: [],
            reminders: "",
            booking_status: "unconfirmed",
            steps: ["calendar-wrapper"],
            agent: "",
            weekDays: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
            disabledDates: {
                to: new Date(Date.now() - 8640000),
                dates: [],
            },
            screen: "step-1",
            slotsPos: [],
            slotsPosUpdates: 0,
            showMoreDates: false,
            loadingUnavailabeDates: false,
            caledarMonthsChecked: 1,
            login_email: "",
            login_password: "",
            login_message: "",
            login_phone_mask: "",
            register_name: "",
            register_email: "",
            register_password: "",
            register_phone: "",
            register_phone_mask: "",
            register_zip: "",
            register_message: "",
            show_prefixes_dropdown: 0,
            phone_check_message: "",
            appointment_message: "",
            appointment_saved: false,
            phone_verified: false,
            has_card: true,
            add_new_card: false,
            card_last_4: "",
            stripe: null,
            stripe_card: null,
            square_card: null,
            stripe_client_secret: "",
            getting_stripe_client_secret: false,
            cards_errors: "",
            blocked: false,
            linked: false,
            existing_user: false,
            square_application_id: "",
            suqare_location_id: "",
            square_card_obj: null,
            square_form_displayed: false,
            paypal_client_token: "",
            paypal_account: null,
            paypal_environment: "",
            deposit_fee: 0,
            deposit_fee_order_id: 0,
            last_appointment_id: 0,
            reset_pass_message: "",
            client_id: 0,
            unavailable_service_days: [],
            unavailable_service_name_days: [],
            signatureOption: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)",
            },
            stripe_cards: [],
            square_cards: [],
            selected_card: "",
            screenWidth: "",
        };
    },
    props: {
        stylistAlias: String,
        stylistName: String,
        serviceId: Number,
    },
    components: {
        Datepicker,
        vueSignature,
    },
    created() {
        this.user = auth.user;

        if (this.user) {
            this.userFirstName = this.user.first_name;
            this.userLastName = this.user.last_name;
            this.userEmail = this.user.email;
            this.client_id = this.user.role == "stylist" ? this.user.client_id : this.user.id;
        }

        this.stylist_name = this.$props.stylistName;

        this.getStylistProfile(this.$props.stylistAlias);
    },
    computed: {
        signatureWidth() {
            return window.innerWidth * 0.9 + "px"; // 90% of viewport width
        },
    },
    mounted() {
        eventBus.$on("user_profile_loaded", this.onLoggedIn);
        eventBus.$on("user_profile_loaded", this.onRegisterSuccess);

        this.getScreenWidth();
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded", this.onLoggedIn);
        eventBus.$off("user_profile_loaded", this.onRegisterSuccess);
    },
    methods: {
        onProfileChange(alias, name) {
            this.stylist_name = name;
            this.setInitialValues();
            this.getStylistProfile(alias);
        },
        setInitialValues() {
            this.booking_date = new Date();
            this.booking_date_time = "";
            this.booking_end_date_time = "";
            this.booking_date_existing = "";
            this.booking_services = [];
            this.booking_services_edit = [];
            this.booking_service_names = [];
            this.booking_services_total_cost = 0;
            this.booking_services_total_duration = 0;
        },
        getDisabledDates() {
            this.disabledDates.days = [];
            var disabledWeekDays = [];
            this.weekDays.forEach((day, i) => {
                if (this.profile.work_settings[day] == 0) {
                    disabledWeekDays.push(i);
                }
            });

            this.profile.free_days.forEach((day) => {
                this.disabledDates.dates.push(moment(day.date).toDate());
            });

            var self = this;
            self.disabledDates.customPredictor = function (date) {
                let date_formatted = new Date(date).toISOString().split("T")[0];
                if (
                    disabledWeekDays.indexOf(date.getDay()) != -1 &&
                    self.profile.exception_days.indexOf(date_formatted) == -1
                ) {
                    return true;
                }
            };
        },
        setServiceDefaultQty() {
            this.profile.services.forEach((service) => {
                service.qty = 1;
            });

            this.profile.categories.forEach((category) => {
                category.services.forEach((service) => {
                    service.qty = 1;
                });
            });
        },
        getStylistProfile(profile_alias) {
            this.$http.get(api.actions.get_stylist_profile + profile_alias).then((response) => {
                if (response.data.error == true) {
                    return;
                }

                this.profile = response.data.profile;
                this.countries = response.data.countries;
                this.countryPrefixes = response.data.prefixes;
                this.blocked = this.profile.blocked;
                this.getDisabledDates();
                this.setServiceDefaultQty();

                if (this.$props.serviceId) {
                    this.setSelectedService(this.$props.serviceId);

                    let service = this.profile.services.filter((el) => {
                        return el.id == this.$props.serviceId;
                    });

                    if (service[0]) {
                        this.booking_selected_services_obj.push(service[0]);
                    }
                } else {
                    this.onAddService();
                }

                this.getNextAvailableDates();

                if (this.profile.phone_prefix) {
                    this.phonePrefix =
                        this.profile.phone_prefix.indexOf("+") == -1
                            ? "+" + this.profile.phone_prefix
                            : this.profile.phone_prefix;
                }

                var othterServices = _.filter(this.profile.services, {
                    category_id: 0,
                });

                if (othterServices.length > 0) {
                    this.profile.categories.push({
                        name: "Other",
                        services: othterServices,
                        services_num: othterServices.length,
                        code: "000",
                    });
                }

                var available_future_days =
                    this.profile.work_settings && this.profile.work_settings.available_future_days > 0
                        ? this.profile.work_settings.available_future_days
                        : 365;
                this.disabledDates.from = moment().add(available_future_days, "days").toDate();
            });
        },
        closeModal() {
            this.profile = null;
            this.$emit("modalClosed");
        },
        onAddService() {
            this.booking_services_edit = _.clone(this.booking_services);
            this.screen = "select-services";
        },
        onServicesClose() {
            if (this.booking_services_edit.length == 0) {
                return;
            }

            this.booking_services_edit = [];
            this.screen = "step-1";
        },
        onServicesSave() {
            if (this.booking_services_edit.length == 0) {
                return;
            }

            this.booking_services = _.clone(this.booking_services_edit);
            this.booking_services_edit = [];
            this.booking_service_names = [];
            this.booking_services_total_cost = 0;
            this.booking_services_total_duration = 0;

            this.disabledDates.dates = [];
            this.getDisabledDates();

            var disabledWeekDays = [];
            this.weekDays.forEach((day, i) => {
                if (this.profile.work_settings[day] == 0) {
                    disabledWeekDays.push(i);
                }
            });

            this.disabledDates.days = [];
            var self = this;
            this.disabledDates.customPredictor = function (date) {
                let date_formatted = new Date(date).toISOString().split("T")[0];
                if (
                    disabledWeekDays.indexOf(date.getDay()) != -1 &&
                    self.profile.exception_days.indexOf(date_formatted) == -1
                ) {
                    return true;
                }
            };

            this.unavailable_service_name_days = [];

            this.booking_services.forEach((id) => {
                this.setSelectedService(id);
            });

            this.showMoreDates = false;
            this.booking_date = "";
            this.booking_date_time = "";

            this.getNextAvailableDates();
            this.screen = "step-1";
            this.checkUserCard();
        },
        updateServiceTotals() {
            this.booking_services_total_cost = 0;
            this.booking_services_total_duration = 0;

            this.booking_selected_services_obj.forEach((service) => {
                this.booking_services_total_cost += parseInt(service.price_default * service.qty);
                this.booking_services_total_duration += parseInt(service.duration_default * service.qty);
            });

            if (!this.booking_date) {
                this.booking_date = new Date();
            }

            this.getNextAvailableDates();
            this.getStylistFreeHours(this.booking_date);

            if (this.booking_date_time) {
                let end_time =
                    moment(this.booking_date_time, "YYYY-MM-DD h:mm A").unix() +
                    this.booking_services_total_duration * 60;
                this.booking_end_date_time = moment.unix(end_time).format("YYYY-MM-DD hh:mm:ss");
            }
        },
        setSelectedService(id) {
            let service = this.profile.services.filter((el) => {
                return el.id == id;
            });

            if (!service[0]) {
                return;
            }

            service = service.pop();
            this.booking_service_names.push(service.name);
            this.booking_services_total_cost += parseInt(service.price_default * service.qty);
            this.booking_services_total_duration += parseInt(service.duration_default * service.qty);

            if (this.booking_services.indexOf(id) == -1) {
                this.booking_services.push(id);
            }

            if (service.price_and_up == 1) {
                this.booking_services_and_up = true;
            } else {
                this.booking_services_and_up = false;
            }

            if (this.profile.policy.deposit != 0 && this.profile.policy.deposit_fee != 0) {
                this.calculateDepositFee();
            }

            let end_time = moment(this.booking_date_time).unix() + this.booking_services_total_duration * 60;
            this.booking_end_date_time = moment.unix(end_time).format("YYYY-MM-DD hh:mm:ss");

            this.unavailable_service_days = [];
            this.unavailable_service_name_days = [];

            this.booking_services.forEach((service_id) => {
                let service = this.profile.services.filter((el) => {
                    return el.id == service_id;
                });

                let restrictions = service[0].restrictions;

                if (!restrictions) {
                    return;
                }

                let service_disabled_days = {
                    service: service[0].name,
                    days: [],
                };

                this.weekDays.forEach((day, i) => {
                    if (restrictions[day] == 0 && this.unavailable_service_days.indexOf(i) == -1) {
                        this.unavailable_service_days.push(i);
                    }

                    if (restrictions[day] == 0) {
                        service_disabled_days.days.push(day.charAt(0).toUpperCase() + day.slice(1));
                    }
                });

                this.unavailable_service_name_days.push(service_disabled_days);
            });

            this.disabledDates.days = [];
            this.weekDays.forEach((day, i) => {
                if (this.unavailable_service_days.indexOf(i) != -1) {
                    this.disabledDates.days.push(i);
                }
            });
        },
        getNextAvailableDates() {
            var data = {
                stylist_id: this.profile.id,
                services_duration: this.booking_services_total_duration,
                current_datetime: moment().format("YYYY-MM-DD h:mm a"),
                disabled_days: this.disabledDates.days,
            };

            this.$http
                .post(api.actions.get_next_available_dates, data, {
                    headers: { "Api-Token": api.DEMO_CLIENT },
                })
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    response.data.forEach((el, k) => {
                        this.slotsPos[k] = 0;
                    });

                    this.available_dates = response.data;
                });
        },
        slotsMoveLeft(index, step_length) {
            if (this.slotsPos[index] >= 0) {
                this.slotsPos[index] = 0;
                return;
            }

            this.slotsPos[index] = this.slotsPos[index] ? this.slotsPos[index] : 0;
            this.slotsPos[index] += step_length;

            if (this.slotsPos[index] > 0) {
                this.slotsPos[index] = 0;
            }

            this.slotsPosUpdates++;
        },
        slotsMoveRight(index, step_length, limit_length) {
            if (this.slotsPos[index] <= limit_length) {
                this.slotsPos[index] = limit_length;
                this.slotsPosUpdates++;
                return;
            }

            this.slotsPos[index] = this.slotsPos[index] ? this.slotsPos[index] : 0;
            this.slotsPos[index] -= step_length;

            if (this.slotsPos[index] < limit_length) {
                this.slotsPos[index] = limit_length;
            }

            this.slotsPosUpdates++;
        },
        getStylistFreeHours(date) {
            var formatedDate = moment(date).format("YYYY-MM-DD");
            var data = {
                stylist_id: this.profile.id,
                date: formatedDate,
                current_datetime: moment().format("YYYY-MM-DD h:mm a"),
                services_duration: this.booking_services_total_duration,
                include_taken: true,
            };

            this.booking_date = formatedDate;

            var action = api.actions.get_free_hours;
            var method = "post";

            if (this.user) {
                action = api.actions.get_stylist_free_hours;
                action += "?stylist_id=" + this.profile.id;
                action += "&date=" + formatedDate;
                action += "&current_datetime=" + moment().format("YYYY-MM-DD h:mm a");
                action += "&services_duration=" + this.booking_services_total_duration;
                action += "&include_taken=1";
                method = "get";
            }

            this.$http[method](action, data, {
                headers: { "Api-Token": api.DEMO_CLIENT },
            }).then((response) => {
                if (response.error == true) {
                    alert("error");
                } else {
                    if (response.data.error == true) {
                        return;
                    } else {
                        this.free_hours = response.data.free_hours;
                        this.free_hours_groups = {
                            morning: [],
                            afternoon: [],
                            evening: [],
                        };

                        let afternoon = moment(formatedDate + " 12:00:00").unix();
                        let evening = moment(formatedDate + " 17:00:00").unix();

                        this.free_hours.forEach((hour) => {
                            var dt = moment(hour, ["h:mm A"]).format("HH:mm");
                            let time = moment(formatedDate + " " + dt).unix();

                            if (time < afternoon) {
                                this.free_hours_groups["morning"].push(hour);
                            } else if (time >= afternoon && time < evening) {
                                this.free_hours_groups["afternoon"].push(hour);
                            } else {
                                this.free_hours_groups["evening"].push(hour);
                            }
                        });

                        this.slotsPos = [];
                        this.free_hours_group_names.forEach((el, k) => {
                            this.slotsPos[k] = 0;
                        });
                    }
                }
            });
        },
        onSelectTime(time, date) {
            if (date) {
                this.booking_date = date;
            }

            this.showMoreDates = true;
            this.booking_hour = time;
            this.booking_date_time = this.booking_date + " " + this.booking_hour;

            var dt = moment(this.booking_hour, ["h:mm A"]).format("HH:mm");
            this.booking_date_time = moment(this.booking_date + " " + dt).format("YYYY-MM-DD HH:mm:ss");

            let end_time = moment(this.booking_date_time).unix() + this.booking_services_total_duration * 60;
            this.booking_end_date_time = moment.unix(end_time).format("YYYY-MM-DD HH:mm:ss");
            this.square_form_displayed = false;

            if (this.profile.logged_in == true) {
                this.checkUserLink();
                this.checkUserCard();
                this.getClientPolicyAgreement();
            }
        },
        onEditTime() {
            this.booking_hour = "";
            this.booking_date_time = "";
            this.booking_end_date_time = "";
        },
        loginAppUser(e) {
            e.preventDefault();
            auth.login(
                {
                    email: this.login_email,
                    password: this.login_password,
                    remember: true,
                },
                false
            );
        },
        onLoggedIn() {
            this.user = auth.user;
            this.client_id = this.user.role == "stylist" ? this.user.client_id : this.user.id;
            this.profile.logged_in = true;
            this.checkUserLink();
            this.getClientPolicyAgreement();
            this.screen = "step-1";
            this.checkUserCard();
        },
        sendResetPasswordEmail() {
            var data = {
                email: this.login_email,
            };
            this.reset_pass_message = "";

            this.$http.post(api.actions.reset_password, data).then((response) => {
                if (response.error == true) {
                    this.reset_pass_message = '<div class="alert alert-danger">There is an error</div>';
                    return;
                }

                if (response.data.error == true) {
                    this.reset_pass_message = '<div class="alert alert-danger">' + response.data.message + "</div>";
                    return;
                }

                this.reset_pass_message = '<div class="alert alert-success">' + response.data.message + "</div>";
            });
        },
        registerAppUser() {
            if (
                !this.register_name ||
                !this.register_email ||
                !this.register_password ||
                !this.register_phone ||
                !this.register_zip
            ) {
                this.register_message = '<div class="alert alert-danger">All the fields are required!</div>';
                return;
            }

            let data = {
                full_name: this.register_name,
                email: this.register_email,
                password: this.register_password,
                role: "client",
                zip: this.register_zip,
                phone: this.register_phone,
                phone_prefix: this.phonePrefix,
            };

            auth.register(data);
        },
        onRegisterSuccess() {
            this.user = auth.user;
            this.client_id = this.user.role == "stylist" ? this.user.client_id : this.user.id;

            this.profile.logged_in = true;
            this.sendUserInvite();
            this.getClientPolicyAgreement();
            this.screen = "step-1";
        },
        onSendCode() {
            this.verifyPhoneNumber();
            this.screen = "verify-phone";
        },
        verifyPhoneNumber() {
            if (this.phone_verified == true) {
                this.registerAppUser();
                return;
            }

            var data = {
                phone_prefix: this.phonePrefix,
                phone: this.register_phone,
            };

            this.register_message = "";

            if (
                (this.existing_user && !this.register_phone) ||
                (!this.existing_user &&
                    (!this.register_name ||
                        !this.register_email ||
                        !this.register_password ||
                        !this.register_phone ||
                        !this.register_zip))
            ) {
                this.register_message = '<div class="alert alert-danger">Fill out the required fields!</div>';
                return;
            }

            this.phone_check_code = "";
            this.phone_check_message = "";

            this.$http.post(api.actions.send_phone_verify_code, data).then((response) => {
                if (response.error == true) {
                    this.register_message = '<div class="alert alert-danger">There is an error</div>';
                    this.screen = "register";
                    return;
                }

                if (response.data.error == true) {
                    this.register_message = '<div class="alert alert-danger">' + response.data.message + "</div>";
                    this.screen = "register";
                }

                if (this.screen == "verify-phone") {
                    this.phone_check_message = '<div class="alert alert-success">The code has been sent</div>';
                }

                this.screen = "verify-phone";
            });
        },
        checkEmptyField(evt, length) {
            if (length > 0) {
                return;
            }

            var target = evt.srcElement;

            while ((target = target.previousElementSibling)) {
                if (target == null) break;
                if (target.tagName.toLowerCase() == "input") {
                    target.focus();
                    break;
                }
            }
        },
        isNumber(evt, maxLength, model) {
            var target = evt.srcElement;

            if (this[model].length > maxLength) {
                this[model] = this[model][0];
            }

            if (this[model].length >= maxLength) {
                var next = target;

                while ((next = next.nextElementSibling)) {
                    if (next == null) break;
                    if (next.tagName.toLowerCase() == "input") {
                        next.focus();
                        break;
                    }
                }
            }
        },
        checkExistingUser() {
            var data = {
                phone: this.register_phone,
                email: this.register_email,
            };

            this.$http.post(api.actions.verify_existing_user, data).then((response) => {
                if (response.error == true) {
                    return;
                }

                if (response.data.error == true) {
                    return;
                }

                if (response.data.exists == true) {
                    this.existing_user = true;
                    this.phonePrefix = response.data.phone_prefix;
                    this.register_phone = response.data.phone;
                    this.screen = "user-exists";
                }
            });
        },
        checkVerificationCode() {
            var data = {
                phone: this.register_phone,
                code: this.phone_check_code,
                passport: 1,
            };

            this.$http.post(api.actions.verify_phone_code, data).then((response) => {
                if (response.error == true) {
                    this.phone_check_message = '<div class="alert alert-danger">There is an error</div>';
                    return;
                }

                if (response.data.error == true) {
                    this.phone_check_message = '<div class="alert alert-danger">' + response.data.message + "</div>";
                } else {
                    this.phone_verified = true;

                    if (!this.existing_user) {
                        this.registerAppUser();
                    } else {
                        if (response.data.login) {
                            auth.logout(false);
                            auth.onLogin(response.data.login, 1);
                            auth.getProfile(false);
                        }
                    }
                }
            });
        },
        getClientPolicyAgreement() {
            this.$http.get(api.actions.get_client_policy_agreement + this.profile.id).then((response) => {
                if (response.error == true) {
                    return;
                }

                if (response.data.error == true) {
                    return;
                }

                this.profile.policy = response.data.policy;
                this.checkUserCard();
            });
        },
        checkUserLink() {
            this.$http
                .get(api.actions.check_user_client_link + "/" + this.profile.id + "/" + this.client_id)
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    if (response.data.error == true) {
                        return;
                    }

                    this.linked = response.data.linked;
                    this.blocked = response.data.blocked;

                    if (!response.data.linked) {
                        this.sendUserInvite();
                    }
                });
        },
        hasPolicyEnabled() {
            return (
                this.profile.policy &&
                ((this.profile.policy.cancel == 1 && this.profile.policy.cancel_fee > 0) ||
                    (this.profile.policy.deposit == 1 && this.profile.policy.deposit_fee > 0)) &&
                this.profile.accept_payments == 1 &&
                (this.profile.accept_stripe || this.profile.accept_square || this.profile.accept_paypal)
            );
        },
        addNewCard() {
            this.add_new_card = true;

            if (this.profile.accept_payments && this.profile.accept_square) {
                this.$http
                    .get(api.actions.get_square_api_credentials + "?stylist_id=" + this.profile.id)
                    .then((res) => {
                        this.square_application_id = res.data.response.application_id;
                        this.displayCardForm();
                    });
                return;
            }

            this.displayCardForm();
        },
        cancelNewCardAdd() {
            this.add_new_card = false;
            this.square_form_displayed = false;
        },
        saveNewCard() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            let self = this;
            async function tokenize(paymentMethod) {
                const tokenResult = await paymentMethod.tokenize();
                if (tokenResult.status === "OK") {
                    return tokenResult.token;
                }
            }

            //Save the card in Stripe
            if (this.profile.accept_payments && this.profile.accept_stripe) {
                this.stripe
                    .confirmCardSetup(this.stripe_client_secret, {
                        payment_method: {
                            card: this.stripe_card,
                            billing_details: {},
                        },
                    })
                    .then((result) => {
                        this.loading = false;
                        if (result.error) {
                            this.cards_errors = result.error.message;
                            return;
                        }
                        this.add_new_card = false;
                        this.checkUserCard();
                    });

                //Save the card in Square
            } else if (this.profile.accept_payments && this.profile.accept_square) {
                this.square_card_obj.then((card) => {
                    try {
                        tokenize(card).then((nonce) => {
                            if (!nonce) {
                                this.loading = false;
                                return;
                            }

                            var data = {
                                card_nonce: nonce,
                                stylist_id: this.profile.id,
                                client_id: this.client_id,
                            };

                            this.loading = true;

                            this.$http.post(api.actions.save_square_customer_card, data).then(() => {
                                this.loading = false;
                                this.add_new_card = false;
                                this.checkUserCard();
                            });
                        });
                    } catch (e) {
                        self.cards_errors = e.message;
                        self.loading = false;
                    }
                });
            }
        },
        checkUserCard() {
            if (!this.user || !this.hasPolicyEnabled()) {
                return;
            }

            var data = {};

            if (this.profile.accept_stripe) {
                this.$http
                    .get(
                        api.actions.get_stripe_payment_methods +
                            "?user_id=" +
                            this.client_id +
                            "&stylist_id=" +
                            this.profile.id
                    )
                    .then((response) => {
                        if (response.data.error) {
                            0;
                            this.has_card = false;
                            this.displayCardForm();
                            return;
                        }

                        this.stripe_cards = response.data.payment_methods;
                        this.selected_card = "";
                        this.card_payment_processor = "stripe";

                        if (!response.data.payment_methods.length > 0) {
                            this.has_card = false;
                            this.displayCardForm();
                        } else {
                            //this.card_last_4 = response.data.payment_methods[0].card.last4;

                            this.selected_card = response.data.payment_methods[0].id;
                        }
                    });
            } else if (this.profile.accept_square) {
                data = {
                    stylist_id: this.profile.id,
                    client_id: this.client_id,
                };

                this.$http.post(api.actions.get_square_customer_cards, data).then((response) => {
                    if (response.data.error) {
                        return;
                    }

                    this.square_cards = response.data.response;
                    this.selected_card = "";
                    this.card_payment_processor = "square";

                    if (!response.data.response.length) {
                        this.has_card = false;

                        this.$http
                            .get(api.actions.get_square_api_credentials + "?stylist_id=" + this.profile.id)
                            .then((res) => {
                                this.square_application_id = res.data.response.application_id;
                                this.displayCardForm();
                            });
                    } else {
                        this.selected_card = response.data.response[0].id;
                    }
                });
            }
            if (this.profile.accept_paypal) {
                data = {
                    stylist_id: this.profile.id,
                    client_id: this.client_id,
                };

                this.$http.post(api.actions.get_paypal_customer_cards, data).then((response) => {
                    if (response.data.error) {
                        return;
                    }

                    if (!response.data.response.length) {
                        this.has_card = false;

                        this.$http
                            .post(api.actions.get_paypal_client_token, {
                                stylist_id: this.profile.id,
                            })
                            .then((res) => {
                                this.paypal_client_token = res.data.client_token;
                                this.paypal_environment = res.data.environment;
                                this.displayCardForm();
                            });
                    }
                });
            }
        },
        sendUserInvite() {
            var data = {
                user_id: this.profile.id,
                client_id: this.client_id,
            };

            this.$http.post(api.actions.save_client_stylist_link, data);
        },
        getStripeSetupIntent() {
            if (this.getting_stripe_client_secret) {
                return;
            }

            this.getting_stripe_client_secret = true;

            this.$http
                .post(api.actions.get_stripe_setup_intent, {
                    user_id: this.client_id,
                    stylist_id: this.profile.id,
                })
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    this.stripe_client_secret = response.data.client_secret;
                    this.getting_stripe_client_secret = false;
                });
        },
        displayCardForm() {
            if (this.profile.accept_payments == 0) {
                return;
            }

            let self = this;

            async function initializeCard(payments) {
                const card = await payments.card();
                await card.attach("#card-container");

                return card;
            }

            function loadSquareForm() {
                const payments = window.Square.payments(self.square_application_id, self.suqare_location_id);

                try {
                    self.square_card_obj = initializeCard(payments);
                } catch (e) {
                    self.cards_errors = "Initializing Card failed";
                    return;
                }
            }

            //Generate the Stripe card form
            if (this.profile.accept_stripe) {
                if (!this.$refs.card_form) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (!this.profile.stripe_public_key) {
                    return;
                }

                this.stripe = window.Stripe(this.profile.stripe_public_key);
                var elements = this.stripe.elements();
                this.stripe_card = elements.create("card");
                this.stripe_card.mount(this.$refs.card_form);

                this.stripe_card.addEventListener("change", (event) => {
                    if (event.error) {
                        this.cards_errors = event.error.message;
                    } else {
                        this.cards_errors = "";
                    }
                });

                this.getStripeSetupIntent();

                //Generate the Square card Form
            } else if (this.profile.accept_square) {
                if (!this.$refs.card_form_square) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (this.square_form_displayed) {
                    return;
                }

                this.square_form_displayed = true;

                this.$http
                    .post(api.actions.get_square_locations, {
                        stylist_id: this.profile.id,
                    })
                    .then((response) => {
                        if (response.data.error == true || !response.data.locations) {
                            this.cards_errors = "Error receiving the Square location";
                            return;
                        }

                        this.suqare_location_id = response.data.locations[0].id;
                        loadSquareForm();
                    });

                //Generate the PayPal vault form
            } else if (this.profile.accept_paypal) {
                if (!this.$refs.card_form_paypal) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (this.paypal_account) {
                    return;
                }

                let paypal_client = {};
                paypal_client[this.paypal_environment] = this.paypal_client_token;

                this.paypal_account = window.paypal.Button.render(
                    {
                        braintree: window.braintree,
                        client: paypal_client,
                        env: this.paypal_environment,
                        commit: true,
                        payment: function (data, actions) {
                            return actions.braintree.create({
                                flow: "vault",
                                enableShippingAddress: false,
                                shippingAddressEditable: false,
                                landingPageType: "billing",
                            });
                        },
                        onAuthorize: (payload) => {
                            var data = {
                                payment_method_nonce: payload.nonce,
                                stylist_id: this.profile.id,
                                client_id: this.client_id,
                            };

                            this.loading = true;

                            this.$http.post(api.actions.save_paypal_customer_card, data).then((response) => {
                                this.loading = false;

                                if (response.error == true) {
                                    return;
                                }

                                if (response.data.error == true) {
                                    return;
                                }

                                this.has_card = true;
                                this.appointment_message = "";
                                this.$http.post(api.actions.save_stylist_policy_agreement, {
                                    stylist_id: this.profile.id,
                                    client_id: this.client_id,
                                    agree: 1,
                                });
                            });
                        },
                    },
                    "#paypal-button"
                );
            }
        },
        saveCard() {
            if (this.loading) {
                return;
            }

            let self = this;
            async function tokenize(paymentMethod) {
                const tokenResult = await paymentMethod.tokenize();
                if (tokenResult.status === "OK") {
                    return tokenResult.token;
                }
            }

            //Save the card in Stripe
            if (this.profile.accept_payments && this.profile.accept_stripe) {
                this.stripe
                    .confirmCardSetup(this.stripe_client_secret, {
                        payment_method: {
                            card: this.stripe_card,
                            billing_details: {},
                        },
                    })
                    .then((result) => {
                        if (result.error) {
                            this.cards_errors = result.error.message;
                            return;
                        } else {
                            this.loading = false;
                            this.has_card = true;
                            this.$http.post(api.actions.save_stylist_policy_agreement, {
                                stylist_id: this.profile.id,
                                client_id: this.client_id,
                                agree: 1,
                            });

                            this.saveAppointment();
                        }
                    });

                //Save the card in Square
            } else if (this.profile.accept_payments && this.profile.accept_square) {
                this.square_card_obj.then((card) => {
                    try {
                        tokenize(card).then((nonce) => {
                            if (!nonce) {
                                return;
                            }

                            var data = {
                                card_nonce: nonce,
                                stylist_id: this.profile.id,
                                client_id: this.client_id,
                            };

                            this.loading = true;

                            this.$http.post(api.actions.save_square_customer_card, data).then((response) => {
                                this.loading = false;

                                if (response.error == true) {
                                    return;
                                }

                                if (response.data.error == true) {
                                    return;
                                }

                                this.has_card = true;
                                this.$http.post(api.actions.save_stylist_policy_agreement, {
                                    stylist_id: this.profile.id,
                                    client_id: this.user.id,
                                    agree: 1,
                                });
                                this.saveAppointment();
                            });
                        });
                    } catch (e) {
                        self.cards_errors = e.message;
                    }
                });
            } else if (this.profile.accept_payments && this.profile.accept_paypal) {
                if (!this.has_card) {
                    this.appointment_message =
                        '<div class="alert alert-danger">You have to add the PayPal account first.</div>';
                    return;
                }

                this.saveAppointment();
            }
        },
        handleSaveAppointment() {
            if (!this.linked && this.profile.work_settings.accept_new_clients === 0) {
                let message = this.profile.work_settings.accept_new_clients_text
                    ? this.profile.work_settings.accept_new_clients_text
                    : this.$ml.get("schedule.acceptNewClientsOffMessage");
                this.appointment_message = '<div class="alert alert-danger">' + message + "</div>";
                return;
            }

            if (this.has_card == false) {
                this.saveCard();
                return;
            }

            this.saveAppointment();
        },
        getNewServices(services) {
            let response = {};

            if (this.profile.book_modal_template == 2) {
                this.profile.categories.forEach((category) => {
                    category.services.forEach((service) => {
                        if (services.indexOf(parseInt(service.id)) !== -1) {
                            response[service.id] = {
                                quantity: service.qty,
                                duration: service.duration * service.qty,
                                price: service.price * service.qty,
                            };
                        }
                    });
                });
            } else {
                this.profile.services.forEach((service) => {
                    if (services.indexOf(parseInt(service.id)) !== -1) {
                        response[service.id] = {
                            quantity: service.qty,
                            duration: service.duration * service.qty,
                            price: service.price * service.qty,
                        };
                    }
                });
            }

            return response;
        },
        saveAppointment() {
            if (this.loading) {
                return;
            }

            let services = [];

            if (typeof this.booking_services == "string") {
                services = this.booking_services.split(",");
            } else {
                services = this.booking_services;
            }

            var data = {
                date_time: this.booking_date,
                hour: this.booking_hour,
                services: services,
                user_id: this.profile.id,
                client_id: this.client_id,
                status: "unconfirmed",
                notes: this.booking_notes,
                total_duration: this.booking_services_total_duration,
                reminders: "",
                special_event: 0,
                new_services: this.getNewServices(services),
                card_payment_processor: this.card_payment_processor,
                card_id: this.selected_card,
            };

            if (this.deposit_fee) {
                data["deposit_fee"] = this.deposit_fee;
            }

            this.appointment_message = "";
            this.loading = true;

            this.$http.post(api.actions.save_appointment, data).then((response) => {
                this.loading = false;

                if (response.data.error == true) {
                    this.appointment_message = '<div class="alert alert-danger">' + response.data.message + "</div>";
                    return;
                }

                this.last_appointment_id = response.data.appointment_id;
                this.appointment_saved = true;
                this.saveSignature();
            });
        },
        updateRegisterPhone() {
            this.register_phone = this.register_phone_mask.replace(/\D/g, "");
        },
        parseISOLocal(s) {
            var b = s.split(/\D/);
            return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        },
        getFormattedDate(date, format) {
            return moment(date).format(format);
        },
        onRequestCode() {
            var data = {
                phone: this.login_phone_mask.replace(/\D/g, ""),
            };

            this.$http.post(api.actions.verify_existing_user, data).then((response) => {
                if (response.error == true) {
                    this.phone_check_message = '<div class="alert alert-danger">Error checking the phone</div>';
                    return;
                }

                if (response.data.error == true) {
                    this.phone_check_message = '<div class="alert alert-danger">' + response.data.message + "</div>";
                    return;
                }

                if (response.data.exists == true) {
                    this.existing_user = true;
                    this.phonePrefix = response.data.phone_prefix;
                    this.register_phone = response.data.phone;
                    this.onSendCode();
                } else {
                    this.phone_check_message =
                        '<div class="alert alert-danger">The phone does not match any user</div>';
                }
            });
        },
        editPhoneNumber() {
            this.screen = !this.login_phone_mask ? "register" : "request-code";
            this.phone_check_message = "";
        },
        getFormattedServiceDuration(duration) {
            let service_duration = "";
            let duration_hours = Math.floor(duration / 60);
            let duration_min_left = duration % 60;

            if (duration_hours) {
                service_duration = duration_hours + " " + (duration_hours == 1 ? "hour" : "hours");
            }

            if (duration_hours && duration_min_left) {
                service_duration += " and ";
            }

            if (duration_min_left) {
                service_duration += duration_min_left + " " + "minutes";
            }

            return service_duration;
        },
        onShowMoreDates(startDate) {
            this.showMoreDates = true;
            this.loadingUnavailabeDates = true;

            if (!startDate) {
                startDate = moment().format("YYYY-MM-DD");
            }

            var data = {
                months_check: 1,
                services_duration: this.booking_services_total_duration,
                start_date: startDate,
                stylist_id: this.profile.id,
            };

            this.$http.post(api.actions.get_unavailable_dates, data).then((response) => {
                this.loadingUnavailabeDates = false;

                if (response.data && response.error == true) {
                    return;
                }

                this.getDisabledDates();

                response.data.forEach((date) => {
                    this.disabledDates.dates.push(moment(date).toDate());
                });
            });
        },
        onCalendarMonthChange(firstOfMonth) {
            let startDate = moment(firstOfMonth).format("YYYY-MM-DD");
            this.onShowMoreDates(startDate);
        },
        setSelectedServices() {
            this.booking_selected_services_obj = [];

            if (this.profile.book_modal_template == 2) {
                this.profile.categories.forEach((category) => {
                    category.services.forEach((service) => {
                        if (this.booking_services_edit.indexOf(service.id) != -1) {
                            this.booking_selected_services_obj.push(service);
                        }
                    });
                });
                return;
            }

            this.profile.services.forEach((service) => {
                if (this.booking_services_edit.indexOf(service.id) != -1) {
                    this.booking_selected_services_obj.push(service);
                }
            });
        },
        resetDateChoose() {
            this.showMoreDates = false;
            this.booking_date = "";
            this.booking_date_time = "";
            this.screen = "step-1";
        },
        deleteService(index, service_id) {
            this.booking_services.splice(this.booking_services.indexOf(service_id), 1);
            this.booking_selected_services_obj.splice(index, 1);

            if (!this.booking_date) {
                this.booking_date = new Date();
            }

            this.updateServiceTotals();
            this.getStylistFreeHours(this.booking_date);

            if (this.booking_date_time) {
                let end_time = moment(this.booking_date_time).unix() + this.booking_services_total_duration * 60;
                this.booking_end_date_time = moment.unix(end_time).format("YYYY-MM-DD hh:mm:ss");
            }

            this.resetDateChoose();

            if (this.booking_services.length == 0) {
                this.onAddService();
            }
        },
        saveSignature() {
            var _this = this;
            var png = _this.$refs.signature.save();
            this.$refs.signature.isEmpty();

            let data = {
                appointment_id: this.last_appointment_id,
                user_id: this.user.id,
                signature: png,
                dataImage: 1,
                type: "deposit_fee",
            };

            this.loading = false;

            this.$http.post(api.actions.save_signature, data).then((response) => {
                if (response.body.error == true) {
                    return;
                }
            });
        },
        clearSignature() {
            var _this = this;
            _this.$refs.signature.clear();
        },
        undoSignature() {
            var _this = this;
            _this.$refs.signature.undo();
        },
        getScreenWidth() {
            if (window.innerWidth < 375) {
                this.screenWidth = "258px";
            }
            if (window.innerWidth >= 375) {
                this.screenWidth = "300px";
            }

            if (window.innerWidth > 620) {
                this.screenWidth = "536px";
            }
        },
        calculateDepositFee() {
            if (this.profile.policy.deposit_type == "fixed") {
                this.deposit_fee = this.profile.policy.deposit_fee;
            } else {
                this.deposit_fee = (this.booking_services_total_cost * (this.profile.policy.deposit_fee / 100)).toFixed(
                    2
                );
            }
        },
    },
};
</script>
