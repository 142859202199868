<template>
    <div
        v-if="salon"
        class="salon-component main-wrap-inner"
        :class="{
            'rms-page': generalOptions.app_id == 1,
            'rmb-page': generalOptions.app_id == 2,
            'rmg-page': generalOptions.app_id == 3,
        }"
    >
        <div class="side-bar-column">
            <div v-if="salon.image" class="salon-avatar">
                <img :src="salon.image" :alt="salon.name" />
            </div>
            <h1 v-if="show_name" class="salon-name">{{ salon.name }}</h1>
            <SalonSidebarSection v-if="salon" 
                :salon="salon" 
                :id="1" 
                :twitter_url="twitter_url"
                :facebook_url="facebook_url"
                :instagram_url="instagram_url"
                :yelp_url="yelp_url"
                class="desktop-section"></SalonSidebarSection>
        </div>
        <div class="content-column">
            <div class="breadcrumbs">
                <div
                    class="breadcrumb-item active"
                    :class="{ pointer: currentStep > 0 }"
                    v-on:click="
                        currentStep = 0;
                        onResetData();
                    "
                >
                    Professional
                </div>
                <div
                    class="breadcrumb-item"
                    :class="{
                        active: currentStep >= 1,
                        pointer: currentStep > 1,
                    }"
                    v-on:click="currentStep > 1 ? (currentStep = 1) : {}"
                >
                    Services
                </div>
                <div class="breadcrumb-item" :class="{ active: currentStep >= 2 }">Time</div>
                <div class="breadcrumb-item" :class="{ active: currentStep == 3 }">Done</div>
            </div>

            <div class="page-wrap">
                <div id="step-1" v-if="currentStep == 0">
                    <h2 class="section-title">Choose a professional</h2>
                    <div class="salon-staff">
                        <div v-for="(user, index) in salon.users" :key="index" class="staff-member"
                            v-on:click="onChooseStylist(user)">
                            <div v-if="user.profile.avatar" class="staff-member-avatar-wrap">
                                <img :src="user.avatar" :alt="user.first_name + ' ' + user.last_name" />
                            </div>
                            <div class="staff-member-name">
                                <div>{{ user.first_name }} {{ user.last_name }}</div>
                            </div>
                            <div v-if="user.next_available" class="staff-member-details">
                                Next available on {{ user.next_available }}
                            </div>
                            <div class="staff-member-select">
                                <button class="book-now-button">Book Now</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="step-2" v-if="currentStep == 1">
                    <h2 class="section-title">Choose service(s)</h2>
                    <div class="services-wrap">
                        <select class="general-select" v-model="selectedStylistId" v-on:change="onStylistChange">
                            <option v-for="(user, index) in salon.users" :key="`user-${index}`" :value="user.user_id">
                                {{ user.first_name }} {{ user.last_name }}
                            </option>
                        </select>
                        <div
                            v-if="selectedStylist.services && selectedStylist.services.length > 0"
                            class="services-inner"
                        >
                            <div
                                v-for="(service, i) in selectedStylist.services"
                                :key="`service-${i}`"
                                class="service-item"
                            >
                                <h3 class="service-name">
                                    <span class="add-service-to-cart" v-on:click="onSelectService(service)">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </span>
                                    {{ service.name }}
                                </h3>
                                <div class="service-meta">
                                    <div class="service-desc">
                                        <div v-if="service.image" class="service-image">
                                            <img
                                                :src="baseImageUrl + selectedStylist.user_id + '/thumb_' + service.image"
                                                :alt="service.name"
                                            />
                                        </div>
                                        <p class="service-description">
                                            {{ service.description }}
                                        </p>
                                        <div class="service-price-wrap">
                                            <span v-if="selectedStylist.settings.display_prices">
                                                <span v-if="selectedStylist.profile.currency == 'USD'"
                                                    >${{ service.price_default }}
                                                </span>
                                                <span v-if="selectedStylist.profile.currency != 'USD'"
                                                    >{{ service.price_default }}
                                                    {{ selectedStylist.profile.currency }}
                                                </span>
                                                {{ service.price_and_up ? " and up" : "" }}</span
                                            >
                                            <span v-if="service.duration_default">,</span>
                                            <span v-if="service.duration_default">
                                                {{ getFormattedServiceDuration(service.duration_default) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="step-3" v-if="currentStep == 2">
                    <h2 class="section-title">
                        <span v-if="hasCard && !addNewCard">Choose Time</span>
                    </h2>
                    <div v-if="!addNewCard" class="avaiable-dates-wrap">
                        <div v-if="!showMoreDates && hasCard">
                            <div
                                v-for="(date, index) in availableDates"
                                :key="index"
                                class="available-date"
                                :data-up="slotsPosUpdates"
                            >
                                <div class="date">
                                    {{ getFormattedDate(date.date, "dddd, MMM D") }}
                                </div>
                                <div :ref="`slots-ref-1-${index}`" class="slots-wrap">
                                    <div
                                        v-if="date.slots.length > 5"
                                        v-on:click="
                                            slotsMoveLeft(index, $refs['slots-ref-1-' + index][0].clientWidth / 2)
                                        "
                                        class="slots-left"
                                    >
                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                    </div>
                                    <div
                                        class="slots has-more"
                                        :class="{
                                            'has-more': date.slots.length > 5,
                                        }"
                                    >
                                        <div
                                            :ref="`slots-inner-ref-1-${index}`"
                                            class="slots-inner"
                                            :style="{
                                                width: 100 * date.slots.length + 'px',
                                                left: slotsPos[index] + 'px',
                                            }"
                                        >
                                            <span
                                                v-on:click="onSelectTime(slot, date.date)"
                                                class="slot"
                                                v-for="(slot, i) in date.slots"
                                                :key="i"
                                                >{{ slot }}</span
                                            >
                                        </div>
                                    </div>
                                    <div
                                        v-if="date.slots.length > 5"
                                        v-on:click="
                                            slotsMoveRight(
                                                index,
                                                $refs['slots-ref-1-' + index][0].clientWidth / 2,
                                                -(
                                                    $refs['slots-inner-ref-1-' + index][0].clientWidth -
                                                    $refs['slots-ref-1-' + index][0].clientWidth +
                                                    20
                                                )
                                            )
                                        "
                                        class="slots-right"
                                    >
                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!showMoreDates && hasCard" class="text-left">
                            <span v-on:click="onShowMoreDates()" class="action-link"
                                >Click here to view more dates</span
                            >
                        </div>
                        <div v-if="showMoreDates && hasCard" class="more-dates-wrap text-left">
                            <div v-if="bookingDate" class="date">
                                {{ getFormattedDate(bookingDate, "dddd, MMM D") }}
                                - {{ bookingHour }}
                            </div>
                            <div class="more-dates-wrap-inner">
                                <div class="book-caledar-wrapper">
                                    <datepicker
                                        @selected="onDateSelected"
                                        @changedMonth="onCalendarMonthChange"
                                        :inline="true"
                                        :disabledDates="disabledDates"
                                        :value="bookingDate"
                                    ></datepicker>
                                    <div class="loader-wrap" v-if="loadingUnavailabeDates">
                                        <div class="loading-icon-wrap">
                                            <i class="loading-icon"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="book-date-time">
                                    <div class="free-hours">
                                        <div
                                            v-for="(group, index) in freeHoursGroupNames"
                                            :key="index"
                                            class="free-hours-group"
                                            :data-up="slotsPosUpdates"
                                        >
                                            <h4 v-if="freeHoursGroups[group].length > 0" class="group-name">
                                                {{ group }}
                                            </h4>
                                            <div
                                                :ref="`slots-ref-2-${index}`"
                                                v-if="freeHoursGroups[group].length > 0"
                                                class="slots-wrap"
                                            >
                                                <div
                                                    v-if="freeHoursGroups[group].length > 2"
                                                    v-on:click="
                                                        slotsMoveLeft(
                                                            index,
                                                            $refs['slots-ref-2-' + index][0].clientWidth / 2
                                                        )
                                                    "
                                                    class="slots-left"
                                                >
                                                    <i aria-hidden="true" class="fa fa-angle-left"></i>
                                                </div>
                                                <div
                                                    class="slots has-more"
                                                    :class="{
                                                        'has-more': freeHoursGroups[group].length > 2,
                                                    }"
                                                >
                                                    <div
                                                        :ref="`slots-inner-ref-2-${index}`"
                                                        class="slots-inner"
                                                        :style="{
                                                            width: 101 * freeHoursGroups[group].length + 'px',
                                                            left: slotsPos[index] + 'px',
                                                        }"
                                                    >
                                                        <span
                                                            v-on:click="onSelectTime(slot)"
                                                            class="slot"
                                                            :class="{
                                                                selected: bookingHour == slot,
                                                            }"
                                                            v-for="(slot, i) in freeHoursGroups[group]"
                                                            :key="i"
                                                            >{{ slot }}</span
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="freeHoursGroups[group].length > 2"
                                                    v-on:click="
                                                        slotsMoveRight(
                                                            index,
                                                            $refs['slots-ref-2-' + index][0].clientWidth / 2,
                                                            -(
                                                                $refs['slots-inner-ref-2-' + index][0].clientWidth -
                                                                $refs['slots-ref-2-' + index][0].clientWidth +
                                                                40
                                                            )
                                                        )
                                                    "
                                                    class="slots-right"
                                                >
                                                    <i aria-hidden="true" class="fa fa-angle-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="unavailableServiceNameDays.length > 0" class="unavailable-service-days">
                                <p v-for="(item, i) in unavailableServiceNameDays" :key="i">
                                    <span v-if="item.days.length > 0"
                                        ><b>{{ item.service }}</b> is not available on {{ item.days.join(", ") }}.</span
                                    >
                                </p>
                            </div>
                        </div>

                        <div v-if="user && user.id && !hasCard" class="policy-note">
                            <h3>
                                A credit card is required for this reservation.
                            </h3>
                            <p
                                v-if="
                                    (selectedStylist.policy && selectedStylist.policy.deposit == 0) ||
                                    selectedStylist.policy.deposit_fee == 0
                                "
                            >
                                Your card will not be charged, services payment will be collected after rendering the
                                services.
                            </p>
                            <p
                                v-if="
                                    selectedStylist.policy &&
                                    selectedStylist.policy.deposit != 0 &&
                                    selectedStylist.policy.deposit_fee != 0
                                "
                            >
                                Your card will be charged with the deposit fee:
                                <strong
                                    ><span>{{
                                        selectedStylist.profile.currency == "USD"
                                            ? "$"
                                            : selectedStylist.profile.currency + " "
                                    }}</span
                                    >{{ depositFee }}</strong
                                >
                            </p>

                            <div v-if="selectedStylist.accept_stripe" class="card-form" ref="card_form"></div>
                            <div
                                v-if="selectedStylist.accept_square && !selectedStylist.accept_stripe"
                                class="card-form card-form-square"
                                ref="card_form_square"
                            >
                                <div id="card-container"></div>
                            </div>
                            <div
                                v-if="
                                    selectedStylist.accept_paypal &&
                                    !selectedStylist.accept_stripe &&
                                    !selectedStylist.accept_square
                                "
                                class="card-form card-form-paypal"
                                ref="card_form_paypal"
                            >
                                <div id="paypal-button"></div>
                            </div>
                            <div class="card-logos">
                                <img class="logos" src="../assets/images/card-logos.png" />
                                <img class="verified" src="../assets/images/verified-secured.jpg" />
                            </div>
                            <div class="card-errors">{{ cardsErrors }}</div>

                            <div
                                v-if="
                                    selectedStylist.policy &&
                                    selectedStylist.policy.cancel == 1 &&
                                    selectedStylist.policy.cancel_fee > 0
                                "
                            >
                                <h4>Cancellation Policy</h4>
                                <p>
                                    You will not be charged if you cancel at least
                                    {{ selectedStylist.policy.cancel_advance_hours }} hours before your appointment
                                    starts. Otherwise, you will be charged up to
                                    {{ selectedStylist.policy.cancel_fee }}% of the services booked.
                                </p>
                            </div>

                            <div class="text-center">
                                <button
                                    class="general-button"
                                    v-on:click="saveCard"
                                >
                                    <span v-if="!loading">Save Card & Continue</span>
                                    <span v-if="loading">Saving...</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="addNewCard" class="avaiable-dates-wrap">
                        <div v-if="user && user.id" class="policy-note">
                            <div v-if="selectedStylist.accept_stripe" class="card-form" ref="card_form"></div>
                            <div
                                v-if="selectedStylist.accept_square && !selectedStylist.accept_stripe"
                                class="card-form card-form-square"
                                ref="card_form_square"
                            >
                                <div id="card-container"></div>
                            </div>
                            <div class="card-logos">
                                <img class="logos" src="../assets/images/card-logos.png" />
                                <img class="verified" src="../assets/images/verified-secured.jpg" />
                            </div>
                            <div class="card-errors">{{ cardsErrors }}</div>

                            <div class="text-center">
                                <span v-if="!loading" v-on:click="saveNewCard" class="action-link link-bold">Save card</span>
                                <span v-if="loading" class="action-link">Saving...</span>
                                <span v-if="!loading">
                                    | <span v-on:click="cancelNewCardAdd" class="cancel-link">Cancel</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="step-4" v-if="currentStep == 3">
                    <div class="avaiable-dates-wrap text-center">
                        <h4>
                            Thank you,
                            <span class="client-user-name">{{ user.first_name }}</span
                            >!
                        </h4>
                        <p class="note-text">
                            Your appointment has been scheduled<br>
                            for <span id="appt-date">{{ getFormattedDate(bookingDate, "MMM D, Y") }}</span>
                            at <span id="appt-time">{{ bookingHour }}</span>
                        </p>
                        <span v-on:click="resetProcess" class="action-link large">Shop</span> |
                        <router-link class="action-link large" :to="{ path: '/app/login' }">My Account</router-link>
                    </div>
                </div>

                <div v-if="selectedServices.length > 0" class="service-cart" :class="{'collapsed': cartCollapsed}">
                    <h3 v-on:click="cartCollapsed=!cartCollapsed">Your Order <span v-if="cartCollapsed">({{cartItems}})</span></h3>
                    <div v-if="!displayLogin">
                        <div class="cart-title">{{ selectedStylist.first_name }} {{ selectedStylist.last_name }}</div>
                        <ul v-if="selectedServices.length > 0 && !cartCollapsed" class="category-services-list">
                            <li v-for="(service, index) in selectedServices" :key="`service-${index}`">
                                <div>
                                    <span class="service-names">{{ service.name }}</span>
                                    <p class="service-desc">
                                        <span v-if="selectedStylist.settings.display_prices">
                                            <span v-if="selectedStylist.profile.currency == 'USD'"
                                                >${{ service.price_default * service.qty }}
                                            </span>
                                            <span v-if="selectedStylist.profile.currency != 'USD'"
                                                >{{ service.price_default * service.qty }}
                                                {{ selectedStylist.profile.currency }}
                                            </span>
                                            {{ service.price_and_up ? " and up" : "" }}
                                        </span>
                                        <span v-if="service.duration_default">,</span>
                                        <span v-if="service.duration_default">
                                            {{ getFormattedServiceDuration(service.duration_default * service.qty) }}
                                        </span>
                                    </p>
                                </div>
                                <select v-model="service.qty" v-on:change="onServiceQtyChange(service)">
                                    <option v-for="i in 10" :value="i" :key="`opt-${i}`">
                                        {{ i }}
                                    </option>
                                </select>
                                <span class="delete-link" v-on:click="deleteService(index)">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </span>
                            </li>
                        </ul>
                        <div class="notes-wrap">
                            <div class="notes-input-wrap">
                                <input v-model="bookingNotes" type="text" name="notes" class="notes-input" placeholder="Any notes?" />
                            </div>
                        </div>
                        <div v-if="bookingHour!=''" class="datetime-wrap">
                            {{ getFormattedDate(bookingDate, "MMM Do") }} at {{ bookingHour }}
                        </div>
                        <div class="action-button-wrap">
                            <button class="general-button" v-if="currentStep < 2" v-on:click="onContinueToTime">
                                Continue
                            </button>
                            <button class="general-button" v-if="currentStep == 2 && !bookingHour && hasCard"
                                v-on:click="onChooseTime">
                                Choose a Time
                            </button>
                            <button
                                class="general-button"
                                v-if="currentStep == 2 && bookingHour && !user"
                                v-on:click="displayLogin = true;cartCollapsed = false"
                            >
                                Continue
                            </button>

                            <div
                                v-if="
                                    hasPolicyEnabled() &&
                                    hasCard &&
                                    currentStep == 2 &&
                                    bookingHour &&
                                    user &&
                                    !loading &&
                                    selectedStylist.policy.deposit != 0 &&
                                    selectedStylist.policy.deposit_fee != 0 &&
                                    !addNewCard
                                "
                                class="policy-note"
                            >
                                Your card <span v-if="cardLast4">ending in <strong>{{cardLast4}}</strong></span> will be charged with the deposit fee:
                                <strong
                                    ><span>{{
                                        selectedStylist.profile.currency == "USD"
                                            ? "$"
                                            : selectedStylist.profile.currency + " "
                                    }}</span
                                    >{{ depositFee }}</strong
                                >
                                <br><br>
                                <span v-on:click="addOtherCard" class="action-link">Add other card</span>
                            </div>

                            <button
                                class="general-button"
                                v-if="currentStep == 2 && bookingHour && user && hasCard && !loading && !addNewCard"
                                v-on:click="bookAppointment"
                            >
                                <span v-if="!hasPolicyEnabled() || selectedStylist.policy.deposit_fee == 0"
                                    >Reserve</span
                                >
                                <span v-if="hasPolicyEnabled() && selectedStylist.policy.deposit_fee > 0"
                                    >Reserve & Pay</span
                                >
                            </button>
                            <div class="message-wrap" v-if="appointmentMessage" v-html="appointmentMessage"></div>

                            <button
                                class="general-button"
                                v-if="currentStep == 2 && bookingHour && user && hasCard && loading && !addNewCard"
                                v-on:click="bookAppointment"
                            >
                                Saving...
                            </button>
                        </div>
                    </div>
                    <div v-if="displayLogin && !cartCollapsed" class="login-section form-section">
                        <div class="login-form-row email-login-row">
                            <input
                                id="email-login"
                                type="email"
                                v-model="loginEmail"
                                name="email"
                                placeholder="Email"
                            />
                            <span class="input-stripe-border"></span>
                        </div>
                        <div class="login-form-row password-login-row">
                            <input
                                id="password-login"
                                type="password"
                                v-model="loginPassword"
                                name="password"
                                placeholder="Password"
                            />
                            <span class="input-stripe-border"></span>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 action-buttons-wrap text-center">
                                <button @click="loginAppUser" class="book-button">Login</button>
                            </div>
                        </div>
                        <div class="row login-form-row">
                            <div v-if="loginMessage" v-html="loginMessage" class="col-sm-12"></div>
                        </div>
                        <div class="login-form-row login-forgot-pass text-center">
                            <a
                                class="action-link"
                                target="_blank"
                                :href="base_url + '/app/forgot-password'"
                                >Forgot password?</a
                            >
                        </div>
                        <div class="col-sm-12 text-center">
                            <span>Don't have an account yet? <br /></span
                            ><a class="action-link" target="_blank" :href="base_url + '/app/register'"
                                >Register Now</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <SalonSidebarSection v-if="salon" 
                :salon="salon" 
                :id="2" 
                :twitter_url="twitter_url"
                :facebook_url="facebook_url"
                :instagram_url="instagram_url"
                :yelp_url="yelp_url"
                class="mobile-section"></SalonSidebarSection>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import $ from "jquery";
import moment from "moment";
import api from "./../services/api_endpoints.js";
import eventBus from "./../services/event_bus.js";
import auth from "./../services/auth.js";
import SalonSidebarSection from "./salon_sidebar_section";
import * as VueGoogleMaps from "vue2-google-maps";
import Datepicker from "vuejs-datepicker";

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_MAPS_API_KEY,
        libraries: "places",
    },
    installComponents: true,
});

export default {
    name: "Salon",
    data: function () {
        return {
            user: null,
            salon: null,
            show_name: 1,
            marker_position: null,
            randomDisplayImages: [],
            gallery_images: [],
            profileLikes: [],
            currentShareProfile: {},
            facebook_url: "",
            twitter_url: "",
            instagram_url: "",
            yelp_url: "",
            base_url: '',
            facebookBaseUrl: "http://facebook.com/",
            instagramBaseUrl: "http://instagram.com/",
            yelpBaseUrl: "http://yelp.com/",
            twitterBaseUrl: "http://twitter.com/",
            modalStylistAlias: "",
            modalStylistServiceId: 0,
            addressLat: 0,
            addressLng: 0,
            workDays: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
            disabledDates: {
                to: new Date(Date.now() - 8640000),
                dates: [],
            },
            slotsPos: [],
            slotsPosUpdates: 0,
            currentStep: 0,
            selectedStylist: null,
            selectedStylistId: 0,
            baseImageUrl: "",
            selectedServices: [],
            selectedServicesTotalDuration: 0,
            selectedServicesTotalCost: 0,
            bookingNotes: "",
            bookingDate: new Date(),
            bookingDateTime: new Date(),
            bookingHour: "",
            bookingEndDateTime: new Date(),
            unavailableServiceDays: [],
            unavailableServiceNameDays: [],
            freeHours: [],
            freeHoursGroups: {
                morning: [],
                afternoon: [],
                evening: [],
            },
            freeHoursGroupNames: ["morning", "afternoon", "evening"],
            availableDates: [],
            showMoreDates: false,
            loadingUnavailabeDates: false,
            displayLogin: false,
            loginEmail: "",
            loginPassword: "",
            loginMessage: "",
            hasCard: true,
            addNewCard: false,
            cardLast4: '',
            blocked: false,
            linked: false,
            squareFormDisplayed: false,
            stripeFormDisplayed: false,
            squareApplicationId: "",
            suqareLocationId: "",
            squareCardObj: null,
            squareCard: null,
            stripe: null,
            stripeCard: null,
            stripeClientSecret: "",
            gettingStripeClientSecret: false,
            cardsErrors: "",
            depositFee: 0,
            appointmentMessage: "",
            loading: false,
            cartItems: 0,
            cartCollapsed: false
        };
    },
    mounted: function () {
        this.$nextTick(function () {
            if (!this.$route.params.alias) {
                this.$router.push("/");
            }

            this.base_url = window.location.origin;
            this.user = auth.user;
            this.getSalonDetails(this.$route.params.alias);

            this.baseImageUrl = process.env.VUE_APP_BASE_SERVICE_IMAGE_URL;
            document.querySelector("body").classList.add('shop-page');
        });

        eventBus.$on("user_profile_loaded", this.onLoggedIn);
    },
    beforeDestroy() {
        eventBus.$off("user_profile_loaded", this.onLoggedIn);
    },
    components: {
        SalonSidebarSection,
        Datepicker,
    },
    methods: {
        getSalonDetails(alias) {
            this.$http.get(api.actions.get_salon_details_new + alias + "?save_view_log=1").then((response) => {
                if (response.data.error == true) {
                    this.$router.push("/");
                    return false;
                }

                if(response.data.salon.app_id != this.generalOptions.app_id && response.data.salon.app_id) {
                    window.location =  process.env['VUE_APP_SALON_PAGE_'+response.data.salon.app_id] + response.data.salon.alias;
                    return;
                }

                this.salon = response.data.salon;
                this.show_name = response.data.salon.show_name;

                var fields = ["facebook_url", "instagram_url", "yelp_url", "twitter_url"];
                var modelFieldsBase = ["facebookBaseUrl", "instagramBaseUrl", "yelpBaseUrl", "twitterBaseUrl"];

                fields.forEach((field, k) => {
                    if (this.salon[field] && this.salon[field].indexOf("http") == -1) {
                        this[field] = "http://" + this.salon[field];
                    } else {
                        this[field] = this.salon[field];
                    }

                    if (this.salon[field] && this.salon[field].indexOf("https") != -1) {
                        this[field] = this.salon[field].replace("https", "http");
                    }

                    if (this[field] == this[modelFieldsBase[k]]) {
                        this[field] = "";
                    }
                });

                this.salon.users.forEach((user) => {
                    this.getNextAvailableDate(user);
                });

                this.insertHeaderTitle(this.salon.name);
            });
        },
        getNextAvailableDate(user) {
            var data = {
                stylist_id: user.user_id,
                services_duration: 0,
                current_datetime: moment().format("YYYY-MM-DD h:mm a"),
                disabled_days: [],
            };

            this.$http
                .post(api.actions.get_next_available_dates, data, {
                    headers: { "Api-Token": api.DEMO_CLIENT },
                })
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    if (response.data && response.data.length > 0) {
                        user.next_available = moment(response.data[0].date).format("dddd, MMM DD");
                        this.$forceUpdate();
                    }
                });
        },
        onChooseStylist(user) {
            this.selectedStylist = user;
            this.selectedStylistId = user.user_id;
            this.selectedServices = [];
            this.updateCartItemsNumber();
            this.getDisabledDates();
            this.getClientPolicyAgreement();
            this.currentStep = 1;
            this.appointmentMessage = '';
        },
        initMap() {
            var map = this.$refs.mapRef;
            var geocoder = new window.google.maps.Geocoder();
            var address =
                this.salon.address +
                ", " +
                this.salon.city +
                ", " +
                this.salon.state +
                ", " +
                this.salon.zip +
                ", " +
                this.salon.country;
            geocoder.geocode({ address: address }, (results, status) => {
                if (status === "OK") {
                    this.addressLat = results[0].geometry.location.lat();
                    this.addressLng = results[0].geometry.location.lng();
                    map.panTo(results[0].geometry.location);
                    this.marker_position = results[0].geometry.location;
                }
            });
        },
        onStylistChange() {
            this.salon.users.forEach((user) => {
                if (this.selectedStylistId == user.user_id) {
                    this.selectedStylist = user;
                    this.selectedServices = [];
                    this.updateCartItemsNumber();
                    this.getDisabledDates();
                    this.getClientPolicyAgreement();
                }
            });
        },
        getFormattedServiceDuration(duration) {
            let service_duration = "";
            let duration_hours = Math.floor(duration / 60);
            let duration_min_left = duration % 60;

            if (duration_hours) {
                service_duration = duration_hours + " " + (duration_hours == 1 ? "hour" : "hours");
            }

            if (duration_hours && duration_min_left) {
                service_duration += " and ";
            }

            if (duration_min_left) {
                service_duration += duration_min_left + " " + "minutes";
            }

            return service_duration;
        },
        onSelectService(service) {
            if (!_.includes(this.selectedServices, service)) {
                this.selectedServices.push(service);
                this.getDisabledDates();
            } else {
                service.qty++;
            }

            this.updateServiceTotals();
            this.getStylistFreeHours(this.bookingDate);
            this.getNextAvailableDates();
            this.updateCartItemsNumber();

            if (this.selectedServices.length > 2 && this.isMobile()) {
                this.cartCollapsed = true;
            }
        },
        deleteService(index) {
            this.selectedServices[index].qty = 1;
            this.selectedServices.splice(index, 1);
            this.getDisabledDates();
            this.updateServiceTotals();
            this.getStylistFreeHours(this.bookingDate);
            this.getNextAvailableDates();
            this.updateCartItemsNumber();
        },
        onContinueToTime() {
            this.currentStep = 2;

            if (this.isMobile()) {
                this.cartCollapsed = true;
            }
        },
        onChooseTime() {
            this.currentStep = 2;
            this.showMoreDates = false;

            if (this.isMobile()) {
                this.cartCollapsed = true;
            }
        },
        onResetData() {
            this.selectedStylist = {};
            this.selectedStylistId = 0;
            this.selectedServices = [];
            this.updateCartItemsNumber();
        },
        onShowMoreDates(startDate) {
            this.showMoreDates = true;
            this.loadingUnavailabeDates = true;

            if (!startDate) {
                startDate = moment().format("YYYY-MM-DD");
            }

            var data = {
                months_check: 1,
                services_duration: this.selectedServicesTotalDuration,
                start_date: startDate,
                stylist_id: this.selectedStylistId,
            };

            this.$http.post(api.actions.get_unavailable_dates, data).then((response) => {
                this.loadingUnavailabeDates = false;

                if (response.data && response.error == true) {
                    return;
                }

                this.getDisabledDates();

                response.data.forEach((date) => {
                    let date_formatted = new Date(date).toISOString().split("T")[0];

                    if (this.selectedStylist.exception_days.indexOf(date_formatted) != -1) {
                        return;
                    }

                    this.disabledDates.dates.push(moment(date).toDate());
                });
            });
        },
        getDisabledDates() {
            this.disabledDates.days = [];
            this.disabledDates.dates = [];

            var disabledWeekDays = [];
            this.workDays.forEach((day, i) => {
                if (this.selectedStylist.work_settings[day] == 0) {
                    disabledWeekDays.push(i);
                }
            });

            this.selectedStylist.free_days.forEach((day) => {
                this.disabledDates.dates.push(moment(day.date).toDate());
            });

            var available_future_days =
                this.selectedStylist.work_settings && this.selectedStylist.work_settings.available_future_days > 0
                    ? this.selectedStylist.work_settings.available_future_days
                    : 365;
            this.disabledDates.from = moment().add(available_future_days, "days").toDate();

            //Add the service restricted dates
            this.unavailableServiceDays = [];
            this.unavailableServiceNameDays = [];

            this.selectedServices.forEach((service) => {
                if (!service.restricted || !service.restrictions) {
                    return;
                }

                let serviceDisabledDays = {
                    service: service.name,
                    days: [],
                };

                this.workDays.forEach((day, i) => {
                    if (service.restrictions[day] == 0 && this.unavailableServiceDays.indexOf(i) == -1) {
                        this.unavailableServiceDays.push(i);
                    }

                    if (service.restrictions[day] == 0 && disabledWeekDays.indexOf(i) == -1) {
                        disabledWeekDays.push(i);
                    }

                    if (service.restrictions[day] == 0) {
                        serviceDisabledDays.days.push(day.charAt(0).toUpperCase() + day.slice(1));
                    }
                });

                this.unavailableServiceNameDays.push(serviceDisabledDays);

                this.disabledDates.customPredictor = (date) => {
                    let date_formatted = new Date(date).toISOString().split("T")[0];
                    if (
                        disabledWeekDays.indexOf(date.getDay()) != -1 &&
                        this.selectedStylist.exception_days.indexOf(date_formatted) == -1
                    ) {
                        return true;
                    }
                };
            });
        },
        updateServiceTotals() {
            this.selectedServicesTotalCost = 0;
            this.selectedServicesTotalDuration = 0;

            this.selectedServices.forEach((service) => {
                this.selectedServicesTotalCost += parseInt(service.price_default * service.qty);
                this.selectedServicesTotalDuration += parseInt(service.duration_default * service.qty);
            });

            if (!this.bookingDate) {
                this.bookingDate = new Date();
            }

            if (this.bookingDateTime) {
                let endTime =
                    moment(this.bookingDateTime, "YYYY-MM-DD h:mm A").unix() + this.selectedServicesTotalDuration * 60;
                this.bookingEndDateTime = moment.unix(endTime).format("YYYY-MM-DD hh:mm:ss");
            }

            if (
                this.selectedStylist.policy &&
                this.selectedStylist.policy.deposit != 0 &&
                this.selectedStylist.policy.deposit_fee != 0
            ) {
                if (this.selectedStylist.policy.deposit_type == "fixed") {
                    this.depositFee = this.selectedStylist.policy.deposit_fee;
                } else {
                    this.depositFee = (
                        this.selectedServicesTotalCost *
                        (this.selectedStylist.policy.deposit_fee / 100)
                    ).toFixed(2);
                }
            }
        },
        onDateSelected(date) {
            this.getStylistFreeHours(date);
            this.bookingHour = "";
        },
        onCalendarMonthChange(firstOfMonth) {
            let startDate = moment(firstOfMonth).format("YYYY-MM-DD");
            this.onShowMoreDates(startDate);
        },
        getStylistFreeHours(date) {
            var formatedDate = moment(date).format("YYYY-MM-DD");
            var data = {
                stylist_id: this.selectedStylistId,
                date: formatedDate,
                current_datetime: moment().format("YYYY-MM-DD h:mm a"),
                services_duration: this.selectedServicesTotalDuration,
                include_taken: true,
            };

            this.bookingDate = formatedDate;

            var action = api.actions.get_free_hours;
            var method = "post";

            if (this.user) {
                action = api.actions.get_stylist_free_hours;
                action += "?stylist_id=" + this.selectedStylistId;
                action += "&date=" + formatedDate;
                action += "&current_datetime=" + moment().format("YYYY-MM-DD h:mm a");
                action += "&services_duration=" + this.selectedServicesTotalDuration;
                action += "&include_taken=1";
                method = "get";
            }

            this.$http[method](action, data, {
                headers: { "Api-Token": api.DEMO_CLIENT },
            }).then((response) => {
                if (response.error == true) {
                    alert("error");
                } else {
                    if (response.data.error == true) {
                        return;
                    } else {
                        this.freeHours = response.data.free_hours ? response.data.free_hours : [];
                        this.freeHoursGroups = {
                            morning: [],
                            afternoon: [],
                            evening: [],
                        };

                        let afternoon = moment(formatedDate + " 12:00:00").unix();
                        let evening = moment(formatedDate + " 17:00:00").unix();

                        this.freeHours.forEach((hour) => {
                            var dt = moment(hour, ["h:mm A"]).format("HH:mm");
                            let time = moment(formatedDate + " " + dt).unix();

                            if (time < afternoon) {
                                this.freeHoursGroups["morning"].push(hour);
                            } else if (time >= afternoon && time < evening) {
                                this.freeHoursGroups["afternoon"].push(hour);
                            } else {
                                this.freeHoursGroups["evening"].push(hour);
                            }
                        });

                        this.slotsPos = [];
                        this.freeHoursGroupNames.forEach((el, k) => {
                            this.slotsPos[k] = 0;
                        });

                        if (this.freeHours.indexOf(this.bookingHour) == -1) {
                            this.bookingHour = "";
                        }
                    }
                }
            });
        },
        onServiceQtyChange() {
            this.updateServiceTotals();
            this.getStylistFreeHours(this.bookingDate);
            this.getNextAvailableDates();
        },
        getNextAvailableDates() {
            var data = {
                stylist_id: this.selectedStylistId,
                services_duration: this.selectedServicesTotalDuration,
                current_datetime: moment().format("YYYY-MM-DD h:mm a"),
                disabled_days: this.disabledDates.days,
            };

            this.$http
                .post(api.actions.get_next_available_dates, data, {
                    headers: { "Api-Token": api.DEMO_CLIENT },
                })
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    response.data.forEach((el, k) => {
                        this.slotsPos[k] = 0;
                    });

                    this.availableDates = response.data;
                });
        },
        onSelectTime(time, date) {
            if (date) {
                this.bookingDate = date;
            }

            this.showMoreDates = true;
            this.bookingHour = time;
            this.bookingDateTime = this.bookingDate + " " + this.bookingHour;

            var dt = moment(this.booking_hour, ["h:mm A"]).format("HH:mm");
            this.bookingDateTime = moment(this.bookingDate + " " + dt).format("YYYY-MM-DD HH:mm:ss");

            let end_time = moment(this.bookingDateTime).unix() + this.booking_services_total_duration * 60;
            this.booking_end_date_time = moment.unix(end_time).format("YYYY-MM-DD HH:mm:ss");

            this.getStylistFreeHours(this.bookingDate);

            this.squareFormDisplayed = false;
            this.stripeFormDisplayed = false;

            if (this.user && this.user.id) {
                this.checkUserLink();
                this.checkUserCard();
                this.getClientPolicyAgreement();
            } else {
                this.displayLogin = true;
                this.cartCollapsed = false;
            }
        },
        checkUserLink() {
            this.$http
                .get(api.actions.check_user_client_link + "/" + this.selectedStylistId + "/" + this.user.id)
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    if (response.data.error == true) {
                        return;
                    }

                    this.linked = response.data.linked;
                    this.blocked = response.data.blocked;

                    if (!response.data.linked) {
                        this.sendUserInvite();
                    }
                });
        },
        sendUserInvite() {
            var data = {
                user_id: this.selectedStylistId,
                client_id: this.user.id,
            };

            this.$http.post(api.actions.save_client_stylist_link, data);
        },
        slotsMoveLeft(index, step_length) {
            if (this.slotsPos[index] >= 0) {
                this.slotsPos[index] = 0;
                return;
            }

            this.slotsPos[index] = this.slotsPos[index] ? this.slotsPos[index] : 0;
            this.slotsPos[index] += step_length;

            if (this.slotsPos[index] > 0) {
                this.slotsPos[index] = 0;
            }

            this.slotsPosUpdates++;
        },
        slotsMoveRight(index, step_length, limit_length) {
            if (this.slotsPos[index] <= limit_length) {
                this.slotsPos[index] = limit_length;
                this.slotsPosUpdates++;
                return;
            }

            this.slotsPos[index] = this.slotsPos[index] ? this.slotsPos[index] : 0;
            this.slotsPos[index] -= step_length;

            if (this.slotsPos[index] < limit_length) {
                this.slotsPos[index] = limit_length;
            }

            this.slotsPosUpdates++;
        },
        getFormattedDate(date, format) {
            return moment(date).format(format);
        },
        loginAppUser(e) {
            e.preventDefault();
            auth.login(
                {
                    email: this.loginEmail,
                    password: this.loginPassword,
                    remember: true,
                },
                false
            );
        },
        onLoggedIn() {
            this.user = auth.user;
            this.client_id = this.user.role == "stylist" ? this.user.client_id : this.user.id;

            this.displayLogin = false;
            this.checkUserLink();
            this.getClientPolicyAgreement();
        },
        getClientPolicyAgreement() {
            if (!this.user) {
                return;
            }

            this.$http.get(api.actions.get_client_policy_agreement + this.selectedStylist.user_id).then((response) => {
                if (response.error == true) {
                    return;
                }

                if (response.data.error == true) {
                    return;
                }

                this.selectedStylist.policy = response.data.policy;
                this.checkUserCard();
            });
        },
        hasPolicyEnabled() {
            let policy = this.selectedStylist.policy;
            return (
                policy &&
                ((policy.cancel == 1 && policy.cancel_fee > 0) || (policy.deposit == 1 && policy.deposit_fee > 0)) &&
                this.selectedStylist.settings.accept_payments == 1 &&
                (this.selectedStylist.accept_stripe || this.selectedStylist.accept_square)
            );
        },
        addOtherCard() {
            this.addNewCard = true;

            if (this.selectedStylist.settings.accept_payments && this.selectedStylist.accept_square) {
                this.$http
                    .get(
                        api.actions.get_square_api_credentials +
                        "?stylist_id=" +
                        this.selectedStylist.user_id
                    )
                    .then((res) => {
                        this.squareApplicationId = res.data.response.application_id;
                        this.displayCardForm();
                    });
                return;
            }

            this.displayCardForm();
        },
        cancelNewCardAdd() {
            this.stripeCard.destroy();
            this.addNewCard = false;
            this.stripeFormDisplayed = false;
        },
        saveNewCard() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            let self = this;
            async function tokenize(paymentMethod) {
                const tokenResult = await paymentMethod.tokenize();
                self.loading = false;
                if (tokenResult.status === "OK") {
                    return tokenResult.token;
                }
            }

            //Save the card in Stripe
            if (this.selectedStylist.settings.accept_payments && this.selectedStylist.accept_stripe) {
                this.stripe
                    .confirmCardSetup(this.stripeClientSecret, {
                    payment_method: {
                        card: this.stripeCard,
                        billing_details: {},
                    },
                    })
                    .then((result) => {
                        this.loading = false;
                        if (result.error) {
                            this.cardsErrors = result.error.message;
                            return;
                        }
                        this.addNewCard = false;
                        this.stripeCard.destroy();
                        this.stripeFormDisplayed = false;
                        this.checkUserCard();
                    });

            //Save the card in Square
            } else if (this.selectedStylist.settings.accept_payments && this.selectedStylist.accept_square) {
                this.squareCardObj.then((card) => {
                    try {
                    tokenize(card).then((nonce) => {
                        if (!nonce) {
                        this.loading = false;
                        return;
                        }

                        var data = {
                            card_nonce: nonce,
                            stylist_id: this.selectedStylist.user_id,
                            client_id: this.user.id,
                        };

                        this.loading = true;

                        this.$http
                            .post(api.actions.save_square_customer_card, data)
                            .then(() => {
                                this.loading = false;
                                this.addNewCard = false;
                                this.checkUserCard();
                            });
                    });
                    } catch (e) {
                        self.cardsErrors = e.message;
                        self.loading = false;
                    }
                });
            }
        },
        checkUserCard() {
            if (!this.user || !this.hasPolicyEnabled()) {
                return;
            }

            var data = {};

            if (this.selectedStylist.accept_stripe) {
                this.$http
                    .get(
                        api.actions.get_stripe_payment_methods +
                            "?user_id=" +
                            this.user.id +
                            "&stylist_id=" +
                            this.selectedStylist.user_id
                    )
                    .then((response) => {
                        if (response.data.error) {
                            0;
                            this.hasCard = false;
                            this.displayCardForm();
                            return;
                        }

                        if (!response.data.payment_methods.length > 0) {
                            this.hasCard = false;
                            this.displayCardForm();
                        } else {
                            this.hasCard = true;
                            this.cardLast4 = response.data.payment_methods[0].card.last4;
                        }
                    });
            } else if (this.selectedStylist.accept_square) {
                data = {
                    stylist_id: this.selectedStylist.user_id,
                    client_id: this.user.id,
                };

                this.$http.post(api.actions.get_square_customer_cards, data).then((response) => {
                    if (response.data.error) {
                        return;
                    }

                    if (!response.data.response.length) {
                        this.hasCard = false;

                        this.$http
                            .get(api.actions.get_square_api_credentials + "?stylist_id=" + this.selectedStylist.user_id)
                            .then((res) => {
                                this.squareApplicationId = res.data.response.application_id;
                                this.displayCardForm();
                            });
                    } else {
                        this.hasCard = true;
                        this.cardLast4 = response.data.response[0].last_4;
                    }
                });
            }
        },
        getStripeSetupIntent() {
            if (this.gettingStripeClientSecret) {
                return;
            }

            this.gettingStripeClientSecret = true;

            this.$http
                .post(api.actions.get_stripe_setup_intent, {
                    user_id: this.user.id,
                    stylist_id: this.selectedStylist.user_id,
                })
                .then((response) => {
                    if (response.error == true) {
                        return;
                    }

                    this.stripeClientSecret = response.data.client_secret;
                    this.gettingStripeClientSecret = false;
                });
        },
        displayCardForm() {
            if (this.selectedStylist.settings.accept_payments == 0) {
                return;
            }

            let self = this;

            async function initializeCard(payments) {
                const card = await payments.card();
                await card.attach("#card-container");
                return card;
            }

            function loadSquareForm() {
                const payments = window.Square.payments(self.squareApplicationId, self.suqareLocationId);

                try {
                    self.squareCardObj = initializeCard(payments);
                } catch (e) {
                    self.cardsErrors = "Initializing Card failed";
                    return;
                }
            }

            //Generate the Stripe card form
            if (this.selectedStylist.accept_stripe) {
                if (!this.$refs.card_form) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (this.stripeFormDisplayed) {
                    return;
                }

                this.stripeFormDisplayed = true;

                if (!this.selectedStylist.stripe_public_key) {
                    return;
                }

                this.stripe = window.Stripe(this.selectedStylist.stripe_public_key);
                var elements = this.stripe.elements();
                this.stripeCard = elements.create("card");
                this.stripeCard.mount(this.$refs.card_form);

                this.stripeCard.addEventListener("change", (event) => {
                    if (event.error) {
                        this.cardsErrors = event.error.message;
                    } else {
                        this.cardsErrors = "";
                    }
                });

                this.getStripeSetupIntent();

                //Generate the Square card Form
            } else if (this.selectedStylist.accept_square) {
                if (!this.$refs.card_form_square) {
                    setTimeout(() => {
                        this.displayCardForm();
                    }, 100);
                    return;
                }

                if (this.squareFormDisplayed) {
                    return;
                }

                this.squareFormDisplayed = true;

                this.$http
                    .post(api.actions.get_square_locations, {
                        stylist_id: this.selectedStylist.user_id,
                    })
                    .then((response) => {
                        if (response.data.error == true || !response.data.locations) {
                            this.cardsErrors = "Error receiving the Square location";
                            return;
                        }

                        this.suqareLocationId = response.data.locations[0].id;
                        loadSquareForm();
                    });
            }
        },
        saveCard() {
            if (this.loading && !this.stripeClientSecret) {
                return;
            }

            this.loading = true;

            let self = this;
            async function tokenize(paymentMethod) {
                const tokenResult = await paymentMethod.tokenize();
                if (tokenResult.status === "OK") {
                    return tokenResult.token;
                }
            }

            //Save the card in Stripe
            if (this.selectedStylist.settings.accept_payments && this.selectedStylist.accept_stripe) {
                this.stripe
                    .confirmCardSetup(this.stripeClientSecret, {
                        payment_method: {
                            card: this.stripeCard,
                            billing_details: {},
                        },
                    })
                    .then((result) => {
                        this.loading = false;
                        if (result.error) {
                            this.cards_errors = result.error.message;
                            return;
                        } else {
                            this.hasCard = true;
                            this.$http.post(api.actions.save_stylist_policy_agreement, {
                                stylist_id: this.selectedStylistId,
                                client_id: this.user.id,
                                agree: 1,
                            });

                            this.checkUserCard();
                        }
                    });

                //Save the card in Square
            } else if (this.selectedStylist.settings.accept_payments && this.selectedStylist.accept_square) {
                this.squareCardObj.then((card) => {
                    try {
                        tokenize(card).then((nonce) => {
                            if (!nonce) {
                                return;
                            }

                            var data = {
                                card_nonce: nonce,
                                stylist_id: this.selectedStylistId,
                                client_id: this.user.id,
                            };

                            this.loading = true;

                            this.$http.post(api.actions.save_square_customer_card, data).then((response) => {
                                this.loading = false;

                                if (response.error == true) {
                                    return;
                                }

                                if (response.data.error == true) {
                                    return;
                                }

                                this.has_card = true;
                                this.$http.post(api.actions.save_stylist_policy_agreement, {
                                    stylist_id: this.selectedStylistId,
                                    client_id: this.user.id,
                                    agree: 1,
                                });

                                this.checkUserCard();
                            });
                        });
                    } catch (e) {
                        self.cardsErrors = e.message;
                    }
                });
            }
        },
        getNewServices(services) {
            let response = {};

            this.selectedStylist.services.forEach((service) => {
                if (services.indexOf(parseInt(service.id)) !== -1) {
                    response[service.id] = {
                        quantity: service.qty,
                        duration: service.duration * service.qty,
                        price: service.price * service.qty,
                    };
                }
            });

            return response;
        },
        bookAppointment() {
            if (this.loading) {
                return;
            }

            let services = this.selectedServices.map((service) => {
                return service.id;
            });

            let client_id = this.user.role == "stylist" ? this.user.client_id : this.user.id;

            var data = {
                date_time: this.bookingDate,
                hour: this.bookingHour,
                services: services,
                user_id: this.selectedStylistId,
                client_id: client_id,
                status: "unconfirmed",
                notes: this.bookingNotes,
                total_duration: this.selectedServicesTotalDuration,
                reminders: "",
                special_event: 0,
                new_services: this.getNewServices(services),
            };

            if (this.depositFee) {
                data["deposit_fee"] = this.depositFee;
            }

            this.appointmentMessage = "";
            this.loading = true;

            this.$http.post(api.actions.save_appointment, data).then((response) => {
                this.loading = false;

                if (response.data.error == true) {
                    this.appointmentMessage = '<div class="alert alert-danger">' + response.data.message + "</div>";
                    return;
                }

                this.currentStep = 3;
                this.selectedServices = [];
                this.updateCartItemsNumber();
            });
        },
        updateCartItemsNumber() {
            this.cartItems = 0;
            this.selectedServices.forEach((service) => {
                this.cartItems += service.qty;
            });
        },
        resetProcess() {
            this.currentStep = 0;
        },
        isMobile() {
            return (window.innerWidth < 768);
        },
        modalOpen(e, profile_alias, service_id) {
            e.preventDefault();
            document.querySelector("body").classList = ["modal-open"];
            this.modalStylistAlias = profile_alias;
            this.modalStylistServiceId = service_id;
        },
        modalClosed() {
            document.querySelector("body").classList = [];
            this.modalStylistAlias = null;
            this.modalStylistServiceId = 0;
        },
        insertHeaderTitle(title) {
            $('.site-top .site-top-inner').append(`<span class="mobile-salon-title">${title}</span>`);
        }
    },
};
</script>
