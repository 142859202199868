<template>
    <div>
        <div v-if="user && user.role == 'stylist'">
            <h5>{{ title }}</h5>
        </div>
        <div class="select-wrapper">
            <select
                class="selectInput selectInputExtraSmall selectInputSmall text-style"
                v-model="selectModel"
                v-on:change="saveClientsNotifications"
            >
                <option value="0">
                    {{ $ml.get(`notifications.${label}`) }}
                </option>
                <option v-for="(item, index) in list" v-bind:key="index" v-bind:value="index">
                    {{ item }}
                </option>
            </select>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            requierd: true,
        },
        user: {
            type: Object,
            requierd: true,
        },
        inputValue: {
            requierd: true,
        },
        list: {
            type: Object,
            requierd: true,
        },
        item: {
            type: String,
            requierd: true,
        },
        label: {
            type: String,
            requierd: true,
        },
    },
    emits: ["save"],
    data() {
        return {
            selectModel: null,
        };
    },
    watch: {
        inputValue(newValue) {
            this.selectModel = newValue;
        },
    },
    methods: {
        saveClientsNotifications() {
            this.$emit("save", {
                value: this.selectModel,
                field: this.item,
            });
        },
    },
};
</script>
