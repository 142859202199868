<template>
    <div class="stylists-component">
        <h3 class="book-title-page">
            Search for new <span v-if="generalOptions.app_id === 1">Stylists</span>
            <span v-if="generalOptions.app_id === 2">Barbers</span>
            <span v-if="generalOptions.app_id === 5">Locticians</span>
        </h3>
        <div class="search-stylists">
            <div class="search-input">
                <input
                    v-model="search"
                    v-on:keyup="debouncedSearch"
                    type="text"
                    :placeholder="
                        generalOptions.app_id === 1
                            ? 'Search for a service, salon, stylist, or phone number.'
                            : generalOptions.app_id === 2
                            ? 'Search for a service, salon, barber, or phone number.'
                            : generalOptions.app_id === 5
                            ? 'Search for a service, salon, loctician, or phone number.'
                            : ''
                    "
                    class="search-new-stylists"
                    name="search-new-stylists"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
                <i v-if="search !== ''" @click="resetSearch()" class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div
                class="stylists-container"
                :class="searchStylistsProfiles.length > 0 && search !== '' ? 'section-wrap' : ''"
            >
                <div
                    class="section-wrap no-shadow"
                    v-for="stylistProfile in searchStylistsProfiles"
                    :key="stylistProfile.id"
                >
                    <div
                        class="stylists-wrapper-info"
                        :class="!stylistProfile.has_subscription_active ? 'disabled-button' : ''"
                    >
                        <div class="stylist-avatar-image">
                            <div class="member-avatar-global">
                                <img
                                    v-if="stylistProfile.avatar"
                                    class="avatar-img"
                                    :src="stylistProfile.avatar"
                                    :alt="stylistProfile.first_name"
                                />
                                <div class="stylist-initials" v-if="!stylistProfile.avatar">
                                    {{ getInitial(stylistProfile.first_name) }}
                                    {{ getInitial(stylistProfile.last_name) }}
                                </div>
                            </div>
                            <div class="stylist-name">
                                {{ stylistProfile.first_name }} {{ stylistProfile.last_name }}
                            </div>
                        </div>
                        <div class="stylist-book-btns-wrap">
                            <div>
                                <div
                                    v-if="stylistProfile.accept_new_clients && !stylistProfile.prevent_clients_book"
                                    class="stylist-book-button"
                                    v-on:click="
                                        onSelectStylist(stylistProfile);
                                        scrollToTop();
                                    "
                                >
                                    Click to Book
                                </div>
                            </div>
                            <div class="stylist-btns-container">
                                <div class="">
                                    <a
                                        :href="stylistProfile.portfolio_url"
                                        class="view-profile-link primary-btn extra-small mobile"
                                        target="_blank"
                                        >View Profile</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stylist-unavailable" v-if="!stylistProfile.has_subscription_active">
                        This <span v-if="generalOptions.app_id === 1">stylist</span>
                        <span v-if="generalOptions.app_id === 2">barber</span>
                        <span v-if="generalOptions.app_id === 5">loctician</span> is currently unavailable. Please come
                        back later.
                    </div>
                    <div
                        class="stylist-unavailable"
                        v-if="
                            (stylistProfile.has_subscription_active && stylistProfile.accept_new_clients === 0) ||
                            (stylistProfile.has_subscription_active && !stylistProfile.prevent_clients_book == 0)
                        "
                    >
                        We are currently not accepting new clients or bookings. <br />
                        Thank you for your interest in our services.
                    </div>
                </div>
                <div
                    v-if="loadingStylist && searchStylistsProfiles.length === 0 && search !== ''"
                    class="no-stylists-found section-wrap"
                >
                    No stylists found
                </div>
            </div>
        </div>
        <div>
            <h3 class="book-title-page">
                <span v-if="generalOptions.app_id === 1">My Stylists</span>
                <span v-if="generalOptions.app_id === 2">My Barbers</span>
                <span v-if="generalOptions.app_id === 5">My Locticians</span>
                <span v-if="stylists.length > 0" class="stylist-number">({{ stylists.length }})</span>
            </h3>
            <div class="book-sections">
                <div class="book-section-date-time section-wrap">
                    <div class="stylist-wrapper">
                        <div class="stylist">
                            <div class="">
                                <div class="stylists-container">
                                    <div class="section-wrap no-shadow" v-for="(stylist, i) in stylists" v-bind:key="i">
                                        <div class="stylist-wrapp">
                                            <div class="stylist-logo-name-wrapper">
                                                <!--Logo stylists-->
                                                <div class="stylist-logo-wrapp">
                                                    <div class="member-avatar-global" v-if="stylist.avatar">
                                                        <img v-bind:src="stylist.avatar" />
                                                    </div>
                                                    <div class="member-avatar-global" v-if="!stylist.avatar">
                                                        <img src="../../assets/images/rmb-logo.png" />
                                                    </div>
                                                </div>
                                                <div class="stylist-details">
                                                    <div class="stylist-name">
                                                        {{ stylist.first_name }} {{ stylist.last_name }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stylist-book-btns-wrap">
                                                <div>
                                                    <div
                                                        v-if="!stylist.prevent_clients_book"
                                                        class="stylist-book-button"
                                                        v-on:click="
                                                            onSelectStylist(stylist);
                                                            scrollToTop();
                                                        "
                                                    >
                                                        Click to Book
                                                    </div>
                                                </div>
                                                <div class="stylist-btns-container">
                                                    <div class="">
                                                        <a
                                                            :href="stylist.portfolio_url"
                                                            class="view-profile-link primary-btn extra-small mobile"
                                                            target="_blank"
                                                            >View Profile</a
                                                        >
                                                    </div>
                                                    <div class="" v-on:click="confirmDeleteStylist(stylist)">
                                                        <div class="view-profile-link secondary-btn small mobile">
                                                            Delete
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="stylist-unavailable" v-if="stylist.prevent_clients_book">
                                            We are currently not accepting bookings. <br />
                                            Thank you for your interest in our services.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="search-stylist-text" v-if="loaded && stylists.length == 0">
                        <p>
                            You don't have any active <span v-if="generalOptions.app_id === 1">stylists</span>
                            <span v-if="generalOptions.app_id === 2">barbers</span>
                            <span v-if="generalOptions.app_id === 5">locticians</span> yet.
                        </p>
                    </div>
                    <div class="search-stylist-text" v-if="stylists.length === 0">
                        <!-- Click
                        <a class="main-text-link" href="https://book.ringmystylist.com/" target="_blank">here</a> to
                        search for stylists, barbers, or locticians. -->
                        Search for a <span v-if="generalOptions.app_id === 1">stylist</span>
                        <span v-if="generalOptions.app_id === 2">barber</span>
                        <span v-if="generalOptions.app_id === 5">loctician</span>
                        to make your first appointment.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "bootstrap";
import api from "./../../services/api_endpoints.js";
import auth from "./../../services/auth.js";
import store from "./../../services/vuex.js";
import messages from "./../../services/messages.js";
import eventBus from "./../../services/event_bus.js";
import Vue from "vue";

export default {
    name: "AdminBook",
    data: function () {
        return {
            user: null,
            stylists: [],
            loaded: false,
            i: 0,
            search: "",
            searchStylistsProfiles: [],
            loadingStylist: false,
            debounceTimer: null,
        };
    },
    mounted: function () {
        this.user = auth.user;
        this.getStylists();
        eventBus.$emit("check_admin_section");
    },
    computed: {},
    methods: {
        getStylists: function () {
            this.stylists = [];
            this.loaded = false;

            this.$http.get(api.actions.get_stylists + "?working=1&exclude_blocked=1").then(
                function (response) {
                    if (typeof response.data.stylists != "undefined") {
                        this.stylists = response.data.stylists;
                        this.loaded = true;
                    }
                }.bind(this)
            );
        },
        onSelectStylist: function (stylist) {
            //reset everything
            store.state.bookData = {
                id: 0,
                stylist_id: stylist.id,
                services: [],
                date: store.state.bookData.date,
                hour: store.state.bookData.hour,
                special_event: false,
                notes: "",
                reminders: [],
            };
            store.state.previewData = {
                stylistName: "",
                services: [],
            };
            store.state.currentSection = 1;
            store.state.freeHours = [];
            store.state.currentBookSection = 1;
            store.state.serviceCategories = [];
            store.state.services = {};
            store.state.stylists = [];
            store.state.selectedStylist = null;
            store.state.isUserGroup = false;
            store.state.selectedServicesDuration = 0;

            if (stylist.alias === undefined) {
                stylist.alias = stylist.id;
            }

            this.$router.push("/app/book/" + stylist.alias);
        },
        confirmDeleteStylist: function (client) {
            let customClass = "rms-app";
            document.querySelector("body").classList.add("rms-app");

            if (this.generalOptions.app_id === 2) {
                customClass = "rmb-app";
                document.querySelector("body").classList.add("rmb-app");
            } else if (this.generalOptions.app_id === 5) {
                customClass = "rml-app";
                document.querySelector("body").classList.add("rml-app");
            }

            Vue.swal({
                title: this.$ml.get("adminDashboard.details.cofirmQuestion"),
                type: "warning",
                customClass: customClass,
                showCancelButton: true,
                confirmButtonText: this.$ml.get("clients.cofirmYes"),
                cancelButtonText: this.$ml.get("clients.cofirmNo"),
            }).then((result) => {
                if (result.value) {
                    this.deleteStylist(client);
                }
            });
        },
        deleteStylist: function (user) {
            let data = {
                id: user.id,
            };
            this.$http.post(api.actions.delete_stylist, data).then(
                function (response) {
                    if (response.data.error == false) {
                        messages.successMessage(messages.GLOBAL_MESSAGES.GENERAL_DELETE);
                        //refresh the list
                        this.getStylists();
                    }
                }.bind(this),
                function () {
                    return false;
                }
            );
        },
        scrollToTop() {
            // // window.scrollTo(0, 0);
            // window.scrollTo({ top: 0, behavior: "smooth" });
            const duration = 800; // Scroll duration in milliseconds
            const start = window.scrollY;
            const startTime = "now" in window.performance ? performance.now() : new Date().getTime();

            const scrollToTop = (timestamp) => {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easeInOutCubic = (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
                window.scrollTo(0, start + (0 - start) * easeInOutCubic(progress));

                if (elapsed < duration) {
                    requestAnimationFrame(scrollToTop);
                }
            };

            requestAnimationFrame(scrollToTop);
        },
        onSearch() {
            if (this.search.length >= 3) {
                this.$http.get(api.actions.get_profiles_list + "?search_name_phone=" + this.search).then(
                    function (response) {
                        if (response.data.error == false) {
                            this.loadingStylist = true;
                            this.searchStylistsProfiles = response.data.profiles;
                        }
                    }.bind(this),
                    function () {
                        return false;
                    }
                );
            }
            if (this.search === "") {
                this.searchStylistsProfiles = [];
                this.loadingStylist = false;
            }
        },
        debouncedSearch() {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.onSearch();
            }, 500);
        },

        resetSearch: function () {
            this.searchStylistsProfiles = [];
            this.search = "";
            this.loadingStylist = false;
        },
        getInitial: function (name) {
            if (!name) {
                return;
            }
            return name[0].toUpperCase();
        },
    },
    components: {},
};
</script>
